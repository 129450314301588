import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProjectProcessStartRequestCustomerAlignMutation } from "src/FSD/entities/projectProcess/api";
import { useProcessWorkStartRequestCustomerAlignMutation } from "src/FSD/entities/processWork/api";
import { Nullable } from "src/types/generics";
import { requestCustomerAlignSchema } from "../schemas/requestCustomerAlignSchema";

interface UseModalProps {
  id: number;
  type: "ProjectProcess" | "ProcessWork";
}

const defaultValues = {
  dateDeadline: null,
  content: "",
  name: "Предварительное решение",
};

export interface TFieldValues {
  dateDeadline: Nullable<Date>;
  content: string;
  name: string;
}

export const useRequestCustomerAlignStart = ({ id, type }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const form = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(requestCustomerAlignSchema),
  });

  const submitQueries = {
    ProjectProcess: useProjectProcessStartRequestCustomerAlignMutation,
    ProcessWork: useProcessWorkStartRequestCustomerAlignMutation,
  };

  const [startRequestCustomerAlign] = submitQueries[type]();

  const onSubmit = async (dataValues: TFieldValues) => {
    const data = {
      StartForm: {
        content: dataValues.content,
        name: dataValues.name,
        date_deadline: toServerDate(dataValues.dateDeadline)!,
      },
    };

    await startRequestCustomerAlign({ id, data })
      .unwrap()
      .then(() => {
        handleClose();
      });
  };

  return {
    form,
    onSubmit,
  } as const;
};
