import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TModalMode } from "src/components/pages/ProjectProcessView/Expertise/ExpertiseCard/Remark/RemarkTable/RemarkTableContent/RemarkTableContent";
import { ApiPrimaryTaskType } from "src/types/api/primaryTypes/apiPrimaryTaskType";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Nullable } from "src/types/generics";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useTaskCompleteMutation } from "src/FSD/entities/task/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { taskCompleteSchema } from "../schemas/taskComplete";

interface TProps {
  task: ApiPrimaryTaskType;
  expertiseName: string;
  projectName: string;
  mode: TModalMode;
}

interface TInputs {
  comment: string;
  reason: Nullable<TSelectItem>;
  changes: string;
}

export const useTaskComplete = ({
  expertiseName,
  mode,
  task,
  projectName,
}: TProps) => {
  const { handleClose } = useModalContext();
  const [taskComplete, { isLoading: isLoadingTaskComplete }] =
    useTaskCompleteMutation();

  const isEditingMode = mode === "edit";
  const titleAfter = isEditingMode
    ? `Изменение ответа на замечания`
    : `Подготовка ответа на замечания`;
  const title = `${titleAfter} «${expertiseName}» по проекту «${projectName}`;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TInputs>({
    mode: "onSubmit",
    resolver: yupResolver(taskCompleteSchema),
    defaultValues: {
      reason: task?.workflow_data.reason
        ? {
            title: task?.workflow_data.reason,
          }
        : null,
      comment: task.comment,
      changes: task.workflow_data.changes,
    },
  });

  const handleSubmitForm = async (dataValues: TInputs) => {
    const data = {
      CompleteForm: {
        status: isEditingMode ? task?.status.key : Statuses.IS_DONE,
        comment: dataValues.comment,
        reason: dataValues.reason?.title,
        changes: dataValues.changes,
      },
    };

    await taskComplete({ id: task.id, task: data })
      .unwrap()
      .then(() => {
        handleClose();
      });
  };

  const onSubmit = handleSubmit(handleSubmitForm);

  return {
    isEditingMode,
    onSubmit,
    title,
    control,
    errors,
    isLoadingTaskComplete,
  };
};
