import React, { FC, useMemo, useEffect, useRef, MouseEvent } from "react";
import { IconButton } from "@mui/material";
import cn from "classnames";
import style from "../style.module.scss";
import { FolderSVG } from "../svgs";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getDashboardSections } from "../../../../../../app/feature/dashboard/sections";
import {
  getDashboardDestinations,
  setProjectId,
  setSectionId,
  setDashboardCounts,
  setSectionData,
  setTab,
} from "../../../../../../app/feature/dashboard/destinations";
import {
  FolderArrow,
  FolderArrowFill,
  OpenIcon,
} from "../../../../../newUI/SVG";
import { getProject } from "../../../../../../app/feature/dashboard/projects/thunks";
import SubProjects from "./SubProjects";
import {
  getDashboardProjects,
  setOpenSubProjects,
  setProjectStatus,
} from "../../../../../../app/feature/dashboard/projects";
import { config } from "../../../../../../app.cofig";
import useHover from "../../../../../hooks/useHover";

interface ProjectItemProps {
  id: number;
  name: string;
  status: number;
  children: number[];
  offset?: number;
  stopped?: boolean;
  parentId: number | null;
  haveChilds?: boolean;
  dashboardCounts: Record<string, number>;
  isMobile: boolean;
}

const ProjectItem: FC<ProjectItemProps> = ({
  id,
  status,
  name,
  offset = 0,
  stopped,
  haveChilds,
  dashboardCounts,
  isMobile,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [hovered, eventHandlers, setHovered] = useHover();
  const { subPending, subData, openSubProjects } =
    useAppSelector(getDashboardProjects);
  const { pending } = useAppSelector(getDashboardSections);
  const { userType, projectId, tab } = useAppSelector(getDashboardDestinations);

  const { countApplication, countUnSignedDocs, countTasks } = dashboardCounts;

  const isOpen = openSubProjects.includes(id);

  const dispatch = useAppDispatch();

  const indicator = useMemo(() => {
    if (stopped) {
      return haveChilds ? "redFolder" : "red";
    }

    return haveChilds ? "folder" : null;
  }, [haveChilds, status]);

  useEffect(() => {
    if (isOpen && !subData[id]) {
      dispatch(getProject({ userType, id }));
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setHovered(false);
    };
  }, []);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(setOpenSubProjects(id));
  };

  const isSelected = id === projectId;
  const isPending = subPending.includes(id);

  const handleClick = () => {
    if (pending || (isSelected && !isMobile)) {
      return;
    }

    if (isMobile && isSelected) {
      dispatch(setTab("section"));
    }

    dispatch(
      setSectionData({
        containsApplications: false,
        containsTasks: false,
      })
    );
    dispatch(setDashboardCounts(dashboardCounts));
    dispatch(setProjectId(id));
    dispatch(setProjectStatus(id));
    dispatch(setSectionId(0));
  };

  const handleOpenProject = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    window.open(`${config.localDomain}/project/${id}`, "_blank")!.focus();
  };

  useEffect(() => {
    if (scrollRef.current && isSelected) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [tab]);

  const showFolderLine = haveChilds && isOpen && !isPending;
  const showAttachmentLine =
    Boolean(offset) && indicator !== "redFolder" && indicator !== "folder";

  const shiftLine =
    isSelected && (indicator === "folder" || indicator === "redFolder");

  const downLine =
    isSelected && indicator !== "folder" && indicator !== "redFolder";

  const Counters = () => (
    <div className={style.projectCounters}>
      <div>
        <p>Заявки</p>
        <h2>{countApplication}</h2>
      </div>
      <div>
        <p>Подписать</p>
        <h2>{countUnSignedDocs}</h2>
      </div>
      <div>
        <p>Выполнить</p>
        <h2>{countTasks}</h2>
      </div>
    </div>
  );

  const OpenProject = () => {
    if (isSelected) {
      return (
        <div className={style.openProject}>
          {!isMobile && (
            <IconButton onClick={handleOpenProject} {...(eventHandlers as any)}>
              <OpenIcon />
            </IconButton>
          )}
          {haveChilds && (
            <IconButton onClick={handleOpen} className={style.dropDown}>
              {isOpen ? <FolderArrowFill /> : <FolderArrow />}
            </IconButton>
          )}
          {hovered && (
            <div className={cn(style.prompt, style.smallPrompt)}>Открыть</div>
          )}
        </div>
      );
    }

    return (
      <div className={style.openProject}>
        {haveChilds && (
          <IconButton onClick={handleOpen} className={style.dropDown}>
            {isOpen ? <FolderArrowFill /> : <FolderArrow />}
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn(
        style.projectItemBasic,
        !offset && style.projectsGroup,
        isMobile && !indicator && style.noIndicator,
        isMobile && offset && style.darkSubProjects
      )}
    >
      <div
        onMouseLeave={() => setHovered(false)}
        ref={scrollRef}
        onClick={handleClick}
        className={cn(style.projectItem, isSelected && style.selected)}
      >
        {!isMobile && (
          <>
            {showFolderLine && (
              <div
                style={{ left: `${27 + 25 * offset}px` }}
                className={cn(style.folderLine, shiftLine && style.shiftedLine)}
              />
            )}
            {showAttachmentLine && (
              <div
                className={cn(
                  style.attachmentLine,
                  downLine && style.selectShiftedLine
                )}
                style={{ left: `${27 + 25 * (offset - 1)}px` }}
              />
            )}
          </>
        )}
        {indicator && (
          <div className={style.indicator}>
            {indicator === "redFolder" && <FolderSVG color="red" />}
            {indicator === "folder" && <FolderSVG color="grey" />}
            {indicator === "red" && <div className={style.red} />}
          </div>
        )}
        <a
          style={{ paddingLeft: `${25 * offset * Number(!isMobile)}px` }}
          className={cn(style.title, style.whiteText)}
        >
          {name}
        </a>
        <OpenProject />
        <Counters />
        {isSelected && isMobile && (
          <IconButton
            className={style.openProjectMobile}
            onClick={handleOpenProject}
            {...(eventHandlers as any)}
          >
            <OpenIcon />
          </IconButton>
        )}
      </div>
      {isOpen && <SubProjects isMobile={isMobile} offset={offset} id={id} />}
    </div>
  );
};

export default ProjectItem;
