import React from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  Modal,
  Select,
  TextField,
  TextLink,
} from "src/FSD/shared/uiKit/v2";
import { ApiPrimaryTaskType } from "src/types/api/primaryTypes/apiPrimaryTaskType";
import { TModalMode } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseInTasks/ExpertiseCard/Remark/RemarkTable/RemarkTableContent/RemarkTableContent";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useTaskComplete } from "../lib/hooks/useTaskComplete";
import styles from "./TaskComplete.module.scss";

const reasonList: TSelectItem[] = [
  {
    id: 1,
    title: "Замечание принято",
  },
  {
    id: 2,
    title: "Замечание принято частично",
  },
  {
    id: 3,
    title: "Замечание не принято",
  },
];

interface IProps {
  task: ApiPrimaryTaskType;
  row: { text: string };
  expertiseName: string;
  projectName: string;
  files?: any;
  mode: TModalMode;
}

export const TaskComplete = ({
  task,
  row,
  expertiseName,
  projectName,
  files,
  mode,
}: IProps) => {
  const {
    isEditingMode,
    title,
    onSubmit,
    control,
    errors,
    isLoadingTaskComplete,
  } = useTaskComplete({
    expertiseName,
    projectName,
    mode,
    task,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <div>
          <div className={styles.legend}>Замечание</div>
          <div className={styles.fieldset}>
            <div className={styles.fieldset_files}>
              {row.text}
              {files?.map((file: any) => (
                <TextLink url={file.url} redirect target={"_blank"}>
                  {file.name}
                </TextLink>
              ))}
            </div>
          </div>
        </div>
        <Controller
          name="reason"
          control={control}
          render={({ field: { value, onChange, name } }) => (
            <Select
              label="Статус замечания"
              value={value}
              options={reasonList}
              changeHandler={(_, newValue) => {
                onChange(newValue);
              }}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, name } }) => (
            <TextField
              label="Ответ на замечание"
              placeholder="Введите текст"
              multiline
              minRows={2}
              onChange={onChange}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
        <Controller
          name="changes"
          control={control}
          render={({ field: { onChange, name } }) => (
            <TextField
              label="Документация, в которую внесены изменения"
              placeholder="Введите текст"
              multiline
              minRows={2}
              onChange={onChange}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
        <Modal.Controls>
          <Button type="submit" isLoading={isLoadingTaskComplete}>
            {isEditingMode ? "Сохранить" : "Отправить на проверку"}
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
