import {
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  memo,
} from "react";
import cn from "classnames";
import styles from "./Paragraph.module.scss";

export type TColor = "default" | "light" | "warning" | "error" | "minor";
type TFontWeight = "medium" | "semiBold" | "bold";

type TProps = HTMLAttributes<HTMLParagraphElement> & {
  children: ReactNode;
  fontSize?: number;
  className?: string;
  style?: Record<string, string | number>;
  color?: TColor;
  fontWeight?: TFontWeight;
};

export const Paragraph = memo(
  forwardRef(
    (
      {
        children,
        fontSize = 16,
        className = "",
        fontWeight,
        style = {},
        color = "default",
        ...rest
      }: TProps,
      ref: ForwardedRef<any>
    ) => {
      return (
        <p
          ref={ref}
          className={cn(
            styles.paragraph,
            className,
            color === "light" && styles.paragraph_light,
            color === "warning" && styles.paragraph_warning,
            color === "error" && styles.paragraph_error,
            color === "minor" && styles.paragraph_minor,
            fontWeight === "medium" && styles.paragraph_medium,
            fontWeight === "semiBold" && styles.paragraph_semiBold,
            fontWeight === "bold" && styles.paragraph_bold
          )}
          style={{ fontSize: `${fontSize}px`, ...style }}
          {...rest}
        >
          {children}
        </p>
      );
    }
  )
);
