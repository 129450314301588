import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiProjectAccessRequests } from "src/FSD/entities/projectAccess/model/requestsTypes";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { Nullable } from "src/types/generics";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { executorUpdateSchema } from "../schemas/executorUpdate";
import { useQueries } from "./useQueries";

type TFieldValues = {
  userTypeToAssign: Nullable<TSelectItem>;
  userToAssign: Nullable<TSelectItem>;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
  type: ProjectAccessControllingEntities;
};

export const useExecutorUpdate = ({
  id,
  defaultValues,
  handleClose,
  type,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(executorUpdateSchema),
    mode: "onChange",
  });

  const isProcessWork = type === ProjectAccessControllingEntities.PROCESS_WORK;
  const isProject = type === ProjectAccessControllingEntities.PROJECT;

  const { updateExecutor, viewData, isFetching } = useQueries({ id, type });

  const userType = watch("userTypeToAssign");
  const executor = watch("userToAssign");

  const accessData = isProject
    ? viewData?.gipAccessActive
    : viewData?.activeAccess;

  const userTypeId = isProcessWork
    ? accessData?.userType?.firmId
    : accessData?.userType?.id;

  const userId = isProject ? accessData?.user_id : accessData?.user?.id;

  const label = isProject ? "ГИП" : "Исполнитель";

  const onSubmit = async (data: TFieldValues) => {
    const accessCode = isProject
      ? viewData!.gipAccessActive.access_code
      : viewData!.activeAccess.access_code;

    const formedData: ApiProjectAccessRequests.Update = {
      access_code: accessCode,
      user_id: +data.userToAssign!.id,
    };

    if (isProcessWork) {
      formedData.firm_id = +data.userTypeToAssign!.id;
    } else {
      formedData.user_type_id = +data.userTypeToAssign!.id;
    }

    const activeAccessId = isProject
      ? viewData!.gipAccessActive.id
      : viewData!.activeAccess.id;

    const result = await updateExecutor({ id: activeAccessId, formedData });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
    }
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    errors,
    userType,
    executor,
    isViewPending: !viewData || isFetching,
    userTypeId,
    userId,
    label,
    isProcessWork,
  } as const;
};
