import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiProcessMaterialsSubmit } from "src/app/services/api/projectProcess/material";
import { pushError } from "../../../errorTrace";

export const fetchMaterialsOfferSubmit = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data: any;
  },
  { rejectValue: { status: string; message: string } }
>(
  "materialProcess/fetchMaterialsOfferSubmit",
  async ({ taskId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiProcessMaterialsSubmit(taskId, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);

export const fetchMaterialsOfferUpdate = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data: any;
  },
  { rejectValue: { status: string; message: string } }
>(
  "materialProcess/fetchMaterialsOfferUpdate",
  async ({ taskId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiProcessMaterialsSubmit(taskId, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);
