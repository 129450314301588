const defaultTreaty = {
  id: 0,
  statusKey: 1, // договор
  status: [],
  title: "",
  reason: null,
  sections: [],
  date: "",
  price: null,
};

enum COLORS {
  ORANGE = "orange",
  GREEN = "green",
}

const NO_VAT = "без НДС";

const parseExecutor = (documentAccesses: any) => {
  const executor = documentAccesses.find(
    (executor: any) => executor.access_code === 3
  );
  const { surname, name, patronymic } = executor.userType.user;
  return `${surname} ${name} ${patronymic}`;
};

const getPrice = (
  value: number,
  projectProcesses: any = [],
  processWorks: any = []
) => {
  const process = projectProcesses.length ? projectProcesses : processWorks;

  return {
    value,
    secure: process.some((process: any) => process.secure),
    nds:
      process.reduce(
        (message: string, process: any) =>
          process.executorNds !== NO_VAT ? process.executorNds : message,
        NO_VAT
      ) || NO_VAT,
  };
};

const getStatus = (item: any) => {
  const getSignColor = (code: number) =>
    item.documentAccesses?.find((doc: any) => doc.access_code === code)
      .documentSign?.status === 1
      ? COLORS.GREEN
      : COLORS.ORANGE;

  const author = getSignColor(1);
  const executor = getSignColor(3);

  return [author, executor];
};

const createAttachment = ({ id, name }: any, status: string[]) => ({
  ...defaultTreaty,
  id,
  title: name,
  status,
});

const getDateAndSections = (
  projectProcesses: any = [],
  processWorks: any = []
) => {
  const processes = projectProcesses.length ? projectProcesses : processWorks;

  const date = processes
    .reduce((dates: string[], process: any, i: number) => {
      const newDates = [...dates];
      if (!i || new Date(dates[0]) > new Date(process.date_start)) {
        newDates[0] = process.date_start;
      }

      if (!i || new Date(dates[1]) < new Date(process.date_limit)) {
        newDates[1] = process.date_limit;
      }

      return newDates;
    }, [])
    .join(" • ");

  const sections = Array.from(
    new Set(processes.map((process: any) => process.projectPart.name))
  ).map((name: any) => {
    const letters = Array.from(
      new Set(
        processes
          .filter((process: any) => process.projectPart.name === name)
          .map((process: any) => (process.projectPart.type === 2 ? "П" : "Р"))
      )
    );

    return { name, letters };
  });

  return { date, sections };
};

const parseDocument = (item: any) => {
  const { id } = item;
  const title = item.name;
  const reason = null;
  const statusKey = item.status.id;
  const status = getStatus(item);
  const folderName = item.type.name;
  const typeId = item.type.id;
  const price = getPrice(
    item.totalPrice,
    item.projectProcesses,
    item.processWorks
  );
  const { date, sections } = getDateAndSections(
    item.projectProcesses,
    item.processWorks
  );

  return {
    id,
    title,
    reason,
    statusKey,
    status,
    folderName,
    typeId,
    price,
    date,
    sections,
  };
};

export const createDocuments = (
  documentsOut: any,
  childDocs: any,
  id: number,
  treatyName: string,
  treatyStatus: string[]
) => {
  const types = {
    treaty: [1, 2, 3, 7, 8, 12],
    act: [4, 10, 11],
    pirsDocs: [5, 6, 9],
  };

  let documents: any[] = [];

  documents.push(["Договор", []]);
  documents.push(["Акты", []]);
  documents.push(["Отчеты ПИРС", []]);

  let attachment: unknown;
  if (childDocs.length) {
    attachment = createAttachment(childDocs[0], treatyStatus);
  }
  const realTreaty = createAttachment({ id, name: "Договор" }, treatyStatus);

  const allDocuments = documentsOut.map((document: any) =>
    parseDocument(document)
  );

  allDocuments.forEach((document: any) => {
    const { typeId: id } = document;

    let folderName = "";
    if (types.treaty.includes(id)) {
      folderName = "Договор";
    } else if (types.act.includes(id)) {
      folderName = "Акты";
    } else if (types.pirsDocs.includes(id)) {
      folderName = "Отчеты ПИРС";
    }

    documents = documents.map((doc: any) => {
      if (doc[0] === folderName) {
        return [doc[0], [...doc[1], document]];
      }

      return doc;
    });
  });

  documents = documents.map((doc: any) => {
    if (doc[0] === "Договор") {
      const finalAttachments = [...doc[1], realTreaty];
      if (attachment) {
        finalAttachments.push(attachment);
      }

      return [treatyName, finalAttachments];
    }

    return doc;
  });

  return documents;
};

const parseTreaty = (item: any) => {
  const { id } = item;
  const title = item.name.split("Договор ")?.[1] ?? "";
  const reason = item.status.id === 4 ? item.status.name : null;
  const statusKey = item.status.id;

  const price = getPrice(
    item.totalPrice,
    item.projectProcesses,
    item.processWorks
  );
  const { date, sections } = getDateAndSections(
    item.projectProcesses,
    item.processWorks
  );
  const status = getStatus(item);
  const executor = parseExecutor(item.documentAccesses);
  const documents = createDocuments(
    item.documentsOut,
    item.childDocs,
    item.id,
    item.name,
    status
  );

  return {
    id,
    title,
    reason,
    statusKey,
    price,
    date,
    status,
    sections,
    executor,
    documents,
  };
};

export const createTreaties = (data: any) => {
  const allTreaties = data.map((treaty: any) => parseTreaty(treaty));
  const uniqueExecutors: string[] = Array.from(
    new Set(data.map((treaty: any) => parseExecutor(treaty.documentAccesses)))
  );

  const treaties: Record<string, any> = {};
  uniqueExecutors.forEach((executor: string) => {
    const appropriateTreaties = allTreaties
      .filter((treaty: any) => treaty.executor === executor)
      .map((treaty: any) => {
        delete treaty.executor;
        return treaty;
      });

    treaties[executor] = [...appropriateTreaties];
  });

  return treaties;
};
