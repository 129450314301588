import { CheckCircle, Circle } from "react-bootstrap-icons";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./InfoObject.module.scss";

type TStageProps = {
  done: boolean;
  color: string;
  label: string;
};

export const Stage = ({ done, color, label }: TStageProps) => {
  return (
    <div className={styles.stage}>
      {done ? (
        <CheckCircle color={color} className={styles.stage__icon} />
      ) : (
        <Circle color={color} className={styles.stage__icon} />
      )}
      <Paragraph color="light" fontSize={14}>
        {label}
      </Paragraph>
    </div>
  );
};
