import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { Task } from "./Task";
import styles from "./Tasks.module.scss";

type TProps = {
  material: ApiMaterialProcessResponses.Index;
};

export const Tasks = ({ material }: TProps) => {
  return (
    <ul className={styles.tasksBlock}>
      {material.tasks.map((task) => (
        <Task key={task.id} task={task} materialId={material.id} />
      ))}
    </ul>
  );
};
