import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import style from "./style.module.scss";
import { NavigationItemHead } from "../NavigationItemHead";
import { navigationType } from "src/types/stateTypes/navigation";

export interface DropdownMenuProps {
  item: navigationType;
  firstElement?: boolean;
}

export const getLengthChildren = (
  item: navigationType,
  length = item.items!.length || 0
): number => {
  const isHaveChildren = (parent: navigationType) =>
    parent.items && parent.items.length > 0;
  if (isHaveChildren(item)) {
    item.items!.forEach((child): void => {
      if (isHaveChildren(child)) {
        getLengthChildren(child, length + child.items!.length);
      }
    });
  }
  return length || 0;
}; // Рекурсивно считаем детей, для плавной анимации раскрытия

export const DropdownMenuHeader: React.FC<DropdownMenuProps> = React.memo(
  ({ item, firstElement = false }) => {
    const ELEMENT_HEIGHT = 41; // px
    const [isOpen, setOpen] = useState(false);
    const [childrenLength, setChildrenLength] = useState(0);
    const refBody = useRef(null);

    const clickHandler = () => {
      setOpen(!isOpen);
    };

    useEffect(() => {
      setChildrenLength(getLengthChildren(item) * ELEMENT_HEIGHT);
    }, [item]);

    const transitionHeight = {
      maxHeight: childrenLength,
    };

    useOnClickOutside(refBody, () => {
      setOpen(false);
    });

    return (
      <>
        <div className={classNames(style.dropdown)} ref={refBody}>
          <div className={style.head}>
            <NavigationItemHead item={item} clickHandler={clickHandler} />
          </div>

          <ul
            id={(firstElement && "dropdownBody") || ""}
            className={classNames(style.body, isOpen && style.active)}
            style={(isOpen && transitionHeight) || {}}
          >
            {item.items!.map((child) => {
              return child.items && child.items.length > 0 ? (
                <DropdownMenuHeader
                  key={child.url}
                  firstElement={false}
                  item={child}
                />
              ) : (
                <li
                  key={child.url}
                  role="menubar"
                  className={style.item}
                  onClick={() => setOpen(!isOpen)}
                  onKeyPress={(e) =>
                    e.key === "Enter" ? setOpen(!isOpen) : null
                  }
                  tabIndex={-1}
                >
                  <NavigationItemHead item={child} clickHandler={() => {}} />
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
);
