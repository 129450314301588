import { memo } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { CommentsList } from "src/FSD/widgets/v2/task/modals/Comments/ui/CommentsList";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";

interface Props {
  comments: ApiTaskResponses.Comment[];
}

export const CommentsObjectForm = memo(({ comments }: Props) => {
  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      <Modal.Form>
        <CommentsList comments={comments} />
      </Modal.Form>
    </Modal.Layout>
  );
});
