import React, { memo } from "react";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { selectorUserPageContentData } from "src/app/feature/userPage/userPage";
import { config } from "src/app.cofig";

type EmployeeType = {
  employee: {
    id: number;
    name: string;
    surname: string;
    patronymic: string;
    tested: boolean;
    photo: string;
  };
};

const Employee: React.FC<EmployeeType> = memo(({ employee }) => (
  <div className={style.employee}>
    <img src={`${config.localDomain}${employee.photo}`} alt="" />
    <TextLink
      url={`/user/${employee.id}?tab=info`}
      variant={style.employee__link}
    >
      {`${employee.surname} ${employee.name} ${employee.patronymic}`}
    </TextLink>
  </div>
));

type TeamProps = {
  team: {
    id: number;
    name: string;
    firm_id: number;
    firm: any;
    part_group_id: Record<string, string>;
    teamlead: EmployeeType["employee"];
    members: EmployeeType["employee"][];
  };
};

const Team = memo(({ team }: TeamProps) => (
  <div className={style.team}>
    <div className={style.team__info}>
      <h1>{team.name}</h1>
      {team.firm?.title && (
        <TextLink
          url={`/firm/${team.firm.id}?tab=staff`}
          variant={style.team__info__link}
        >
          {team.firm.title}
        </TextLink>
      )}
    </div>
    <div className={style.employees}>
      <Employee employee={team.teamlead} />
      {team.members.map((employee: EmployeeType["employee"]) => (
        <Employee employee={employee} />
      ))}
    </div>
  </div>
));

export const Teams: React.FC = memo(() => {
  const data = {
    content: useSelector(selectorUserPageContentData),
  }.content!.teams;

  return (
    <div className={style.wrapper}>
      {data.length > 0 ? (
        data.map((team: TeamProps["team"]) => <Team team={team} />)
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
});
