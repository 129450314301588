import { WorksHeader } from "./WorksHeader";
import { WorksGroupAccordion } from "./WorksGroupAccordion";
import { useProjectProcessWorks } from "../lib/hooks/useProjectProcessWorks";
import { Skeleton } from "../../../../../../shared/uiKit/v2/Skeleton";
import { ListMapper } from "../../../../../../shared/uiKit/v2/ListMapper/ListMapper";
import styles from "./WorksAccordion.module.scss";

export const Works = () => {
  const { fullCost, isLoading, groups } = useProjectProcessWorks();

  return (
    <>
      {isLoading ? (
        <Skeleton.List className={styles.skeleton} height={80} />
      ) : (
        <>
          <WorksHeader
            title="Задачи"
            fullCost={fullCost}
            isLoading={isLoading}
          />
          <ListMapper>
            {groups!.map(([userId, group]) => (
              <WorksGroupAccordion key={userId} userId={+userId} {...group} />
            ))}
          </ListMapper>
        </>
      )}
    </>
  );
};
