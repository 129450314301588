import React from "react";
import style from "./style.module.scss";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";

const CHECKED = "Проверенная";
const UNCHECKED = "Не проверенная";
const NO_DATA = "Нет данных";

interface OrganisationItemProps {
  id: number;
  role: string | null;
  title: string | null;
  inn: string | null;
  address: string | null;
  tested: boolean | null;
}

const OrganisationItem = ({
  id,
  role,
  inn,
  address,
  title,
  tested,
}: OrganisationItemProps) => {
  return (
    <div className={style.organisation}>
      <div className={style.header}>
        <TextLink variant={style.textLink} url={`/firm/${id}`}>
          {title ?? NO_DATA}
        </TextLink>
        <p>{tested ? CHECKED : UNCHECKED}</p>
      </div>
      <div className={style.flex}>
        <div className={style.section}>
          <h1 className={style.title}>Ваша роль</h1>
          <h1 className={style.text_small}>{role ?? NO_DATA}</h1>
        </div>

        <div className={style.section}>
          <h1 className={style.title}>ИНН</h1>
          <p className={style.text}>{inn ?? NO_DATA}</p>
        </div>

        <div className={style.section}>
          <h1 className={style.title}>Адрес</h1>
          <p className={style.text}>{address ?? NO_DATA}</p>
        </div>
      </div>
    </div>
  );
};

export default OrganisationItem;
