import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { indexComment, create } from "./queries";

export const commentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    commentIndex: indexComment(builder),
    commentCreate: create(builder),
  }),
  overrideExisting: false,
});

export const { useCommentIndexQuery, useCommentCreateMutation } = commentApi;
