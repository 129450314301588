import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import style from "../style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { VerticalThreeDots } from "../../../../../newUI/SVG";
import { StyledMenu } from "../../../../../newUI/Menu/Menu";
import { config } from "../../../../../../app.cofig";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import Modal from "../../../../../newUI/Modal/Modal";
import { PublishModalContent } from "../PublishModalContent/PublishModalContent";
import {
  fetchProjectSectionsTreeDuplicate,
  fetchUnPublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { UpdatePublishModalContent } from "../UpdatePublishModalContent/UpdatePublishModalContent";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { ModalBackToWork } from "../ModalBackToWork/ModalBackToWork";
import {
  setTouched,
  setOpen as setOpenGroupProcessPublishing,
} from "../../../../../../app/feature/project/projectProcessPublication";
import { setOpen as setOpenGroupProcessRequest } from "../../../../../../app/feature/project/projectProcessRequest";

type ThreeDotButtonsProps = {
  node: TValuesData;
};
const sx = { color: "white" };

export const ThreeDotButtons = ({ node }: ThreeDotButtonsProps) => {
  const isMobile = useIsMobile(680);
  const { isLoadPublish, isLoadUpdatePublish } =
    useSelector(getProjectSections);
  const [publishModal, setPublishModal] = useState(false);
  const [backToWorkModal, setBackToWorkModal] = useState(false);
  const [updatePublishModal, setUpdatePublishModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const memoCloseModal = useCallback(() => {
    setPublishModal(false);
    setUpdatePublishModal(false);
    setBackToWorkModal(false);
  }, []);

  const handleOpenPublishProcess = () => {
    dispatch(setOpenGroupProcessPublishing(node));
  };

  const handleOpenRequestProcess = () => {
    dispatch(setOpenGroupProcessRequest(node));
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setTouched(false)); // для групповой публикации
  };

  const handleNavigate = () => {
    window.open(`${config.localDomain}/project-process/${node.id}`, "_blank");
  };

  const unPublishClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );
    if (result) {
      await dispatch(fetchUnPublishProcess(node.id));
      await dispatch(fetchProjectSectionsTreeDuplicate(node.project_id));
    }
  };

  const canPublishGroup = Boolean(node.partGroup);

  const canRequestGroup = Boolean(node.partGroup) && node.can?.requestExecution;

  return (
    <div className={classNames(style.btnFlex)}>
      {node.can?.publish && node.projectPart.published && (
        <Modal
          active={publishModal}
          setActive={setPublishModal}
          isLoad={isLoadPublish}
        >
          <PublishModalContent node={node} closeModal={memoCloseModal} />
        </Modal>
      )}
      {node.can?.publish && !node.projectPart.published && (
        <Modal
          active={backToWorkModal}
          setActive={setBackToWorkModal}
          activeClass={style.overflowModal}
        >
          <ModalBackToWork node={node} closeModal={memoCloseModal} />
        </Modal>
      )}
      {node.can?.unpublish && (
        <Modal
          active={updatePublishModal}
          setActive={setUpdatePublishModal}
          isLoad={isLoadUpdatePublish}
        >
          <UpdatePublishModalContent node={node} closeModal={memoCloseModal} />
        </Modal>
      )}
      <CustomButton
        className={classNames(isMobile && style.btn)}
        onClick={handleNavigate}
        width={100}
        background={palette.blue}
      >
        Открыть
      </CustomButton>
      <IconButton
        sx={sx}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <VerticalThreeDots />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleNavigate();
          }}
          disableRipple
        >
          Перейти к разделу
        </MenuItem>
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              setUpdatePublishModal(true);
              handleClose();
            }}
            disableRipple
          >
            Редактировать
          </MenuItem>
        )}
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              unPublishClick();
              handleClose();
            }}
            disableRipple
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {node.can?.publish && node.projectPart.published && (
          <div>
            <MenuItem
              onClick={() => {
                setPublishModal(true);
                handleClose();
              }}
              disableRipple
            >
              Опубликовать
            </MenuItem>
            {canPublishGroup && (
              <MenuItem
                onClick={() => {
                  handleOpenPublishProcess();
                  handleClose();
                }}
                disableRipple
              >
                Опубликовать группу
              </MenuItem>
            )}
          </div>
        )}
        {canRequestGroup && (
          <MenuItem
            onClick={() => {
              handleOpenRequestProcess();
              handleClose();
            }}
            disableRipple
          >
            Заявиться на группу
          </MenuItem>
        )}
        {node.can?.publish &&
          !node.projectPart.published &&
          node.can?.expertiseTakeToWork && (
            <MenuItem
              onClick={() => {
                setBackToWorkModal(true);
                handleClose();
              }}
              disableRipple
            >
              Взять в работу
            </MenuItem>
          )}
      </StyledMenu>
    </div>
  );
};
