import { ReactNode } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./ProviderFirm.module.scss";

type TImageTitleProps = {
  title: string;
  photoUrl?: string;
  icon?: ReactNode;
};

export const ImageTitle = ({ photoUrl, title, icon }: TImageTitleProps) => (
  <div className={styles.blockImg}>
    {photoUrl ? (
      <img src={photoUrl} alt="Баннеры поставщиков материалов" />
    ) : (
      icon
    )}
    <Paragraph
      fontSize={18}
      color="light"
      className={styles.blockProvidesFirm__title}
    >
      {title}
    </Paragraph>
  </div>
);
