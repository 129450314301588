import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
}>;

export const pullRequest = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id }) => ({
      url: `v1/material-process/pull-request`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
    }),
    invalidatesTags: [ApiTags.MaterialProcess.INDEX],
  });
