import { Draft, PayloadAction } from "@reduxjs/toolkit";
import * as KanbanType from "../../types";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";

export const removeFreeTask = (
  state: Draft<KanbanType.State>,
  action: PayloadAction<any>
) => {
  const {
    groupBy: { id: groupById },
  } = state.data;

  const { taskId, status: columnId, partGroupId } = action.payload;

  let rowId: number | WorkFlowClasses = 0;

  if (groupById === "skill") {
    rowId = partGroupId;
  }

  if (groupById === "type") {
    rowId = WorkFlowClasses.FREE_TASK;
  }

  state.tasks.rowTasks[rowId].columns[columnId] = state.tasks.rowTasks[
    rowId
  ].columns[columnId].filter((task) => task.id !== taskId);
};
