import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import style from "./AddRights.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectUsersList } from "src/app/feature/ProjectView/AdminReducer/projectAdmin";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import {
  completeTask,
  getUserTypes,
} from "src/app/feature/dashboard/header/thunks";
import { TModalTaskComplete } from "../Admin";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";

const ERROR_MESSAGES = {
  USER_TYPE_REQUIRED: "Необходимо заполнить «Форма собственности»",
};

interface CompleteTaskProps {
  modalTaskComplete: TModalTaskComplete;
  setModalTaskComplete: SetState<TModalTaskComplete>;
  handleCloseModal: () => void;
}

type TCompleteTask = {
  userType: TSelectItem | null;
};

const CompleteTask = ({
  handleCloseModal,
  modalTaskComplete,
}: CompleteTaskProps) => {
  const [submissionStatus, setSubmissionStatus] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  const { defaultValues, userTypes, pending } =
    useAppSelector(getProjectUsersList);

  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<TCompleteTask>({
    defaultValues,
  });

  const onSubmit = async (data: TCompleteTask, status: number) => {
    if (status === Statuses.ACCEPT && !data.userType) {
      setError("userType", { message: ERROR_MESSAGES.USER_TYPE_REQUIRED });
      return;
    }

    clearErrors("userType");

    const formedData: Record<string, string | number> = {
      status,
      comment: "",
    };

    if (data!.userType?.id) {
      formedData.userTypeId = data!.userType.id;
    }

    setSubmissionStatus(status);
    await dispatch(
      completeTask({
        id: modalTaskComplete!.taskId,
        data: formedData,
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(getUserTypes());
  }, []);

  const isDisabled =
    Object.values(pending).some(Boolean) || pending.userTypes || isSubmitting;

  return (
    <form className={style.wrapper}>
      <p>Выберите свою форму собственности</p>
      <Controller
        name="userType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            isLoading={pending.userTypes}
            label="Форма собственности"
            options={userTypes}
            error={Boolean(errors.userType)}
            helperText={errors.userType?.message}
          />
        )}
      />
      <div className={style.submitControls}>
        <CustomButton
          className={style.submitButton}
          background={palette.red}
          width={120}
          disabled={isDisabled}
          forceDisabled={!isSubmitting || submissionStatus !== Statuses.REJECT}
          onClick={handleSubmit((data) => onSubmit(data, Statuses.REJECT))}
        >
          Отказать
        </CustomButton>
        <CustomButton
          className={style.submitButton}
          background={palette.blue}
          width={120}
          disabled={isDisabled}
          forceDisabled={!isSubmitting || submissionStatus !== Statuses.ACCEPT}
          onClick={handleSubmit((data) => onSubmit(data, Statuses.ACCEPT))}
        >
          Принять
        </CustomButton>
      </div>
    </form>
  );
};

export default CompleteTask;
