import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import PortfolioItem from "./PortfolioItem/PortfolioItem";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import { IBuildingOption } from "../../../../../app/feature/profileReducer/types";
import C from "../../../../../constants";
import style from "./style.module.scss";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { UnsavedType } from "../../../Project/types";
import { getErrorMessage } from "../../../../../app/services/api/requestHandler";

interface ComponentProps {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
}

export interface SubmitDataProps {
  name: string;
  description: string;
  buildingFunction: IBuildingOption;
  photo_upload: File[];
  upload_files: File[];
}

const Portfolio: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const {
    portfolioPageIsLoading,
    loadBuildingFunctions,
    buildingOptions,
    loadPortfolioList,
    portfolioList,
    createPortfolio,
    deletePortfolio,
    updatePortfolio,
  } = useProfile();

  useEffect(() => {
    if (buildingOptions.length === 0) {
      loadBuildingFunctions();
    }
  }, [buildingOptions]);

  useEffect(() => {
    loadPortfolioList(userId);
  }, [userId]);

  const { enqueueSnackbar } = useSnackbar();

  const onCreate = (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    createPortfolio({ userId, ...data })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, portfolio: false }));
        loadPortfolioList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onUpdate = (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    updatePortfolio({ ...data, id })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, portfolio: false }));
        loadPortfolioList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onDelete = (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!window.confirm(C.CONFIRM_DELETE)) {
      return;
    }

    setIsFetching(true);
    deletePortfolio(id)
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        loadPortfolioList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  return (
    <div className={style.wrapper}>
      {portfolioPageIsLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="500px"
        />
      ) : (
        <>
          <PortfolioItem
            buildingOptions={buildingOptions}
            onCreate={onCreate}
            setUnsaved={setUnsaved}
          />
          {portfolioList.map((portfolio: any) => {
            return (
              <PortfolioItem
                key={portfolio.id}
                buildingOptions={buildingOptions}
                onUpdate={onUpdate}
                onDelete={onDelete}
                portfolio={portfolio.portfolio}
                setUnsaved={setUnsaved}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Portfolio;
