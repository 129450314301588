import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  createSourceComment,
  deleteSourceComment,
  editSourceComment,
  fetchGetUsersToAssign,
  fetchGetUsersTypeToAssign,
  fetchProjectSectionsTree,
  fetchProjectSectionsTreeDuplicate,
  fetchPublishInfo,
  fetchPublishProcess,
  fetchSourceComments,
  fetchUnPublishProcess,
  fetchUpdatePublishProcess,
  IPublishInfoReturn,
  TValuesData,
} from "./thunks";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { toSelect } from "../../project/projectProcessPublication";
import {
  collectNums,
  setAllToggleNode,
  setToggleNode,
} from "src/utils/helpersOpenNode";

export type TSourceComment = {
  id: number;
  date_limit: string;
  status: number;
  comment: string;
  can: Record<string, boolean>;
};

type TInitialStateProjectView = {
  sections: TValuesData[] | null;
  isLoad: boolean;
  error: any;
  error422: any;
  isLoadExecutor: boolean;
  executorsToArray: [string, string][];
  executors: TSelectItem[];
  isLoadExecutorType: boolean;
  typeExecutorsToArray: [string, string][];
  TypeExecutors: TSelectItem[];
  update: boolean;
  publish: boolean;
  isLoadPublish: boolean;
  isLoadUpdatePublish: boolean;
  isLoadPublishInfo: boolean;
  publishInfo: IPublishInfoReturn;
  pending: {
    executors: boolean;
    TypeExecutors: boolean;
    duplicateTree: boolean;
    creatingSourceComment: boolean;
    editingSourceComment: boolean;
    deletingSourceComment: boolean;
    getSourceComments: number[];
  };
  openNode: any;
  sourceComments: Record<string, TSourceComment[]>;
  isAllToggle: boolean;
};
const initialState: TInitialStateProjectView = {
  sections: null,
  isLoad: false,
  error: null,
  error422: null,
  isLoadExecutor: false,
  executors: [],
  executorsToArray: [],
  isLoadExecutorType: false,
  typeExecutorsToArray: [],
  TypeExecutors: [],
  update: false,
  publish: false,
  isLoadPublish: false,
  isLoadUpdatePublish: false,
  sourceComments: {},
  publishInfo: {
    canNoSecure: true,
    pay1: true,
    pay2: true,
    pay3: false,
    secureMissingAttributes: [],
    vor: true,
    vor_pnr: true,
    kac: true,
  },
  isLoadPublishInfo: false,
  pending: {
    executors: false,
    TypeExecutors: false,
    duplicateTree: false,
    deletingSourceComment: false,
    creatingSourceComment: false,
    editingSourceComment: false,
    getSourceComments: [],
  },
  openNode: {},
  isAllToggle: false,
};

const projectSectionsSlice = createSlice({
  name: "projectSectionsSlice",
  initialState,
  reducers: {
    setErrorNull(state) {
      state.error = null;
    },
    setOpenNode: (state, action) => {
      setToggleNode(state.openNode, action.payload);
    },
    setOpenAllNode: (state) => {
      setAllToggleNode(state);
    },
    setPublishNull(state) {
      state.publish = false;
      state.update = false;
    },
    clearSourceComments(state, action) {
      const sourceDataId = action.payload;

      delete state.sourceComments[sourceDataId];
      state.pending.getSourceComments = state.pending.getSourceComments.filter(
        (id) => id !== sourceDataId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectSectionsTree.pending, (state) => {
      state.isLoad = true;
      state.error = null;
    });
    builder.addCase(fetchProjectSectionsTree.fulfilled, (state, action) => {
      state.isLoad = false;
      state.sections = Object.values(action.payload);
      state.openNode = collectNums(action.payload);
    });
    builder.addCase(fetchProjectSectionsTree.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectSectionsTreeDuplicate.pending, (state) => {
      state.error = null;
      state.pending.duplicateTree = true;
    });
    builder.addCase(
      fetchProjectSectionsTreeDuplicate.fulfilled,
      (state, action) => {
        state.sections = Object.values(action.payload);
        state.publish = false;
        state.update = false;
        state.pending.duplicateTree = false;
      }
    );
    builder.addCase(
      fetchProjectSectionsTreeDuplicate.rejected,
      (state, action) => {
        state.error = action.payload;
        state.pending.duplicateTree = false;
      }
    );
    builder.addCase(fetchPublishProcess.pending, (state) => {
      state.isLoadPublish = true;
      state.error = null;
      state.error422 = null;
      state.publish = false;
    });
    builder.addCase(fetchPublishProcess.fulfilled, (state) => {
      state.publish = true;
      state.error422 = null;
      state.error = null;
      state.isLoadPublish = false;
    });
    builder.addCase(fetchPublishProcess.rejected, (state, action) => {
      state.isLoadPublish = false;
      if (action?.payload?.status === "none") {
        state.error = action.payload.message;
      }
      if (action?.payload?.status === "422") {
        state.error422 = action.payload.message;
      }
    });
    builder.addCase(fetchUnPublishProcess.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchUnPublishProcess.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchUpdatePublishProcess.pending, (state) => {
      state.isLoadUpdatePublish = true;
      state.error = null;
      state.update = false;
      state.error422 = null;
    });
    builder.addCase(fetchUpdatePublishProcess.fulfilled, (state) => {
      state.update = true;
      state.isLoadUpdatePublish = false;
    });
    builder.addCase(fetchUpdatePublishProcess.rejected, (state, action) => {
      state.isLoadUpdatePublish = false;
      if (action?.payload?.status === "none") {
        state.error = action.payload.message;
      }
      if (action?.payload?.status === "422") {
        state.error422 = action.payload.message;
      }
    });
    builder.addCase(fetchGetUsersToAssign.pending, (state) => {
      state.isLoadExecutor = true;

      state.pending.executors = true;
      state.error = null;
    });
    builder.addCase(fetchGetUsersToAssign.fulfilled, (state, action) => {
      state.isLoadExecutor = false;
      state.executorsToArray = Object.entries(action.payload);
      state.pending.executors = false;
      state.executors = toSelect(action.payload);
    });
    builder.addCase(fetchGetUsersToAssign.rejected, (state, action) => {
      state.isLoadExecutor = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGetUsersTypeToAssign.pending, (state) => {
      state.isLoadExecutorType = true;

      state.pending.TypeExecutors = true;
      state.error = null;
    });
    builder.addCase(fetchGetUsersTypeToAssign.fulfilled, (state, action) => {
      state.isLoadExecutorType = false;
      state.typeExecutorsToArray = Object.entries(action.payload);
      state.pending.TypeExecutors = false;
      state.TypeExecutors = toSelect(action.payload);
    });
    builder.addCase(fetchGetUsersTypeToAssign.rejected, (state, action) => {
      state.isLoadExecutorType = false;
      state.error = action.payload;
    });
    builder.addCase(fetchPublishInfo.pending, (state) => {
      state.isLoadPublishInfo = true;
      state.error = null;
    });
    builder.addCase(fetchPublishInfo.fulfilled, (state, action) => {
      state.publishInfo = action.payload;
      state.isLoadPublishInfo = false;
    });
    builder.addCase(fetchPublishInfo.rejected, (state, action) => {
      state.isLoadPublishInfo = false;
      state.error = action.payload;
    });
    builder.addCase(fetchSourceComments.pending, (state, action) => {
      const sourceDataId = action.meta.arg;
      state.pending.getSourceComments.push(sourceDataId);

      state.error = null;
    });
    builder.addCase(fetchSourceComments.fulfilled, (state, action) => {
      const sourceDataId = action.meta.arg;

      state.pending.getSourceComments = state.pending.getSourceComments.filter(
        (id) => id !== sourceDataId
      );

      state.sourceComments[sourceDataId] = action.payload;
    });
    builder.addCase(fetchSourceComments.rejected, (state, action) => {
      const sourceDataId = action.meta.arg;

      state.pending.getSourceComments = state.pending.getSourceComments.filter(
        (id) => id !== sourceDataId
      );
      state.error = action.payload;
    });
    builder.addCase(deleteSourceComment.pending, (state) => {
      state.pending.deletingSourceComment = true;
      state.error = null;
    });
    builder.addCase(deleteSourceComment.fulfilled, (state) => {
      state.pending.deletingSourceComment = false;
    });
    builder.addCase(deleteSourceComment.rejected, (state, action) => {
      state.pending.deletingSourceComment = false;
      state.error = action.payload;
    });
    builder.addCase(createSourceComment.pending, (state) => {
      state.pending.creatingSourceComment = true;
      state.error = null;
    });
    builder.addCase(createSourceComment.fulfilled, (state) => {
      state.pending.creatingSourceComment = false;
    });
    builder.addCase(createSourceComment.rejected, (state, action) => {
      state.pending.creatingSourceComment = false;
      state.error = action.payload;
    });
    builder.addCase(editSourceComment.pending, (state) => {
      state.pending.editingSourceComment = true;
      state.error = null;
    });
    builder.addCase(editSourceComment.fulfilled, (state) => {
      state.pending.editingSourceComment = false;
    });
    builder.addCase(editSourceComment.rejected, (state, action) => {
      state.pending.editingSourceComment = false;
      state.error = action.payload;
    });
  },
});

export const {
  setErrorNull,
  setOpenAllNode,
  setOpenNode,
  setPublishNull,
  clearSourceComments,
} = projectSectionsSlice.actions;
export const getProjectSections = (state: RootState) => state.projectSections;
export const getUpdate = (state: RootState) => state.projectSections.update;
export const getIsLoadUpdate = (state: RootState) =>
  state.projectSections.isLoadUpdatePublish;
export const getIsLoadPublish = (state: RootState) =>
  state.projectSections.isLoadPublish;
export const getPublishState = (state: RootState) =>
  state.projectSections.publish;
export const getSourceComments = (state: RootState) =>
  state.projectSections.sourceComments;
export default projectSectionsSlice.reducer;
