import { CircularProgress } from "@mui/material";
import cn from "classnames";
import styles from "./styles.module.scss";
import { useUploader } from "../lib";
import File from "./File";
import { NewUploaderProps, TFile } from "../model/types";
import { Dropzone } from "./Dropzone";
import { ScrollWrap } from "../../../../../../components/newUI/ScrollWrap/ScrollWrap";
import { Paragraph } from "../../Paragraph";

/*

canEdit: Если true => отображаются кнопки удалить и/или галочка "Активный",

postUrl: URL для отправки файлов на сервер (/v1/...). Необходим в случае, если autoUpload = true.

autoUpload: Автоматическая отправка файлов

initialFiles: Начальный массив с файлами

uploadAsActive: Ставит галочку "Активный" по умолчанию

getDataCallback: Асинхронная функция, в которой:
  i: Выполняется запрос
  ii: Происходит извлечение массива с файлами из данного запроса (имеет тот же тип данных, что и initialFiles)

*/

export const Uploader = ({
  canEdit = true,
  postUrl = "",
  autoUpload = false,
  initialFiles = [],
  uploadAsActive = true,
  getDataCallback,
  setFiles,
  error = false,
  helperText = "",
  handleUpdate = () => {},
  stretch = false,
  weakStretch = false,
  className,
}: NewUploaderProps) => {
  const {
    isLoading,
    handleUpload,
    handleDelete,
    handleChangeActive,
    files,
    progresses,
  } = useUploader({
    handleUpdate,
    initialFiles,
    autoUpload,
    postUrl,
    getDataCallback,
    uploadAsActive,
    setFilesCallback: setFiles,
  });

  const FilesError = () => (
    <p className={styles.filesError}>{String(helperText ?? "")}</p>
  );

  const NoFiles = () => <Paragraph>Файлы отсутствуют</Paragraph>;

  const Preloader = () => (
    <div className={styles.centered}>
      <CircularProgress sx={{ color: "#0aa699" }} />
    </div>
  );

  return (
    <div className={cn(styles.uploader, className)}>
      {canEdit && <Dropzone handleUpload={handleUpload} />}
      {isLoading ? (
        <Preloader />
      ) : files.length ? (
        <ScrollWrap maxHeight="350px">
          <div
            className={cn(
              styles.filesList,
              stretch && styles.filesList_stretch,
              weakStretch && styles.filesList_weakStretch
            )}
          >
            {files.map((file: TFile) => (
              <File
                key={file.id}
                canEdit={canEdit}
                handleChangeActive={handleChangeActive}
                handleDelete={handleDelete}
                progresses={progresses}
                {...file}
              />
            ))}
          </div>
        </ScrollWrap>
      ) : (
        <NoFiles />
      )}
      {error && <FilesError />}
    </div>
  );
};
