import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { SelectWithPopper } from "../../../UI/components/FormComponentns/SelectWithPaper/SelectWithPaper";
import { ApiGetRegionsOfCountry } from "../../../../app/services/api/countries/countries";
import { createPaginationMeta } from "../../../../app/feature/indexPages/createData";
import style from "./style.module.scss";
import { request } from "../../../../app/services/api/requestHandler";

export interface RegionsTypeItem {
  title: string;
  id: number;
}

interface RegionsDataType {
  code: number;
  country_code: string;
  title: string;
  country: {
    code: string;
    title: string;
    title_full: string;
  };
}

export type RegionsTypeProps = ComponentPropsType<{
  id?: string;
  name?: string;
  changeHandler?: (type: RegionsTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
  paperOptions?: Record<string, any>;
  label?: string;
  countryCode?: string;
  paginationCount?: number;
  error?: boolean;
  blurHandler?: (event: any) => void;
  helperText?: string;
  disabled?: boolean;
}>;

export const Regions: React.FC<RegionsTypeProps> = ({
  id,
  name,
  variant = "",
  changeHandler = () => {},
  blurHandler,
  value,
  paperOptions,
  label = "",
  countryCode = "",
  error,
  helperText,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [paginationCount, setPaginationCount] = useState(0);
  const [allTypes, setAllTypes] = useState<RegionsTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<RegionsTypeItem | undefined>(
    undefined
  );

  useEffect(() => {
    if (countryCode) {
      request(
        ApiGetRegionsOfCountry(`${countryCode}`),
        (_, __, headersValue) => {
          const meta = createPaginationMeta(headersValue);
          setPaginationCount(meta.paginationPageCount);
        }
      )();
    }
  }, [countryCode]);

  const onLoadTypes = () => {
    for (let i = 1; i <= paginationCount; i++) {
      request(
        ApiGetRegionsOfCountry(`${countryCode}`, i),
        (data) => {
          setAllTypes((allTypes) => [
            ...allTypes,
            ...data.map((item: RegionsDataType) => ({
              title: item.title,
              id: item.code,
            })),
          ]);
        },
        () => () => {
          enqueueSnackbar("Ошибка запроса регионов", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      )(dispatch);
    }
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    onLoadTypes();
    setAllTypes([]);
    setPaginationCount(0);
  }, [countryCode, paginationCount]);

  useEffect(() => {
    const result = allTypes.find((item) => String(item.id) === String(value));
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  useEffect(() => {
    if (allTypes.length && !value) {
      setSelectValue(allTypes[0]);
      changeHandler(allTypes[0]);
    }
  }, [allTypes]);

  return (
    <div>
      <SelectWithPopper
        id={id}
        name={name}
        changeHandler={(event, value) => {
          changeHandler(value);
        }}
        key={selectedValue ? selectedValue.title : "noneSpec"}
        value={selectedValue}
        searchHandler={onSearch}
        options={allTypes}
        label={label}
        variant={classNames(variant)}
        disableClear={true}
        fullWidth={true}
        paperOptions={paperOptions}
        blurHandler={blurHandler}
        error={error}
        disabled={disabled}
      />
      {helperText && <p className={style.helperText}>{helperText}</p>}
    </div>
  );
};
