import React, { memo, useEffect, useRef } from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getProjectProcessUserTypes,
  getProjectProcessUserTypesIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getUserTypesProjectProcess";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import {
  getProjectProcessExecutorBank,
  getProjectProcessExecutorBankIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessExecutorBank";
import { getUserId } from "../../../../../../app/services/auth/auth";
import { fetchProjectProcessBank } from "../../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessBank";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { fetchUserTypes } from "../../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchUserTypes";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../../styles/restyle";
import cls from "./ModalUpdateRequest.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { fetchProjectProcessUpdateRequest } from "../../../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessUpdateRequest";
import {
  getApplicationsRequestUpdate,
  getApplicationsRequestUpdateIsError422,
} from "../../../../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsRequestUpdate";
import { fetchProjectProcessApplications } from "../../../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import { setNullRequestUpdate } from "../../../../../../app/feature/ProjectProcessView/Applications/slice/projectProcessApplications";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

interface Inputs {
  content: string;
  date_start: any;
  date_limit: any;
  price: string | number;
  executor_type: any;
  bank_account_id: number | null;
}

const schema = Yup.object().shape({
  content: Yup.string().max(
    200,
    "Содержание заявки не может быть больше 200 символов"
  ),
  price: Yup.number()
    .required("Это поле обязательно для заполнения")
    .min(1000, `Стоимость не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость не может быть больше 10 млн рублей!`)
    .typeError("Значение «Cтоимость» должно быть числом."),
  executor_type: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
});

interface ModalRequestProps {
  closeModal: () => void;
  price: number;
  type: string;
  dateStart: string;
  dateLimit: string;
  bankId: number;
  taskId: number;
  content: string;
}

export const ModalUpdateRequest = memo(
  ({
    closeModal,
    price,
    dateStart,
    dateLimit,
    type,
    taskId,
    content,
  }: ModalRequestProps) => {
    const dispatch = useAppDispatch();
    const infoData = useAppSelector(getProjectProcessInfoData);
    const isLoadUserTypes = useAppSelector(getProjectProcessUserTypesIsLoad);
    const requestUpdateSuccess = useAppSelector(getApplicationsRequestUpdate);
    const userTypes = useAppSelector(getProjectProcessUserTypes);
    const executorBank = useAppSelector(getProjectProcessExecutorBank);
    const name = useAppSelector(getProjectProcessInfoName);
    const error422 = useAppSelector(getApplicationsRequestUpdateIsError422);
    const infoId = useAppSelector(getProjectProcessInfoId);
    const isLoadExecutorBank = useAppSelector(
      getProjectProcessExecutorBankIsLoad
    );
    const isFirstRender = useRef(true);

    useEffect(() => {
      isFirstRender.current = false;
    }, []);

    const userId = getUserId();
    const startDate = dateStart
      ? moment(dateStart, "DD.MM.YYYY").toDate()
      : null;
    const endDate = dateLimit ? moment(dateLimit, "DD.MM.YYYY").toDate() : null;

    const {
      register,
      handleSubmit,
      control,
      setValue,
      watch,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        price,
        date_start: startDate,
        date_limit: endDate,
        executor_type: type,
        bank_account_id: null,
      },
    });

    const executorTypeValue = watch("executor_type");
    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        UpdateForm: {
          content: dataValues.content,
          price: +dataValues.price,
          executor_type: +dataValues.executor_type,
          date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
            "DD.MM.YYYY"
          ),
          date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
            "DD.MM.YYYY"
          ),
          bank_account_id:
            dataValues.bank_account_id === -1
              ? null
              : dataValues.bank_account_id,
        },
      };
      if (taskId) {
        await dispatch(fetchProjectProcessUpdateRequest({ taskId, data }));
      }
    };

    useEffect(() => {
      if (executorBank) {
        setValue("bank_account_id", executorBank[0].id);
      }
    }, [executorBank, setValue]);

    useEffect(() => {
      if (executorTypeValue) {
        dispatch(fetchProjectProcessBank(executorTypeValue));
        if (!isFirstRender) {
          setValue("bank_account_id", null);
        }
      }
    }, [executorTypeValue]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      if (userId) {
        dispatch(fetchUserTypes(userId));
      }
    }, [userId]);

    useEffect(() => {
      if (requestUpdateSuccess) {
        clearErrors();
        dispatch(setNullRequestUpdate());
        if (infoId) {
          dispatch(fetchProjectProcessApplications(infoId));
        }
        closeModal();
      }
    }, [requestUpdateSuccess]);

    if (isLoadUserTypes) {
      return <ModalPreloader />;
    }

    const bankAccountId = watch("bank_account_id");

    return (
      <div>
        <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
          <h1>{`Заявка на исполнение раздела «${name}» по проекту «${infoData?.project?.name}»`}</h1>
          <TextField
            {...register("price")}
            {...textFieldSX}
            label="Стоимость"
            defaultValue={price || ""}
            error={!!errors.price}
            helperText={errors.price ? errors.price.message : null}
          />
          <div className={cls.form_date_start}>
            <Controller
              control={control}
              name="date_start"
              render={() => (
                <DateField
                  variant={cls.formElement}
                  label="Начальный срок"
                  changeDateHandler={(date) => {
                    setValue("date_start", date);
                  }}
                  startDateProp={startDate}
                  error={!!errors.date_start}
                />
              )}
            />
            {errors.date_start && (
              <p className={cls.error}>{errors.date_start.message}</p>
            )}
          </div>
          <div className={cls.form_date_end}>
            <Controller
              control={control}
              name="date_limit"
              render={() => (
                <DateField
                  variant={cls.formElement}
                  label="Конечный срок"
                  changeDateHandler={(date) => {
                    setValue("date_limit", date);
                  }}
                  startDateProp={endDate}
                  error={!!errors.date_limit}
                />
              )}
            />
            {errors.date_limit && (
              <p className={cls.error}>{errors.date_limit.message}</p>
            )}
          </div>
          <FormControl {...textFieldSX}>
            <InputLabel id="executor_type">Форма собственности</InputLabel>
            <Select
              {...register("executor_type")}
              labelId="executor_type"
              label="Форма собственности"
              defaultValue={type}
              error={!!errors.executor_type}
            >
              {userTypes?.map((type: any) => (
                <MenuItem sx={menuItemSx} value={type.id} key={type.id}>
                  {type.listName}
                </MenuItem>
              ))}
            </Select>
            {errors.executor_type ? (
              <p className={cls.error}>{errors.executor_type.message}</p>
            ) : null}
          </FormControl>
          <FormControl {...textFieldSX}>
            <InputLabel id="bank_account_id">Банковский счет</InputLabel>
            <Select
              {...register("bank_account_id")}
              labelId="bank_account_id"
              label="Банковский счет"
              value={bankAccountId}
              error={!!errors.bank_account_id}
              disabled={!executorBank || isLoadExecutorBank}
            >
              {executorBank?.map((bank: any) => (
                <MenuItem sx={menuItemSx} value={bank.id} key={bank.id}>
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
            {errors.bank_account_id ? (
              <p className={cls.error}>{errors.bank_account_id.message}</p>
            ) : null}
          </FormControl>
          <TextField
            {...textFieldSX}
            multiline
            minRows={2}
            label="Содержание заявки"
            {...register("content")}
            placeholder="Введите текст"
            error={!!errors.content}
            defaultValue={content}
            helperText={errors.content ? errors.content.message : null}
          />
          <CustomButton
            className={cls.form_submit}
            background={palette.blue}
            width={160}
            type="submit"
          >
            Отправить
          </CustomButton>
        </form>
      </div>
    );
  }
);
