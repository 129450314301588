import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessInitialData } from "../services/fetchProjectProcessInitialData";
import {
  ProcessesType,
  IProjectsValue,
  SourceDataType,
  ProjectProcessSourceDataChild,
} from "../types/projectProcessInitialDataTypes";
import {
  fetchProjectProcessSourceData,
  fetchProjectProcessSourceDataDuplicate,
} from "../services/fetchProjectProcessSourceData";
import { fetchProjectProcessChecklist } from "../services/fetchProjectProcessChecklist";
import { TProjectProcessCheckList } from "../types/projectProcessCheckList";
import { fetchProjectProcessSourceDataUpdate } from "../services/fetchProjectProcessSourceDataUpdate";
import formSourceData from "../utils/formSourceData";

interface InitialState {
  isLoad: boolean;
  isLoadSourceChild: boolean;
  isLoadCheckList: boolean;
  isLoadUpdateSourceData: boolean;
  process: ProcessesType | undefined;
  projects: [string, IProjectsValue][] | undefined;
  sourceData: Record<string, SourceDataType[]>;
  sourceChild: ProjectProcessSourceDataChild[][];
  checkList: TProjectProcessCheckList[] | undefined;
  openSourceData: any;
}

const initialState: InitialState = {
  isLoad: false,
  isLoadSourceChild: false,
  isLoadCheckList: false,
  isLoadUpdateSourceData: false,
  process: undefined,
  projects: undefined,
  sourceData: {},
  sourceChild: [],
  checkList: undefined,
  openSourceData: {},
};

const projectProcessInitialData = createSlice({
  name: "projectProcessInitialData",
  initialState,
  reducers: {
    setOpenSourceData: (state, action) => {
      const index = action.payload;
      if (state.openSourceData[index] !== undefined) {
        state.openSourceData[index] = undefined;
      } else {
        state.openSourceData[index] = index;
      }
    },
    clearOpenSourceData: (state) => {
      state.openSourceData = {};
      state.sourceChild = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessInitialData.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(
      fetchProjectProcessInitialData.fulfilled,
      (state, action) => {
        state.isLoad = false;
        state.process = action.payload?.process;
        state.projects = Object.entries(action.payload?.projects);
        state.sourceData = formSourceData(action.payload?.newSourceData ?? {});
        // state.sourceChild = new Array(action.payload.sourceData?.length);
      }
    );
    builder.addCase(fetchProjectProcessSourceData.pending, (state) => {
      state.isLoadSourceChild = true;
    });
    builder.addCase(
      fetchProjectProcessSourceData.fulfilled,
      (state, action) => {
        state.isLoadSourceChild = false;
        const newArr = [...state.sourceChild];
        newArr.splice(action.payload.index, 1, action.payload.item);
        state.sourceChild = newArr;
      }
    );
    builder.addCase(
      fetchProjectProcessSourceDataDuplicate.fulfilled,
      (state, action) => {
        const newArr = [...state.sourceChild];
        newArr.splice(action.payload.index, 1, action.payload.item);
        state.sourceChild = newArr;
      }
    );

    builder.addCase(fetchProjectProcessSourceDataUpdate.pending, (state) => {
      state.isLoadUpdateSourceData = true;
    });
    builder.addCase(fetchProjectProcessSourceDataUpdate.fulfilled, (state) => {
      state.isLoadUpdateSourceData = false;
    });
    builder.addCase(fetchProjectProcessChecklist.pending, (state) => {
      state.isLoadCheckList = true;
    });
    builder.addCase(fetchProjectProcessChecklist.fulfilled, (state, action) => {
      state.isLoadCheckList = false;
      state.checkList = action.payload;
    });
  },
});

export const { setOpenSourceData, clearOpenSourceData } =
  projectProcessInitialData.actions;
export default projectProcessInitialData;
