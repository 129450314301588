import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { responseAgreement } from "src/FSD/features/v2/SelectAgreement";
import { useModalContext } from "src/FSD/shared/uiKit/v2";
import { useProjectProcessGroupPublishMutation } from "src/FSD/entities/projectProcess/api";
import { Props } from "../../ui/GroupPublicationForm/PublicationForm";
import { TPublicationFieldValues } from "../../model/types/fieldValues";
import { publicationValidationSchema } from "../../model/schemas/publicationSchema";
import { createPublicationFormData } from "../helpers/createPublicationFormData";
import { useAppDispatch } from "../../../../../../../../app/store";
import { fetchProjectSectionsTreeDuplicate } from "../../../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { useParams } from "react-router-dom";

export type TSubmitAction = "back" | "draft" | "publishing";

const publicationDefaultValues = {
  secure: responseAgreement[0],
  withDocs: responseAgreement[0],
  vor: false,
  kac: false,
  vorPnr: false,
  bim: false,
  executorType: [],
  executorByAdmin: null,
  executorTypeByAdmin: null,
};

export const usePublicationForm = ({
  processId,
  setCurrentPage,
  formCache,
  setFormCache,
}: Props) => {
  // временно, пока "проект" не переписан на RTK Query
  const params = useParams();

  const dispatch = useAppDispatch();

  const { handleClose } = useModalContext();

  const [groupPublish] = useProjectProcessGroupPublishMutation();

  const defaultValues = useMemo(() => {
    if (formCache.publication) {
      return formCache.publication as TPublicationFieldValues;
    }

    return publicationDefaultValues;
  }, []);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<TPublicationFieldValues>({
    defaultValues,
    resolver: yupResolver(publicationValidationSchema),
  });

  const showExecutorAssignment = watch("showExecutorAssignment");

  const onSubmit = async (
    dataValues: TPublicationFieldValues,
    action: TSubmitAction
  ) => {
    if (action === "back") {
      setFormCache({
        ...formCache,
        publication: dataValues,
      });

      setCurrentPage((prev) => prev - 1);
      return;
    }

    const formedData = createPublicationFormData(dataValues, formCache, action);

    const response = await groupPublish({
      id: processId,
      data: formedData,
    });

    // @ts-ignore
    if (!response?.error && params.id) {
      handleClose();
      // временно, пока "проект" не переписан на RTK Query
      dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
    }
  };

  return {
    control,
    showExecutorAssignment,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    isSubmitting,
  } as const;
};
