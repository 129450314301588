import { TDot } from "src/FSD/shared/lib/types/dot";
import { palette } from "src/styles/restyle";

export const dotColors = (dot?: TDot) => {
  switch (dot) {
    case "error":
      return palette.bordeaux;
    case "success":
      return palette.persianGreen;
    case true:
    case "warning":
      return palette.warning;
    default:
      return palette.persianGreen;
  }
};
