import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { mapTask } from "../mappers/mapTask";
import { Nullable } from "src/types/generics";
import { useAppDispatch } from "src/app/store";
import { checkFreeTaskSchema } from "../schemas/checkFreeTask";

type TFieldValues = {
  comment: string;
  status: Nullable<Statuses>;
};

const defaultValues = {
  comment: "",
  status: null,
};

export const useCheckFreeTask = (taskId: number) => {
  const dispatch = useAppDispatch();

  const { handleClose } = useModalContext();

  const { data: taskRaw, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["project"],
  });

  const task = useDataMapper(taskRaw, mapTask);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      comment: data.comment,
      status: data.status!,
    };

    await dispatch(
      TaskApi.complete({
        data: {
          id: taskId,
          formedData,
        },
        events: {
          onFulfilled: () => {
            handleClose();
          },
        },
      })
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(checkFreeTaskSchema),
  });

  const status = watch("status");

  return {
    task,
    register,
    setValue,
    errors,
    handleSubmit,
    isTaskViewPending: isFetching && !task,
    isAccepting: status === Statuses.ACCEPT && isSubmitting,
    isRejecting: status === Statuses.IS_REJECT && isSubmitting,
    onSubmit,
  } as const;
};
