import { FC, useState, useEffect } from "react";
import cn from "classnames";
import { Skeleton } from "@mui/material";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import style from "./style.module.scss";
import ProcessesList, { TPart } from "./ProcessesList";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getMilestone,
  setOpenGroups,
} from "../../../../../app/feature/milestone";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import ChooseProcess from "./modals/ChooseProcess";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";

export const SkeletonGroup = () => (
  <div className={cn(style.wrapper, style.paneWrapper)}>
    <Skeleton className={style.skltn} />
    <Skeleton className={style.skltn} />
    <Skeleton className={style.skltn} />
    <Skeleton className={style.skltn} />
    <Skeleton className={style.skltn} />
  </div>
);

interface SetupProps {
  firmId: number;
  bfType: number;
}

const Setup: FC<SetupProps> = ({ bfType, firmId }) => {
  const dispatch = useAppDispatch();
  const [showChooseProcess, setShowChooseProcess] = useState<boolean>(false);
  const [milestoneId, setMilestoneId] = useState<number | null>(null);
  const [partType, setPartType] = useState<number | null>(null);
  const [groupId, setGroupId] = useState<number | null>(null);
  const [processes, setProcesses] = useState<Record<string, TSelectItem[]>>({
    value: [],
    options: [],
  });

  const { data, pending } = useAppSelector(getMilestone);
  const { groups, openGroups } = data;

  const handleUpdateToggle = (id: number) => {
    dispatch(setOpenGroups(id));
  };

  const handleAddProcess = (
    id: number,
    parts: TPart[],
    groupParts: TPart[],
    type: number,
    groupId: number
  ) => {
    const value = parts.map(({ id, code: title }: TPart) => ({
      id,
      title,
    }));

    const options = Array.from(new Set([...parts, ...groupParts])).map(
      ({ id, code: title }: TPart) => ({
        id,
        title,
      })
    );

    setMilestoneId(id);
    setPartType(type);
    setGroupId(groupId);
    setProcesses({ options, value });

    setShowChooseProcess(true);
  };

  const handleCloseModal = () => {
    setShowChooseProcess(false);
  };

  useEffect(() => {
    if (pending.editLinks) {
      return;
    }

    handleCloseModal();
  }, [pending.editLinks]);

  if (pending.groups) {
    return <SkeletonGroup />;
  }

  return (
    <div className={cn(style.wrapper, style.paneWrapper)}>
      {groups.map(({ group, types }: any) => {
        const isOpen = openGroups.includes(group.id);

        return (
          <>
            <Accordion
              onToggle={() => handleUpdateToggle(group.id)}
              toogle={isOpen}
              text={group.title}
              light
            />
            {isOpen && (
              <div className={style.list}>
                {Object.values(types).map(({ type, parts, groups }: any) => (
                  <>
                    <h2>{type.title}</h2>
                    <ProcessesList
                      bfType={bfType}
                      firmId={firmId}
                      groups={groups}
                      parts={parts}
                      partType={type.id}
                      groupId={group.id}
                      handleAddProcess={handleAddProcess}
                    />
                  </>
                ))}
              </div>
            )}
          </>
        );
      })}
      <ModalWrapper
        show={showChooseProcess}
        handleCloseModal={handleCloseModal}
      >
        <ChooseProcess
          id={milestoneId}
          groupId={groupId}
          partType={partType}
          processes={processes}
        />
      </ModalWrapper>
    </div>
  );
};

export default Setup;
