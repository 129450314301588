import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import {
  fetchProjectProcessView,
  fetchProjectProcessViewDuplicate,
  fetchProjectProcessViewInfoIsLoadDuplicate,
} from "../services/fetchProjectProcessView";
import {
  ProjectProcessInfoTypes,
  TabsType,
} from "../types/projectProcessInfoTypes";
import { ApiPrimaryFileType } from "../../../../../types/api/primaryTypes/apiPrimaryFileType";
import { fetchProjectProcessFeedbackExecutor } from "../services/fetchProjectProcessFeedbackExecutor";
import { config } from "../../../../../app.cofig";
import { fetchProjectProcessUsers } from "../services/fetchProjectProcessUsers";
import { fetchProjectProcessUsersTypes } from "../services/fetchProjectProcessUsersTypes";
import { fetchProjectProcessUsersUpdate } from "../services/fetchProjectProcessUsersUpdate";
import { fetchProjectProcessUpdatePrice } from "../services/fetchProjectProcessUpdatePrice";
import {
  fetchProjectProcessUpdateAdditional,
  fetchProjectProcessUpdateAdditionalWork,
} from "../services/fetchProjectProcessUpdateAdditional";
import { ITaskCan } from "../../../../../types/api/primaryTypes/apiPrimaryTaskType";
import { ApiPrimaryProjectType } from "../../../../../types/api/primaryTypes/apiPrimaryProjectType";
import { ApiProjectProcessResponses } from "../../../../../FSD/entities/projectProcess/model/responseTypes";
import { ApiCommonResponses } from "../../../../../FSD/entities/apiCommonResponses";

interface InitialState {
  isLoad: boolean;
  isLoadFeedbackExecutor: boolean;
  isLoadAdditionalUpdate: boolean;
  infoData: ProjectProcessInfoTypes | null;
  tabShow: TabsType | null;
  can: ApiProjectProcessResponses.Can | undefined;
  canTask: ITaskCan | undefined;
  paused: boolean | undefined;
  stopped: boolean;
  id: number | undefined;
  projectId: number | undefined;
  projectParentId: number | undefined;
  projectChatId: number | null;
  projectPartType: number | undefined;
  activeAccessId: number | undefined;
  status: string;
  name: string;
  files: ApiPrimaryFileType[] | null;
  executor: {
    id: number;
    name: string;
    jobDuration: number;
    rating: number;
    link: string;
    role: string;
    firm: string;
    avatar: string;
    userTypeId: number;
  } | null;
  author: {
    name: string;
    id: number;
    avatar: string;
    link: string;
    role: string;
    rating: number;
    jobDuration: number;
    firm: string;
  } | null;
  warningInput: ApiCommonResponses.Warning["input"] | undefined;
  warningActual: ApiCommonResponses.Warning["actual"] | undefined;
  warningOutput: ApiCommonResponses.Warning["output"] | undefined;
  warningRequired: ApiCommonResponses.Warning["required"] | undefined;
  usersList: { id: string; title: string }[];
  isLoadUsersList: boolean;
  userTypes: { id: string; title: string }[];
  isLoadUserTypes: boolean;
  isLoadUserUpdate: boolean;
  isLoadPriceUpdate: boolean;
  isLoadInfoPayBlock: boolean;
  price: number | undefined;
  project: ApiPrimaryProjectType | undefined;
  dateStart: any;
  dateLimit: any;
  executorTypeForm: string;
  published: boolean;
  additionalChecked: {
    kac: boolean;
    vor: boolean;
    vorPnr: boolean;
  };
}

const initialState: InitialState = {
  isLoad: false,
  isLoadAdditionalUpdate: false,
  can: undefined,
  canTask: undefined,
  paused: undefined,
  stopped: false,
  tabShow: null,
  isLoadFeedbackExecutor: false,
  name: "",
  status: "",
  infoData: null,
  executor: null,
  author: null,
  files: null,
  id: undefined,
  projectId: undefined,
  projectParentId: undefined,
  projectPartType: undefined,
  projectChatId: null,
  activeAccessId: undefined,
  warningInput: undefined,
  warningActual: undefined,
  warningOutput: undefined,
  warningRequired: undefined,
  dateStart: undefined,
  dateLimit: undefined,
  isLoadUsersList: false,
  usersList: [],
  isLoadUserTypes: false,
  userTypes: [],
  isLoadUserUpdate: false,
  isLoadPriceUpdate: false,
  isLoadInfoPayBlock: false,
  price: undefined,
  executorTypeForm: "",
  project: undefined,
  published: false,
  additionalChecked: {
    kac: false,
    vor: false,
    vorPnr: false,
  },
};

const projectProcessInfo = createSlice({
  name: "projectProcessInfoSlice",
  initialState,
  reducers: {
    setAdditionalChecked: (state, action) => {
      const { name, checked } = action.payload;
      if (name === "kac") {
        state.additionalChecked = { ...state.additionalChecked, kac: checked };
      }
      if (name === "vor") {
        state.additionalChecked = { ...state.additionalChecked, vor: checked };
      }
      if (name === "vorPnr") {
        state.additionalChecked = {
          ...state.additionalChecked,
          vorPnr: checked,
        };
      }
    },
    setInitialAdditionalChecked: (state, action) => {
      state.additionalChecked = action.payload;
    },
    setStartDate: (state, action) => {
      state.dateStart = action.payload;
    },
    setDateLimit: (state, action) => {
      state.dateLimit = action.payload;
    },
    setExecutorTypeForm: (state, action) => {
      state.executorTypeForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessView.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectProcessView.fulfilled, (state, action) => {
      const info = action.payload;
      const { activeAccess } = action.payload;
      state.isLoad = false;
      state.name = info.title;
      state.status = info.status.value;
      state.can = info.can;
      state.infoData = info;
      state.dateStart = info?.date_start
        ? moment(info.date_start, "DD.MM.YYYY").toDate()
        : null;
      state.dateLimit = info?.date_limit
        ? moment(info.date_limit, "DD.MM.YYYY").toDate()
        : null;
      state.id = info.id;
      state.project = info?.project;
      state.projectId = info?.project?.id;
      state.projectParentId = info?.project.parent_id;
      state.projectPartType = info?.projectPart.type.key;
      state.published = info.projectPart.published;
      state.projectChatId = info?.project?.chatId;
      state.activeAccessId = info?.activeAccess?.id;
      state.price = +info?.price;
      state.executor = activeAccess?.user && {
        avatar:
          activeAccess.user.photo &&
          `${config.localDomain}${activeAccess.user.photo}`,
        link: `/user/${activeAccess.user.id}`,
        name: `${activeAccess.user.surname} ${activeAccess.user.name} ${
          activeAccess.user.patronymic || ""
        }`,
        role: "Исполнитель",
        id: activeAccess.user.id,
        rating: activeAccess.user.rating.executor,
        jobDuration: Math.floor(activeAccess.user.job_duration / 365),
        firm: activeAccess.userType?.listName,
        userTypeId: activeAccess.userType?.id,
      };
      state.author = info?.task?.author && {
        avatar:
          info.task.author.photo &&
          `${config.localDomain}${info.task.author.photo}`,
        link: `/user/${info.task.author?.id}`,
        name: `${info?.task?.author?.surname} ${info?.task?.author?.name} ${info?.task?.author?.patronymic}`,
        role: "Заказчик",
        id: info.task.author.id,
        rating: info.task.author.rating.executor,
        jobDuration: Math.floor(info.task.author.job_duration / 365),
        // заменить
        firm: info?.activeAccess?.userType?.listName,
      };
      state.files = info?.files;
      state.warningInput =
        info?.warning.input.data.length !== 0 ? info?.warning.input : undefined;
      state.warningActual =
        info?.warning.actual.data.length !== 0
          ? info?.warning.actual
          : undefined;
      state.warningOutput =
        info?.warning.output.title.length !== 0
          ? info?.warning.output
          : undefined;
      state.warningRequired =
        info?.warning.required.title.length !== 0
          ? info?.warning.required
          : undefined;
      state.tabShow = info.tabs;
      state.canTask = info.task?.can;
      state.paused = info?.paused;
      state.stopped = info?.stopped;
    });
    builder.addCase(
      fetchProjectProcessViewDuplicate.fulfilled,
      (state, action) => {
        const info = action.payload;
        const { activeAccess } = action.payload;
        state.isLoad = false;
        state.name = info.title;
        state.status = info.status.value;
        state.can = info.can;
        state.infoData = info;
        state.id = info.id;
        state.project = info?.project;
        state.projectId = info?.project?.id;
        state.projectParentId = info?.project.parent_id;
        state.projectPartType = info?.projectPart.type.key;
        state.published = info.projectPart.published;
        state.projectChatId = info?.project?.chatId;
        state.activeAccessId = info?.activeAccess?.id;
        state.price = +info?.price;
        state.executor = activeAccess?.user && {
          avatar:
            activeAccess.user.photo &&
            `${config.localDomain}${activeAccess.user.photo}`,
          link: `/user/${activeAccess.user.id}`,
          name: `${activeAccess.user.surname} ${activeAccess.user.name} ${
            activeAccess.user.patronymic || ""
          }`,
          role: "Исполнитель",
          id: activeAccess.user.id,
          rating: activeAccess.user.rating.executor,
          jobDuration: Math.floor(activeAccess.user.job_duration / 365),
          firm: activeAccess.userType?.listName,
          userTypeId: activeAccess.userType?.id,
        };
        state.author = info?.task?.author && {
          avatar:
            info.task.author.photo &&
            `${config.localDomain}${info.task.author.photo}`,
          link: `/user/${info.task.author?.id}`,
          name: `${info?.task?.author?.surname} ${info?.task?.author?.name} ${info?.task?.author?.patronymic}`,
          role: "Заказчик",
          id: info.task.author.id,
          rating: info.task.author.rating.executor,
          jobDuration: Math.floor(info.task.author.job_duration / 365),
          // заменить
          firm: info?.activeAccess?.userType?.listName,
        };
        state.files = info?.files;
        state.warningInput =
          info?.warning.input.data.length !== 0
            ? info?.warning.input
            : undefined;
        state.warningActual =
          info?.warning.actual.data.length !== 0
            ? info?.warning.actual
            : undefined;
        state.warningOutput =
          info?.warning.output.title.length !== 0
            ? info?.warning.output
            : undefined;
        state.warningRequired =
          info?.warning.required.title.length !== 0
            ? info?.warning.required
            : undefined;
        state.tabShow = info.tabs;
        state.canTask = info.task?.can;
        state.paused = info?.paused;
        state.stopped = info?.stopped;
      }
    );
    builder.addCase(
      fetchProjectProcessViewInfoIsLoadDuplicate.pending,
      (state) => {
        state.isLoadInfoPayBlock = true;
      }
    );
    builder.addCase(
      fetchProjectProcessViewInfoIsLoadDuplicate.fulfilled,
      (state, action) => {
        state.infoData = action.payload;
        state.isLoadInfoPayBlock = false;
      }
    );
    builder.addCase(fetchProjectProcessFeedbackExecutor.pending, (state) => {
      state.isLoadFeedbackExecutor = true;
    });
    builder.addCase(fetchProjectProcessFeedbackExecutor.fulfilled, (state) => {
      state.isLoadFeedbackExecutor = false;
    });
    builder.addCase(fetchProjectProcessUsers.pending, (state) => {
      state.isLoadUsersList = true;
    });
    builder.addCase(fetchProjectProcessUsers.fulfilled, (state, action) => {
      state.isLoadUsersList = false;
      state.usersList = Object.keys(action.payload).map((id: any) => ({
        id,
        title: action.payload[id],
      }));
    });
    builder.addCase(fetchProjectProcessUsersTypes.pending, (state) => {
      state.isLoadUserTypes = true;
    });
    builder.addCase(
      fetchProjectProcessUsersTypes.fulfilled,
      (state, action) => {
        state.isLoadUserTypes = false;
        state.userTypes = Object.keys(action.payload).map((id: any) => ({
          id,
          title: action.payload[id],
        }));
      }
    );
    builder.addCase(fetchProjectProcessUsersUpdate.pending, (state) => {
      state.isLoadUserUpdate = true;
    });
    builder.addCase(fetchProjectProcessUsersUpdate.fulfilled, (state) => {
      state.isLoadUserUpdate = false;
    });
    builder.addCase(fetchProjectProcessUpdatePrice.pending, (state) => {
      state.isLoadPriceUpdate = true;
    });
    builder.addCase(fetchProjectProcessUpdatePrice.fulfilled, (state) => {
      state.isLoadPriceUpdate = false;
    });
    builder.addCase(fetchProjectProcessUpdateAdditional.pending, (state) => {
      state.isLoadAdditionalUpdate = true;
    });
    builder.addCase(fetchProjectProcessUpdateAdditional.fulfilled, (state) => {
      state.isLoadAdditionalUpdate = false;
    });
    builder.addCase(
      fetchProjectProcessUpdateAdditionalWork.pending,
      (state) => {
        state.isLoadAdditionalUpdate = true;
      }
    );
    builder.addCase(
      fetchProjectProcessUpdateAdditionalWork.fulfilled,
      (state) => {
        state.isLoadAdditionalUpdate = false;
      }
    );
  },
});

export const {
  setAdditionalChecked,
  setInitialAdditionalChecked,
  setStartDate,
  setExecutorTypeForm,
  setDateLimit,
} = projectProcessInfo.actions;
export default projectProcessInfo;
