import queryString from "query-string";

type CreateUrlStringType = (
  params: Record<string, string | number> | null,
  setFn: (string: string) => void
) => void;

export const createUrlSearchString: CreateUrlStringType = (params, setFn) => {
  const newString = {
    ...params,
  };

  setFn(`?${queryString.stringify(newString)}`);
};

export const createUrlSearchParamsFromObject = (
  params: Record<string, string | number | (string | number)[]>
): string => {
  const finalParams: (string | number)[] = [];

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((param) => {
        finalParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(param)}`
        );
      });

      return;
    }

    finalParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  });

  return finalParams.join("&");
};
