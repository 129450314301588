import { forwardRef, memo, useEffect, useState } from "react";
import {
  Select,
  SelectProps,
  TSelectItem,
} from "src/FSD/shared/uiKit/v2/Select";
import { PartGroupApi } from "src/FSD/shared/api/v1/partGroup";
import { useAppDispatch } from "src/app/store";
import { pushError } from "src/app/feature/errorTrace";
import { arrayToSelect } from "src/app/feature/project/projectProcessPublication";

type TSpecializations = {
  data: TSelectItem[];
  pending: boolean;
};

type Props = Omit<SelectProps, "label" | "options">;

export const SelectSpecialization = memo(
  forwardRef((props: Props, ref) => {
    const dispatch = useAppDispatch();

    const [specializations, setSpecializations] = useState<TSpecializations>({
      data: [],
      pending: false,
    });

    useEffect(() => {
      dispatch(
        PartGroupApi.getPartGroup({
          data: null,
          events: {
            onPending: () => {
              setSpecializations({ ...specializations, pending: true });
            },
            onFulfilled: (data) => {
              setSpecializations({ data: arrayToSelect(data), pending: false });
            },
            onRejected: (error) => {
              dispatch(pushError(error));
              setSpecializations({ ...specializations, pending: false });
            },
          },
        })
      );
    }, []);

    return (
      <Select
        {...props}
        ref={ref}
        disableClear
        label="Специализация"
        isLoading={specializations.pending}
        options={specializations.data}
      />
    );
  })
);
