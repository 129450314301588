import { useEffect } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { checkRow } from "../helpers/checkRow";
import {
  TGroupPublicationFieldValues,
  TGroupFieldValues,
} from "../../model/types/fieldValues";

type Props = {
  processId: number;
  form: UseFormReturn<TGroupFieldValues>;
};

export const useProcessesList = ({ form, processId }: Props) => {
  const { control, setValue, getValues, trigger } = form;

  const { fields } = useFieldArray({
    control,
    name: "processes",
  });

  const toggleSelectAll = (areAllSelected: boolean) => {
    const fields = getValues("processes");

    fields.forEach((process, index) => {
      if (process.processId === processId) {
        return;
      }

      setValue(`processes.${index}.isActive`, areAllSelected);
      trigger(`processes.${index}.isActive`);

      checkRow(index, form);
    });
  };

  const checkSelectAll = () => {
    const fields = getValues("processes");

    const areAllProcessesSelected = (
      fields as TGroupPublicationFieldValues["processes"]
    ).every((process) => process.isActive);
    setValue("common.areAllSelected", areAllProcessesSelected);
  };

  useEffect(() => {
    checkSelectAll();

    fields.forEach((_, index) => {
      checkRow(index, form);
    });
  }, []);

  return { fields, checkSelectAll, toggleSelectAll } as const;
};
