import { ReactNode, memo } from "react";

type TProps = {
  children: ReactNode;
};

const ColumnTitle = memo(({ children }: TProps) => {
  return <h2>{children}</h2>;
});

export default ColumnTitle;
