import { Dispatch, SetStateAction, FC } from "react";
import { TextField } from "@mui/material";
import style from "../style.module.scss";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import { textFieldSX } from "../../../../../../styles/restyle";
import BankSearchField from "../../../../User/Edit/Payments/BankSearchField/BankSearchField";

interface BankAccountProps {
  targetNumber: number;
  targetKey: string;
  editing: boolean;
  defaultNumber: number;
  setDefaultNumber: Dispatch<SetStateAction<number>>;
  isSubmitting?: boolean;
  errors: Record<string, any>;
  watch: any;
  register: any;
  trigger: any;
  setValue: any;
}

export const BankAccount: FC<BankAccountProps> = ({
  targetNumber,
  targetKey,
  editing,
  defaultNumber,
  setDefaultNumber,
  watch,
  register,
  trigger,
  setValue,
  errors,
}) => {
  const paymentInfoBik = watch(`${targetKey}bik`);
  const paymentInfoBankName = watch(`${targetKey}bank_name`);

  return (
    <form className={style.wrapper}>
      <div className={style.title}>
        <div>Счет №{targetNumber}</div>
        <div className={style.ticker}>
          {editing && (
            <CustomCheckbox
              editing={editing}
              onChange={() => {
                if (paymentInfoBik && editing) {
                  setDefaultNumber(targetNumber);
                }
              }}
              checked={defaultNumber === targetNumber}
            />
          )}
          {(defaultNumber === targetNumber || editing) && (
            <div>Счет по умолчанию</div>
          )}
        </div>
      </div>
      <div className={style.upperFields}>
        <BankSearchField
          name={`${targetKey}bik`}
          label="БИК Банка"
          register={register}
          disabled={!editing}
          defaultValue={paymentInfoBik}
          setValue={setValue}
          trigger={trigger}
          targetKey={targetKey}
          hasError={Boolean(errors[`${targetKey}bik`])}
          helperText={(errors[`${targetKey}bik`]?.message ?? "") as string}
        />
        <BankSearchField
          name={`${targetKey}bank_name`}
          label="Наименование банка"
          disabled={!editing}
          register={register}
          defaultValue={paymentInfoBankName}
          trigger={trigger}
          setValue={setValue}
          targetKey={targetKey}
          hasError={Boolean(errors[`${targetKey}bank_name`])}
          helperText={
            (errors[`${targetKey}bank_name`]?.message ?? "") as string
          }
        />
      </div>
      <div className={style.middleFields}>
        <TextField
          name={`${targetKey}correspondent_account`}
          {...register(`${targetKey}correspondent_account`)}
          {...textFieldSX}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Корреспондентский счет"
          error={Boolean(errors[`${targetKey}correspondent_account`])}
          helperText={errors[`${targetKey}correspondent_account`]?.message}
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          {...register(`${targetKey}bank_account`)}
          {...textFieldSX}
          name={`${targetKey}bank_account`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Расчетный счет"
          error={Boolean(errors[`${targetKey}bank_account`])}
          helperText={errors[`${targetKey}bank_account`]?.message}
          inputProps={{ maxLength: 20 }}
        />
      </div>
    </form>
  );
};

export const TreasuryAccount: FC<BankAccountProps> = ({
  targetNumber,
  targetKey,
  editing,
  defaultNumber,
  setDefaultNumber,
  watch,
  errors,
  register,
  trigger,
  setValue,
}) => {
  const paymentInfoBik = watch(`${targetKey}bik`);
  const paymentInfoBankName = watch(`${targetKey}bank_name`);

  return (
    <form className={style.wrapper}>
      <div className={style.title}>
        <div>Казначейский счет</div>
        <div className={style.ticker}>
          {editing && (
            <CustomCheckbox
              editing={editing}
              onChange={() => {
                if (paymentInfoBik && editing) {
                  setDefaultNumber(targetNumber);
                }
              }}
              checked={defaultNumber === targetNumber}
            />
          )}
          {(defaultNumber === targetNumber || editing) && (
            <div>Счет по умолчанию</div>
          )}
        </div>
      </div>
      <div className={style.upperFields}>
        <BankSearchField
          name={`${targetKey}bik`}
          label="БИК Банка"
          register={register}
          disabled={!editing}
          defaultValue={paymentInfoBik}
          setValue={setValue}
          trigger={trigger}
          targetKey={targetKey}
          hasError={Boolean(errors[`${targetKey}bik`])}
          helperText={(errors[`${targetKey}bik`]?.message ?? "") as string}
        />
        <BankSearchField
          name={`${targetKey}bank_name`}
          label="Наименование банка"
          disabled={!editing}
          register={register}
          defaultValue={paymentInfoBankName}
          trigger={trigger}
          setValue={setValue}
          targetKey={targetKey}
          hasError={Boolean(errors[`${targetKey}bank_name`])}
          helperText={
            (errors[`${targetKey}bank_name`]?.message ?? "") as string
          }
        />
      </div>
      <div className={style.middleFields}>
        <TextField
          {...register(`${targetKey}correspondent_account`)}
          {...textFieldSX}
          name={`${targetKey}correspondent_account`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Корреспондентский счет"
          error={Boolean(errors[`${targetKey}correspondent_account`])}
          helperText={errors[`${targetKey}correspondent_account`]?.message}
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          {...register(`${targetKey}bank_account`)}
          {...textFieldSX}
          name={`${targetKey}bank_account`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Расчетный счет"
          error={Boolean(errors[`${targetKey}bank_account`])}
          helperText={errors[`${targetKey}bank_account`]?.message}
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          {...register(`${targetKey}client_number`)}
          {...textFieldSX}
          name={`${targetKey}client_number`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Лицевой счет"
          error={Boolean(errors[`${targetKey}client_number`])}
          helperText={errors[`${targetKey}client_number`]?.message}
        />
        <TextField
          {...register(`${targetKey}kpp`)}
          {...textFieldSX}
          name={`${targetKey}kpp`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="КПП"
          error={Boolean(errors[`${targetKey}kpp`])}
          helperText={errors[`${targetKey}kpp`]?.message}
        />
        <TextField
          {...register(`${targetKey}inn`)}
          {...textFieldSX}
          name={`${targetKey}inn`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="ИНН"
          error={Boolean(errors[`${targetKey}inn`])}
          helperText={errors[`${targetKey}inn`]?.message}
        />
        <TextField
          {...register(`${targetKey}analysis_code`)}
          {...textFieldSX}
          name={`${targetKey}analysis_code`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="Аналитический код раздела"
          error={Boolean(errors[`${targetKey}analysis_code`])}
          helperText={errors[`${targetKey}analysis_code`]?.message}
        />
      </div>
      <div className={style.longField}>
        <TextField
          {...register(`${targetKey}ufk`)}
          {...textFieldSX}
          name={`${targetKey}ufk`}
          disabled={!editing}
          InputLabelProps={{ shrink: true }}
          label="УФК"
          error={Boolean(errors[`${targetKey}ufk`])}
          helperText={errors[`${targetKey}ufk`]?.message}
        />
      </div>
    </form>
  );
};
