import { FC } from "react";

type TProps = {
  color?: string;
};

const palette: Record<string, string> = {
  dark: "#26313A",
  grey: "#9DAFBD",
  orange: "orange",
  white: "white",
};

export const HomeSVG = ({ color }: TProps) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0614 1.25168C12.7802 0.970473 12.3987 0.8125 12.0009 0.8125C11.6032 0.8125 11.2217 0.970473 10.9404 1.25168L0.969947 11.2207C0.900216 11.2904 0.844901 11.3732 0.807162 11.4643C0.769424 11.5554 0.75 11.6531 0.75 11.7517C0.75 11.8503 0.769424 11.9479 0.807162 12.0391C0.844901 12.1302 0.900216 12.2129 0.969947 12.2827C1.11078 12.4235 1.30178 12.5026 1.50095 12.5026C1.59956 12.5026 1.69721 12.4832 1.78832 12.4455C1.87943 12.4077 1.96222 12.3524 2.03195 12.2827L3.00095 11.3122V19.2517C3.00095 19.8484 3.238 20.4207 3.65996 20.8427C4.08191 21.2646 4.65421 21.5017 5.25095 21.5017H18.7509C19.3477 21.5017 19.92 21.2646 20.3419 20.8427C20.7639 20.4207 21.0009 19.8484 21.0009 19.2517V11.3122L21.9699 12.2827C22.1108 12.4235 22.3018 12.5026 22.5009 12.5026C22.7001 12.5026 22.8911 12.4235 23.0319 12.2827C23.1728 12.1419 23.2519 11.9508 23.2519 11.7517C23.2519 11.5525 23.1728 11.3615 23.0319 11.2207L19.5009 7.69118V2.75168C19.5009 2.55277 19.4219 2.362 19.2813 2.22135C19.1406 2.0807 18.9499 2.00168 18.7509 2.00168H17.2509C17.052 2.00168 16.8613 2.0807 16.7206 2.22135C16.58 2.362 16.5009 2.55277 16.5009 2.75168V4.69118L13.0614 1.25168ZM19.5009 9.81218V19.2517C19.5009 19.4506 19.4219 19.6414 19.2813 19.782C19.1406 19.9227 18.9499 20.0017 18.7509 20.0017H5.25095C5.05203 20.0017 4.86127 19.9227 4.72062 19.782C4.57997 19.6414 4.50095 19.4506 4.50095 19.2517V9.81218L12.0009 2.31218L19.5009 9.81218Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);

export const PenSVG: FC<TProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={palette[color || palette.white]}
      d="M10.646.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 0 1-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 0 1 5.43 3.377l3.313-.828L10.646.646zm-1.8 2.908-3.173.793a.5.5 0 0 0-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 0 0 .34-.357l.794-3.174-3.6-3.6z"
    />
    <path
      fill={palette[color || palette.white]}
      d="M2.832 13.228 8 9a1 1 0 1 0-1-1l-4.228 5.168-.026.086.086-.026z"
    />
  </svg>
);

export const GridSVG: FC<TProps> = ({ color }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2V6.5H1.5V2H9ZM1.5 0.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2L0 6.5C0 6.89782 0.158035 7.27936 0.43934 7.56066C0.720644 7.84196 1.10218 8 1.5 8H9C9.39782 8 9.77936 7.84196 10.0607 7.56066C10.342 7.27936 10.5 6.89782 10.5 6.5V2C10.5 1.60218 10.342 1.22064 10.0607 0.93934C9.77936 0.658035 9.39782 0.5 9 0.5H1.5ZM22.5 18.5V23H15V18.5H22.5ZM15 17C14.6022 17 14.2206 17.158 13.9393 17.4393C13.658 17.7206 13.5 18.1022 13.5 18.5V23C13.5 23.3978 13.658 23.7794 13.9393 24.0607C14.2206 24.342 14.6022 24.5 15 24.5H22.5C22.8978 24.5 23.2794 24.342 23.5607 24.0607C23.842 23.7794 24 23.3978 24 23V18.5C24 18.1022 23.842 17.7206 23.5607 17.4393C23.2794 17.158 22.8978 17 22.5 17H15ZM9 12.5V23H1.5V12.5H9ZM1.5 11C1.10218 11 0.720644 11.158 0.43934 11.4393C0.158035 11.7206 0 12.1022 0 12.5L0 23C0 23.3978 0.158035 23.7794 0.43934 24.0607C0.720644 24.342 1.10218 24.5 1.5 24.5H9C9.39782 24.5 9.77936 24.342 10.0607 24.0607C10.342 23.7794 10.5 23.3978 10.5 23V12.5C10.5 12.1022 10.342 11.7206 10.0607 11.4393C9.77936 11.158 9.39782 11 9 11H1.5ZM22.5 2V12.5H15V2H22.5ZM15 0.5C14.6022 0.5 14.2206 0.658035 13.9393 0.93934C13.658 1.22064 13.5 1.60218 13.5 2V12.5C13.5 12.8978 13.658 13.2794 13.9393 13.5607C14.2206 13.842 14.6022 14 15 14H22.5C22.8978 14 23.2794 13.842 23.5607 13.5607C23.842 13.2794 24 12.8978 24 12.5V2C24 1.60218 23.842 1.22064 23.5607 0.93934C23.2794 0.658035 22.8978 0.5 22.5 0.5H15Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);

export const SectionsSVG: FC<TProps> = ({ color }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 5.25H18.5C19.2956 5.25 20.0587 5.56607 20.6213 6.12868C21.1839 6.69129 21.5 7.45435 21.5 8.25V12.75C21.5 13.5456 21.1839 14.3087 20.6213 14.8713C20.0587 15.4339 19.2956 15.75 18.5 15.75H3.5C2.70435 15.75 1.94129 15.4339 1.37868 14.8713C0.816071 14.3087 0.5 13.5456 0.5 12.75V8.25C0.5 7.45435 0.816071 6.69129 1.37868 6.12868C1.94129 5.56607 2.70435 5.25 3.5 5.25ZM3.5 6.75C3.10218 6.75 2.72064 6.90804 2.43934 7.18934C2.15804 7.47064 2 7.85218 2 8.25V12.75C2 13.1478 2.15804 13.5294 2.43934 13.8107C2.72064 14.092 3.10218 14.25 3.5 14.25H18.5C18.8978 14.25 19.2794 14.092 19.5607 13.8107C19.842 13.5294 20 13.1478 20 12.75V8.25C20 7.85218 19.842 7.47064 19.5607 7.18934C19.2794 6.90804 18.8978 6.75 18.5 6.75H3.5ZM0.5 1.5C0.5 1.30109 0.579018 1.11032 0.71967 0.96967C0.860322 0.829018 1.05109 0.75 1.25 0.75H20.75C20.9489 0.75 21.1397 0.829018 21.2803 0.96967C21.421 1.11032 21.5 1.30109 21.5 1.5C21.5 1.69891 21.421 1.88968 21.2803 2.03033C21.1397 2.17098 20.9489 2.25 20.75 2.25H1.25C1.05109 2.25 0.860322 2.17098 0.71967 2.03033C0.579018 1.88968 0.5 1.69891 0.5 1.5ZM0.5 19.5C0.5 19.3011 0.579018 19.1103 0.71967 18.9697C0.860322 18.829 1.05109 18.75 1.25 18.75H20.75C20.9489 18.75 21.1397 18.829 21.2803 18.9697C21.421 19.1103 21.5 19.3011 21.5 19.5C21.5 19.6989 21.421 19.8897 21.2803 20.0303C21.1397 20.171 20.9489 20.25 20.75 20.25H1.25C1.05109 20.25 0.860322 20.171 0.71967 20.0303C0.579018 19.8897 0.5 19.6989 0.5 19.5Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);

export const LadderSVG: FC<TProps> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 0C0.948912 0 1.13968 0.0790176 1.28033 0.21967C1.42098 0.360322 1.5 0.551088 1.5 0.75V23.25C1.5 23.4489 1.42098 23.6397 1.28033 23.7803C1.13968 23.921 0.948912 24 0.75 24C0.551088 24 0.360322 23.921 0.21967 23.7803C0.0790176 23.6397 0 23.4489 0 23.25V0.75C0 0.551088 0.0790176 0.360322 0.21967 0.21967C0.360322 0.0790176 0.551088 0 0.75 0V0ZM3 2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5H9.75C9.94891 1.5 10.1397 1.57902 10.2803 1.71967C10.421 1.86032 10.5 2.05109 10.5 2.25V3.75C10.5 3.94891 10.421 4.13968 10.2803 4.28033C10.1397 4.42098 9.94891 4.5 9.75 4.5H3.75C3.55109 4.5 3.36032 4.42098 3.21967 4.28033C3.07902 4.13968 3 3.94891 3 3.75V2.25ZM6 8.25C6 8.05109 6.07902 7.86032 6.21967 7.71967C6.36032 7.57902 6.55109 7.5 6.75 7.5H17.25C17.4489 7.5 17.6397 7.57902 17.7803 7.71967C17.921 7.86032 18 8.05109 18 8.25V9.75C18 9.94891 17.921 10.1397 17.7803 10.2803C17.6397 10.421 17.4489 10.5 17.25 10.5H6.75C6.55109 10.5 6.36032 10.421 6.21967 10.2803C6.07902 10.1397 6 9.94891 6 9.75V8.25ZM9 14.25C9 14.0511 9.07902 13.8603 9.21967 13.7197C9.36032 13.579 9.55109 13.5 9.75 13.5H18.75C18.9489 13.5 19.1397 13.579 19.2803 13.7197C19.421 13.8603 19.5 14.0511 19.5 14.25V15.75C19.5 15.9489 19.421 16.1397 19.2803 16.2803C19.1397 16.421 18.9489 16.5 18.75 16.5H9.75C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V14.25ZM12 20.25C12 20.0511 12.079 19.8603 12.2197 19.7197C12.3603 19.579 12.5511 19.5 12.75 19.5H23.25C23.4489 19.5 23.6397 19.579 23.7803 19.7197C23.921 19.8603 24 20.0511 24 20.25V21.75C24 21.9489 23.921 22.1397 23.7803 22.2803C23.6397 22.421 23.4489 22.5 23.25 22.5H12.75C12.5511 22.5 12.3603 22.421 12.2197 22.2803C12.079 22.1397 12 21.9489 12 21.75V20.25Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);

export const CaseSVG: FC<TProps> = ({ color }) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 0.5C9.15326 0.5 8.58097 0.737053 8.15901 1.15901C7.73705 1.58097 7.5 2.15326 7.5 2.75V3.5H2.25C1.65326 3.5 1.08097 3.73705 0.65901 4.15901C0.237053 4.58097 0 5.15326 0 5.75L0 7.826L11.421 10.871C11.8004 10.972 12.1996 10.972 12.579 10.871L24 7.826V5.75C24 5.15326 23.7629 4.58097 23.341 4.15901C22.919 3.73705 22.3467 3.5 21.75 3.5H16.5V2.75C16.5 2.15326 16.2629 1.58097 15.841 1.15901C15.419 0.737053 14.8467 0.5 14.25 0.5H9.75ZM9.75 2H14.25C14.4489 2 14.6397 2.07902 14.7803 2.21967C14.921 2.36032 15 2.55109 15 2.75V3.5H9V2.75C9 2.55109 9.07902 2.36032 9.21967 2.21967C9.36032 2.07902 9.55109 2 9.75 2Z"
      fill={palette[color || palette.white]}
    />
    <path
      d="M0 17.7484C0 18.3452 0.237053 18.9175 0.65901 19.3394C1.08097 19.7614 1.65326 19.9984 2.25 19.9984H21.75C22.3467 19.9984 22.919 19.7614 23.341 19.3394C23.7629 18.9175 24 18.3452 24 17.7484V9.27344L12.1935 12.4189C12.0667 12.4528 11.9333 12.4528 11.8065 12.4189L0 9.27344V17.7484Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);

export const PersonSVG: FC<TProps> = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 18C1.5 18 0 18 0 16.5C0 15 1.5 10.5 9 10.5C16.5 10.5 18 15 18 16.5C18 18 16.5 18 16.5 18H1.5ZM9 9C10.1935 9 11.3381 8.52589 12.182 7.68198C13.0259 6.83807 13.5 5.69347 13.5 4.5C13.5 3.30653 13.0259 2.16193 12.182 1.31802C11.3381 0.474106 10.1935 0 9 0C7.80653 0 6.66193 0.474106 5.81802 1.31802C4.97411 2.16193 4.5 3.30653 4.5 4.5C4.5 5.69347 4.97411 6.83807 5.81802 7.68198C6.66193 8.52589 7.80653 9 9 9V9Z"
      fill={palette[color || palette.white]}
    />
  </svg>
);
