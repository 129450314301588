import React, { memo } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { iconBtnSx } from "src/styles/restyle";
import { EyeFill, GearFill } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import {
  useRemarkDeleteMutation,
  useRemarkStartMutation,
} from "src/FSD/entities/expertise/remark/api";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import styles from "../RemarkCard.module.scss";

interface RemarkCardHeaderProps {
  editMode: boolean;
  setEditMode: (mode: boolean) => void;
  remarkId: number;
  processId: number;
  can?: ApiProjectProcessResponses.Can;
}

export const RemarkCardHeader = memo(
  ({
    editMode,
    setEditMode,
    remarkId,
    processId,
    can,
  }: RemarkCardHeaderProps) => {
    const [remarkDelete] = useRemarkDeleteMutation();
    const [remarkStart] = useRemarkStartMutation();
    const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();
    const { name, handleCloseModal, handleOpenModal } = useModal();

    const handleCreateRemarkRow = () => {
      closeThreeDot();
      handleOpenModal({
        name: ModalNames.ProjectProcess.UPDATE_REMARK_ROW,
      });
    };

    async function deleteRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите удалить замечание?"
      );
      if (result) {
        await remarkDelete({ id: remarkId });
      }
      closeThreeDot();
    }

    async function startRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите направить все в работу?"
      );
      if (result) {
        await remarkStart({ id: remarkId });
      }
      closeThreeDot();
    }

    return (
      <div className={styles.header}>
        {!editMode ? (
          <h1>Чек-лист снятия замечаний</h1>
        ) : (
          <h1>Таблица замечаний</h1>
        )}

        <div className={styles.header_btn}>
          {can?.edit && (
            <>
              {!editMode ? (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(true)}>
                  <GearFill />
                </IconButton>
              ) : (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(false)}>
                  <EyeFill />
                </IconButton>
              )}
            </>
          )}
          {editMode ? (
            <>
              <Modal
                isActive={name === ModalNames.ProjectProcess.UPDATE_REMARK_ROW}
                handleClose={handleCloseModal}
              >
                <ProjectProcessModals.UpdateRemarkRowForm
                  remarkId={remarkId}
                  processId={processId}
                />
              </Modal>
              <ThreeDotButton ref={threeDotButtonRef}>
                <MenuItem onClick={handleCreateRemarkRow}>
                  Добавить замечание
                </MenuItem>
                <MenuItem onClick={startRemarkClick}>Направить все</MenuItem>
                <MenuItem onClick={deleteRemarkClick}>Удалить</MenuItem>
              </ThreeDotButton>
            </>
          ) : (
            <ThreeDotButton ref={threeDotButtonRef}>
              <MenuItem
                className={styles.link}
                component={"a"}
                href={`/expertise-remark/pdf?id=${remarkId}`}
              >
                Экспорт в PDF
              </MenuItem>
              <MenuItem
                className={styles.link}
                component={"a"}
                href={`/expertise-remark/xlsx?id=${remarkId}`}
              >
                Экспорт в Excel
              </MenuItem>
            </ThreeDotButton>
          )}
        </div>
      </div>
    );
  }
);
