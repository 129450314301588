import DefaultAvatar from "../../../../../../../../../media/images/avatarDefault.jpg";
import { helpersYears } from "../../../../../../../../../utils/helpersYears";
import { StarRating } from "../../../../../../../../newUI/SVG";
import { TextLink } from "../../../../../../TextLink/TextLink";
import styles from "./styles.module.scss";

const AvatarCompareGip = ({ project }: any) => {
  return (
    <div className={styles.info}>
      <div className={styles.info__avatar}>
        <img src={project.avatar || DefaultAvatar} alt="Аватар пользователя" />
        <div className={styles.rating}>
          <StarRating />
          <p>{project.rating}</p>
        </div>
      </div>
      <div className={styles.info__credentials}>
        <p className={styles.info__credentials__experience}>
          Опыт {helpersYears(project.jobDuration)}
        </p>
        <TextLink
          redirect
          target="_blank"
          url={project.link}
          variant={styles.info__credentials__link}
        >
          {project?.name}
        </TextLink>
        <p className={styles.info__credentials__firm}>{project.firm}</p>
      </div>
    </div>
  );
};

export default AvatarCompareGip;
