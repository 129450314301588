import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { StarSVG } from "../../../../newUI/SVG";
import { Price } from "../../../User/Dashboard/Secondary/Sections/Section";
import style from "./style.module.scss";
import CustomCheckbox from "../../../../newUI/CustomCheckbox/CustomCheckbox";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  TExecutor,
  clearSelectedExecutors,
  getGroupApplications,
  toggleGroupComparisonMode,
} from "src/app/feature/ProjectView/GroupApplications";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import {
  getExecutors,
  saveChosenTasks,
} from "src/app/feature/ProjectView/GroupApplications/thunks";
import { createSumValues } from "src/app/feature/ProjectView/GroupApplications/utils";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";
import { config } from "src/app.cofig";

const formProcesses = (executors: TExecutor[]) => {
  const result: Record<string, number> = {};

  executors.forEach((executor: TExecutor) => {
    Object.values(executor.advancedApplications).forEach((application) => {
      Object.entries(application).forEach(([id, value]: any) => {
        if (
          !result[id] &&
          value.taskId &&
          value.dateRange &&
          !value.badReason
        ) {
          result[id] = value.taskId;
        }
      });
    });
  });

  return result;
};

type TFormValues = {
  processes: Record<string, number>;
};

interface ComparisonProps {
  id: number;
}

const Comparison = ({ id }: ComparisonProps) => {
  const [sumValues, setSumValues] = useState<any>({});
  const [hasTick, setHasTick] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    data: { selectedExecutors, typeNames },
    pending,
    error,
  } = useAppSelector(getGroupApplications);

  const defaultProcesses = formProcesses(selectedExecutors);

  const { setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      processes: defaultProcesses,
    },
  });

  const processes = watch("processes");

  const executorsCount = selectedExecutors.length;

  const onSubmit = (data: TFormValues) => {
    const body = {
      taskIds: Object.values(data.processes),
    };

    dispatch(saveChosenTasks({ id, body }));
  };

  useEffect(() => {
    const result = createSumValues(selectedExecutors, processes);
    setSumValues(result);
  }, [processes]);

  useNonInitialEffect(() => {
    if (pending.choosing || error.choosing) {
      return;
    }

    dispatch(toggleGroupComparisonMode());
    dispatch(clearSelectedExecutors());
    dispatch(getExecutors(id));
  }, [pending.choosing]);

  const GeneralInfo = () => (
    <>
      <div className={style.row}>
        <p>Проектировщик</p>
        {selectedExecutors.map((executor: TExecutor) => (
          <h2 className={style.extraPadding}>{executor.fio}</h2>
        ))}
      </div>
      <div className={style.row}>
        <p>Форма собственности</p>
        {selectedExecutors.map((executor: TExecutor) => (
          <p>{executor.userTypeText}</p>
        ))}
      </div>
      <div className={style.row}>
        <p>Рейтинг</p>
        {selectedExecutors.map((executor: TExecutor) => (
          <div className={cn(style.noTranslation, style.rating)}>
            <StarSVG />
            <p>{executor.rating}</p>
          </div>
        ))}
      </div>
    </>
  );

  const handleOpenProcess = (processId: number) => {
    const hasApplications = Object.keys(defaultProcesses).includes(
      String(processId)
    );

    window.open(
      `${config.localDomain}/project-process/${processId}?tab=${
        hasApplications ? "applications" : "info"
      }`
    );
  };

  const ExecutorSummary = () => (
    <>
      {Object.entries(typeNames).map(([type, name]: any) => (
        <>
          <div className={style.row}>
            <p>
              Сумма заявок
              <br />
              {name}
            </p>
            {selectedExecutors.map((executor: TExecutor) => {
              if (!executor.executorsDocumnetation[type]) {
                return <p className={style.textCentered}>—</p>;
              }

              return (
                <div className={style.forPrice}>
                  <Price
                    color="white"
                    reverse
                    secure={false}
                    price={executor.executorsDocumnetation[type].price}
                    message={""}
                  />
                  <p>{executor.executorsDocumnetation[type].ndsText}</p>
                </div>
              );
            })}
          </div>
          <div className={style.row}>
            <p>
              Сроки
              <br />
              {name}
            </p>
            {selectedExecutors.map((executor: TExecutor) => {
              if (!executor.executorsDocumnetation[type]) {
                return <p className={style.textCentered}>—</p>;
              }

              return <p>{executor.executorsDocumnetation[type].dateRange}</p>;
            })}
          </div>
        </>
      ))}
    </>
  );

  const Summary = () => (
    <>
      {Object.entries(typeNames).map(([type, name]: any) => (
        <>
          <div className={style.row}>
            <h2>{name}</h2>
            <p />
          </div>
          {Object.entries(
            selectedExecutors[0].advancedApplications[type] ?? []
          ).map(([id, process]: any) => (
            <div className={style.row}>
              <p
                className={style.processLink}
                onClick={() => handleOpenProcess(id)}
              >
                {process.name}
              </p>
              {selectedExecutors.map((executor: TExecutor) => {
                const { badReason, ndsText, dateRange, price, taskId } =
                  executor.advancedApplications[type][id];

                const checked = processes[id] === taskId;

                const handleChange = () => {
                  if (!taskId) {
                    return;
                  }

                  if (checked) {
                    const newProcesses = { ...processes };
                    delete newProcesses[id];

                    if (!Object.keys(newProcesses).length) {
                      return;
                    }

                    setValue("processes", newProcesses);

                    return;
                  }

                  setValue("processes", {
                    ...processes,
                    [id]: taskId,
                  });
                };

                if (!dateRange) {
                  return <p className={style.textCentered}>—</p>;
                }

                if (badReason) {
                  return <p className={style.badReason}>{badReason}</p>;
                }

                setHasTick(true);

                return (
                  <div className={style.check}>
                    <CustomCheckbox
                      onChange={handleChange}
                      checked={checked}
                      editing
                    />
                    <div className={cn(style.forPrice, style.smallPrice)}>
                      <Price
                        color="white"
                        reverse
                        secure={false}
                        price={price}
                        flexBasis={80}
                        message={""}
                      />
                      <p>{ndsText}</p>
                      <p>{dateRange}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          <div className={style.row}>
            <h2>{name}, итого:</h2>
            {selectedExecutors.map((executor: TExecutor) => {
              const sumValue = sumValues?.[executor.id]?.[type];

              if (!sumValue) {
                return <p className={style.textCentered}>—</p>;
              }

              return (
                <div className={style.forPrice}>
                  <Price
                    color="white"
                    reverse
                    secure={false}
                    price={sumValue.price}
                    message={""}
                  />
                  <p>{sumValue.ndsText}</p>
                </div>
              );
            })}
          </div>
        </>
      ))}
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.wrapper}>
      <h2>Сравнение исполнителей</h2>
      <div
        className={cn(
          style.comparisonGrid,
          style[`rowColumns${executorsCount}`]
        )}
      >
        <GeneralInfo />
        <ExecutorSummary />
        <Summary />
        <div className={style.forSubmit}>
          {error.choosing && (
            <p
              className={style.error}
              dangerouslySetInnerHTML={{
                __html: error.choosing,
              }}
            />
          )}
          <CustomButton
            disabled={pending.choosing || !hasTick}
            forceDisabled
            width={130}
            type="submit"
            background={palette.blue}
          >
            Выбрать
          </CustomButton>
        </div>
      </div>
    </form>
  );
};

export default Comparison;
