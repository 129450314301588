import React from "react";
import style from "./style.module.scss";
import { TextLink } from "../../../../../TextLink/TextLink";
import { config } from "../../../../../../../../app.cofig";
import { useInnerWidth } from "../Organisation/Organisation";

interface SumProps {
  start?: number;
  end?: number;
  publishedCost?: string;
  projectProcesses?: any;
  id?: number;
}

const Sum: React.FC<SumProps> = ({
  start,
  end,
  projectProcesses,
  id,
  publishedCost,
}) => {
  const publishedCostRefactored = `${publishedCost}`;
  const showRuble = !!(publishedCost && publishedCost !== "По договоренности");

  const innerWidth = useInnerWidth();

  return (
    <div className={style.wrapper}>
      <h1 className={style.header}>
        <p>Опубликовано разделов на сумму</p>
        {/* @ts-ignore */}
        {innerWidth < 1050 && publishedCostRefactored?.length > 8 && (
          <h4 className={showRuble ? style.ruble : ""}>
            {publishedCostRefactored}
          </h4>
        )}
        <ol>
          {projectProcesses.map((item: any, i: number) => (
            <li key={i}>
              {item.code} - <b>{item.price}</b>
            </li>
          ))}
        </ol>
        <br />
        <TextLink
          redirect
          url={`${config.localDomain}/project/${id}?tab=project-process`}
          variant={style.more}
        >
          Посмотреть все
        </TextLink>
      </h1>
      {/* @ts-ignore */}
      {((innerWidth < 1050 && publishedCostRefactored?.length <= 8) ||
        innerWidth >= 1050) && (
        <h2
          className={showRuble ? style.ruble : ""}
          style={publishedCostRefactored?.length > 8 ? { fontSize: 28 } : {}}
        >
          {publishedCostRefactored}
        </h2>
      )}
      <div className={style.terms}>
        <h1>Сроки выполнения</h1>
        <p>{start}</p>
        <p>{end}</p>
      </div>
    </div>
  );
};

export default Sum;
