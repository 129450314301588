import { Button, Card } from "src/FSD/shared/uiKit/v2";
import styles from "../Info.module.scss";

type TProps = {
  projectId: number;
};

export const InfoDocumentation = ({ projectId }: TProps) => {
  return (
    <Card
      contentClassName={styles.documents}
      header={<Card.Header>Документация</Card.Header>}
    >
      <Button
        color="primary"
        target={"_blank"}
        className={styles.documents__files}
        href={`/project/download-files?id=${projectId}&withTasks=0`}
      >
        Скачать
      </Button>
    </Card>
  );
};
