import React from "react";
import style from "./style.module.scss";
import Logo from "../../../media/images/main_logo.png";
import { useTitle } from "../../hooks/useTitle";

export interface PageErrorScreenProps {
  title: string;
  description: string;
  link?: {
    url: string;
    title: string;
  };
  linkClickHandler?: VoidFunction;
}

export const PageErrorScreen: React.FC<PageErrorScreenProps> = ({
  title,
  description,
  link = {
    title: "главная страница",
    url: "/",
  },
  linkClickHandler = () => {},
}) => {
  useTitle(title);
  return (
    <div className={style.screen}>
      <img className={style.logo} src={Logo} alt="" />
      <div className={style.container}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 className={style.title}>{title}</h1>
          <span className={style.description}>{description}</span>
          {
            <a
              onClick={linkClickHandler}
              className={style.backLink}
              href={link.url}
            >
              {link.title}
            </a>
          }
        </div>
      </div>
      <div className={style.imgContainer}>
        <img
          className={style.img}
          src="https://pirs.online/images/screens.png"
          alt=""
        />
      </div>
    </div>
  );
};
