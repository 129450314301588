import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiProjectProcessCheckTask } from "src/app/services/api/projectProcess/documentations";
import { pushError } from "../../../errorTrace";
import { fetchProjectProcessDocumentations } from "./fetchProjectProcessDocumentations";
import { fetchProjectProcessViewDuplicate } from "../../Info/services/fetchProjectProcessView";

export const fetchProjectProcessCheckAlign = createAsyncThunk<
  boolean,
  {
    taskId: number;
    data: any;
    processId?: number;
  },
  { rejectValue: { status: string; message: string } }
>(
  "projectProcess/fetchProjectProcessCheckAlign",
  async ({ taskId, data, processId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiProjectProcessCheckTask(taskId, data);
      if (!response.ok) {
        throw await response.json();
      }
      if (processId) {
        dispatch(fetchProjectProcessDocumentations(processId));
        dispatch(fetchProjectProcessViewDuplicate(processId));
      }
      return await response.json();
    } catch (err: any) {
      dispatch(pushError(err));
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
    }
  }
);
