import React, { useEffect, useState } from "react";
// хук позволяющий сохранять и восстанавливать позицию каретки в поле, что было передано

export type UseCaretPositionProps = {
  inputFieldElement: React.RefObject<HTMLDivElement | null>;
};
export type UseCaretReturnType = VoidFunction[]; // restore,save,change

export type UseCaretPositionType = (
  elementRef: UseCaretPositionProps["inputFieldElement"]
) => UseCaretReturnType;

export const useCaretPosition: UseCaretPositionType = function useCaretPosition(
  elementRef
) {
  const sel = document.getSelection(); // позиция каретки в документе
  const [saved, setSaved] = useState<any[]>([null, null]); // массив сохранения позиции каретки
  const [editor, setEditor] = useState<any>(null);
  let lastCharSpace = false;

  useEffect(() => {
    if (elementRef) {
      const inputField = elementRef.current;
      setEditor(inputField);
    }
  }, [elementRef]);

  function restorePosition() {
    editor.focus();
    if (!saved[0]) {
      sel!.collapse(editor, saved[1]);
      return;
    }
    sel!.collapse(saved[0], saved[1]);
  }

  function setLastPosition() {
    editor.focus();
    if (
      typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined"
    ) {
      const range = document.createRange();
      range.selectNodeContents(editor);
      range.collapse(false);
      const sel = window.getSelection();
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
      // @ts-ignore
    } else if (typeof document.body.createTextRange !== "undefined") {
      // @ts-ignore
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(editor);
      textRange.collapse(false);
      textRange.select();
    }
  }

  function savePosition() {
    setSaved([sel!.focusNode, sel!.focusOffset]);
  }

  function change() {
    const text = editor.childNodes;
    for (const item of text) {
      if (item.textContent === "base") {
        const bold = document.createElement("span");
        bold.classList.add("highlight");
        bold.appendChild(document.createTextNode(item.textContent));
        editor.replaceChild(bold, item);
      }
    }
  }

  function handleKeyPress(e: KeyboardEvent) {
    change();
    if (lastCharSpace) {
      e.preventDefault();
      lastCharSpace = false;
      const newNode = new Text(String.fromCharCode(e.keyCode));
      editor.appendChild(newNode);
      sel!.collapse(newNode, 1);
    }
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.keyCode === 13 && e.ctrlKey) {
      e.preventDefault();
      return;
    }
    if (e.keyCode === 13) {
      document.execCommand("insertLineBreak");
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (editor) {
      editor!.addEventListener("keydown", (e: KeyboardEvent) =>
        handleKeyDown(e)
      );
      editor!.addEventListener("keypress", (e: KeyboardEvent) =>
        handleKeyPress(e)
      );
    }
  }, [editor]);

  return [restorePosition, savePosition, change, setLastPosition];
};
