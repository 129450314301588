import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { CheckSquareFill } from "react-bootstrap-icons";
import { useTaskCompleteMutation } from "src/FSD/entities/task/api";

type TProps = {
  taskId: number;
};

export const CompleteBtn = memo(({ taskId }: TProps) => {
  const [taskComplete] = useTaskCompleteMutation();

  async function completeHandle() {
    const data = {
      CompleteForm: {
        status: 4,
      },
    };
    await taskComplete({ id: taskId, task: data });
  }

  return (
    <IconButton sx={iconBtnSx} title={"Проверить"} onClick={completeHandle}>
      <CheckSquareFill />
    </IconButton>
  );
});
