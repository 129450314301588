import React from "react";
import { Button, Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

interface TProps {
  projectProcessId: number;
  can: ApiProjectProcessResponses.View["can"];
}

export const ExpertiseDocumentsHeader = ({ projectProcessId, can }: TProps) => {
  const { name, handleCloseModal, handleOpenModal } = useModal();

  const handleOpenExpertiseContract = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.EXPERTISE_CONTRACT,
    });
  };

  if (can!.edit) {
    return (
      <div>
        <Modal
          isActive={name === ModalNames.ProjectProcess.EXPERTISE_CONTRACT}
          handleClose={handleCloseModal}
        >
          <ProjectProcessModals.ExpertiseContractForm
            processId={projectProcessId}
          />
        </Modal>
        <Button onClick={handleOpenExpertiseContract} color="primary">
          Добавить документ
        </Button>
      </div>
    );
  }

  return null;
};
