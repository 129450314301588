import React from "react";
import { Controller } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import SelectMultiple from "src/FSD/shared/uiKit/v2/SelectMultiple/SelectMultiple";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { useEditRemarkRow } from "../lib/hooks/useEditRemarkRow";

export type TProps = {
  processId: number;
  remarkRowId: number;
};

export const EditRemarkRowForm = ({ processId, remarkRowId }: TProps) => {
  const {
    isFetching,
    handleSubmit,
    onSubmit,
    control,
    errors,
    isProcessesFetching,
    projectsList,
    isSubmitting,
    processesList,
    disabled,
    isProjectsFetching,
  } = useEditRemarkRow({ processId, remarkRowId });

  return (
    <Modal.Layout>
      <Modal.Header>Редактирование строки замечания</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Текст замечания"
                placeholder="Введите текст"
                multiline
                minRows={2}
                onChange={onChange}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, name } }) => (
              <TextField
                multiline
                minRows={2}
                label="Комментарий"
                placeholder="Введите текст"
                onChange={onChange}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
          <Controller
            name="dateLimit"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateField
                label="Контрольный срок"
                changeDateHandler={onChange}
                startDateProp={value}
                error={Boolean(errors.dateLimit)}
                helperText={errors.dateLimit?.message}
              />
            )}
          />
          <Controller
            name="project"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                key={value?.id ?? null}
                value={value}
                changeHandler={(_, newValue) => {
                  onChange(newValue);
                }}
                label="Объект"
                options={projectsList}
                isLoading={isProjectsFetching}
              />
            )}
          />
          <Controller
            name="projectPartIds"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectMultiple
                label="Разделы"
                showSelectAll
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                value={value}
                disabled={isProcessesFetching}
                options={processesList ?? []}
                isLoading={isProcessesFetching}
                error={Boolean(errors.projectPartIds)}
                helperText={errors.projectPartIds?.message}
              />
            )}
          />
          <Modal.Controls>
            <Button
              color="primary"
              type="submit"
              disabled={disabled}
              isLoading={isSubmitting}
            >
              Сохранить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
