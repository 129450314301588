import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  messages,
  stringMaxLengthRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const checkCustomerAlignSchema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.REJECT,
    then: stringMaxLengthRequired("Комментарий", 2000),
  }),
  paymentSum: Yup.number()
    .positive("Значение должно быть положительным числом")
    .required(messages.requiredField("Сумма для оплаты"))
    .typeError(messages.shouldBeNumber)
    .test(
      "balanceControl",
      "Недостаточно зарезервированных на работы средств",
      function (value) {
        return this.parent.processBalance >= Number(value);
      }
    ),
});
