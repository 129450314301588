import { memo } from "react";
import { IconButton } from "@mui/material";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import styles from "./Heading.module.scss";
import { Heading } from "./Heading";
import { SetState } from "../../../lib/types/setState";

type TProps = {
  id: string;
  isOpen: boolean;
  setOpen: SetState<boolean>;
  label: string;
};

export const HeadingAccordion = memo(
  ({ id, isOpen, setOpen, label }: TProps) => (
    <Heading>
      <label htmlFor={id} className={styles.headingAccordion}>
        {label}
      </label>
      <IconButton
        id={id}
        onClick={() => setOpen(!isOpen)}
        className={styles.headingAccordion__button}
      >
        {isOpen ? (
          <CaretUpFill color="white" />
        ) : (
          <CaretDownFill color="white" />
        )}
      </IconButton>
    </Heading>
  )
);
