import { useEffect, useState } from "react";
import cn from "classnames";
import { KanbanPage } from "src/FSD/pages/v2/kanban";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import style from "./style.module.scss";
import Projects from "./Primary/Projects";
import SectionsFull from "./Secondary/Sections";
import Sections from "./Primary/Sections";
import Applications from "./Secondary/Applications";
import Works from "./Primary/Works";
import Wallet from "./Wallet";
import Staff from "./Staff";
import Instruction from "./Instruction";
import StaffList from "./Primary/Staff";
import Groups from "./Secondary/Groups";
import Tasks from "./Secondary/Tasks";
import Treaties from "./Secondary/Treaties";
import CurrentLocation from "./CurrentLocation";
import useClearUnusedInfo from "./useClearUnusedInfo";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getDashboardDestinations,
  setTab,
} from "src/app/feature/dashboard/destinations";
import WorkTasks from "./Secondary/WorkTasks";
import { useIsMobile } from "../../../hooks/useIsMobile";
import Navigation from "./Header/Navigation";
import CurrentSection from "./Header/CurrentSection";
import useNonInitialEffect from "../../../hooks/useNonInitialEffect";

const Desktop = () => {
  const { tab } = useAppSelector(getDashboardDestinations);

  const showProject = tab === "project->section" || tab === "project->treaty";
  const showFullSections = tab === "project->section";
  const showShortSections =
    tab === "section->treaty" ||
    tab === "section->application" ||
    tab === "section->tasksAndWorks";
  const showApplications =
    tab === "section->application" || tab === "work->application";
  const showTask = tab === "section->tasksAndWorks";
  const showTreaties =
    tab === "project->treaty" ||
    tab === "section->treaty" ||
    tab === "work->treaty";
  const showGroups = tab === "staff->groups";
  const showWorks =
    tab === "work->application" ||
    tab === "work->treaty" ||
    tab === "work->tasks";
  const showWorkTasks = tab === "work->tasks";
  const showKanban = tab === "kanban";

  if (showKanban) {
    return (
      <div className={style.wrapper}>
        <Header />
        <KanbanPage />
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <Header />
      <CurrentLocation />
      <div className={style.main}>
        {showProject && <Projects />}
        {showFullSections && <SectionsFull />}
        {showWorks && <Works />}
        {showShortSections && <Sections />}
        {showApplications && <Applications />}
        {showTask && <Tasks />}
        {showTreaties && <Treaties type="treaties" />}
        {showGroups && (
          <>
            <StaffList />
            <Groups />
          </>
        )}
        {showWorkTasks && <WorkTasks />}
        <Wallet />
        <Staff />
        <Instruction />
      </div>
    </div>
  );
};

const Mobile = () => {
  const { tab } = useAppSelector(getDashboardDestinations);

  const showProject = tab === "project->section";
  const showSections = tab === "section";
  const showApplications =
    tab === "section->application" || tab === "work->application";
  const showTask = tab === "section->tasksAndWorks";
  const showTreaties =
    tab === "project->treaty" ||
    tab === "section->treaty" ||
    tab === "work->treaty";

  const showStaff = tab === "staff";
  const showGroups = tab === "groups";

  const showWorkTasks = tab === "work->tasks";

  return (
    <div className={style.wrapper}>
      <Header />
      <Navigation />
      <CurrentSection />
      <div className={cn(style.main, style.mobile)}>
        {showProject && <Projects />}
        {showSections && <SectionsFull />}
        {showApplications && <Applications />}
        {showTask && <Tasks />}
        {showTreaties && <Treaties type="treaties" />}
        {showStaff && <StaffList />}
        {showWorkTasks && <WorkTasks />}
        {showGroups && <Groups />}
      </div>
    </div>
  );
};

type TDashboardProps = {
  initialTab?: string;
};

const Dashboard = ({ initialTab }: TDashboardProps) => {
  useClearUnusedInfo();

  const navigate = useNavigate();

  const isMobile = useIsMobile(1101);
  const dispatch = useAppDispatch();

  const [isInitialTabSet, setInitialTab] = useState(false);

  const { tab } = useAppSelector(getDashboardDestinations);

  useEffect(() => {
    if (initialTab) {
      dispatch(setTab(initialTab));
    }

    setInitialTab(true);
  }, []);

  useEffect(() => {
    if (!isMobile && tab === "section") {
      dispatch(setTab("project->section"));
    }
  }, [isMobile]);

  useNonInitialEffect(() => {
    navigate(tab === "kanban" ? "/user/kanban" : "/user/new-dashboard");
  }, [tab]);

  if (!isInitialTabSet) {
    return <div className={style.longWrapper} />;
  }

  return isMobile ? <Mobile /> : <Desktop />;
};

export default Dashboard;
