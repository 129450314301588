import { createSlice } from "@reduxjs/toolkit";
import { fetchUserType } from "../services/fetchUserType";

interface InitialState {
  isLoadUserTypeList: boolean;
  userTypeList: { id: number; title: string }[];
}

const initialState: InitialState = {
  isLoadUserTypeList: false,
  userTypeList: [],
};

const userTypeList = createSlice({
  name: "userTypeList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserType.pending, (state) => {
      state.isLoadUserTypeList = true;
    });
    builder.addCase(fetchUserType.fulfilled, (state, action) => {
      state.isLoadUserTypeList = false;
      state.userTypeList = Object.entries(action.payload).map(([id, name]) => {
        return {
          id: +id,
          title: name,
        };
      });
    });
  },
});
export default userTypeList;
