import React, { MouseEvent } from "react";
import cn from "classnames";
import { IconButton } from "@mui/material";
import style from "../style.module.scss";
import { config } from "src/app.cofig";
import { Price } from "../Sections/Section";
import globalStyle from "../../Primary/style.module.scss";
import { TTreaty } from "src/app/feature/dashboard/treaties";
import { useAppSelector } from "src/app/store";
import { getDashboardDestinations } from "src/app/feature/dashboard/destinations";
import { FolderArrow, FolderArrowFill } from "../../../../../newUI/SVG";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";

type TreatyProps = {
  treaty: TTreaty;
  areTreaties: boolean;
  offset?: number;
  showOpen?: boolean;
  isOpen?: boolean;
  setOpen?: SetState<boolean>;
  isMobile?: boolean;
};

const Treaty = ({
  treaty,
  areTreaties,
  offset = 0,
  showOpen = false,
  isOpen = false,
  setOpen = () => {},
  isMobile,
}: TreatyProps) => {
  const { id, status, title, date, price, sections, typeId } = treaty;

  const { treatyId } = useAppSelector(getDashboardDestinations);

  const isSelected = treatyId === id && areTreaties;
  const isTreaty = typeId === 1;
  const isAct = typeId === 4;

  const atSecondLevel = offset > 8;

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    if (!atSecondLevel || areTreaties) {
      return;
    }

    event.stopPropagation();
    window.open(`${config.localDomain}/document/${id}`, "_blank")!.focus();
  };

  const DateElement = () => {
    const showDate = areTreaties || isTreaty;

    if (isMobile) {
      const dates = date.split(" • ");
      return (
        <h2 className={style.dateTreaty}>
          {showDate && dates.map((date: string) => <p>{date}</p>)}
        </h2>
      );
    }

    return <h2 className={style.dateTreaty}>{showDate && date}</h2>;
  };

  const codeElement = (
    <div
      className={cn(
        style.code,
        style.treatyCode,
        atSecondLevel && style.secondLevel,
        !areTreaties && style.fitLink
      )}
    >
      {status.map((status) => {
        return (
          <>
            {status === "green" && (
              <div className={cn(globalStyle.red, globalStyle.green)} />
            )}
            {status === "orange" && (
              <div className={cn(globalStyle.red, globalStyle.orange)} />
            )}
          </>
        );
      })}
      <p
        onClick={handleOpen}
        className={cn(
          style.taskLink,
          style.treatyLink,
          areTreaties && style.greyLink
        )}
      >
        {title}
      </p>
    </div>
  );

  const sectionsElement = (
    <div className={style.treatySections}>
      {sections.map((section: any) => (
        <div key={section.id}>
          {section.letters.map((letter: any, i: number) => (
            <div key={i} className={style.sectionType}>
              {letter}
            </div>
          ))}
          <p>{section.name}</p>
        </div>
      ))}
    </div>
  );

  if (isMobile) {
    return (
      <div
        className={cn(
          style.short,
          isSelected && globalStyle.selected,
          style.mobileTreaty
        )}
      >
        <div className={cn(style.flex, style.top, style.shortCodeTreaty)}>
          {codeElement}
          {price && (isTreaty || isAct || areTreaties) && (
            <Price
              secure={price.secure}
              message={price.nds}
              price={price.value}
              flexBasis={160}
            />
          )}
        </div>
        <div className={cn(style.flex, style.low, style.lowTreaty)}>
          {sectionsElement}
          <DateElement />
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() => !atSecondLevel && setOpen(!isOpen)}
      style={{ paddingLeft: `${offset * 2}px` }}
      className={cn(style.section, !areTreaties && style.noPointer)}
    >
      {codeElement}
      {sectionsElement}
      <DateElement />
      {price && (isTreaty || isAct || areTreaties) ? (
        <Price
          message={price.nds}
          price={price.value}
          secure={price.secure}
          flexBasis={160}
        />
      ) : (
        <div className={style.price} />
      )}
      {showOpen ? (
        <IconButton className={globalStyle.dropDown}>
          {isOpen ? <FolderArrowFill /> : <FolderArrow />}
        </IconButton>
      ) : (
        <div className={style.dummyPrice} />
      )}
    </div>
  );
};

export default Treaty;
