import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { shallowEqual, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import style from "../style.module.scss";
import { MenuItem } from "../MenuItem/MenuItem";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { chatRoomType } from "../../../../../types/stateTypes/chatType";
import { selectorOpenRooms } from "../../../../../app/feature/chat/chat";
import { TriangleDecor } from "../TiangleDecor/TriangleDecor";
import { AvatarMenu } from "../AvatarMenu/AvatarMenu";
import { userIsAdmin } from "../../../../../app/services/user/userRoles";

/*eslint-disable*/
export type RoomGroupProps = ComponentPropsType<{
  data: chatRoomType[];
  joinRoomHandler?: (roomId: chatRoomType["roomId"]) => void;
  closeGroupHandler?: (groupId: string) => void;
  openGroupHandler?: (groupId: string) => void;
  activeRoomId?: number | string;
  updDate: Date;
  paddingItem?: {
    count: number;
    paddingLeft: number;
  };
}>;

// Активировать поверхностное сравнение при проблемах с производительностью
// const equal = (prevState: any, nextState: any) => {
//   return JSON.stringify(prevState) === JSON.stringify(nextState);
// };

const RoomListUI: React.FC<RoomGroupProps> = ({
  data,
  theme = "dark",
  updDate,
  joinRoomHandler = () => {},
  closeGroupHandler = () => {},
  openGroupHandler = () => {},
  activeRoomId = -1,
  paddingItem = {
    count: 1,
    paddingLeft: 0,
  },
}) => {
  const openRooms = useSelector(selectorOpenRooms, shallowEqual);

  const Group: React.FC<{
    data: chatRoomType;
    paddingItem: {
      count: number;
      paddingLeft: number;
    };
  }> = ({
    data,
    paddingItem = {
      count: 0,
      paddingLeft: 10,
    },
  }) => {
    const head = useRef(null);
    const body = useRef(null);
    const [isOpen, setOpen] = useState(openRooms.includes(data.groupId!));
    useEffect(() => {
      setOpen(openRooms.includes(data.groupId!));
    }, [openRooms]);

    const onClickGroup = (groupId: string) =>
      isOpen ? closeGroupHandler(groupId) : openGroupHandler(groupId);

    return (
      <li
        className={classNames(style.group)}
        data-group={true}
        key={data.groupId}
      >
        <div
          style={{ paddingLeft: paddingItem.count + paddingItem.paddingLeft }}
          ref={head}
          onClick={() => onClickGroup(data.groupId!)}
          data-group={true}
          id={`${data.groupId}`}
          className={classNames(
            style.groupHead,
            style.menuItem,
            style[theme],
            "js-head",
          )}
        >
          <div className={style.groupLeft}>
            {data.isGroup ? (
              <div className={style.groupAvatarWrapper}>
                {data.avatarType === "users" && (
                  <div
                    className={classNames(
                      style.groupAvatarWrapperIcon,
                      style[theme],
                      style.users,
                    )}
                  />
                )}
                {data.avatarType === "projects" && (
                  <div
                    className={classNames(
                      style.groupAvatarWrapperIcon,
                      style[theme],
                      style.projects,
                    )}
                  />
                )}
                {data.avatarType !== "projects" &&
                  data.avatarType !== "users" && (
                    <div
                      className={classNames(
                        style.groupAvatarWrapperIcon,
                        style[theme],
                        style.person,
                      )}
                    />
                  )}
              </div>
            ) : (
              <AvatarMenu
                variant={style.avatarGroup}
                url={data.avatarUrl || ""}
                title={""}
                theme={theme}
              />
            )}

            <div className={style.groupTitle}>{ReactHtmlParser(data.name)}</div>
          </div>
          <div className={style.groupAdditional}>
            <div className={style.groupCounter}>
              {data.countNew ? <p>{`+${data.countNew}`}</p> : <></>}
            </div>
            <div className={style.groupTriangle}>
              {isOpen ? (
                <TriangleDecor isActive={true} theme={theme} />
              ) : (
                <TriangleDecor isActive={false} theme={theme} />
              )}
            </div>
          </div>
        </div>

        <ul
          className={style.groupBody}
          ref={body}
          data-parent={data.groupId}
          style={
            isOpen
              ? // @ts-ignore
                { display: "block" }
              : // @ts-ignore
                { display: "none" }
          }
        >
          {data.rooms ? (
            <RoomList
              paddingItem={paddingItem}
              activeRoomId={activeRoomId}
              updDate={updDate}
              theme={theme}
              openGroupHandler={openGroupHandler}
              closeGroupHandler={closeGroupHandler}
              joinRoomHandler={joinRoomHandler}
              data={data.rooms!}
            />
          ) : (
            <></>
          )}
        </ul>
      </li>
    );
  };

  const MINPADDINGLEFT = 30;
  const paddingLeftComputed =
    (paddingItem.paddingLeft + MINPADDINGLEFT) * paddingItem.count;

  return (
    <>
      {data.map((item, i, arr) => {
        if (item.isGroup && item.rooms && item.rooms.length > 0) {
          return (
            <Group
              key={item.groupId!}
              data={item}
              paddingItem={{
                paddingLeft: paddingLeftComputed / paddingItem.count,
                count: paddingItem.count + 1,
              }}
            />
          );
        }

        return (
          <li
            key={item.roomId}
            className={classNames(
              style.menuItem,
              style[theme],
              Number(item.roomId) === Number(activeRoomId) && style.active,
            )}
            onClick={() => {
              joinRoomHandler(item.roomId);
            }}
            id={`room-${item.roomId}`}
          >
            <MenuItem
              paddingLeft={paddingLeftComputed / paddingItem.count}
              theme={theme}
              variant={classNames(style.menuItem, style[theme])}
              title={item.name}
              chatIconType={{
                avatarUrl: userIsAdmin() ? "" : item.avatarUrl || "",
              }}
              time={""}
              newMessage={item.countNew!}
            />
          </li>
        );
      })}
    </>
  );
};

export const RoomList = React.memo(RoomListUI);
