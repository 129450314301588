import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjectProcessRemoveExecutor,
  fetchProjectProcessRemoveExecutorData,
} from "../services/fetchProjectProcessRemoveExecutor";
import { RemoveExecutorData } from "../types/projectProcessRemoveExecutorTypes";
import { fetchProjectProcessRemoveExecutorComplete } from "../services/fetchProjectProcessRemoveExecutorComplete";

interface InitialState {
  isLoadExecutorRemoveData: boolean;
  isLoadRemoveExecutorComplete: boolean;
  isLoadExecutorRemove: boolean;
  objects: {
    projectName: string;
    processes: {
      id: number;
      price: number;
      checked: boolean;
      code: string;
      max: number;
      min: number;
    }[];
  }[];
  executorRemoveReasons: RemoveExecutorData["reasons"] | undefined;
  article717List: [string, string][];
  contractName: string | null;
  checkedReason2: string;
  checkedAgree: boolean;
  checkedRefund: boolean;
  reasonValue: string;
  contentValue: string;
  checkedPrice: boolean;
}

const initialState: InitialState = {
  isLoadExecutorRemoveData: false,
  isLoadRemoveExecutorComplete: false,
  isLoadExecutorRemove: false,
  executorRemoveReasons: undefined,
  objects: [],
  article717List: [],
  contractName: null,
  checkedReason2: "",
  checkedAgree: false,
  checkedRefund: false,
  checkedPrice: false,
  reasonValue: "",
  contentValue: "",
};

const projectProcessRemoveExecutor = createSlice({
  name: "projectProcessRemoveExecutor",
  initialState,
  reducers: {
    setRemoveExecutorObjectChecked: (state, action) => {
      const { name, id } = action.payload;
      const newArr = state.objects.map((object) => {
        if (object.projectName === name) {
          const newProcesses = object.processes.map((process) => {
            if (process.id === +id) {
              return {
                ...process,
                checked: !process.checked,
              };
            }
            return process;
          });
          return {
            ...object,
            processes: newProcesses,
          };
        }
        return object;
      });
      state.objects = newArr;
    },
    setPriceObject: (state, action) => {
      const { id, newPrice, name } = action.payload;
      const newArr = state.objects.map((object) => {
        if (object.projectName === name) {
          const newProcesses = object.processes.map((process) => {
            if (process.id === +id) {
              return {
                ...process,
                price: newPrice,
              };
            }
            return process;
          });
          return {
            ...object,
            processes: newProcesses,
          };
        }
        return object;
      });
      state.objects = newArr;
    },
    setCheckedReason2: (state, action) => {
      state.checkedReason2 = action.payload;
    },
    setCheckedAgree: (state, action) => {
      state.checkedAgree = action.payload;
    },
    setReasonValue: (state, action) => {
      state.reasonValue = action.payload;
    },
    setContentValue: (state, action) => {
      state.contentValue = action.payload;
    },
    setCheckedRefund: (state) => {
      state.checkedRefund = !state.checkedRefund;
    },
    setCheckedPrice: (state) => {
      state.checkedPrice = !state.checkedPrice;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessRemoveExecutorData.pending, (state) => {
      state.isLoadExecutorRemoveData = true;
    });
    builder.addCase(
      fetchProjectProcessRemoveExecutorData.fulfilled,
      (state, action) => {
        state.isLoadExecutorRemoveData = false;
        const data = action.payload.response;
        const { processId } = action.payload;
        state.contractName = data.contractName;
        const newObjects = data.objects && Object.values(data.objects);
        const reformatObjects = newObjects.map((object) => {
          return {
            projectName: object.projectName,
            processes: Object.values(object.processes).map((process) => {
              return {
                id: process.object.id,
                price: process.object.paid_out,
                checked: process.object.id === processId,
                code: process.object.projectPart.code,
                max: process.maxExecutorPrice,
                min: process.minExecutorPrice,
              };
            }),
          };
        });
        state.objects = reformatObjects;
        state.executorRemoveReasons = data.reasons;
        state.article717List = Object.entries(data.reasons["article-715"].list);
      }
    );
    builder.addCase(fetchProjectProcessRemoveExecutor.pending, (state) => {
      state.isLoadExecutorRemove = true;
    });
    builder.addCase(fetchProjectProcessRemoveExecutor.fulfilled, (state) => {
      state.isLoadExecutorRemove = false;
    });
    builder.addCase(
      fetchProjectProcessRemoveExecutorComplete.pending,
      (state) => {
        state.isLoadRemoveExecutorComplete = true;
      }
    );
    builder.addCase(
      fetchProjectProcessRemoveExecutorComplete.fulfilled,
      (state) => {
        state.isLoadRemoveExecutorComplete = false;
      }
    );
  },
});
export const {
  setRemoveExecutorObjectChecked,
  setCheckedReason2,
  setCheckedAgree,
  setReasonValue,
  setContentValue,
  setCheckedRefund,
  setPriceObject,
  setCheckedPrice,
} = projectProcessRemoveExecutor.actions;
export default projectProcessRemoveExecutor;
