import React from "react";
import { ComponentPropsType } from "../../../../../../types/other/components/componentPropsType";
import { TextLink } from "../../../TextLink/TextLink";
import style from "../../UserList/User/style.module.scss";
import Avatar from "../../UserList/User/subcomponents/Avatar/Avatar";
import Region from "../../UserList/User/subcomponents/Region/Region";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import Sum from "../../UserList/User/subcomponents/Sum/Sum";
import { config } from "../../../../../../app.cofig";
import { useInnerWidth } from "../../UserList/User/subcomponents/Organisation/Organisation";
import { palette } from "../../../../../../styles/restyle";

export interface ParticipantType {
  photo?: string;
  id: number;
  name: string;
  type: string;
}

export type ProjectProps = ComponentPropsType<{
  id: number;
  name: string;
  code: string;
  region: { title: string };
  status: { value: string };
  author: ParticipantType;
  gip: ParticipantType | null;
  gipProcess: {
    dateComplete: string | null;
    dateStart: string;
    gipTypes: { key: number; value: string; full: string }[] | null;
    price: string;
    projectId: number;
  } | null;
  designType: { value: string };
  budget: string;
  advance: number;
  start: number;
  end: number;
  countProcesses: { value: number; subtitle: string }[];
  expertise: { title: string }[];
  publishedCost: string;
  projectProcesses: {
    type: number;
    processId: number;
    code: string;
    price: string;
    secure: boolean;
  }[];
}>;

// const showProcesses = 6;

export const ProjectItem: React.FC<ProjectProps> = (props) => {
  const {
    id,
    name,
    code,
    status,
    region,
    start,
    end,
    author,
    gip,
    gipProcess,
    publishedCost,
    designType,
    expertise,
    projectProcesses,
  } = props;

  const innerWidth = useInnerWidth();
  const fullName = `${code} ${name}`;
  const trimmedName =
    fullName.length > 50 && innerWidth < 1050
      ? `${fullName.substring(0, 45)}...`
      : fullName;

  const data = [
    {
      avatar: `${config.localDomain}${author.photo}`,
      link: `/user/${author.id}?tab=info`,
      name: author.name,
      firm: author.type,
      role: "Заказчик",
    },
  ];

  data.push(
    gip
      ? {
          avatar: `${config.localDomain}${gip.photo}`,
          link: `/user/${gip?.id}?tab=info`,
          name: gip?.name,
          firm: gip?.type,
          role: "Главный инженер проекта",
        }
      : {
          avatar: "",
          link: "",
          name: gipProcess ? "Прием заявок" : "Не назначен",
          firm: "",
          role: "Главный инженер проекта",
        }
  );

  const expertiseTrimmed =
    expertise
      .slice(0, 2)
      .map((item: any) => item.title)
      .join("\n") || "Экспертиза не требуется";

  const projectProcessesTrimmed = projectProcesses
    .filter((item: any) => item?.type === 3)
    .slice(0, 5)
    .map((item) => ({
      code: item.code,
      price: item.price,
    }));

  const handleNavigate = () => {
    window.location.href = `${config.localDomain}/project/${id}`;
  };

  const getColor = (value: string) => {
    switch (value) {
      case "В работе":
        return "#F5A623";
      case "Выполнено":
        return "#3baf6a";
      default:
        return "#6b7d8a";
    }
  };

  return (
    <div className={style.container}>
      <div className={style.container__info}>
        <TextLink variant={style.mainLink} url={`/project/${id}`}>
          {trimmedName as string}
        </TextLink>
        <div className={style.container__info__who}>
          <p>{status.value}</p>
          <div style={{ backgroundColor: getColor(status.value) }} />
        </div>
      </div>
      <div className={style.contents}>
        <Avatar data={data} />
        <Region
          projecting
          data={{
            region: region?.title,
            expertise: expertiseTrimmed,
            logo: `${designType?.value}`,
          }}
        />
        <Sum
          start={start}
          end={end}
          publishedCost={publishedCost}
          projectProcesses={projectProcessesTrimmed}
          id={id}
        />
      </div>
      <CustomButton
        className={style.moreButton}
        onClick={handleNavigate}
        background={palette.green}
        color={"#1B252C"}
      >
        Подробнее
      </CustomButton>
    </div>
  );
};
