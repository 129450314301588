import React, { memo, useState } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { FilterSquare, PlusSquareFill } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import {
  HeaderRemarksFilter,
  TFilter,
} from "../HeaderRemarksFilter/HeaderRemarksFilter";
import styles from "./HeaderRemarks.module.scss";

type TProps = {
  can?: ApiProjectProcessResponses.Can;
  projectId: number;
  processId: number;
  setFilter: (value: TFilter) => void;
};

export const HeaderRemarks = memo(
  ({ can, projectId, processId, setFilter }: TProps) => {
    const [showFilter, setShowFilter] = useState(false);

    const { name, handleCloseModal, handleOpenModal } = useModal();
    const handleRemarkCreateForm = () => {
      handleOpenModal({
        name: ModalNames.ProjectProcess.REMARK_CREATE,
      });
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Замечания</h1>
          <div className={styles.header_btn}>
            {can?.edit && (
              <>
                <IconButton sx={iconBtnSx} onClick={handleRemarkCreateForm}>
                  <PlusSquareFill />
                </IconButton>
                <Modal
                  isActive={name === ModalNames.ProjectProcess.REMARK_CREATE}
                  handleClose={handleCloseModal}
                >
                  <ProjectProcessModals.RemarkCreateForm
                    processId={processId}
                    projectId={projectId}
                  />
                </Modal>
              </>
            )}
            <IconButton
              sx={iconBtnSx}
              onClick={() => setShowFilter((prevState) => !prevState)}
            >
              <FilterSquare />
            </IconButton>
          </div>
        </div>
        {showFilter && (
          <HeaderRemarksFilter
            projectId={projectId}
            processId={processId}
            setFilter={setFilter}
          />
        )}
      </div>
    );
  }
);
