import { forwardRef } from "react";
import { useSelectExecutorType } from "../lib/hooks/useSelectExecutorType";
import { Select, SelectProps } from "../../../../shared/uiKit/v2/Select";

type TProps = Omit<SelectProps, "label" | "options">;

export const SelectExecutorType = forwardRef(
  ({ value, ...props }: TProps, ref) => {
    const { executorTypes, isExecutorTypesLoading } = useSelectExecutorType({});

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={executorTypes}
        isLoading={isExecutorTypesLoading}
        label="Форма собственности"
      />
    );
  }
);
