import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { view, update, deleteQuery, start, stop, create } from "./queries";

export const remarkRowApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    remarkRowUpdate: update(builder),
    remarkRowView: view(builder),
    remarkRowDelete: deleteQuery(builder),
    remarkRowStart: start(builder),
    remarkRowStop: stop(builder),
    remarkRowCreate: create(builder),
  }),
  overrideExisting: false,
});

export const {
  useRemarkRowUpdateMutation,
  useRemarkRowViewQuery,
  useRemarkRowDeleteMutation,
  useRemarkRowStartMutation,
  useRemarkRowStopMutation,
  useRemarkRowCreateMutation,
} = remarkRowApi;
