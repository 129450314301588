import { useRef } from "react";
import { useForm } from "react-hook-form";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { dropCompletedTaskReducer } from "src/FSD/entities/kanban/model";
import { Nullable } from "src/types/generics";
import { compareFileArrays } from "../helpers/compareFileArrays";
import { useAppDispatch } from "src/app/store";
import { pushSuccessMessage } from "src/app/feature/errorTrace";

type TFieldValues = {
  files: TFile[];
  status: Nullable<Statuses>;
};

type UseModalProps = {
  taskId: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export type TOriginalFilesRef = {
  files: TFile[];
  isFirstlyLoaded: boolean;
};

export const useTechnicalSpecifications = ({
  taskId,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const originalFilesRef = useRef<TOriginalFilesRef>({
    files: [],
    isFirstlyLoaded: false,
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: TFieldValues) => {
    const isNewFileUploaded = compareFileArrays(
      data.files,
      originalFilesRef.current.files
    );

    if (isNewFileUploaded && data.files.length) {
      dispatch(
        dropCompletedTaskReducer({
          id: taskId,
          isCheck: false,
          status: Statuses.DONE,
        })
      );
      dispatch(pushSuccessMessage());
    }
    handleClose();
  };

  return {
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    control,
    isSubmitting,
    originalFilesRef,
  } as const;
};
