import { PencilSquare, Trash, ChevronDown } from "react-bootstrap-icons";
import { IconButton } from "@mui/material";
import { Button, Paragraph, TextLink } from "src/FSD/shared/uiKit/v2";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./Material.module.scss";
import { TextItemMaterial } from "../TextItemMaterial/TextItemMaterial";
import { useMaterial } from "../../lib/hooks/useMaterial";
import { Tasks } from "../Tasks/Tasks";

type TProps = {
  material: ApiMaterialProcessResponses.Index;
};

export const Material = ({ material }: TProps) => {
  const {
    handleDeleteMaterialProcess,
    handlePullRequestMaterialProcess,
    updateQuestionnaire,
    questionnaire,
    files,
    filesSize,
    isMultipleFiles,
    isTasksVisible,
    handleToggleTasks,
    showTasks,
    colorDateLimit,
  } = useMaterial({
    material,
  });

  const { handleOpenModal } = useModal();

  const titleKP = (
    <div className={styles.cardMaterial__titleKP}>
      <Paragraph color="light">Предложений</Paragraph>
      {showTasks && (
        <IconButton sx={{ padding: "0px" }}>
          <ChevronDown
            className={
              isTasksVisible
                ? styles.cardMaterial_rotateVisible
                : styles.cardMaterial_rotate
            }
            color="#D5D6DE"
          />
        </IconButton>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.cardMaterial}>
        <div className={styles.cardMaterial__controls}>
          <div className={styles.cardMaterial__materialName}>
            <Paragraph color="light" fontSize={20} fontWeight="bold">
              {material.name}
            </Paragraph>
            {updateQuestionnaire && (
              <Paragraph color="error">
                Необходимо заполнить и опубликовать опросный лист
              </Paragraph>
            )}
          </div>
          <div className={styles.cardMaterial__controlsActions}>
            {material.can.takeToWork && (
              <Button onClick={handlePullRequestMaterialProcess}>
                Взять в работу
              </Button>
            )}
            {material.can.delete ? (
              <IconButton
                onClick={handleDeleteMaterialProcess}
                className={styles.cardMaterial__controlsIconBtn}
              >
                <Trash color="white" />
              </IconButton>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className={styles.cardMaterial__content}>
          <TextItemMaterial
            title="Срок предоставления ТКП"
            className={styles.cardMaterial__content_padding}
          >
            <Paragraph color={colorDateLimit} fontWeight="medium">
              до {material.date_limit}
            </Paragraph>
          </TextItemMaterial>
          <TextItemMaterial title="Комментарий">
            <Paragraph className={styles.cardMaterial__content_comment}>
              {material.comment}
            </Paragraph>
          </TextItemMaterial>
          <TextItemMaterial
            title="Опросный лист"
            className={styles.cardMaterial__content_basisFiles}
          >
            {!updateQuestionnaire && !questionnaire && (
              <>
                <FilesList
                  fileLinks={files}
                  classNameFilesList={
                    isMultipleFiles
                      ? styles.cardMaterial__filesListMultiple
                      : styles.cardMaterial__filesList
                  }
                  classNameTextLink={
                    isMultipleFiles
                      ? styles.cardMaterial__textLink
                      : styles.cardMaterial__textLinkFontSize
                  }
                />
                {isMultipleFiles && (
                  <TextLink
                    title="Скачать все"
                    redirect
                    target="blank"
                    url={`${config.localDomain}/material-process/download-files?id=${material.id}`}
                    className={styles.cardMaterial__textLinkDownload}
                  >
                    Скачать все {filesSize}
                  </TextLink>
                )}
              </>
            )}
            {updateQuestionnaire && (
              <TextLink
                redirect
                target="blank"
                url={`/material-process/edit-form-columns?id=${material.id}`}
              >
                Заполнить опросный лист{" "}
                <IconButton className={styles.cardMaterial__iconBtn}>
                  <PencilSquare />
                </IconButton>
              </TextLink>
            )}
            {questionnaire && (
              <IconButton
                className={styles.cardMaterial__iconBtn}
                onClick={() =>
                  handleOpenModal({
                    name: ModalNames.ProjectProcess
                      .MATERIAL_PROCESS_QUESTIONNAIRE,
                    materialId: material.id,
                  })
                }
              >
                Опросный лист
              </IconButton>
            )}
          </TextItemMaterial>
          <TextItemMaterial title="Направлено запросов">
            <Paragraph fontSize={24} fontWeight="bold">
              {material.countTasks}
            </Paragraph>
          </TextItemMaterial>
          <TextItemMaterial
            title={titleKP}
            className={showTasks ? styles.cardMaterial_cursor : ""}
            onClick={handleToggleTasks}
          >
            <Paragraph fontSize={24} color="minor" fontWeight="bold">
              {material.countKP}
            </Paragraph>
          </TextItemMaterial>
        </div>
      </div>
      {isTasksVisible && showTasks && <Tasks material={material} />}
    </>
  );
};
