import { Statuses } from "src/FSD/shared/lib/constants/statuses";

export const statusGroup = (status: Statuses, statusString: string) => {
  switch (status) {
    case Statuses.ACCEPT:
    case Statuses.DONE:
      return "Выполнен";
    case Statuses.CANCELLED:
    case Statuses.IS_DONE:
    case Statuses.IS_REJECT:
    case Statuses.WORK:
    case Statuses.REJECT:
      return "В работе";
    default:
      return statusString;
  }
};
