import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getProjectViewData,
  setError,
} from "src/app/feature/ProjectView/projectView";

import {
  fetchProjectSourceDataView,
  fetchTaskDataUpdate,
  ProjectSourceData,
} from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { SourceDataView } from "src/FSD/widgets/v2/project/SourceData/ui/SourceDataView/SourceDataView";
import { useAppSelector } from "src/app/store";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { toServerDate } from "../../../../../shared/lib/helpers/toServerDate";
import { Nullable } from "../../../../../../types/generics";

type SourceDataProps = {
  el: ProjectSourceData;
  parentId: number;
  index: number;
  isMobile?: boolean;
};

type TInputs = {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
};

export const SourceDataContent = ({
  el,
  parentId,
  index,
  isMobile,
}: SourceDataProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();
  const { project } = useSelector(getProjectViewData);
  const { error, isLoadDateUpdate, searchValue } = useSelector(
    getProjectInitialData
  );

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const onChangeDate = async (data: TInputs) => {
    const { id } = params;
    const dataParams = {
      date_create: toServerDate(data.startDate)!,
      date_deadline: toServerDate(data.endDate)!,
    };
    await dispatch(fetchTaskDataUpdate({ id: +el.task_id, dataParams }));
    if (id) {
      await dispatch(
        fetchProjectSourceDataView({
          idx: { parentId, id: +id, index, searchValue, type },
        })
      );
    }
  };

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setError());
  }

  if (params.id) {
    return (
      <SourceDataView
        isMobile={isMobile}
        isLoadDateUpdate={isLoadDateUpdate}
        {...el}
        onChangeDate={onChangeDate}
        canUpdateSourceData={project?.can.updateSourceData || false}
        projectId={+params.id}
        parentId={parentId}
        index={index}
        projectParentId={project?.parentId}
        type={"project"}
        canAddFile={project?.can.editByGipHelper || false}
      />
    );
  }
  return <></>;
};
