import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjectProcessDocumentations,
  fetchProjectProcessDocumentationsDuplicate,
} from "../services/fetchProjectProcessDocumentations";
import {
  TCustomerAlign,
  ITask,
  CanCheckType,
  IExpertiseIn,
} from "../types/projectProcessDocumentationsTypes";
import { fetchProjectProcessTaskComplete } from "../services/fetchProjectProcessTaskComplete";
import { fetchProjectProcessCheckAlign } from "../services/fetchProjectProcessCheckAlign";
import {
  fetchProjectProcessPreliminaryDone,
  fetchProjectProcessPreliminaryDoneByTask,
} from "../services/fetchProjectProcessPreliminaryDone";
import { fetchProjectProcessEdit } from "../services/fetchProjectProcessEdit";
import { fetchProjectProcessCanCheck } from "../services/fetchProjectProcessCanCheck";
import { fetchProjectProcessDepositFunds } from "../services/fetchProjectProcessDepositFunds";

interface InitialState {
  isLoad: boolean;
  isLoadCompleteTask: boolean;
  isLoadCompleteCheckAlign: boolean;
  isLoadPreliminaryDone: boolean;
  isLoadPreliminaryByTask: boolean;
  isLoadEdit: boolean;
  isLoadDepositFunds: boolean;
  isLoadCanCheck: boolean;
  error: any;
  error422: any;
  completeCheck: boolean;
  customerAlign: TCustomerAlign[] | undefined;
  requestCustomerAlign: TCustomerAlign[] | undefined;
  processTask: ITask | undefined;
  expertiseIn: IExpertiseIn[] | undefined;
  tab: number;
  canCheck: CanCheckType | undefined;
  modalCheckComment: string;
}

const initialState: InitialState = {
  isLoad: false,
  isLoadCompleteTask: false,
  isLoadCompleteCheckAlign: false,
  isLoadPreliminaryDone: false,
  isLoadPreliminaryByTask: false,
  isLoadEdit: false,
  isLoadDepositFunds: false,
  isLoadCanCheck: false,
  error: null,
  error422: null,
  completeCheck: false,
  tab: 1,
  customerAlign: undefined,
  processTask: undefined,
  requestCustomerAlign: undefined,
  expertiseIn: undefined,
  canCheck: undefined,
  modalCheckComment: "",
};

const projectProcessDocumentations = createSlice({
  name: "projectProcessDocumentations",
  initialState,
  reducers: {
    setTabs: (state, action) => {
      state.tab = action.payload;
    },
    setComment: (state, action) => {
      state.modalCheckComment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessDocumentations.pending, (state) => {
      state.error = null;
      state.isLoad = true;
      state.completeCheck = false;
      state.error422 = null;
    });
    builder.addCase(
      fetchProjectProcessDocumentations.fulfilled,
      (state, action) => {
        state.isLoad = false;
        const { task, customerAlign, requestCustomerAlign, expertiseIn } =
          action.payload;
        state.customerAlign = customerAlign && Object.values(customerAlign);
        state.requestCustomerAlign =
          requestCustomerAlign && Object.values(requestCustomerAlign);
        state.processTask = task;
        state.expertiseIn = expertiseIn && Object.values(expertiseIn);
      }
    );
    builder.addCase(
      fetchProjectProcessDocumentations.rejected,
      (state, action) => {
        state.isLoad = false;
        state.error = action.payload;
      }
    );
    builder.addCase(
      fetchProjectProcessDocumentationsDuplicate.fulfilled,
      (state, action) => {
        const { task, customerAlign, requestCustomerAlign, expertiseIn } =
          action.payload;
        state.customerAlign = customerAlign && Object.values(customerAlign);
        state.requestCustomerAlign =
          requestCustomerAlign && Object.values(requestCustomerAlign);
        state.processTask = task;
        state.expertiseIn = expertiseIn && Object.values(expertiseIn);
      }
    );
    builder.addCase(fetchProjectProcessTaskComplete.pending, (state) => {
      state.isLoadCompleteTask = true;
    });
    builder.addCase(fetchProjectProcessTaskComplete.fulfilled, (state) => {
      state.isLoadCompleteTask = false;
      state.completeCheck = true;
    });
    builder.addCase(
      fetchProjectProcessTaskComplete.rejected,
      (state, action) => {
        state.isLoadCompleteTask = false;
        state.error422 = action.payload?.message;
      }
    );
    builder.addCase(fetchProjectProcessCheckAlign.pending, (state) => {
      state.isLoadCompleteCheckAlign = true;
    });
    builder.addCase(fetchProjectProcessCheckAlign.fulfilled, (state) => {
      state.isLoadCompleteCheckAlign = false;
      state.completeCheck = true;
    });
    builder.addCase(fetchProjectProcessCheckAlign.rejected, (state, action) => {
      state.isLoadCompleteCheckAlign = false;
      state.error422 = action.payload?.message;
    });
    builder.addCase(fetchProjectProcessPreliminaryDone.pending, (state) => {
      state.isLoadPreliminaryDone = true;
    });
    builder.addCase(fetchProjectProcessPreliminaryDone.fulfilled, (state) => {
      state.isLoadPreliminaryDone = false;
    });
    builder.addCase(
      fetchProjectProcessPreliminaryDoneByTask.pending,
      (state) => {
        state.isLoadPreliminaryByTask = true;
      }
    );
    builder.addCase(
      fetchProjectProcessPreliminaryDoneByTask.fulfilled,
      (state) => {
        state.isLoadPreliminaryByTask = false;
      }
    );
    builder.addCase(fetchProjectProcessEdit.pending, (state) => {
      state.isLoadEdit = true;
    });
    builder.addCase(fetchProjectProcessEdit.fulfilled, (state) => {
      state.isLoadEdit = false;
    });
    builder.addCase(fetchProjectProcessCanCheck.pending, (state) => {
      state.isLoadCanCheck = true;
    });
    builder.addCase(fetchProjectProcessCanCheck.fulfilled, (state, action) => {
      state.isLoadCanCheck = false;
      state.canCheck = action.payload;
    });
    builder.addCase(fetchProjectProcessDepositFunds.pending, (state) => {
      state.isLoadDepositFunds = true;
    });
    builder.addCase(fetchProjectProcessDepositFunds.fulfilled, (state) => {
      state.isLoadDepositFunds = false;
    });
  },
});
export const { setTabs, setComment } = projectProcessDocumentations.actions;
export default projectProcessDocumentations;
