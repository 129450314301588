import { useForm } from "react-hook-form";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { Nullable } from "src/types/generics";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useConclusionCreateMutation } from "src/FSD/entities/expertise/conclusion/api";
import { conclusionCreateSchema } from "../schemas/conclusionCreate";

type TInputs = {
  date: Nullable<Date>;
  fail: boolean;
  fileUpload: TFile[];
  num: string;
};

type TProps = {
  processId: number;
};

export const useConclusionCreate = ({ processId }: TProps) => {
  const { handleClose } = useModalContext();

  const [createConclusion, { isLoading }] = useConclusionCreateMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputs>({
    mode: "onSubmit",
    resolver: yupResolver(conclusionCreateSchema),
  });

  const formSubmitHandler = async (dataValues: TInputs) => {
    const formData = new FormData();
    formData.append("fail", dataValues.fail ? "1" : "0");
    formData.append("num", dataValues.num);
    formData.append("date", String(toServerDate(dataValues.date)));
    dataValues.fileUpload.forEach(({ file }) => {
      formData.append("file_upload", file!);
    });
    if (processId) {
      await createConclusion({ id: processId, data: formData });
    }
    handleClose();
  };

  const onSubmit = handleSubmit(formSubmitHandler);

  return {
    onSubmit,
    control,
    errors,
    isLoading,
  } as const;
};
