import { MetaType } from "../../../types/other/pages/formationOfParameters";

export interface OrganisationPageInfoType {
  id: number;
  photo: string | null;
  title: string;
  title_full: string | null;
  is_ip: boolean;
  inn: string;
  ogrn: string;
  kpp: string | null;
  boss: {
    name: string;
    id: number;
  } | null;
  address: string | null;
  tested: boolean;
  phone: string | null;
  email: string | null;
  nds: any;
  bank_name: string | null;
  correspondent_account: string | null;
  bank_account: string | null;
  employees: string | null;
  account_id: number | null;
  membersCount: number;
  address_fact: null | string;
  country: any;
  region_code: any;
  city: string | null;
  manager_post: string | null;
  manager_name: string | null;
  doc_type: any;
  doc_num: string | null;
  doc_date_reg: string | null;
  doc_date_start: string | null;
  doc_date_end: string | null;
  boss_prof: null | string;
  gipBudget: boolean | null;
  joinToFirmTasks: any;
  firmAttorney: any;
  canBeFree: boolean;
  milestone: boolean;
  promote: boolean;
  site: string;
  fio?: {
    id: number;
    title: string;
  };
  dayOfWeek: number;
  numberOfWeeks: number;
}

export type TStaff = {
  role_id?: number | undefined;
  can: any;
  id: number;
  user_id: number;
  role: string;
  name: string;
  gipAccountId: number | null;
  photo: string | null;
  teams: any[];
};

export type OrganisationPageStaffType = TStaff[];

export type OrganisationPageBankType = Record<string, any>[] | null;
export type OrganisationPageHistoryType = Record<string, any>[];
export type OrganisationPageRequestsType =
  | Record<string, any>[]
  | Record<string, any>
  | null;
export type OrganisationPageCanType = Record<string, boolean> | null;

export interface OrganisationPageInitialStateType {
  title: string;
  info: OrganisationPageInfoType;
  staff: OrganisationPageStaffType[];
  staffMeta: MetaType;
  task: OrganisationPageTaskType;
  bank: OrganisationPageBankType;
  history: OrganisationPageHistoryType;
  historyMeta: MetaType;
  requests: OrganisationPageRequestsType;
  can: OrganisationPageCanType;
  modal: boolean;
}

export type OrganisationPageTaskType = {
  id: number;
  date_create: string | null;
  date_complete: string | null;
  content: string;
  files:
    | {
        id: number;
        name: string;
      }[]
    | null;
  comment: string;
  status: string;
} | null;

export const organisationPageInitialState: OrganisationPageInitialStateType = {
  title: "",
  info: {
    id: 0,
    photo: null,
    title: "",
    title_full: null,
    is_ip: false,
    inn: "",
    ogrn: "",
    kpp: null,
    boss: null,
    address: null,
    tested: false,
    phone: null,
    email: null,
    bank_name: null,
    correspondent_account: null,
    bank_account: null,
    employees: null,
    account_id: null,
    membersCount: 0,
    address_fact: null,
    joinToFirmTasks: null,
    firmAttorney: null,
    canBeFree: false,
    milestone: false,
    country: {
      id: "RU",
      title: "Россия",
    },
    region_code: {
      id: 1,
      title: "Республика Адыгея (Адыгея)",
    },
    city: null,
    manager_post: null,
    manager_name: null,
    doc_type: {
      id: 0,
      title: "",
    },
    doc_num: null,
    doc_date_reg: null,
    doc_date_start: null,
    doc_date_end: null,
    boss_prof: null,
    nds: {
      id: 0,
      title: "",
    },
    gipBudget: false,
    promote: false,
    site: "",
    fio: {
      id: 0,
      title: "",
    },
    dayOfWeek: 0,
    numberOfWeeks: 1,
  },
  staff: [],
  staffMeta: null,
  task: null,
  bank: null,
  history: [],
  historyMeta: null,
  requests: [],
  can: null,
  modal: false,
};

export const defaultFirmAttorney = {
  date_start: null,
  date_end: null,
  date_sign: null,
  file: null,
};
