import { ReactNode } from "react";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import classNames from "classnames";
import styles from "./Modal.module.scss";
import { useModalContext } from "./ModalContext";

type TProps = {
  children: ReactNode;
  modalSize?: "lg" | "xl";
  position?: "center" | "right";
};

const ModalLayout = ({ children, modalSize, position = "center" }: TProps) => {
  const { handleClose } = useModalContext();
  const modalSizeClass = modalSize ? `modal__size_${modalSize}` : "";

  const isRightPosition = position === "right";

  return (
    <div
      className={classNames(
        styles.modal__layout,
        {
          [styles[modalSizeClass]]: modalSize,
        },
        isRightPosition && styles.modal__layout_right
      )}
    >
      <IconButton
        onClick={handleClose}
        className={styles.modal__layout__closeButton}
      >
        <Clear />
      </IconButton>
      {children}
    </div>
  );
};

export default ModalLayout;
