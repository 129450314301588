import moment from "moment";
import { KanbanColumns, KanbanStatuses } from "../../model/constants";
import { timeStampToDate, toUnixTimestamp } from "src/app/services/date/date";
import * as KanbanType from "../../types";
import { isDateRangeNPercentLeft } from "src/FSD/shared/lib/helpers/isDateRangeNPercentLeft";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { ApiTaskResponses } from "../../../task/model/responseTypes";
import { Nullable } from "src/types/generics";

type KanbanDataResponse = {
  isStop: boolean;
  isDelegate: boolean;
  isCheck: Nullable<0 | 1 | 2>;
  project: {
    id: number;
    code: string;
  };
  object: {
    code: string;
    deposit: number;
    nds: number;
  };
  task: ApiTaskResponses.View;
  projectCode: string;
  title: string;
  partGroup: {
    color: string;
    code: string;
    fullName: string;
  };
};

type TPayload = {
  data: KanbanDataResponse[];
  columnKey: number;
};

export function mapColumn({ data, columnKey }: TPayload): KanbanType.Task[] {
  return data.map(
    ({
      task,
      title,
      project,
      object,
      isCheck,
      isStop,
      isDelegate,
      partGroup,
    }: KanbanDataResponse) => {
      const dateCreateValue = timeStampToDate(task.date_create!);
      const dateDeadlineValue = timeStampToDate(
        columnKey === KanbanStatuses[KanbanColumns.DONE]
          ? task.date_complete!
          : task.date_deadline
      );

      // console.log(task, title, key, index); // на случай дебага

      return {
        id: task.id,
        name: task.name,
        project,
        isStop,
        isCheck,
        isImportant: task.workflow_data?.importance ?? false,
        partGroup: { ...partGroup, id: task.part_group_id! },
        process: {
          id: task.workflow_data?.processId ?? null,
          name: object.code,
        },
        executor: {
          isDelegate,
          fullName: formFullUserName(task.executor),
        },
        date: {
          value: dateDeadlineValue,
          isExpired: toUnixTimestamp(dateDeadlineValue) < moment().unix(),
          isExpiring: isDateRangeNPercentLeft(
            dateCreateValue,
            dateDeadlineValue,
            10
          ),
        },
        workFlowClass: task.workflow_class,
        files: task.files,
        comment: task.comment,
        content: task.content,
        header: title,
        status: task.status.key,
        can: task.can,
        needFiles: task.need_files,
        addText: task.data?.addText ?? "",
        remarkRow: task.remarkRow
          ? {
              text: task.remarkRow.text,
              files: task.remarkRow.remark.files,
              status: task.workflow_data.reason,
              changes: task.workflow_data.changes,
            }
          : null,
        parent: task.parent
          ? {
              comment: task.parent.comment,
              remarkRow: {
                text: task.parent.remarkRow!.text,
                files: task.parent.remarkRow!.remark.files,
                status: task.parent.workflow_data.reason,
                changes: task.parent.workflow_data.changes,
              },
            }
          : null,
      };
    }
  );
}
