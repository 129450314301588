import { Controller, UseFormReturn } from "react-hook-form";
import cn from "classnames";
import {
  DateField,
  Heading,
  Paragraph,
  TextField,
} from "src/FSD/shared/uiKit/v2";
import styles from "./styles.module.scss";
import { setCommonPrice } from "../lib/helpers/setCommonPrice";
import { useCommonRow } from "../lib/hooks/useCommonRow";
import { TGroupFieldValues } from "../model/types/fieldValues";
import { checkCommonRow } from "../lib/helpers/checkCommonRow";

type Props = {
  form: UseFormReturn<TGroupFieldValues>;
};

export const CommonRow = ({ form }: Props) => {
  const { fields, setCommonDateStart, setCommonDateLimit } = useCommonRow({
    form,
  });

  const { control } = form;

  return (
    <div className={cn(styles.datesGrid, styles.datesGrid_common)}>
      <div className={styles.commonHeader}>
        <Heading>Укажите единый срок и стоимость для группы разделов</Heading>
        <Paragraph>
          Стоимость равномерно распределится по всем выбранным разделам
        </Paragraph>
      </div>
      <Controller
        control={control}
        name={"common.dateStart"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <DateField
              label="Начальный срок"
              startDateProp={value}
              changeDateHandler={(newValue) => {
                onChange(newValue);
                setCommonDateStart(newValue);

                checkCommonRow(form);
              }}
              error={Boolean(error)}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={"common.dateLimit"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <DateField
              label="Конечный срок"
              startDateProp={value}
              changeDateHandler={(newValue) => {
                onChange(newValue);
                setCommonDateLimit(newValue);

                checkCommonRow(form);
              }}
              error={Boolean(error)}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={"common.price"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextField
              value={value}
              label="Общая стоимость"
              onChange={(event) => {
                onChange(event.target.value);
                setCommonPrice(form, fields);

                checkCommonRow(form);
              }}
              error={Boolean(error)}
              helperText={error?.message}
            />
          );
        }}
      />
    </div>
  );
};
