import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import styles from "./Executors.module.scss";
import { Comment } from "./Comment";
import { Correspondence } from "./Correspondence";
import { EmptyCell } from "./EmptyCell";
import { PaymentAndDeadlines } from "./PaymentAndDeadlines";

type TProps = {
  appProcesses: ApiProjectResponses.GetApplications[0]["userTypes"][0]["appProcesses"];
};

export const GroupApplicationsTable = ({ appProcesses }: TProps) => {
  return (
    <div className={styles.groupApplicationsTable}>
      <div className={styles.groupApplicationsTable__line}>
        <Paragraph fontSize={14}>Наименование раздела</Paragraph>
        <Paragraph fontSize={14}>Соответствие требованиям</Paragraph>
        <Paragraph fontSize={14}>Стоимость и сроки</Paragraph>
        <Paragraph fontSize={14}>Комментарий</Paragraph>
      </div>
      {Object.values(appProcesses).map(
        ({ statusText, price, badReasons, process, ndsText, task }: any) => {
          const formattedStatusText = statusText.replace(/&nbsp;/g, " ");

          return (
            <div
              key={process.id}
              className={styles.groupApplicationsTable__line}
            >
              <Paragraph color={task ? "light" : "default"}>
                {process.title}
              </Paragraph>
              {task ? (
                <>
                  <Correspondence
                    badReasons={badReasons}
                    statusText={formattedStatusText}
                  />
                  <PaymentAndDeadlines
                    price={price}
                    ndsText={ndsText}
                    dateStart={task.workflow_data.date_start}
                    dateLimit={task.workflow_data.date_limit}
                  />
                  <Comment value={task.content} />
                </>
              ) : (
                <>
                  <EmptyCell />
                  <EmptyCell />
                  <EmptyCell />
                </>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};
