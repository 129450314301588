import { ReactNode } from "react";
import cn from "classnames";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import styles from "./Application.module.scss";
import { ApplicationRating } from "./ApplicationRating";
import { ApplicationAvatar } from "./ApplicationAvatar";
import { ApplicationSegment } from "./ApplicationSegment";
import { ApplicationFooter } from "./ApplicationFooter";
import { ApplicationRemarks } from "./ApplicationRemarks";

type TProps = {
  avatar: ReactNode;
  rating: ReactNode;
  duration?: ReactNode;
  price?: ReactNode;
  actions?: ReactNode;
  footer?: ReactNode;
  remarks?: ReactNode;
  className?: string;
};

export const Application = ({
  className,
  avatar,
  rating,
  duration,
  price,
  actions,
  footer,
  remarks,
}: TProps) => {
  return (
    <Card className={styles.application}>
      <div className={cn(styles.card, className)}>
        {avatar}
        {rating}
        {duration}
        {price}
        {actions}
      </div>
      {remarks}
      {footer}
    </Card>
  );
};

Application.Avatar = ApplicationAvatar;
Application.Rating = ApplicationRating;
Application.Segment = ApplicationSegment;
Application.Footer = ApplicationFooter;
Application.Remarks = ApplicationRemarks;
