import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { config } from "src/app.cofig";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import { GipCard } from "./GipCard/GipCard";
import styles from "./GipHave.module.scss";

export const GipHave = memo(() => {
  const { project } = useSelector(getProjectViewData);
  if (project && project.gip) {
    return (
      <div className={styles.gip_have}>
        <GipCard />
        <div className={styles.gip_avatar}>
          <NewAvatar
            photoUrl={`${config.localDomain}${project.gip.photo}`}
            jobDuration={project.gip.jobDuration}
            rating={project.gip.rating}
            borderColor={"#2EBBAA"}
          />
        </div>
      </div>
    );
  }
  return <></>;
});
