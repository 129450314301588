import { Card, TextLink } from "src/FSD/shared/uiKit/v2";
import { ApiFile } from "src/FSD/entities/file/model/responseTypes";
import styles from "./SourcesFiles.module.scss";

type Props = {
  files: ApiFile[];
};

export const SourcesFiles = ({ files }: Props) => {
  if (!files.length) {
    return null;
  }

  return (
    <Card
      header={<Card.Header>Исходные данные</Card.Header>}
      contentClassName={styles.files}
    >
      {files.map((file) => {
        return (
          <TextLink redirect target="blank" url={file.url} key={file.id}>
            {file.name}
          </TextLink>
        );
      })}
    </Card>
  );
};
