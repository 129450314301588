import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import styles from "./AddRights.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getProjectUsersList } from "../../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import useNonInitialEffect from "../../../../../hooks/useNonInitialEffect";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import {
  completeTask,
  getUserTypes,
} from "../../../../../../app/feature/dashboard/header/thunks";
import { TModalTaskComplete } from "../Admin";

const statuses: Record<string, number> = {
  ACCEPT: 4,
  REJECT: 5,
};

interface CompleteTaskProps {
  modalTaskComplete: TModalTaskComplete;
  setModalTaskComplete: SetState<TModalTaskComplete>;
  handleCloseModal: () => void;
}

type TCompleteTask = {
  userType: TSelectItem | null;
};

const CompleteTask = ({
  handleCloseModal,
  modalTaskComplete,
}: CompleteTaskProps) => {
  const [submissionStatus, setSubmissionStatus] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  const { defaultValues, userTypes, pending } =
    useAppSelector(getProjectUsersList);

  const { userType } = defaultValues;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<TCompleteTask>({
    defaultValues,
  });

  const onSubmit = async (data: TCompleteTask, status: number) => {
    setSubmissionStatus(status);
    await dispatch(
      completeTask({
        id: modalTaskComplete!.taskId,
        data: { userTypeId: data!.userType!.id, status, comment: "" },
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(getUserTypes());
  }, []);

  useNonInitialEffect(() => {
    if (userType) {
      setValue("userType", userType);
    }
  }, [userType]);

  const isDisabled =
    Object.values(pending).some(Boolean) || pending.userTypes || isSubmitting;

  return (
    <form className={styles.wrapper}>
      <Controller
        name="userType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            isLoading={pending.userTypes}
            label=""
            newLabel={"Форма собственности"}
            variant={styles.select}
            options={userTypes}
          />
        )}
      />
      <div className={styles.submitControls}>
        <Button
          variant="outlined"
          disabled={isDisabled}
          isLoading={!isSubmitting || submissionStatus !== statuses.REJECT}
          onClick={handleSubmit((data) => onSubmit(data, statuses.REJECT))}
          className={styles.submitButton}
        >
          Отказать
        </Button>
        <Button
          disabled={isDisabled}
          isLoading={!isSubmitting || submissionStatus !== statuses.ACCEPT}
          onClick={handleSubmit((data) => onSubmit(data, statuses.ACCEPT))}
          className={styles.submitButton}
        >
          Принять
        </Button>
      </div>
    </form>
  );
};

export default CompleteTask;
