import { useRef } from "react";
import { useTaskBackToWorkMutation } from "src/FSD/entities/task/api";
import { stringDateToDDMMYYYY } from "src/FSD/shared/lib/helpers";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { TColor } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { trimNumber } from "../../../../../../../../utils/trimNumber";

export const taskStatuses: Record<number, TColor> = {
  [Statuses.WORK]: "light",
  [Statuses.ACCEPT]: "minor",
  [Statuses.REJECT]: "error",
  [Statuses.IS_REJECT]: "default",
  [Statuses.CANCELLED]: "error",
};

type TUseModal = {
  task: ApiTaskResponses.View;
};

export const useTask = ({ task }: TUseModal) => {
  const popoverRef = useRef<any>(null);

  const [backToWork] = useTaskBackToWorkMutation();

  const handleBackToWorkTask = async () => {
    const isBackToWork = window.confirm(
      "Вы уверены, что хотите вернуть на доработку?"
    );

    if (isBackToWork) {
      await backToWork({ id: task.id });
    }
  };

  const price = task.workflow_data.price
    ? trimNumber(task.workflow_data.price)
    : "-";
  const dateDeadline = task.date_deadline
    ? stringDateToDDMMYYYY(task.date_deadline)
    : "—.—.—";

  const filesLink = `${config.localDomain}/task/download-files?id=${task.id}`;

  const outFilesLength = task.outFiles.length;

  return {
    handleBackToWorkTask,
    price,
    dateDeadline,
    filesLink,
    outFilesLength,
    popoverRef,
  } as const;
};
