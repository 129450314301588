import { UseFormReturn } from "react-hook-form";
import { daysBetweenDates } from "src/FSD/shared/lib/helpers/daysBetweenDates";
import { daysFromToday } from "src/FSD/shared/lib/helpers/daysFromToday";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { ErrorMessage } from "../../model/constants/errorMesages";
import { TGroupFieldValues } from "../../model/types/fieldValues";

export const checkRow = (
  index: number,
  form: UseFormReturn<TGroupFieldValues>
) => {
  const { clearErrors, getValues, setError } = form;

  clearErrors([
    `processes.${index}.dateStart`,
    `processes.${index}.dateLimit`,
    `processes.${index}.price`,
  ]);

  const isActive = getValues(`processes.${index}.isActive`);
  const dateStart = getValues(`processes.${index}.dateStart`);
  const dateLimit = getValues(`processes.${index}.dateLimit`);
  const price = Number(getValues(`processes.${index}.price`));

  if (!isActive) {
    return;
  }

  if (Number.isNaN(price)) {
    setError(`processes.${index}.price`, {
      message: ErrorMessage.PRICE_IS_INT,
    });
  }

  if (price < 1000) {
    setError(`processes.${index}.price`, {
      message: ErrorMessage.PRICE_1000,
    });
  }

  if (!dateStart) {
    setError(`processes.${index}.dateStart`, {
      message: ErrorMessage.DATE_START_REQUIRED,
    });
  }

  if (!dateLimit) {
    setError(`processes.${index}.dateLimit`, {
      message: ErrorMessage.DATE_LIMIT_REQUIRED,
    });
  }

  const stringDateStart = toServerDate(dateStart);
  const stringDateLimit = toServerDate(dateLimit);

  const dateStartDifferneceFromToday = daysFromToday(stringDateStart!);
  const dateLimitDifferneceFromToday = daysFromToday(stringDateLimit!);

  if (dateStartDifferneceFromToday < 0) {
    setError(`processes.${index}.dateStart`, {
      message: ErrorMessage.DATE_IS_NEXT,
    });
  }

  if (dateLimitDifferneceFromToday < 0) {
    setError(`processes.${index}.dateLimit`, {
      message: ErrorMessage.DATE_IS_NEXT,
    });
  }

  const datesRange = daysBetweenDates(
    stringDateStart!,
    stringDateLimit!,
    "DD.MM.YYYY"
  );

  if (datesRange < 0) {
    setError(`processes.${index}.dateStart`, {
      message: ErrorMessage.DATE_RANGE,
    });
    setError(`processes.${index}.dateLimit`, {});
  }

  if (datesRange < 8) {
    setError(`processes.${index}.dateStart`, {
      message: ErrorMessage.MIN_DATE_RANGE_8_DAYS,
    });
    setError(`processes.${index}.dateLimit`, {});
  }
};
