import React, { useRef, useState } from "react";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { Picker } from "emoji-mart";
import { InsertEmoticon, Send } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { ComponentPropsType } from "src/types/other/components/componentPropsType";
import style from "./style.module.scss";
import { emojiConfig } from "../emojiMartConfig";
import {
  changeNBSP,
  clearSpan,
  deleteAllTags,
  formatText,
} from "../../../../features/Chat/lib/chat";
import { useCaretPosition } from "../../../../features/Chat/lib/useCaretPosition";

export type FooterProps = ComponentPropsType<{
  additionalLeft?: JSX.Element;
  onSetMessage?: (value: string) => void;
}>;

export const insertText = (str: string) => {
  const range = window.getSelection()!.getRangeAt(0);
  const span = document.createElement("span");
  span.textContent = str;
  if (range) {
    range.insertNode(span);
    range.collapse();
    range.collapse();
  }
};

const FIELD_MAX_LENGTH = 2000;

export const BodyFooter = ({
  theme = "dark",
  onSetMessage = () => {},
}: FooterProps) => {
  const [message, setMessage] = useState<string[]>([]);
  const [isFocusField, setFocusField] = useState(false);
  const inputFieldRef = useRef<null | HTMLDivElement>(null);
  const [openPicker, setOpenPicker] = useState(false);
  const pickerRef = useRef<null | HTMLDivElement>(null);

  const [restoreCaretPositionHandler, saveCaretPositionHandler] =
    useCaretPosition(inputFieldRef); //
  const [isNote, setNote] = useState(false);

  const classEmptyField = (innerTextArray: string[]) => {
    if (innerTextArray.length > 0) return style.haveText;
  };
  const onClearTextField = () => {
    setMessage([]);
    if (inputFieldRef.current) {
      inputFieldRef.current.textContent = "";
    }
  };
  const onCloseEmojiPicker = () => {
    setOpenPicker(false);
    restoreCaretPositionHandler();
    // @ts-ignore
    setMessage(inputFieldRef!.current!.innerHTML.toString());
  };
  const onOpenEmojiPicker = () => {
    setOpenPicker(true);
  };
  const onSetFocusField = () => {
    inputFieldRef!.current!.focus();
  };

  const onClickInput = () => {
    setTimeout(() => {
      saveCaretPositionHandler();
    }, 100);
  };
  const onFocus = () => {
    setFocusField(true);
    saveCaretPositionHandler();
  };
  const onSubmit = () => {
    if (message.length === 0 || !message || message.length > FIELD_MAX_LENGTH) {
      return;
    }

    // @ts-ignore
    onSetMessage(changeNBSP(clearSpan(formatText(message))));
    onClearTextField();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      onSubmit();
    }
  };

  const onUnFocus = () => setFocusField(false);

  const checkMaxFieldLength = () => {
    setNote(
      deleteAllTags(inputFieldRef.current!.innerHTML.toString()).length >
        FIELD_MAX_LENGTH
    );
  };

  const onPaste = (e: React.ClipboardEvent<HTMLParagraphElement>) => {
    e.preventDefault();
    insertText(e.clipboardData.getData("text"));
    saveCaretPositionHandler();
    // @ts-ignore
    setMessage(inputFieldRef.current.innerHTML.toString());
    checkMaxFieldLength();
  };

  const onChangeText = () => {
    checkMaxFieldLength();
    saveCaretPositionHandler();
    // @ts-ignore
    setMessage(inputFieldRef.current.innerHTML.toString());
  };

  useOnClickOutside(pickerRef, () => {
    if (openPicker) onCloseEmojiPicker();
  });

  return (
    <>
      <footer className={classNames(style.footer, style[theme])}>
        <div className={style.textAreaWrapper}>
          <div className={classNames(style.left)} ref={pickerRef}>
            <div
              className={classNames(style.iconWrapper, "js-button-emoji")}
              onClick={(e) => {
                const { target } = e;
                // @ts-ignore
                if (target.classList.contains("js-button-emoji")) {
                  if (openPicker) {
                    onCloseEmojiPicker();
                    return;
                  }
                  onOpenEmojiPicker();
                }
              }}
            />
            <SvgIcon
              component={InsertEmoticon}
              className={classNames(style.iconSend, style[theme])}
            />

            <div
              className={classNames(
                style.pickerWrapper,
                openPicker && style.active
              )}
            >
              <Picker
                set="apple"
                onSelect={(e) => {
                  restoreCaretPositionHandler();
                  // @ts-ignore
                  insertText(e.native);
                  saveCaretPositionHandler();
                }}
                {...emojiConfig}
              />
            </div>
          </div>

          {/* } */}
          <div className={style.center} onClick={onSetFocusField}>
            <div className={style.textWrapper}>
              {isNote ? (
                <div
                  className={style.note}
                >{`Не более ${FIELD_MAX_LENGTH} символов`}</div>
              ) : (
                <></>
              )}
              <p
                id="chatInput"
                onKeyDown={onKeyPress}
                ref={inputFieldRef}
                onInput={onChangeText}
                onFocus={onFocus}
                onBlur={onUnFocus}
                onClick={onClickInput}
                contentEditable={true}
                onPaste={onPaste}
                className={classNames(
                  style.input,
                  style[theme],
                  classEmptyField(message)
                )}
              />
              {!isFocusField && message.length === 0 && (
                <span className={style.placeholder}>Введите сообщение...</span>
              )}
            </div>
          </div>
          <div
            className={classNames(style.right, style[theme])}
            onClick={onSubmit}
          >
            <Send className={classNames(style.iconSend, style[theme])} />
          </div>
        </div>
      </footer>
    </>
  );
};
