import React from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { Button } from "../../UI/components/Buttons/Button/Button";
import { createSearchObjectDataFromFormParams } from "../../../app/services/searchParams/searchParamsCreateData";
import { TagType } from "../../features/SpecialForm/TagType";

export type LibrarySearchFormProps = ComponentPropsType<{
  submitHandler?: (params: Record<string, any>) => void;
  initialValues?: Record<string, any>;
}>;

type TFormValues = Record<string, any>;

export const LibrarySearchForm: React.FC<LibrarySearchFormProps> = ({
  theme = "dark",
  submitHandler = () => {},
  initialValues = () => {},
}) => {
  const { register, setValue, watch, handleSubmit } = useForm<TFormValues>({
    defaultValues: {
      // @ts-ignore
      title: initialValues["PostSearch[title]"] || "",
      // @ts-ignore
      tag_id: initialValues["PostSearch[tag_id]"] || "",
    },
  });

  const watchTagId = watch("tag_id");

  const onSubmit = (data: TFormValues) => {
    const searchData = createSearchObjectDataFromFormParams(data, "posts");
    submitHandler(searchData);
  };

  return (
    <div className={style.libraryForm}>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("title")}
          className={style.titleSearch}
          id="title"
          name="title"
          type="text"
          label="Заголовок"
          variant="outlined"
          size="small"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TagType
          variant={style.tagType}
          changeHandler={(data) =>
            setValue("tag_id", data ? data.id : undefined)
          }
          value={watchTagId}
        />
        <Button
          theme={theme}
          className={style.searchButton}
          variant="contained"
          color="primary"
          type={"submit"}
        >
          Поиск
        </Button>
      </form>
    </div>
  );
};
