import React, { useEffect, useRef, useState } from "react";
import { config } from "src/app.cofig";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import {
  Uploader,
  getFilesDifference,
  TFile,
  TFilesState,
} from "src/FSD/shared/uiKit/v2/Uploader";
import { hasNonNullFile, getTaskFiles } from "../lib/helpers";

type TProps = {
  taskId: number;
  setModalFiles?: (data: boolean) => void;
  handleCloseModal?: () => void;
  handleUpdate?: () => void;
};

export const FileUploader = ({
  taskId,
  setModalFiles,
  handleCloseModal,
  handleUpdate,
}: TProps) => {
  const files = useRef<TFilesState>({
    initialIsFilled: false,
    initial: [],
    final: [],
  });
  const [haveChanges, setHaveChanges] = useState(false);

  useEffect(
    () => () => {
      getFilesDifference(files.current);
    },
    []
  );

  const handleSetFiles = (uploadedFiles: TFile[]) => {
    const checkNewFile = hasNonNullFile(uploadedFiles);
    const shouldUpdate = getFilesDifference(files.current);
    setHaveChanges(checkNewFile && shouldUpdate);

    files.current = files.current.initialIsFilled
      ? { ...files.current, final: [...uploadedFiles] }
      : {
          ...files.current,
          initial: [...uploadedFiles],
          initialIsFilled: true,
        };
  };

  return (
    <Modal.Form>
      <Uploader
        autoUpload
        getDataCallback={() => getTaskFiles(taskId)}
        postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
        uploadAsActive
        handleUpdate={handleUpdate}
        setFiles={handleSetFiles}
      />
      <Modal.Controls>
        <Button
          variant={haveChanges ? "contained" : "outlined"}
          onClick={() => {
            if (setModalFiles) {
              setModalFiles(false);
            }

            if (handleCloseModal) {
              handleCloseModal();
            }
          }}
        >
          {haveChanges ? "Сохранить" : "Закрыть"}
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
