import { createSlice } from "@reduxjs/toolkit";
import { fetchExpertiseRemarks } from "../services/fetchExpertiseRemarks";
import { RemarksType } from "../types/expertiseRemarksTypes";
import { fetchExpertiseProjectList } from "../services/fetchExpertiseProjectList";
import { ExpertiseProjectListType } from "../types/expertiseProjectListType";
import { fetchExpertiseProjectPartList } from "../services/fetchExpertiseProcesses";
import { fetchExpertiseRemarkRowUpdate } from "../services/fetchExpertiseRemarkRowUpdate";
import { fetchExpertiseRemarkRowCreate } from "../services/fetchExpertiseRemarkRowCreate";
import { fetchExpertisePartGroup } from "../services/fetchExpertisePartGroup";
import {
  ExpertisePartGroupType,
  ExpertiseProjectPartType,
} from "../types/expertisePartGroupType";
import { fetchExpertiseProjectPartByGroup } from "../services/fetchExpertiseProjectPartByGroup";
import { fetchExpertiseRemarksFilter } from "../services/fetchExpertiseRemarksFilter";
import { fetchExpertiseRemarkCreate } from "../services/fetchExpertiseRemarkCreate";
import { fetchExpertiseCommentView } from "../services/fetchExpertiseCommentView";
import { ExpertiseCommentType } from "../types/expertiseCommentType";

interface InitialState {
  isLoadExpertiseRemarks: boolean;
  initialLoadDone: boolean;
  isLoadExpertiseProjectList: boolean;
  isLoadExpertiseProjectPartList: boolean;
  isLoadExpertiseUpdate: boolean;
  isLoadExpertiseCreate: boolean;
  isLoadPartGroup: boolean;
  isLoadProjectPart: boolean;
  isLoadExpertiseRemarksFilter: boolean;
  isLoadCreateRemark: boolean;
  isLoadCommentView: boolean;
  projectPart: ExpertiseProjectPartType[] | undefined;
  partGroup: ExpertisePartGroupType[] | undefined;
  expertiseProjectList: ExpertiseProjectListType[] | undefined;
  expertiseRemarks: RemarksType[];
  expertiseProjectPartList: Record<string, string> | undefined;
  filterVisible: boolean;
  openRemarks: any;
  partGroupId: string;
  projectPartCode: string;
  statusId: string;
  comment: ExpertiseCommentType[] | undefined;
}

const initialState: InitialState = {
  isLoadExpertiseRemarks: false,
  initialLoadDone: false,
  isLoadExpertiseProjectList: false,
  isLoadExpertiseProjectPartList: false,
  isLoadExpertiseUpdate: false,
  isLoadExpertiseCreate: false,
  isLoadPartGroup: false,
  isLoadProjectPart: false,
  isLoadExpertiseRemarksFilter: false,
  isLoadCreateRemark: false,
  isLoadCommentView: false,
  comment: undefined,
  partGroup: undefined,
  projectPart: undefined,
  expertiseRemarks: [],
  expertiseProjectList: undefined,
  expertiseProjectPartList: undefined,
  filterVisible: false,
  partGroupId: "",
  projectPartCode: "",
  statusId: "",
  openRemarks: {},
};

const expertiseRemarks = createSlice({
  name: "expertiseRemarks",
  initialState,
  reducers: {
    setOpenRemarks: (state, action) => {
      const index = action.payload;
      if (state.openRemarks[index] !== undefined) {
        state.openRemarks[index] = undefined;
      } else {
        state.openRemarks[index] = index;
      }
    },
    setVisible: (state) => {
      state.filterVisible = !state.filterVisible;
    },
    setPartGroupId: (state, action) => {
      state.partGroupId = action.payload;
    },
    setProjectPartCode: (state, action) => {
      state.projectPartCode = action.payload;
    },
    setStatusId: (state, action) => {
      state.statusId = action.payload;
    },
    clearFilter: (state) => {
      state.statusId = "";
      state.projectPartCode = "";
      state.partGroupId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpertiseRemarks.pending, (state) => {
      if (!state.initialLoadDone) {
        state.isLoadExpertiseRemarks = true;
      }
    });
    builder.addCase(fetchExpertiseRemarks.fulfilled, (state, action) => {
      state.isLoadExpertiseRemarks = false;
      state.expertiseRemarks = Object.values(action.payload);
      state.initialLoadDone = true;
    });
    builder.addCase(fetchExpertiseRemarksFilter.pending, (state) => {
      state.isLoadExpertiseRemarksFilter = true;
    });
    builder.addCase(fetchExpertiseRemarksFilter.fulfilled, (state, action) => {
      state.isLoadExpertiseRemarksFilter = false;
      state.expertiseRemarks = Object.values(action.payload);
    });
    builder.addCase(fetchExpertiseProjectList.pending, (state) => {
      state.isLoadExpertiseProjectList = true;
    });
    builder.addCase(fetchExpertiseProjectList.fulfilled, (state, action) => {
      state.isLoadExpertiseProjectList = false;
      state.expertiseProjectList = action.payload;
    });
    builder.addCase(fetchExpertiseProjectPartList.pending, (state) => {
      state.isLoadExpertiseProjectPartList = true;
    });
    builder.addCase(
      fetchExpertiseProjectPartList.fulfilled,
      (state, action) => {
        state.isLoadExpertiseProjectPartList = false;
        state.expertiseProjectPartList = action.payload;
      }
    );
    builder.addCase(fetchExpertiseRemarkRowUpdate.pending, (state) => {
      state.isLoadExpertiseRemarks = false;
      state.isLoadExpertiseUpdate = true;
    });
    builder.addCase(fetchExpertiseRemarkRowUpdate.fulfilled, (state) => {
      state.isLoadExpertiseUpdate = false;
    });
    builder.addCase(fetchExpertiseRemarkRowCreate.pending, (state) => {
      state.isLoadExpertiseCreate = true;
    });
    builder.addCase(fetchExpertiseRemarkRowCreate.fulfilled, (state) => {
      state.isLoadExpertiseCreate = false;
    });
    builder.addCase(fetchExpertisePartGroup.pending, (state) => {
      state.isLoadPartGroup = true;
    });
    builder.addCase(fetchExpertisePartGroup.fulfilled, (state, action) => {
      state.isLoadPartGroup = false;
      state.partGroup = action.payload;
    });
    builder.addCase(fetchExpertiseProjectPartByGroup.pending, (state) => {
      state.isLoadProjectPart = true;
    });
    builder.addCase(
      fetchExpertiseProjectPartByGroup.fulfilled,
      (state, action) => {
        state.isLoadProjectPart = false;
        state.projectPart = action.payload;
      }
    );
    builder.addCase(fetchExpertiseRemarkCreate.pending, (state) => {
      state.isLoadCreateRemark = true;
    });
    builder.addCase(fetchExpertiseRemarkCreate.fulfilled, (state) => {
      state.isLoadCreateRemark = false;
    });
    builder.addCase(fetchExpertiseCommentView.pending, (state) => {
      state.isLoadCommentView = true;
    });
    builder.addCase(fetchExpertiseCommentView.fulfilled, (state, action) => {
      state.isLoadCommentView = false;
      state.comment = action.payload;
    });
  },
});

export const {
  setOpenRemarks,
  setVisible,
  setPartGroupId,
  setProjectPartCode,
  setStatusId,
  clearFilter,
} = expertiseRemarks.actions;
export default expertiseRemarks;
