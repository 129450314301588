import { useSelector } from "react-redux";
import { OrganisationPageInfoType } from "src/app/feature/organisationPage/initialState";
import { selectorOrganisationCreation } from "src/app/feature/profileReducer/profileReducer";
import { notRequired, charter } from "./utils";

export type TDefaultValues = {
  id: number;
  photo: string | null;
  title_full: string;
  title: string | null;
  inn: string;
  ogrn: string | null;
  city: string | null;
  address: string | null;
  manager_post: string | null;
  manager_name: string | null;
  email: string | null;
  boss_prof: string | null;
  fio: {
    id: number;
    title: string;
  } | null;
  nds: {
    id: number;
    title: string;
  } | null;
  kpp: string | null;
  doc_type: {
    id: number;
    title: string;
  };
  region: number;
  country: string;
  address_fact: string | null;
  phone: string | null;
  doc_num: string;
  doc_date_reg: string | null;
  doc_date_start: string | null;
  doc_date_end: string | null;
  is_ip: boolean;
  firmAttorney: File | null;
};

const newFirmDefaultValues: TDefaultValues = {
  id: 0,
  photo: null,
  title_full: "",
  title: "",
  inn: "",
  ogrn: "",
  city: "",
  address: "",
  manager_post: "",
  manager_name: "",
  email: "",
  boss_prof: "",
  fio: {
    id: 0,
    title: "",
  },
  nds: {
    id: 1,
    title: "УСН",
  },
  kpp: "",
  doc_type: {
    id: 1,
    title: "Устав",
  },
  region: 1,
  country: "RU",
  address_fact: "",
  phone: "",
  doc_num: "б/н",
  doc_date_reg: null,
  doc_date_start: null,
  doc_date_end: null,
  is_ip: false,
  firmAttorney: null,
};

const useOrganisationForm = (
  data: OrganisationPageInfoType | null,
  isNew: boolean | undefined = false
) => {
  const newFirmData = useSelector(selectorOrganisationCreation);

  if (newFirmData?.inn && isNew) {
    const isIp = newFirmData?.inn.length === 12;
    const docType = newFirmData?.doc_type || (isIp ? notRequired : charter);

    return {
      defaultValues: {
        ...newFirmDefaultValues,
        ...newFirmData,
        region: newFirmData?.region_code ?? 1,
        doc_type: docType,
      },
    } as const;
  }

  if (!data) {
    return { defaultValues: newFirmDefaultValues } as const;
  }

  const defaultValues = {
    id: data.id,
    photo: data.photo,
    title_full: data.title_full || "",
    title: data.title || "",
    inn: data.inn || "",
    ogrn: data.ogrn || "",
    city: data.city || "",
    address: data.address || "",
    manager_post: data.manager_post || "",
    manager_name: data.manager_name || "",
    email: data.email || "",
    boss_prof: data.boss_prof || "",
    fio: {
      id: data.boss?.id || 0,
      title: data.boss?.name || "",
    },
    nds: data.nds || null,
    kpp: data.kpp || "",
    doc_type: data.doc_type || notRequired,
    region: data.region_code.id,
    country: data.country.id,
    address_fact: data.address_fact || "",
    phone: data.phone || "",
    doc_num: data.doc_num || "б/н",
    doc_date_reg: data.doc_date_reg ? new Date(`${data.doc_date_reg}`) : null,
    doc_date_start: data.doc_date_start
      ? new Date(`${data.doc_date_start}`)
      : null,
    doc_date_end: data.doc_date_end ? new Date(`${data.doc_date_end}`) : null,
    is_ip: data.is_ip,
    firmAttorney: data.firmAttorney,
  };

  return { defaultValues } as const;
};

export default useOrganisationForm;
