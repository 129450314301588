import { useForm } from "react-hook-form";
import { useFeedbackTaskMutation } from "src/FSD/entities/feedback/api";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { calculationJobDurationYears } from "../helpers/CalculationJobDuration";
import { pushSuccessMessage } from "src/app/feature/errorTrace";
import { useAppDispatch } from "src/app/store";
import { useMemo } from "react";

type TFieldValues = {
  comment: string;
  quality: number;
  reliability: number;
  loyalty: number;
};

type TUseModalProps = {
  taskId: number;
  handleClose: () => void;
  defaultValues: TFieldValues;
  isExecutor: boolean;
};

export const useFeedbackTask = ({
  taskId,
  handleClose,
  defaultValues,
  isExecutor,
}: TUseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<TFieldValues>({
    defaultValues,
  });

  const { data: taskInfo, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["executorUserType", "controllerUserType"],
  });

  const [feedbackTask] = useFeedbackTaskMutation();

  const type = isExecutor ? "executor" : "author";
  const userType = isExecutor ? "executorUserType" : "controllerUserType";
  const ratingType = isExecutor ? "executor" : "customer";

  const taskDetails = useMemo(() => {
    if (!taskInfo) {
      return {};
    }

    return {
      jobDuration: calculationJobDurationYears(taskInfo[type].job_duration),
      photoUrl: `${config.localDomain}${taskInfo[type].photo}`,
      lowerText: taskInfo[userType]?.listName,
      fio: formFullUserName(taskInfo[type]),
      userId: taskInfo[type].id,
      rating: taskInfo[type].rating?.[ratingType],
    };
  }, [taskInfo]);

  const onSubmit = async (data: TFieldValues) => {
    const typeFeedback = isExecutor ? 1 : 2;

    const formedData = {
      Feedback: {
        quality: data.quality,
        reliability: data.reliability,
        loyalty: data.loyalty,
        comment: data.comment,
        type: typeFeedback,
      },
    };

    const result = await feedbackTask({
      taskId: taskInfo!.id,
      userId: taskDetails.userId!,
      data: formedData,
    });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isTaskViewPending: isFetching || !taskInfo,
    taskDetails,
  } as const;
};
