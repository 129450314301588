import { useMemo } from "react";

export const useDataMapper = (rawData: any, mapper: (data: any) => any) => {
  return useMemo(() => {
    if (!rawData) {
      return undefined;
    }

    return mapper(rawData);
  }, [rawData]);
};
