import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { palette } from "src/styles/restyle";

const sx = { bgcolor: palette.darkSlate };

export const SkeletonInfo = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={123} />
      </Grid>
      <Grid item xs={6} md={4}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={8}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={4}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={6} md={8}>
        <Skeleton variant="rounded" sx={sx} height={265} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={145} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="rounded" sx={sx} height={73} />
      </Grid>
    </Grid>
  );
};
