import classNames from "classnames";
import styles from "./NothingFound.module.scss";

type TNothingFoundProps = {
  className?: string;
};

export const NothingFound = ({ className }: TNothingFoundProps) => {
  return (
    <p className={classNames(styles.notFound, className)}>Ничего не найдено</p>
  );
};
