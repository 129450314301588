import { useProcessWorkApplicationQuery } from "src/FSD/entities/processWork/api";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProjectProcessApplicationQuery } from "src/FSD/entities/projectProcess/api";
import { useTabsPanelContext } from "../../../../TabsPanel/ui/TabsPanel/TabsPanelContext";

const applicationQuery: Record<PublishedObjectControllingEntities, any> = {
  ProcessWork: useProcessWorkApplicationQuery,
  ProjectProcess: useProjectProcessApplicationQuery,
};

interface HookProps {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
}

export const useExecutorSearch = ({ publishedObject, type }: HookProps) => {
  const { setCurrentTab } = useTabsPanelContext();

  const { data: applications, isFetching: isFetchingApplications } =
    applicationQuery[type]({
      id: publishedObject.id,
    });

  const countApplications =
    (applications?.good?.length || 0) + (applications?.bad?.length || 0);

  const handleNavigation = () => {
    setCurrentTab("applications");
  };

  const executorTypes = publishedObject.executorType.map((item) => item.value);

  const publishedObjectName = `«${publishedObject.projectPart.name}${publishedObject.projectPart.code ? ` (${publishedObject.projectPart.code})` : ""}»`;

  const requestExecutionHeader = `Заявка на исполнение задачи «${publishedObject.title}» по разделу ${publishedObjectName}`;

  return {
    countApplications,
    handleNavigation,
    executorTypes,
    requestExecutionHeader,
    isFetchingApplications,
  } as const;
};
