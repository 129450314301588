import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { IconButton } from "@mui/material";
import { PencilSquare } from "react-bootstrap-icons";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import cn from "classnames";
import { ITasksMaterials } from "../../../../../../app/feature/ProjectProcessView/Material/types/projectProcessMaterialsTypes";
import { StatusCardWrapper } from "../../../../../newUI/StatusCardWrapper/StatusCardWrapper";
import cls from "./MaterialTaskCard.module.scss";
import { ToggleAccordion } from "../../../../../newUI/ToggleAccordion/ToggleAccordion";
import { trimPrice } from "../../../../../../utils/trimNumber";
import { iconBtnSx } from "../../../../../../styles/restyle";
import {
  AddFile,
  ChatIcon,
  CheckSVG,
  FeedbackStarIcon,
  NotApproveIcon,
} from "../../../../../newUI/SVG";
import Modal from "../../../../../newUI/Modal/Modal";
import { ModalSubmit } from "./ModalSubmit/ModalSubmit";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessMaterialOfferBackIsload,
  getProjectProcessMaterialOfferSelectIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialIsLoad";
import { fetchProcessMaterialOfferBack } from "../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialOfferBack";
import { fetchProcessMaterialDuplicate } from "../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterial";
import { getProjectProcessInfoId } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getSubmitFormMaterialsIsLoad,
  getSubmitFormMaterialsSuccessSubmit,
} from "../../../../../../app/feature/ProjectProcessView/Material/selectors/getSubmitFormMaterials";
import { ModalUpdate } from "./ModalUpdate/ModalUpdate";
import {
  setClear,
  setErrorFlag,
} from "../../../../../../app/feature/ProjectProcessView/Material/slice/submitFormMaterials";
import {
  setClearUpdate,
  setErrorFlagUpdate,
} from "../../../../../../app/feature/ProjectProcessView/Material/slice/updateFormMaterials";
import {
  getUpdateFormMaterialsIsLoad,
  getUpdateFormMaterialsSuccess,
} from "../../../../../../app/feature/ProjectProcessView/Material/selectors/getUpdateFormMaterials";
import { TextLink } from "../../../../../UI/components/TextLink/TextLink";
import { ModalCheck } from "./ModalCheck/ModalCheck";
import { ModalFeedbackMaterial } from "./ModalFeedbackMaterial/ModalFeedbackMaterial";
import { config } from "../../../../../../app.cofig";
import { getFeedbackExecutorIsLoad } from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getFeedbackExecutorIsLoad";

export const MaterialTaskCard: React.FC<
  ITasksMaterials & { cardName: string; dateLimit: any; materialId: number }
> = memo(
  ({
    status,
    files,
    outFiles,
    executor,
    userType,
    executorUserType,
    comment,
    content,
    workflow_data,
    date_deadline,
    chatRoomId,
    can,
    cardName,
    materialId,
    id,
  }) => {
    const dispatch = useAppDispatch();
    const [toggleComment, setToggleComment] = useState(false);
    const [toggleFiles, setToggleFiles] = useState(false);
    const [modalSubmit, setModalSubmit] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalCheck, setModalCheck] = useState(false);
    const [modalFeedback, setModalFeedback] = useState(false);
    const processId = useAppSelector(getProjectProcessInfoId);
    const isLoadSubmit = useAppSelector(getSubmitFormMaterialsIsLoad);
    const isLoadFeedback = useAppSelector(getFeedbackExecutorIsLoad);
    const submitSuccess = useAppSelector(getSubmitFormMaterialsSuccessSubmit);
    const isLoadUpdate = useAppSelector(getUpdateFormMaterialsIsLoad);
    const isLoadSelect = useAppSelector(
      getProjectProcessMaterialOfferSelectIsLoad
    );
    const updateSubmitSuccess = useAppSelector(getUpdateFormMaterialsSuccess);
    const isLoadOfferBack = useAppSelector(
      getProjectProcessMaterialOfferBackIsload
    );

    const viewStatus = !toggleComment && !toggleFiles;
    const viewComment = toggleComment && !toggleFiles;
    const viewFiles = !toggleComment && toggleFiles;

    useEffect(() => {
      if (submitSuccess && processId) {
        dispatch(fetchProcessMaterialDuplicate(processId));
        setModalSubmit(false);
        dispatch(setClear());
      }
    }, [submitSuccess]);

    useEffect(() => {
      if (updateSubmitSuccess && processId) {
        dispatch(fetchProcessMaterialDuplicate(processId));
        setModalUpdate(false);
        dispatch(setClearUpdate());
      }
    }, [updateSubmitSuccess]);

    const dataExecutorForAvatar = useMemo(() => {
      return [
        {
          avatar: executor?.photo && `${config.localDomain}${executor.photo}`,
          link: `/user/${executor?.id}`,
          name: `${executor.surname} ${executor.name} ${executor?.patronymic}`,
          role: "Исполнитель",
          id,
          executorId: executor.id,
          rating: executor?.rating?.executor.slice(0, -1),
          jobDuration: Math.floor(executor?.job_duration / 365),
          firm: executorUserType?.listName || "",
        },
      ];
    }, []);

    function toggleCommentClick() {
      setToggleFiles(false);
      setToggleComment(!toggleComment);
    }
    function toggleFilesClick() {
      setToggleComment(false);
      setToggleFiles(!toggleFiles);
    }

    function submitApplication() {
      setModalSubmit(true);
    }

    function updateApplication() {
      setModalUpdate(true);
    }

    function checkApplication() {
      setModalCheck(true);
    }
    function modalSubmitClose(state: boolean) {
      dispatch(setErrorFlag());
      setModalSubmit(state);
    }

    function modalUpdateSubmitClose(state: boolean) {
      dispatch(setErrorFlagUpdate());
      setModalUpdate(state);
    }

    const closeModalCheck = useCallback(() => {
      setModalCheck(false);
    }, []);

    const closeModalFeedback = useCallback(() => {
      setModalFeedback(false);
    }, []);

    async function backToWorkApplication() {
      await dispatch(fetchProcessMaterialOfferBack({ taskId: id }));
      if (processId) {
        dispatch(fetchProcessMaterialDuplicate(processId));
      }
    }

    const SkeletonCard = () => {
      const sx = { bgcolor: "#26313a" };
      return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" sx={sx} height={310} />
          </Grid>
        </Grid>
      );
    };

    if (isLoadOfferBack || submitSuccess) {
      return <SkeletonCard />;
    }

    return (
      <div>
        <StatusCardWrapper
          color={status.color}
          viewStatus={viewStatus}
          viewComment={viewComment}
          viewFiles={viewFiles}
          files={outFiles}
          comment={comment}
          status={status}
          classname={cls.card}
          downloadAllLink={
            outFiles?.length > 0 ? `/task/download-files?id=${id}` : undefined
          }
        >
          <div className={cls.content}>
            <div className={cls.content_flex}>
              <TextLink
                variant={cls.content_flex_type}
                url={`/firm/${executorUserType?.firmId}`}
                target={"_blank"}
                redirect
              >
                {userType}
              </TextLink>
              <p>{`${executor.surname} ${executor.name} ${executor?.patronymic}`}</p>
            </div>
            <div className={cn(cls.content_price, cls.toggleList)}>
              <div className={cls.toggleList}>
                <div className={cls.toggle} onClick={toggleCommentClick}>
                  <p>Комментарий</p>
                  <ToggleAccordion toggle={toggleComment} />
                </div>
                {outFiles && (
                  <div className={cls.toggle} onClick={toggleFilesClick}>
                    <p>Вложения</p>
                    <ToggleAccordion toggle={toggleFiles} />
                  </div>
                )}
              </div>
              {Number(workflow_data?.price) > 0 && (
                <div className={cls.content_price_rub}>
                  <span>{`${trimPrice(workflow_data.price)} ₽`}</span>
                  <div className={cls.content_price_rub_date}>
                    {`Действительно до ${moment(
                      date_deadline,
                      "DD.MM.YYYY HH:mm:ss Z"
                    ).format("DD.MM.YYYY")}`}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={cls.btn}>
            {can?.submit && (
              <Modal
                active={modalSubmit}
                setActive={modalSubmitClose}
                isLoad={isLoadSubmit}
              >
                <ModalSubmit
                  name={cardName}
                  content={content}
                  files={files}
                  date_deadline={date_deadline}
                  taskId={id}
                  materialId={materialId}
                  price={workflow_data?.price || "0"}
                />
              </Modal>
            )}
            {/* {can?.update && (
              <Modal
                active={modalUpdate}
                setActive={modalUpdateSubmitClose}
                isLoad={isLoadUpdate}
              >
                <ModalUpdate
                  name={cardName}
                  content={content}
                  comment={comment}
                  files={files}
                  date_deadline={date_deadline}
                  price={workflow_data?.price || "0"}
                  taskId={id}
                />
              </Modal>
            )} */}
            {can?.select && (
              <Modal
                active={modalCheck}
                setActive={setModalCheck}
                isLoad={isLoadSelect}
              >
                <ModalCheck
                  taskId={id}
                  materialId={materialId}
                  closeModal={closeModalCheck}
                />
              </Modal>
            )}
            {can?.feedback && (
              <Modal
                active={modalFeedback}
                setActive={setModalFeedback}
                isLoad={isLoadFeedback}
              >
                <ModalFeedbackMaterial
                  executorData={dataExecutorForAvatar}
                  closeModal={closeModalFeedback}
                />
              </Modal>
            )}
            {can?.feedback && (
              <IconButton sx={iconBtnSx} onClick={() => setModalFeedback(true)}>
                <FeedbackStarIcon />
              </IconButton>
            )}
            {can?.submit && (
              <IconButton
                sx={iconBtnSx}
                title="Подать заявку"
                onClick={submitApplication}
              >
                <AddFile />
              </IconButton>
            )}
            {can?.select && (
              <IconButton
                sx={iconBtnSx}
                title="Выбрать"
                onClick={checkApplication}
              >
                <CheckSVG width="24" height="24" />
              </IconButton>
            )}
            {/* {can?.update && (
              <IconButton
                sx={iconBtnSx}
                title="Редактировать"
                onClick={updateApplication}
              >
                <PencilSquare width="24" height="24" />
              </IconButton>
            )} */}
            {can?.backToWork && (
              <IconButton
                sx={iconBtnSx}
                title="Отозвать"
                onClick={backToWorkApplication}
              >
                <NotApproveIcon width="24" heigth="24" />
              </IconButton>
            )}
            {chatRoomId?.id && (
              <TextLink
                url={`/site/front/chat/${chatRoomId.id}`}
                redirect
                target={"_blank"}
              >
                <IconButton sx={iconBtnSx} title="Чат">
                  <ChatIcon width="24" height="24" />
                </IconButton>
              </TextLink>
            )}
          </div>
        </StatusCardWrapper>
      </div>
    );
  }
);
