import { useFieldArray, UseFormReturn } from "react-hook-form";
import { checkRow } from "../helpers/checkRow";
import { Nullable } from "../../../../../../../../types/generics";
import { TGroupFieldValues } from "../../model/types/fieldValues";

type Props = {
  form: UseFormReturn<TGroupFieldValues>;
};

export const useCommonRow = ({ form }: Props) => {
  const { control, setValue } = form;

  const { fields } = useFieldArray({
    control,
    name: "processes",
  });

  const setCommonDateStart = (date: Nullable<Date>) => {
    fields.forEach((_, index) => {
      setValue(`processes.${index}.dateStart`, date);
      checkRow(index, form);
    });
  };

  const setCommonDateLimit = (date: Nullable<Date>) => {
    fields.forEach((_, index) => {
      setValue(`processes.${index}.dateLimit`, date);
      checkRow(index, form);
    });
  };

  return { fields, setCommonDateStart, setCommonDateLimit } as const;
};
