import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  processId: number;
  data: {
    content: string;
  };
}>;

export const createStop = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ processId, data }) => ({
      url: `v1/task/create-stop`,
      params: {
        processId,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.VIEW, ApiTags.Task.GET_STOPPED],
  });
