import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProcessWorkUpdatePublishedMutation } from "src/FSD/entities/processWork/api";
import { useProjectProcessUpdatePublishedMutation } from "src/FSD/entities/projectProcess/api";
import { usePublishContent } from "../lib/hooks/usePublishContent";
import { ObjectForm } from "./Form";
import { SimpleUpdateForm } from "./SimpleUpdateForm";

const submitQueries = {
  ProcessWork: useProcessWorkUpdatePublishedMutation,
  ProjectProcess: useProjectProcessUpdatePublishedMutation,
};

export type Props = {
  id: number;
  header: string;
  type: PublishedObjectControllingEntities;
};

export const UpdatePublishedObjectForm = ({ id, type, header }: Props) => {
  const [submitQuery] = submitQueries[type]();

  const {
    onSubmit,
    form,
    publicationValues,
    isSubmittingSave,
    isSubmittingPublish,
    isProjectProcessViewPending,
    processData,
    showErrorFiles,
    publishInfo,
    textProcess,
    isProcessWork,
  } = usePublishContent({
    id,
    type,
    submitQuery,
    isEdit: true,
  });

  return (
    <>
      {publishInfo?.simpleModal ? (
        <SimpleUpdateForm header={header} form={form} onSubmit={onSubmit} />
      ) : (
        <ObjectForm
          id={id}
          type={type}
          header={header}
          form={form}
          onSubmit={onSubmit}
          publicationValues={publicationValues}
          isSubmittingSave={isSubmittingSave}
          isSubmittingPublish={isSubmittingPublish}
          isProjectProcessViewPending={isProjectProcessViewPending}
          processData={processData}
          showErrorFiles={showErrorFiles}
          publishInfo={publishInfo}
          textProcess={textProcess}
          isProcessWork={isProcessWork}
          isEdit
        />
      )}
    </>
  );
};
