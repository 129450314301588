import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Wrapper } from "../../../UI/templates";
import style from "../style.module.scss";
import { withClassCurry } from "../../../utils";
import { UserList } from "../../../UI/components/indexPageLists/UserList/UserList";
import { selectorAppTheme } from "../../../../app/feature/app/app";
import { SortButton } from "../../../UI/components/Buttons/SortButton/SortButton";
import { Paginate } from "../../../UI/components/Pagination/Pagination";
import { UserSkeletonList } from "../../../UI/components/indexPageLists/UserList/UserSkeletonList";
import { ApiGetCustomersList } from "../../../../app/services/api/indexPages/customersPage";
import { CustomerFormHead } from "./CustomersFormHead";
import {
  selectorCustomerList,
  selectorCustomerMeta,
  setCustomers,
} from "../../../../app/feature/indexPages/users/customerPage/customerPage";
import { createPaginationMeta } from "../../../../app/feature/indexPages/createData";
import { IndexPageTitle } from "../../../features/IndexPageTitle/IndexPageTitle";
import { request } from "../../../../app/services/api/requestHandler";
import { useTitle } from "../../../hooks/useTitle";
import {
  FiltersType,
  InitialParamType,
  MetaType,
} from "../../../../types/other/pages/formationOfParameters";
import { createUrlSearchString } from "../../../../app/services/url/createUrlSearchString";

export const CustomersPage = () => {
  useTitle("Заказчики");
  const theme = useSelector(selectorAppTheme);
  const withTheme = withClassCurry(style[theme]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoad, setLoad] = useState(true);

  const [searchString, setSearchString] = useState(location.search);
  const [currentFilters, setCurrentFilters] = useState<FiltersType>(null);
  const [currentMetaPage, setCurrentMeta] = useState<MetaType>({
    sort: "-rating.customer",
  });
  const [currentInitialSearchParams, setInitialSearchParams] =
    useState<InitialParamType>(null);

  const data = {
    users: useSelector(selectorCustomerList),
    meta: useSelector(selectorCustomerMeta),
  };

  const params = queryString.parse(location.search);

  useEffect(() => {
    setInitialSearchParams(params as InitialParamType);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    navigate({
      search: searchString,
    });

    setLoad(true);
    if (searchString.length === 0) return;
    request(ApiGetCustomersList(searchString), (res, _, headersValues) => {
      const meta = createPaginationMeta(headersValues);
      dispatch(setCustomers(res, meta));
      setLoad(false);
    })(dispatch);
  }, [searchString]);

  useEffect(() => {
    if (firstLoad) return;
    createUrlSearchString(
      {
        ...currentInitialSearchParams,
        ...currentMetaPage,
        ...currentFilters,
      },
      setSearchString
    );
  }, [currentMetaPage, currentFilters, currentInitialSearchParams]);

  const onChangePage = (event: any, page: number) => {
    setCurrentMeta({ ...currentMetaPage, page });
  };

  const onClickSort = (type: string, isUp: boolean) => {
    const sortString = `${isUp ? "" : "-"}${type}`;
    setCurrentMeta({ ...currentMetaPage, sort: sortString });
  };

  const onResetFilter = () => {
    setInitialSearchParams({});
    setCurrentFilters({});
  };

  const onSubmitFilter = (data: any) => {
    setCurrentFilters(data);
  };

  const onClickLink = (url: string) => {
    const params = url.split("?");
    setCurrentFilters(queryString.parse(params[1]) as Record<string, any>);
  };

  const paginate = (
    <Paginate
      page={Number(data.meta.paginationCurrentPage)}
      handleChange={onChangePage}
      count={data.meta.paginationPageCount}
      theme={theme}
      variant="outlined"
      shape="rounded"
    />
  );

  return (
    <div className={style.wrapper}>
      <div
        className={classNames(withTheme(style.container), style.listWrapper)}
      >
        <IndexPageTitle
          clickLink={onClickLink}
          title={"Заказчики"}
          description={`Владеют финансами и хотят безупречный проект.`}
          theme={theme}
          page={"author"}
        />
        <CustomerFormHead
          submitHandler={onSubmitFilter}
          resetHandler={onResetFilter}
          initialValues={params}
          theme={theme}
        />
      </div>
      <Wrapper>
        <div className={style.paginationContainer}>
          {data.users?.length > 0 && paginate}
          <div className={style.sortingContainer}>
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="id"
              text="По дате регистрации"
              clickHandler={onClickSort}
              isShow={params.sort === "id" || params.sort === "-id"}
              isUp={params.sort === "id"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="rating.customer"
              text="По рейтингу"
              clickHandler={onClickSort}
              isShow={
                params.sort === "rating.customer" ||
                params.sort === "-rating.customer"
              }
              isUp={params.sort === "rating.customer"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
            <SortButton
              posVariant={style.sortButtonMargin}
              theme={theme}
              sortType="countActiveProject"
              text="По активным проектам"
              clickHandler={onClickSort}
              isShow={
                params.sort === "countActiveProject" ||
                params.sort === "-countActiveProject"
              }
              isUp={params.sort === "countActiveProject"}
              variant="outlined"
              className={style.sortButton}
              isDisable={isLoad}
            />
          </div>
        </div>
      </Wrapper>
      {isLoad ? (
        <UserSkeletonList theme={theme} />
      ) : (
        <UserList
          customers
          isShowUserLabels={false}
          items={data.users}
          theme={theme}
        />
      )}
      {data.users?.length > 0 && (
        <Wrapper>
          <div className={style.paginationContainer}>{paginate}</div>
        </Wrapper>
      )}
    </div>
  );
};
