import * as Yup from "yup";
import {
  dateFutureRequired,
  stringRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const editRemarkRowSchema = Yup.object().shape({
  text: stringRequired("Текст"),
  dateLimit: dateFutureRequired("Контрольный срок"),
});
