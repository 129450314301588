import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  ChatLeftText,
  Download,
  FileEarmarkArrowDown,
} from "react-bootstrap-icons";
import cn from "classnames";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./ExecutionTask.module.scss";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { CloudLink } from "./CloudLink";

type TProps = {
  taskId: number;
  parentId: number;
  taskName: string;
  userName: string;
  filesCount: number;
  dates?: ReactNode;
  media?: ReactNode;
  actions?: ReactNode;
  checks?: ReactNode;
  className?: string;
  cloudLink?: string;
  can?: ApiTaskResponses.Can;
  comments?: ApiTaskResponses.Comment[];
};

export const ExecutionTask = ({
  taskId,
  parentId,
  taskName,
  userName,
  filesCount = 0,
  dates,
  media,
  actions,
  checks,
  className,
  cloudLink,
  can,
  comments,
}: TProps) => {
  const isMobile = useResolution(1200);

  const commentsCount = comments?.length ? comments.length : 0;
  const filesLink = filesCount
    ? `${config.localDomain}/task/download-files?id=${parentId}`
    : "";

  const { handleOpenModal } = useModal();

  const handleShowComments = () => {
    if (comments) {
      handleOpenModal({
        name: ModalNames.PublishedObject.COMMENTS,
        comments,
      });
    } else {
      handleOpenModal({
        name: ModalNames.Task.COMMENTS,
        taskId,
      });
    }
  };

  if (isMobile) {
    return (
      <Card className={styles.mobileCard}>
        <div className={styles.upper}>
          <div className={styles.upper__titles}>
            <h2>{taskName}</h2>
            <p>{userName}</p>
          </div>
          <div>{dates}</div>
        </div>
        <div className={styles.lower}>
          <div className={styles.lower__buttons}>
            {filesCount ? (
              <Link
                className={cn(styles.mobileButton, styles.mobileButton_files)}
                to={`${config.localDomain}/task/download-files?id=${parentId}`}
                target="_blank"
              >
                <IconButton>
                  <p>
                    <FileEarmarkArrowDown />
                    <span>{filesCount}</span>
                  </p>
                </IconButton>
              </Link>
            ) : (
              <IconButton
                disabled
                className={cn(styles.mobileButton, styles.mobileButton_files)}
              >
                <p>
                  <FileEarmarkArrowDown />
                  <span>{filesCount}</span>
                </p>
              </IconButton>
            )}
            <IconButton
              className={styles.mobileButton}
              onClick={handleShowComments}
            >
              <ChatLeftText />
            </IconButton>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className={className}>
      <div className={styles.upper}>
        <div className={styles.upper__titles}>
          <h2>
            {taskName}
            <CloudLink canBimitUser={can?.bimitUser} cloudLink={cloudLink} />
          </h2>
          <p>{userName}</p>
        </div>
        {dates}
        {actions}
      </div>
      <div className={styles.lower}>
        <div className={styles.lower__buttons}>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!filesCount}
            borderRadius={20}
            endIcon={<Download />}
            target="_blank"
            href={filesLink}
          >
            Вложение ({filesCount})
          </Button>
          <Button
            onClick={handleShowComments}
            variant="outlined"
            color="success"
            borderRadius={20}
            disabled={!commentsCount && !comments?.length}
          >
            Комментарии ({commentsCount})
          </Button>
          {checks}
        </div>
        <div>{media}</div>
      </div>
    </Card>
  );
};
