import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  fetchGetLogTypes,
  fetchProjectHistory,
  ProjectHistoryType,
} from "./thunks";
import { createPaginationMeta } from "../../indexPages/createData";

interface InitialState {
  isLoad: boolean;
  date1: Date | null;
  date2: Date | null;
  user: string;
  events: { id: string; title: string } | null;
  personValue: {
    userFio: string;
    date1: Date | null;
    date2: Date | null;
    type: {
      title: string;
      id: string;
    };
    data: string;
  };
  error: any;
  logs: ProjectHistoryType["result"];
  meta: any;
  newTypes: { id: string; title: string }[];
  countFilter: number;
  isSearched: boolean;
}

const initialState: InitialState = {
  isLoad: true,
  date1: null,
  date2: null,
  events: null,
  personValue: {
    userFio: "",
    date1: null,
    date2: null,
    type: {
      title: "",
      id: "",
    },
    data: "",
  },
  isSearched: false,
  error: null,
  logs: [],
  newTypes: [],
  meta: {},
  user: "",
  countFilter: 0,
};

const projectHistorySlice = createSlice({
  name: "projectHistorySlice",
  initialState,
  reducers: {
    setPersonValueReducer(state, action: PayloadAction<string>) {
      state.personValue.userFio = action.payload;
    },
    setDateReducer(state, action: PayloadAction<Date[]>) {
      const date1 = action.payload[0];
      const date2 = action.payload[1];
      state.personValue.date1 = date1;
      state.personValue.date2 = date2;
    },
    setDate1(state, action) {
      state.date1 = action.payload;
    },
    setDate2(state, action) {
      state.date2 = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setEventsReducer(state, action) {
      state.personValue.type = action.payload;
    },
    setDataReducer(state, action: PayloadAction<string>) {
      state.personValue.data = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setCountFilter(state, action) {
      state.countFilter = action.payload;
    },
    setIsSearch(state, action) {
      state.isSearched = action.payload;
    },
    resetData(state) {
      state.user = "";
      state.date1 = null;
      state.date2 = null;
      state.events = null;
      state.personValue = initialState.personValue;
    },
    applyFilter(state, action) {
      state.personValue = { ...state.personValue, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectHistory.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectHistory.fulfilled, (state, action) => {
      state.isLoad = false;
      state.logs = action.payload.result;
      state.meta = createPaginationMeta(action.payload.head);
    });
    builder.addCase(fetchProjectHistory.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGetLogTypes.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchGetLogTypes.fulfilled, (state, action) => {
      state.isLoad = false;
      state.newTypes = Object.keys(action.payload).map((id: any) => ({
        id,
        title: action.payload[id],
      }));
    });
    builder.addCase(fetchGetLogTypes.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
  },
});
export const {
  setPersonValueReducer,
  setDateReducer,
  setEventsReducer,
  setDataReducer,
  setDate1,
  setDate2,
  setEvents,
  setUser,
  resetData,
  applyFilter,
  setCountFilter,
  setIsSearch,
} = projectHistorySlice.actions;
export const getProjectHistory = (state: RootState) => state.projectHistory;
export default projectHistorySlice.reducer;
