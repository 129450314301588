import { Controller } from "react-hook-form";
import { Button, Checkbox, Modal, Paragraph } from "src/FSD/shared/uiKit/v2";
import { SelectAgreement } from "src/FSD/features/v2/SelectAgreement";
import { SetState } from "src/FSD/shared/lib/types/setState";
import { SelectExecutorTypeMultiple } from "src/FSD/features/v2/SelectExecutorType";
import { SelectUserTypeToAssign } from "src/FSD/features/v2/SelectUserTypeToAssign";
import { SelectExecutor } from "src/FSD/features/v2/SelectExecutor";
import { usePublicationForm } from "../../lib/hooks/usePublicationForm";
import { TFormCache } from "../../model/types/fieldValues";
import styles from "../styles.module.scss";

export type Props = {
  processId: number;
  setCurrentPage: SetState<number>;
  formCache: TFormCache;
  setFormCache: SetState<TFormCache>;
};

export const PublicationForm = ({
  processId,
  setCurrentPage,
  formCache,
  setFormCache,
}: Props) => {
  const {
    control,
    showExecutorAssignment,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    isSubmitting,
  } = usePublicationForm({
    processId,
    setCurrentPage,
    formCache,
    setFormCache,
  });

  return (
    <Modal.Form>
      <Paragraph color="light">Договор</Paragraph>
      <div className={styles.agreements}>
        <Controller
          name="secure"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectAgreement
              value={value}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              label="Договор и Акты"
            />
          )}
        />
        <Controller
          name="withDocs"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectAgreement
              value={value}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              label="Безопасная сделка"
            />
          )}
        />
      </div>
      <Paragraph color="light">
        Дополнительные требования к разработке раздела
      </Paragraph>
      <div className={styles.checks}>
        <Controller
          name="vor"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              editing
              label="Ведомость объемов работ"
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <Controller
          name="kac"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              editing
              label="Конъюнктурный анализ цен"
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <Controller
          name="vorPnr"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              editing
              label="ВОР на пусконаладочные работы"
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
          )}
        />
        <Controller
          name="bim"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              editing
              label="BIM"
              checked={value}
              onChange={() => {
                onChange(!value);
              }}
            />
          )}
        />
      </div>
      <Paragraph color="light">Требование к исполнителю</Paragraph>
      <Controller
        name="executorType"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SelectExecutorTypeMultiple
            value={value}
            changeHandler={(newValue) => {
              onChange(newValue);
            }}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />
      <Button
        onClick={() =>
          setValue("showExecutorAssignment", !showExecutorAssignment)
        }
        width={270}
      >
        Назначение исполнителя
      </Button>
      {showExecutorAssignment && (
        <div className={styles.executorAssignment}>
          <Controller
            name="executorByAdmin"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectExecutor
                publishedObjectId={processId}
                value={value}
                changeHandler={(_, newValue) => {
                  onChange(newValue);
                }}
                type={"ProjectProcess"}
                disableClear
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="executorTypeByAdmin"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const executor = watch("executorByAdmin");

              return (
                <SelectUserTypeToAssign
                  publishedObjectId={processId}
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  executor={executor}
                  type={"ProjectProcess"}
                  disableClear
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              );
            }}
          />
        </div>
      )}
      <Modal.Controls>
        <Button
          onClick={() => {
            handleSubmit((dataValues) => onSubmit(dataValues, "back"))();
          }}
          color="secondary"
          type="button"
        >
          Назад
        </Button>
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            handleSubmit((dataValues) => onSubmit(dataValues, "draft"))();
          }}
          variant="outlined"
        >
          Сохранить в черновике
        </Button>
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            handleSubmit((dataValues) => onSubmit(dataValues, "publishing"))();
          }}
        >
          Опубликовать
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
