import cn from "classnames";
import styles from "./FormSteps.module.scss";
import { Step } from "./Step";

type Props = {
  steps: Record<string, string>;
  currentStep: string;
  className?: string;
};

export const FormSteps = ({ steps, currentStep, className }: Props) => {
  return (
    <div className={cn(styles.formSteps, className)}>
      {Object.entries(steps).map(([stepId, stepName], index) => (
        <Step key={index} isSelected={stepId === currentStep} index={index}>
          {stepName}
        </Step>
      ))}
    </div>
  );
};
