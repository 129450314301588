import React, { memo } from "react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
  getProjectProcessInfoProjectName,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../styles/restyle";
import classes from "../../../Project/View/Info/InfoGip/style.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import cls from "./ModalCompleteRemove.module.scss";
import { fetchProjectProcessRemoveExecutorComplete } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessRemoveExecutorComplete";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";

interface Inputs {
  status: number;
  withoutFeedback: string;
  comment: string;
}
interface ModalCompleteRemoveProps {
  closeModal: () => void;
}
export const ModalCompleteRemove = memo(
  ({ closeModal }: ModalCompleteRemoveProps) => {
    const processName = useAppSelector(getProjectProcessInfoName);
    const projectName = useAppSelector(getProjectProcessInfoProjectName);
    const processData = useAppSelector(getProjectProcessInfoData);
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    const { handleSubmit, register, setValue } = useForm<Inputs>();

    const formSubmitHandler = async (dataValue: Inputs) => {
      const data = {
        CompleteForm: {
          status: dataValue.status,
          withoutFeedback: dataValue.withoutFeedback === "Да",
          comment: dataValue.comment,
        },
      };
      const taskId = processData?.activeAccess?.task_remove_id;
      if (taskId && processId) {
        await dispatch(
          fetchProjectProcessRemoveExecutorComplete({ taskId, data })
        );
        dispatch(fetchProjectProcessViewDuplicate(processId));
        closeModal();
      }
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{`Рассмотрение заявки на Запрос на снятие исполнителя «${processName}» по проекту «${projectName}».`}</h1>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          InputLabelProps={{
            shrink: true,
          }}
          label="Комментарий от руководителя проекта"
          value={processData?.activeAccess?.taskRemove?.content}
          disabled={true}
        />
        {processData?.activeAccess?.taskRemove?.data
          ?.visibleWithoutFeedback && (
          <FormControl {...textFieldSX}>
            <InputLabel id="type">Запретить оценку</InputLabel>
            <Select
              {...register("withoutFeedback")}
              labelId="withoutFeedback"
              label="Запретить оценку"
              defaultValue="Нет"
            >
              <MenuItem sx={menuItemSx} value="Нет">
                Нет
              </MenuItem>
              <MenuItem sx={menuItemSx} value="Да">
                Да
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <TextField
          {...textFieldSX}
          {...register("comment")}
          multiline
          minRows={2}
          label="Комментарий"
          placeholder="Введите комментарий"
        />
        <div className={cls.form_submit}>
          <CustomButton
            type="submit"
            width={160}
            onClick={() => setValue("status", 4)}
            background={palette.blue}
            className={classes.gipButton}
          >
            Согласиться
          </CustomButton>
          <CustomButton
            type="submit"
            width={160}
            onClick={() => setValue("status", 5)}
            background={palette.red}
            className={classes.gipButton}
          >
            Отказать
          </CustomButton>
        </div>
      </form>
    );
  }
);
