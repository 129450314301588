import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import C from "./constants";
import { appErrorInitialState } from "./appErrorInitialState";
import { StateType } from "../../initialState";

export interface AppErrorType {
  code: number;
  message: string;
  name: string;
  status: number;
}

export const appErrorReducer = createReducer(
  appErrorInitialState,
  (builder) => {
    builder.addCase(
      C.CREATE_APP_ERROR,
      (state, action: PayloadAction<AppErrorType>) => {
        return {
          ...state,
          ...action.payload,
        };
      }
    );
    builder.addCase(C.CLEAR_APP_ERROR, () => {
      return {
        ...appErrorInitialState,
      };
    });
  }
);

export const createAppError = createAction(
  C.CREATE_APP_ERROR,
  (payload: AppErrorType) => ({ payload })
);

export const selectorAppErrorData = (state: StateType) => state.appError;
