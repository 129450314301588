import React, { memo, useState } from "react";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useProjectProcessDelegateMutation } from "src/FSD/entities/projectProcess/api";

type TProps = {
  delegate: boolean;
  userId: number;
};

export const Delegate = memo(({ delegate, userId }: TProps) => {
  const [projectProcessDelegate, { isLoading }] =
    useProjectProcessDelegateMutation();
  const { handleClose } = useModalContext();
  const processId = useAppSelector(getProjectProcessInfoId);
  const [checkedDelegate, setCheckedDelegate] = useState(delegate);

  const onSubmit = async () => {
    const data = {
      delegate: checkedDelegate,
    };
    if (processId) {
      await projectProcessDelegate({ id: processId, userId, data });
    }
    handleClose();
  };

  return (
    <Modal.Layout>
      <Modal.Header>Делегирование прав на исполнение раздела</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <Checkbox
          label="Делегировать права"
          onChange={() => setCheckedDelegate(!checkedDelegate)}
          value={checkedDelegate}
          checked={checkedDelegate}
          editing
        />
        <Modal.Controls>
          <Button disabled={isLoading} isLoading={isLoading} onClick={onSubmit}>
            Сохранить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
});
