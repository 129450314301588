import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import style from "../style.module.scss";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { RegionSelect } from "../../../features/SpecialForm/RegionSelect";
import { createSearchObjectDataFromFormParams } from "../../../../app/services/searchParams/searchParamsCreateData";
import { Rating } from "../../../features/SpecialForm/Rating";
import { buttonStyle } from "../Executors/ExecutorFormHead";
import { Select } from "../../../UI/components/FormComponentns/Select";
import { textFieldSX } from "../../../../styles/restyle";

const experienceOptions = [
  {
    id: "0;3",
    title: "До 3 лет",
  },
  {
    id: "0;5",
    title: "До 5 лет",
  },
  {
    id: "0;10",
    title: "До 10 лет",
  },
  {
    id: "10;100",
    title: "Свыше 10 лет",
  },
];

const findExperience = (value: string | undefined) =>
  experienceOptions.find((item) => item.id === value) ?? {
    id: undefined,
    title: "",
  };

export const findRating = (value: string | undefined) => {
  const splitter = value?.split(";");
  return splitter?.length === 2 ? splitter[1] : "5";
};

type TFormValues = Record<string, any>;

export type CustomerFormHeadType = ComponentPropsType<{
  submitHandler?: (params: Record<string, any>) => void;
  resetHandler?: () => void;
  initialValues?: Record<string, any>;
}>;

export const CustomerFormHead: React.FC<CustomerFormHeadType> = ({
  theme = "dark",
  submitHandler = () => {},
  resetHandler = () => {},
  initialValues = {},
}) => {
  const { register, setValue, handleSubmit, watch, reset } =
    useForm<TFormValues>({
      defaultValues: {
        fio: initialValues["GipUserSearch[fio]"],
        region: initialValues["GipUserSearch[region_code]"],
        rating: findRating(initialValues["GipUserSearch[rating]"]),
        executedProject: initialValues["GipUserSearch[executedProject]"],
        experience: findExperience(initialValues["GipUserSearch[jobDuration]"]),
      },
    });

  const watchRegion = watch("region");
  const watchExperience = watch("experience");
  const watchRating = watch("rating");

  const onSubmit = (data: TFormValues) => {
    const searchData = createSearchObjectDataFromFormParams(
      // @ts-ignore
      { ...data, experience: data.experience.id },
      "gip"
    );
    submitHandler(searchData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div className={style.formContainer}>
        <div className={style.formGroup}>
          <TextField
            {...register("fio")}
            {...textFieldSX}
            className={style.formElement}
            label="ФИО"
          />
          <RegionSelect
            variant={style.formElement}
            changeHandler={(data) => {
              setValue("region", data ? data.code : undefined);
            }}
            value={watchRegion}
          />
          <Select
            variant={style.formElement}
            changeHandler={(_, value) => {
              setValue("experience", value);
            }}
            value={watchExperience}
            label={"Стаж"}
            options={experienceOptions}
          />
          <Rating
            value={watchRating}
            changeHandler={(val) => {
              setValue("rating", val);
            }}
          />
        </div>
        <div className={style.right}>
          <div className={style.buttonFormWrapper}>
            <Button
              style={buttonStyle}
              theme={theme}
              variant="contained"
              color="primary"
              type={"submit"}
            >
              Поиск
            </Button>
            <Button
              style={buttonStyle}
              theme={theme}
              variant="contained"
              color="grey"
              clickHandler={() => {
                resetHandler();
                reset();
                setValue("fio", "");
                setValue("region", undefined);
                setValue("rating", "5");
                setValue("executedProject", undefined);
                setValue("experience", undefined);
              }}
            >
              Сбросить
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
