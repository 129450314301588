import moment from "moment";

/*
  Функция, что форматирует дату
 */
export const DDMMYYYYtoMMYYYY = (dateString: string): string =>
  moment(dateString, "DD.MM.YYYY").format("MM.YYYY");

/*
  @param dateFrom - DD.MM.YYYY
  @param dateTo - "DD.MM.YYYY" | null
  @returns "MM.YYYY - MM.YYYY" | "MM.YYYY - По настоящее время"
 */
export const getGapDateString = (
  dateFrom: string,
  dateTo: string | null
): string => {
  const dateFromN = DDMMYYYYtoMMYYYY(dateFrom);
  const dateToN = dateTo ? DDMMYYYYtoMMYYYY(dateTo) : "По настоящее время";
  return `${dateFromN} - ${dateToN}`;
};
export const getDateAndTime = (
  dateString: string | null,
  withTime = true
): string | null => {
  if (!dateString) return null;

  const [date, time] = dateString.split(" ");

  return withTime ? `${date} ${time}` : `${date}`;
};

export const getDateWithMonthName = (date: string | null): string => {
  if (!date) {
    return "Неизвестно";
  }

  const months: Record<string, string> = {
    January: "января",
    February: "февраля",
    March: "марта",
    April: "апреля",
    May: "мая",
    June: "июня",
    July: "июля",
    August: "августа",
    September: "сентября",
    October: "октября",
    November: "ноября",
    December: "декабря",
  };
  const sp = date.split(".");
  const month = months[moment.utc(date, "DD.MM.YYYY").format("MMMM")] || "";

  return `${+sp[0]} ${month} ${sp[2]}`;
};

export const timeStampToDate = (dateString: string): string =>
  moment(dateString, "DD.MM.YYYY").format("DD.MM.YYYY");

export const toUnixTimestamp = (dateString: string) =>
  moment(dateString, "DD.MM.YYYY").unix();
