import { useEffect } from "react";
import { useProjectGetExecutorFullTypesQuery } from "src/FSD/entities/project/api";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { toSelect } from "src/app/feature/project/projectProcessPublication";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";

type UseSelectProps = {
  setValue?: any;
  processWork?: ApiProcessWorkResponses.View;
};

export const useSelectExecutorType = ({
  setValue,
  processWork,
}: UseSelectProps) => {
  const { data: executorTypesRaw, isFetching } =
    useProjectGetExecutorFullTypesQuery();

  const executorTypes = useDataMapper(executorTypesRaw, toSelect);

  const isExecutorTypesLoading = isFetching || !executorTypes;

  useEffect(() => {
    if (!processWork) {
      return;
    }

    if (executorTypes && processWork.executorType) {
      const executorTypesProcessWork = processWork.executorType.map(
        (item) => item.key
      );

      const executorTypesFilter = executorTypes.filter((item: TSelectItem) =>
        executorTypesProcessWork.includes(Number(item.id))
      );

      setValue("executorTypes", executorTypesFilter);
    }
  }, [processWork, executorTypes]);

  return {
    executorTypes,
    isExecutorTypesLoading,
  } as const;
};
