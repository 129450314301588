import React, { memo } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { palette, textFieldSX } from "../../../../../../../../styles/restyle";
import cls from "./ModalCheckDocumentations.module.scss";
import { TextLink } from "../../../../../../../UI/components/TextLink/TextLink";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../app/store";
import { fetchProjectProcessCheckDocumentations } from "../../../../../../../../app/feature/ProjectProcessView/CheckTask/services/fetchProjectProcessCheckDocumentations";
import { fetchProjectProcessCheckTask } from "../../../../../../../../app/feature/ProjectProcessView/CheckTask/services/fetchProjectProcessCheckTask";
import { getProjectProcessInfoId } from "../../../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ApiPrimaryFileType } from "../../../../../../../../types/api/primaryTypes/apiPrimaryFileType";

interface ModalCheckDocumentationsProps {
  closeModal: () => void;
  files: ApiPrimaryFileType[];
  taskId: number;
  header: string;
  processIdDerrived?: number;
}

interface Inputs {
  comment: string;
  status: number;
}

const schema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: 5,
    then: Yup.string().required("Поле обязательно для заполнения"),
    otherwise: Yup.string(),
  }),
});

export const ModalCheckDocumentations = memo(
  ({
    closeModal,
    files,
    taskId,
    header,
    processIdDerrived,
  }: ModalCheckDocumentationsProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
    });

    const actualProcessId = processId ?? processIdDerrived;

    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        comment: dataValues.comment,
        status: dataValues.status,
      };
      await dispatch(fetchProjectProcessCheckDocumentations({ taskId, data }));
      if (actualProcessId) {
        dispatch(fetchProjectProcessCheckTask(actualProcessId));
      }
      closeModal();
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{header}</h1>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Замечания"
          {...register("comment")}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment ? errors.comment.message : null}
        />
        <fieldset className={cls.fieldset}>
          <legend>Документация на проверке</legend>
          <div className={cls.fieldset_files}>
            {files.map((file) => (
              <TextLink key={file.id} url={file.url} redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
        <div className={cls.form_submit}>
          <CustomButton
            width={160}
            background={palette.red}
            onClick={() => setValue("status", 5)}
            type="submit"
          >
            Отказать
          </CustomButton>
          <CustomButton
            width={160}
            background={palette.blue}
            onClick={() => setValue("status", 4)}
            type="submit"
          >
            Принять
          </CustomButton>
        </div>
      </form>
    );
  }
);
