import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import classes from "./style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import {
  fetchGipRequest,
  fetchProjectViewSearchGip,
} from "../../../../../../app/feature/ProjectView/thunks";
import { ApiGipType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";

interface Inputs {
  price: string;
  type: string;
  content: string;
}

interface RequestGipProps {
  gipTypes: ApiGipType["userTypes"];
  price: string;
}

const schema = Yup.object().shape({
  content: Yup.string().max(200),
  price: Yup.number()
    .required("Это поле обязательно для заполнения")
    .min(1000, `Стоимость работ ГИП не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость процесса не может быть больше 10 млн рублей!`)
    .typeError("Значение «Cтоимость» должно быть числом."),
  type: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
});

export const RequestGip: React.FC<RequestGipProps> = ({ gipTypes, price }) => {
  const sx = {
    bgcolor: palette.white,
    color: palette.common,
    "&.Mui-selected": {
      bgcolor: "#A7B0B7!important",
    },
  };
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      content: dataValues.content,
      price: dataValues.price,
      gip_type: +dataValues.type,
    };
    const { id } = params;
    if (id) {
      await dispatch(fetchGipRequest({ gip: { id, data } }));
      await dispatch(fetchProjectViewSearchGip(id));
    }
  };

  if (gipTypes) {
    return (
      <div>
        <form
          onSubmit={handleSubmit(formSubmitHandler)}
          className={classes.requestGip}
        >
          <h1>Заявиться</h1>
          <TextField
            {...register("price")}
            {...textFieldSX}
            label="Стоимость"
            defaultValue={price || ""}
            error={!!errors.price}
            helperText={errors.price ? errors.price.message : null}
          />
          <FormControl {...textFieldSX}>
            <InputLabel id="type">Форма собственности</InputLabel>
            <Select
              {...register("type")}
              labelId="type"
              label="Форма собственности"
              defaultValue={[]}
              error={!!errors.type}
            >
              {gipTypes.map((type: any) => (
                <MenuItem sx={sx} value={type.id} key={type.id}>
                  {type.listName}
                </MenuItem>
              ))}
            </Select>
            {errors.type ? (
              <p className={classes.error}>{errors.type.message}</p>
            ) : null}
          </FormControl>
          <TextField
            {...textFieldSX}
            multiline
            minRows={2}
            label="Комментарий к заявке"
            {...register("content")}
            placeholder="Введите комментарий"
            error={!!errors.content}
            helperText={errors.content ? errors.content.message : null}
          />
          <FormControl className={classes.requestGip_btn}>
            <CustomButton
              className={classes.searchGip_btn}
              background={palette.blue}
              width={160}
              type="submit"
            >
              Отправить
            </CustomButton>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
