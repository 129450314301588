import { ApiProjectResponses } from "../../../../../entities/project/model/responseTypes";

export const mapApplications = (
  applicationsRaw: Partial<ApiProjectResponses.GetApplications> | undefined
) => {
  if (!applicationsRaw) {
    return {
      applications: {},
      customer: "",
      warning: [],
      account: {
        id: 0,
        value: 0,
      },
    };
  }

  const applicationsRawClone = structuredClone(applicationsRaw);

  const data = {
    account: {
      id: applicationsRawClone.account!.id,
      value: Number(applicationsRawClone.account!.value),
    },
    customer: applicationsRawClone.customer!,
    // @ts-ignore
    warning: applicationsRawClone.warning!.map((warning) => warning.title),
  };

  delete applicationsRawClone.account;
  delete applicationsRawClone.customer;
  delete applicationsRawClone.warning;

  return {
    ...data,
    applications: applicationsRawClone,
  };
};
