import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { Select } from "../../UI/components/FormComponentns/Select";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetNewsTags } from "../../../app/services/api/getTags/ApiGetNewsTags";

export interface StatusTypeItem {
  title: string;
  id: string;
}

export type StatusTypeProps = ComponentPropsType<{
  changeHandler?: (type: StatusTypeItem) => void;
  value?: number | null;
}>;

export const TagType: React.FC<StatusTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  value,
}) => {
  const dispatch = useDispatch();
  const [allSpec, setAllSpec] = useState<StatusTypeItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    StatusTypeItem | undefined
  >(undefined);

  const onLoadTags = () => {
    request(ApiGetNewsTags(), (data) => {
      setAllSpec(
        data.map(
          (item: { id: number; label: string; date_create: string }) => ({
            id: item.id,
            title: item.label,
          })
        )
      );
    })(dispatch);
  };

  const onSearch = (data: string) => {
    const result = allSpec.find((item) => item.title === data);
    setSelectedValue(result || undefined);
  };

  useEffect(() => {
    onLoadTags();
  }, []);

  useEffect(() => {
    const result = allSpec.find((item) => String(item.id) === String(value));
    setSelectedValue(result || undefined);
  }, [value, allSpec]);

  return (
    <Select
      changeHandler={(_, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      searchHandler={onSearch}
      variant={classNames(variant)}
      label="Тег"
      options={allSpec}
    />
  );
};
