import { createSlice } from "@reduxjs/toolkit";
import {
  createBook,
  updateBook,
  formAgain,
  deleteBookByWorkId,
  fetchProjectComposition,
} from "./thunks";
import { RootState } from "src/app/store";

export type TComposition = {
  num: string;
  header: string;
  name: string;
  comment: string;
  objectId: number | null;
  objectClass: string;
  type: string;
  typeNum: string;
  can: {
    create: boolean;
    update: boolean;
    delete: boolean;
  };
};

type TState = {
  data: Record<string, TComposition[]>;
  pending: {
    getComposition: boolean;
    formAgain: boolean;
    createBook: boolean;
    deleteBook: boolean;
    updateBook: boolean;
  };
  error: {
    getComposition: string | null;
    formAgain: string | null;
    createBook: string | null;
    deleteBook: string | null;
    updateBook: string | null;
  };
  contentStatus: string;
};

const initialState: TState = {
  data: {
    project: [],
    work: [],
  },
  pending: {
    getComposition: false,
    formAgain: false,
    createBook: false,
    deleteBook: false,
    updateBook: false,
  },
  error: {
    getComposition: null,
    formAgain: null,
    createBook: null,
    deleteBook: null,
    updateBook: null,
  },
  contentStatus: "Проектная",
};

const projectCompositionSlice = createSlice({
  name: "projectComposition",
  initialState,
  reducers: {
    clearProjectComposition() {
      return initialState;
    },
    setContentStatus: (state, action) => {
      state.contentStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBook.pending, (state) => {
      state.pending.createBook = true;
    });
    builder.addCase(createBook.fulfilled, (state) => {
      state.pending.createBook = false;
    });
    builder.addCase(createBook.rejected, (state) => {
      state.pending.createBook = false;
    });
    builder.addCase(updateBook.pending, (state) => {
      state.pending.updateBook = true;
    });
    builder.addCase(updateBook.fulfilled, (state) => {
      state.pending.updateBook = false;
    });
    builder.addCase(updateBook.rejected, (state) => {
      state.pending.updateBook = false;
    });
    builder.addCase(deleteBookByWorkId.pending, (state) => {
      state.pending.deleteBook = true;
    });
    builder.addCase(deleteBookByWorkId.fulfilled, (state) => {
      state.pending.deleteBook = false;
    });
    builder.addCase(deleteBookByWorkId.rejected, (state) => {
      state.pending.deleteBook = false;
    });
    builder.addCase(formAgain.pending, (state) => {
      state.pending.formAgain = true;
    });
    builder.addCase(formAgain.fulfilled, (state) => {
      state.pending.formAgain = false;
    });
    builder.addCase(formAgain.rejected, (state) => {
      state.pending.formAgain = false;
    });
    builder.addCase(fetchProjectComposition.pending, (state) => {
      state.pending.getComposition = true;
    });
    builder.addCase(fetchProjectComposition.fulfilled, (state, action) => {
      state.pending.getComposition = false;
      state.data = action.payload;
    });
    builder.addCase(fetchProjectComposition.rejected, (state, action) => {
      state.pending.getComposition = false;
      state.error.getComposition = action.payload as string;
    });
  },
});

export const getProjectComposition = (state: RootState) =>
  state.projectComposition;

export const { clearProjectComposition, setContentStatus } =
  projectCompositionSlice.actions;

export default projectCompositionSlice.reducer;
