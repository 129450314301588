import { useEffect, useMemo } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { ApiProjectAccessResponses } from "src/FSD/entities/projectAccess/model/responseTypes";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { checkPaymentTerms } from "../helpers/checkPaymentTerms";
import { checkRow } from "../helpers/checkRow";
import { Props } from "../../ui/GroupFormWithPayment";
import { createGroupPublicationDefaultValues } from "../helpers/createGroupPublicationDefaultValues";
import { createGroupRequestDefaultValues } from "../helpers/createGroupRequestDefaultValues";
import {
  TGroupFieldValues,
  TGroupPublicationFieldValues,
} from "../../model/types/fieldValues";

type GroupFormType = "request" | "publication";

const defaultValuesCreators = {
  request: createGroupRequestDefaultValues,
  publication: createGroupPublicationDefaultValues,
};

type HookProps = Omit<Props, "groupPublishProcess"> & {
  type: GroupFormType;
  groupPublishProcess:
    | ApiProjectAccessResponses.GetGroupRequestStartForms
    | ApiProjectProcessResponses.GetGroupPublishProcess;
};

export const useGroupForm = ({
  groupPublishProcess,
  currentFormId,
  formCache,
  setFormCache,
  processId,
  currentPage,
  setCurrentPage,
  type,
}: HookProps) => {
  const defaultValues = useMemo(() => {
    if (formCache[currentFormId]) {
      return formCache[currentFormId] as TGroupFieldValues;
    }

    return defaultValuesCreators[type](
      // @ts-ignore
      groupPublishProcess,
      Number(currentFormId),
      processId
    );
  }, []);

  const form = useForm<TGroupFieldValues>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const isValid = !Object.keys(errors).length;

  const onSubmit = (dataValues: TGroupFieldValues, direction: 1 | -1) => {
    setFormCache((formCache) => ({
      ...formCache,
      [currentFormId]: dataValues,
    }));
    setCurrentPage((prev) => prev + direction);
  };

  const showBackwardButton = currentPage !== 0;

  useEffect(() => {
    if (!defaultValues) {
      return;
    }

    if (type === "publication") {
      checkPaymentTerms(form as UseFormReturn<TGroupPublicationFieldValues>);
    }

    defaultValues.processes.forEach((_, index) => {
      checkRow(index, form);
    });
  }, [defaultValues, form]);

  return {
    form,
    showBackwardButton,
    defaultValues,
    onSubmit,
    handleSubmit,
    isValid,
  } as const;
};
