import { memo } from "react";
import { useSelector } from "react-redux";
import { Card, List, Paragraph } from "src/FSD/shared/uiKit/v2";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/app.cofig";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { palette } from "src/styles/restyle";
import styles from "./InfoCustomer.module.scss";

const InfoExecutor = memo(() => {
  const { project } = useSelector(getProjectViewData);

  if (!project) {
    return null;
  }

  const { author, authorPremium } = project;

  return (
    <Card
      header={<Card.Header>Заказчик</Card.Header>}
      className={styles.customer}
    >
      <List gap={4} disableOverflow>
        <TextLink url={`/user/${author.id}`} className={styles.customer_name}>
          {author.name}
        </TextLink>
        <Paragraph className={styles.authorType}>{author.type}</Paragraph>
        {authorPremium && (
          <div className={styles.premium}>
            <span>Подписка: ПИРС</span>
            <span style={{ fontWeight: 700 }}>
              PRO (до {authorPremium.date_end})
            </span>
          </div>
        )}
      </List>
      <div className={styles.customer_avatar}>
        <NewAvatar
          photoUrl={`${config.localDomain}${author.photo}`}
          rating={author.rating}
          jobDuration={author.jobDuration}
          borderColor={palette.signalOrange}
        />
      </div>
      <div className={styles.customer_vector} />
    </Card>
  );
});

export default InfoExecutor;
