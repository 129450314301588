import { useMemo } from "react";
import { useProjectProcessGetDocumentationsQuery } from "src/FSD/entities/projectProcess/api";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";

interface TExecutionTasks {
  customerAlign: ApiProjectProcessResponses.TabCustomerAlign[];
  verification?: null;
  task: ApiProjectProcessResponses.TabTask[];
}

interface UseModalProps {
  projectProcessId: number;
  can: ApiProjectProcessResponses.View["can"];
}

export const useExecutionTasks = ({ projectProcessId, can }: UseModalProps) => {
  const { currentChildTab } = useTabsPanelContext();

  const isCustomerAlignTab = currentChildTab === "customerAlign";

  const canCustomerAlignStart = isCustomerAlignTab && can!.customerAlign;

  const canRequestCustomerAlignStart =
    isCustomerAlignTab && can!.requestCustomerAlign;

  const skip = !projectProcessId;

  const { data, isLoading } = useProjectProcessGetDocumentationsQuery(
    {
      id: projectProcessId,
      expand: ["svgIcon"],
    },
    {
      skip,
    }
  );

  const isExecutionTaskLoading = isLoading || !data;

  const executionTaskData: TExecutionTasks = useMemo(() => {
    if (!data) {
      return {
        customerAlign: [],
        task: [],
      };
    }

    return {
      customerAlign: data?.customerAlign,
      task: data?.task,
    };
  }, [data]);

  const executionTasks =
    executionTaskData[currentChildTab as keyof TExecutionTasks] || [];

  return {
    executionTasks,
    warning: data?.checks,
    isExecutionTaskLoading,
    canRequestCustomerAlignStart,
    canCustomerAlignStart,
    isCustomerAlignTab,
  };
};
