import { createPaginationMeta } from "../../../app/feature/indexPages/createData";
import { ApiGetOrganisationInfo } from "../../../app/services/api/organisation/organisation";
import { request } from "../../../app/services/api/requestHandler";
import { ApiOrganisationDataType } from "../../../types/api/responseTypes/apiOrganisationType";
import { MetaType } from "../../../types/other/pages/formationOfParameters";
import { OrganisationResponseType } from "../../../types/stateTypes/organisationType";

export const getOrganisationStaff = async (
  params: any,
  expands: string
): Promise<OrganisationResponseType> => {
  let data: ApiOrganisationDataType | any = [];
  let meta: MetaType;
  let statusCode: number | undefined;
  let totalCount = 0;

  const getData = async (page: number) =>
    request(
      ApiGetOrganisationInfo(
        `get-members?id=${params.id}&expand=${expands},role,user,gipAccountId,teams.members,can,firmAttorney,firmBoss&per-page=50&page=${page}`
      ),
      (res, _, headersValue, status) => {
        meta = createPaginationMeta(headersValue);
        data = data.concat(res);
        statusCode = status;

        if (page === 1) {
          totalCount = +meta.paginationPageCount;
        }
      }
    )();

  await getData(1);

  const dataPromises = [];
  for (let i = 2; i <= totalCount; ++i) {
    dataPromises.push(getData(i));
  }

  await Promise.all(dataPromises);

  return { data: data!, meta: meta!, status: statusCode! };
};

export const getOrganisation = async (
  section: string,
  params: any,
  searchString: any
): Promise<OrganisationResponseType> => {
  let data: ApiOrganisationDataType | any;
  let meta: MetaType;
  let statusCode: number | undefined;
  const organisationSections: Record<string, string> = {
    info: `view?id=${params.id}&expand=taskCheck,bankAccounts,can,requestToJoin,account_id,membersCount,joinToFirmTasks`,
    roles: `get-roles`,
    events: `get-log-types`,
    history: `get-logs?per-page=${5}&id=${params.id}&${searchString.replace(
      "?",
      "&"
    )}`,
    sync: `sync-by-dadata?id=${params.id}`,
  };

  const requestUrl = organisationSections[section];

  await request(
    ApiGetOrganisationInfo(requestUrl),
    (res, headersValue, headers, status) => {
      meta = createPaginationMeta(headersValue);
      data = res;
      statusCode = status;
    },
    () => (err) => {
      statusCode = err.status;
    }
  )();

  return { data: data!, meta: meta!, status: statusCode! };
};
