import { createAsyncThunk } from "@reduxjs/toolkit";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ThunkArg } from "src/FSD/shared/lib/types/thunkArg";
import { selectArrayToUrlParams } from "src/FSD/shared/lib/helpers/selectArrayToUrlParams";
import { createUrlSearchParamsFromObject } from "src/FSD/shared/lib/helpers/createUrlSearchString";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import * as KanbanDataMapper from "../lib/dataMappers";
import {
  ApiCreateFreeTask,
  ApiDeleteKanbanTask,
  ApiGetGroupsByFirmIdAndPartGroupId,
  ApiGetKanbanView,
  ApiGetMultilevelProjectsByUserTypeId,
  ApiGetUsersListByUserTypeId,
  ApiGetWorkFlowList,
  ApiKanbanComplete,
  ApiUpdateKanbanTask,
} from "./queries";
import { request } from "../../../../app/services/api/requestHandler";
import { pushSuccessMessage } from "../../../../app/feature/errorTrace";
import { KanbanType } from "..";
import {
  TUserSearchParams,
  createUserSearchParams,
} from "../lib/dataMappers/createUserSearchParams";
import { KanbanApiErrors } from "./errors";

export const fetchViewByUserTypeId = createAsyncThunk<any, any>(
  "kanban/fetchView",
  async (
    {
      userTypeId,
      filterValues,
    }: {
      userTypeId: number;
      filterValues: KanbanType.FilterValues;
      showSpinner: boolean;
    },
    { rejectWithValue }
  ) => {
    const filterSearchParams =
      KanbanDataMapper.createFilterURLParams(filterValues);

    let response: unknown;
    let error: unknown;

    await request(
      ApiGetKanbanView(userTypeId, filterSearchParams),
      (data) => {
        response = data;
      },
      () => () => {
        error = KanbanApiErrors.VIEW;
      }
    )();

    if (error && !response) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const fetchProjectsByUserTypeId = createAsyncThunk(
  "kanban/fetchProjectsByUserTypeId",
  async (projectId: number, { rejectWithValue }) => {
    let response: unknown;
    let error: unknown;

    await request(
      ApiGetMultilevelProjectsByUserTypeId(projectId),
      (data) => {
        response = data;
      },
      () => () => {
        error = KanbanApiErrors.GET_PROJECTS;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const fetchGroupsByFirmIdAndPartGroupId = createAsyncThunk(
  "kanban/fetchGroupsByFirmIdAndPartGroupId",
  async (
    { firmId, partGroups }: { firmId: number; partGroups: TSelectItem[] },
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    const partGroupsParams = selectArrayToUrlParams(
      "TeamSearch[part_group_id][]",
      partGroups
    );

    const partGroupsUrlParams =
      createUrlSearchParamsFromObject(partGroupsParams);

    await request(
      ApiGetGroupsByFirmIdAndPartGroupId(firmId, partGroupsUrlParams),
      (data) => {
        response = data;
      },
      () => () => {
        error = KanbanApiErrors.GET_GROUPS;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const fetchWorkFlowList = createAsyncThunk(
  "kanban/fetchWorkFlowList",
  async (_, { rejectWithValue }) => {
    let response: unknown;
    let error: unknown;

    await request(
      ApiGetWorkFlowList(),
      (data) => {
        response = data;
      },
      () => () => {
        error = KanbanApiErrors.GET_WORKFLOWS;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const fetchUsersListByUserTypeId = createAsyncThunk(
  "kanban/fetchUsersListByUserTypeId",
  async (searchParams: TUserSearchParams, { rejectWithValue }) => {
    const formedSearchParams = createUserSearchParams(searchParams);
    let response: unknown;
    let error: unknown;

    await request(
      ApiGetUsersListByUserTypeId(formedSearchParams),
      (data) => {
        response = data;
      },
      () => () => {
        error = KanbanApiErrors.GET_USERS_LIST;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const createFreeTask = createAsyncThunk(
  "kanban/createFreeTask",
  async (payload: KanbanType.FreeTaskFields, { dispatch, rejectWithValue }) => {
    let response: any;
    let error: unknown;

    const formedPayload = KanbanDataMapper.formNewTaskPayload(payload);

    await request(
      ApiCreateFreeTask(formedPayload),
      (data) => {
        response = data;
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const updateTask = createAsyncThunk(
  "kanban/updateTask",
  async (
    { taskId, status }: { taskId: number; status: number },
    { dispatch, rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    await request(
      ApiUpdateKanbanTask(taskId, { status }),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const deleteTask = createAsyncThunk(
  "kanban/deleteTask",
  async (taskId: number, { dispatch, rejectWithValue }) => {
    let response: unknown;
    let error: unknown;

    await request(
      ApiDeleteKanbanTask(taskId),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const complete = createAsyncThunk(
  "kanban/complete",
  async (
    {
      data: { id, formedData },
      events,
    }: ThunkArg<{
      id: number;
      formedData: { status: Statuses; comment: string };
    }>,
    { rejectWithValue }
  ) => {
    let response: unknown;
    let error: unknown;

    events.onPending?.();

    await request(
      ApiKanbanComplete(id, formedData),
      (data) => {
        events.onFulfilled?.(data);
        response = data;
      },
      () => (err) => {
        events.onRejected?.(err);
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    if (response) {
      return response;
    }
  }
);
