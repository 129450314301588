import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../services/api/requestHandler";
import { httpRequestGet } from "../../services/api/api";
import { config } from "src/app.cofig";

export type TObject = Record<string, string>;

export type TCountry = {
  code: string;
  title: string;
  title_full: string;
};

export const fetchConstantValues = createAsyncThunk(
  "constants/fetch",
  async () => {
    let roles: TObject = {};
    let countries: TCountry[] = [];
    let designTypes: TObject = {};

    await request(
      httpRequestGet(`${config.localDomain}/v1/firm/get-roles`),
      (data) => {
        roles = { ...data };
      },
      () => (err) => {
        throw Error(JSON.stringify(err));
      }
    )();

    await request(
      httpRequestGet(`${config.localDomain}/v1/project/get-design-types`),
      (data) => {
        designTypes = { ...data };
      },
      () => (err) => {
        throw Error(JSON.stringify(err));
      }
    )();

    await request(
      httpRequestGet(`${config.localDomain}/v1/country/get-full-list`),
      (data) => {
        countries = [...data];
      },
      () => (err) => {
        throw Error(JSON.stringify(err));
      }
    )();

    return { roles, countries, designTypes };
  }
);
