import * as Yup from "yup";
import {
  dateFutureRequired,
  dateLimitExceedsDateStartRequired,
} from "src/utils/SimplifiedYup";

export const editSimpleContentSchema = Yup.object().shape({
  dateStart: dateFutureRequired("Начальный срок"),
  dateLimit: dateLimitExceedsDateStartRequired("Конечный срок"),
});
