import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessStage } from "../services/fetchProjectProcessStage";
import {
  IMileStone,
  MilestoneType,
} from "../types/projectProcessMileStoneTypes";
import { fetchProjectProcessStageComplete } from "../services/fetchProjectProcessStageComplete";

function getTotalPercent(mileStone: MilestoneType["stages"], id: number) {
  const mile = mileStone.find((m) => m.id === id);
  if (!mile) {
    return 0;
  }
  let totalPercent = mile.percent || 0;
  if (mile.parent_id) {
    totalPercent += getTotalPercent(mileStone, mile.parent_id);
  }
  return totalPercent;
}

interface InitialState {
  isLoadMileStone: boolean;
  isLoadMileStoneComplete: boolean;
  stages: IMileStone[];
  availablePercent: number | undefined;
}

const initialState: InitialState = {
  isLoadMileStone: false,
  isLoadMileStoneComplete: false,
  stages: [],
  availablePercent: undefined,
};

const projectProcessMileStone = createSlice({
  name: "projectProcessMileStone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessStage.pending, (state) => {
      state.isLoadMileStone = true;
    });
    builder.addCase(fetchProjectProcessStage.fulfilled, (state, action) => {
      state.isLoadMileStone = false;
      const { stages, availablePercent } = action.payload;
      const newMileStone = stages.map((mile, index) => {
        let status: "greenCheck" | "grayCheck" | "gray" | "" = "";
        if (mile.complete) {
          status = "greenCheck";
        } else if (index > 0 && stages[index - 1].complete) {
          status = "grayCheck";
        } else {
          status = "gray";
        }

        const totalPercent = getTotalPercent(stages, mile.id);

        return {
          ...mile,
          status,
          totalPercent,
        };
      });
      state.availablePercent = availablePercent;
      state.stages = newMileStone;
    });
    builder.addCase(fetchProjectProcessStageComplete.pending, (state) => {
      state.isLoadMileStoneComplete = true;
    });
    builder.addCase(fetchProjectProcessStageComplete.fulfilled, (state) => {
      state.isLoadMileStoneComplete = false;
    });
  },
});
export default projectProcessMileStone;
