import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  ApiGetUserProjects,
  ApiInviteToProject,
  ApiInviteGip,
  ApiGetProjectsAsGipHelper,
} from "../../../../app/services/api/organisation/organisation";
import { request } from "../../../../app/services/api/requestHandler";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../../app/feature/modalClose/eventModalClose";
import style from "../style.module.scss";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { selectorEventUserAtUserPageData } from "../../../../app/feature/eventEmitter/user/eventUserJoin";
import { appErrorHandler } from "../../../../app/services/api/errorHandlers";
import { getUserId } from "../../../../app/services/auth/auth";
import { joinUserFormSchema } from "./yupSchemas";
import { JoinUserFormProps } from "./types";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { palette, textFieldSX } from "src/styles/restyle";
import { TSelectItem } from "../../../features/Forms/SetGipForm/types";
import { Select } from "../../../UI/components/FormComponentns/Select";

type TJoinUserForm = {
  comment: string;
  project: TSelectItem | null;
};

export const JoinUserForm: React.FC<JoinUserFormProps> = ({
  variant = "",
  closeHandler = () => {},
}) => {
  const dispatch = useDispatch();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TJoinUserForm>({
    resolver: yupResolver(joinUserFormSchema),
    defaultValues: {
      comment: "Приглашаю стать участником проекта!",
      project: null,
    },
  });

  const watchProject = watch("project");

  const [projectsList, setProjectsList] = useState<TSelectItem[]>([]);
  const [disabled, setDisabled] = useState(false);

  const { Popup } = useUserWorkflow();

  const currentUserId = getUserId();
  const { modal, userId } = useSelector(selectorEventUserAtUserPageData);
  const modalClosed: boolean = useSelector(selectorModalClosed);
  const snackbar = useSnackbar();

  useEffect(() => {
    const apiMethod = modal
      ? ApiGetUserProjects(currentUserId)
      : ApiGetProjectsAsGipHelper();

    request(
      apiMethod,
      (data) => {
        let dataModified: TSelectItem[] = [];

        if (modal) {
          dataModified = data.map((item: any) => ({
            title: item.name,
            id: item.id,
          }));
        } else {
          dataModified = Object.entries(data).map(([key, value]: any) => ({
            title: value,
            id: key,
          }));
        }

        setProjectsList(dataModified);

        if (dataModified.length > 0) {
          setValue("project", dataModified[0]);
        } else {
          setDisabled(true);
        }
      },
      appErrorHandler
    )();
  }, []);

  const onSubmit = async (data: any) => {
    if (!watchProject) {
      return;
    }

    setDisabled(true);
    const ApiInviteToProjectRequest = () =>
      ApiInviteToProject(userId, {
        ProjectInviteForm: {
          comment: data.comment,
          project_id: watchProject.id,
        },
      });
    const ApiInviteGipRequest = () =>
      ApiInviteGip(userId, {
        project_id: watchProject.id,
      });

    const currentRequestFetch = modal
      ? ApiInviteToProjectRequest
      : ApiInviteGipRequest;

    const currentRequestActionHandler = () => {
      snackbar.successSnackbar("Успешно!");
      setTimeout(() => {
        setDisabled(false);
        Popup.eventUserJoinAtUserPage.set({});
        closeHandler();
      }, 500);
    };
    const currentRequestErrorHandler = () => () => {
      snackbar.errorSnackbar(
        "Ошибка приглашения, сообщите администратору в чате"
      );
      setTimeout(() => {
        setDisabled(false);
        Popup.eventUserJoinAtUserPage.set({});
        closeHandler();
      }, 500);
    };
    await request(
      currentRequestFetch(),
      currentRequestActionHandler,
      currentRequestErrorHandler
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, variant)}
    >
      <Select
        value={watchProject}
        name={"project"}
        changeHandler={(_, value) => {
          setValue("project", value);
        }}
        label={"Проект"}
        options={projectsList}
        isLoading={false}
        disableClear
      />
      {disabled && projectsList.length === 0 && (
        <p className={style.noProjects}>У Вас нет активных проектов</p>
      )}
      <br />
      {Boolean(modal) && (
        <TextField
          {...register("comment")}
          name={"comment"}
          className={style.textField}
          variant="outlined"
          label="Комментарий"
          error={Boolean(errors.comment)}
          helperText={errors.comment?.message}
          multiline
          minRows={3}
          {...textFieldSX}
        />
      )}
      <CustomButton
        className={style.submitButton}
        disabled={disabled}
        forceDisabled={!projectsList.length}
        type="submit"
        background={palette.blue}
        width={160}
      >
        Отправить
      </CustomButton>
    </form>
  );
};
