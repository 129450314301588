import React, { FC, MouseEvent, memo } from "react";
import { IconButton } from "@mui/material";
import cn from "classnames";
import globalStyle from "../../Primary/style.module.scss";
import style from "../style.module.scss";
import { FolderSVG } from "../../Primary/svgs";
import { FolderArrow, FolderArrowFill } from "../../../../../newUI/SVG";

interface FolderPanelProps {
  short?: boolean;
  name: string;
  isOpen: boolean;
  color?: string | null;
  handleShowSubtree: (e: MouseEvent<HTMLElement>) => void;
  textColor?: string;
  canOpen: boolean;
  offset?: number;
}

const FolderPanel: FC<FolderPanelProps> = ({
  name,
  short,
  isOpen,
  color,
  handleShowSubtree,
  textColor,
  canOpen,
  offset = 0,
}) => (
  <div
    className={cn(style.folder, short && style.darkBorders)}
    onClick={handleShowSubtree}
    style={{ paddingLeft: `${20 + offset}px` }}
  >
    <FolderSVG color={color ?? "#9DAFBD"} />
    <p style={{ color: textColor }}>{name}</p>
    <IconButton disabled={!canOpen} className={globalStyle.dropDown}>
      {canOpen && <>{isOpen ? <FolderArrowFill /> : <FolderArrow />}</>}
    </IconButton>
  </div>
);

export default memo(FolderPanel);
