import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { ProjectProcessApi } from "src/FSD/shared/api/v1/projectProcess";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { customerAlignCompleteSchema } from "../schemas";
import { useAppDispatch } from "src/app/store";
import { pushError } from "src/app/feature/errorTrace";
import { toSelect } from "src/app/feature/project/projectProcessPublication";
import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { ApiTags } from "src/FSD/shared/api/tags";

type TFieldValues = {
  processes: TSelectItem[];
  executorComment: string;
  files: TFile[];
};

type TProcessesToCheck = {
  data: TSelectItem[];
  pending: boolean;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
};

export const useCustomerAlignComplete = ({
  id,
  defaultValues,
}: UseModalProps) => {
  const dispatch = useAppDispatch();
  const { handleClose } = useModalContext();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(customerAlignCompleteSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["taskProcess", "project"],
  });

  const [processesToCheck, setProcessesToCheck] = useState<TProcessesToCheck>({
    data: [],
    pending: false,
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("executorComment", taskData.comment);
  }, [taskData]);

  useEffect(() => {
    if (!taskData) {
      return;
    }

    dispatch(
      ProjectProcessApi.getProjectProcessToProcesses({
        data: {
          id,
          projectId: taskData.project!.id,
          processId: taskData.taskProcess!.id,
        },
        events: {
          onFulfilled: (data) => {
            setProcessesToCheck({
              data: toSelect(data),
              pending: false,
            });
          },
          onRejected: (error) => {
            dispatch(pushError(error));
            setProcessesToCheck({
              ...processesToCheck,
              pending: false,
            });
          },
        },
      })
    );
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      comment: data.executorComment,
      status: Statuses.IS_DONE,
      reviewProcessIds: data.processes.map((process) => process.id),
    };

    await dispatch(
      TaskApi.complete({
        data: { id, formedData },
        events: {
          onFulfilled: () => {
            handleClose();
            dispatch(
              rootApi.util.invalidateTags([
                ApiTags.ProjectProcess.GET_DOCUMENTATIONS,
              ])
            );
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  return {
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    control,
    isSubmitting,
    processesToCheck,
    isTaskViewPending: isFetching || !taskData,
  } as const;
};
