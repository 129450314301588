import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useTitle } from "../../hooks/useTitle";
import style from "./style.module.scss";
import {
  selectorBlogFirstFaceItems,
  selectorBlogLibraryItems,
  selectorBlogMediaItems,
  selectorBlogPlatformItems,
  setBlogItems,
  selectorBlogProjectItem,
} from "src/app/feature/indexPages/postPage/postPage";
import { request } from "src/app/services/api/requestHandler";
import {
  ApiGetPostsListForBlog,
  ApiGetRandomProject,
} from "src/app/services/api/posts/posts";
import { NewsPageSkeleton } from "../../UI/components/indexPageLists/ArticlesList/ArticlesSkeleton/ArticlesPageSkeleton";
import { TextLink } from "../../UI/components/TextLink/TextLink";

import {
  NewsPageLayoutProps,
  NewsPageTitle,
  MainPost,
  Post,
  FirstFace,
  Swiper,
  LIBRARY,
  PLATFORM,
  FIRST_FACE,
  MEDIA,
  NothingFound,
} from "../../UI/components/indexPageLists/ArticlesList/ArticlesFragments";
import {
  ApiPostProjectType,
  ApiPostType,
} from "src/types/api/responseTypes/apiPostType";
import { ApiPrimaryAuthorType } from "src/types/api/primaryTypes/apiPrimaryPostType";
import { config } from "src/app.cofig";

export const trimNumber = (value: string): string => {
  const result = new Intl.NumberFormat("ru-RU").format(+value);
  return Number.isNaN(+value) ? value : `${result.toLocaleString()} ₽`;
};

export const getAuthorName = (value: ApiPrimaryAuthorType): string =>
  `${value.name} ${value.surname}`;

type TitleInfoType = {
  boldTitle: string;
  normalTitle: string;
  text: string;
};

export const titleInfo: Readonly<Record<string, TitleInfoType>> = {
  library: {
    boldTitle: "библио",
    normalTitle: "тека",
    text: "Новости, комментарии, мнения – в строительной отрасли",
  },
  platform: {
    boldTitle: "новости",
    normalTitle: " платформы",
    text: "Новые возможности, общение с поддержкой",
  },
  face: {
    boldTitle: "от первого",
    normalTitle: " лица",
    text: "Интервью, ЖЗЛ, истории из практики",
  },
  media: {
    boldTitle: "ино",
    normalTitle: " сми",
    text: "",
  },
};

export const NewsPageLayout: React.FC<NewsPageLayoutProps> = ({
  library,
  platform,
  firstFace,
  media,
  project,
}) => {
  useTitle("Новости");

  const threePosts: boolean = library.length === 3;

  const libraryExists: boolean = library.length > 0;
  const platformExists: boolean = platform.length > 0;
  const firstFaceExists: boolean = firstFace.length > 0;
  const mediaExists: boolean = media.length > 0;
  const projectExists: boolean = Object.keys(project).length > 0;

  const control: boolean =
    libraryExists ||
    platformExists ||
    firstFaceExists ||
    mediaExists ||
    projectExists;

  return (
    <div className={style.wrapper}>
      {control ? (
        <>
          {libraryExists && (
            <>
              <TextLink
                variant={style.mainArticleLink}
                url={`/blog/section/library?`}
              >
                <NewsPageTitle
                  bigFont
                  boldTitle={titleInfo.library.boldTitle}
                  normalTitle={titleInfo.library.normalTitle}
                  text={titleInfo.library.text}
                />
              </TextLink>
              <div className={style.generalPosts}>
                <MainPost
                  key={library[0].id}
                  id={library[0].id}
                  imageSource={library[0].photoUrl}
                  date={library[0].date_publication}
                  title={library[0].title}
                  content={library[0].content}
                />
                <div className={style.articlesList}>
                  {libraryExists &&
                    library.map((post) => (
                      <Post
                        id={post.id}
                        key={post.id}
                        imageSource={post.photoUrl}
                        date={post.date_publication}
                        title={post.title}
                        content={post.content}
                      />
                    ))}
                  {threePosts && <div className={style.article} />}
                </div>
              </div>
            </>
          )}
          {projectExists && (
            <Swiper
              amount={trimNumber(project.processPublishedCost)}
              imageSource={`${config.localDomain}${project.bannerUrl}`}
              date={project.date_end}
              product={project.name}
              id={project.id}
            />
          )}
          {platformExists && (
            <>
              <TextLink
                variant={style.mainArticleLink}
                url={`/blog/section/platform?`}
              >
                <NewsPageTitle
                  boldTitle={titleInfo.platform.boldTitle}
                  normalTitle={titleInfo.platform.normalTitle}
                  text={titleInfo.platform.text}
                />
              </TextLink>
              <div className={classNames(style.platformNews, style.wide)}>
                {platformExists &&
                  platform.map((post) => (
                    <Post
                      id={post.id}
                      key={post.id}
                      imageSource={post.photoUrl}
                      date={post.date_publication}
                      title={post.title}
                      content={post.content}
                    />
                  ))}
              </div>
            </>
          )}
          {firstFaceExists && (
            <>
              <TextLink
                variant={style.mainArticleLink}
                url={`/blog/section/face?`}
              >
                <NewsPageTitle
                  boldTitle={titleInfo.face.boldTitle}
                  normalTitle={titleInfo.face.normalTitle}
                  text={titleInfo.face.text}
                />
              </TextLink>
              <FirstFace
                id={firstFace[0]?.id}
                key={firstFace[0]?.id}
                name={getAuthorName(firstFace[0]?.author)}
                position={""}
                imageSource={firstFace[0]?.photoUrl}
                title={firstFace[0]?.title}
                date={firstFace[0]?.date_publication}
                content={firstFace[0]?.content}
              />
            </>
          )}
          {mediaExists && (
            <>
              <TextLink
                variant={style.mainArticleLink}
                url={`/blog/section/media?`}
              >
                <NewsPageTitle
                  boldTitle={titleInfo.media.boldTitle}
                  normalTitle={titleInfo.media.normalTitle}
                  text={titleInfo.media.text}
                />
              </TextLink>
              <div className={classNames(style.platformNews, style.wide)}>
                {mediaExists &&
                  media.map((post) => (
                    <Post
                      id={post.id}
                      key={post.id}
                      imageSource={post.photoUrl}
                      date={post.date_publication}
                      title={post.title}
                      content={post.content}
                    />
                  ))}
              </div>
            </>
          )}
        </>
      ) : (
        <NothingFound />
      )}
    </div>
  );
};

export const NewsPage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [isLoad, setLoad] = useState(true);

  const data = {
    library: useSelector(selectorBlogLibraryItems),
    platform: useSelector(selectorBlogPlatformItems),
    firstFace: useSelector(selectorBlogFirstFaceItems),
    media: useSelector(selectorBlogMediaItems),
    project: useSelector(selectorBlogProjectItem),
  };

  const getPostsByTagAndAmount = async (
    tag: string,
    num: number
  ): Promise<ApiPostType["post"]> => {
    let data: ApiPostType["post"];

    try {
      await request(ApiGetPostsListForBlog(tag, num), (res) => {
        data = res;
      })();
    } catch (_error) {
      data = [];
    }

    return data!;
  };

  const getRandomProject = async (): Promise<ApiPostProjectType> => {
    let project: ApiPostProjectType;
    try {
      await request(ApiGetRandomProject(), (res) => {
        if (!res.id) {
          throw new Error();
        }

        project = _.pick(res, [
          "processPublishedCost",
          "name",
          "date_end",
          "id",
          "bannerUrl",
        ]);
      })();
    } catch (_error) {
      project = {
        name: "",
        processPublishedCost: "",
        date_end: "",
        id: 0,
        bannerUrl: "",
      };
    }

    return project!;
  };

  useEffect(() => {
    (async () => {
      setLoad(true);

      const library: ApiPostType["post"] = await getPostsByTagAndAmount(
        LIBRARY,
        4
      );
      const platform: ApiPostType["post"] = await getPostsByTagAndAmount(
        PLATFORM,
        4
      );
      const firstFace: ApiPostType["post"] = await getPostsByTagAndAmount(
        FIRST_FACE,
        1
      );
      const media: ApiPostType["post"] = await getPostsByTagAndAmount(MEDIA, 2);

      const project: ApiPostProjectType = await getRandomProject();

      dispatch(setBlogItems(library, platform, firstFace, media, project));
      setLoad(false);
    })();
  }, [dispatch, history]);

  return isLoad ? (
    <NewsPageSkeleton />
  ) : (
    <NewsPageLayout
      library={data.library}
      platform={data.platform}
      firstFace={data.firstFace}
      media={data.media}
      project={data.project}
    />
  );
};
