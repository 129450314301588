import { MouseEventHandler, ReactNode } from "react";
import cn from "classnames";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { TColor } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import styles from "../Material/Material.module.scss";

type TProps = {
  title: ReactNode;
  children: ReactNode;
  isBold?: boolean;
  color?: TColor;
  className?: string;
  fontWeight?: "medium" | "bold";
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const TextItemMaterial = ({
  title,
  children,
  color = "light",
  className,
  fontWeight,
  onClick,
}: TProps) => {
  return (
    <div
      className={cn(styles.cardMaterial__content_item, className)}
      onClick={onClick}
    >
      {typeof title === "string" ? (
        <Paragraph fontWeight={fontWeight} color={color}>
          {title}
        </Paragraph>
      ) : (
        title
      )}
      {children}
    </div>
  );
};
