import React, { useState } from "react";
import classNames from "classnames";
import { Link as LinkInnerApp, useLocation } from "react-router-dom";
import style from "../DropdownMenuHeader/style.module.scss";
import { Counter } from "../Counter/Counter";
import { Glyphicon } from "../CustomIcon/Glyphicon/GlyphIcon";

import { navigationType } from "../../../../types/stateTypes/navigation";
import { ApiLogout } from "../../../../app/services/api/site/site";
import { isBadStatusRequest } from "../../../../app/services/api/api";
import { redirectToIndexPage } from "../../../../utils/redirectOnMainPage";
import { clientLogoutClearAllData } from "../../../../app/services/auth/auth";
import { Avatar } from "../Avatar/Avatar";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { isClientRoute } from "../../../../app/services/clientAppRoutes/clientAppRoutes";

export interface NavigationItemHeadProps {
  item: navigationType;
  clickHandler: VoidFunction;
}

const Link: React.FC<{
  link: string;
  variant?: string;
  isActive: boolean;
  alt?: string;
  isRedirect?: boolean;
}> = ({ link, isRedirect = true, alt = "", children, variant }) => {
  const location = useLocation();
  const path = location.pathname;
  const isActive = path.includes(link) && link !== "";
  const isLogoutLink = link === "/site/logout";
  return link ? (
    <>
      {isRedirect ? (
        <a
          onClick={(e) => {
            if (isLogoutLink) e.preventDefault();
          }}
          className={classNames(style.link, isActive && style.active, variant)}
          style={isLogoutLink ? { padding: 0, height: "auto" } : {}}
          title={alt}
          href={link}
        >
          {children}
        </a>
      ) : (
        <LinkInnerApp
          to={link}
          title={alt}
          className={classNames(style.link, isActive && style.active, variant)}
        >
          {children}
        </LinkInnerApp>
      )}
    </>
  ) : (
    <span className={classNames(style.link, variant)}>{children}</span>
  );
};

export const NavigationItemHead: React.FC<NavigationItemHeadProps> = ({
  item,
  clickHandler,
}) => {
  const { info } = item;
  const [isActive, setActive] = useState(false);
  const { Popup } = useUserWorkflow();

  const onClick = () => {
    setActive(!isActive);
    clickHandler();
  };

  return (
    <div
      className={classNames(
        style.label,
        isActive && style.active,
        item.items && item.items.length > 0 && style.mobileTriangle
      )}
      onClick={onClick}
    >
      <Link
        // @ts-ignore
        variant={item.info.counter && item.info.counter && style.counterWrapper}
        link={item.url || ""}
        alt={info.alt || info.label}
        isActive={isActive}
        isRedirect={!isClientRoute(item.url!)}
      >
        <>
          {
            // Создаем иконку если она есть, иначе просто текст
            info.icon && info.icon.type ? (
              <span className={style.icon}>
                <Glyphicon
                  type={info.icon.type}
                  size="20px"
                  // Должна ли она скрываться
                  variant={style.desktop || ""}
                />

                <span
                  className={classNames(
                    style.mobile || style.disable,
                    style.desktopOnlyBefore,
                    item.items && item.items.length > 0 && style.mobileTriangle
                  )}
                >
                  <span>{item.info.label}</span>
                  {info.counter && (
                    <Counter
                      count={item.info.counter!}
                      variantStyle="orange"
                      variant={style.counter}
                    />
                  )}
                </span>
              </span>
            ) : info.image && info.image.src ? (
              <>
                <Avatar
                  variant={classNames(
                    style.desktop,
                    style.avatar,
                    style.headerAvatar
                  )}
                  src={info.image.src}
                />
                <span className={style.mobile || style.disable}>
                  {item.info.label}
                </span>
              </>
            ) : (
              <>
                {item.info.label === "Выход" ? (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      ApiLogout()
                        .then((res) => res.json())
                        .then((res) => {
                          if (isBadStatusRequest(res.code)) {
                            throw new Error(res);
                          }
                          return res;
                        })
                        .then((res) => {
                          clientLogoutClearAllData();
                          return res;
                        })
                        .then(() => {
                          setTimeout(() => {
                            redirectToIndexPage();
                          }, 100);
                        })
                        .catch((err) => {
                          Popup.error.set(err.message, "Ошибка");
                          Popup.error.open();
                        });
                    }}
                    className={classNames(
                      style.logout,
                      (item.items &&
                        item.items.length > 0 &&
                        classNames(style.mobileTriangle, style.triangle)) ||
                        ""
                    )}
                  >
                    <span>{item.info.label}</span>
                  </span>
                ) : (
                  <span
                    className={
                      (item.items &&
                        item.items.length > 0 &&
                        classNames(style.mobileTriangle, style.triangle)) ||
                      ""
                    }
                  >
                    <span>{item.info.label}</span>
                  </span>
                )}
              </>
            )
          }
        </>
      </Link>
      {info.counter && (
        <Counter
          count={item.info.counter!}
          url={item.url}
          variantStyle="orange"
          variant={classNames(style.counter, style.counterDesk, style.desktop)}
        />
      )}
    </div>
  );
};
