import * as Yup from "yup";
import {
  dateFutureRequired,
  filesAreRequired,
  stringMaxLengthRequired,
  stringMinMaxLengthRequired,
} from "src/utils/SimplifiedYup";

export const updateMaterialProcessSchema = Yup.object().shape({
  comment: stringMinMaxLengthRequired("Комментарий", 15, 200),
  dateLimit: dateFutureRequired("Срок предоставления КП"),
  nameRequest: stringMaxLengthRequired("Наименование запроса", 200),
  template: Yup.mixed().when(["files", "templateListLength"], {
    is: (files, templateListLength) => !files.length && templateListLength,
    then: Yup.mixed()
      .nullable()
      .required("Необходимо заполнить «Шаблон опросного листа» или «Файлы»"),
  }),
  files: Yup.mixed().when("templateListLength", {
    is: (templateListLength) => !templateListLength,
    then: filesAreRequired(),
  }),
});
