import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAccordionState } from "src/FSD/shared/uiKit/v2";
import { useProjectGetApplicationsQuery } from "src/FSD/entities/project/api";
import { Nullable } from "../../../../../../../types/generics";
import { TFieldValues } from "../../model/fieldValues";
import { mapApplications } from "../../model/mapApplications";

type TProps = {
  projectId: number;
};

export const useApplications = ({ projectId }: TProps) => {
  const [isComparison, setComparison] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [groupId, setGroupId] = useState<Nullable<number>>(null);
  const [selectedUserTypeIds, setSelectedUserTypeIds] = useState<number[]>([]);

  const [bankErrorTaskIds, setBankErrorTaskIds] = useState<number[]>([]);

  const { data: applicationsRaw, isFetching } = useProjectGetApplicationsQuery({
    id: projectId,
  });

  const isApplicationsLoading = !applicationsRaw || isFetching;

  const { applications, customer, account, warning } =
    mapApplications(applicationsRaw);

  const form = useForm<TFieldValues>({
    defaultValues: {},
  });

  const { handleExpand, handleShrink, expandedIds } = useAccordionState();

  return {
    form,
    groupId,
    setGroupId,
    applications,
    isComparison,
    setComparison,
    selectedUserTypeIds,
    setSelectedUserTypeIds,
    projectId,
    totalPrice,
    setTotalPrice,
    isApplicationsLoading,
    bankErrorTaskIds,
    setBankErrorTaskIds,
    customer,
    account,
    warning,
    accordionState: { handleExpand, handleShrink, expandedIds },
  };
};
