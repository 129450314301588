import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage, request } from "src/app/services/api/requestHandler";
import {
  ApiProjectProcessToProcess,
  ApiProjectGetInTask,
  ApiProjectGetOutTask,
  ApiAcceptTask,
  ApiNotAcceptTask,
  ApiCompleteTask,
  ApiBackToWork,
  ApiProjectGetAllTask,
  ApiGetCommentsByTaskId,
} from "src/app/services/api/project/tasks";
import { ApiTaskDateUpdate } from "src/app/services/api/project/project";
import { ResponseAllTaskType } from "./types";
import { setStatus } from "./projectTask";
import { pushError } from "../../errorTrace";

export type TaskValueType = {
  tasks?: TaskValueType[];
  partName: string;
  dot: "warning" | null;
  count: number;
  executorName: string;
  header: string;
  title: string;
  project?: string;
  code: string;
  taskStatusInfo: {
    bgClass: "pirs-gray" | "pirs-red" | "pirs-green";
    date: string;
    dot: string;
    statusString: string;
  };
  can: {
    accept: boolean;
    reject: boolean;
    chat: boolean;
    complete: boolean;
    "back-to-work": boolean;
    "not-required": boolean;
    "update-date-deadline": boolean;
  };
  minDate: string;
  maxDate: string;
  taskCheckId: number;
  comment: string;
  files: {
    url: string;
    name: string;
    id: number;
    svgIcon: string;
  }[];
  task: {
    date_create: string;
    date_deadline: string;
    id: number;
    name: string;
    need_files: boolean;
    comment: string;
    status: {
      key: number;
      value: string;
    };
    workflow_data: {
      taskToChekId: number;
      processId: number;
      toProcessId: number;
    };
  };
};

type TaskTreeType = Record<string, TaskValueType>;

export const fetchProjectTaskInTree = createAsyncThunk<TaskTreeType, number>(
  "fetchProjectTaskTree/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiProjectGetInTask(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectTaskOutTree = createAsyncThunk<TaskTreeType, number>(
  "fetchProjectTaskOutTree/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiProjectGetOutTask(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectTaskAll = createAsyncThunk<
  ResponseAllTaskType,
  { id: number; status: string; load?: string }
>(
  "fetchProjectTaskAll/fetch",
  async ({ id, status = "" }, { rejectWithValue, dispatch }) => {
    try {
      if (status === "2") {
        dispatch(setStatus({ check: false, done: false, work: true }));
      }
      if (status === "3") {
        dispatch(setStatus({ check: false, done: true, work: false }));
      }
      if (status === "6") {
        dispatch(setStatus({ check: true, done: false, work: false }));
      }
      if (status === "") {
        dispatch(setStatus({ check: false, done: false, work: false }));
      }
      const response = await ApiProjectGetAllTask(id, status);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchAcceptTask = createAsyncThunk<void, number>(
  "fetchAcceptTask/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiAcceptTask(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export interface INotAcceptTaskProps {
  id: number;
  data: {
    comment: string;
    status: number;
  };
}
export const fetchNotAcceptTask = createAsyncThunk<void, INotAcceptTaskProps>(
  "fetchNotAcceptTask/fetch",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ApiNotAcceptTask(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export interface ICompleteTaskProps {
  id: number;
  data: {
    status: number;
    comment: string;
    upload_files: any;
  };
}
export const fetchCompleteTask = createAsyncThunk<void, ICompleteTaskProps>(
  "fetchCompleteTask/fetch",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ApiCompleteTask(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchBackToWork = createAsyncThunk<void, number>(
  "fetchBackToWork/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiBackToWork(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

interface IProjectProcessTypeProps {
  projectId: number;
  processId: number;
}
export type TProjectProcessType = Record<string, string>;
export const fetchProjectProcessType = createAsyncThunk<
  TProjectProcessType,
  IProjectProcessTypeProps
>("fetchProjectProcessType/fetch", async (props, { rejectWithValue }) => {
  const { projectId, processId } = props;
  try {
    const response = await ApiProjectProcessToProcess(projectId, processId);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

interface ITaskDateDeadlineUpdateProps {
  id: number;
  dataParams: {
    date_deadline: string;
  };
}
export const fetchTaskDateDeadlineUpdate = createAsyncThunk<
  any,
  ITaskDateDeadlineUpdateProps
>("fetchTaskDateDeadlineUpdate/fetch", async (data, { rejectWithValue }) => {
  const { id, dataParams } = data;
  try {
    const response = await ApiTaskDateUpdate(id, dataParams);
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    return result;
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchCommentsByTaskId = createAsyncThunk<any, number>(
  "fetchTask/fetch",
  async (taskId, { dispatch }) => {
    let response: any = {};
    await request(
      ApiGetCommentsByTaskId(taskId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
