import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { ProjectProcessApi } from "src/FSD/shared/api/v1/projectProcess";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { requestCustomerAlignCompleteSchema } from "../schemas";
import { useAppDispatch } from "src/app/store";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { pushError } from "src/app/feature/errorTrace";
import { toSelect } from "src/app/feature/project/projectProcessPublication";
import { Nullable } from "src/types/generics";

type TFieldValues = {
  processes: TSelectItem[];
  executorComment: string;
  files: TFile[];
  status: Nullable<Statuses>;
};

type TProcessesToCheck = {
  data: TSelectItem[];
  pending: boolean;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useRequestCustomerAlignComplete = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(requestCustomerAlignCompleteSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["taskProcess", "project"],
  });

  const [processesToCheck, setProcessesToCheck] = useState<TProcessesToCheck>({
    data: [],
    pending: false,
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("executorComment", taskData.comment);
  }, [taskData]);

  useEffect(() => {
    if (!taskData) {
      return;
    }

    dispatch(
      ProjectProcessApi.getProjectProcessToProcesses({
        data: {
          id,
          projectId: taskData.project!.id,
          processId: taskData.taskProcess!.id,
        },
        events: {
          onFulfilled: (data) => {
            setProcessesToCheck({
              data: toSelect(data),
              pending: false,
            });
          },
          onRejected: (error) => {
            dispatch(pushError(error));
            setProcessesToCheck({
              ...processesToCheck,
              pending: false,
            });
          },
        },
      })
    );
  }, [taskData]);

  const status = watch("status");

  const onSubmit = async (data: TFieldValues) => {
    const formData = new FormData();

    formData.append("CompleteForm[comment]", data.executorComment);
    formData.append("CompleteForm[status]", String(data.status));

    data.files.forEach(({ file }) => {
      formData.append("CompleteForm[upload_files][]", file!);
    });

    data.processes.forEach((process) => {
      formData.append("CompleteForm[reviewProcessIds][]", String(process.id));
    });

    await dispatch(
      TaskApi.completeMultiData({
        data: { id, formData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  return {
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    control,
    isSubmitting,
    processesToCheck,
    isRejecting: isSubmitting && status === Statuses.IS_REJECT,
    isAccepting: isSubmitting && status === Statuses.DONE,
    isTaskViewPending: isFetching || !taskData,
  } as const;
};
