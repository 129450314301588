import { ReactNode, memo } from "react";
import styles from "./Column.module.scss";

type TProps = {
  children: ReactNode;
};

const ColumnHeader = memo(({ children }: TProps) => {
  return <div className={styles.header}>{children}</div>;
});

export default ColumnHeader;
