import React from "react";
import styles from "./ExecutionTask.module.scss";
import { trimRubPennies } from "src/utils/trimNumber";

interface Props {
  paidOut?: { price: number; nds: number };
}

export const ExecutionTaskPaidOut = ({ paidOut }: Props) => {
  if (paidOut?.price) {
    return (
      <div className={styles.dates}>
        <div>
          {paidOut.price && (
            <span className={styles.priceText}>
              {trimRubPennies(paidOut.price)}
            </span>
          )}
          <small> {paidOut.nds ? "В т. ч. НДС" : "без НДС"}</small>
        </div>
        <div>Оплачено</div>
      </div>
    );
  }

  return null;
};
