import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { PublishedObjectView } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Description as UIDescription } from "src/FSD/shared/uiKit/v2/Description/Description";
import styles from "./Description.module.scss";

interface Props {
  publishedObject: PublishedObjectView;
}

export const Description = ({ publishedObject }: Props) => {
  return (
    <Card
      className={styles.description}
      header={<Card.Header>{publishedObject.title}</Card.Header>}
    >
      <UIDescription
        text={publishedObject.project!.description}
        linesCount={3}
      />
    </Card>
  );
};
