import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { processWorkApi } from "src/FSD/entities/processWork/api";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";
import { projectApi } from "src/FSD/entities/project/api";
import { Nullable } from "src/types/generics";

type TUseQueriesProps = {
  id: number;
  type: "Project" | "ProjectProcess" | "ProcessWork";
  executor?: Nullable<TSelectItem>;
};

export const useQueries = ({ id, type, executor }: TUseQueriesProps) => {
  const [
    getUserTypeToAssignWork,
    { data: userTypeToAssignWorkRaw, isLoading: isLoadingWork },
  ] = processWorkApi.useLazyProcessWorkGetUserTypeToAssignQuery();

  const [
    getUserTypeToAssignProcess,
    { data: userTypeToAssignProcessRaw, isLoading: isLoadingProcess },
  ] = projectProcessApi.useLazyProjectProcessGetUserTypeToAssignQuery();

  const [
    getUserTypeToAssignProject,
    { data: userTypeToAssignProjectRaw, isLoading: isLoadingProject },
  ] = projectApi.useLazyProjectGetUserTypeToAssignQuery();

  const fetchGetGetUserTypeToAssignWork = () => {
    getUserTypeToAssignWork({
      id,
    });
  };

  const fetchGetUserTypeToAssignProcess = () => {
    if (!executor) {
      return;
    }

    getUserTypeToAssignProcess({
      id,
      userId: +executor.id,
    });
  };

  const fetchGetUserTypeToAssignProject = () => {
    if (!executor) {
      return;
    }

    getUserTypeToAssignProject({
      id,
      userId: +executor.id,
    });
  };

  const dataMap = {
    ProcessWork: {
      fetchGetGetUserTypeToAssign: fetchGetGetUserTypeToAssignWork,
      userTypeToAssignRaw: userTypeToAssignWorkRaw,
      isLoading: isLoadingWork,
    },
    ProjectProcess: {
      fetchGetGetUserTypeToAssign: fetchGetUserTypeToAssignProcess,
      userTypeToAssignRaw: userTypeToAssignProcessRaw,
      isLoading: isLoadingProcess,
    },
    Project: {
      fetchGetGetUserTypeToAssign: fetchGetUserTypeToAssignProject,
      userTypeToAssignRaw: userTypeToAssignProjectRaw,
      isLoading: isLoadingProject,
    },
  };

  const { fetchGetGetUserTypeToAssign, userTypeToAssignRaw, isLoading } =
    dataMap[type];

  return { fetchGetGetUserTypeToAssign, userTypeToAssignRaw, isLoading };
};
