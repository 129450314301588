import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = RequestParams<{
  projectId: number;
  data: {
    taskIds: number[];
  };
}>;

export const groupSelectExecutor = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ projectId, data }) => ({
      url: `v1/project-access/group-select-executor`,
      params: {
        projectId,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [],
  });
