import { Tooltip } from "src/FSD/shared/uiKit/v2/Tooltip";
import { IconButton } from "@mui/material";
import styles from "./ProviderFirm.module.scss";

type TIconWithTooltipProps = {
  title: string;
  icon: any;
  onClick?: () => void;
  color?: string;
};

export const IconWithTooltip = ({
  title,
  icon,
  onClick,
  color,
}: TIconWithTooltipProps) => (
  <Tooltip title={title} classNamePopper={styles.tooltip}>
    {onClick ? (
      <IconButton sx={{ padding: "0px" }} onClick={onClick}>
        {icon(color)}
      </IconButton>
    ) : (
      icon(color)
    )}
  </Tooltip>
);
