import { createSlice } from "@reduxjs/toolkit";
import { fetchExpertiseUpdatePublish } from "../services/fetchExpertiseUpdatePublish";
import { fetchExpertisePublish } from "../services/fetchExpertisePublish";
import { fetchExpertiseCreateDocument } from "../services/fetchExpertiseCreateDocument";
import { fetchExpertiseConclusionCreate } from "../services/fetchExpertiseConclusionCreate";

interface InitialState {
  isLoadUpdatePublish: boolean;
  isLoadPublish: boolean;
  isLoadCreateDocument: boolean;
  isLoadCreateConclusion: boolean;
  createDocumentSuccess: boolean;
  publishSuccess: boolean;
  publishUpdateSuccess: boolean;
  error422: any;
  error422Document: any;
  error422Update: any;
}

const initialState: InitialState = {
  isLoadPublish: false,
  isLoadUpdatePublish: false,
  isLoadCreateDocument: false,
  isLoadCreateConclusion: false,
  createDocumentSuccess: false,
  publishSuccess: false,
  publishUpdateSuccess: false,
  error422Document: undefined,
  error422: undefined,
  error422Update: undefined,
};

const expertiseHeaderBtn = createSlice({
  name: "expertiseHeaderBtn",
  initialState,
  reducers: {
    setClearDocumentSuccess: (state) => {
      state.createDocumentSuccess = false;
      state.publishSuccess = false;
      state.publishUpdateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpertiseUpdatePublish.pending, (state) => {
      state.isLoadUpdatePublish = true;
      state.error422Update = undefined;
      state.publishUpdateSuccess = false;
    });
    builder.addCase(fetchExpertiseUpdatePublish.fulfilled, (state) => {
      state.isLoadUpdatePublish = true;
      state.isLoadUpdatePublish = false;
      state.publishUpdateSuccess = true;
    });
    builder.addCase(fetchExpertiseUpdatePublish.rejected, (state, action) => {
      state.error422Update = action.payload?.message;
      state.isLoadUpdatePublish = false;
    });
    builder.addCase(fetchExpertisePublish.pending, (state) => {
      state.isLoadPublish = true;
      state.error422 = undefined;
      state.publishSuccess = false;
    });
    builder.addCase(fetchExpertisePublish.fulfilled, (state) => {
      state.publishSuccess = true;
      state.isLoadPublish = false;
    });
    builder.addCase(fetchExpertisePublish.rejected, (state, action) => {
      state.error422 = action.payload?.message;
      state.isLoadPublish = false;
    });
    builder.addCase(fetchExpertiseCreateDocument.pending, (state) => {
      state.isLoadCreateDocument = true;
      state.error422Document = undefined;
      state.createDocumentSuccess = false;
    });
    builder.addCase(fetchExpertiseCreateDocument.fulfilled, (state) => {
      state.createDocumentSuccess = true;
      state.isLoadCreateDocument = false;
    });
    builder.addCase(fetchExpertiseCreateDocument.rejected, (state, action) => {
      state.error422Document = action.payload?.message;
      state.isLoadCreateDocument = false;
    });
    builder.addCase(fetchExpertiseConclusionCreate.pending, (state) => {
      state.isLoadCreateConclusion = true;
    });
    builder.addCase(fetchExpertiseConclusionCreate.fulfilled, (state) => {
      state.isLoadCreateConclusion = false;
    });
  },
});

export const { setClearDocumentSuccess } = expertiseHeaderBtn.actions;
export default expertiseHeaderBtn;
