import React, { memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, DateField, Modal, TextField } from "src/FSD/shared/uiKit/v2";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { createOutTaskSchema } from "../lib/schemas/createRemarkRow";
import { SelectReviewProcesses } from "../../../../../../features/v2/SelectReviewProcesses";
import { useRemarkRowCreateMutation } from "../../../../../../entities/expertise/remarkRow/api";
import { toServerDate } from "../../../../../../shared/lib/helpers/toServerDate";
import { Nullable } from "../../../../../../../types/generics";

type TInputs = {
  text: string;
  comment: string;
  date_limit: Nullable<Date>;
  project_id: TSelectItem;
  project_part_ids: TSelectItem[];
};

interface ModalCreateRemarkRowProps {
  remarkId: number;
  processId: number;
}

export const UpdateRemarkRowForm = memo(
  ({ remarkId, processId }: ModalCreateRemarkRowProps) => {
    const { handleClose } = useModalContext();
    const [remarkRowCreate, { isLoading }] = useRemarkRowCreateMutation();

    const {
      handleSubmit,
      control,
      setValue,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(createOutTaskSchema),
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        text: dataValues.text,
        comment: dataValues.comment,
        date_limit: toServerDate(dataValues.date_limit)!,
        project_id: Number(dataValues.project_id.id),
        project_part_ids: dataValues.project_part_ids?.map(
          ({ id }) => id as number
        ),
      };

      await remarkRowCreate({ remarkId, data })
        .unwrap()
        .then(() => {
          handleClose();
        });
    };

    return (
      <Modal.Layout>
        <Modal.Header>Добавление строки замечания</Modal.Header>
        <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Текст замечания"
                placeholder="Введите текст"
                multiline
                minRows={2}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="comment"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Комментарий"
                placeholder="Введите текст"
                multiline
                minRows={2}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="date_limit"
            render={({ field: { onChange, name } }) => (
              <DateField
                label="Контрольный срок"
                changeDateHandler={onChange}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
          <Controller
            name="project_part_ids"
            control={control}
            render={({ field: { onChange, name } }) => (
              <SelectReviewProcesses
                processId={processId}
                projectFieldLabel="Объект"
                projectFieldName="project_id"
                onProjectChange={(newValue) => {
                  setValue("project_id", newValue);
                }}
                processesFieldLabel="Разделы"
                processesFieldName={name}
                onProcessesChange={(newValue) => {
                  onChange(newValue);
                }}
                errors={errors}
              />
            )}
          />
          <Modal.Controls>
            <Button type="submit" isLoading={isLoading}>
              Сохранить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      </Modal.Layout>
    );
  }
);
