import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface ModalVisibilityState {
  visible: boolean;
  redirect: boolean;
  modalName: string;
  tab: string;
}

const initialState: ModalVisibilityState = {
  visible: false,
  redirect: false,
  modalName: "",
  tab: "",
};

const modalVisibilitySlice = createSlice({
  name: "modalVisibility",
  initialState,
  reducers: {
    setTabVisible(state, action) {
      state.tab = action.payload;
      state.visible = true;
      state.redirect = false;
    },
    setNameVisible(state, action) {
      state.modalName = action.payload;
      state.visible = true;
      state.redirect = false;
    },
    setRedirectVisible(state) {
      state.modalName = "";
      state.visible = true;
      state.redirect = true;
    },
    setVisible(state) {
      state.modalName = "";
      state.visible = true;
      state.redirect = false;
    },
    setInvisible(state) {
      state.modalName = "";
      state.visible = false;
      state.redirect = false;
    },
  },
});

export const getModalVisibility = (state: RootState) => state.modalVisibility;

export const {
  setTabVisible,
  setVisible,
  setRedirectVisible,
  setInvisible,
  setNameVisible,
} = modalVisibilitySlice.actions;
export default modalVisibilitySlice.reducer;
