import { createAsyncThunk } from "@reduxjs/toolkit";
import { pushError } from "../../../../errorTrace";
import { ApiUpdatePublishExpertise } from "src/app/services/api/projectProcess/ExpertiseApi/expertiseHeaderBtn";
import { fetchProjectProcessView } from "../../../Info/services/fetchProjectProcessView";

export const fetchExpertiseUpdatePublish = createAsyncThunk<
  boolean,
  any,
  { rejectValue: { status: string; message: string } }
>(
  "expertise/fetchExpertiseUpdatePublish",
  async ({ processId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiUpdatePublishExpertise(processId, data);
      if (!response.ok) {
        throw await response.json();
      }
      dispatch(fetchProjectProcessView(processId));
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);
