import React, { memo, useEffect } from "react";
import moment from "moment/moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import parse from "html-react-parser";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessDateLimit,
  getProjectProcessDateStart,
  getProjectProcessExecutorTypeForm,
  getProjectProcessInfoData,
  getProjectProcessInfoName,
} from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getProjectProcessUserTypes,
  getProjectProcessUserTypesIsLoad,
} from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getUserTypesProjectProcess";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import { fetchProjectProcessRequest } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessRequest";
import {
  getProjectProcessRequestError422,
  getProjectProcessRequestSuccess,
} from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { setNullRequest } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/slice/projectProcessHeaderBtn";
import {
  menuItemSx,
  palette,
  textFieldSX,
} from "../../../../../styles/restyle";
import cls from "./ModalRequest.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import {
  getProjectProcessExecutorBank,
  getProjectProcessExecutorBankIsLoad,
} from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessExecutorBank";
import { getUserId } from "../../../../../app/services/auth/auth";
import { fetchProjectProcessBank } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessBank";
import { parseUnprocessableFields } from "../../../../../app/services/api/requestHandler";
import { fetchUserTypes } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchUserTypes";
import { fetchProjectProcessApplications } from "../../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";
import {
  setDateLimit,
  setExecutorTypeForm,
  setStartDate,
} from "../../../../../app/feature/ProjectProcessView/Info/slice/projectProcessInfo";

interface Inputs {
  content: string;
  date_start: any;
  date_limit: any;
  price: string | number;
  executor_type: any;
  bank_account_id: number | null;
}

const schema = Yup.object().shape({
  content: Yup.string().max(200),
  price: Yup.number()
    .required("Это поле обязательно для заполнения")
    .min(1000, `Стоимость не может быть меньше 1000 рублей!`)
    .max(10000000, `Стоимость не может быть больше 10 млн рублей!`)
    .typeError("Значение «Стоимость» должно быть числом."),
  executor_type: Yup.string()
    .ensure()
    .required("Необходимо выбрать форму ответственности"),
});

interface ModalRequestProps {
  closeModal: () => void;
}
export const ModalRequest = memo(({ closeModal }: ModalRequestProps) => {
  const dispatch = useAppDispatch();
  const infoData = useAppSelector(getProjectProcessInfoData);
  const isLoadUserTypes = useAppSelector(getProjectProcessUserTypesIsLoad);
  const requestSuccess = useAppSelector(getProjectProcessRequestSuccess);
  const userTypes = useAppSelector(getProjectProcessUserTypes);
  const executorBank = useAppSelector(getProjectProcessExecutorBank);
  const name = useAppSelector(getProjectProcessInfoName);
  const error422 = useAppSelector(getProjectProcessRequestError422);
  const executorTypeValue = useAppSelector(getProjectProcessExecutorTypeForm);
  const dateStart = useAppSelector(getProjectProcessDateStart);
  const dateLimit = useAppSelector(getProjectProcessDateLimit);
  const isLoadExecutorBank = useAppSelector(
    getProjectProcessExecutorBankIsLoad
  );
  const userId = getUserId();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      price: Number(infoData?.price),
      date_start: dateStart,
      date_limit: dateLimit,
      bank_account_id: null,
    },
  });
  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      StartForm: {
        content: dataValues.content,
        price: +dataValues.price,
        executor_type: +dataValues.executor_type,
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        bank_account_id:
          dataValues.bank_account_id === -1 ? null : dataValues.bank_account_id,
      },
    };
    if (infoData?.id) {
      await dispatch(
        fetchProjectProcessRequest({ processId: infoData.id, data })
      );
    }
  };

  useEffect(() => {
    if (executorBank) {
      setValue("bank_account_id", executorBank[0]?.id);
    }
  }, [executorBank, setValue]);

  useEffect(() => {
    if (executorTypeValue) {
      clearErrors("executor_type");
      dispatch(fetchProjectProcessBank(+executorTypeValue));
      setValue("bank_account_id", null);
    }
    if (!executorTypeValue) {
      setValue("bank_account_id", null);
    }
  }, [executorTypeValue]);

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserTypes(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (requestSuccess) {
      if (infoData?.id) {
        dispatch(fetchProjectProcessViewDuplicate(infoData.id));
        dispatch(fetchProjectProcessApplications(infoData.id));
      }
      clearErrors();
      dispatch(setNullRequest());
      closeModal();
    }
  }, [requestSuccess]);

  if (isLoadUserTypes) {
    return <ModalPreloader />;
  }

  const bankAccountId = watch("bank_account_id");

  function onChangeExecutorType(event: SelectChangeEvent<any>) {
    dispatch(setExecutorTypeForm(event.target.value));
  }

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{`Заявка на исполнение раздела «${name}» по проекту «${infoData?.project?.name}»`}</h1>
        <TextField
          {...register("price")}
          {...textFieldSX}
          label="Стоимость без НДС"
          type={"number"}
          inputProps={{
            step: "any",
          }}
          defaultValue={infoData?.price || ""}
          error={!!errors.price}
          helperText={errors.price ? errors.price.message : null}
        />
        <div className={cls.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={() => (
              <DateField
                variant={cls.formElement}
                label="Начальный срок"
                changeDateHandler={(date) => {
                  dispatch(setStartDate(date));
                  setValue("date_start", date);
                }}
                startDateProp={dateStart}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={cls.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={cls.form_date_end}>
          <Controller
            control={control}
            name="date_limit"
            render={() => (
              <DateField
                variant={cls.formElement}
                label="Конечный срок"
                changeDateHandler={(date) => {
                  dispatch(setDateLimit(date));
                  setValue("date_limit", date);
                }}
                startDateProp={dateLimit}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
        <FormControl {...textFieldSX}>
          <InputLabel id="executor_type">Форма собственности</InputLabel>
          <Select
            {...register("executor_type")}
            labelId="executor_type"
            label="Форма собственности"
            error={!!errors.executor_type}
            value={executorTypeValue}
            onChange={(event) => onChangeExecutorType(event)}
          >
            {userTypes?.map((type: any) => (
              <MenuItem sx={menuItemSx} value={type.id} key={type.id}>
                {type.listName}
              </MenuItem>
            ))}
          </Select>
          {errors.executor_type ? (
            <p className={cls.error}>
              {parse((errors.executor_type.message as any) || "")}
            </p>
          ) : null}
        </FormControl>
        <TextField
          {...register("bank_account_id")}
          id="bank_account_id"
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
          select
          label="Банковский счет"
          value={bankAccountId}
          disabled={!executorBank || isLoadExecutorBank}
          error={!!errors.bank_account_id}
          helperText={errors.bank_account_id && errors.bank_account_id.message}
        >
          {executorBank?.map((bank: any) => (
            <MenuItem sx={menuItemSx} value={bank.id} key={bank.id}>
              {bank.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Содержание заявки"
          {...register("content")}
          placeholder="Введите текст"
          error={!!errors.content}
          helperText={errors.content ? errors.content.message : null}
        />
        <FormControl className={cls.requestGip_btn}>
          <CustomButton
            className={cls.form_submit}
            background={palette.blue}
            width={160}
            type="submit"
          >
            Отправить
          </CustomButton>
        </FormControl>
      </form>
    </div>
  );
});
