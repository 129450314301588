import React, { memo } from "react";
import classNames from "classnames";
import { Button } from "src/FSD/shared/uiKit/v2";
import { RowsType } from "src/app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import { setCheckInput } from "src/app/feature/ProjectView/projectView";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getTaskCompleteIsLoad } from "src/app/feature/taskComplete/selectrors/getTaskComplete";
import { RemarkTableCard } from "./RemarkTableContent/RemarkTableContent";
import { ModalComplete } from "./ ModalComplete/ModalComplete";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { useModal } from "src/FSD/shared/lib/hooks/useModal";
import cls from "./RemarkTable.module.scss";

interface RemarkTableProps {
  rows: RowsType[];
  files: any;
  canComplete: boolean;
  task: any;
  name: string;
}

export const RemarkTable = memo(
  ({ rows, canComplete, task, name, files }: RemarkTableProps) => {
    const { name: nameModal, handleCloseModal, handleOpenModal } = useModal();
    const dispatch = useAppDispatch();
    const isLoadCompleteTask = useAppSelector(getTaskCompleteIsLoad);

    const handleTaskComplete = () => {
      dispatch(setCheckInput(true));
      handleOpenModal({
        name: "modalComplete",
      });
    };

    return (
      <div className={cls.wrapper}>
        <div className={cls.header}>
          <h1>Чек-лист снятия замечаний</h1>
          <div className={cls.header_btn}>
            {canComplete && (
              <>
                <Modal
                  isActive={nameModal === "modalComplete"}
                  handleClose={handleCloseModal}
                >
                  <ModalComplete
                    taskId={task.id}
                    addText={task?.data?.addText}
                    isLoad={isLoadCompleteTask}
                  />
                </Modal>
                <Button onClick={handleTaskComplete}>
                  Загрузить документацию
                </Button>
              </>
            )}
            {task?.files?.length === 0 && <p>(Документация не приложена)</p>}
          </div>
        </div>
        <table className={cls.table}>
          <thead className={cls.table_head}>
            <tr>
              <th className={classNames(cls.table_head_num, cls.headTd)}>
                № п/п
              </th>
              <th className={classNames(cls.table_head_remark, cls.headTd)}>
                Замечание
              </th>
              <th className={classNames(cls.table_head_comment, cls.headTd)}>
                Комментарий
              </th>
              <th className={classNames(cls.table_head_answer, cls.headTd)}>
                Ответы на замечание
              </th>
              <th
                colSpan={2}
                className={classNames(cls.table_head_doc, cls.headTd)}
              >
                Документация, в которую внесены изменения
              </th>
            </tr>
          </thead>
          <tbody className={cls.tbody}>
            {rows.map((el) => (
              <RemarkTableCard
                filesUpdate={files}
                name={name}
                data={el}
                key={el.row.id}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
