import { httpRequestPost } from "../api";
import { config } from "src/app.cofig";

export const ApiPostRegistrationForm = (
  data: Record<string, Record<string, string | boolean | number[]>>
): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/site/registration`, {}, data);

export const ApiPostEmailConfirm = (
  id: string,
  data: Record<string, Record<string, string>>
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/site/email-confirm?id=${id}`,
    {},
    data
  );
