import moment from "moment";
import { Heading, Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import styles from "./Comparison.module.scss";
import { trimPrice } from "../../../../../../../utils/trimNumber";

type TProps = {
  processType: ApiProjectResponses.GetApplications[0]["processTypes"][0];
};

export const ProcessTypeDetails = ({ processType }: TProps) => {
  const { typeName, fullPrice, dateStart, dateEnd } = processType;

  const formattedDateStart = moment(dateStart.date).format("DD.MM.YYYY");

  const formattedDateEnd = moment(dateEnd.date).format("DD.MM.YYYY");

  const formattedFullPrice = trimPrice(fullPrice);

  return (
    <>
      <div />
      <div />
      <div className={styles.partGroupDetails}>
        <Heading>{typeName}</Heading>
        <Paragraph>
          Общие сроки: {formattedDateStart}-{formattedDateEnd}
        </Paragraph>
        <Paragraph>
          Общий бюджет: <span>{formattedFullPrice} ₽</span>
        </Paragraph>
      </div>
    </>
  );
};
