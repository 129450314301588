import { ChangeEvent, FC } from "react";
import { FolderOpen } from "@mui/icons-material";
import style from "./style.module.scss";

interface DropzoneProps {
  handleUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Dropzone: FC<DropzoneProps> = ({ handleUpload }) => (
  <label className={style.dropzone}>
    <FolderOpen
      className={style.inputIcon}
      style={{ color: "white", fontSize: 40 }}
    />
    <p className={style.description}>
      <span>Загрузите</span>&nbsp;
      <span>файлы</span>
    </p>
    <input onChange={handleUpload} type="file" hidden multiple />
  </label>
);

export default Dropzone;
