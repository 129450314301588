import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { selectorAppTheme } from "src/app/feature/app/app";
import style from "./style.module.scss";
import { request } from "src/app/services/api/requestHandler";
import { ApiPostForgotPasswordForm } from "src/app/services/api/forgotPassword/forgotPassword";
import { textFieldSX } from "src/styles/restyle";

type TFormValues = {
  email: string;
};

const schema = Yup.object().shape({
  email: Yup.string().required("Необходимо заполнить «Email»"),
});

export const ForgotPasswordForm = () => {
  const theme = useSelector(selectorAppTheme);
  const [showMessage, setShowMessage] = useState(false);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: TFormValues) => {
    request(
      ApiPostForgotPasswordForm({
        ForgotForm: {
          email: data.email,
        },
      }),
      (result, headersData, headers, status) => {
        if (status === 200) {
          setShowMessage(true);
        }
      },
      () => (data) => {
        const message = JSON.parse(data.message);
        Object.keys(message).map((key, i) =>
          setError(key as keyof TFormValues, message[key][i])
        );
      }
    )();
  };

  if (showMessage) {
    return (
      <div className={style.message}>
        На ваш email отправлена инструкция для восстановления пароля.
      </div>
    );
  }

  return (
    <>
      <div className={style.formTitle}>Укажите ваш Email:</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.fieldWrapper}>
          <TextField
            {...register("email")}
            size={"small"}
            fullWidth
            id="email"
            name="email"
            type="text"
            label="Email"
            autoComplete="email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            {...textFieldSX}
          />
        </div>
        <Button
          theme={theme}
          type="submit"
          color={"blue"}
          className={style.submitButton}
        >
          Восстановить
        </Button>
      </form>
    </>
  );
};
