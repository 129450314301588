import React from "react";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Dot } from "src/FSD/shared/uiKit/v2/Dot/Dot";
import classNames from "classnames";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { stringDateToDDMMYYYY } from "src/FSD/shared/lib/helpers";
import { Button, TextLink } from "src/FSD/shared/uiKit/v2";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import { Popper } from "src/FSD/shared/uiKit/v2/Popper";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import {
  useTaskBackToWorkMutation,
  useTaskCompleteMutation,
  useTaskUpdateDateMutation,
} from "src/FSD/entities/task/api";
import { daysBetweenDates } from "src/components/newUI/ChecklistCard/helpers";
import C from "src/constants";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import styles from "./ExchangeTasks.module.scss";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { PlusSquare, Reply, CheckSquare } from "react-bootstrap-icons";

interface TProps {
  tasks: ApiProjectProcessResponses.GetInTaskItem[];
  show: boolean;
}

export const ExchangeTasksVersions = ({ tasks, show }: TProps) => {
  const { currentChildTab } = useTabsPanelContext();

  const [updateDate, { isLoading: isDatesUpdating }] =
    useTaskUpdateDateMutation();

  const handleChangeDates = (id: number) => async (dataValues: any) => {
    if (!id) {
      return;
    }

    const data = {
      date_deadline: toServerDate(dataValues.date),
    };
    await updateDate({ id, data });
  };

  const { handleOpenModal } = useModal();

  const handleShowComments = (taskId: number) => {
    handleOpenModal({
      name: ModalNames.Task.COMMENTS,
      taskId,
    });
  };

  const isInputTask = currentChildTab === "inputTask";

  const canEditDate = (can: boolean, minDate?: string, maxDate?: string) => {
    if (!can || !minDate || !maxDate) {
      return false;
    }

    return daysBetweenDates(minDate, maxDate) > 1 && can;
  };

  const handleShowOutputTask = (taskId: number, header: string) => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.OUTPUT_TASK,
      taskId,
      header,
    });
  };

  const [backToWork] = useTaskBackToWorkMutation();
  const handleReturnForRevision = async (id: number) => {
    if (!window.confirm(C.CONFIRM_RETURN_TASK_FOR_REVISION)) {
      return;
    }
    await backToWork({ id });
  };

  const [completeTask] = useTaskCompleteMutation();
  const handleAcceptTask = async (id: number) => {
    if (!window.confirm(C.CONFIRM_ACCEPT_TASK)) {
      return;
    }

    const formedData = {
      CompleteForm: {
        status: Statuses.ACCEPT,
      },
    };

    await completeTask({ id, task: formedData });
  };

  return (
    <AccordionMui
      expanded={show}
      classes={{ root: classNames(styles.accordion) }}
    >
      <AccordionSummary
        classes={{ root: styles.summaryRoot }}
      ></AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.detailsRoot,
        }}
      >
        <div className={styles.content}>
          {tasks.map(
            ({
              can,
              header,
              minDate,
              maxDate,
              taskStatusInfo,
              taskCheckId,
              files,
              task,
            }) => {
              const showEditDate = canEditDate(
                can["update-date-deadline"],
                minDate,
                maxDate
              );
              const beforeText =
                taskStatusInfo.status === Statuses.WORK ? "до " : "";

              const taskId = isInputTask ? taskCheckId : task.id;
              const visibleAccept = can.accept;
              const visibleComplete = can.complete;
              const notRequired = can["not-required"];
              const visibleBackToWork =
                !notRequired && !visibleComplete && can["back-to-work"];

              return (
                <div className={styles.content__item} key={task.id}>
                  <div>
                    <div className={classNames(styles.label, styles.flex)}>
                      <Dot dot={taskStatusInfo.dot} />
                      {header}
                    </div>
                    <div>
                      {task.date_complete
                        ? `от ${stringDateToDDMMYYYY(task.date_complete)}`
                        : ""}
                    </div>
                  </div>
                  <div>
                    <div className={styles.label}>
                      {taskStatusInfo.statusString}
                    </div>
                    <div
                      className={classNames({
                        [styles.item__desc_error]:
                          taskStatusInfo.bgClass === "pirs-red",
                      })}
                    >
                      {showEditDate ? (
                        <Popper.DateField
                          date={taskStatusInfo.date}
                          canEdit={can["update-date-deadline"]}
                          title="Конечный срок"
                          minDate={minDate}
                          maxDate={maxDate}
                          onChange={handleChangeDates(task.id)}
                          isLoading={isDatesUpdating}
                          showPencil
                          className={styles.item__desc_date}
                        />
                      ) : (
                        `${beforeText}${taskStatusInfo.date}`
                      )}
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => handleShowComments(taskId)}
                      variant="outlined"
                      color="success"
                      borderRadius={20}
                    >
                      Комментарии
                    </Button>
                  </div>
                  <div>
                    <div
                      className={classNames(styles.label, styles.label_medium)}
                    >
                      Вложение
                    </div>
                    {files.length ? (
                      <TextLink
                        url={`/task/download-files?id=${taskId}`}
                        redirect
                        target={"_blank"}
                        variant="oceanic"
                      >
                        Архив.zip
                      </TextLink>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className={styles.actions}>
                    {visibleAccept && (
                      <IconButton
                        sx={iconBtnSx}
                        onClick={() => handleAcceptTask(taskId)}
                        title="Принять задание"
                      >
                        <CheckSquare />
                      </IconButton>
                    )}
                    {visibleComplete && (
                      <IconButton
                        sx={iconBtnSx}
                        onClick={() => handleShowOutputTask(taskId, header)}
                        title="Выдать задание"
                      >
                        <PlusSquare />
                      </IconButton>
                    )}
                    {visibleBackToWork && (
                      <IconButton
                        sx={iconBtnSx}
                        onClick={() => handleReturnForRevision(taskId)}
                        title="Вернуть на доработку"
                      >
                        <Reply />
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </AccordionDetails>
    </AccordionMui>
  );
};
