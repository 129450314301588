import { memo } from "react";
import { Select } from "src/FSD/shared/uiKit/v2";
import { KanbanModel, KanbanSortValues } from "src/FSD/entities/kanban";
import { useAppDispatch, useAppSelector } from "src/app/store";
import styles from "./Column.module.scss";

const shortOptions = [
  {
    id: KanbanSortValues.DATE_ASC,
    title: "По дате (новые) ↑",
  },
  {
    id: KanbanSortValues.DATE_DESC,
    title: "По дате (старые) ↓",
  },
  {
    id: KanbanSortValues.PART_GROUP_ASC,
    title: "По специал. и дате ↑",
  },
  {
    id: KanbanSortValues.PART_GROUP_DESC,
    title: "По специал. и дате ↓",
  },
];

/*
const fullOptions = [
  {
    id: KanbanSortValues.DATE_ASC,
    title: "Сортировка по дате ↑",
  },
  {
    id: KanbanSortValues.DATE_DESC,
    title: "Сортировка по дате ↓",
  },
  {
    id: KanbanSortValues.PART_GROUP_ASC,
    title: "Сортировка по разделам",
  },
  {
    id: KanbanSortValues.PART_GROUP_DESC,
    title: "Сортировка по типу задачи",
  },
];
*/

const ColumnSortFilter = memo(() => {
  const dispatch = useAppDispatch();

  const sort = useAppSelector(KanbanModel.getSortValue);

  const sortValue = shortOptions.find((option) => option.id === sort);

  return (
    <Select
      value={sortValue}
      className={styles.select}
      textClassName={styles.select__text}
      options={shortOptions}
      changeHandler={(_, newValue) => {
        dispatch(KanbanModel.setSortValue(newValue.id));
      }}
      disableClear
    />
  );
});

export default ColumnSortFilter;
