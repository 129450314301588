import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import classNames from "classnames";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { selectorEditOrganisationTaskData } from "../../../app/feature/eventEmitter/organisation/eventEditOrganisationTask";
import {
  ApiUpdateTaskMultiData,
  ApiGetTaskFiles,
} from "../../../app/services/api/organisation/organisation";
import style from "./style.module.scss";
import { request } from "../../../app/services/api/requestHandler";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import { Uploader } from "../../features/Uploader/Uploader";
import { config } from "../../../app.cofig";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import CustomCheckbox from "../../newUI/CustomCheckbox/CustomCheckbox";
import { palette } from "../../../styles/restyle";

const TEMPLATE_FILE = `${config.localDomain}/files/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD_%D0%B4%D0%BE%D0%B2%D0%B5%D1%80%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8.doc`;

const margin = "0 0 5px calc(100% - 160px)";

export type EditTaskFormProps = ComponentPropsType<{
  submitHandler?: () => void;
  submitClose?: () => void;
  closeHandler?: VoidFunction;
}>;

export const EditTaskForm: React.FC<EditTaskFormProps> = ({
  variant = "",
  closeHandler = () => {},
}) => {
  const [initialListLength, setInitialListLength] = useState(-1);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const [invalidAcceptance, setInvalidAcceptance] = useState(false);

  const { content, comment, id, taskId, files } = useSelector(
    selectorEditOrganisationTaskData
  );

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      content,
      comment,
      files,
      acceptance: false,
    },
  });

  const watchFiles = watch("files");
  const watchAcceptance = watch("acceptance");

  const { enqueueSnackbar } = useSnackbar();
  const { Popup } = useUserWorkflow();

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const onSumbit = (data: any) => {
    const totalFilesLength = initialListLength + data.files.length;
    if (!data.acceptance) {
      setInvalidAcceptance(true);
    }
    if (!data.acceptance || totalFilesLength < 1) {
      return;
    }

    setDisabled(true);
    const formData = new FormData();

    formData.append("StartForm[personalAgreement]", "true");
    formData.append("StartForm[content]", data.content);
    formData.append("StartForm[comment]", data.comment);

    if (!taskId) {
      for (const file of data.files) {
        formData.append(`StartForm[upload_files][]`, file);
      }
    }

    request(
      ApiUpdateTaskMultiData(id, formData),
      () => {
        enqueueSnackbar("Задача организации успешно обновлена", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.editOrganisationTask.set({});
          dispatch(setModalClosed(!modalClosed));
          closeHandler();
        }, 500);
      },
      () => (err) => {
        if (err.status === 403) {
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar("Ошибка обновления задачи организации", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        setTimeout(() => {
          setDisabled(false);
          Popup.editOrganisationTask.set({});
          closeHandler();
        }, 500);
      }
    )();
  };

  return (
    <form
      onSubmit={handleSubmit(onSumbit)}
      className={classNames(style.modalWindow, variant)}
    >
      <div className={style.wrapper}>
        <TextField
          {...register("content")}
          name={"content"}
          className={style.textField}
          variant="outlined"
          label="Комментарий проверяющего"
          disabled
          multiline
          size="small"
        />
        <div className={style.fileUploader}>
          <Uploader
            autoUpload={Boolean(taskId)}
            identifier={taskId}
            canBeEdited={true}
            getDataCallback={ApiGetTaskFiles}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              if (data.length !== watchFiles.length) {
                setValue("files", data);
              }
            }}
            setInitialListLength={setInitialListLength}
          />
          {initialListLength + watchFiles.length === 0 && (
            <div className={style.error}>
              Необходимо прикрепить минимум один файл из описания!
            </div>
          )}
        </div>
        <TextField
          {...register("comment")}
          name={"comment"}
          className={style.textField}
          variant="outlined"
          label="Комментарий"
          multiline
          size="small"
        />
        <label className={style.checker}>
          <CustomCheckbox
            checked={watchAcceptance}
            onChange={() => {
              setValue("acceptance", !watchAcceptance);

              if (!watchAcceptance) {
                setInvalidAcceptance(false);
              }
            }}
          />
          <p>
            Подтверждаю получение мною согласия третьих лиц на передачу их
            персональных данных на обработку
          </p>
        </label>
        {invalidAcceptance && (
          <div className={style.error}>Подтвердите получение согласия</div>
        )}
        <div className={style.documentsList}>
          Список документов необходимых для проверки организации:
          <br />
          &emsp;1. Копия cвидетельства о постановке на учет в налоговом органе.
          <br />
          &emsp;2. Копия паспорта руководителя и лица действующего по
          доверенности.
          <br />
          &emsp;3. Доверенность на замещающее лицо. &nbsp;
          <a className={style.link} href={TEMPLATE_FILE}>
            Шаблон доверенности
          </a>
        </div>
        <CustomButton
          style={{ margin }}
          width={160}
          disabled={disabled}
          type="submit"
          background={palette.blue}
        >
          Отправить
        </CustomButton>
      </div>
    </form>
  );
};
