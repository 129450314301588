import { memo } from "react";
import classNames from "classnames";
import styles from "./Price.module.scss";
import { trimPrice } from "../../../../utils/trimNumber";

const contractPrice = "Договорная";

interface Props {
  price: string;
  classname?: string;
  isProcess?: boolean;
}

export const Price = memo(({ price, classname, isProcess = false }: Props) => {
  const priceTrim = trimPrice(price);
  const ruble = priceTrim !== contractPrice;
  const canLowFontSizeRuble = isProcess && !ruble;

  return (
    <div className={classNames(styles.price, classname)}>
      <p
        className={classNames(
          styles.price__rub,
          canLowFontSizeRuble && styles.price__lowRub
        )}
      >
        {priceTrim} {ruble ? <span>₽</span> : ""}
      </p>
      <p className={styles.price__nds}>{ruble && "без НДС"}</p>
    </div>
  );
});
