import { UseFormReturn } from "react-hook-form";
import cn from "classnames";
import { Button, Modal } from "src/FSD/shared/uiKit/v2";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { SetState } from "src/FSD/shared/lib/types/setState";
import { CommonRow } from "./CommonRow";
import { ProcessesList } from "./ProcessesList";
import { PaymentTerms } from "./PaymentTerms";
import styles from "./styles.module.scss";
import { useGroupForm } from "../lib/hooks/useGroupForm";
import {
  TFormCache,
  TGroupPublicationFieldValues,
} from "../model/types/fieldValues";

export type Props = {
  groupPublishProcess: ApiProjectProcessResponses.GetGroupPublishProcess;
  currentFormId: string;
  processId: number;
  currentPage: number;
  setCurrentPage: SetState<number>;
  setFormCache: SetState<TFormCache>;
  formCache: TFormCache;
};

export const GroupFormWithPayment = ({
  groupPublishProcess,
  currentFormId,
  processId,
  currentPage,
  setCurrentPage,
  setFormCache,
  formCache,
}: Props) => {
  const {
    form,
    showBackwardButton,
    defaultValues,
    onSubmit,
    handleSubmit,
    isValid,
  } = useGroupForm({
    groupPublishProcess,
    currentFormId,
    processId,
    currentPage,
    setCurrentPage,
    setFormCache,
    formCache,
    type: "publication",
  });

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.datesList, styles.datesList_publication)}>
        <CommonRow form={form} />
        <ProcessesList form={form} processId={processId} />
      </div>
      <div className={styles.paymentTerms}>
        <PaymentTerms
          showPay3={
            (defaultValues as TGroupPublicationFieldValues).maybeExpertise
          }
          form={form as UseFormReturn<TGroupPublicationFieldValues>}
        />
        <Modal.Controls>
          {showBackwardButton && (
            <Button
              onClick={() => {
                if (isValid) {
                  handleSubmit((dataValues) => onSubmit(dataValues, -1))();
                }
              }}
              disabled={!isValid}
              color="secondary"
              type="button"
            >
              Назад
            </Button>
          )}
          <Button
            onClick={() => {
              if (isValid) {
                handleSubmit((dataValues) => onSubmit(dataValues, 1))();
              }
            }}
            disabled={!isValid}
            color="primary"
            type="button"
          >
            Далее
          </Button>
        </Modal.Controls>
      </div>
    </div>
  );
};
