import { Project as ProjectTags } from "./project";
import { ProjectProcess as ProjectProcessTags } from "./projectProcess";
import { ProcessWork as ProcessWorkTags } from "./processWork";
import { Task as TaskTags } from "./task";
import { Feedback as FeedbackTags } from "./feedback";
import { Expertise as ExpertiseTags } from "./expertise";
import { ProjectPart as ProjectPartTags } from "./projectPart";
import { ProjectChecklist as ProjectChecklistTags } from "./projectChecklist";
import { ProjectSourceData as ProjectSourceDataTags } from "./projectSourceData";
import { ProjectAccess as ProjectAccessTags } from "./projectAccess";
import { MaterialProcess as MaterialProcessTags } from "./materialProcess";

export namespace ApiTags {
  export const Project = ProjectTags;
  export const ProjectProcess = ProjectProcessTags;
  export const ProjectPart = ProjectPartTags;
  export const ProcessWork = ProcessWorkTags;
  export const Task = TaskTags;
  export const Feedback = FeedbackTags;
  export const Expertise = ExpertiseTags;
  export const ProjectChecklist = ProjectChecklistTags;
  export const ProjectSourceData = ProjectSourceDataTags;
  export const ProjectAccess = ProjectAccessTags;
  export const MaterialProcess = MaterialProcessTags;
}
