import React from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  Checkbox,
  DateField,
  Modal,
  Uploader,
  Select,
} from "src/FSD/shared/uiKit/v2";
import { useRemarkCreate } from "src/FSD/widgets/v2/projectProcess/modals/RemarkCreate/lib/hooks/useRemarkCreate";
import styles from "./RemarkCreateForm.module.scss";

type TProps = {
  processId: number;
  projectId: number;
};

export const RemarkCreateForm = ({ processId, projectId }: TProps) => {
  const {
    control,
    errors,
    projectList,
    onSubmit,
    checkImport,
    isLoadingRemarkCreate,
    isLoadingToProjects,
  } = useRemarkCreate({ processId, projectId });

  return (
    <Modal.Layout>
      <Modal.Header>Замечания</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <Controller
          control={control}
          name="parseSpreadsheet"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label="Импорт вопросов из таблицы (.xlsx, .xls, .ods, .csv)"
              onChange={onChange}
              checked={value}
            />
          )}
        />
        {checkImport && (
          <div className={styles.form_alert}>
            <p>
              Импортируемый документ должен иметь следующий вид: в первой
              колонке нумерация строк должна быть в числовом формате, во второй
              колонке - текст в текстовом формате, в третьей колонке дата должна
              иметь вид ДД.MM.ГГ или ДД.ММ.ГГГГ и иметь формат текстовый или
              даты.
            </p>
            <div className={styles.form_alert_grid}>
              <div className={styles.form_alert_grid_item}>№ п/п</div>
              <div className={styles.form_alert_grid_item}>Замечание</div>
              <div className={styles.form_alert_grid_item}>Срок</div>
            </div>
          </div>
        )}
        <Controller
          name="date_create"
          control={control}
          render={({ field: { value, onChange } }) => (
            <DateField
              label="Контрольный срок"
              changeDateHandler={onChange}
              startDateProp={value}
              error={!!errors.date_create}
            />
          )}
        />
        {checkImport && (
          <Controller
            name="project_id"
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <Select
                label="Объект"
                value={value}
                disabled={isLoadingToProjects}
                optionLabel="name"
                options={projectList}
                changeHandler={(_, newValue) => {
                  onChange(newValue);
                }}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
        )}
        <Controller
          name="files"
          control={control}
          render={({ field: { onChange, name } }) => (
            <Uploader
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
        <Modal.Controls>
          <Button
            type="submit"
            disabled={isLoadingRemarkCreate}
            isLoading={isLoadingRemarkCreate}
          >
            Создать
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
