import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../../services/api/requestHandler";
import { ApiExpertiseRemarkRowCreate } from "../../../../../services/api/projectProcess/ExpertiseApi/expertiseRemarks";
import { pushError } from "../../../../errorTrace";

interface DataType {
  text: string;
  comment: string;
  date_limit: any;
  project_id: number;
  project_part_ids: number[];
}

export const fetchExpertiseRemarkRowCreate = createAsyncThunk<
  boolean,
  { remarkId: number; data: DataType }
>(
  "expertise/fetchExpertiseRemarkRowCreate",
  async ({ remarkId, data }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiExpertiseRemarkRowCreate(remarkId, data),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
