import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiProjectProcessRequests } from "../../model/requestTypes";

type TParams = {
  id: number;
  data: ApiProjectProcessRequests.GroupPublish;
};

export const groupPublish = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/group-publish`,
      params: {
        id,
      },
      data,
      method: HTTPMethods.POST,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.VIEW],
  });
