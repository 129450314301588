import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiTags } from "src/FSD/shared/api/tags";

export const remove = (builder: QueryBuilder) =>
  builder.mutation({
    query: (id: number) => ({
      url: `v1/task/delete?id=${id}`,
      method: HTTPMethods.DELETE,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.GET_DOCUMENTATIONS],
  });
