import React from "react";
import {
  Rating as RatingUi,
  RatingProps as MuiRatingProps,
} from "@mui/material";
import classNames from "classnames";
import style from "./style.module.scss";

export interface RatingUiProps {
  theme?: "old" | "dark" | "light";
  variant?: string;
  value: string | number;
}

const convert = (value: string | number): number => {
  return Number(value) / 20;
};

export const Rating: React.FC<
  RatingUiProps & Omit<MuiRatingProps, keyof RatingUiProps>
> = (props) => {
  const { variant = "", theme = "dark", value, ...other } = props;
  return (
    <RatingUi
      className={classNames(variant, style.rating, style[theme])}
      defaultValue={convert(value)}
      precision={0.1}
      {...other}
    />
  );
};
