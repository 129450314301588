import { FolderWarningIcon } from "src/components/newUI/SVG";
import { dotColors } from "src/FSD/shared/lib/helpers/dotColor";
import { TDot } from "src/FSD/shared/lib/types/dot";

type TProps = {
  dot?: TDot;
};

export const FolderIcon = ({ dot }: TProps) => {
  const circleColor = dot ? dotColors(dot) : "transparent";
  return <FolderWarningIcon circleColor={circleColor} />;
};
