import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Modal.module.scss";

type TProps = {
  children: ReactNode;
  className?: string;
};

const ModalControls = ({ children, className }: TProps) => {
  return (
    <div className={cn(styles.modal__controls, className)}>{children}</div>
  );
};

export default ModalControls;
