import {
  useImperativeHandle,
  forwardRef,
  useState,
  MouseEvent,
  ReactNode,
  memo,
} from "react";
import { Popover, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import cn from "classnames";
import { Paragraph } from "../Paragraph";
import styles from "./Popover.module.scss";

type TPopoverPopupStateProps = {
  content: ReactNode;
  title?: string;
  showHeader?: boolean;
  classNamePaper?: string;
  classNameRoot?: string;
};

export const PopoverPopupState = memo(
  forwardRef(
    (
      {
        title,
        content,
        classNamePaper,
        classNameRoot,
        showHeader = true,
      }: TPopoverPopupStateProps,
      ref
    ) => {
      const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

      useImperativeHandle(ref, () => ({
        openPopover: (event: MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget),
        closePopover: () => setAnchorEl(null),
      }));

      const open = Boolean(anchorEl);
      const id = open ? "comment-popover" : undefined;

      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            root: cn(styles.popover__root, classNameRoot),
            paper: cn(styles.popover__paper, classNamePaper),
          }}
        >
          {showHeader && (
            <div className={styles.popover__box}>
              <Paragraph fontSize={18} color="light" fontWeight="bold">
                {title}
              </Paragraph>
              <IconButton
                onClick={() => setAnchorEl(null)}
                className={styles.popover__closeBtn}
              >
                <Clear />
              </IconButton>
            </div>
          )}
          {typeof content === "string" ? (
            <Paragraph color="light">{content}</Paragraph>
          ) : (
            content
          )}
        </Popover>
      );
    }
  )
);
