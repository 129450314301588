import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import RewardItem from "./RewardItem/RewardItem";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import C from "../../../../../constants";
import style from "./style.module.scss";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { UnsavedType } from "../../../Project/types";
import { getErrorMessage } from "../../../../../app/services/api/requestHandler";

interface ComponentProps {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
}

export interface SubmitDataProps {
  name: string;
  file_upload?: File[];
}

const Rewards: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const {
    rewardListIsLoading,
    rewardList,
    loadRewardList,
    createReward,
    deleteReward,
    updateReward,
  } = useProfile();

  useEffect(() => {
    loadRewardList(userId);
  }, [userId]);

  const { enqueueSnackbar } = useSnackbar();

  const onCreate = (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    createReward({ user_id: userId, ...data })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        loadRewardList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onUpdate = (
    name: string,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsFetching(true);
    updateReward({ name, id })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, awards: false }));
        loadRewardList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onDelete = (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!window.confirm(C.CONFIRM_DELETE)) {
      return;
    }

    setIsFetching(true);
    deleteReward(id)
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        loadRewardList(userId);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  return (
    <div className={style.wrapper}>
      {rewardListIsLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="232px"
          width="100%"
        />
      ) : (
        <>
          <RewardItem setUnsaved={setUnsaved} onCreate={onCreate} />
          {rewardList.map((reward: any) => {
            return (
              <RewardItem
                setUnsaved={setUnsaved}
                key={reward.id}
                onUpdate={onUpdate}
                onDelete={onDelete}
                reward={reward}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default Rewards;
