import { pushSuccessCopyTextToClipboard } from "../../../../app/feature/errorTrace";
import { AppDispatch } from "../../../../app/store";

export const copyTextToClipboard = async (
  text: string,
  dispatch: AppDispatch
) => {
  try {
    await navigator.clipboard.writeText(text);
    dispatch(pushSuccessCopyTextToClipboard());
  } catch (err) {
    console.error("Ошибка:", err);
  }
};
