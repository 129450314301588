import { httpRequestPost } from "../api";
import { config } from "src/app.cofig";

export const ApiPostForgotPasswordForm = (
  data: Record<string, Record<string, string>>
): Promise<Response> =>
  httpRequestPost(`${config.localDomain}/v1/site/forgot`, {}, data);

export const ApiPostResetPasswordForm = (
  token: string,
  data: Record<string, Record<string, string>>
): Promise<Response> =>
  httpRequestPost(
    `${config.localDomain}/v1/site/reset-pwd?token=${token}`,
    {},
    data
  );
