import { Link } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { Application as ApplicationCard } from "src/FSD/entities/application";
import { Description } from "src/FSD/shared/uiKit/v2/Description/Description";
import { useThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton/useThreeDotButton";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { MenuItem } from "@mui/material";
import { DropdownButton } from "src/FSD/shared/uiKit/v2/DropdownButton";
import { ApiPublishedObjectResponse } from "src/FSD/entities/publishedObject/model/responseTypes";
import styles from "./Applications.module.scss";
import { palette } from "src/styles/restyle";
import { helpersYears } from "src/utils/helpersYears";
import { useApplication } from "../lib/hooks/useApplication";

type TProps = {
  application: ApiPublishedObjectResponse.ApplicationType;
};

export const Application = ({ application }: TProps) => {
  const {
    isMobile,
    userId,
    fullName,
    jobDuration,
    ndsText,
    comment,
    headerUpdate,
    headerRemove,
    fetchTaskComplete,
    isLoadingTaskComplete,
    color,
    disabled,
    price,
  } = useApplication(application);

  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const { handleOpenModal } = useModal();

  const actions = (
    <div className={styles.actions}>
      {application.buttons.chat && (
        <Link
          to={`${config.localDomain}/chat/${application.task.chatRoomId!.id}`}
          target="_blank"
        >
          <Button color="primary" variant="outlined">
            Чат
          </Button>
        </Link>
      )}
      {userId === application.task.author.id ? (
        <DropdownButton textBtn="Изм." ref={threeDotButtonRef}>
          <MenuItem
            onClick={() => {
              handleOpenModal({
                name: ModalNames.PublishedObject.UPDATE_REQUEST,
                taskId: application.task.id,
                header: headerUpdate,
              });

              closeThreeDot();
            }}
          >
            Редактировать заявку
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenModal({
                name: ModalNames.PublishedObject.REMOVE_REQUEST_EXECUTION,
                taskId: application.task.id,
                header: headerRemove,
              });

              closeThreeDot();
            }}
          >
            Удалить заявку
          </MenuItem>
        </DropdownButton>
      ) : (
        <Button
          color={color}
          disabled={disabled}
          style={{
            borderWidth: "0px !important",
          }}
          onClick={() => {
            fetchTaskComplete(application.task.id);
          }}
          isLoading={isLoadingTaskComplete}
        >
          Нанять
        </Button>
      )}
    </div>
  );

  if (isMobile) {
    const jobDurationText = `Опыт ${helpersYears(jobDuration)}`;

    return (
      <ApplicationCard
        avatar={
          <ApplicationCard.Avatar
            photoUrl={`${config.localDomain}${application.task.author.photo}`}
            borderColor={palette.persianGreen}
            upperText={jobDurationText}
            lowerText={application.userType.listName}
            fio={fullName}
            userId={application.task.author.id as number}
          />
        }
        rating={
          <ApplicationCard.Rating
            rating={application.task.author.rating.executor}
          />
        }
        duration={
          <ApplicationCard.Segment className={styles.dates}>
            {application.task.workflow_data.date_start} -{" "}
            {application.task.workflow_data.date_limit}
          </ApplicationCard.Segment>
        }
        price={
          <ApplicationCard.Segment prompt={ndsText} className={styles.price}>
            {price} ₽
          </ApplicationCard.Segment>
        }
        remarks={
          <ApplicationCard.Remarks
            remarks={application.remarks}
            reasons={application.reasons}
            accountId={application?.accountId?.id ?? null}
          />
        }
        footer={
          <ApplicationCard.Footer>
            <Description text={comment} linesCount={1} />
          </ApplicationCard.Footer>
        }
      />
    );
  }

  return (
    <ApplicationCard
      avatar={
        <ApplicationCard.Avatar
          photoUrl={`${config.localDomain}${application.task.author.photo}`}
          borderColor={palette.persianGreen}
          lowerText={application.userType.listName}
          fio={fullName}
          userId={application.task.author.id as number}
        />
      }
      rating={
        <ApplicationCard.Rating
          rating={application.task.author.rating.executor}
          jobDuration={Number(jobDuration)}
        />
      }
      duration={
        <ApplicationCard.Segment>
          {application.task.workflow_data.date_start} -{" "}
          {application.task.workflow_data.date_limit}
        </ApplicationCard.Segment>
      }
      price={
        <ApplicationCard.Segment prompt={ndsText}>
          {price} ₽
        </ApplicationCard.Segment>
      }
      remarks={
        <ApplicationCard.Remarks
          remarks={application.remarks}
          reasons={application.reasons}
          accountId={application?.accountId?.id ?? null}
        />
      }
      actions={actions}
      footer={
        <ApplicationCard.Footer>
          <Description text={comment} linesCount={1} />
        </ApplicationCard.Footer>
      }
    />
  );
};
