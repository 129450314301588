import React, { memo } from "react";
import classNames from "classnames";
import style from "../style.module.scss";

export const Name = memo(({ name }: { name: string }) => {
  return <div className={style.previewName}>{name.replace(/_/g, " ")}</div>;
});

export const Percent = memo(({ percent }: { percent: number }) => {
  return <div className={style.previewPercent}>{Math.round(percent)}%</div>;
});

export const DefaultFileIcon = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="grey"
      viewBox="0 0 16 16"
      className="icon-file"
    >
      <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"></path>
    </svg>
  );
});

export const Status = ({ status }: { status: string }) => {
  const statuses: Record<string, React.ReactNode | React.ReactElement> = {
    error_file_size: (
      <div className={classNames(style.statusSize)}>
        Максимальный размер файла 100 Мб
      </div>
    ),
    headers_received: <div className={style.loading}>подготовка</div>,
    uploading: <div className={style.loading}>загрузка</div>,
  };

  return <>{statuses[status]}</> || <div>неизвестный статус</div>;
};

export const FileLoading = ({
  status,
  name,
  percent,
}: {
  status: string;
  name: string;
  percent: number;
}) => {
  return (
    <>
      {status === "done" ? (
        <Name name={name} />
      ) : (
        <div className={style.previewStatus}>
          <Percent percent={percent} />
          <Status status={status} />
        </div>
      )}
    </>
  );
};
