import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { SkeletonGip } from "src/components/UI/components/Project/Skeleton";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { InfoGip } from "./InfoGip/InfoGip";
import { InfoObject } from "./InfoObject/InfoObject";
import { InfoDescription } from "./InfoDescription/InfoDescription";
import { InfoFiles } from "./InfoFiles/InfoFiles";
import { InfoDesign } from "./InfoDesign/InfoDesign";
import InfoExecutor from "./InfoCustomer/InfoCustomer";
import { SkeletonInfo } from "./SkeletonInfo";
import styles from "./Info.module.scss";
import { useTabsPanelContext } from "../../TabsPanel";
import { InfoDocumentation } from "./InfoDocumentation/InfoDocumentation";

export const Info = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentTab } = useTabsPanelContext();

  const {
    project,
    isLoad,
    searchGipStatus,
    cancelSearchGip,
    isLoadGip,
    removeGip,
    removeGipComplete,
    requestGip,
    cancelRequestGip,
  } = useSelector(getProjectViewData);

  useEffect(() => {
    if (searchGipStatus.length !== 0) {
      enqueueSnackbar(searchGipStatus, {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelSearchGip) {
      enqueueSnackbar("Поиск ГИП отменен", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGip) {
      enqueueSnackbar("Запрос на снятие ГИПа прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGipComplete) {
      enqueueSnackbar("Запрос на рассмотрение прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (requestGip) {
      enqueueSnackbar("Заявка на ГИП прошла успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelRequestGip) {
      enqueueSnackbar("Заявка на ГИП отозвана", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
  }, [
    searchGipStatus,
    cancelSearchGip,
    removeGip,
    removeGipComplete,
    project,
    requestGip,
    cancelRequestGip,
  ]);

  if (isLoad && !searchGipStatus) {
    return <SkeletonInfo />;
  }

  if (project) {
    const { files, description } = project;
    return (
      <ScrollWrap height={"994px"} classname={styles.wrapper}>
        <InfoDescription description={description} name={project.name} />
        <div className={styles.object}>
          <div className={styles.object_block1}>
            <InfoDesign />
            <InfoObject />
          </div>
          <div className={styles.object_block1}>
            <InfoExecutor />
            {isLoadGip ? (
              <SkeletonGip />
            ) : (
              <InfoGip key={project.id} setCurrentTab={setCurrentTab} />
            )}
          </div>
        </div>
        <InfoFiles files={files} />
        {project.can.downloadDocs && (
          <InfoDocumentation projectId={Number(project.id)} />
        )}
      </ScrollWrap>
    );
  }
  return null;
};
