import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import { ProcessWorkModel } from "src/FSD/entities/processWork";
import { setWorkPublicationActive } from "src/FSD/entities/processWork/model/modalsSlice";
import { Modal as ModalNew } from "src/FSD/shared/uiKit/v2/Modal";
import PublishedObjectModals from "src/FSD/widgets/v2/publishedObject/modals";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { TWorks } from "src/app/feature/ProjectProcessView/Work/types/projectProcessWorkTypes";
import { TaskCard } from "../../../../../newUI/TaskCard/TaskCard";
import Modal from "../../../../../newUI/Modal/Modal";
import {
  RefType,
  ThreeDotButton,
} from "../../../../../newUI/ThreeDotButton/ThreeDotButton";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import cls from "../GroupsCard.module.scss";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessWorkPublish,
  getProjectProcessWorkPublishUpdateIsLoad,
  getProjectProcessWorkUpdatePublish,
} from "src/app/feature/ProjectProcessView/Work/selectors/getProjectProcessWorkPublish";
import { fetchProcessWorkDuplicate } from "src/app/feature/ProjectProcessView/Work/services/fetchProcessWork";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ModalTaskPublishUpdate } from "./ModalTaskPublishUpdate/ModalTaskPublishUpdate";
import { fetchProjectProcessWorkUnPublish } from "src/app/feature/ProjectProcessView/Work/services/fetchProjectProcessWorkUnPublish";
import { config } from "src/app.cofig";

type WorkListProps = TWorks & { userFio: string };

export const WorkList: FC<WorkListProps> = memo(
  ({ dot, work, userFio, appCount }) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const publishWork = useAppSelector(getProjectProcessWorkPublish);
    const updateWork = useAppSelector(getProjectProcessWorkUpdatePublish);
    // const isLoadWorkPublish = useAppSelector(
    //   getProjectProcessWorkPublishIsLoad
    // );
    const isLoadWorkPublishUpdate = useAppSelector(
      getProjectProcessWorkPublishUpdateIsLoad
    );
    const threeDotButtonRef = useRef<RefType>(null);
    const [modalTaskPublish, setModalTaskPublish] = useState(false);
    const [modalTaskPublishUpdate, setModalPublishUpdate] = useState(false);
    const isMobile = useIsMobile(680);
    const closeThreeDot = () => {
      if (threeDotButtonRef.current) {
        threeDotButtonRef.current.handleClose();
      }
    };

    useEffect(() => {
      if (processId && (publishWork || updateWork)) {
        dispatch(fetchProcessWorkDuplicate(processId));
      }
    }, [publishWork, updateWork]);

    const handleNavigate = () => {
      window.open(`${config.localDomain}/process-work/${work.id}`, "_blank");
    };

    // function handlePublishTask() {
    //   setModalTaskPublish(true);
    //   closeThreeDot();
    // }

    function handleUpdatePublishTask() {
      setModalPublishUpdate(true);
      closeThreeDot();
    }

    const unPublishWorkClick = async () => {
      const result = window.confirm(
        "Вы уверены, что хотите вернуть задачу с публикации?"
      );
      if (result && processId) {
        await dispatch(fetchProjectProcessWorkUnPublish(work.id));
        await dispatch(fetchProcessWorkDuplicate(processId));
      }
    };

    const closeModalPublishTask = useCallback(() => {
      setModalTaskPublish(false);
    }, []);

    const { workPublication } = useAppSelector(
      ProcessWorkModel.modals.getModalsData
    );

    const handleOpenWorkPublication = () => {
      dispatch(setWorkPublicationActive(true));
      closeThreeDot();
    };

    const handleCloseWorkPublication = () => {
      dispatch(setWorkPublicationActive(false));
    };

    return (
      <>
        <li>
          <TaskCard {...work} dot={dot} userFio={userFio} appCount={appCount}>
            {work && (
              <ModalNew
                isActive={workPublication}
                handleClose={handleCloseWorkPublication}
              >
                <PublishedObjectModals.PublishObjectForm
                  id={work.id}
                  header="Публикация задачи"
                  type={PublishedObjectControllingEntities.PROCESS_WORK}
                />
              </ModalNew>
            )}
            {work.can.unpublish && (
              <Modal
                active={modalTaskPublishUpdate}
                setActive={setModalPublishUpdate}
                isLoad={isLoadWorkPublishUpdate}
              >
                <ModalTaskPublishUpdate
                  {...work}
                  closeModal={closeModalPublishTask}
                />
              </Modal>
            )}
            <CustomButton
              background={palette.blue}
              width={140}
              className={classNames(isMobile && cls.btnNone)}
              onClick={handleNavigate}
            >
              Открыть
            </CustomButton>
            <ThreeDotButton ref={threeDotButtonRef}>
              <MenuItem
                onClick={() => {
                  closeThreeDot();
                  handleNavigate();
                }}
                disableRipple
              >
                Перейти к задаче
              </MenuItem>
              {work.can.publish && (
                <MenuItem onClick={handleOpenWorkPublication} disableRipple>
                  Опубликовать
                </MenuItem>
              )}
              {work.can.unpublish && (
                <MenuItem onClick={handleUpdatePublishTask} disableRipple>
                  Редактировать
                </MenuItem>
              )}
              {work.can.unpublish && (
                <MenuItem onClick={unPublishWorkClick} disableRipple>
                  Вернуть с публикации
                </MenuItem>
              )}
            </ThreeDotButton>
          </TaskCard>
        </li>
      </>
    );
  }
);
