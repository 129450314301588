import React from "react";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { useRemarkGetRemarksQuery } from "src/FSD/entities/expertise/remark/api";
import { List, Modal } from "src/FSD/shared/uiKit/v2";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { TFilter } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseRemarks/ui/HeaderRemarksFilter/HeaderRemarksFilter";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { HeaderRemarks } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseRemarks/ui/HeaderRemarks/HeaderRemarks";
import { RemarkCard } from "./RemarkCard/RemarkCard";

interface TProp {
  processId: number;
  projectId?: number;
  expertiseRemarksShow?: boolean;
  can?: ApiProjectProcessResponses.Can;
  title?: string;
  projectName: string;
}

export const RemarksExpertise = ({
  processId,
  projectId,
  expertiseRemarksShow,
  can,
  title,
  projectName,
}: TProp) => {
  const [filter, setFilter] = React.useState<TFilter>({
    partGroupId: undefined,
    projectPartIds: undefined,
    status: undefined,
  });

  const { data = {}, isFetching } = useRemarkGetRemarksQuery(
    {
      id: processId,
      partGroupId: filter.partGroupId,
      projectPartIds: filter.projectPartIds,
      status: filter.status,
    },
    { skip: !(expertiseRemarksShow && processId) }
  );
  const isLoading = !data || isFetching;
  const expertiseRemarks = Object.values(data);

  const { name, handleCloseModal, restData } = useModal();

  return (
    <>
      <HeaderRemarks
        can={can}
        projectId={projectId as number}
        processId={processId}
        setFilter={setFilter}
      />
      <List maxHeight="865px" gap={10}>
        {isLoading ? (
          <Skeleton.List height={65} />
        ) : (
          <ListMapper>
            {expertiseRemarks.map((remark, index) => (
              <RemarkCard
                key={remark.remark.id}
                {...remark}
                index={index + 1}
                processId={processId}
                can={can}
              />
            ))}
          </ListMapper>
        )}
      </List>
      <Modal
        isActive={name === ModalNames.ProjectProcess.COMMENT_REMARK_ROW}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.ExpertiseCommentCreateForm
          rowId={restData.rowId}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.TASK_COMPLETE}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.TaskComplete
          row={restData.row}
          task={restData.task}
          expertiseName={title!}
          projectName={projectName}
          mode="edit"
        />
      </Modal>
    </>
  );
};
