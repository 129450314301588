import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { freezeFundsSchema } from "../schemas/freezeFundsSchema";
import { useQueries } from "./useQueries";

type TFieldValues = {
  price: number;
  maxDeposit: number;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
  type: PublishedObjectControllingEntities;
};

export const useFreezeFunds = ({
  id,
  defaultValues,
  handleClose,
  type,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(freezeFundsSchema),
    mode: "onChange",
  });

  const { processData, isFetching, freezeFunds } = useQueries({ id, type });

  useEffect(() => {
    if (!processData) {
      return;
    }

    const maxDeposit =
      Number(processData.price) - Number(processData.account!.value);
    setValue("maxDeposit", maxDeposit);
  }, [processData]);

  const label = processData?.executor_nds
    ? "Сумма с налогом (НДС)"
    : "Сумма без налога (НДС)";

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      value: data.price,
    };

    await freezeFunds({ id, data: formedData });

    handleClose();
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    isSubmitting,
    isPublishedObjectPending: isFetching || !processData,
    label,
  } as const;
};
