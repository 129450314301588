import { useContext } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { BorderLine } from "./BorderLine";
import styles from "./Comparison.module.scss";
import { ProcessCheckbox } from "./ProcessCheckbox";
import { EmptyCell } from "./EmptyCell";
import { WarningCell } from "./WarningCell";
import { ApplicationsContext } from "../Applications";
import { getProcessesByProcessTypeId } from "../../lib/helpers/getProcessesByProcessTypeId";

type TProps = {
  applicationsGroup: ApiProjectResponses.GetApplications[0];
  processTypeId: number;
};

export const ProcessesTable = ({
  applicationsGroup,
  processTypeId,
}: TProps) => {
  const { selectedUserTypeIds, form } = useContext(ApplicationsContext);

  const processesByProcessTypeId = getProcessesByProcessTypeId(
    applicationsGroup,
    selectedUserTypeIds,
    processTypeId
  );

  return (
    <>
      {processesByProcessTypeId.map(({ processId, title }) => (
        <>
          <Paragraph className={styles.rowName}>{title}</Paragraph>
          {selectedUserTypeIds.map((userTypeId) => {
            const { price, badReasons, ndsText, task, process } =
              applicationsGroup.userTypes[userTypeId].appProcesses[processId];

            if (!task) {
              return (
                <>
                  <BorderLine />
                  <EmptyCell />
                </>
              );
            }

            if (badReasons.length) {
              return (
                <>
                  <BorderLine />
                  <WarningCell>{badReasons[0]}</WarningCell>
                </>
              );
            }

            return (
              <>
                <BorderLine />
                <ProcessCheckbox
                  taskId={task.id}
                  price={price}
                  form={form}
                  ndsText={ndsText}
                  dateStart={task.workflow_data.date_limit.date}
                  dateLimit={task.workflow_data.date_limit.date}
                  field={{ processTypeId, userTypeId, processId }}
                  processPrice={Number(process.price)}
                />
              </>
            );
          })}
        </>
      ))}
    </>
  );
};
