import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import PublishedObjectModals from "src/FSD/widgets/v2/publishedObject/modals";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import {
  useProjectProcessPauseMutation,
  useProjectProcessUnpublishMutation,
} from "src/FSD/entities/projectProcess/api";
import TaskModals from "../../../../widgets/v2/task/modals";

type TProps = {
  projectProcess: ApiProjectProcessResponses.View;
};

export const Actions = ({ projectProcess }: TProps) => {
  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  const [pauseProcess] = useProjectProcessPauseMutation();

  const [unpublishProcess] = useProjectProcessUnpublishMutation();

  const handleUnpublishProcess = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );

    if (result) {
      await unpublishProcess({ id: projectProcess.id });
    }
  };

  const handlePauseProcess = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить раздел?"
    );

    if (result) {
      await pauseProcess({ id: projectProcess.id, data: { paused: true } });
      closeThreeDot();
    }
  };

  const handleUnPauseProcess = async () => {
    const result = window.confirm("Вы уверены, что хотите возобновить раздел?");

    if (result) {
      await pauseProcess({ id: projectProcess.id, data: { paused: false } });
      closeThreeDot();
    }
  };

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const projectProcessName = `«${projectProcess.projectPart.name}» по проекту «${projectProcess.project.name}»`;

  return (
    <>
      <Modal
        isActive={name === ModalNames.Task.FEEDBACK}
        handleClose={handleCloseModal}
      >
        <TaskModals.FeedbackForm
          taskId={restData.taskId}
          header={restData.header}
          isExecutor={restData.isExecutor}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.PublishObjectForm
          id={restData.id}
          header={restData.header}
          type={restData.type}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.UpdatePublishedObjectForm
          id={restData.id}
          header={restData.header}
          type={restData.type}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.FREEZE_FUNDS}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.FreezeFundsForm
          id={projectProcess.id}
          header="Пополнение баланса раздела"
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <ThreeDotButton ref={threeDotButtonRef}>
        {projectProcess.can!.publish &&
          projectProcess.projectPart.published && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  name: ModalNames.PublishedObject.PUBLISH_OBJECT,
                  header: `Публикация раздела «${projectProcessName}»`,
                  id: projectProcess.id,
                  type: PublishedObjectControllingEntities.PROJECT_PROCESS,
                });
              }}
            >
              Опубликовать
            </MenuItem>
          )}
        {(projectProcess.can!.update || projectProcess.can!.unpublish) && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                header: `Изменение опубликованного раздела ${projectProcessName}`,
                name: ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT,
                type: PublishedObjectControllingEntities.PROJECT_PROCESS,
                id: projectProcess.id,
              });
            }}
          >
            Редактировать
          </MenuItem>
        )}
        {projectProcess.can!.unpublish && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleUnpublishProcess();
            }}
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {projectProcess.can!.freezeFunds && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.PublishedObject.FREEZE_FUNDS,
              });
            }}
          >
            Пополнить баланс
          </MenuItem>
        )}
        {projectProcess.can!.pause && (
          <MenuItem onClick={handlePauseProcess}>Приостановить</MenuItem>
        )}
        {projectProcess.can!.unpause && (
          <MenuItem onClick={handleUnPauseProcess}>Возобновить</MenuItem>
        )}
        {projectProcess.task?.can?.feedbackToController && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
              handleOpenModal({
                name: ModalNames.Task.FEEDBACK,
                taskId: projectProcess.task.id,
                header: "Оценить заказчика",
                isExecutor: false,
              });
            }}
            disableRipple
          >
            Оценить заказчика
          </MenuItem>
        )}
        {projectProcess.can!.chat && (
          <MenuItem
            component={Link}
            to={`/chat/${projectProcess.project.chatId}`}
            target="_blank"
          >
            Чат
          </MenuItem>
        )}
      </ThreeDotButton>
    </>
  );
};
