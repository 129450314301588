import { Card } from "src/FSD/shared/uiKit/v2";
import { Description } from "src/FSD/shared/uiKit/v2/Description/Description";
import styles from "./InfoDescription.module.scss";

type InfoDescriptionProps = {
  description: string;
  name: string;
};

export const InfoDescription = ({
  description,
  name,
}: InfoDescriptionProps) => {
  return (
    <Card
      className={styles.description}
      header={<Card.Header>{name}</Card.Header>}
    >
      <Description text={description} linesCount={3} />
    </Card>
  );
};
