import { httpRequestDelete, httpRequestGet, httpRequestPut } from "../api";
import { config } from "../../../../app.cofig";

export const ApiGetUsersProject = (): Promise<Response> => {
  // Пользователи для назначения на роль
  return httpRequestGet(`${config.localDomain}/v1/user/get-available-users`);
};

export const ApiGetUsersTypeProject = (
  id: string | number
): Promise<Response> => {
  // Типы пользователей
  return httpRequestGet(
    `${config.localDomain}/v1/user-type/get-user-types?userId=${id}`
  );
};

export interface ApiUpdateParams {
  ProjectAccess: {
    access_code: number;
    user_id: number;
    user_type_id: number;
  };
}
export const ApiUpdateUserProject = (
  id: number,
  data: ApiUpdateParams
): Promise<Response> => {
  // Назначение
  return httpRequestPut(
    `${config.localDomain}/v1/project-access/update?id=${id}`,
    {},
    data
  );
};

export const ApiGetAccessCodesByProject = (
  projectId: number,
  isInviteGip: boolean
): Promise<Response> => {
  return httpRequestGet(
    `${
      config.localDomain
    }/v1/project-access/get-access-codes-by-project?projectId=${projectId}&isInviteGip=${Number(
      isInviteGip
    )}`
  );
};

export const ApiDeleteProjectAccess = (id: number): Promise<Response> => {
  return httpRequestDelete(
    `${config.localDomain}/v1/project-access/delete?id=${id}`
  );
};
