import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  partNum: number;
}>;

export const getTemplateList = (builder: QueryBuilder) =>
  builder.query<any, TParams>({
    query: (params) => ({
      url: `v1/material-process/get-template-list`,
      params: {
        partNum: params.partNum,
        expand: concatenateParams(params.expand),
      },
    }),
  });
