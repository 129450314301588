import React, { useState, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import style from "./style.module.scss";
import PaymentsForm from "./Form/PaymentForm";
import {
  getErrorsList,
  FormErrorType,
} from "../../../../../app/services/api/requestHandler";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { UnsavedType } from "../../../Project/types";

interface ComponentProps {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
}

export type SubmitDataProps = {
  inn: string;
  bik: string;
  bank_name: string;
  card_owner_fio: string;
  correspondent_account: string;
  bank_account: string;
  card_num: string;
  snils: string;
  passport_serial: string;
  passport_num: string;
  passport_issued_by: string;
  passport_date: Date | null;
  passport_file: File[];
  inn_file: File[];
  snils_file: File[];
} & Record<string, any>;

const Payments: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const [errors, setErrors] = useState<FormErrorType[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    paymentInfoIsLoading,
    paymentInfo,
    loadPaymentInfo,
    updatePaymentInfo,
    canEdit,
  } = useProfile();

  useEffect(() => {
    loadPaymentInfo(userId);
  }, [userId]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<SubmitDataProps> = (data) => {
    setDisabled(true);
    updatePaymentInfo({ id: userId, canEdit, ...data })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, payments: false }));
        setDisabled(false);
        setErrors([]);
        loadPaymentInfo(userId);
      })
      .catch((err) => {
        setErrors(getErrorsList(err));

        setDisabled(false);
        enqueueSnackbar("При обновлении данных возникли ошибки", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <div className={style.wrapper}>
      {paymentInfoIsLoading || !paymentInfo ? (
        <Skeleton
          variant="rectangular"
          sx={{ bgcolor: "#37474F", borderRadius: "10px" }}
          height="500px"
        />
      ) : (
        <PaymentsForm
          setUnsaved={setUnsaved}
          disabled={disabled}
          errorsList={errors}
          paymentInfo={paymentInfo}
          canEdit={canEdit}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default Payments;
