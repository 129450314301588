import { createSlice } from "@reduxjs/toolkit";
import { ProjectListStateType } from "../types/ProjectListType";
import { fetchProjectListByProcessId } from "../services/fetchProjectListByProcessId";

interface InitialState {
  isLoadProjectList: boolean;
  projectList: ProjectListStateType[];
}

const initialState: InitialState = {
  isLoadProjectList: false,
  projectList: [],
};

const projectListByProcess = createSlice({
  name: "projectListByProcess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectListByProcessId.pending, (state) => {
      state.isLoadProjectList = true;
    });
    builder.addCase(fetchProjectListByProcessId.fulfilled, (state, action) => {
      state.isLoadProjectList = false;
      const newProjectList = action.payload?.map((project) => {
        return {
          title: project.name,
          id: project.id,
        };
      });
      state.projectList = newProjectList;
    });
  },
});
export default projectListByProcess;
