import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { Select } from "../../UI/components/FormComponentns/Select";
import {
  selectorModalClosed,
  setModalClosed,
} from "src/app/feature/modalClose/eventModalClose";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { margin } from "./SendMoneyForm";
import { request } from "src/app/services/api/requestHandler";
import {
  ApiGetInnerSpecializations,
  ApiRemoveTeammate,
} from "src/app/services/api/organisation/organisation";
import { selectorManageTeammateData } from "src/app/feature/eventEmitter/organisation/eventManageTeammate";
import { palette } from "src/styles/restyle";
import { TSelectItem } from "../../features/Forms/SetGipForm/types";

interface OptionType {
  id: string | number;
  title: string;
}

type TFormValues = {
  specialization: TSelectItem | null;
};

export const RemoveTeammateForm: React.FC = () => {
  const { id, candidateId } = useSelector(selectorManageTeammateData);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [specializationList, setSpecializationList] = useState<OptionType[]>(
    []
  );

  const dispatch = useDispatch();
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  const { setValue, handleSubmit, watch } = useForm<TFormValues>({
    defaultValues: {
      specialization:
        specializationList.length > 0 ? specializationList[0] : null,
    },
  });

  const watchSpecialization = watch("specialization");

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const onSubmit = async (data: any) => {
    setDisabled(true);

    await request(
      ApiRemoveTeammate(candidateId, data.specialization.id),
      () => {
        enqueueSnackbar("Успешно", {
          variant: "success",
          autoHideDuration: 5000,
        });
        Popup.manageTeammate.set({});
      },
      () => (err) => {
        const message =
          typeof err.message === "string" ? err.message : "Возникла ошибка";
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setDisabled(false);
    dispatch(setModalClosed(!modalClosed));
  };

  useEffect(() => {
    request(
      ApiGetInnerSpecializations(candidateId, id),
      (data) => {
        const formedData = Object.keys(data).map((key: any) => {
          const { id } = data[key];
          const title =
            data[key].part_group_id[Object.keys(data[key].part_group_id)[0]];

          return {
            id,
            title,
          };
        });

        setSpecializationList(formedData);
      },
      () => () => {
        enqueueSnackbar("Возникла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, style.noPaddings)}
    >
      <Select
        value={watchSpecialization}
        name={"specialization"}
        changeHandler={(_, value) => {
          setValue("specialization", value);
        }}
        label={"Специализация"}
        options={specializationList}
        isLoading={false}
        disableClear
      />
      <br />
      <CustomButton
        width={160}
        disabled={disabled}
        type="submit"
        background={palette.blue}
        style={{ margin }}
      >
        Добавить
      </CustomButton>
    </form>
  );
};
