import React, { FC } from "react";
import { CircularProgress } from "@mui/material";
import cn from "classnames";
import style from "../style.module.scss";
import { TProject } from "../../../../../../app/feature/dashboard/projects/thunks";
import DashboardSkeleton from "../../Skeleton";
import useDynamicPagination from "./useDynamicPagination";
import ProjectItem from "./ProjectItem";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

interface ProjectsListProps {
  fulfilled: boolean;
  query: string;
}

const ProjectsList: FC<ProjectsListProps> = ({ fulfilled, query }) => {
  const isMobile = useIsMobile(1100);

  const { scrollRef, scrollHandler, finalData, pending, totalCount } =
    useDynamicPagination(fulfilled, query);

  if (totalCount === null) {
    return <DashboardSkeleton />;
  }

  return (
    <div
      className={cn(style.list, style.projectList)}
      onScroll={scrollHandler}
      ref={scrollRef}
    >
      {finalData.map((project: TProject) => (
        <ProjectItem isMobile={isMobile} {...project} key={project.id} />
      ))}
      {pending && (
        <div className={style.preloader}>
          <CircularProgress />
        </div>
      )}
      {totalCount === 0 && <p className={style.center}>Проекты не найдены</p>}
    </div>
  );
};

export default ProjectsList;
