import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Tasks.module.scss";

type TProps = {
  price: string | number;
  dateDeadline: string;
};

export const Price = ({ price, dateDeadline }: TProps) => {
  return (
    <div className={styles.tasksBlock__content}>
      <Paragraph fontSize={24} color="light">
        {price} ₽
      </Paragraph>
      <Paragraph>Действительно</Paragraph>
      <Paragraph>до {dateDeadline}</Paragraph>
    </div>
  );
};
