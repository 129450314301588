import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiMaterialProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const viewFormColumns = (builder: QueryBuilder) =>
  builder.query<ApiMaterialProcessResponses.ViewFormColumn[], TParams>({
    query: (params) => ({
      url: `v1/material-process/view-form-columns`,
      params: {
        id: params.id,
        expand: concatenateParams(params.expand),
      },
    }),
  });
