import { Controller, UseFormReturn } from "react-hook-form";
import {
  Checkbox,
  DateField,
  Paragraph,
  TextField,
} from "src/FSD/shared/uiKit/v2";
import styles from "./styles.module.scss";
import { checkRow } from "../lib/helpers/checkRow";
import { setCommonPrice } from "../lib/helpers/setCommonPrice";
import { useProcessesList } from "../lib/hooks/useProcessesList";
import { TGroupFieldValues } from "../model/types/fieldValues";

type Props = {
  processId: number;
  form: UseFormReturn<TGroupFieldValues>;
};

export const ProcessesList = ({ processId, form }: Props) => {
  const { fields, toggleSelectAll, checkSelectAll } = useProcessesList({
    processId,
    form,
  });

  const { control, getValues } = form;

  const areAllSelectedDisabled =
    fields.length === 1 && fields[0].processId === processId;

  return (
    <div className={styles.datesList__processes}>
      <Paragraph color="light" className={styles.subTitle}>
        Выберите разделы группы
      </Paragraph>
      <div className={styles.datesGrid}>
        <Controller
          control={control}
          name={"common.areAllSelected"}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                checked={value}
                onChange={() => {
                  toggleSelectAll(!value);
                  onChange(!value);
                }}
                editing
                disabled={areAllSelectedDisabled}
                label="Выбрать все"
              />
            );
          }}
        />
        <Paragraph>Начальный срок</Paragraph>
        <Paragraph>Конечный срок</Paragraph>
        <Paragraph>Стоимость</Paragraph>
        <>
          {fields.map((_, index) => {
            const id = getValues(`processes.${index}.processId`);
            const isActive = getValues(`processes.${index}.isActive`);

            return (
              <>
                <Controller
                  control={control}
                  name={`processes.${index}.isActive`}
                  render={({ field: { value, onChange } }) => {
                    const name = getValues(`processes.${index}.name`);

                    return (
                      <Checkbox
                        editing
                        value={value}
                        checked={isActive}
                        disabled={id === processId}
                        label={name}
                        onChange={() => {
                          onChange(!isActive);
                          checkRow(index, form);
                          checkSelectAll();

                          setCommonPrice(form, fields);
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name={`processes.${index}.dateStart`}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <DateField
                        isCorrect={isActive}
                        changeDateHandler={(newValue) => {
                          onChange(newValue);
                          checkRow(index, form);
                        }}
                        startDateProp={value}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name={`processes.${index}.dateLimit`}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <DateField
                        isCorrect={isActive}
                        changeDateHandler={async (newValue) => {
                          onChange(newValue);
                          checkRow(index, form);
                        }}
                        startDateProp={value}
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    );
                  }}
                />
                <Controller
                  name={`processes.${index}.price`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        checkRow(index, form);
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                />
              </>
            );
          })}
        </>
      </div>
    </div>
  );
};
