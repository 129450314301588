import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import styles from "./Info.module.scss";
import { Payment } from "../../../publishedObject/Info/ui/Payment/Payment";
import { Requirements } from "../../../publishedObject/Info/ui/Requirements/Requirements";
import { Executor } from "../../../publishedObject/Info/ui/Executor/Executor";
import { Description } from "../../../publishedObject/Info/ui/Description/Description";
import { Deadlines } from "../../../publishedObject/Info/ui/Deadlines/Deadlines";

type TProps = {
  processWork: ApiProcessWorkResponses.View;
};

export const Info = ({ processWork }: TProps) => {
  return (
    <div className={styles.grid}>
      <Description publishedObject={processWork} />
      <Deadlines publishedObject={processWork} />
      <Payment
        publishedObject={processWork}
        type={PublishedObjectControllingEntities.PROCESS_WORK}
      />
      <Executor
        publishedObject={processWork}
        type={PublishedObjectControllingEntities.PROCESS_WORK}
        accessType={ProjectAccessControllingEntities.PROCESS_WORK}
      />
      <Requirements
        publishedObject={processWork}
        type={PublishedObjectControllingEntities.PROCESS_WORK}
      />
    </div>
  );
};
