import { useState, useMemo, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { getParentsProjects } from "../../../../../../app/feature/dashboard/projects/thunks";
import {
  getDashboardProjects,
  clearProjectsData,
} from "../../../../../../app/feature/dashboard/projects";
import useNonInitialEffect from "../../../../../hooks/useNonInitialEffect";
import { getDashboardDestinations } from "../../../../../../app/feature/dashboard/destinations";

const useDynamicPagination = (fulfilled: boolean, query: string) => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const { userType } = useAppSelector(getDashboardDestinations);
  const { data, pending, totalCount } = useAppSelector(getDashboardProjects);

  const finalData = useMemo(() => {
    const ids = new Set<number>();

    data.forEach((project: any) => {
      ids.add(project.id);
    });

    return Array.from(ids).map((id: number) => {
      const foundProject = data.find((project: any) => project.id === id);
      return foundProject;
    });
  }, [data]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollHandler = () => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight } = scrollRef.current;

    if (
      scrollHeight - (scrollTop + 600) <= 50 &&
      (totalCount === null || data.length < totalCount) &&
      !pending
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (
      (totalCount === null && page === 1) ||
      (totalCount !== null && page > 1)
    ) {
      dispatch(getParentsProjects({ userType, page, fulfilled, query }));
    }
  }, [page, dispatch, totalCount, fulfilled, query]);

  useNonInitialEffect(() => {
    setPage(1);
    dispatch(clearProjectsData());
  }, [userType, fulfilled, query]);

  return { scrollRef, scrollHandler, finalData, pending, totalCount } as const;
};

export default useDynamicPagination;
