import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import styles from "./MaterialProcessQuestionnaire.module.scss";

type TProps = {
  attr: ApiMaterialProcessResponses.Attr;
};

export const AttrColumn = ({ attr }: TProps) => {
  const values = attr.data.map((item) => item.value).join("; ");

  return (
    <li className={styles.attr__item}>
      <Paragraph fontSize={18} color="light">
        {attr.label}
      </Paragraph>
      <Paragraph>{values}</Paragraph>
    </li>
  );
};
