import classNames from "classnames";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { stringDateToDDMMYYYY } from "src/FSD/shared/lib/helpers";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import styles from "./Stop.module.scss";

interface Props {
  task: ApiTaskResponses.View;
  complete: any;
}

export const StopReason = ({ task, complete: completeTask }: Props) => {
  const handleCompleteTask = (id: number) => {
    completeTask({
      id,
      task: {
        CompleteForm: {
          status: Statuses.DONE,
        },
      },
    });
  };

  const dateCreate = task.date_create
    ? stringDateToDDMMYYYY(task.date_create)
    : "-";

  const dateComplete = task.date_complete
    ? stringDateToDDMMYYYY(task.date_complete)
    : null;

  const isChecked = Boolean(dateComplete);

  return (
    <>
      <p className={classNames(!isChecked && styles.stop)}>{task.content}</p>
      <p className={classNames(!isChecked && styles.stop)}>{dateCreate}</p>
      <p>{dateComplete}</p>
      <div className={styles.stopRowAction}>
        {!isChecked && (
          <Button
            variant="outlined"
            onClick={() => {
              handleCompleteTask(task.id);
            }}
          >
            Закрыть
          </Button>
        )}
      </div>
    </>
  );
};
