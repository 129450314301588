import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import { Info } from "src/FSD/widgets/v2/publishedObject/Info";
import { Removed } from "src/FSD/widgets/v2/processWork/Removed";
import { SourceFiles } from "src/FSD/widgets/v2/processWork/SourceFiles";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { Applications } from "src/FSD/widgets/v2/processWork/Applications";
import { Documents } from "src/FSD/widgets/v2/processWork/Documents";
import { ExecutionTasks } from "src/FSD/widgets/v2/processWork/ExecutionTask";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { useProcessWorkViewPage } from "../lib/hooks/useProcessWorkViewPage";
import { SkeletonPage } from "./SkeletonPage";
import { ScreenResultions } from "../lib/constants/screenResolutions";
import { Actions } from "./Actions";
import { ApiProcessWorkResponses } from "../../../../entities/processWork/model/responseTypes";

export const ProcessWorkViewPage = () => {
  const isMobile = useResolution(ScreenResultions.MOBILE);
  const isTablet = useResolution(ScreenResultions.TABLET);

  const { id } = useParams();

  const { processWork, device, breadCrumbs, isProcessWorkLoading } =
    useProcessWorkViewPage({
      processWorkId: Number(id),
    });

  const componentsList = useMemo(() => {
    if (!processWork) {
      return {};
    }

    const components: ApiProcessWorkResponses.ComponentsType = {
      info: <Info processWork={processWork} />,
      sourceFiles: <SourceFiles processWork={processWork} />,
      applications: <Applications processWorkId={processWork.id} />,
      removed: <Removed processWorkId={processWork.id} />,
      executionTask: (
        <ExecutionTasks processWorkId={processWork.id} can={processWork.can} />
      ),
      documents: <Documents activeContract={processWork.activeContract} />,
    };

    return components;
  }, [processWork]);

  return (
    <PageWrapper>
      {isProcessWorkLoading ? (
        <SkeletonPage isMobile={isMobile} isTablet={isTablet} />
      ) : (
        <>
          <Breadcrumbs items={breadCrumbs} />
          <TabsPanel
            title={processWork!.title}
            subTitle={processWork!.status.value}
            tabsList={processWork!.tabs}
            componentsList={componentsList}
            device={device}
            actions={<Actions processWork={processWork!} />}
          />
        </>
      )}
    </PageWrapper>
  );
};
