import { ApiProjectProcessRequests } from "src/FSD/entities/projectProcess/model/requestTypes";
import {
  TFormCache,
  TGroupPublicationFieldValues,
  TPublicationFieldValues,
} from "../../model/types/fieldValues";
import { TSubmitAction } from "../hooks/usePublicationForm";
import { createCurrentGroupProcesses } from "./createCurrentGroupGroupProcesses";

type TPublicationGroupType = {
  pay1: number;
  pay2: number;
  pay3: number;
  id: number[];
};

export const createPublicationFormData = (
  publicationFormValues: TPublicationFieldValues,
  formCache: TFormCache,
  action: TSubmitAction
) => {
  const groupFormValues = structuredClone(formCache);
  delete groupFormValues.publication;

  const types: Record<string, TPublicationGroupType> = {};

  const {
    executorType,
    executorByAdmin,
    executorTypeByAdmin,
    showExecutorAssignment,
    kac,
    vor,
    bim,
    vorPnr,
    withDocs,
    secure,
  } = publicationFormValues;

  const processes = Object.keys(groupFormValues).reduce(
    (groupProcesses, groupId) => {
      const { processes, pay1, pay2, pay3, maybeExpertise } = groupFormValues[
        groupId
      ] as TGroupPublicationFieldValues;

      types[`type${groupId}`] = {
        pay1,
        pay2,
        pay3: maybeExpertise ? pay3 : 0,
        id: processes
          .filter(({ isActive }) => isActive)
          .map(({ processId }) => processId),
      };

      const currentGroupProcesses = createCurrentGroupProcesses(processes);

      return { ...groupProcesses, ...currentGroupProcesses };
    },
    {}
  );

  const formedData: ApiProjectProcessRequests.GroupPublish = {
    save: action === "draft",
    ProjectProcess: {
      0: {
        executor_type: executorType.map(({ id }) => Number(id)),
      },
      ...types,
      ...processes,
      secure: Boolean(secure.id),
      with_docs: Boolean(withDocs.id),
      vor_pnr: vorPnr,
      vor,
      kac,
      bim,
    },
  };

  if (showExecutorAssignment) {
    formedData.ProjectProcess[0].executorByAdminId = Number(
      executorByAdmin!.id
    );
    formedData.ProjectProcess[0].executorTypeByAdminId = Number(
      executorTypeByAdmin!.id
    );
  }

  return formedData;
};

export type TGroupPublicationSubmitData = ReturnType<
  typeof createPublicationFormData
>;
