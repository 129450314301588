import React, { useState, useMemo, useEffect, memo } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import cn from "classnames";
import globalStyle from "../Primary/style.module.scss";
import FilterTreaties from "../Filter/Treaties";
import useFilter from "../Filter/useFilter";
import Switch from "../../../../newUI/Switch/Switch";
import SearchField from "../SearchField";
import style from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getDashboardTreaties } from "src/app/feature/dashboard/treaties";
import { getDashboardDestinations } from "src/app/feature/dashboard/destinations";
import { getTreaties } from "src/app/feature/dashboard/treaties/thunks";
import DashboardSkeleton from "../Skeleton";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { TreatiesGroup } from "./Treaty/Group";

type TreatiesProps = {
  type: "documents" | "treaties";
};

const Treaties = ({ type }: TreatiesProps) => {
  const areTreaties = type === "treaties";
  const areDocs = type === "documents";

  const dispatch = useAppDispatch();
  const [fulfilled, setFulfilled] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const { userType, projectId, sectionId, workId } = useAppSelector(
    getDashboardDestinations
  );
  const { data, pending } = useAppSelector(getDashboardTreaties);
  const { treaties, documents } = data;

  const dataForProcess = useMemo(() => {
    if (areDocs) {
      return documents;
    }
    if (areTreaties) {
      return treaties;
    }

    return {};
  }, [data]);

  const { ticked, handleChange } = useFilter(
    dataForProcess,
    query,
    fulfilled,
    FilterTreaties,
    setQuery
  );

  const isMobile = useIsMobile(1600);
  const isRealMobile = useIsMobile(1100);

  const treatiesData = Object.entries(treaties);

  useEffect(() => {
    if (areTreaties) {
      let type = "projectId";
      let id = projectId;
      if (sectionId) {
        type = "processId";
        id = sectionId;
      }
      if (workId) {
        type = "workId";
        id = workId;
      }

      if (!id) {
        return;
      }

      dispatch(
        getTreaties({
          id,
          type,
          userType,
        })
      );
    }
  }, [projectId, sectionId, workId]);

  const head = (
    <div className={cn(style.generalHeader, style.treatiesHeader)}>
      <h1>Название</h1>
      <p>Разделы</p>
      <p>Срок</p>
      <p>Стоимость</p>
    </div>
  );

  const treatiesList = Boolean(projectId || sectionId) && (
    <div className={globalStyle.list}>
      {!isMobile && head}
      {treatiesData.length ? (
        treatiesData.map(([name, treaties]: any) => (
          <TreatiesGroup
            name={name}
            treaties={treaties}
            ticked={ticked}
            areTreaties={areTreaties}
            isMobile={isMobile}
            offset={8}
          />
        ))
      ) : (
        <p className={globalStyle.center}>Договоры не найдены</p>
      )}
    </div>
  );

  return (
    <div className={cn(globalStyle.wrapper, style.wrapper, style.longWrapper)}>
      <div className={cn(globalStyle.header, style.sectionsFullHeader)}>
        <h3>Договор</h3>
        {areTreaties && (
          <>
            {isMobile ? (
              <div className={style.mobileHeader}>
                <p>Расторгнут</p>
                <Switch
                  checked={fulfilled}
                  onChange={() => setFulfilled(!fulfilled)}
                />
                <IconButton
                  className={style.mobileSearchBar}
                  onClick={() => setShowSearchBar(!showSearchBar)}
                >
                  <Search style={{ color: "#9DAFBD" }} />
                </IconButton>
              </div>
            ) : (
              <>
                <p>Расторгнут</p>
                <Switch
                  checked={fulfilled}
                  onChange={() => setFulfilled(!fulfilled)}
                />
                <SearchField value={query} onChange={handleChange} />
              </>
            )}
          </>
        )}
      </div>
      {isRealMobile && showSearchBar && (
        <SearchField
          className={style.searchBar}
          value={query}
          onChange={handleChange}
          mobile
        />
      )}
      {pending.treaties ? <DashboardSkeleton /> : treatiesList}
    </div>
  );
};

export default memo(Treaties);
