import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import style from "./style.module.scss";
import { UnsavedType } from "../../../Project/types";
import { SetState } from "../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { config } from "../../../../../app.cofig";
import {
  request,
  getErrorMessage,
} from "../../../../../app/services/api/requestHandler";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { clientLogoutClearAllData } from "../../../../../app/services/auth/auth";
import { ApiSelfDestruction } from "../../../../../app/services/api/user/user";

const BIMIT_AUTH_LINK = `${config.localDomain}/site/bimit`;

const MESSAGES = {
  PASSWORD_CHANGED: "Пароль изменён",
  PASSWORD_UPDATE_ERROR: "При обновлении пароля возникли ошибки",
  ACCOUNT_DESTRUCTED: "Аккаунт был успешно отключен",
  ERROR: "Произошла ошибка",
  WARNING: "Вы уверены, что хотите отключить аккаунт?",
};

interface ComponentProps {
  userId: string;
  setUnsaved: SetState<UnsavedType>;
}

interface SubmitDataProps {
  old_password: string;
  password: string;
  password_confirm: string;
}

const schema = yup.object().shape({
  old_password: yup
    .string()
    .required("Это поле обязательно")
    .min(6, "Минимум 6 символов")
    .test("spaces", "Пароль не может содержать только пробелы", (val) =>
      // @ts-ignore
      Boolean(val && !val.split("").every((letter) => letter === " "))
    ),
  password: yup
    .string()
    .required("Это поле обязательно")
    .min(6, "Минимум 6 символов"),
  password_confirm: yup
    .string()
    .required("Это поле обязательно")
    .min(6, "Минимум 6 символов")
    // @ts-ignore
    .oneOf([yup.ref("password"), ""], "Пароли должны совпадать"),
});

const Security: React.FC<ComponentProps> = ({ userId, setUnsaved }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { changePassword } = useProfile();

  const { enqueueSnackbar } = useSnackbar();

  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, security: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  const onSubmit: SubmitHandler<SubmitDataProps> = (data) => {
    setIsFetching(true);
    changePassword(data)
      .unwrap()
      .then(() => {
        enqueueSnackbar(MESSAGES.PASSWORD_CHANGED, {
          variant: "success",
          autoHideDuration: 3000,
        });
        setUnsaved((prev) => ({ ...prev, security: false }));
        setIsFetching(false);
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setIsFetching(false);
      });
  };

  const onDelete = async () => {
    if (!window.confirm(MESSAGES.WARNING)) {
      return;
    }

    await request(
      ApiSelfDestruction(userId),
      () => {
        clientLogoutClearAllData();
        window.location.href = `${config.localDomain}/`;
      },
      () => (error) => {
        const textError = getErrorMessage(error);
        enqueueSnackbar(textError, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    )();
  };

  return (
    <div className={style.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.container}>
          <h2>Смена пароля</h2>
          <div className={style.formContainer}>
            <TextField
              type={showCurrentPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              fullWidth
              label="Текущий пароль"
              autoComplete="new-password"
              {...register("old_password")}
              {...textFieldSX}
              error={!!errors.old_password}
              helperText={
                errors.old_password ? errors.old_password.message : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleCurrentPassword}
                      className={style.iconButton}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showNewPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              fullWidth
              label="Новый пароль"
              autoComplete="new-password"
              {...register("password")}
              {...textFieldSX}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleNewPassword}
                      className={style.iconButton}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              fullWidth
              label="Повторите новый пароль"
              autoComplete="new-password"
              {...register("password_confirm")}
              {...textFieldSX}
              error={!!errors.password_confirm}
              helperText={
                errors.password_confirm ? errors.password_confirm.message : null
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleConfirmPassword}
                      className={style.iconButton}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <CustomButton
            width={160}
            type="submit"
            background={palette.green}
            className={style.button}
            style={{ margin: "16px calc(100% - 160px) 0 0" }}
            disabled={isFetching}
          >
            Сохранить
          </CustomButton>
        </div>
      </form>
      <div className={style.container}>
        <h2>Привязка внешних сервисов</h2>
        <p>
          Привяжите внешние сервисы к аккаунту. После этого вы сможете входить
          на ПИРС в один клик.
        </p>
        <TextLink url={BIMIT_AUTH_LINK} redirect>
          <img
            src={`${config.localDomain}/images/bim_logo.svg`}
            className={style.bimit}
            alt={""}
          />
        </TextLink>
      </div>
      <div className={style.container}>
        <h2>Отключение аккаунта</h2>
        <CustomButton
          width={160}
          type="button"
          background={palette.red}
          className={style.button}
          style={{ margin: "0 calc(100% - 160px) 0 0" }}
          disabled={isFetching}
          onClick={onDelete}
        >
          Отключить
        </CustomButton>
      </div>
    </div>
  );
};

export default Security;
