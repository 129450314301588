import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiProjectProcessRequestTask } from "src/app/services/api/projectProcess/headerBtn";
import { pushError } from "../../../errorTrace";
import { fetchProjectProcessOutTasks } from "../../Tasks/services/fetchProjectProcessTasks";

export const fetchRequestTask = createAsyncThunk<
  boolean,
  { processId: number; data: any },
  { rejectValue: { status: string; message: string } }
>(
  "projectProcess/fetchRequestTask",
  async ({ processId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiProjectProcessRequestTask(processId, data);
      if (!response.ok) {
        throw await response.json();
      }
      dispatch(fetchProjectProcessOutTasks(processId));
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      return dispatch(pushError(err));
    }
  }
);
