import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Modal, Button } from "src/FSD/shared/uiKit/v2";
import { DateStartAndLimit } from "./DateStartAndLimit";
import { TFieldValues } from "../lib/hooks/usePublishContent";

type TProp = {
  header: string;
  form: UseFormReturn<TFieldValues>;
  onSubmit: (dataValues: TFieldValues) => void;
};

export const SimpleUpdateForm = ({ header, form, onSubmit }: TProp) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = form;

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <p>Стоимость, сроки и условия оплаты</p>
        <DateStartAndLimit control={control} errors={errors} />
        <Modal.Controls>
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Изменить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
