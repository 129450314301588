import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { useMaterialProcessQuestionnaire } from "../hooks/useMaterialProcessQuestionnaire";
import styles from "./MaterialProcessQuestionnaire.module.scss";
import { AttrColumn } from "./AttrColumn";

type TProps = {
  materialId: number;
  header: string;
};

export const MaterialProcessQuestionnaire = ({
  materialId,
  header,
}: TProps) => {
  const { viewFormColumns, isFetching } = useMaterialProcessQuestionnaire({
    materialId,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form>
          {viewFormColumns!.map(
            (viewFormColumn: ApiMaterialProcessResponses.ViewFormColumn) => (
              <div className={styles.formColumns}>
                <Paragraph fontWeight="bold" fontSize={18} color="light">
                  {viewFormColumn.group}
                </Paragraph>
                <ul className={styles.attr}>
                  {viewFormColumn.attrs.map(
                    (attr: ApiMaterialProcessResponses.Attr) => (
                      <AttrColumn attr={attr} />
                    )
                  )}
                </ul>
              </div>
            )
          )}
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
