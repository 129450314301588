import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { YMInitializer } from "react-yandex-metrika";
import { FooterNavigation } from "../../UI/components/FooterNavigation/FooterNavigation";
import { FooterSectionInfo } from "../../UI/components/FooterSectionInfo/FooterSectionInfo";
import { Wrapper } from "../../UI/templates";
import { Copyright } from "../../UI/components/Copyright/Copyright";
import style from "./style.module.scss";
import { Social } from "../../UI/components/Social/Social";
import { selectorAppTheme } from "../../../app/feature/app/app";

export interface FooterProps {
  variant?: string;
}

export const Footer: React.FC<FooterProps> = ({ variant = "" }) => {
  const theme = useSelector(selectorAppTheme);

  return (
    <footer className={classNames(style.footer, variant)}>
      <Wrapper variant={style.footerWrapper}>
        <div className={style.row}>
          <FooterSectionInfo />
          <FooterNavigation theme={theme} />
          <FooterNavigation theme={theme} isDocumentation />
        </div>
        <div className={style.bottom}>
          <Copyright variant={style.copyright} />
          <Social variant={style.social} size={"20"} theme={theme} />
        </div>
      </Wrapper>
      {window.location.hostname === "pirs.online" ? (
        <YMInitializer
          accounts={[66624187]} // 66624187 pirs.online
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
          version="2"
        />
      ) : null}
    </footer>
  );
};
