import { useContext } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { BorderLine } from "./BorderLine";
import { PriceSummary } from "./PriceSummary";
import styles from "./Comparison.module.scss";
import { getColumnPriceSum } from "../../lib/helpers/getColumnPriceSum";
import { ApplicationsContext } from "../Applications";

type TProps = {
  processTypeId: number;
  processTypeName: string;
  applicationsGroup: ApiProjectResponses.GetApplications[0];
};

export const ProcessesSummary = ({
  processTypeId,
  processTypeName,
  applicationsGroup,
}: TProps) => {
  const { selectedUserTypeIds, form } = useContext(ApplicationsContext);

  return (
    <>
      <Paragraph className={styles.rowName}>
        Всего по "{processTypeName}"
      </Paragraph>
      {selectedUserTypeIds.map((userTypeId) => {
        const { ndsText, price } = getColumnPriceSum(
          userTypeId,
          processTypeId,
          applicationsGroup,
          form
        );

        return (
          <>
            <BorderLine />
            <PriceSummary price={price} ndsText={ndsText} />
          </>
        );
      })}
    </>
  );
};
