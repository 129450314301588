import React from "react";
import classNames from "classnames";
import { config } from "src/app.cofig";
import style from "../../../../pages/News/style.module.scss";
import { TextLink } from "../../TextLink/TextLink";
import {
  ApiPostProjectType,
  ApiPostType,
} from "src/types/api/responseTypes/apiPostType";
import { getDateWithMonthName } from "src/app/services/date/date";

export const LIBRARY = "Библиотека";
export const PLATFORM = "Новости Платформы";
export const FIRST_FACE = "От первого лица";
export const MEDIA = "ИноСМИ";

export interface PaginationProps {
  pagination: JSX.Element | null;
  meta: ApiPostType["meta"];
}

export interface PostProps {
  imageSource: string | null;
  date: string | null;
  title: string;
  content: string;
  id: number;
}

export interface NewsPageTitleProps {
  boldTitle: string;
  normalTitle: string;
  text: string;
  bigFont?: boolean;
}

export interface MainPostProps {
  imageSource: string | null;
  date: string | null;
  title: string;
  content: string;
  id: number;
}

export interface SwiperProps {
  amount: string;
  imageSource: string | null;
  date: string | null;
  product: string;
  id: number;
}

export interface FirstFaceProps {
  name: string;
  position: string;
  imageSource: string | null;
  title: string;
  date: string | null;
  content: string;
  id: number;
}

export interface NewsPageLayoutProps {
  library: ApiPostType["post"];
  platform: ApiPostType["post"];
  firstFace: ApiPostType["post"];
  media: ApiPostType["post"];
  project: ApiPostProjectType;
}

export interface NewsListLayoutProps {
  posts: ApiPostType["post"];
}

export const imagePlaceholder = "https://i.stack.imgur.com/y9DpT.jpg";

export const getPhoto = (imageURL: string | null): string => {
  return imageURL ? `${config.localDomain}${imageURL}` : imagePlaceholder;
};

export const getField = (
  content: string | undefined,
  allowedLength: number
): string => {
  if (!content) {
    return "";
  }

  return content.length > allowedLength + 2
    ? `${content!.substring(0, allowedLength)}...`
    : content;
};

export const NewsPageTitle: React.FC<NewsPageTitleProps> = ({
  boldTitle,
  normalTitle,
  text,
  bigFont,
}) => {
  return (
    <div className={style.title}>
      <div className={bigFont ? "" : style.bigFontSize}>
        <b>{boldTitle}</b>
        {normalTitle}
      </div>
      <div className={classNames(style.averageFont, style.titleSecondary)}>
        {text}
      </div>
    </div>
  );
};

export const NothingFound = () => {
  return <div className={style.nothingFound}>Новости не найдены</div>;
};

export const MainPost: React.FC<MainPostProps> = ({
  imageSource,
  date,
  title,
  content,
  id,
}) => {
  return (
    <div className={style.mainArticle}>
      <img
        className={style.mainArticleImage}
        draggable="false"
        src={getPhoto(imageSource)}
        alt=""
      />
      <div>
        <p className={style.mainArticleDate}>{getDateWithMonthName(date)}</p>
        <TextLink
          variant={classNames(style.mainArticleLink, style.setHeight)}
          url={`/post/view?id=${id}`}
        >
          {getField(title, 50)}
        </TextLink>
        <h2 className={style.mainArticleText}>{getField(content, 210)}</h2>
      </div>
    </div>
  );
};

export const Post = ({ imageSource, date, title, content, id }: PostProps) => {
  return (
    <div className={style.article}>
      <img
        className={style.articleImage}
        draggable="false"
        src={getPhoto(imageSource)}
        alt=""
      />
      <div className={style.articleBlock}>
        <p className={style.articleDate}>{getDateWithMonthName(date)}</p>
        <TextLink variant={style.articleLink} url={`/post/view?id=${id}`}>
          {getField(title, 50)}
        </TextLink>
        <h2 className={style.articleText}>{getField(content, 75)}</h2>
      </div>
    </div>
  );
};

export const Swiper: React.FC<SwiperProps> = ({
  amount,
  date,
  product,
  id,
  imageSource,
}) => {
  return (
    <div className={style.swiper}>
      <div className={style.decoration}>
        <img
          className={style.decorationImage}
          draggable="false"
          src={imageSource ?? imagePlaceholder}
          alt=""
        />
      </div>
      <div className={style.info}>
        <div className={style.general}>
          <h2 className={style.generalH2}>Тем временем, на Платформе ПИРС</h2>
          <h1 className={style.generalH1}>{getField(product, 90)}</h1>
          <h3 className={style.generalH3}>Время, до / {date}</h3>
        </div>
        <div className={style.secondary}>
          <p className={style.secondaryP}>Опубликовано разделов на сумму</p>
          <h1 className={style.secondaryH1}>{amount}</h1>
          <TextLink url={`${config.localDomain}/project/${id}`} redirect>
            <button className={style.secondaryShow}>Посмотреть</button>
          </TextLink>
        </div>
      </div>
    </div>
  );
};

export const FirstFace: React.FC<FirstFaceProps> = ({
  name,
  imageSource,
  title,
  date,
  content,
  id,
}) => {
  return (
    <div className={style.firstFace}>
      <div className={style.firstFaceBlock}>
        <img
          className={style.firstFaceImage}
          draggable="false"
          src={getPhoto(imageSource)}
          alt=""
        />
      </div>
      <div className={style.firstFaceArticle}>
        <p className={style.firstFaceDate}>{getDateWithMonthName(date)}</p>
        <h3 className={style.firstFaceName}>{name}</h3>
        <h4 className={style.firstFacePosition}>{}</h4>
        <TextLink
          variant={style.firstFaceArticleLink}
          url={`/post/view?id=${id}`}
        >
          {getField(title, 75)}
        </TextLink>
        <h2 className={style.firstFaceArticleText}>{getField(content, 320)}</h2>
      </div>
    </div>
  );
};
