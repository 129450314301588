import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiExpertiseRemarkResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  partGroupId?: number | string;
  projectPartIds?: number | string;
  status?: number | string;
}>;

export const getRemarks = (builder: QueryBuilder) =>
  builder.query<ApiExpertiseRemarkResponses.Remarks, TParams>({
    query: (params) => ({
      url: `v1/expertise/remark/get-remarks`,
      params: {
        processId: params.id,
        "RemarkSearch[partGroupId]": params.partGroupId,
        "RemarkSearch[projectPartIds]": params.projectPartIds,
        "RemarkSearch[status]": params.status,
      },
    }),
    providesTags: [ApiTags.Expertise.GET_REMARKS],
  });
