import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

export const createFormSteps = (
  groupPublishProcess: ApiProjectProcessResponses.GetGroupPublishProcess
): Record<string | number, string> => {
  const partGroups = Object.keys(groupPublishProcess)
    .filter((id) => !Number.isNaN(Number(id)))
    .reduce(
      (result, id) => ({
        ...result,
        [id]: groupPublishProcess[Number(id)]?.title,
      }),
      {}
    );

  return partGroups;
};
