// Стоимость, установленная заказчиком превышена на 2000 Р

import { ExclamationCircle } from "react-bootstrap-icons";
import { Tooltip } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";
import { trimNumber } from "../../../../../../../utils/trimNumber";

type TProps = {
  type: "error" | "warning";
  price: number;
};

export const Exclamation = ({ type, price }: TProps) => {
  const content = {
    error: "Недостаточно средств, зарезервированных в кошельке",
    warning: `Стоимость, установленная заказчиком, превышена на ${trimNumber(price)}р`,
  };

  return (
    <Tooltip color={type} content={content[type]}>
      <ExclamationCircle className={styles.exclamation} />
    </Tooltip>
  );
};
