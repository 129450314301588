import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";
import { trimPrice } from "../../../../../../../utils/trimNumber";

type TProps = {
  price: number;
  ndsText: string;
};

export const PriceSummary = ({ price, ndsText }: TProps) => {
  const formedPrice = trimPrice(price);

  return (
    <Paragraph color="minor" className={styles.priceSummary}>
      {formedPrice} ₽ <span>&nbsp;&nbsp;{ndsText}</span>
    </Paragraph>
  );
};
