import { forwardRef } from "react";
import { useSelectBankAccountByUserType } from "../lib/hooks/useSelectBankAccountByUserType";
import {
  Select,
  SelectProps,
  TSelectItem,
} from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "../../../../../types/generics";

type Props = {
  userType?: Nullable<TSelectItem>;
  setValue?: any;
  bankAccountId?: number;
  isBankAccount: boolean;
} & Omit<SelectProps, "label" | "options">;

export const SelectBankAccountByUserType = forwardRef(
  (
    {
      value,
      userType,
      setValue,
      bankAccountId,
      isBankAccount = true,
      ...props
    }: Props,
    ref
  ) => {
    const { bankAccount, isLoadingBankAccount } =
      useSelectBankAccountByUserType({
        userType,
        setValue,
        bankAccountId,
        isBankAccount,
      });

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={bankAccount}
        isLoading={isLoadingBankAccount}
        label="Банковский счет"
      />
    );
  }
);
