import { useState } from "react";
import { useSelector } from "react-redux";
import { Card, List, Paragraph } from "src/FSD/shared/uiKit/v2";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { trimPrice } from "src/utils/trimNumber";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { NewModal } from "src/components/newUI/NewModal/NewModal";
import { ModalSeismicity } from "./ModalSeismicity/ModalSeismicity";
import styles from "./InfoObject.module.scss";

export const InfoObject = () => {
  const { project } = useSelector(getProjectViewData);
  const containsSeismicity = !!project?.isSeismic;

  const [modalSeismicity, setModalSeismicity] = useState(false);

  const canProjectLinear =
    project?.objectType === 2 ||
    project?.objectType === 5 ||
    project?.objectType === 7;

  const canProjectSquare =
    project?.objectType === 1 ||
    project?.objectType === 4 ||
    project?.objectType === 6;

  /*
  return (
    <Card
      className={styles.object}
      header={<Card.Header>Объект</Card.Header>}
    />
  );
  */

  return (
    <Card className={styles.object} header={<Card.Header>Объекты</Card.Header>}>
      <div className={styles.object_params}>
        <div className={styles.flex}>
          <div className={styles.flexCol}>
            <List gap={2} disableOverflow>
              <Paragraph>Тип:</Paragraph>
              <Paragraph color="light">{project?.contructionType}</Paragraph>
            </List>
            <List gap={2} margin={0} disableOverflow>
              <Paragraph>Регион:</Paragraph>
              <Paragraph color="light">{project?.region}</Paragraph>
            </List>
            <List gap={2} margin={0} disableOverflow>
              <Paragraph>Нас. пункт:</Paragraph>
              <Paragraph color="light">
                {project?.location ? project.location : "(не указан)"}
              </Paragraph>
              {containsSeismicity && (
                <Paragraph color="light">
                  Сейсм. интенсивность: (A - {project?.seismicity?.a}, B -{" "}
                  {project?.seismicity?.b}, C - {project?.seismicity?.c})
                </Paragraph>
              )}
              <Paragraph
                color="warning"
                className={styles.seismicity}
                onClick={() => setModalSeismicity(true)}
              >
                <span>Показать подробную информацию</span>
                <ExclamationTriangleFill color="orange" />
              </Paragraph>
            </List>
            <List gap={2} margin={0} disableOverflow>
              <Paragraph>Назначение:</Paragraph>
              <Paragraph color="light">{project?.appointment}</Paragraph>
            </List>
          </div>
          <div className={styles.count}>
            <h2>Основные параметры</h2>
            {project?.projectParameters.map((el) => (
              <div key={el.id} className={styles.count_block}>
                <p>{el.type}:</p>
                <span>{el.value || "0"}</span>
              </div>
            ))}
            {!!project?.projectLength.length && canProjectSquare && (
              <div className={styles.count_block}>
                <p>Протяженность, пог.м</p>
                <h4>{trimPrice(project?.projectLength[0].value) || "0"}</h4>
              </div>
            )}
            {!!project?.projectSquare.length && canProjectLinear && (
              <div className={styles.count_block}>
                <p>Общая площадь, кв.м</p>
                <h4>{trimPrice(project?.projectSquare[0].value) || "0"}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
      {!!project?.projectLength.length && canProjectLinear && (
        <div className={styles.object_count}>
          <h1>{trimPrice(project?.projectLength[0].value) || "0"}</h1>
          <p>Протяженность, пог.м</p>
        </div>
      )}
      {!!project?.projectSquare.length && canProjectSquare && (
        <div className={styles.object_count}>
          <h1>{trimPrice(project?.projectSquare[0].value) || "0"}</h1>
          <p>Общая площадь, кв.м</p>
        </div>
      )}
      <NewModal
        active={modalSeismicity}
        setActive={setModalSeismicity}
        title={"Сейсмичность по данным ОСР-2016"}
      >
        <ModalSeismicity
          seismicity={project?.seismicity ?? null}
          location={project?.location ?? ""}
          region={project?.region ?? ""}
          closeModal={() => setModalSeismicity(false)}
        />
      </NewModal>
    </Card>
  );
};
