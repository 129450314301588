import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";

export const createGroupPublicationDefaultValues = (
  groupPublishProcess: ApiProjectProcessResponses.GetGroupPublishProcess,
  typeId: number,
  processId: number
) => {
  const { pay1, pay2, pay3 } = groupPublishProcess;
  const { processes, maybeExpertise } = groupPublishProcess[typeId];

  return {
    common: {
      areAllSelected: false,
      dateStart: null,
      dateLimit: null,
      price: null,
    },
    processes: processes.map((process) => {
      return {
        processId: process.id,
        name: `${process.projectPart.code} ${process.projectPart.name}`,
        isActive: process.id === processId,
        dateStart: timestampToDate(process.date_start),
        dateLimit: timestampToDate(process.date_limit),
        price: process.price,
      };
    }),
    maybeExpertise,
    pay1,
    pay2: maybeExpertise ? pay2 : pay2 + pay3,
    pay3,
    paySum: null,
  };
};
