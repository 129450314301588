import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, TextField } from "@mui/material";
import classNames from "classnames";
import CustomCheckbox from "../../../../newUI/CustomCheckbox/CustomCheckbox";
import style from "../style.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import {
  parseUnprocessableFields,
  request,
} from "../../../../../app/services/api/requestHandler";
import { ApiUpdateOrganisation } from "../../../../../app/services/api/organisation/organisation";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../../../app/feature/modalClose/eventModalClose";
import { PencilEditSVG } from "../../../../newUI/SVG";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { weekDaysOptions, weeksCountOptions } from "./constants";
import { Nullable } from "../../../../../types/generics";
import { TSelectItem } from "../../../../features/Forms/SetGipForm/types";

interface SettingsProps {
  data: any;
  access: any;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editingCondition: any;
  handleSetEdit: any;
  isMobile: any;
  sx: any;
}

const SITE_ERROR = "Значение «Сайт поставщика» не является правильным URL.";

const Settings: React.FC<SettingsProps> = ({
  data,
  access,
  editing,
  setEditing,
  editingCondition,
  handleSetEdit,
  isMobile,
  sx,
}) => {
  const dispatch = useDispatch();
  const modalClosed: boolean = useSelector(selectorModalClosed);

  const [isTested, setTested] = useState(data.tested);
  const [isGipBudget, setGipBudget] = useState(data.gipBudget);
  const [canBeFree, setCanBeFree] = useState(data.canBeFree);
  const [isPromote, setIsPromote] = useState(data.promote);
  const [milestone, setMileStone] = useState(data.milestone);

  const defaultWeekDay = weekDaysOptions[data.dayOfWeek];
  const defaultWeeksCount = weeksCountOptions[data.numberOfWeeks - 1];

  const [kanbanWeekDay, setKanbanWeekDay] =
    useState<Nullable<TSelectItem>>(defaultWeekDay);
  const [kanbanWeeksCount, setKanbanWeeksCount] =
    useState<Nullable<TSelectItem>>(defaultWeeksCount);

  const [site, setSite] = useState(data.site);
  const [siteError, setSiteError] = useState("");

  const [disabled, setDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();
  const id = params.id ?? "";

  const handleSubmit = async () => {
    if (siteError) {
      return;
    }

    setDisabled(true);

    const formData = new FormData();

    formData.append("inn", data.inn);
    formData.append("title", data.title);
    formData.append("region_code", data.region_code.id);

    formData.append("tested", `${+isTested}`);
    formData.append("gip_budget", `${+isGipBudget}`);
    formData.append("can_be_free", `${+canBeFree}`);
    formData.append("promote", `${+isPromote}`);
    formData.append("milestone", `${+milestone}`);

    formData.append("day_of_week", String(kanbanWeekDay!.id));
    formData.append("number_of_weeks", String(kanbanWeeksCount!.id));

    if (isPromote) {
      formData.append("site", site);
    }

    await request(
      ApiUpdateOrganisation(id, formData),
      () => {
        enqueueSnackbar("Информация успешно обновлена", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      () => (err) => {
        parseUnprocessableFields(err.message, () => {});

        enqueueSnackbar("При обновлении данных возникли ошибки", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    setEditing(false);
    setDisabled(false);

    dispatch(setModalClosed(!modalClosed));
  };

  const handleStopEditing = () => setEditing(false);

  const validateSite = () => {
    if (!site) {
      return;
    }

    let url;
    let error = false;
    try {
      url = new URL(site);
    } catch (_) {
      error = true;
    }
    if (url && url.protocol !== "http:" && url.protocol !== "https:") {
      error = true;
    }

    setSiteError(error ? SITE_ERROR : "");
  };

  return (
    <div className={style.globalContainer}>
      <div className={style.wrapper}>
        <div className={style.title}>
          <div>Настройка</div>
          {editing ? (
            <div className={style.mode}>Режим редактирования</div>
          ) : (
            access?.edit &&
            editingCondition() &&
            (isMobile ? (
              <IconButton sx={sx} onClick={handleSetEdit}>
                <PencilEditSVG />
              </IconButton>
            ) : (
              <CustomButton
                width={160}
                disabled={editing}
                onClick={handleSetEdit}
                background={palette.blue}
                className={classNames(style.button, style.move)}
              >
                Редактировать
              </CustomButton>
            ))
          )}
        </div>
        <div className={style.tickers}>
          {access?.isAdmin && (
            <>
              <div className={style.ticker}>
                <CustomCheckbox
                  onChange={() =>
                    access?.isAdmin && editing && setTested(!isTested)
                  }
                  checked={editing ? isTested : data.tested}
                  editing={editing}
                />
                <div>Проверенная</div>
              </div>
              <div className={style.ticker}>
                <CustomCheckbox
                  onChange={() =>
                    access?.isAdmin && editing && setCanBeFree(!canBeFree)
                  }
                  checked={editing ? canBeFree : data.canBeFree}
                  editing={editing}
                />
                <div>Работа не по БС</div>
              </div>
            </>
          )}
          <div className={style.ticker}>
            <CustomCheckbox
              onChange={() => editing && setGipBudget(!isGipBudget)}
              checked={editing ? isGipBudget : data.gipBudget}
              editing={editing}
            />
            <div>ГИП работает по бюджету</div>
          </div>
          {access?.isAdmin && (
            <div className={style.ticker}>
              <CustomCheckbox
                onChange={() => editing && setIsPromote(!isPromote)}
                checked={editing ? isPromote : data.promote}
                editing={editing}
              />
              <div>Рекламировать</div>
            </div>
          )}

          <div className={style.ticker}>
            <CustomCheckbox
              onChange={() => editing && setMileStone(!milestone)}
              checked={editing ? milestone : data.milestone}
              editing={editing}
            />
            <div>Контроль исполнения работ по контрольным точкам</div>
          </div>
        </div>
        <div className={style.website}>
          <TextField
            label={"Сайт поставщика"}
            disabled={!editing || !isPromote}
            value={site}
            onBlur={validateSite}
            onChange={(e) => setSite(e.target.value)}
            error={Boolean(siteError)}
            helperText={siteError}
            {...textFieldSX}
          />
        </div>
      </div>
      <div className={style.kanbanSettingsWrapper}>
        <div className={style.kanbanSettings}>
          <h2>Канбан</h2>
          <div className={style.kanbanSettings__field}>
            <p>
              Выберите день недели, с которого будет начинаться отчетная неделя
              на канбан доске
            </p>
            <Select
              value={editing ? kanbanWeekDay : defaultWeekDay}
              changeHandler={(_, value) => {
                setKanbanWeekDay(value);
              }}
              label=""
              options={weekDaysOptions}
              disableClear
              variant={style.kanbanSettings__field__weekDay}
              disabled={!editing}
            />
          </div>
          <div className={style.kanbanSettings__field}>
            <p>Укажите количество недель между оперативными совещаниями</p>
            <Select
              value={editing ? kanbanWeeksCount : defaultWeeksCount}
              changeHandler={(_, value) => {
                setKanbanWeeksCount(value);
              }}
              label=""
              options={weeksCountOptions}
              disableClear
              variant={style.kanbanSettings__field__weeksCount}
              disabled={!editing}
            />
          </div>
        </div>
      </div>
      {editing && (
        <div className={style.formControl}>
          <CustomButton
            type={"submit"}
            background={palette.blue}
            disabled={disabled}
            width={160}
            onClick={handleSubmit}
            className={style.button}
          >
            Сохранить
          </CustomButton>
          <CustomButton
            background={palette.red}
            onClick={handleStopEditing}
            width={160}
            className={style.button}
          >
            Отменить
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default Settings;
