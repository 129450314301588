import { useState } from "react";
import classNames from "classnames";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { IconButton } from "@mui/material";
import classes from "./InfoObject.module.scss";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import { trimPrice } from "../../../../../../utils/trimNumber";
import { ModalWrapper } from "../../../../User/Edit/PersonalDataChange/PersonalDataChange";
import SeismicityInfo from "./modals/SeismicityInfo";

interface InfoObjectProps {
  project: ProjectViewData | null;
}

export const InfoObject: React.FC<InfoObjectProps> = ({ project }) => {
  const [showSeimicityInfo, setShowSeimicityInfo] = useState<boolean>(false);

  const canProjectLinear =
    project?.objectType === 2 ||
    project?.objectType === 5 ||
    project?.objectType === 7;

  const canProjectSquare =
    project?.objectType === 1 ||
    project?.objectType === 4 ||
    project?.objectType === 6;

  const containsSeismicity = Boolean(project?.isSeismic);

  return (
    <div className={classes.object_block1}>
      <div className={classNames(classes.object_obj, classes.basic)}>
        <h1>Объект</h1>
        <div className={classes.object_obj_flex}>
          <p className={classes.subtitle}>Тип</p>
          <h4>{project?.contructionType}</h4>
        </div>
        <div className={classes.object_obj_flex}>
          <p className={classes.subtitle}>Регион</p>
          <h4>{project?.region}</h4>
        </div>
        <div
          className={classNames(
            classes.object_obj_flex,
            containsSeismicity && classes.extraMargin
          )}
        >
          <p className={classes.subtitle}>Населенный пункт</p>
          <h4>
            {project?.location ? project.location : "-"}
            {containsSeismicity && (
              <>
                <br />
                Сейсм. интенсивность
                <br />
                по ОСР-2016: A - {project?.seismicity?.a}, B -&nbsp;
                {project?.seismicity?.b}, C - {project?.seismicity?.c}
              </>
            )}
          </h4>
          <IconButton
            onClick={() => setShowSeimicityInfo(true)}
            className={classes.exclamation}
          >
            <ExclamationTriangleFill color="orange" />
          </IconButton>
        </div>
        <div className={classes.object_obj_flex}>
          <p className={classes.subtitle}>Назначение</p>
          <h4>{project?.appointment}</h4>
        </div>
      </div>
      <div className={classNames(classes.object_optionsWrap, classes.basic)}>
        <h1>Параметры объекта</h1>
        <div className={classes.object_options}>
          <div>
            {project?.projectParameters.map((el) => (
              <div key={el.id} className={classes.object_options_flex}>
                <p>{el.type}</p>
                <h4>{el.value || "0"}</h4>
              </div>
            ))}
            {!!project?.projectLength.length && canProjectSquare && (
              <div className={classes.object_options_flex}>
                <p>Протяженность</p>
                <h4>{trimPrice(project?.projectLength[0].value) || "0"}</h4>
              </div>
            )}
            {!!project?.projectSquare.length && canProjectLinear && (
              <div className={classes.object_options_flex}>
                <p>Общая площадь</p>
                <h4>{trimPrice(project?.projectSquare[0].value) || "0"}</h4>
              </div>
            )}
          </div>
          {!!project?.projectLength.length && canProjectLinear && (
            <div className={classes.square}>
              <h1>{trimPrice(project?.projectLength[0].value) || "0"}</h1>
              <p>Протяженность, пог. м</p>
            </div>
          )}
          {!!project?.projectSquare.length && canProjectSquare && (
            <div className={classes.square}>
              <h1>{trimPrice(project?.projectSquare[0].value) || "0"}</h1>
              <p>Общая площадь, кв. м</p>
            </div>
          )}
        </div>
      </div>
      <ModalWrapper
        show={showSeimicityInfo}
        handleCloseModal={() => setShowSeimicityInfo(false)}
      >
        <SeismicityInfo
          seismicity={project?.seismicity ?? null}
          location={project?.location ?? ""}
          region={project?.region ?? ""}
          handleCloseModal={() => setShowSeimicityInfo(false)}
        />
      </ModalWrapper>
    </div>
  );
};
