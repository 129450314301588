export const getExclamationType = (
  showBankAccountError: boolean,
  processPriceDifference: number
) => {
  if (showBankAccountError) {
    return "error";
  }

  if (processPriceDifference > 0) {
    return "warning";
  }

  return null;
};
