import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { TextLink } from "../../../../../TextLink/TextLink";

export const useInnerWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

type OrganisationProps = any;

const Organisation: React.FC<OrganisationProps> = ({
  price,
  alignRight,
  specialization,
  text,
  workWithBim,
  indicators,
  scalable,
  customers,
  executorSkill,
}) => {
  const [shown, setShown] = useState(false);
  const handleShow = () => setShown(!shown);

  return (
    <div className={style.wrapper}>
      <h1 className={style.header}>
        <p>{customers ? "Организации" : "Специализация"}</p>
        {specialization.length > 0 && alignRight && scalable && (
          <button onClick={handleShow}>
            {shown ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-down"
                viewBox="0 0 16 16"
              >
                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
              </svg>
            )}
          </button>
        )}
      </h1>
      <ol>
        {specialization.length > 0 ? (
          specialization.map((item: any, i: number) => {
            return customers ? (
              <React.Fragment key={i}>
                <TextLink
                  key={i}
                  variant={style.organisationLink}
                  url={`/firm/${item.id}`}
                >
                  {item.title}
                </TextLink>
                <br />
              </React.Fragment>
            ) : (
              <li
                style={{
                  fontWeight:
                    executorSkill && item?.key === executorSkill
                      ? "bold"
                      : "normal",
                }}
                key={i}
              >
                {item?.name}
              </li>
            );
          })
        ) : (
          <li>Ничего не найдено</li>
        )}
      </ol>
      <div
        className={classNames(
          style.labels,
          alignRight && style.alignRight,
          price.number && style.labelsMobile
        )}
      >
        {indicators.map((item: any, i: number) => (
          <div key={i}>
            <TextLink variant={style.link} url={`${item.link}`}>
              {item.value}
            </TextLink>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
      <div className={style.price}>
        {price.number && (
          <>
            <p>{price.text}</p>
            <h1>{price.number}</h1>
          </>
        )}
        {workWithBim && <h2>BIM</h2>}
        {text && <p className={style.text}>{text}</p>}
      </div>
    </div>
  );
};

export default Organisation;
