import React, { ChangeEvent, FC, memo } from "react";
import {
  IconButton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import style from "./style.module.scss";
import { searchFieldSX } from "../../../../styles/restyle";

interface SearchFieldProps {
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  pending?: boolean;
  handleSubmit?: any;
  mobile?: boolean;
}

const SearchField: FC<SearchFieldProps> = ({
  value,
  onChange,
  className,
  pending = false,
  handleSubmit = () => {},
  mobile = false,
}) => {
  if (mobile) {
    return (
      <TextField
        inputRef={(input) => input && input.focus({ preventScroll: true })}
        variant="filled"
        value={value}
        onChange={onChange}
        className={className}
        {...searchFieldSX}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {Boolean(pending) && (
                <CircularProgress className={style.progress} />
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <TextField
      type="text"
      value={value}
      onChange={onChange}
      className={className}
      {...searchFieldSX}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {pending ? (
              <CircularProgress className={style.progress} />
            ) : (
              <IconButton onClick={handleSubmit}>
                <Search style={{ color: "#9DAFBD" }} />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(SearchField);
