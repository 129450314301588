export type EventEmitterEventType = Record<
  string,
  {
    isShow: boolean;
    data: any;
    success?: boolean;
    error?: {
      message: string;
    };
  }
>;

const defaultEEData = {
  isShow: false,
  data: {},
};

export const eventEmitterInitialState: EventEmitterEventType = {
  eventPublishTask: {
    isShow: false,
    data: {
      heading: "",
      dateFrom: null,
      dateTo: null,
      price: 0,
    },
  },
  eventUnPublishTask: {
    isShow: false,
    data: {
      taskId: 0,
    },
  },
  eventSetExecutor: defaultEEData,
  eventRequestRemoveGip: defaultEEData,
  eventSetGipProject: defaultEEData,
  eventRequestGipProject: defaultEEData,
  eventCloseConflictDeleteGip: defaultEEData,
  eventRevokeRequestGip: defaultEEData,
  eventRemoveRequestSearchGip: defaultEEData,
  eventSearchProjectGip: defaultEEData,
  eventCompareGip: defaultEEData,
  eventDeleteProject: defaultEEData,
  eventJoinUserFirm: defaultEEData,
  eventJoinUserProject: defaultEEData,
  eventManageTeammate: defaultEEData,
  eventShowUserAgreement: defaultEEData,
  eventShowUserPolicy: defaultEEData,
  eventChangeUserRole: defaultEEData,
  eventEditOrganisationTask: defaultEEData,
  eventEditBankAccount: defaultEEData,
  eventSendMoney: defaultEEData,
  eventUserJoin: defaultEEData,
  eventUserJoinAtUserPage: defaultEEData,
  eventUserInviteToTeam: defaultEEData,
  eventEditRequestJoin: defaultEEData,
  eventCreateTeam: defaultEEData,
  eventViewProject: defaultEEData,
  error: {
    isShow: false,
    data: {
      message: "",
    },
  },
};
