import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { selectorAppTheme } from "src/app/feature/app/app";
import { request } from "src/app/services/api/requestHandler";
import { ApiUpdatePost } from "src/app/services/api/posts/posts";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import style from "./style.module.scss";
import { TagsMultiple, TagsTypeItem } from "../../SpecialForm/TagsMultiple";
import { CKEditorWrapper } from "../../CKEditorWrapper/CKEditorWrapper";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { PostEditFormProps } from "./types";
import { textFieldSX } from "src/styles/restyle";
import { TPostCreateForm } from "../PostCreateForm/PostCreateForm";
import { PostCreateFormSchema } from "../PostCreateForm/yupSchemas";

export const PostEditForm: React.FC<PostEditFormProps> = ({
  variant = "",
  imageHandler = () => {},
  post,
}) => {
  const theme = useSelector(selectorAppTheme);
  const navigate = useNavigate();
  const [isLoadImage, setIsLoadImage] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { errors },
  } = useForm<TPostCreateForm>({
    resolver: yupResolver(PostCreateFormSchema),
    defaultValues: {
      image: "",
      title: "",
      linkSource: "",
      tags: [] as TagsTypeItem[],
      published: false,
      content: null,
    },
    mode: "onBlur",
  });

  const onSubmit = (data: TPostCreateForm) => {
    const formData = new FormData();

    if (isLoadImage) {
      formData.append("photo_upload", data.image);
    }

    formData.append("title", data.title);
    formData.append("url", data.linkSource);
    formData.append("published", `${Number(data.published)}`);

    formData.append("content", data.content as string);

    for (const item of data.tags) {
      formData.append(`tag_ids[${item["id"]}]`, item["id"]);
    }

    request(
      ApiUpdatePost(post.id, formData),
      (result, headersData, headers, status) => {
        if (status === 200) {
          navigate(`/post/view?id=${result}`);
        }
      }
    )();
  };

  useEffect(() => {
    if (post.photoUrl) {
      imageHandler(post.photoUrl);
    }
  }, [post.photoUrl]);

  useEffect(() => {
    if (post.title) {
      setValue("title", post.title);
    }

    if (post.url) {
      setValue("linkSource", post.url);
    }

    if (post.content) {
      // setValue("content", post.content);
      setValue("content", post.content);
    }

    if (post.date_publication) {
      setValue("published", true);
    }

    if (post.tags) {
      setValue("tags", post.tags);
    }
  }, [post.title, post.url, post.content, post.tags, post.date_publication]);

  const handleFile = (e: any) => {
    const content = e.target.result;
    imageHandler(content);
  };

  const handleChangeFile = (file: any) => {
    const fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsDataURL(file);
  };

  const watchPublished = watch("published");
  const watchTags = watch("tags");

  const TitleError = Boolean(errors.title) && (
    <ErrorMessage
      variant={style.postInputError}
      message={errors.title?.message}
    />
  );

  const ContentError = Boolean(errors.content) && (
    <ErrorMessage message={errors.content?.message} />
  );

  const TagsError = Boolean(errors.tags) && (
    <ErrorMessage
      variant={style.postInputError}
      message={errors.tags?.message}
    />
  );

  return (
    <form
      className={variant}
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classNames("inputWrapper")}>
        <input
          id="file"
          name="file"
          type="file"
          onChange={(e) => {
            if (e.target.files !== null) {
              setValue("image", e.target.files[0]);
              handleChangeFile(e.target.files[0]);
              setIsLoadImage(true);
            }
          }}
        />
      </div>
      <div className={classNames("inputWrapper")}>
        <TextField
          {...register("title")}
          id="title"
          name="title"
          label={"Название"}
          variant="outlined"
          size="small"
          autoComplete="off"
          fullWidth
          error={Boolean(errors.title?.message)}
          helperText={errors.title?.message}
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
        {TitleError}
      </div>
      <div className={classNames("inputWrapper")}>
        <TextField
          {...register("linkSource")}
          id="linkSource"
          name="linkSource"
          label={"Ссылка на источник"}
          helperText={errors.linkSource?.message}
          variant="outlined"
          size="small"
          autoComplete="off"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldSX}
        />
      </div>
      <div className={classNames("inputWrapper", style.tags)}>
        <TagsMultiple
          changeHandler={(data) => {
            // @ts-ignore
            setValue("tags", data);
            trigger("tags");
          }}
          error={Boolean(errors.tags)}
          value={watchTags}
        />
        {TagsError}
      </div>
      <div className={classNames("inputWrapper")}>
        <Controller
          control={control}
          name="content"
          render={({ field: { value } }) => {
            return (
              <>
                {value !== null && (
                  <>
                    <CKEditorWrapper
                      changeHandler={(data: string) => {
                        setValue("content", data);
                      }}
                      data={value}
                    />
                    {ContentError}
                  </>
                )}
              </>
            );
          }}
        />
      </div>
      <div className="publishedWrapper">
        <FormControlLabel
          control={
            <Checkbox
              checked={watchPublished}
              onChange={(_, value) => {
                setValue("published", value);
              }}
              name="published"
              color="primary"
            />
          }
          label="Опубликованно"
        />
      </div>
      <div>
        <Button theme={theme} type="submit" color={"blue"}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};
