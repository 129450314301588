import { useState, FC, useEffect, SetStateAction, Dispatch } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { TextField, IconButton } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { Phone } from "../../../../features/SpecialForm/Phone/Phone";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";
import {
  ListCheckedSVG,
  PencilEditSVG,
  PencilSquareSVG,
} from "../../../../newUI/SVG";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { palette, textFieldSX } from "src/styles/restyle";
import AdvancedAddressField from "../../../User/Edit/Info/AdvancedAddressField/AdvancedAddressField";
import { schema, advancedSchema } from "./schemas";
import {
  selectorOrganisationPageContentData,
  setOrganisationPageContentData,
} from "src/app/feature/organisationPage/organisationPage";
import { CountriesType } from "../../../../features/SpecialForm/Country/Country";
import { Regions } from "../../../../features/SpecialForm/Country/RegionsOfTheCountry";
import { prepareForm, preCheck } from "./utils";
import { TAnyObject } from "./types";
import useOrganisationForm, { TDefaultValues } from "./useOrganisationForm";
import useAttorneyFile from "./useAttorneyFile";
import useImage from "./useImage";
import useAttorneyForm from "./useAttorneyForm";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import {
  request,
  parseUnprocessableFields,
  getErrorMessage,
} from "src/app/services/api/requestHandler";
import {
  ApiUpdateOrganisation,
  ApiCreateOrganisation,
} from "src/app/services/api/organisation/organisation";
import { setInboundForm } from "src/app/feature/unsaved/unsavedController";

import { getOrganisation, getOrganisationStaff } from "../../requests";
import { getUserId } from "src/app/services/auth/auth";
import {
  OrganisationPageStateType,
  OrganisationResponseType,
} from "src/types/stateTypes/organisationType";
import {
  selectorModalClosed,
  setModalClosed,
} from "src/app/feature/modalClose/eventModalClose";
import { TStaff } from "src/app/feature/organisationPage/initialState";
import useUnsavedControl from "../../../../hooks/useUnsavedControl";
import { ImagePlaceholders } from "src/FSD/shared/lib/constants/imagePlaceholders";

type TProps = {
  firmData: OrganisationPageStateType | null;
  ndsList: TAnyObject[];
  isNew?: boolean;
  editingCondition: () => boolean;
  handleRequestTestedFirm: () => void;
  unsaved: boolean;
  setUnsaved: Dispatch<SetStateAction<boolean>>;
};

const OrganisationForm: FC<TProps> = ({
  ndsList,
  isNew,
  editingCondition,
  handleRequestTestedFirm,
  setUnsaved,
  unsaved,
  firmData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = getUserId();

  const data = firmData?.info || null;
  const access = firmData?.can;

  const [disabled, setDisabled] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(Boolean(isNew));
  const [validationSchema, setValidationSchema] =
    useState<Yup.ObjectSchema>(advancedSchema);

  const isMobile = useIsMobile();
  const modalClosed = useSelector(selectorModalClosed);

  const { enqueueSnackbar } = useSnackbar();
  const { defaultValues } = useOrganisationForm(data, isNew);

  const canEdit = access?.edit && editingCondition();
  const isIp = defaultValues.inn.length === 12;

  let info: OrganisationResponseType;
  let staff: OrganisationResponseType;

  const handleSynchronize = async () => {
    setDisabled(true);
    try {
      info = await getOrganisation("sync", defaultValues, "");
      staff = await getOrganisationStaff(defaultValues, "");

      enqueueSnackbar("Синхронизация с ЕГРЮЛ/ЕГРИП прошла успешно", {
        variant: "success",
        autoHideDuration: 5000,
      });
    } catch (e) {
      enqueueSnackbar("Ошибка в синхронизации", {
        variant: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setDisabled(false);
      dispatch(
        setOrganisationPageContentData({
          ...firmData,
          info: info.data,
          staff: staff.data,
          staffMeta: staff.meta,
        })
      );
    }
  };

  const handleStartEditing = () => {
    setEditing(true);
  };

  const staffList = useSelector(selectorOrganisationPageContentData).staff.map(
    (item: TStaff) => ({
      id: item.user_id,
      title: item.name,
    })
  );

  const {
    register,
    trigger,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields },
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues,
  });

  const watchFio = watch("fio");
  const watchNds = watch("nds");
  const watchDocType = watch("doc_type");
  const watchRegion = watch("region");
  const watchCountry = watch("country");
  const watchPhone = watch("phone");

  const watchDocDateReg = watch("doc_date_reg");
  const watchDocDateStart = watch("doc_date_start");
  const watchDocDateEnd = watch("doc_date_end");

  const {
    fileForUploading,
    attorneyFile,
    attorneyFileError,
    handleAttorneyFileChange,
    clearAttorneyFile,
    setAttorneyFileError,
  } = useAttorneyFile(data?.firmAttorney.file);

  const { showAttorneyForm, isAttorneyFormOk, docTypes, ATTORNEY } =
    useAttorneyForm(
      isIp,
      watchDocType,
      watchDocDateReg,
      watchDocDateStart,
      watchDocDateEnd,
      attorneyFile
    );

  const { photoUrl, photoFile, clearImage, handleImageUpload } = useImage(
    defaultValues.photo,
    editing
  );

  const controlledData = {
    photoFile,
    watchFio,
    watchNds,
    watchDocType,
    watchRegion,
    watchCountry,
    watchPhone,
    watchDocDateReg,
    watchDocDateStart,
    watchDocDateEnd,
  };

  const controlledDataArray = [
    photoFile,
    watchFio,
    watchNds,
    watchDocType,
    watchRegion,
    watchCountry,
    watchPhone,
    watchDocDateReg,
    watchDocDateStart,
    watchDocDateEnd,
  ];

  useUnsavedControl(controlledDataArray);

  useEffect(() => {
    const dirtyFieldsCount = Object.keys(dirtyFields).length;
    setUnsaved(
      Boolean(
        dirtyFieldsCount ||
          Boolean(data?.firmAttorney.file) !== fileForUploading
      )
    );
  }, [dirtyFields, fileForUploading]);

  const onSubmit = async (data: TDefaultValues) => {
    if (
      !preCheck(
        isIp,
        watchDocType.id,
        showAttorneyForm,
        fileForUploading,
        setAttorneyFileError,
        attorneyFileError
      )
    ) {
      return;
    }

    if (!data.doc_num && data.doc_type.id === 2) {
      setValue("doc_num", "б/н");
    }

    setDisabled(true);

    const formData = prepareForm(
      defaultValues,
      { ...data, fio: watchFio },
      photoFile,
      fileForUploading
    );

    let error;

    const processErrors = (err: Record<string, any>) => {
      parseUnprocessableFields(err.message, setError);
      error = getErrorMessage(err);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000,
      });
    };

    if (isNew) {
      formData.delete("boss_id");
      formData.append("boss_id", `${userId}`);

      await request(
        ApiCreateOrganisation(formData),
        (data) => {
          enqueueSnackbar("Информация успешно сохранена", {
            variant: "success",
            autoHideDuration: 5000,
          });

          dispatch(setInboundForm(controlledData));

          setUnsaved(false);
          navigate(`/firm/${data.id}`);
        },
        () => (err) => {
          processErrors(err);
        }
      )();
    } else {
      await request(
        ApiUpdateOrganisation(defaultValues.id, formData),
        () => {
          enqueueSnackbar("Информация успешно обновлена", {
            variant: "success",
            autoHideDuration: 5000,
          });

          dispatch(setModalClosed(!modalClosed));
          dispatch(setInboundForm(controlledData));

          setUnsaved(false);
        },
        () => (err) => {
          processErrors(err);
        }
      )();
    }

    setDisabled(false);
    if (!error) {
      setEditing(false);
    }
  };

  const handleStopEditing = () => {
    reset();
    clearImage();
    clearAttorneyFile();

    setUnsaved(false);
    setEditing(false);
  };

  useEffect(() => {
    setValidationSchema(showAttorneyForm ? advancedSchema : schema);
  }, [showAttorneyForm]);

  const editingButton = isMobile ? (
    <IconButton onClick={handleStartEditing}>
      <PencilEditSVG />
    </IconButton>
  ) : (
    <CustomButton
      width={160}
      disabled={editing}
      onClick={handleStartEditing}
      background={palette.blue}
      className={style.button}
    >
      Редактировать
    </CustomButton>
  );

  const requestTestedFirmButton = isMobile ? (
    <IconButton onClick={handleRequestTestedFirm}>
      <ListCheckedSVG />
    </IconButton>
  ) : (
    <CustomButton
      width={210}
      onClick={handleRequestTestedFirm}
      background={palette.green}
      className={style.button}
    >
      Подтвердить данные
    </CustomButton>
  );

  const firmControls = editing ? (
    <div>Режим редактирования</div>
  ) : (
    <div className={style.confirmData}>
      {access?.requestTestedFirm && requestTestedFirmButton}
      {canEdit && editingButton}
    </div>
  );

  const imageUploader = (
    <label className={style.uploader}>
      <img
        className={classNames(
          style.imageUploader,
          editing && style.imageCursor
        )}
        src={photoUrl || ImagePlaceholders.BASIC}
        alt=""
      />
      {editing && <div>Выбрать</div>}
      <input
        disabled={!isNew && !editing}
        type="file"
        onChange={handleImageUpload}
        className={style.input}
        accept="image/*"
      />
    </label>
  );

  const saveButton = (
    <CustomButton
      type={"submit"}
      background={palette.blue}
      width={160}
      disabled={disabled}
      className={style.button}
    >
      Сохранить
    </CustomButton>
  );

  const dismissButton = !isNew && (
    <CustomButton
      background={palette.red}
      width={160}
      onClick={handleStopEditing}
      className={style.button}
    >
      Отменить
    </CustomButton>
  );

  const synchronizeButton = (
    <CustomButton
      background={palette.blue}
      width={280}
      disabled={disabled}
      onClick={handleSynchronize}
      className={style.button}
    >
      Синхронизация с ЕГРЮЛ/ЕГРИП
    </CustomButton>
  );

  const titleFull = (
    <Controller
      name={"title_full"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"Полное наименование организации"}
          {...textFieldSX}
          {...field}
          disabled={!isNew}
        />
      )}
    />
  );

  const title = (
    <Controller
      name={"title"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"Краткое наименование организации"}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.title)}
          helperText={errors.title?.message}
          disabled={!isNew}
        />
      )}
    />
  );

  const inn = (
    <Controller
      name={"inn"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"ИНН"}
          inputProps={{ maxLength: 12 }}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.inn)}
          helperText={errors.inn?.message}
          disabled={!isNew}
        />
      )}
    />
  );

  const ogrn = (
    <Controller
      name={"ogrn"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"ОГРН"}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.ogrn)}
          helperText={errors.ogrn?.message}
          disabled={!isNew}
        />
      )}
    />
  );

  const kpp = (
    <Controller
      name={"kpp"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"КПП"}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.kpp)}
          helperText={errors.kpp?.message}
          disabled={!isNew || isIp}
        />
      )}
    />
  );

  const city = (
    <Controller
      name={"city"}
      control={control}
      render={({ field }) => (
        <TextField
          InputLabelProps={{ shrink: true }}
          label={"Город"}
          {...textFieldSX}
          {...field}
          disabled={!isNew}
        />
      )}
    />
  );

  const address = (
    <Controller
      name={"address"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"Юридический адрес"}
          {...textFieldSX}
          {...field}
          disabled={!isNew}
        />
      )}
    />
  );

  const managerPost = (
    <Controller
      name={"manager_post"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"Должность из ЕГРЮЛ/ЕГРИП"}
          {...textFieldSX}
          {...field}
          disabled={!isNew}
        />
      )}
    />
  );

  const managerName = (
    <Controller
      name={"manager_name"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"ФИО руководителя из ЕГРЮЛ/ЕГРИП"}
          {...textFieldSX}
          {...field}
          disabled={!isNew}
        />
      )}
    />
  );

  const email = (
    <Controller
      name={"email"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"E-mail"}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          disabled={!isNew && !editing}
        />
      )}
    />
  );

  const bossProf = (
    <Controller
      name={"boss_prof"}
      control={control}
      render={({ field }) => (
        <TextField
          label={"Должность доверенного лица"}
          {...textFieldSX}
          {...field}
          disabled={!isNew && !editing}
        />
      )}
    />
  );

  const fio = (
    <Select
      value={watchFio}
      changeHandler={async (_, value) => {
        setValue("fio", value);
      }}
      options={staffList}
      variant={style.select}
      disableClear
      label={"ФИО доверенного лица"}
      size={"small"}
      editing={editing}
      disabled={!isNew && !editing}
      name={"fio"}
    />
  );

  const nds = (
    <Select
      value={watchNds}
      changeHandler={(_, value) => {
        setValue("nds", value);
      }}
      label={"Вид налогообложения"}
      name={"nds"}
      options={ndsList}
      variant={style.select}
      disableClear
      size="small"
      disabled={!isNew && !editing}
      editing={editing}
    />
  );

  const docType = (
    <Select
      value={watchDocType}
      changeHandler={(_, value) => {
        setValue("doc_type", value);
      }}
      label={"Документ-основание"}
      name={"doc_type"}
      options={docTypes}
      variant={style.select}
      disableClear
      size="small"
      disabled={!isNew && !editing}
      editing={editing}
    />
  );

  const regionCode = (
    <Regions
      id="region_code"
      name="region_code"
      label="Регион"
      value={watchRegion}
      countryCode={watchCountry}
      disabled={!isNew}
      blurHandler={async () => {
        await trigger("region");
      }}
      changeHandler={async (data: any) => {
        setValue("region", data.id);
        await trigger("region");
      }}
      error={Boolean(errors.region_code)}
      helperText={String(errors.region_code?.message || "")}
    />
  );

  const country = (
    <CountriesType
      variant={style.countries}
      disabled={!isNew}
      changeHandler={async (data) => {
        setValue("country", data.id);
        setValue("region_code", 0);

        await trigger("country");
        await trigger("region_code");
      }}
      value={watchCountry}
      label="Страна"
    />
  );

  const addressFact = (
    <AdvancedAddressField
      name={"address_fact"}
      defaultValue={defaultValues.address_fact}
      register={register}
      label={"Фактический адрес"}
      handler={(option) => {
        setValue("address_fact", option.value);

        if (!unsaved) {
          setUnsaved(true);
        }
      }}
      changeHandler={(value) => {
        setValue("address_fact", value);

        if (!unsaved) {
          setUnsaved(true);
        }
      }}
      setValue={setValue}
      disabled={!isNew && !editing}
    />
  );

  const phone = (
    <Phone
      changeHandler={(data) => {
        setValue("phone", data);
      }}
      value={watchPhone}
      error={Boolean(errors.phone)}
      errorText={String(errors.phone?.message || "")}
      disabled={!isNew && !editing}
      errorStyles={style.errorText}
    />
  );

  const attorneyTitle = (
    <div className={style.title}>
      <div>Доверенность</div>
      <p className={style.marker}>
        {isAttorneyFormOk ? ATTORNEY.IN_WORK : ATTORNEY.ABSCENT}
        <div className={classNames(style.plot, isAttorneyFormOk && style.ok)} />
      </p>
    </div>
  );

  const docNum = (
    <Controller
      name={"doc_num"}
      control={control}
      render={({ field }) => (
        <TextField
          inputProps={{ maxLength: 20 }}
          label={"Номер доверенности"}
          {...textFieldSX}
          {...field}
          error={Boolean(errors.doc_num)}
          helperText={errors.doc_num?.message}
          disabled={!isNew && !editing}
        />
      )}
    />
  );

  const docDateReg = (
    <DateField
      label="Дата подписи доверенности"
      startDateProp={watchDocDateReg}
      changeDateHandler={async (data) => {
        setValue("doc_date_reg", data);
        await trigger("doc_date_reg");
      }}
      error={Boolean(errors.doc_date_reg)}
      helperText={String(errors.doc_date_reg?.message || "")}
      disabled={!isNew && !editing}
    />
  );

  const docDateStart = (
    <DateField
      label="Дата начала действия"
      startDateProp={watchDocDateStart}
      error={Boolean(errors.doc_date_start)}
      helperText={String(errors.doc_date_start?.message || "")}
      changeDateHandler={async (data) => {
        setValue("doc_date_start", data);
        await trigger("doc_date_start");
      }}
      disabled={!isNew && !editing}
    />
  );

  const docDateEnd = (
    <DateField
      label="Дата окончания действия"
      startDateProp={watchDocDateEnd}
      error={Boolean(errors.doc_date_end)}
      helperText={String(errors.doc_date_end?.message || "")}
      changeDateHandler={async (data) => {
        setValue("doc_date_end", data);
        await trigger("doc_date_end");
      }}
      disabled={!isNew && !editing}
    />
  );

  const attorneyFileLink = (
    <>
      {attorneyFile.url ? (
        <TextLink
          redirect
          target="_blank"
          variant={style.textLink}
          download
          url={attorneyFile.url}
        >
          {attorneyFile.name}
        </TextLink>
      ) : (
        <p>Файл отсутствует</p>
      )}
      {editing && (
        <label>
          <PencilSquareSVG />
          <input
            onChange={handleAttorneyFileChange}
            type="file"
            accept={"image/jpeg, image/jpg, image/png, application/pdf"}
          />
        </label>
      )}
    </>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
      <div className={style.wrapper}>
        <div className={style.title}>
          <div>Карта организации</div>
          {!isNew && firmControls}
        </div>
        {imageUploader}
        <div
          className={classNames(
            style.upperFields,
            !editing && style.fieldsDisabled
          )}
        >
          {titleFull}
          {title}
        </div>
        <div className={style.middleFields}>
          {inn}
          {ogrn}
          {kpp}
          {country}
          {regionCode}
          {city}
        </div>
        <div className={style.lowerFields}>
          {address}
          {addressFact}
          {managerPost}
          {managerName}
          {docType}
          {nds}
          {phone}
          {email}
          {bossProf}
          {fio}
        </div>
      </div>
      {showAttorneyForm && (
        <div className={style.wrapper}>
          {attorneyTitle}
          <div className={style.lowerFields}>
            {docNum}
            {docDateReg}
            {docDateStart}
            {docDateEnd}
          </div>
          <div className={style.file}>{attorneyFileLink}</div>
          {attorneyFileError.required && (
            <div className={style.error}>Необходимо приложить файл</div>
          )}
          {attorneyFileError.size && (
            <div className={style.error}>
              Размер файла не должен превышать 5 Мб
            </div>
          )}
          <br />
        </div>
      )}
      {editing && (
        <div className={style.formControl}>
          {saveButton}
          {dismissButton}
          {access?.isAdmin && synchronizeButton}
        </div>
      )}
    </form>
  );
};

export default OrganisationForm;
