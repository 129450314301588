import React, { memo } from "react";
import { RemarkRowsValue } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import { TableCardBody } from "./TableCardBody/TableCardBody";
import cls from "./TableCard.module.scss";

type TableCardProps = {
  header: string;
  rows: Record<string, RemarkRowsValue>;
};

export const TableCard = memo(({ header, rows }: TableCardProps) => {
  const rowsArray = Object.values(rows);
  return (
    <>
      <tr>
        <td colSpan={6} className={cls.header}>
          {header}
        </td>
      </tr>
      {rowsArray?.map((el) => <TableCardBody key={el.row.id} data={el} />)}
    </>
  );
});
