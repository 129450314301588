import { Paragraph, TextLink } from "src/FSD/shared/uiKit/v2";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { TextItemMaterial } from "../../../std/Materials/ui/TextItemMaterial/TextItemMaterial";
import styles from "./RequestEquipmentForm.module.scss";

type Props = {
  taskData: ApiTaskResponses.View;
};

export const Task = ({ taskData }: Props) => {
  const { name, comment, id } = taskData.myMaterialProcess;
  const files = taskData.files.slice(0, 5);
  const filesSize = taskData.filesSize
    ? `(${taskData.filesSize.join("")})`
    : "";

  return (
    <div className={styles.wrapper}>
      <Paragraph fontSize={18} color="light">
        Входящий запрос на оборудование
      </Paragraph>
      <div className={styles.container}>
        <TextItemMaterial
          title="Наименование"
          color="default"
          className={styles.container__materialInfo}
        >
          <Paragraph color="light">{name}</Paragraph>
        </TextItemMaterial>
        <TextItemMaterial
          title="Комментарий"
          color="default"
          className={styles.container__materialInfo}
        >
          <Paragraph color="light">{comment}</Paragraph>
        </TextItemMaterial>
        {!!files.length && (
          <div>
            <FilesList
              label="Опросный лист"
              fileLinks={files}
              classNameFilesList={styles.filesListMaterial}
              classNameTextLink={styles.filesListMaterial__textLink}
            />
            {files.length > 1 && (
              <TextLink
                title="Скачать все"
                redirect
                target="_blank"
                url={`${config.localDomain}/material-process/download-files?id=${id}`}
              >
                Скачать все {filesSize}
              </TextLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
