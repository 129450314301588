import { IconButton } from "@mui/material";
import { useContext } from "react";
import { X } from "react-bootstrap-icons";
import { ApplicationsContext } from "../Applications";
import { getTotalSum } from "../../lib/helpers/getTotalSum";
import { removeUserTypeId } from "../../lib/helpers/removeUserType";

type TProps = {
  userTypeId: number;
};

export const RemoveExecutorButton = ({ userTypeId }: TProps) => {
  const {
    selectedUserTypeIds,
    setSelectedUserTypeIds,
    setGroupId,
    setComparison,
    setTotalPrice,
    groupId,
    applications,
    form,
  } = useContext(ApplicationsContext);

  const { getValues, setValue } = form;

  const handleRemoveExecutor = () => {
    const newSelectedExecutorIds = selectedUserTypeIds.filter(
      (id) => id !== userTypeId
    );

    setSelectedUserTypeIds(newSelectedExecutorIds);

    const formValues = getValues();

    removeUserTypeId(formValues, userTypeId, setValue);

    const newTotalPrice = getTotalSum(groupId!, applications, form);
    setTotalPrice(newTotalPrice);

    if (!newSelectedExecutorIds.length) {
      setGroupId(null);
      setComparison(false);
    }
  };

  return (
    <div>
      <IconButton onClick={handleRemoveExecutor}>
        <X color="white" />
      </IconButton>
    </div>
  );
};
