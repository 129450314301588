import cn from "classnames";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Pie } from "src/FSD/shared/uiKit/v2/Pie";
import { Popper } from "src/FSD/shared/uiKit/v2/Popper";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { usePayment } from "../../lib/hooks/usePayment";
import styles from "./Payment.module.scss";
import { Price } from "./Price";

interface Props {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
}

export const Payment = ({ publishedObject, type }: Props) => {
  const {
    pieChartDataSet,
    ndsText,
    isPriceUpdating,
    canEditPrice,
    handleChangePrice,
    isMobile,
  } = usePayment({ publishedObject, type });

  const PriceNode = (
    <Price
      price={publishedObject.price}
      className={cn(
        styles.price_textEdit,
        !canEditPrice && styles.price_textNoEdit,
        !publishedObject.price && styles.noPrice
      )}
      isProcess
    />
  );

  return (
    <Card
      className={styles.container}
      header={<Card.Header>Стоимость и условия оплаты</Card.Header>}
      controls={
        <Card.Controls location="top">
          {!isMobile && publishedObject.projectPart.published && (
            <Popper.TextField
              isLoading={isPriceUpdating}
              node={PriceNode}
              value={publishedObject.price}
              title="Стоимость"
              canEdit={canEditPrice}
              onChange={handleChangePrice}
            />
          )}
        </Card.Controls>
      }
    >
      <div>
        {isMobile && publishedObject.projectPart.published && (
          <Popper.TextField
            isLoading={isPriceUpdating}
            node={PriceNode}
            value={publishedObject.price}
            title="Стоимость"
            canEdit={canEditPrice}
            onChange={handleChangePrice}
          />
        )}
        {publishedObject.with_docs && (
          <div className={styles.payParts}>
            <Pie.Chart
              size={"110px"}
              strokeWidth={8}
              dataSet={pieChartDataSet}
            />
            <div className={styles.payPartsText}>
              {Boolean(publishedObject.pay1) && (
                <Pie.Legend
                  price={publishedObject.price}
                  payPercent={publishedObject.pay1}
                  title="Аванс"
                  payClass={cn(styles.pay1, styles.pay1Color)}
                />
              )}
              {Boolean(publishedObject.pay2) && (
                <Pie.Legend
                  price={publishedObject.price}
                  payPercent={publishedObject.pay2}
                  title="После приёмки документации"
                  payClass={cn(styles.pay2, styles.pay2Color)}
                />
              )}
              {Boolean(publishedObject.pay3) && (
                <Pie.Legend
                  price={publishedObject.price}
                  payPercent={publishedObject.pay3}
                  title="После экспертизы"
                  payClass={cn(styles.pay3, styles.pay3Color)}
                />
              )}
            </div>
          </div>
        )}
        {publishedObject.secure && (
          <div className={styles.payInfo}>
            <div className={styles.payInfo__line}>
              <span className={styles.payInfo__line__label}>Доступно:</span>
              <span className={styles.payInfo__line__value}>
                {publishedObject.account?.value ?? "0.00"} ₽
              </span>
              <span>{ndsText}</span>
            </div>
            <div className={styles.payInfo__line}>
              <span className={styles.payInfo__line__label}>Выплачено:</span>
              <span className={styles.payInfo__line__value}>
                {publishedObject.paid_out} ₽
              </span>
              <span>{ndsText}</span>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
