import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalContext } from "src/FSD/shared/uiKit/v2";
import { useMaterialProcessSelectRequestMutation } from "src/FSD/entities/materialProcess/api";
import { useAppDispatch } from "../../../../../../../../app/store";
import { pushSuccessMessage } from "../../../../../../../../app/feature/errorTrace";
import { materialProcessSelectRequestSchema } from "../schemas/materialProcessSelectRequest";

type TFiledValues = {
  comment: string;
};

type TUseModal = {
  taskId: number;
  materialId: number;
  defaultValues: TFiledValues;
};

export const useMaterialProcessSelectRequest = ({
  taskId,
  materialId,
  defaultValues,
}: TUseModal) => {
  const { handleClose } = useModalContext();
  const dispatch = useAppDispatch();

  const [selectRequest] = useMaterialProcessSelectRequestMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(materialProcessSelectRequestSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: TFiledValues) => {
    const formedData = {
      criteria: data.comment,
    };

    const result = await selectRequest({
      id: materialId,
      taskId,
      data: formedData,
    });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    register,
    handleSubmit,
    isSubmitting,
    errors,
    onSubmit,
  } as const;
};
