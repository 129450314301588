import { createSlice, CombinedState } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../services/api/requestHandler";

export type TState = {
  trace: string[];
  messages: string[];
};

const initialState: TState = {
  trace: [],
  messages: [],
};

const errorTraceSlice = createSlice({
  name: "errorTrace",
  initialState,
  reducers: {
    clearTrace() {
      return initialState;
    },
    pushError(state, action) {
      const error = getErrorMessage(action.payload);
      state.trace.push(error);
    },
    pushErrorMessage(state, action) {
      state.trace.push(action.payload);
    },
    popError(state) {
      state.trace.shift();
    },
    pushMessage(state, action) {
      state.messages.push(action.payload);
    },
    pushSuccessMessage(state) {
      state.messages.push("Успешно");
    },
    pushSuccessCopyTextToClipboard(state) {
      state.messages.push("Скопировано");
    },
    popMessage(state) {
      state.messages.shift();
    },
  },
});

export const {
  pushError,
  popError,
  pushMessage,
  popMessage,
  clearTrace,
  pushSuccessMessage,
  pushErrorMessage,
  pushSuccessCopyTextToClipboard,
} = errorTraceSlice.actions;

export const getErrorTrace = (state: CombinedState<any>) => state.errorTrace;

export default errorTraceSlice.reducer;
