import React, { memo } from "react";
import {
  PendingIcon,
  PendingIconRed,
  SuccessIcon,
  WarningIcon,
} from "../../../SVG";

type TaskIconTypeProps = {
  statusKey: number;
  color?: string;
};
export const TaskIconType = memo(({ statusKey, color }: TaskIconTypeProps) => {
  return (
    <span>
      {statusKey === 4 && <SuccessIcon />}
      {statusKey === 2 && color !== "error" && <PendingIcon />}
      {statusKey === 2 && color === "error" && <PendingIconRed />}
      {statusKey === 5 && <WarningIcon />}
    </span>
  );
});
