import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
  CombinedState,
} from "@reduxjs/toolkit";
import { getSections } from "./thunks";
import { createDepthFirstSearch } from "src/components/pages/Project/helpers";
import { getFodlerColors } from "./utils";
import { deepClone } from "src/utils/deepClone";

export type TState = {
  data: any;
  dataApplications: any;
  dataTasks: any;
  openTabs: string[];
  sectionsLoaded: boolean;
  pending: boolean;
  error: string | null;
};

const initialState: TState = {
  data: {},
  dataApplications: {},
  dataTasks: {},
  openTabs: [],
  sectionsLoaded: false,
  pending: false,
  error: null,
};

const dashboardSections = createSlice({
  name: "dashboardSections",
  initialState,
  reducers: {
    setSectionsLoaded(state, action) {
      state.sectionsLoaded = action.payload;
    },
    setDataTree(state, action) {
      state.data = deepClone(action.payload);
    },
    setDataApplicationsTree(state, action) {
      state.dataApplications = deepClone(action.payload);
    },
    setDataTasksTree(state, action) {
      state.dataTasks = deepClone(action.payload);
    },
    setOpenTabs(state, action) {
      state.openTabs = action.payload;
    },
    clearSectionsData(state) {
      state.data = {};
      state.dataApplications = {};
      state.dataTasks = {};
    },
    clearSections() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(getSections), (state) => {
        state.pending = true;
      })
      .addMatcher(isFulfilled(getSections), (state, action) => {
        const tree = action.payload;
        const rootChildren: string[] = [];
        const treeRaw: any = {};

        const dfs = createDepthFirstSearch(treeRaw, rootChildren);
        dfs(tree, "0");

        treeRaw["0"] = {
          name: null,
          ancestor: null,
          id: null,
          children: rootChildren,
          count: false,
          color: null,
        };

        state.openTabs = [];
        state.pending = false;
        state.sectionsLoaded = true;
        // обычное дерево разделов
        state.data = getFodlerColors(treeRaw, false);
      })
      .addMatcher(isRejected(getSections), (state, action) => {
        state.pending = false;
        state.sectionsLoaded = true;
        state.error = action.payload as string;
      });
  },
});

export const {
  setDataTree,
  setDataApplicationsTree,
  setDataTasksTree,
  clearSectionsData,
  setOpenTabs,
  clearSections,
  setSectionsLoaded,
} = dashboardSections.actions;

export const getDashboardSections = (state: CombinedState<any>) =>
  state.dashboard.sections;

export default dashboardSections;
