import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormControl } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { getProjectUsersList } from "src/app/feature/ProjectView/AdminReducer/projectAdmin";
import {
  fetchProjectUsersList,
  fetchProjectUsersType,
  fetchProjectUsersUpdate,
  getProjectAccessByProject,
} from "src/app/feature/ProjectView/AdminReducer/thunks";
import styles from "./Admin.module.scss";
import { SelectField } from "../../../../newUI/SelectMui/SelectMui";
import { Select } from "../../../../UI/components/FormComponentns/Select";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { NewModalPreloader } from "../../../../newUI/NewModal/NewModalPreloader";

interface Inputs {
  type: number;
  user: { title: string; id: string };
}

const schema = Yup.object().shape({
  user: Yup.string().required("Необходимо выбрать пользователя"),
  type: Yup.string().required("Необходимо выбрать форму ответственности"),
});

export const GipEditModal = ({ closeModalGip }: any) => {
  const { newUsersList, isLoadUsersList, usersType } =
    useSelector(getProjectUsersList);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { project } = useSelector(getProjectViewData);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const watchUser = watch("user");

  useEffect(() => {
    if (newUsersList.length === 0) {
      dispatch(fetchProjectUsersList());
    }
    if (watchUser) {
      dispatch(fetchProjectUsersType(watchUser.id));
    }
  }, [watchUser?.id, dispatch]);

  useEffect(() => {
    const arr = newUsersList.filter(
      (user) => +user.id === +project!.gipAccess[0].user_id
    );
    setValue("user", arr[0]);
  }, [newUsersList]);

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      ProjectAccess: {
        access_code: project!.gipAccess[0].access_code,
        user_id: +watchUser.id,
        user_type_id: dataValues.type,
      },
    };
    if (params.id) {
      await dispatch(
        fetchProjectUsersUpdate({ id: project!.gipAccess[0].id, data })
      );
      await dispatch(getProjectAccessByProject(Number(params.id)));
    }
    closeModalGip();
  };

  if (isLoadUsersList) {
    return <NewModalPreloader />;
  }

  if (watchUser) {
    return (
      <div className={styles.adminWrapper}>
        <form
          className={styles.adminWrapper_form}
          onSubmit={handleSubmit(formSubmitHandler)}
        >
          <Select
            value={watchUser}
            name={"user"}
            changeHandler={(_, value) => {
              setValue("user", value);
            }}
            label=""
            newLabel={"Пользователь"}
            options={newUsersList}
            error={!!errors.user}
            helperText={errors.user ? errors.user.message : null}
            isLoading={false}
            disableClear
            newDesign
            variant={styles.select}
          />
          <SelectField
            {...register("type")}
            label={""}
            newLabel={"Форма собственности"}
            name="type"
            options={usersType}
            error={errors.type ? errors.type.message : null}
            newDesign
          />
          <FormControl className={styles.adminWrapper_btn}>
            <Button type="submit">Сохранить</Button>
          </FormControl>
        </form>
      </div>
    );
  }
  return <div></div>;
};
