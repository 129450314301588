import moment from "moment";
import { selectArrayToUrlParams } from "src/FSD/shared/lib/helpers/selectArrayToUrlParams";
import * as KanbanType from "../../types";
import { createUrlSearchParamsFromObject } from "src/FSD/shared/lib/helpers/createUrlSearchString";

export function createFilterURLParams(params: KanbanType.FilterValues) {
  const urlParams: Record<string, string | number> = {};

  if (params.project) {
    urlParams["TaskSearch[projectId]"] = params.project.id;
  }

  if (params.dateStart) {
    urlParams["TaskSearch[kanbanDateStart]"] = moment(params.dateStart).format(
      "DD.MM.YYYY"
    );
  }

  if (params.dateLimit) {
    urlParams["TaskSearch[kanbanDateEnd]"] = moment(params.dateLimit).format(
      "DD.MM.YYYY"
    );
  }

  const partGroupParams = selectArrayToUrlParams(
    "TaskSearch[part_group_id][]",
    params.partGroup
  );

  const workflowParams = selectArrayToUrlParams(
    "TaskSearch[workflow_class][]",
    params.workFlow
  );

  const groupParams = selectArrayToUrlParams(
    "TaskSearch[teamId][]",
    params.group
  );

  const executorParams = selectArrayToUrlParams(
    "TaskSearch[executor_id][]",
    params.executor
  );

  urlParams.sort = params.sort;
  urlParams.groupBy = params.groupBy.id;

  return createUrlSearchParamsFromObject({
    ...urlParams,
    ...partGroupParams,
    ...groupParams,
    ...executorParams,
    ...workflowParams,
  });
}
