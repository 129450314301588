import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { useProjectProcessStartCustomerAlignMutation } from "src/FSD/entities/projectProcess/api";
import { useProcessWorkStartCustomerAlignMutation } from "src/FSD/entities/processWork/api";
import { Props as UseModalProps } from "../../ui/CustomerAlignStartForm";
import { Nullable } from "src/types/generics";
import { customerAlignStartSchema } from "../schemas/customerAlignStart";

interface TFieldValues {
  status: Nullable<Statuses>;
  comment: string;
  uploadFiles: TFile[];
  reviewProcessIds: TSelectItem[];
  name: string;
}

const defaultValues = {
  status: null,
  comment: "",
  uploadFiles: [],
  reviewProcessIds: [],
  name: "Предварительное решение",
};

export const useCustomerAlignStart = ({ id, type }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(customerAlignStartSchema),
  });

  const submitQueries = {
    ProjectProcess: useProjectProcessStartCustomerAlignMutation,
    ProcessWork: useProcessWorkStartCustomerAlignMutation,
  };

  const [startCustomerAlign] = submitQueries[type]();

  // const header = getHeader(type, viewData?.title);

  const onSubmit = async (dataValues: TFieldValues) => {
    const formData = new FormData();

    formData.append("name", dataValues.name);
    formData.append("comment", dataValues.comment);
    formData.append("status", String(Statuses.IS_DONE));

    dataValues.uploadFiles.forEach(({ file }) => {
      formData.append("upload_files[]", file!);
    });

    dataValues.reviewProcessIds.forEach(({ id }) => {
      formData.append("reviewProcessIds[]", String(id));
    });

    await startCustomerAlign({ id, data: formData })
      .unwrap()
      .then((newTask) => {
        handleClose();
      });
  };

  return {
    handleSubmit,
    register,
    errors,
    setValue,
    // header,
    isSubmitting,
    onSubmit,
    control,
  } as const;
};
