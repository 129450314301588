import React, { useState, useRef, useEffect } from "react";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import style from "./style.module.scss";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiRemoveUserFromFirm,
  ApiUserJoin,
} from "../../../app/services/api/organisation/organisation";
import { config } from "../../../app.cofig";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import {
  AddPersonSVG,
  DeleteSVG,
  LightCheckSVG,
  RubleSVG,
  TeamSVG,
} from "../SVG";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { getUserId } from "../../../app/services/auth/auth";

const EMPLOYEE_ID = 7;

interface EmployeeCardProps {
  data: any;
  roles?: any;
  access?: any;
  multipleSupervisors?: boolean;
  isAppliance?: boolean;
  isMobile: boolean;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({
  data,
  roles,
  access,
  isAppliance,
}) => {
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  // const employeeIsNotSupervisor = data.role_id !== MANAGER_ID;
  const userIsEmployee = EMPLOYEE_ID === data.role_id;

  const { id } = useParams<{ id: string }>();
  const currentUserId = getUserId();

  const isCurrentUser = currentUserId === Number(id);

  const dispatch = useDispatch();
  const modalClosed: boolean = useSelector(selectorModalClosed);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const optionsRef: any = useRef();

  const handleEditing = () => {
    Popup.showUserRole.set({
      id: data.id,
      roleId: {
        key: data.role_id,
        title: data.role,
      },
      roles,
    });
    Popup.showUserRole.open();
  };

  const handleTransfer = () => {
    Popup.sendMoney.set({
      value: 0,
      userRoleId: data.id,
    });
    Popup.sendMoney.open();
  };

  const handleManageTeammate = () => {
    Popup.manageTeammate.set({
      candidateId: data.user_id,
      id,
      add: true,
    });
    Popup.manageTeammate.open();
  };

  const handleRemoveTeammate = () => {
    Popup.manageTeammate.set({
      candidateId: data.user_id,
      id,
      add: false,
    });
    Popup.manageTeammate.open();
  };

  const handleRemoveUser = () => {
    if (
      window.confirm("Вы уверены, что хотите исключить указанного сотрудника?")
    ) {
      request(
        ApiRemoveUserFromFirm(data.id),
        () => {
          enqueueSnackbar("Пользователь успешно исключен", {
            variant: "success",
            autoHideDuration: 5000,
          });
        },
        () => (err) => {
          if (err.status === 403) {
            enqueueSnackbar(`${err.message}`, {
              variant: "error",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar("Ошибка исключения пользователя", {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        }
      )();

      dispatch(setModalClosed(!modalClosed));
    }
  };

  const handleSubmit = async (accepted: boolean) => {
    const formData = new FormData();
    formData.append("CompleteForm[status]", accepted ? "4" : "5");
    formData.append(
      "CompleteForm[comment]",
      accepted ? "" : "Вам отказано во вступлении в организацию."
    );

    const message = accepted
      ? "Пользователь успешно принят"
      : "Пользователю отказано во вступлении в организацию.";

    await request(
      ApiUserJoin(data.id, formData),
      () => {
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      () => (err) => {
        if (err.status === 403) {
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar("Возникла ошибка.", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      }
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  const userId = data.user_id ?? data.author.id;

  const handleAcceptance = () => handleSubmit(true);

  const handleRejection = () => handleSubmit(false);

  const handleHideOptions = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    const handleShowOptions = (e: any) => {
      setShowOptions(true);
      e.stopPropagation();
    };
    optionsRef?.current?.addEventListener("click", handleShowOptions, true);

    return () => {
      optionsRef?.current?.removeEventListener(
        "click",
        handleShowOptions,
        true
      );
    };
  }, []);

  return (
    <div
      onClick={() => setShowOptions(false)}
      onMouseLeave={handleHideOptions}
      className={style.wrapper}
    >
      <div className={style.info}>
        <img
          draggable="false"
          className={style.profile}
          src={`${config.localDomain}${
            isAppliance ? data.author.photo : data.photo
          }`}
          alt=""
        />
        <TextLink url={`/user/${userId}?tab=info`} variant={style.name}>
          {isAppliance
            ? `${data.author.surname} ${data.author.name} ${data.author.patronymic}`
            : data.name}
        </TextLink>
        {isAppliance ? (
          <div className={style.applianceControl}>
            <IconButton onClick={handleRejection}>
              <DeleteSVG />
            </IconButton>
            <IconButton onClick={handleAcceptance}>
              <LightCheckSVG />
            </IconButton>
          </div>
        ) : (
          <>
            {access?.editAccount && userIsEmployee && (
              <IconButton onClick={handleTransfer}>
                <RubleSVG />
              </IconButton>
            )}
            {data.can?.beInvitedToTeam && (
              <IconButton onClick={handleManageTeammate}>
                <AddPersonSVG />
              </IconButton>
            )}
            {(data.can?.beDeleted ||
              data.can?.beRemovedFromTeam ||
              data.can?.changeRole) && (
              <IconButton sx={{ color: "white", padding: 0 }} ref={optionsRef}>
                <MoreVertIcon />
              </IconButton>
            )}
          </>
        )}
        {showOptions && (
          <div className={style.subWindow}>
            {data.can?.changeRole && (
              <button onClick={handleEditing}>Редактировать роль</button>
            )}
            {data.can?.beDeleted && (
              <button onClick={handleRemoveUser}>
                {isCurrentUser ? (
                  <>Выйти из организации</>
                ) : (
                  <>Удалить сотрудника</>
                )}
              </button>
            )}
            {data.can?.beRemovedFromTeam && (
              <button onClick={handleRemoveTeammate}>Удалить из команды</button>
            )}
          </div>
        )}
      </div>
      <div className={style.groups}>
        {data?.teams?.length > 0 ? (
          <>
            <p>Группа</p>
            <div className={style.groups__names}>
              {data.teams.map((item: any) => {
                const groupName =
                  item?.part_group_id?.[Object.keys(item?.part_group_id)[0]];

                if (data.role_id !== 12) {
                  return (
                    <div className={style.groupCluster}>
                      <p>{groupName}</p>
                      <TeamSVG />
                    </div>
                  );
                }
                return (
                  <div className={style.groupCluster}>
                    <p>{groupName}</p>
                    <TeamSVG />
                    <p>{item?.members?.length}</p>
                    <div className={style.list}>
                      <h1>{groupName}</h1>
                      {item?.members.map((item: any) => (
                        <p>
                          {item.surname} {item.name} {item.patronymic}
                        </p>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <p style={{ cursor: "default" }}>Группа</p>
            <div className={style.groups__names}>
              <p style={{ cursor: "default" }}>Нет</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeCard;
