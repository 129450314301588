import { ReactNode } from "react";
import styles from "./TabWarning.module.scss";

type TProps = {
  children: ReactNode;
};

export const TabWarning = ({ children }: TProps) => {
  return <div className={styles.warning}>{children}</div>;
};
