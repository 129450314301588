import { useParams } from "react-router-dom";
import classNames from "classnames";
import { Card, Paragraph } from "src/FSD/shared/uiKit/v2";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { trim } from "src/components/pages/User/View/Portfolio/Portfolio";
import { ProjectViewData } from "src/app/feature/ProjectView/createData/createData";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { config } from "src/app.cofig";
import styles from "./InfoFiles.module.scss";

type InfoFilesProps = {
  files: ProjectViewData["files"] | null;
};

export const InfoFiles = ({ files }: InfoFilesProps) => {
  const params = useParams<{ id: string }>();
  const handleNavigate = () => {
    window.location.href = `${config.localDomain}/project/download-files?id=${params.id}&withTasks=0`;
  };

  if (files) {
    return (
      <Card
        header={<Card.Header>Вложения</Card.Header>}
        className={styles.files}
      >
        {files.length ? (
          <>
            {files.map((file) => (
              <TextLink
                className={styles.textLink}
                redirect
                target="blank"
                key={file.url}
                url={file.url}
              >
                {trim(file.title)}
              </TextLink>
            ))}
            <div className={styles.files_btn}>
              <Button onClick={handleNavigate} color="primary">
                Скачать
              </Button>
            </div>
          </>
        ) : (
          <Paragraph>Вложения отсутствуют</Paragraph>
        )}
      </Card>
    );
  }
  return null;
};
