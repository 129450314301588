import { useProjectAccessGetGroupRequestStartFormsQuery } from "src/FSD/entities/projectAccess/api";

type Props = {
  processId: number;
};

export const useGroupRequest = ({ processId }: Props) => {
  const { data: groupRequestProcesses, isFetching } =
    useProjectAccessGetGroupRequestStartFormsQuery({
      processId,
    });

  const isProcessesLoading = !groupRequestProcesses || isFetching;

  return { groupRequestProcesses, isProcessesLoading } as const;
};
