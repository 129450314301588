import * as Yup from "yup";
import {
  arrayRequired,
  selectItemRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const publicationValidationSchema = Yup.object().shape({
  executorType: arrayRequired("Форма собственности"),
  executorByAdmin: Yup.mixed().when("showExecutorAssignment", {
    is: true,
    then: selectItemRequired("Исполнитель"),
  }),
  executorTypeByAdmin: Yup.mixed().when("showExecutorAssignment", {
    is: true,
    then: selectItemRequired("Форма собственности"),
  }),
});
