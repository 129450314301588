import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiMaterialProcessRequests } from "../../model/requestsTypes";

type TParams = RequestParams<{
  id: number;
  taskId: number;
  data: ApiMaterialProcessRequests.SelectRequest;
}>;

export const selectRequest = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, taskId, data }) => ({
      url: `v1/material-process/select-request`,
      params: {
        id,
        taskId,
      },
      data,
      method: HTTPMethods.PATCH,
    }),
    invalidatesTags: [
      ApiTags.MaterialProcess.INDEX,
      ApiTags.MaterialProcess.GET_PROVIDER_FIRMS,
    ],
  });
