export function getAuthorIds(arr: any, type: string) {
  const result = [];
  for (const item of arr) {
    if (item[type]) {
      result.push(item.id);
    }
  }

  return result;
}
