import React, { memo } from "react";
import StarIcon from "@mui/icons-material/Star";
import style from "./style.module.scss";
import { TextLink } from "../../../../../TextLink/TextLink";
import { StarRating } from "../../../../../../../newUI/SVG";
import DefaultAvatar from "../../../../../../../../media/images/avatarDefault.jpg";
import { helpersYears } from "../../../../../../../../utils/helpersYears";
import AvatarProjectView from "./ProjectView/ProjectView";
import AvatarCompareGip from "./CompareGip/CompareGip";

interface AvatarProps {
  data: {
    avatar?: string;
    rating?: number;
    reviews?: number;
    link?: string;
    name?: string;
    firm?: string;
    role?: string;
    firmId?: number;
    jobDuration?: number;
    gipHelpers?: { name: string; id: string }[];
    gipInvite?: boolean;
    gipRemove?: boolean;
    chatId?: number;
    canDelegate?: boolean;
    isDelegated?: boolean;
    id?: number;
  }[];
  individual?: boolean;
  projectView?: boolean;
  projectViewGip?: boolean;
  compareGip?: boolean;
  bottomRating?: number;
}

const Avatar: React.FC<AvatarProps> = ({
  data,
  individual,
  projectView,
  projectViewGip,
  compareGip,
}) => {
  if (!data.length) {
    return null;
  }

  if (individual) {
    const individ = data[0];
    return (
      <div className={style.wrapper}>
        <img src={individ.avatar} alt="" />
        <div className={style.info}>
          <div className={style.info__rating}>
            <StarIcon className={style.info__rating__icon} />
            <h1>{individ.rating}</h1>
          </div>
          <div className={style.info__reviews}>
            <p>Отзывы</p>
            <TextLink url={`${individ.link}`} variant={style.link}>
              {`${individ.reviews}`}
            </TextLink>
          </div>
        </div>
      </div>
    );
  }

  if (projectViewGip) {
    const project = data[0];
    return (
      <div className={style.project}>
        <div className={style.project_img}>
          <img
            src={project.avatar || DefaultAvatar}
            alt="Аватар пользователя"
          />
          <div className={style.project_viewRating}>
            {Boolean(project.rating) && (
              <div className={style.project_viewRating_star}>
                <StarRating />
                <p>{project.rating}</p>
              </div>
            )}
            <p className={style.project_viewRating_skill}>
              Опыт {helpersYears(project.jobDuration)}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (projectView) {
    const project = data[0];
    return <AvatarProjectView project={project} />;
  }

  if (compareGip) {
    const project = data[0];
    return <AvatarCompareGip project={project} />;
  }

  return (
    <div className={style.ownerWrapper}>
      {data.map((item: any, i) => (
        <div key={i} className={style.owner}>
          <div className={style.title}>{item.role}</div>
          <div className={style.who}>
            {item.link ? (
              <>
                <img src={item.avatar} alt="" />
                <div className={style.who__info}>
                  <TextLink variant={style.userLink} url={item.link}>
                    {item.name}
                  </TextLink>
                  <p>{item.firm}</p>
                </div>
              </>
            ) : (
              <p className={style.darkFont}>{item.name}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(Avatar);
