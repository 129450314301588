import { useMaterialProcessViewFormColumnsQuery } from "src/FSD/entities/materialProcess/api";

type TUseModal = {
  materialId: number;
};

export const useMaterialProcessQuestionnaire = ({ materialId }: TUseModal) => {
  const { data: viewFormColumns, isFetching } =
    useMaterialProcessViewFormColumnsQuery({
      id: materialId,
    });

  return {
    viewFormColumns,
    isFetching: !viewFormColumns || isFetching,
  } as const;
};
