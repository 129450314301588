import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import style from "../style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { request } from "src/app/services/api/requestHandler";
import {
  ApiCreateBankAccount,
  ApiUpdateBankAccount,
} from "src/app/services/api/organisation/organisation";
import { BankAccount } from "./Forms";
import {
  selectorModalClosed,
  setModalClosed,
} from "src/app/feature/modalClose/eventModalClose";
import { PencilEditSVG } from "../../../../../newUI/SVG";
import { validationSchema } from "../yupTools";
import { AccountsProps } from "../types";
import { generate } from "../constants";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import {
  bankAccount1bikValidator,
  bankAccount2bikValidator,
  treasuryAccount1bikValidator,
} from "../validators";
import { palette } from "src/styles/restyle";

const Accounts: React.FC<AccountsProps> = ({
  data,
  editing,
  setEditing,
  isMobile,
  sx,
  handleSetEdit,
  editingCondition,
  access,
}) => {
  const defaultValues: any = generate(data);
  const { successSnackbar, enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const modalClosed: boolean = useSelector(selectorModalClosed);
  const params = useParams<{ id: string }>();

  const [disabled, setDisabled] = useState(false);
  const [defaultNumber, setDefaultNumber] = useState(
    defaultValues.defaultNumber
  );

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,
    trigger,
    formState,
  } = useForm<any>({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmit = async (data: any) => {
    setDisabled(true);
    const errors: any[] = [];

    let counter = false;
    const setCounter = (val: boolean) => {
      counter = val;
    };

    if (data.bankAccount1bik) {
      await bankAccount1bikValidator(data, setError, setCounter);
    }

    if (data.bankAccount2bik) {
      await bankAccount2bikValidator(data, setError, setCounter);
    }

    if (data.treasuryAccount1bik) {
      await treasuryAccount1bikValidator(data, setError, setCounter);
    }

    if (counter) {
      setDisabled(false);
      return;
    }

    if (data.bankAccount1bik) {
      const formattedData = {
        firm_id: params.id,
        bik: data.bankAccount1bik,
        bank_name: data.bankAccount1bank_name,
        correspondent_account: data.bankAccount1correspondent_account,
        bank_account: data.bankAccount1bank_account,
        default: defaultNumber === 1,
        inn: data.bankAccount1inn,
        kpp: data.bankAccount1kpp,
        ufk: data.bankAccount1ufk,
        client_number: data.bankAccount1client_number,
        analysis_code: data.bankAccount1analysis_code,
        type: 1,
      };

      await request(
        defaultValues.bankAccount1bik
          ? ApiUpdateBankAccount(data.bankAccount1id, formattedData)
          : ApiCreateBankAccount(formattedData),
        (data, headersValue, headers, status) => {
          if (status && status >= 400) {
            let error = `БАНКОВСКИЕ РЕКВИЗИТЫ №1\n`;
            data.forEach((item: any) => {
              error = `${error}: ${item.message}`;
            });
            errors.push(error);
          }
        },
        () => (err) => {
          const receivedErrors = JSON.parse(err.message);
          let error = `БАНКОВСКИЕ РЕКВИЗИТЫ №1\n`;
          Object.keys(receivedErrors).forEach((item: any) => {
            error = `${error}-${receivedErrors[item].join(", ")}\n`;
          });
          errors.push(error);
        }
      )();
    }

    if (data.bankAccount2bik) {
      const formattedData = {
        firm_id: params.id,
        bik: data.bankAccount2bik,
        bank_name: data.bankAccount2bank_name,
        correspondent_account: data.bankAccount2correspondent_account,
        bank_account: data.bankAccount2bank_account,
        default: defaultNumber === 2,
        inn: data.bankAccount2inn,
        kpp: data.bankAccount2kpp,
        ufk: data.bankAccount2ufk,
        client_number: data.bankAccount2client_number,
        analysis_code: data.bankAccount2analysis_code,
        type: 1,
      };

      await request(
        defaultValues.bankAccount2bik
          ? ApiUpdateBankAccount(data.bankAccount2id, formattedData)
          : ApiCreateBankAccount(formattedData),
        (data, headersValue, headers, status) => {
          if (status && status >= 400) {
            let error = `БАНКОВСКИЕ РЕКВИЗИТЫ №2\n`;
            data.forEach((item: any) => {
              error = `${error}: ${item.message}`;
            });
            errors.push(error);
          }
        },
        () => (err) => {
          const receivedErrors = JSON.parse(err.message);
          let error = `БАНКОВСКИЕ РЕКВИЗИТЫ №2\n`;
          Object.keys(receivedErrors).forEach((item: any) => {
            error = `${error}-${receivedErrors[item].join(", ")}\n`;
          });
          errors.push(error);
        }
      )();
    }

    if (data.treasuryAccount1bik) {
      const formattedData = {
        firm_id: params.id,
        bik: data.treasuryAccount1bik,
        bank_name: data.treasuryAccount1bank_name,
        correspondent_account: data.treasuryAccount1correspondent_account,
        bank_account: data.treasuryAccount1bank_account,
        default: defaultNumber === 3,
        inn: data.treasuryAccount1inn,
        kpp: data.treasuryAccount1kpp,
        ufk: data.treasuryAccount1ufk,
        client_number: data.treasuryAccount1client_number,
        analysis_code: data.treasuryAccount1analysis_code,
        type: 2,
      };

      await request(
        defaultValues.treasuryAccount1bik
          ? ApiUpdateBankAccount(data.treasuryAccount1id, formattedData)
          : ApiCreateBankAccount(formattedData),
        (data, headersValue, headers, status) => {
          if (status && status >= 400) {
            let error = `КАЗНАЧЕЙСКИЙ СЧЕТ\n`;
            data.forEach((item: any) => {
              error = `${error}: ${item.message}`;
            });
            errors.push(error);
          }
        },
        () => (err) => {
          const receivedErrors = JSON.parse(err.message);
          let error = `КАЗНАЧЕЙСКИЙ СЧЕТ\n`;
          Object.keys(receivedErrors).forEach((item: any) => {
            error = `${error}-${receivedErrors[item].join(", ")}\n`;
          });
          errors.push(error);
        }
      )();
    }

    if (errors.length) {
      enqueueSnackbar(errors.join("\n"), {
        variant: "error",
        autoHideDuration: 8000,
      });
    } else {
      successSnackbar("Банковские счета успешно обновлены.");
    }

    setEditing(false);
    setDisabled(false);

    dispatch(setModalClosed(!modalClosed));
  };

  const handleStopEditing = () => {
    setEditing(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.formWrapper}>
      {!editing && (
        <div className={classNames(style.formEditable, style.editingTab)}>
          {access.editBankAccount &&
            editingCondition() &&
            (isMobile ? (
              <IconButton sx={sx} onClick={handleSetEdit}>
                <PencilEditSVG />
              </IconButton>
            ) : (
              <CustomButton
                width={160}
                disabled={editing}
                onClick={handleSetEdit}
                background={palette.blue}
                className={style.button}
              >
                Редактировать
              </CustomButton>
            ))}
        </div>
      )}
      <div className={editing ? style.formEditable : ""}>
        {!defaultValues.bankAccount1bik &&
          !defaultValues.bankAccount2bik &&
          !defaultValues.treasuryAccount1bik &&
          !editing && <div className={style.notFound}>Ничего не найдено</div>}
        {editing && (
          <div className={style.title}>
            <p>Банковские реквизиты</p>
            <p>Режим редактирования</p>
          </div>
        )}
        {(editing || defaultValues.bankAccount1bik) && (
          <BankAccount
            targetKey="bankAccount1"
            targetNumber={1}
            editing={editing}
            watch={watch}
            errors={errors}
            register={register}
            trigger={trigger}
            setValue={setValue}
            defaultNumber={defaultNumber}
            setDefaultNumber={setDefaultNumber}
          />
        )}
        {(editing || defaultValues.bankAccount2bik) && (
          <BankAccount
            targetKey="bankAccount2"
            targetNumber={2}
            editing={editing}
            watch={watch}
            errors={errors}
            register={register}
            trigger={trigger}
            setValue={setValue}
            defaultNumber={defaultNumber}
            setDefaultNumber={setDefaultNumber}
          />
        )}
        {/*
        {(editing || defaultValues.treasuryAccount1bik) && (
          <TreasuryAccount
            targetNumber={3}
            watch={watch}
            errors={errors}
            register={register}
            trigger={trigger}
            setValue={setValue}
            targetKey="treasuryAccount1"
            editing={editing}
            defaultNumber={defaultNumber}
            setDefaultNumber={setDefaultNumber}
          />
        )}
        */}
        <></>
      </div>
      {editing && (
        <div className={style.formControl}>
          <CustomButton
            type="submit"
            background={palette.blue}
            disabled={disabled}
            width={160}
            className={style.button}
          >
            Сохранить
          </CustomButton>
          <CustomButton
            background={palette.red}
            onClick={handleStopEditing}
            width={160}
            className={style.button}
          >
            Отменить
          </CustomButton>
        </div>
      )}
    </form>
  );
};

export default Accounts;
