import React, { memo } from "react";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { palette, textFieldSX } from "src/styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import cls from "./ModalDeposit.module.scss";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessDeposit } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessDeposit";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

type TInputs = {
  deposit: number;
};
type ModalDepositProps = {
  closeModal: () => void;
  includesNds?: boolean;
};
export const ModalDeposit = memo(
  ({ closeModal, includesNds = false }: ModalDepositProps) => {
    const dispatch = useAppDispatch();
    const params = useParams<{ id: string }>();
    const processData = useAppSelector(getProjectProcessInfoData);
    const maxDeposit =
      Number(processData?.price) - Number(processData?.deposit);

    const schema = Yup.object().shape({
      deposit: Yup.number()
        .min(0, `Значение «Сумма без налога (НДС)» должно быть не меньше 0.`)
        .max(maxDeposit, `Максимальная сумма: ${maxDeposit} ₽`)
        .required("Это поле обязательно для заполнения")
        .typeError("Значение «Cтоимость» должно быть числом."),
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
    });

    async function formSubmitHandler(dataValues: TInputs) {
      const data = {
        value: +dataValues.deposit,
      };
      if (params.id) {
        await dispatch(fetchProjectProcessDeposit({ id: +params.id, data }));

        dispatch(fetchProjectProcessViewDuplicate(+params.id));
        closeModal();
      }
    }

    return (
      <form className={cls.deposit} onSubmit={handleSubmit(formSubmitHandler)}>
        <h1>Пополнение баланса раздела</h1>
        <TextField
          {...textFieldSX}
          {...register("deposit")}
          placeholder="Введите сумму"
          label={
            includesNds ? "Сумма с налогом (НДС)" : "Сумма без налога (НДС)"
          }
          error={!!errors.deposit}
          helperText={errors.deposit ? errors.deposit.message : null}
        />
        <CustomButton
          background={palette.blue}
          width={160}
          className={cls.deposit_submit}
          type="submit"
        >
          Перевести
        </CustomButton>
      </form>
    );
  }
);
