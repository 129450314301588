import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { Select } from "../../UI/components/FormComponentns/Select";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetDesignTypes } from "../../../app/services/api/designTypes/designTypes";

export interface DesignTypeItem {
  title: string;
  id: string;
}

export type DesignTypeProps = ComponentPropsType<{
  changeHandler?: (type: DesignTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
}>;

export const DesignType: React.FC<DesignTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  isClearable,
  value,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<DesignTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<DesignTypeItem | undefined>(
    undefined
  );

  const onLoadTypes = () => {
    request(
      ApiGetDesignTypes(),
      (data) => {
        setAllTypes(
          Object.entries<string>(data).map(([id, title]) => ({
            title,
            id,
          }))
        );
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов дизайна", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )(dispatch);
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    onLoadTypes();
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => Number(item.id) === Number(value));
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  return (
    <Select
      changeHandler={(event, value) => {
        changeHandler(value);
      }}
      key={selectedValue ? selectedValue.title : "noneSpec"}
      value={selectedValue}
      searchHandler={onSearch}
      options={allTypes}
      label={"Вид"}
      variant={classNames(variant)}
      disableClear={isClearable}
    />
  );
};
