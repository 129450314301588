import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { config } from "src/app.cofig";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { IReward } from "src/app/feature/profileReducer/types";
import { UnsavedType } from "src/components/pages/Project/types";
import { SetState } from "src/components/features/ProjectEditing/SectionEditing/SectionEditing";
import { palette, textFieldSX } from "src/styles/restyle";
import style from "./style.module.scss";
import { SubmitDataProps } from "../Rewards";

interface ComponentProps {
  onCreate?: (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onUpdate?: (
    name: string,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onDelete?: (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  reward?: IReward;
  setUnsaved: SetState<UnsavedType>;
}

const schemaWithImage = yup.object().shape({
  name: yup
    .string()
    .required("Необходимо заполнить «Наименование»")
    .max(500, "Максимум 500 символов"),
  file_upload: yup
    .mixed()
    .nullable()
    .test(
      "fileIsUploaded",
      "Прикрепите фото",
      // @ts-ignore
      (value) => {
        if (!value) {
          return true;
        }
        return value![0] !== undefined;
      }
    ),
});

const schemaWithoutImage = yup.object().shape({
  name: yup.string().required("Необходимо заполнить «Наименование»"),
});

const RewardItem: React.FC<ComponentProps> = ({
  onCreate,
  onUpdate,
  onDelete,
  reward,
  setUnsaved,
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(reward ? schemaWithoutImage : schemaWithImage),
    mode: "all",
    reValidateMode: "onChange",
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const imagesArray = watch("file_upload");

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, awards: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  return (
    <div className={style.container}>
      <form
        onSubmit={handleSubmit(
          reward
            ? (data = getValues()) => {
                onUpdate!(data.name, reward.id, setIsFetching);
              }
            : (data = getValues()) => onCreate!(data, setIsFetching)
        )}
      >
        <div
          className={cn(style.imageContainer, {
            [style.noInput]: reward,
          })}
        >
          <img
            className={style.image}
            src={
              imagesArray?.length
                ? URL.createObjectURL(imagesArray[0])
                : reward
                  ? `${config.localDomain}${reward.file_id}`
                  : "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            }
            alt="Изображение"
          />
          {reward ? null : (
            <>
              <input
                {...register("file_upload")}
                type="file"
                accept="image/*"
                id="imageInput"
                className={style.imageInput}
              />
              <label htmlFor="imageInput" className={style.imageLabel}>
                <span>Выбрать</span>
              </label>
              {!!errors.file_upload && (
                <span className={style.imageError}>
                  {errors.file_upload.message}
                </span>
              )}
            </>
          )}
        </div>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Наименование"
          defaultValue={reward?.name || ""}
          autoComplete="off"
          {...register("name")}
          {...textFieldSX}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
        <div
          className={cn(style.bottomContainer, {
            [style.twoButtons]: reward,
          })}
        >
          {reward ? (
            <CustomButton
              width={160}
              type="button"
              style={{ marginRight: "16px" }}
              background={palette.red}
              onClick={() => onDelete!(reward.id, setIsFetching)}
              disabled={isFetching}
            >
              Удалить
            </CustomButton>
          ) : null}
          <CustomButton
            width={160}
            type="submit"
            background={palette.blue}
            disabled={isFetching}
          >
            Сохранить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default RewardItem;
