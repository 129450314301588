import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { palette, textFieldSX } from "../../../../../../../../styles/restyle";
import classes from "./Modal.module.scss";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import {
  fetchGipHelperInvite,
  fetchProjectViewSearchGip,
} from "../../../../../../../../app/feature/ProjectView/thunks";
import { getProjectViewData } from "../../../../../../../../app/feature/ProjectView/projectView";

interface Inputs {
  type: string;
}

const sx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

const schema = Yup.object().shape({
  type: Yup.string().ensure().required("Необходимо выбрать помощника"),
});

interface GipHelperModalProps {
  modalClose: () => void;
}

export const GipHelperModal: React.FC<GipHelperModalProps> = memo(
  ({ modalClose }) => {
    const { project, gipHelpersList } = useSelector(getProjectViewData);
    const dispatch = useDispatch();
    const onlyGipHelpers = gipHelpersList?.filter(
      (gipHelper) => gipHelper.id !== project?.gip?.id
    );

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
    });

    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        executor_id: dataValues.type,
      };
      if (project?.id) {
        const invite = {
          id: project.id,
          data,
        };
        await dispatch(fetchGipHelperInvite({ invite }));
        await dispatch(fetchProjectViewSearchGip(project.id));
        modalClose();
      }
    };

    return (
      <div>
        <form
          onSubmit={handleSubmit(formSubmitHandler)}
          className={classes.modalGipAdd}
        >
          <h1>Запрос помощника ГИП</h1>
          <FormControl {...textFieldSX}>
            <InputLabel id="type">Сотрудник</InputLabel>
            <Select
              {...register("type")}
              labelId="type"
              label="Сотрудник"
              defaultValue={[]}
              error={!!errors.type}
            >
              {onlyGipHelpers &&
                onlyGipHelpers.map((person) => (
                  <MenuItem key={person.id} sx={sx} value={person.id}>
                    {person.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.type ? <p>{errors.type.message}</p> : null}
          </FormControl>
          <CustomButton
            className={classes.modalGipAdd_btn}
            background={palette.blue}
            width={160}
            type="submit"
          >
            Отправить
          </CustomButton>
        </form>
      </div>
    );
  }
);
