import React from "react";
import style from "./style.module.scss";

interface AwardItemProps {
  item: {
    src: string;
    title: string;
  };
}

const AwardItem: React.FC<AwardItemProps> = ({ item }) => {
  return (
    <div className={style.item}>
      <img src={item.src} alt="" draggable="false" />
      <div>{item.title}</div>
    </div>
  );
};

interface AwardProps {
  data: any;
}

export const Awards: React.FC<AwardProps> = ({ data }) => {
  return (
    <div className={style.wrapper}>
      {data.dataRewards?.length > 0 ? (
        <div className={style.awards}>
          <p>Награды</p>
          <div className={style.grid}>
            {data.dataRewards?.map((item: any) => <AwardItem item={item} />)}
          </div>
        </div>
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};
