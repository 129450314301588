import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import cls from "./FeedBackForm.module.scss";
import Avatar from "../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import StarsRating from "../../features/SpecialForm/StarsRating";
import { palette, textFieldSX } from "../../../styles/restyle";
import CustomButton from "../CustomButton/CustomButton";

interface Inputs {
  comment: string;
  quality: number;
  reliability: number;
  loyalty: number;
}

const schema = Yup.object().shape({
  comment: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

interface FeedbackFormProps {
  data: {
    avatar?: string;
    rating?: number;
    link?: string;
    name?: string;
    firm?: string;
    jobDuration?: number;
    role: string;
  }[];
  formSubmitHandler: (data: any) => void;
  title?: string;
}
export const FeedBackForm: React.FC<FeedbackFormProps> = memo(
  ({ formSubmitHandler, data, title = "Оценка исполнителя" }) => {
    const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        quality: 0,
        reliability: 0,
        loyalty: 0,
        comment: "",
      },
    });

    const qualityValue = watch("quality");
    const reliabilityValue = watch("reliability");
    const loyaltyValue = watch("loyalty");

    return (
      <form className={cls.wrapper} onSubmit={handleSubmit(formSubmitHandler)}>
        <h1>{title}</h1>
        <div>
          <Avatar data={data} projectView bottomRating={80} />
        </div>
        <div className={cls.star}>
          <div className={cls.star_block}>
            <p>Качество</p>
            <StarsRating
              value={qualityValue}
              handleChange={(value: number) => {
                setValue("quality", value);
              }}
            />
          </div>
          <div className={cls.star_block}>
            <p>Надежность</p>
            <StarsRating
              value={reliabilityValue}
              handleChange={(value: number) => setValue("reliability", value)}
            />
          </div>
          <div className={cls.star_block}>
            <p>Лояльность</p>
            <StarsRating
              value={loyaltyValue}
              handleChange={(value: number) => setValue("loyalty", value)}
            />
          </div>
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          className={cls.comment}
          placeholder="Введите комментарий"
          label="Комментарий"
          {...register("comment")}
        />
        {errors.comment ? (
          <p className={cls.error}>{errors.comment.message}</p>
        ) : null}
        <CustomButton
          className={cls.sendButton}
          type="submit"
          width={160}
          background={palette.blue}
        >
          Отправить
        </CustomButton>
      </form>
    );
  }
);
