import React from "react";
import moment from "moment/moment";
import { Controller } from "react-hook-form";
import { Button, Modal, TextField } from "src/FSD/shared/uiKit/v2";
import { useExpertiseCommentCreate } from "../lib/hooks/useExpertiseCommentCreate";
import styles from "./ExpertiseCommentCreateForm.module.scss";

type TProps = {
  rowId: number;
};

export const ExpertiseCommentCreateForm = ({ rowId }: TProps) => {
  const {
    isLoading,
    isLoadingComment,
    handleSubmit,
    control,
    errors,
    handleSubmitForm,
    comments,
  } = useExpertiseCommentCreate({ rowId });

  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      {isLoadingComment ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(handleSubmitForm)}>
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Комментарий"
                placeholder="Введите комментарий"
                multiline
                minRows={2}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                onChange={onChange}
              />
            )}
          />
          {comments?.map((comment, index) => (
            <div className={styles.form_comment} key={index}>
              <div className={styles.form_comment_flex}>
                <p>{comment.text}</p>
                <p>
                  {moment(comment.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
                    "DD.MM.YYYY"
                  )}
                </p>
              </div>
            </div>
          ))}
          <Modal.Controls>
            <Button type="submit" disabled={isLoading} isLoading={isLoading}>
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
