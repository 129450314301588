import { Tooltip, Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Executors.module.scss";
import { EmptyCell } from "./EmptyCell";

type TProps = {
  value: string;
};

export const Comment = ({ value }: TProps) => {
  if (!value) {
    return <EmptyCell />;
  }

  return (
    <Tooltip content={value}>
      <Paragraph
        color="light"
        className={styles.groupApplicationsTable__comment}
      >
        {value}
      </Paragraph>
    </Tooltip>
  );
};
