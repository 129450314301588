import React from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import style from "./style.module.scss";
import { getDateAndTime } from "../../../../app/services/date/date";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { config } from "../../../../app.cofig";
import { PencilEditSVG } from "../../../newUI/SVG";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import C from "../../../../constants";
import { palette } from "../../../../styles/restyle";

export interface CheckProps {
  data: any;
  title: string | undefined | null;
  sx: any;
  isMobile: any;
  access: any;
  editingCondition: any;
  handleSetEdit: any;
  editing: any;
}

const Check: React.FC<CheckProps> = ({
  data,
  sx,
  isMobile,
  access,
  editingCondition,
  handleSetEdit,
  editing,
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.title}>
        <div>Проверка организации</div>
        <p>
          {data.date_complete
            ? getDateAndTime(data.date_complete, false)
            : C.NOT_STATED}
        </p>
      </div>
      <div className={style.section}>
        <div className={style.name}>Комментарий</div>
        <div className={style.content}>{data.content ?? C.NOT_STATED}</div>
      </div>
      <div className={style.btnEd}>
        {access?.edit &&
          editingCondition() &&
          (isMobile ? (
            <IconButton sx={sx} onClick={handleSetEdit}>
              <PencilEditSVG />
            </IconButton>
          ) : (
            <CustomButton
              width={160}
              disabled={editing}
              onClick={handleSetEdit}
              background={palette.blue}
              className={style.button}
            >
              Редактировать
            </CustomButton>
          ))}
      </div>
      <div className={style.section}>
        <div className={style.name}>Файлы</div>
        {data?.files.length > 0 ? (
          data.files.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <TextLink
                target={"blank"}
                url={`${config.localDomain}/file/get?id=${item.id}`}
                redirect={true}
              >
                <div className={classNames(style.content, style.files)}>
                  {item.name}
                </div>
              </TextLink>
            </React.Fragment>
          ))
        ) : (
          <div className={style.content}>{C.NOT_STATED}</div>
        )}
      </div>
      <div className={style.section}>
        <div className={style.name}>Комментарий администратора</div>
        <div className={style.content}>
          {data.comment ? data.comment : C.NOT_STATED}
        </div>
      </div>
    </div>
  );
};

export default Check;
