export function getAuthorNames(arr: any) {
  const result: Record<number, string> = {};
  for (const item of arr) {
    const { id, name, delete: canDelete } = item;

    if (canDelete) {
      result[id] = name;
    }
  }
  return result;
}
