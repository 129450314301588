import { useContext } from "react";
import { Heading, Paragraph, Rating } from "src/FSD/shared/uiKit/v2";
import { BorderLine } from "./BorderLine";
import { RemoveExecutorButton } from "./RemoveExecutorButton";
import styles from "./Comparison.module.scss";
import { ApplicationsContext } from "../Applications";

export const Header = () => {
  const { selectedUserTypeIds, groupId, applications } =
    useContext(ApplicationsContext);

  const { userTypes } = applications[groupId!];

  return (
    <>
      {/* Кнопки */}
      <div />
      {selectedUserTypeIds.map((userTypeId) => (
        <>
          <div />
          <RemoveExecutorButton userTypeId={userTypeId} />
        </>
      ))}
      {/* ФИО */}
      <Paragraph className={styles.rowName}>
        Проектировщик
        <br />
        Ф.И.О.
      </Paragraph>
      {selectedUserTypeIds.map((id) => (
        <>
          <BorderLine />
          <Heading>{userTypes[id].fio}</Heading>
        </>
      ))}
      {/* Форма собственности */}
      <Paragraph className={styles.rowName}>Форма собственности</Paragraph>
      {selectedUserTypeIds.map((id) => (
        <>
          <BorderLine />
          <Paragraph>{userTypes[id].userTypeText}</Paragraph>
        </>
      ))}
      {/* Рейтинг */}
      <Paragraph className={styles.rowName}>Рейтинг</Paragraph>
      {selectedUserTypeIds.map((id) => (
        <>
          <BorderLine />
          <Rating.Star value={userTypes[id].rating} />
        </>
      ))}
    </>
  );
};
