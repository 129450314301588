import { useContext, useEffect } from "react";
import { useProjectAccessGroupSelectExecutorMutation } from "src/FSD/entities/projectAccess/api";
import { useAppDispatch } from "../../../../../../../app/store";
import { useTabsPanelContext } from "../../../../TabsPanel";
import { ApplicationsContext } from "../../ui/Applications";
import { TFieldValues } from "../../model/fieldValues";
import { createFormData } from "../helpers/createFormData";
import { pushMessage } from "../../../../../../../app/feature/errorTrace";
import { EXECUTORS_SELECTION_DONE_SUCCESSFULLY } from "../../model/constants";
import { trimNumber } from "../../../../../../../utils/trimNumber";

export const useControls = () => {
  const dispatch = useAppDispatch();

  const { setCurrentTab } = useTabsPanelContext();

  const {
    groupId,
    totalPrice,
    applications,
    customer,
    account,
    projectId,
    form: {
      handleSubmit,
      formState: { isSubmitting },
    },
    setBankErrorTaskIds,
  } = useContext(ApplicationsContext);

  const [groupSelectExecutor] = useProjectAccessGroupSelectExecutorMutation();

  const onSubmit = async (dataValues: TFieldValues) => {
    const taskIds = createFormData(dataValues, applications, groupId!);

    const response = await groupSelectExecutor({
      projectId,
      data: { taskIds },
    });

    // @ts-ignore
    if (!response?.error) {
      dispatch(pushMessage(EXECUTORS_SELECTION_DONE_SUCCESSFULLY));
      setCurrentTab("processes");
    }
  };

  const totalPriceDifference = trimNumber(totalPrice - account.value);

  const showBankAccountError = totalPrice > account.value;

  const submitHandler = handleSubmit(onSubmit);

  useEffect(() => {
    if (!showBankAccountError) {
      setBankErrorTaskIds([]);
    }
  }, [showBankAccountError]);

  return {
    handleSubmit,
    onSubmit,
    totalPriceDifference,
    showBankAccountError,
    isSubmitting,
    submitHandler,
    totalPrice,
    applications,
    customer,
    account,
  } as const;
};
