import React, { FC } from "react";
import { Skeleton as MuiSkeleton } from "@mui/material";
import { useAppSelector } from "../../../../../../app/store";
import { getDashboardProjects } from "../../../../../../app/feature/dashboard/projects";
import { TProject } from "../../../../../../app/feature/dashboard/projects/thunks";
import ProjectItem from "./ProjectItem";
import style from "../style.module.scss";

const Skeleton = () => (
  <MuiSkeleton variant="rectangular" className={style.projectPending} />
);

interface SubProjectsProps {
  id: number;
  offset: number;
  isMobile: boolean;
}

const SubProjects: FC<SubProjectsProps> = ({ id, offset, isMobile }) => {
  const { subPending, subData } = useAppSelector(getDashboardProjects);

  const isPending = subPending.includes(id);
  const subProjectsList = (subData[id] ?? []).map((project: TProject) => (
    <ProjectItem isMobile={isMobile} offset={offset + 1} {...project} />
  ));

  return isPending ? <Skeleton /> : subProjectsList;
};

export default SubProjects;
