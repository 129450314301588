import { createContext } from "react";
import { Skeleton, List } from "src/FSD/shared/uiKit/v2";
import { Comparison } from "./Comparison/Comparison";
import { Executors } from "./Executors/Executors";
import { Header } from "./Header/Header";
import { useApplications } from "../lib/hooks/useApplications";
import { TApplicationsContext } from "../model/applicationsContext";
import { Warning } from "./Warning/Warning";

export const ApplicationsContext = createContext<TApplicationsContext>(
  {} as TApplicationsContext
);

type TProps = {
  projectId: number;
};

export const Applications = ({ projectId }: TProps) => {
  const value = useApplications({ projectId });

  const { isApplicationsLoading, isComparison, warning } = value;

  const applicationsContent = isComparison ? <Comparison /> : <Executors />;

  const skeletonPreloader = (
    <List>
      <Skeleton.List />
    </List>
  );

  return (
    <ApplicationsContext.Provider value={value}>
      <Header />
      {Boolean(warning.length) && <Warning warning={warning} />}
      {isApplicationsLoading ? skeletonPreloader : applicationsContent}
    </ApplicationsContext.Provider>
  );
};
