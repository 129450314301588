import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { index, getProjectPartByGroup } from "./queries";

export const partGroupApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    partGroupIndex: index(builder),
    partGroupGetProjectPartByGroup: getProjectPartByGroup(builder),
  }),
  overrideExisting: false,
});

export const {
  usePartGroupIndexQuery,
  usePartGroupGetProjectPartByGroupQuery,
} = partGroupApi;
