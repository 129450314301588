import React from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";
import { Accordion } from "../../../../newUI/Accordion/Accordion";
import { ChecksCard } from "../../../../newUI/ChecksCard/ChecksCard";
import { CheckTaskDataType } from "../../../../../app/feature/ProjectView/CheckTaskReducer/thunks";
import {
  getProjectCheckTasks,
  setToggleAccordion,
} from "../../../../../app/feature/ProjectView/CheckTaskReducer/projectCheckTask";
import { useAppDispatch } from "../../../../../app/store";

interface CheckTaskCardProps {
  checkTask: CheckTaskDataType;
  index: number;
}

export const CheckTaskCard = ({ checkTask, index }: CheckTaskCardProps) => {
  const dispatch = useAppDispatch();
  const { toggleAccordion } = useSelector(getProjectCheckTasks);
  function setToggle(key: number) {
    dispatch(setToggleAccordion(key));
  }

  return (
    <>
      <Accordion
        text={`${checkTask.title} (${checkTask.checks.length})`}
        toogle={toggleAccordion[index]}
        onToggle={() => setToggle(index)}
        numNode={"1"}
        warningFolder={!!checkTask.dot}
        noMargin={index === 1}
      />
      {toggleAccordion[index] && (
        <div className={styles.cardList}>
          {checkTask.checks.map((task) => (
            <ChecksCard key={task.code} {...task} />
          ))}
        </div>
      )}
    </>
  );
};
