import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import cn from "classnames";
import {
  fetchTaskComplete,
  ProjectCompareGipReturn,
} from "../../../../../app/feature/ProjectView/CompareReducer/thunks";
import Avatar from "../../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { config } from "../../../../../app.cofig";
import style from "./style.module.scss";
import { ChatIcon, CheckSVG } from "../../../../newUI/SVG";
import { trimNumber } from "../../../../../utils/trimNumber";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";

interface CompareGipCardProps {
  userGip: ProjectCompareGipReturn;
}

const sxBtn = {
  color: "#9DAFBD",
  "&:hover": {
    cursor: "pointer",
    color: "#FFFFFF",
  },
};

export const CompareGipCard = memo(({ userGip }: CompareGipCardProps) => {
  const dispatch = useDispatch();
  const [toggleDiv, setToggleDiv] = useState(false);

  const clickButton = () => {
    dispatch(fetchTaskComplete(userGip.task_id));
  };

  const hasComment = Boolean(userGip.task.content.length);

  const data = [
    {
      avatar: `${config.localDomain}${userGip.user.photo}`,
      link: `/user/${userGip.user.id}`,
      name: `${userGip.user.surname} ${userGip.user.name} ${userGip.user.patronymic}`,
      firm: userGip.userType.listName,
      jobDuration: Math.floor(userGip?.user.job_duration / 365),
      rating: userGip.user.rating.gip,
    },
  ];

  return (
    <div
      className={cn(
        style.wrapper_block,
        hasComment && style.wrapper_block_hasComment
      )}
    >
      <div className={style.compare}>
        <div className={style.compare_head}>
          <Avatar compareGip data={data} />
          <div className={style.compare_btn}>
            <IconButton sx={sxBtn} onClick={clickButton}>
              <CheckSVG width="22" height="22" />
            </IconButton>
            <Link to={`/chat/${userGip.room_id}`}>
              <IconButton sx={sxBtn}>
                <ChatIcon width="22" height="22" />
              </IconButton>
            </Link>
          </div>
        </div>
        <div className={style.compare__footer}>
          {hasComment ? (
            <div
              className={style.compare__footer__comment}
              onClick={() => setToggleDiv(!toggleDiv)}
            >
              <p>Комментарий</p>
              <ToggleAccordion toggle={toggleDiv} />
            </div>
          ) : (
            <p>Комментарий отсутствует</p>
          )}
          <div className={style.compare__footer__price}>
            <p>без НДС</p>
            <h2>
              {trimNumber(userGip.price)} <span>₽</span>
            </h2>
          </div>
        </div>
      </div>
      {toggleDiv && hasComment && (
        <div className={style.compare__comment}>
          <p>Комментарий</p>
          <p>{userGip.task.content}</p>
        </div>
      )}
    </div>
  );
});
