import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiPartGroupResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  groupId: number;
  projectId: number;
}>;

export const getProjectPartByGroup = (builder: QueryBuilder) =>
  builder.query<ApiPartGroupResponses.View[], TParams>({
    query: (params) => ({
      url: `v1/part-group/get-project-part-by-group`,
      params: {
        id: params.groupId,
        projectId: params.projectId,
      },
    }),
  });
