import React from "react";
import { Control, Controller } from "react-hook-form";
import { DateField } from "src/FSD/shared/uiKit/v2";
import styles from "./DateStartAndLimit.module.scss";
import { TFieldValues } from "../lib/hooks/usePublishContent";

type TProp = {
  control: Control<TFieldValues>;
  errors: any;
};

export const DateStartAndLimit = ({ control, errors }: TProp) => {
  return (
    <div className={styles.date}>
      <div className={styles.date_start}>
        <Controller
          control={control}
          name="dateStart"
          render={({ field: { value, onChange, name } }) => (
            <DateField
              label="Начальный срок"
              startDateProp={value}
              changeDateHandler={onChange}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
      </div>
      <div className={styles.date_end}>
        <Controller
          control={control}
          name="dateLimit"
          render={({ field: { value, onChange, name } }) => (
            <DateField
              label="Конечный срок"
              changeDateHandler={onChange}
              startDateProp={value}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />
      </div>
    </div>
  );
};
