import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import {
  index,
  getProviderFirms,
  deleteMaterial,
  pullRequest,
  create,
  getTemplateList,
  editFormColumns,
  selectRequest,
  viewFormColumns,
} from "./queries";

export const materialProcessApi = rootApi.injectEndpoints({
  endpoints: (builder: QueryBuilder) => ({
    materialProcessIndex: index(builder),
    materialProcessGetProviderFirms: getProviderFirms(builder),
    materialProcessDeleteMaterial: deleteMaterial(builder),
    materialProcessPullRequest: pullRequest(builder),
    materialProcessCreate: create(builder),
    materialProcessGetTemplateList: getTemplateList(builder),
    materialProcessEditFormColumns: editFormColumns(builder),
    materialProcessSelectRequest: selectRequest(builder),
    materialProcessViewFormColumns: viewFormColumns(builder),
  }),
  overrideExisting: false,
});

export const {
  useMaterialProcessIndexQuery,
  useMaterialProcessGetProviderFirmsQuery,
  useMaterialProcessDeleteMaterialMutation,
  useMaterialProcessPullRequestMutation,
  useMaterialProcessCreateMutation,
  useMaterialProcessGetTemplateListQuery,
  useLazyMaterialProcessGetTemplateListQuery,
  useMaterialProcessEditFormColumnsQuery,
  useMaterialProcessSelectRequestMutation,
  useMaterialProcessViewFormColumnsQuery,
} = materialProcessApi;
