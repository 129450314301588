import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/app/store";
import {
  fetchAcceptTask,
  fetchBackToWork,
  fetchCommentsByTaskId,
  fetchCompleteTask,
  fetchNotAcceptTask,
  fetchProjectProcessType,
  fetchProjectTaskAll,
  fetchProjectTaskInTree,
  fetchProjectTaskOutTree,
  fetchTaskDateDeadlineUpdate,
  TaskValueType,
  TProjectProcessType,
} from "./thunks";
import { AllTaskValueType, CountsType, StatusType } from "./types";

type TTaskComment = {
  author: string;
  text: string;
  date: string;
  status: string;
};

interface InitialStateProjectView {
  inTasks: TaskValueType[] | null;
  outTasks: TaskValueType[] | null;
  allTasks: [string, AllTaskValueType][];
  status: StatusType;
  counts: CountsType;
  processToggle: any;
  projectToggle: any;
  isAllToggle: boolean;
  isLoad: boolean;
  fullPageLoad: boolean;
  bodyLoad: boolean;
  error: any;
  processType: TProjectProcessType;
  isLoadProcessType: boolean;
  isLoadDateUpdate: boolean;
  isLoadComments: boolean;
  comments: TTaskComment[];
}
const initialState: InitialStateProjectView = {
  inTasks: null,
  outTasks: null,
  isLoad: false,
  fullPageLoad: false,
  bodyLoad: false,
  processType: {},
  processToggle: {},
  projectToggle: {},
  isAllToggle: false,
  isLoadProcessType: false,
  isLoadDateUpdate: false,
  isLoadComments: false,
  error: null,
  allTasks: [],
  counts: {
    total: 0,
    done: 0,
    check: 0,
    work: 0,
  },
  status: {
    check: false,
    done: false,
    work: false,
  },
  comments: [],
};

const projectTaskSlice = createSlice({
  name: "projectTaskSlice",
  initialState,
  reducers: {
    setToggleProcess: (state, action) => {
      const index = action.payload;
      if (state.processToggle[index] !== undefined) {
        state.processToggle[index] = undefined;
      } else {
        state.processToggle[index] = index;
      }
    },
    setToggleProject: (state, action) => {
      const index = action.payload;
      if (state.projectToggle[index] !== undefined) {
        state.projectToggle[index] = undefined;
      } else {
        state.projectToggle[index] = index;
      }
    },
    setAllToggle(state, action) {
      const newProcess: any = {};
      const newProject: any = {};
      Object.keys(state.processToggle).forEach((key) => {
        newProcess[key] = action.payload;
      });
      Object.keys(state.projectToggle).forEach((key) => {
        newProject[key] = action.payload;
      });
      state.processToggle = newProcess;
      state.projectToggle = newProject;
      state.isAllToggle = action.payload;
    },
    setError(state) {
      state.error = false;
    },
    setStatus(state, action: PayloadAction<StatusType>) {
      state.status = action.payload;
    },
    removeTaskComments(state) {
      state.comments = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectTaskInTree.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectTaskInTree.fulfilled, (state, action) => {
      state.isLoad = false;
      state.inTasks = Object.values(action.payload);
    });
    builder.addCase(fetchProjectTaskInTree.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectTaskOutTree.pending, (state) => {
      state.isLoad = true;
    });
    builder.addCase(fetchProjectTaskOutTree.fulfilled, (state, action) => {
      state.isLoad = false;
      state.outTasks = Object.values(action.payload);
    });
    builder.addCase(fetchProjectTaskOutTree.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectTaskAll.pending, (state, action) => {
      const { load } = action.meta.arg;
      if (load === "fullPageLoad") {
        state.fullPageLoad = true;
      }
      if (load === "bodyLoad") {
        state.bodyLoad = true;
      }
    });
    builder.addCase(fetchProjectTaskAll.fulfilled, (state, action) => {
      state.isLoad = false;
      state.fullPageLoad = false;
      state.bodyLoad = false;
      state.counts = action.payload.counts;
      const entriesData = Object.entries(action.payload.data);
      const initialProcessToggle: any = {};
      const initialProjectToggle: any = {};
      entriesData.forEach(([key, value]) => {
        initialProcessToggle[key] = undefined;
        Object.values(value.projects).forEach((project, index) => {
          initialProjectToggle[+key + index] = undefined;
        });
      });
      state.allTasks = entriesData;
      state.processToggle = initialProcessToggle;
      state.projectToggle = initialProjectToggle;
    });
    builder.addCase(fetchProjectTaskAll.rejected, (state, action) => {
      state.isLoad = false;
      state.fullPageLoad = false;
      state.bodyLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchAcceptTask.pending, (state) => {
      state.isLoad = true;
      state.error = null;
    });
    builder.addCase(fetchAcceptTask.fulfilled, (state) => {
      state.isLoad = false;
    });
    builder.addCase(fetchAcceptTask.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchCompleteTask.pending, (state) => {
      state.isLoad = true;
      state.error = null;
    });
    builder.addCase(fetchCompleteTask.fulfilled, (state) => {
      state.isLoad = false;
    });
    builder.addCase(fetchCompleteTask.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchNotAcceptTask.pending, (state) => {
      state.isLoad = true;
      state.error = null;
    });
    builder.addCase(fetchNotAcceptTask.fulfilled, (state) => {
      state.isLoad = false;
    });
    builder.addCase(fetchNotAcceptTask.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchBackToWork.pending, (state) => {
      state.isLoad = true;
      state.error = null;
    });
    builder.addCase(fetchBackToWork.fulfilled, (state) => {
      state.isLoad = false;
    });
    builder.addCase(fetchBackToWork.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectProcessType.pending, (state) => {
      state.isLoadProcessType = true;
      state.error = null;
    });
    builder.addCase(fetchProjectProcessType.fulfilled, (state, action) => {
      state.isLoadProcessType = false;
      state.processType = action.payload;
    });
    builder.addCase(fetchProjectProcessType.rejected, (state, action) => {
      state.isLoadProcessType = false;
      state.error = action.payload;
    });
    builder.addCase(fetchTaskDateDeadlineUpdate.pending, (state) => {
      state.isLoadDateUpdate = true;
    });
    builder.addCase(fetchTaskDateDeadlineUpdate.fulfilled, (state) => {
      state.isLoadDateUpdate = false;
    });
    builder.addCase(fetchTaskDateDeadlineUpdate.rejected, (state, action) => {
      state.isLoadDateUpdate = false;
      state.error = action.payload;
    });
    builder.addCase(fetchCommentsByTaskId.pending, (state) => {
      state.isLoadComments = true;
    });
    builder.addCase(fetchCommentsByTaskId.fulfilled, (state, action) => {
      state.isLoadComments = false;
      state.comments = action.payload.map(
        ({ author, date_create, status, text }: any) => {
          return {
            author,
            text,
            date: date_create?.substr(0, 10),
            status: status?.value,
          };
        }
      );
    });
    builder.addCase(fetchCommentsByTaskId.rejected, (state, action) => {
      state.isLoadComments = false;
      state.error = action.payload;
    });
  },
});
export const {
  setError,
  setStatus,
  setToggleProcess,
  setToggleProject,
  setAllToggle,
  removeTaskComments,
} = projectTaskSlice.actions;
export const getProjectTasks = (state: RootState) => state.projectTask;
export default projectTaskSlice.reducer;
