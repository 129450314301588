export enum ProjectProcess {
  VIEW = "ProjectProcess.View",
  CAN_CHECK = "ProjectProcess.CanCheck",
  GET_WORKS = "ProjectProcess.GetWorks",
  GET_IN_TASKS = "ProjectProcess.GetInTasks",
  GET_OUT_TASKS = "ProjectProcess.GetOutTasks",
  GET_DOCUMENTATIONS = "ProjectProcess.GetDocumentations",
  REMOVED = "ProjectProcess.Removed",
  APPLICATION = "ProjectProcess.Application",
  GET_PROJECT_PROCESS_TREE = "ProjectProcess.GetProjectProcessTree",
  GET_FILES_DTO = "ProjectProcess.GetFilesDto",
  GET_SUB_CHECK_TASKS = "ProjectProcess.GetSubCheckTasks",
  GET_GROUP_PUBLISH_PROCESS = "ProjectProcess.GetGroupPublishProcess",
}
