import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./Header.module.scss";
import { CountsType } from "../../../../../../app/feature/ProjectView/TaskReducer/types";
import { getProjectTasks } from "../../../../../../app/feature/ProjectView/TaskReducer/projectTask";
import { useAppDispatch } from "../../../../../../app/store";
import { fetchProjectTaskAll } from "../../../../../../app/feature/ProjectView/TaskReducer/thunks";
import { HeaderCount } from "../../../../../newUI/HeaderCount/HeaderCount";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";

interface HeaderProps {
  counts: CountsType;
}

export const Header = ({ counts }: HeaderProps) => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { status } = useSelector(getProjectTasks);
  const { project } = useSelector(getProjectViewData);
  async function setStatusWork() {
    if (params.id) {
      if (!status.work) {
        dispatch(
          fetchProjectTaskAll({
            id: +params.id,
            status: "2",
            load: "bodyLoad",
          })
        );
      } else {
        dispatch(
          fetchProjectTaskAll({ id: +params.id, status: "", load: "bodyLoad" })
        );
      }
    }
  }

  function setStatusDone() {
    if (params.id) {
      if (!status.done) {
        dispatch(
          fetchProjectTaskAll({
            id: +params.id,
            status: "3",
            load: "bodyLoad",
          })
        );
      } else {
        dispatch(
          fetchProjectTaskAll({ id: +params.id, status: "", load: "bodyLoad" })
        );
      }
    }
  }

  function setStatusCheck() {
    if (params.id) {
      if (!status.check) {
        dispatch(
          fetchProjectTaskAll({
            id: +params.id,
            status: "6",
            load: "bodyLoad",
          })
        );
      } else {
        dispatch(
          fetchProjectTaskAll({ id: +params.id, status: "", load: "bodyLoad" })
        );
      }
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.header_left}>
        <div className={styles.total}>
          <h3>Задания</h3>
          <span>{`(всего ${counts?.total})`}</span>
        </div>
        <div className={styles.counts}>
          <HeaderCount
            status={status.work}
            count={counts.work}
            setStatus={setStatusWork}
            text={"В работе"}
          />
          <HeaderCount
            status={status.check}
            count={counts.check}
            setStatus={setStatusCheck}
            text={"На проверке"}
          />
          <HeaderCount
            status={status.done}
            count={counts.done}
            setStatus={setStatusDone}
            text={"Выполненные"}
          />
        </div>
      </div>
      {!project?.authorPremium && (
        <div className={styles.header_right}>
          <p>
            Внимание! У заказчика истекла подписка ПИРC
            <span style={{ fontWeight: 800 }}>PRO</span>, функционал ограничен,
            вы не можете взаимодействовать с вкладкой “Задания”. Обратитесь к
            автору проекта для решения вопроса.
          </p>
        </div>
      )}
    </div>
  );
};
