import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiPrimarySourceDataType } from "src/types/api/primaryTypes/apiPrimarySourceDataType";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  projectId: number;
  parentId: number;
  type?: number;
  searchValue?: string;
}>;

const expandSource = `checklist,status,date_create,date_deadline,date_complete,can.download`;

export const index = (builder: QueryBuilder) =>
  builder.query<ApiPrimarySourceDataType[], TParams>({
    query: (params) => ({
      url: `v1/project-source-data/index`,
      params: {
        "per-page": 30,
        "ProjectSourceDataSearch[project_id]": params.projectId,
        "ProjectSourceDataSearch[parentId]": params.parentId,
        expand: expandSource,
        ...(params.type && { "ProjectSourceDataSearch[type]": params.type }),
        ...(params.searchValue && {
          "ProjectSourceDataSearch[checklistName]": params.searchValue,
        }),
      },
    }),
    providesTags: [ApiTags.ProjectSourceData.INDEX_QUERY],
  });
