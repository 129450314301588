import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Select } from "src/FSD/shared/uiKit/v2/Select";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useCheckFreeTask } from "../lib/hooks/useCheckFreeTask";

type TProps = {
  taskId: number;
};

export const CheckFreeTaskForm = ({ taskId }: TProps) => {
  const {
    task,
    isTaskViewPending,
    register,
    setValue,
    errors,
    handleSubmit,
    onSubmit,
  } = useCheckFreeTask(taskId);

  return (
    <Modal.Layout>
      <Modal.Header>Проверка оперативной задачи</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Select value={task.project} label="Проект" disabled />
          <TextField
            value={task.content}
            label="Текст задачи"
            disabled
            minRows={3}
            multiline
          />
          <Select value={task.executor} label="Исполнитель" disabled />
          <TextField
            {...register("comment")}
            label="Комментарий"
            minRows={2}
            multiline
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
              type="submit"
              color="error"
              variant="outlined"
            >
              Отклонить
            </Button>
            <Button
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
              type="submit"
              color="primary"
            >
              Принять
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
