import { UseFormReturn } from "react-hook-form";
import { TFieldValues } from "../../model/fieldValues";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";

type TPriceSum = {
  ndsText: string;
  price: number;
};

export const getColumnPriceSum = (
  userTypeId: number,
  processTypeId: number,
  applicationsGroup: ApiProjectResponses.GetApplications[0],
  form: UseFormReturn<TFieldValues>
) => {
  return Object.values(
    applicationsGroup.userTypes[userTypeId].appProcesses
  ).reduce(
    (result: TPriceSum, appProcess) => {
      const isProcessChosen = form.watch(
        `${processTypeId}.${appProcess.process.id}.${userTypeId}`
      );

      if (
        appProcess.process.projectPart.type.key !== processTypeId ||
        !isProcessChosen
      ) {
        return result;
      }

      return {
        price: result.price + appProcess.price,
        ndsText:
          result.ndsText === "без НДС" ? appProcess.ndsText : result.ndsText,
      };
    },
    {
      ndsText: "без НДС",
      price: 0,
    }
  );
};
