import React, { useEffect } from "react";
import { Preloader } from "../Preloader/Preloader";

interface RedirectToExternalProps {
  to: string;
}

export const RedirectToExternal: React.FC<RedirectToExternalProps> = ({
  to = "",
}) => {
  useEffect(() => {
    window.location.href = `${to}`;
  }, []);

  return <Preloader />;
};
