import { TabsSourceData } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabsSourceData/TabsSourceData";
import { TabDataByProcess } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabDataByProcess";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import formSourceData from "src/app/feature/ProjectProcessView/InitialData/utils/formSourceData";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiProjectResponses } from "../../../../../../entities/project/model/responseTypes";

interface TProps {
  activeTab: number;
  data: ApiProjectProcessResponses.GetFilesDto;
  canProjectProcess: ApiProjectProcessResponses.View["can"];
  canProject: ApiProjectResponses.View["can"];
  searchValue?: string;
  projectParentId: number;
}

export const SourceDataContent = ({
  activeTab,
  data,
  canProject,
  searchValue,
  canProjectProcess,
  projectParentId,
}: TProps) => {
  if (activeTab === 5) {
    return <TabDataByProcess data={data} />;
  }

  const sourceData = formSourceData(data?.newSourceData ?? {});
  const newSourceData = sourceData?.[activeTab] || [];

  return (
    <ListMapper>
      {newSourceData.map((el, index) => (
        <TabsSourceData
          key={el.name}
          {...el}
          index={index}
          type={Number(activeTab)}
          canProjectProcess={canProjectProcess}
          canProject={canProject}
          projectParentId={projectParentId}
          searchValue={searchValue}
        />
      ))}
    </ListMapper>
  );
};
