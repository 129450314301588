import { palette } from "../../../../../../../styles/restyle";
import { TPropsObject } from "../hooks/useObjects";

export const formStageObject = ({ object }: TPropsObject) => {
  return Object.entries(object.stage).map(([key, value]: any) => ({
    label: key,
    work: value.work,
    done: value.done,
    color: value.enable ? palette.darkTelegraph : palette.persianGreen,
    showStage: value.work || value.done || value.enable,
  }));
};

export const areAllStagesValuesFalse = ({ object }: TPropsObject) => {
  const stages = formStageObject({ object });

  return stages.every((stage) => !stage.showStage);
};
