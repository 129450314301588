import {
  useCommentCreateMutation,
  useCommentIndexQuery,
} from "src/FSD/entities/expertise/comment/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { expertiseCommentCreateSchema } from "../schemas/expertiseCommentCreate";

type TProps = {
  rowId: number;
};

type TInputs = {
  text: string;
};

export const useExpertiseCommentCreate = ({ rowId }: TProps) => {
  const { data: comments = [], isFetching } = useCommentIndexQuery(
    {
      id: rowId,
    },
    { skip: !rowId }
  );

  const [commentCreate, { isLoading }] = useCommentCreateMutation();

  const isLoadingComment = !comments || isFetching;
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(expertiseCommentCreateSchema),
    mode: "onSubmit",
  });

  async function handleSubmitForm(dataValues: TInputs) {
    const data = {
      text: dataValues.text,
    };

    await commentCreate({ remarkRowId: rowId, data })
      .unwrap()
      .then(() => {
        handleClose();
      });
  }

  return {
    isLoading,
    isLoadingComment,
    handleSubmit,
    control,
    errors,
    handleSubmitForm,
    comments,
  };
};
