import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { processControlsInitialState } from "./processControlsInitialState";
import C from "./constants";
import {
  createData,
  setToggleOpen,
  setProcessChecked,
  setOpenStateFromStorage,
  setProcessUnchecked,
} from "./createData/createData";
import { StateType } from "../../initialState";
import { ApiPrimaryProjectPartTree } from "../../../types/api/primaryTypes/apiPrimaryProjectPartTree";

interface ProcessID<T = string> {
  id: T;
}
interface ProcessesIds<T> {
  arrayIds: T[];
}

export const processControlsPage = createReducer(
  processControlsInitialState,
  (builder) => {
    builder.addCase(
      C.SET_PROJECT_CONTROLS_DATA,
      (state, action: PayloadAction<ApiPrimaryProjectPartTree>) => {
        return {
          ...state,
          data: createData(action.payload.data),
        };
      }
    );

    builder.addCase(
      C.SET_INITIAL_CHECKED,
      (
        state,
        action: PayloadAction<{
          data: {
            disabled: any;
            checked: any;
          };
        }>
      ) => {
        return {
          ...state,
          // @ts-ignore
          disabled: action.payload.data.disabled,
          // @ts-ignore
          checked: action.payload.data.checked,
        };
      }
    );

    builder.addCase(C.SET_CLEAR_CONTROLS_DATA, (state) => {
      return {
        ...state,
        data: [],
        disabled: [],
        checked: [],
      };
    });

    builder.addCase(
      C.SET_OPENED_SECTION_FROM_STORAGE,
      (state, action: PayloadAction<ProcessesIds<string>>) => {
        return {
          ...state,
          data: setOpenStateFromStorage(state.data, action.payload.arrayIds),
        };
      }
    );

    builder.addCase(
      C.SET_PROCESS_TOGGLE_OPEN,
      (state, action: PayloadAction<ProcessID>) => {
        return {
          ...state,
          data: setToggleOpen(state.data, action.payload.id),
        };
      }
    );

    builder.addCase(
      C.SET_PROCESS_CHECKED,
      (state, action: PayloadAction<ProcessID>) => {
        return setProcessChecked(state, state.checked, action.payload.id);
      }
    );

    builder.addCase(
      C.SET_PROCESS_UNCHECKED,
      (state, action: PayloadAction<ProcessID>) => {
        return setProcessUnchecked(state, state.checked, action.payload.id);
      }
    );

    builder.addCase(C.SET_CLEAR_CHECKED, (state) => {
      return {
        ...state,
        checked: [],
      };
    });

    builder.addCase(
      C.SET_INITIAL_EXPERTISE_TYPE,
      (state, action: PayloadAction<{ expertiseType: string }>) => {
        return {
          ...state,
          expertiseType: action.payload.expertiseType,
        };
      }
    );
  }
);

export const setProjectControls = (
  data: ApiPrimaryProjectPartTree["data"]
) => ({
  type: C.SET_PROJECT_CONTROLS_DATA,
  payload: {
    data,
  },
});

export const setInitialChecked = createAction(
  C.SET_INITIAL_CHECKED,
  (action) => ({
    payload: {
      data: {
        disabled: action.data.disabled,
        checked: action.data.checked,
      },
    },
  })
);

export const setOpenedSectionsFromState = createAction(
  C.SET_OPENED_SECTION_FROM_STORAGE,
  (action) => ({
    payload: {
      arrayIds: action.arrayIds,
    },
  })
);

// переключение состояния всех раскрывающихся разделов
export const setOpenToggleProcessAction = createAction(
  C.SET_PROCESS_TOGGLE_OPEN,
  (action) => ({
    payload: {
      id: action.id,
    },
  })
);

export const uncheckedAction = createAction(
  C.SET_PROCESS_UNCHECKED,
  (action) => ({
    payload: {
      id: action.id,
    },
  })
);

export const checkedAction = createAction(C.SET_PROCESS_CHECKED, (action) => ({
  payload: {
    id: action.id,
  },
}));

export const setInitialExpertiseType = createAction(
  C.SET_INITIAL_EXPERTISE_TYPE,
  (action) => ({
    payload: {
      expertiseType: action.expertiseType,
    },
  })
);

export const clearControlsData = createAction(C.SET_CLEAR_CONTROLS_DATA);

export const setClearChecked = createAction(C.SET_CLEAR_CHECKED);

export const selectorProjectControls = (state: StateType) =>
  state.projectProcessControls.data;

export const selectorProjectControlsChecked = (state: StateType) =>
  state.projectProcessControls.checked;

export const selectorProjectControlsDisabled = (state: StateType) =>
  state.projectProcessControls.disabled;

export const selectorGetExpertiseType = (state: StateType) =>
  state.projectProcessControls.expertiseType;
