import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ChatLeftText } from "react-bootstrap-icons";
import IconButton from "@mui/material/IconButton";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { palette } from "src/styles/restyle";
import { GipThreeDotBtn } from "./GipThreeDotBtn/GipThreeDotBtn";
import styles from "../GipCard.module.scss";
import { Paragraph } from "../../../../../../../../shared/uiKit/v2";

const sx = {
  color: "#0AA699",
  padding: 0,
  transition: "0.3s ease-in-out 0s!important",
  "&:hover": {
    transform: "scale(1.2)",
  },
};
export const GipInfo = memo(() => {
  const { project } = useSelector(getProjectViewData);
  const gip = project?.gip;
  const canThreeDot =
    project?.can?.completeRemoveGip || project?.can?.requestRemoveGip;

  return (
    <>
      <div className={styles.gip_header}>
        <div>
          <div className={styles.gip_header_flex}>
            {project?.can?.view && (
              <Link to={`/chat/${project?.chatId}`}>
                <IconButton title="Чат" sx={sx}>
                  <ChatLeftText size={20} color={palette.persianGreen} />
                </IconButton>
              </Link>
            )}
            {canThreeDot && <GipThreeDotBtn />}
          </div>
        </div>
        {gip?.id ? (
          <TextLink url={`/user/${gip?.id}`} className={styles.gip_header_name}>
            {gip?.name}
          </TextLink>
        ) : (
          <p className={styles.gip_header_name}>{gip?.name}</p>
        )}
        <Paragraph className={styles.gipType}>{gip?.type}</Paragraph>
      </div>
    </>
  );
});
