import { useMemo, useState } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { FormSteps } from "src/FSD/shared/uiKit/v2/FormSteps";
import { createFormSteps } from "../../lib/helpers/createFormSteps";
import { useGroupRequest } from "../../lib/hooks/useGroupRequest";
import { TFormCache } from "../../model/types/fieldValues";
import { REQUEST_SENDING } from "../../model/constants/formNames";
import { RequestForm } from "./RequestForm";
import { GroupForm } from "../GroupForm";

type Props = {
  processId: number;
  groupName: string;
};

export const GroupRequestForm = ({ processId, groupName }: Props) => {
  const [formCache, setFormCache] = useState<TFormCache>({});
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { groupRequestProcesses, isProcessesLoading } = useGroupRequest({
    processId,
  });

  const formSteps = useMemo(() => {
    if (!groupRequestProcesses) {
      return;
    }

    const groupRequestSteps = createFormSteps(groupRequestProcesses);
    return { ...groupRequestSteps, request: REQUEST_SENDING };
  }, [groupRequestProcesses]);

  const currentFormId = formSteps ? Object.keys(formSteps)[currentPage] : null;

  return (
    <Modal.Layout>
      {isProcessesLoading ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Header>Заявка на разделы «{groupName}»</Modal.Header>
          <FormSteps steps={formSteps!} currentStep={currentFormId!} />
          {currentFormId === "request" ? (
            <RequestForm
              processId={processId}
              setCurrentPage={setCurrentPage}
              formCache={formCache}
              setFormCache={setFormCache}
            />
          ) : (
            <GroupForm
              key={currentFormId!}
              groupPublishProcess={groupRequestProcesses!}
              currentFormId={currentFormId!}
              processId={processId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              formCache={formCache}
              setFormCache={setFormCache}
            />
          )}
        </>
      )}
    </Modal.Layout>
  );
};
