import { ApiResponseTasksPage } from "../../../../../types/api/responseTypes/apiTasksType";
import {
  TasksBtnDataType,
  TasksDataType,
} from "../../../../../types/stateTypes/tasksStateTypes";
import { ApiPrimaryTaskBtnType } from "../../../../../types/api/primaryTypes/apiPrimaryTaskType";
import { getUserNameSurname } from "../../../../services/user/user";
import { getDateAndTime } from "../../../../services/date/date";

type TasksListType = (data: ApiResponseTasksPage["data"]) => TasksDataType[];

type TasksBtnListType = (data: ApiPrimaryTaskBtnType[]) => TasksBtnDataType[];

const btnList: TasksBtnListType = (item) => {
  return item.map((btnItem) => {
    return {
      ...btnItem,
      btnClass: btnItem.btnClass ? btnItem.btnClass : null,
      options: {
        ...btnItem.options,
        dataTarget: btnItem.options["data-target"],
        dataToggle: btnItem.options["data-toggle"],
        dataUri: btnItem.options["data-uri"],
      },
    };
  });
};

const getDataByType = (
  data: ApiResponseTasksPage["data"][number]["data"]
): TasksDataType["data"] => {
  const result: TasksDataType["data"] = {
    firm: null,
    project: null,
    process: null,
    materialProcess: null,
    user: null,
    fromProcess: null,
    taskToCheck: null,
    toProcess: null,
  };

  if (Array.isArray(data)) {
    return result;
  }

  for (const key in result) {
    // @ts-ignore
    if (data[key]) {
      // @ts-ignore
      result[key] = data[key];
    }
  }

  return result;
};

export const createTasksListFromApi: TasksListType = (data) =>
  data.map((item) => {
    return {
      name: item.name,
      content: item.content,
      dateCreate: item.date_create ? getDateAndTime(item.date_create) : null,
      dateComplete: item.date_complete
        ? getDateAndTime(item.date_complete)
        : null,
      dateDeadline: item.date_deadline
        ? getDateAndTime(item.date_deadline)
        : null,
      author: {
        name: getUserNameSurname(item.author),
        id: item.author.id,
      },
      executor: {
        name: getUserNameSurname(item.executor),
        id: item.executor.id,
      },
      status: { key: item.status.key, value: item.status.value },
      taskProcess: item.taskProcess ? { id: item.taskProcess.id } : null,
      btn: btnList(item.btn),
      data: getDataByType(item.data),
      workflowClass: item.workflow_class,
    };
  });
