import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import style from "./style.module.scss";
import { setColorTheme } from "src/app/feature/app/app";
import {
  parsedTokenDataType,
  UserConfigColorThemeType,
} from "src/types/stateTypes/userType";
import { setTestTokens } from "src/app/services/app/storage/cookies";
import { isDevelopment } from "src/app/services/app/app";
import {
  clientLogoutClearAllData,
  parseTokenBody,
} from "src/app/services/auth/auth";

export const DevHeader: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("admin");
  const [isOpen, setOpen] = useState(false);
  const [isRoleReady, setRoleReady] = useState(false);
  const [userToken, setUserToken] = useState<parsedTokenDataType | null>(null);
  const onSetTheme = (color: UserConfigColorThemeType) =>
    dispatch(setColorTheme(color));

  const onSetToken = () => {
    if (localStorage.getItem("testRolesTokens")) {
      if (type === "na") {
        localStorage.removeItem("testRolesTokens");
      }
      const needToken = (
        JSON.parse(localStorage.getItem("testRolesTokens")!) as {
          token: string;
          type: string;
        }[]
      ).filter((item) => item.type === type)[0];
      localStorage.setItem("userToken", needToken.token);
      setRoleReady(true);

      document.location.reload();
    }
  };

  const onLoad = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setRoleReady(false);
    localStorage.removeItem("testRolesTokens");
    if (isDevelopment()) setTestTokens();
  };

  const onClear = () => {
    clientLogoutClearAllData();
    document.location.reload();
  };

  useEffect(() => {
    if (localStorage.getItem("testRolesTokens")!) {
      setRoleReady(true);
    }
  }, []);

  const onToggle = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      setUserToken(parseTokenBody(localStorage.getItem("userToken")!));
    }
  }, []);

  return (
    <>
      {isDevelopment() && (
        <div className={style.wrapper}>
          <div className={style.openBTN} onClick={onToggle} />
          {isOpen && (
            <div className={style.devHeader}>
              <div>
                <p>Theme</p>
                <form action="">
                  <input
                    onChange={(e) =>
                      onSetTheme(e.target.value as UserConfigColorThemeType)
                    }
                    type="radio"
                    value="old"
                    id="old"
                    name="theme"
                    title="old"
                    defaultChecked={true}
                  />
                  <label htmlFor="old"> old</label>
                  <input
                    onChange={(e) =>
                      onSetTheme(e.target.value as UserConfigColorThemeType)
                    }
                    type="radio"
                    value="dark"
                    name="theme"
                    id="dark"
                    title="dark"
                  />
                  <label htmlFor="dark">dark</label>
                  <input
                    onChange={(e) =>
                      onSetTheme(e.target.value as UserConfigColorThemeType)
                    }
                    type="radio"
                    value="light"
                    name="theme"
                    id="white"
                    title="light"
                  />
                  <label htmlFor="white">light</label>
                </form>
              </div>
              <div>
                <p>Роль</p>
                <form>
                  {isRoleReady && (
                    <>
                      <select
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        name="role"
                        id=""
                      >
                        <option value="admin">Админ</option>
                        <option value="author">Заказчик</option>
                        <option value="gip">ГИП</option>
                        <option value="executor">Проектировщик</option>
                        <option value="provider">Поставщик</option>
                      </select>
                      <button type="button" onClick={onSetToken}>
                        Записать
                      </button>
                    </>
                  )}
                  <button onClick={onLoad}>Переподключиться</button>
                  <button onClick={onClear}>Стереть</button>
                </form>
              </div>
              <div style={{ alignItems: "center", display: "flex" }}>
                Пользователь:
                {userToken ? (
                  <>
                    &nbsp;
                    <span>{userToken.data.name}</span>
                    &nbsp;
                    <span>{userToken.data.surname}</span>
                  </>
                ) : (
                  <span>Не назначен</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
