import { StarFill } from "react-bootstrap-icons";
import cn from "classnames";
import styles from "./Rating.module.scss";

type TProps = {
  value: number;
  className?: string;
};

export const RatingStar = ({ value, className }: TProps) => {
  return (
    <div className={cn(styles.basic, className)}>
      <p>{value}</p>
      <StarFill color="orange" />
    </div>
  );
};
