import { EventEmitterEventType } from "../eventEmitterInitialState";
import { StateType } from "../../../initialState";

/*
   Показать попап с сообщением об ошибке
 */
export const showEventErrorReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => {
  return {
    ...state,
    error: {
      isShow: true,
      data: {
        ...state.error.data,
      },
    },
  };
};

/*
   Закрыть попап с сообщением об ошибке
 */
export const closeEventErrorReducer = (
  state: EventEmitterEventType
): EventEmitterEventType => ({
  ...state,
  error: {
    isShow: false,
    data: {
      ...state.error.data,
    },
  },
});

export interface SetMessageActionType {
  type: string;
  message: string | React.ReactElement;
  headerText: string;
}

/*
   Установка значений в папапе ошибок
 */
export const setMessageEventErrorReducer = (
  state: EventEmitterEventType,
  action: SetMessageActionType
): EventEmitterEventType => ({
  ...state,
  error: {
    ...state.error,
    data: {
      ...state.error.data,
      message: action.message,
      headerText: action.headerText,
    },
  },
});

export const selectorEEErrorPopupShow = (state: StateType): boolean => {
  return state.eventEmitter.error.isShow;
};
export const selectorEEErrorMessage = (state: StateType): string => {
  return state.eventEmitter.error.data.message;
};
export const selectorEEErrorHeader = (state: StateType): string => {
  return state.eventEmitter.error.data.headerText;
};
