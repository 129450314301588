import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classes from "./style.module.scss";
import { InfoGip } from "./InfoGip/InfoGip";
import { InfoObject } from "./InfoObject/InfoObject";
import { getProjectViewData } from "../../../../../app/feature/ProjectView/projectView";
import { config } from "../../../../../app.cofig";
import Avatar from "../../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { InfoDescription } from "./InfoDescription/InfoDescription";
import { InfoFiles } from "./InfoFiles/InfoFiles";
import {
  SkeletonGip,
  SkeletonProject,
} from "../../../../UI/components/Project/Skeleton";
import { InfoDesign } from "./InfoDesign/InfoDesign";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { palette } from "../../../../../styles/restyle";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface InfoProps {
  setCurrentTab: (arg: string) => void;
}

const Info: React.FC<InfoProps> = ({ setCurrentTab }) => {
  const {
    project,
    isLoad,
    searchGipStatus,
    cancelSearchGip,
    isLoadGip,
    removeGip,
    removeGipComplete,
    requestGip,
    cancelRequestGip,
  } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useIsMobile(500);

  useEffect(() => {
    if (searchGipStatus.length !== 0) {
      enqueueSnackbar(searchGipStatus, {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelSearchGip) {
      enqueueSnackbar("Поиск ГИП отменен", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGip) {
      enqueueSnackbar("Запрос на снятие ГИПа прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGipComplete) {
      enqueueSnackbar("Запрос на рассмотрение прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (requestGip) {
      enqueueSnackbar("Заявка на ГИП прошла успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelRequestGip) {
      enqueueSnackbar("Заявка на ГИП отозвана", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
  }, [
    searchGipStatus,
    cancelSearchGip,
    removeGip,
    removeGipComplete,
    project,
    requestGip,
    cancelRequestGip,
  ]);

  const data = [
    {
      avatar:
        project?.author?.photo &&
        `${config.localDomain}${project?.author?.photo}`,
      link: `/user/${project?.author?.id}`,
      name: project?.author?.name,
      firm: project?.author?.type,
      role: "Заказчик",
      rating: project?.author?.rating,
      jobDuration: project?.author?.jobDuration,
    },
  ];

  if (isLoad && !searchGipStatus) {
    return <SkeletonProject />;
  }

  if (project) {
    const { files, description } = project;
    return (
      <div className={classes.wrapper}>
        <div className={classes.object}>
          <div className={classes.object_block1}>
            <InfoDesign project={project} />
            {isLoadGip ? (
              <SkeletonGip />
            ) : (
              <InfoGip
                key={project.id}
                project={project}
                setCurrentTab={setCurrentTab}
              />
            )}
          </div>
          <InfoObject project={project} />
        </div>
        <InfoDescription description={description} />
        <InfoFiles files={files} />
        <div className={classes.object_block1}>
          <div className={classNames(classes.basic, classes.author)}>
            <h1>Заказчик</h1>
            <Avatar data={data} projectView />
          </div>
          <div className={classNames(classes.documents, classes.basic)}>
            <div className={classes.documents_btn}>
              <h1>Документ</h1>
              {project.can.downloadDocs && (
                <TextLink
                  url={`/project/download-files?id=${project.id}&withTasks=0`}
                  target={"_blank"}
                  redirect
                >
                  <CustomButton
                    background={palette.green}
                    width={isMobile ? 100 : 160}
                  >
                    Скачать
                  </CustomButton>
                </TextLink>
              )}
            </div>
            {project.can.downloadDocs && (
              <p>{`${project.resultFilesSize[0]} ${project.resultFilesSize[1]}`}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default Info;
