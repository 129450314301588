import React from "react";
import { AppErrorType } from "../../app/feature/appError/appError";
import { PageErrorScreen } from "../pages/ErrorScreen/PageErrorScreen";
import { RequestError } from "../../app/services/errorHandler";
import { removeToken } from "../../app/services/auth/auth";

export interface ErrorState {
  type: number;
}

export type Pages = Record<number, any>;

export interface ErrorHandlerProps {
  children: any;
}

export type Types = Record<number, { type: number }>;

export class ErrorHandler extends React.Component<
  ErrorHandlerProps,
  ErrorState
> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = { type: 0 };
  }

  static getDerivedStateFromError(error: RequestError) {
    const statusError = error.status;
    const types: Types = {
      401: { type: 401 },
      403: { type: 403 },
      404: { type: 404 },
      500: { type: 500 },
    };
    return types[statusError] || { type: 0 };
  }

  componentDidCatch(error: AppErrorType) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    switch (error.status) {
      case 401:
        this.setState({ type: 401 });
        removeToken();
        break;
      case 403:
        this.setState({ type: 403 });
        break;
      case 404:
        this.setState({ type: 404 });
        break;
      case 500:
        this.setState({ type: 500 });
        break;
      default:
        this.setState({ type: 0 });
    }
  }

  pages: Pages = {
    401: (
      <PageErrorScreen
        linkClickHandler={removeToken}
        title={"401"}
        description={"Ошибка авторизации"}
        link={{
          title: "Авторизоваться",
          url: "/site/login",
        }}
      />
    ),
    403: <PageErrorScreen title={"403"} description={"Ошибка доступа"} />,
    404: <PageErrorScreen title={"404"} description={"Страница не найдена!"} />,
    500: (
      <PageErrorScreen
        title={"500"}
        description={"Возникла внутренняя ошибка сервера"}
      />
    ),
  };

  render() {
    return this.pages[this.state.type] || this.props.children;
  }
}
