import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import {
  useProcessWorkUnPublishMutation,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

interface TUsePageProps {
  processWorkId: number;
}

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProcessWorkViewPage = ({ processWorkId }: TUsePageProps) => {
  const { data: processWorkRaw, isLoading } = useProcessWorkViewQuery(
    {
      id: processWorkId,
      expand: [
        "account",
        "files",
        "can",
        "activeAccess.taskRemove",
        "activeAccess.user",
        "activeAccess.userType",
        "project.chatId",
        "tabs",
        "activeContract",
        "task.can",
      ],
    },
    { skip: !processWorkId }
  );

  const [unpublishWork] = useProcessWorkUnPublishMutation();

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const isProcessWorkLoading = !processWorkRaw || isLoading;

  const breadCrumbs = getBreadcrumbs(processWorkRaw!);

  return {
    processWork: processWorkRaw,
    device,
    breadCrumbs,
    isProcessWorkLoading,
  } as const;
};
