import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../api";

export interface ApiGetGip {
  page: number;
}

export const ApiGetGipList = (paramsString: string): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/index-gip${paramsString}&expand=portfolios,jobPlaces,educations,rewards,feedbacks,additionalInfo,rating,gipDoneProjectCount`,
    {}
  );
};
