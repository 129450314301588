import React from "react";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { TextLink } from "../../UI/components/TextLink/TextLink";
import { HomeIcon } from "../SVG";
import trimPanelName from "./utils/trimPanelName";

type TitlePanelProps = {
  primaryText?: string;
  secondaryText?: string;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  isContentLoad: boolean;
  can?: Record<string, boolean>;
  link?: string;
  photo?: string;
  small?: boolean;
  title?: string;
  projectView?: boolean;
  parentName?: string;
  parentId?: number;
};

const TitlePanel: React.FC<TitlePanelProps> = ({
  primaryText,
  secondaryText,
  isContentLoad,
  children,
  can,
  link,
  photo,
  small,
  projectView,
  parentName,
  parentId,
  title,
}) => {
  const primaryTextFormatted = trimPanelName(primaryText, can);

  const buttons = isContentLoad ? (
    <Skeleton variant="rectangular" className={style.controlsSkeleton} />
  ) : (
    <div className={classNames(style.controls, children && style.extraMargin)}>
      {children}
    </div>
  );

  return (
    <div
      className={classNames(style.wrapper, projectView && style.wrapperProject)}
    >
      <div className={style.info}>
        {isContentLoad ? (
          <>
            <Skeleton variant="rectangular" className={style.primarySkeleton} />
            <Skeleton
              variant="rectangular"
              className={style.secondarySkeleton}
            />
          </>
        ) : (
          <div className={photo ? style.contents : ""}>
            {photo && (
              <img
                className={style.image}
                src={photo}
                alt=""
                draggable="false"
              />
            )}
            {parentName && (
              <Link
                to={`/project/${parentId}?tab=info`}
                className={style.parent}
              >
                <div>
                  <HomeIcon />
                </div>
                <p>{parentName}</p>
              </Link>
            )}
            <div className={style.context}>
              {link ? (
                <TextLink url={link} variant={style.primary}>
                  {primaryTextFormatted}
                </TextLink>
              ) : (
                <div className={style.primary} title={title}>
                  {primaryTextFormatted}
                </div>
              )}
              <div className={style.secondary}>{secondaryText}</div>
            </div>
          </div>
        )}
      </div>
      {small ? (
        <div
          className={classNames(
            style.limitedControls,
            projectView && style.projectView
          )}
        >
          {children}
        </div>
      ) : (
        buttons
      )}
    </div>
  );
};

export default TitlePanel;
