import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "src/app.cofig";
import { httpRequestGet } from "src/app/services/api/api";
import { request } from "src/app/services/api/requestHandler";
import { pushError } from "../../errorTrace";
import { createPaginationMeta } from "../../indexPages/createData";

export type TProject = {
  id: number;
  name: string;
  status: number;
  children: number[];
  parentId: number | null;
  dashboardCounts: Record<string, number>;
};

export type TApiGetProjects = {
  userType: number;
  page: number;
  query: string;
  fulfilled: boolean;
};

export const ApiGetParentsProjectsByUserType = ({
  fulfilled,
  query,
  userType,
  page,
}: TApiGetProjects): Promise<Response> => {
  return httpRequestGet(
    `${
      config.localDomain
    }/v1/project/index?ProjectSearch[withCounts]=1&ProjectSearch[userTypeId]=${userType}&ProjectSearch[onlyParents]=1&ProjectSearch[name]=${query}${
      fulfilled
        ? "&ProjectSearch[status]=3"
        : "&ProjectSearch[status][]=1&ProjectSearch[status][]=2&ProjectSearch[status][]=15"
    }&page=${page}&per-page=20&fields=id,name,parent_id,status,code&expand=stopped,haveChilds,dashboardCounts`
  );
};

export const ApiGetProjectById = ({
  userType,
  page,
  id,
}: Record<string, number>): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/project/index?ProjectSearch[withCounts]=1&ProjectSearch[userTypeId]=${userType}&ProjectSearch[parent_id]=${id}&ProjectSearch[status]=<>0&page=${page}&per-page=50&fields=id,name,parent_id,status,code&expand=stopped,haveChilds,childs,childs.haveChilds,dashboardCounts`
  );
};

export const getParentsProjects = createAsyncThunk(
  "dashboard/getParentsProjects",
  async (
    { userType, page, fulfilled, query }: TApiGetProjects,
    { dispatch }
  ) => {
    let response: any = {
      data: [],
      totalCount: 0,
    };
    await request(
      ApiGetParentsProjectsByUserType({ userType, page, fulfilled, query }),
      (data, _, headersValue) => {
        const meta = createPaginationMeta(headersValue);
        const totalCount = Number(meta.paginationAllPageCount);
        response = { data, totalCount };
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const getProject = createAsyncThunk(
  "dashboard/getProject",
  async ({ id, userType }: { id: number; userType: number }, { dispatch }) => {
    let response: any = [];

    await request(
      ApiGetProjectById({ userType, id, page: 1 }),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);
