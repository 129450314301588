import React, { useEffect, useRef, useState, ReactNode } from "react";
import ReactDatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import ru from "date-fns/locale/ru";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import classNames from "classnames";
import moment from "moment";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Nullable } from "src/types/generics";
import { XLg } from "react-bootstrap-icons";
import { getYears } from "./helpers";
import style from "./DateField.module.scss";

type TDateFieldProps = {
  startDateProp?: Nullable<Date>;
  changeDateHandler?: (date: Nullable<Date>) => void;
  changeBlur?: () => void;
  changeFocus?: () => void;
  onSave?: () => void;
  onClose?: () => void;
  minDate?: Nullable<Date>;
  maxDate?: Nullable<Date>;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  helperText?: ReactNode;
  disableClear?: boolean;
  isCorrect?: boolean;
  isLoad?: boolean;
  className?: string;
  placeholderText?: string;
  startWithCurrentYear?: boolean;
  customInput?: ReactNode;
};

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const DateField = ({
  startDateProp = null,
  changeDateHandler = () => {},
  changeBlur = () => {},
  changeFocus = () => {},
  onSave = () => {},
  onClose = () => {},
  minDate,
  maxDate,
  label = "",
  error,
  disabled,
  readOnly,
  helperText,
  disableClear,
  className,
  isCorrect = false,
  startWithCurrentYear = false,
  placeholderText = "дд.мм.гггг",
  isLoad = false,
  customInput,
}: TDateFieldProps) => {
  const [active, setActive] = useState(false);
  const [toClose, setToClose] = useState(false);
  const [startDate, setStartDate] = useState<Nullable<Date>>(startDateProp);
  const refDate = useRef<ReactDatePicker | null>(null);
  const years = getYears(startWithCurrentYear);

  const onChangeDates = (date: Nullable<Date>) => {
    setStartDate(date);
    changeDateHandler(date);
  };

  const handleCloseClick = () => {
    if (refDate.current) {
      setStartDate(startDateProp);
      refDate.current.setOpen(false);
    }
    onClose();
  };

  const saveBtnHandler = () => {
    if (refDate.current) {
      setToClose(true);
      refDate.current.setOpen(false);

      onSave();
    }
  };

  const onReset = () => {
    setStartDate(null);
  };

  useEffect(() => {
    if (startDateProp === null) {
      onReset();
    }

    if (startDateProp) {
      setStartDate(startDateProp);
    }
  }, [startDateProp]);

  useEffect(() => {
    if (refDate.current && toClose && !isLoad) {
      refDate.current.setOpen(false);
    }
  }, [isLoad, toClose]);

  const disabledDate = startDateProp
    ? moment(startDateProp).format("DD.MM.YYYY")
    : "";

  if (disabled) {
    return (
      <div className={classNames(className)}>
        <TextField
          disabled
          label={label}
          value={disabledDate}
          className={classNames(isCorrect && style.correct)}
          error={error}
          helperText={helperText}
        />
      </div>
    );
  }

  return (
    <div
      onBlur={() => {
        setActive(false);
      }}
      className={classNames(style.datePicker, className)}
    >
      {label && (
        <p
          className={classNames(
            style.datePicker__label,
            active && style.datePicker__label_active
          )}
        >
          {label}
        </p>
      )}
      <div className={style.datePickerComponent}>
        <ReactDatePicker
          customInput={customInput}
          className={classNames(
            style.datePickerInput,
            isCorrect && !error && style.correctData
          )}
          ref={refDate}
          locale={ru}
          popperPlacement="bottom"
          autoComplete="off"
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={"dd.MM.yyyy"}
          placeholderText={placeholderText}
          selected={startDate}
          onFocus={() => {
            setActive(true);
            changeFocus();
          }}
          onChange={onChangeDates}
          portalId="root-portal"
          shouldCloseOnSelect={false}
          onBlur={changeBlur}
          isClearable={!disabled && !disableClear}
          disabled={disabled}
          readOnly={readOnly}
          wrapperClassName={style.fullWidth}
          popperClassName={style.datePickerPopper}
          showPopperArrow={false}
          renderCustomHeader={({
            date,
            changeYear,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className={style.head}>
              <div className={style.year}>
                <p>{months[getMonth(date)]}</p>
                <select
                  className={style.select}
                  value={getYear(date)}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className={style.arrows}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    decreaseMonth();
                  }}
                  className={style.button}
                  disabled={prevMonthButtonDisabled}
                >
                  <ChevronLeft className={style.chevron} />
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    increaseMonth();
                  }}
                  className={style.button}
                  disabled={nextMonthButtonDisabled}
                >
                  <ChevronRight className={style.chevron} />
                </button>
              </div>
              <div className={style.close} onClick={handleCloseClick}>
                <XLg />
              </div>
            </div>
          )}
        >
          <Button
            onClick={saveBtnHandler}
            color="primary"
            className={style.btnSave}
            isLoading={isLoad}
          >
            Сохранить
          </Button>
        </ReactDatePicker>
      </div>
      {Boolean(error && helperText) && (
        <div className={style.errorText}>{helperText}</div>
      )}
    </div>
  );
};
