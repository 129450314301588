import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { useModalExecutorUpdate } from "src/FSD/entities/publishedObject/lib/hooks/useModalExecutorUpdate";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useModalsRequestRemoveGip } from "src/FSD/entities/project/lib/hooks/useModalRequestRemoveGip";
import { TabChildrenHeader } from "../../../TabsPanel";
import { useObjects } from "../lib/hooks/useObjects";
import { Object } from "./Object";
import ProjectModals from "../../modals";
import ProjectAccessModals from "../../../projectAccess/modals";
import styles from "./Object.module.scss";

type TProps = {
  projectId: number;
  isEdit?: boolean;
};

export const Objects = ({ projectId, isEdit = false }: TProps) => {
  const { isObjectsLoading, objects, handleCreateSubProject } =
    useObjects(projectId);

  const media = isEdit && (
    <Button onClick={handleCreateSubProject}>Добавить объект</Button>
  );

  const { requestRemoveGip, handleCloseRequestRemoveGip } =
    useModalsRequestRemoveGip();

  const { executorUpdate, handleCloseExecutorUpdate } =
    useModalExecutorUpdate();

  return (
    <>
      <TabChildrenHeader
        title="Объекты"
        media={media}
        className={styles.tabHeader}
      />
      <List maxHeight="860px">
        {isObjectsLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {objects.map((object: ApiProjectResponses.ChildProject) => (
              <Object key={object.id} object={object} />
            ))}
          </ListMapper>
        )}
      </List>

      <Modal
        isActive={executorUpdate.isOpen}
        handleClose={handleCloseExecutorUpdate}
      >
        <ProjectAccessModals.UpdateAccessForm
          id={executorUpdate.projectId!}
          header="Редактировать ГИП"
          type={ProjectAccessControllingEntities.PROJECT}
        />
      </Modal>
      <Modal
        isActive={requestRemoveGip.isOpen}
        handleClose={handleCloseRequestRemoveGip}
      >
        <ProjectModals.RequestRemoveGipForm
          projectId={requestRemoveGip.projectId!}
          header={"Заявка на снятие ГИП проекта"}
        />
      </Modal>
    </>
  );
};
