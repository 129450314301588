import React, { useState } from "react";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Dot } from "src/FSD/shared/uiKit/v2/Dot/Dot";
import { ChatLeftText } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { palette } from "src/styles/restyle";
import { ExchangeTasksVersions } from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/ui/ExchangeTasksVersions";
import { ExchangeVersionsCount } from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/ui/ExchangeVersionsCount";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { statusGroup } from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/lib/helpers/statusGroup";
import { isBeforeDate } from "src/FSD/shared/lib/helpers/isBeforeDate";
import classNames from "classnames";
import styles from "./ExchangeTasks.module.scss";

interface TProps {
  tasks: Record<string, ApiProjectProcessResponses.TaskGroup>;
}

export const ExchangeTasksItems = ({ tasks }: TProps) => {
  const data = Object.values(tasks);
  const [showStates, setShowStates] = useState<boolean[]>(
    Array(data.length).fill(false)
  );

  const handleShowContent = (index: number) => {
    const newShowStates = [...showStates];
    newShowStates[index] = !newShowStates[index];
    setShowStates(newShowStates);
  };

  return (
    <div className={styles.list__item}>
      {data.map(
        (
          {
            header,
            executorName,
            chatRoomId,
            taskStatusInfo: { date, dot, statusString, bgClass, status },
            tasks,
          },
          index
        ) => {
          const dateHasExpired = isBeforeDate(date);
          const errorStatus = bgClass === "pirs-red";
          const grayStatus = bgClass === "pirs-gray" && dateHasExpired;
          const show = showStates[index];
          const tasksCount = tasks ? tasks.length : 0;
          const beforeText = status === Statuses.WORK ? "до " : "";

          return (
            <div
              key={index}
              className={classNames(styles.item, {
                [styles.item_error]: errorStatus,
                [styles.item_gray]: grayStatus,
                [styles.item_show]: show,
              })}
            >
              <div className={styles.item__header}>
                <div>
                  <div className={styles.item__title}>
                    <Dot dot={dot} />
                    {header}
                  </div>
                  <div className={styles.item__desc}>{executorName}</div>
                </div>
                <div>
                  <div className={styles.item__status}>
                    <div className={styles.label}>
                      {statusGroup(status, statusString)}
                    </div>
                    <div
                      className={classNames(styles.item__desc, {
                        [styles.item__desc_error]: errorStatus,
                      })}
                    >
                      {beforeText}
                      {date}
                    </div>
                  </div>
                </div>
                <div>
                  <ExchangeVersionsCount
                    open={showStates[index]}
                    count={tasksCount}
                    onClick={() => handleShowContent(index)}
                  />
                </div>
                <div></div>
                <div className={styles.actions}>
                  {chatRoomId && (
                    <IconButton
                      title="Чат"
                      component={Link}
                      to={`/chat/${chatRoomId}`}
                    >
                      <ChatLeftText size={22} color={palette.silverGrey} />
                    </IconButton>
                  )}
                </div>
              </div>
              <ExchangeTasksVersions show={show} tasks={tasks} />
            </div>
          );
        }
      )}
    </div>
  );
};
