import { UseFormReturn } from "react-hook-form";
import { daysBetweenDates } from "src/FSD/shared/lib/helpers/daysBetweenDates";
import { daysFromToday } from "src/FSD/shared/lib/helpers/daysFromToday";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { ErrorMessage } from "../../model/constants/errorMesages";
import { TGroupFieldValues } from "../../model/types/fieldValues";

export const checkCommonRow = (form: UseFormReturn<TGroupFieldValues>) => {
  const { clearErrors, getValues, setError } = form;

  clearErrors([`common.dateStart`, `common.dateLimit`, `common.price`]);

  const dateStart = getValues(`common.dateStart`);
  const dateLimit = getValues(`common.dateLimit`);
  const price = Number(getValues(`common.price`));

  if (price && Number.isNaN(price)) {
    setError(`common.price`, {
      message: ErrorMessage.PRICE_IS_INT,
    });
  }

  const stringDateStart = toServerDate(dateStart);
  const stringDateLimit = toServerDate(dateLimit);

  const dateStartDifferneceFromToday = daysFromToday(stringDateStart!);
  const dateLimitDifferneceFromToday = daysFromToday(stringDateLimit!);

  if (dateStartDifferneceFromToday < 0) {
    setError(`common.dateStart`, {
      message: ErrorMessage.DATE_IS_NEXT,
    });
  }

  if (dateLimitDifferneceFromToday < 0) {
    setError(`common.dateLimit`, {
      message: ErrorMessage.DATE_IS_NEXT,
    });
  }

  const datesRange = daysBetweenDates(
    stringDateStart!,
    stringDateLimit!,
    "DD.MM.YYYY"
  );

  if (datesRange < 0) {
    setError(`common.dateStart`, {
      message: ErrorMessage.DATE_RANGE,
    });
    setError(`common.dateLimit`, {});
  }

  if (datesRange < 8) {
    setError(`common.dateStart`, {
      message: ErrorMessage.MIN_DATE_RANGE_8_DAYS,
    });
    setError(`common.dateLimit`, {});
  }
};
