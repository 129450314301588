import { useConclusionIndexQuery } from "src/FSD/entities/expertise/conclusion/api";

interface TPops {
  projectProcessId: number;
}

export const useExpertiseConclusion = ({ projectProcessId }: TPops) => {
  const { data: expertiseConclusion = [], isFetching } =
    useConclusionIndexQuery(
      {
        id: projectProcessId,
        expand: ["file"],
      },
      { skip: !projectProcessId }
    );

  const isLoading = isFetching || !expertiseConclusion;

  return { expertiseConclusion, isLoading } as const;
};
