import { ReactNode } from "react";
import TooltipMui, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import cn from "classnames";
import styles from "./Tooltip.module.scss";

type TProps = Omit<TooltipProps, "title"> & {
  title?: ReactNode;
  content?: ReactNode;
  className?: string;
  color?: "error" | "warning";
  classNamePopper?: string;
};

export const Tooltip = ({
  children,
  title,
  content,
  className,
  color,
  classNamePopper,
  ...props
}: TProps) => {
  return (
    <TooltipMui
      title={
        <div className={className}>
          {title}
          {content && (
            <span className={color && styles[color]}>
              {Boolean(title) && <>&nbsp;</>}
              {content}
            </span>
          )}
        </div>
      }
      classes={{
        popper: cn(styles.popper, classNamePopper),
        tooltip: styles.tooltip,
        [`& .${tooltipClasses.tooltip}`]: {
          width: "500px",
        },
      }}
      {...props}
    >
      <div>{children}</div>
    </TooltipMui>
  );
};
