import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessSourceData } from "../../../../services/api/projectProcess/initialData";
import { ProjectProcessSourceDataChild } from "../types/projectProcessInitialDataTypes";

interface ProjectProcessSourceDataParams {
  item: ProjectProcessSourceDataChild[];
  index: number;
}
export const fetchProjectProcessSourceData = createAsyncThunk<
  ProjectProcessSourceDataParams,
  { projectId: number; parentId: number; index: number; type?: number }
>(
  "projectProcess/fetchProjectProcessSourceData",
  async ({ projectId, parentId, index, type }, { dispatch }) => {
    const response: any = {
      item: null,
      index,
    };
    await request(
      ApiProjectProcessSourceData(projectId, parentId, type),
      (data) => {
        response.item = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);

export const fetchProjectProcessSourceDataDuplicate = createAsyncThunk<
  ProjectProcessSourceDataParams,
  { projectId: number; parentId: number; index: number; type?: number }
>(
  "projectProcess/fetchProjectProcessSourceDataDuplicate",
  async ({ projectId, parentId, index, type }, { dispatch }) => {
    const response: any = {
      item: null,
      index,
    };
    await request(
      ApiProjectProcessSourceData(projectId, parentId, type),
      (data) => {
        response.item = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
