import React, { memo } from "react";
import moment from "moment";
import { IconButton } from "@mui/material";
import {
  PencilSquare,
  PlusSquareFill,
  XSquareFill,
} from "react-bootstrap-icons";
import { RowsType } from "src/app/feature/ProjectProcessView/Expertise/types/projectProcessExpertiseTypes";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { iconBtnSx } from "src/styles/restyle";
import { selectColor } from "src/utils/selectColor";
import { fetchProjectProcessExpertise } from "src/app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchBackToWork } from "src/app/feature/ProjectView/TaskReducer/thunks";
import cls from "../RemarkTable.module.scss";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";

export type TModalMode = "complete" | "edit" | null;

interface RemarkTableContent {
  data: RowsType;
  name: string;
  filesUpdate: any;
}

interface ITaskComplete {
  mode?: "complete" | "edit";
  files?: any;
}

export const RemarkTableCard = memo(
  ({ data, name, filesUpdate }: RemarkTableContent) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    async function backToWorkHandle(id: number) {
      const confirmResult = window.confirm(
        "Вы уверены, что хотите вернуть на доработку?"
      );
      if (confirmResult) {
        await dispatch(fetchBackToWork(id));
      }
      if (processId) {
        dispatch(fetchProjectProcessExpertise(processId));
      }
    }

    const { handleOpenModal } = useModal();
    const handleTaskComplete = ({ mode, files }: ITaskComplete) => {
      handleOpenModal({
        name: ModalNames.ProjectProcess.TASK_COMPLETE,
        row: data.row,
        task: data.task,
        expertiseName: name,
        mode: mode,
        files: files,
      });
    };

    return (
      <>
        <tr>
          <td rowSpan={2}>{data.row.num}</td>
          <td rowSpan={2}>{data.row.text}</td>
          <td rowSpan={2}>{data.row.comment}</td>
          <td colSpan={3}>
            <div className={cls.tbody_answer}>
              <p style={{ color: selectColor(data.task?.status?.color) }}>
                {data.task?.status?.value}
              </p>
              <span>
                {moment(
                  data?.task?.date_deadline,
                  "DD.MM.YYYY HH:mm:ss Z"
                ).format("DD.MM.YYYY")}
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p
              style={{ color: selectColor(data.task?.workflow_data?.color) }}
              className={cls.tbody_reason}
            >
              {data?.task?.workflow_data?.reason}
            </p>
            <span>{data?.task?.comment}</span>
          </td>
          <td className={cls.tbody_changes}>
            {data?.task?.workflow_data?.changes}
          </td>
          <td className={cls.tbody_btn}>
            {(data.can.complete || data.can.save) && (
              <IconButton
                sx={iconBtnSx}
                onClick={() => {
                  handleTaskComplete({ mode: "complete" });
                }}
                title={"Выдать ответ"}
              >
                <PlusSquareFill />
              </IconButton>
            )}
            {data.can?.edit && (
              <IconButton
                sx={iconBtnSx}
                onClick={() => {
                  handleTaskComplete({ mode: "edit", files: filesUpdate });
                }}
                title={"Изменить ответ"}
              >
                <PencilSquare />
              </IconButton>
            )}
            {data.can?.backToWork && (
              <IconButton
                sx={iconBtnSx}
                title={"Вернуть на доработку"}
                onClick={() => {
                  backToWorkHandle(data?.task?.id);
                }}
              >
                <XSquareFill size={24} />
              </IconButton>
            )}
          </td>
        </tr>
      </>
    );
  }
);
