import { useState } from "react";
import {
  useMaterialProcessDeleteMaterialMutation,
  useMaterialProcessPullRequestMutation,
} from "src/FSD/entities/materialProcess/api";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";

type TUseModal = {
  material: ApiMaterialProcessResponses.Index;
};

export const useMaterial = ({ material }: TUseModal) => {
  const [deleteMaterial] = useMaterialProcessDeleteMaterialMutation();
  const [pullRequest] = useMaterialProcessPullRequestMutation();

  const handleDeleteMaterialProcess = async () => {
    const isDelete = window.confirm("Вы уверены, что хотите удалить?");

    if (isDelete) {
      await deleteMaterial({ id: material.id });
    }
  };

  const handlePullRequestMaterialProcess = async () => {
    const isPullRequest = window.confirm(
      "Вы уверены, что хотите взять в работу?"
    );

    if (isPullRequest) {
      await pullRequest({ id: material.id });
    }
  };

  const { updateQuestionnaire, questionnaire } = material.can;

  const files = material.files.slice(0, 2);

  const filesSize = material.filesSize
    ? `(${material.filesSize.join("")})`
    : "";

  const isMultipleFiles = files.length > 1;

  const [isTasksVisible, setTasksVisible] = useState<boolean>(false);

  const showTasks = !!material?.tasks?.length;

  const handleToggleTasks = () => {
    if (!showTasks) {
      return;
    }

    setTasksVisible(!isTasksVisible);
  };

  const currentDate = new Date();

  const colorDateLimit =
    timestampToDate(material.date_limit)! < currentDate ? "error" : "warning";

  return {
    handleDeleteMaterialProcess,
    handlePullRequestMaterialProcess,
    updateQuestionnaire,
    questionnaire,
    files,
    filesSize,
    isMultipleFiles,
    isTasksVisible,
    handleToggleTasks,
    showTasks,
    colorDateLimit,
  } as const;
};
