import React, { forwardRef, useImperativeHandle, Ref, ReactNode } from "react";
import classNames from "classnames";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import style from "../ThreeDotButton/style.module.scss";
import { Button } from "../Button";
import { CaretArrowDown } from "src/components/newUI/ToggleAccordion/ToggleAccordionSvg/ToggleAccordionSvg";
import { RefType } from "../ThreeDotButton";

interface IDropdownButtonProps {
  children: ReactNode;
  className?: string;
  color?: "primary" | "secondary" | "success" | "error" | "info";
  disabled?: boolean;
  textBtn: string;
}

const theme = createTheme({
  palette: {
    white: {
      main: "#FFFFFF",
    },
  },
});

export const DropdownButton = forwardRef(
  (
    {
      children,
      textBtn,
      className,
      disabled = false,
      color = "primary",
    }: IDropdownButtonProps,
    ref: Ref<RefType>
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    return (
      <ThemeProvider theme={theme}>
        <div className={classNames(style.root, className)}>
          <Button
            onClick={handleClick}
            endIcon={<CaretArrowDown isOpen={isOpen} />}
            disabled={disabled}
            color={color}
          >
            {textBtn}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            {children}
          </Menu>
        </div>
      </ThemeProvider>
    );
  }
);
