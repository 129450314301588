import { ThunkDispatch } from "@reduxjs/toolkit";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { DraggableLocation, DropResult } from "react-beautiful-dnd";
import { KanbanType, KanbanModel } from "src/FSD/entities/kanban";
import { useTaskBackToWorkMutation } from "src/FSD/entities/task/api";
import { useAppSelector, useAppDispatch } from "src/app/store";
import { pushErrorMessage } from "src/app/feature/errorTrace";
import { statusDirections } from "../../constants/directions";
import { getTaskPosition } from "src/FSD/widgets/v2/kanban/Column/lib/helpers/getTaskPosition";
import { allTasksToInWork } from "./allTasksToInWork";
import { inWorkToAllTasks } from "./inWorkToAllTasks";
import { inWorkToIsDone } from "./inWorkToIsDone";
import { isDoneToInWork } from "./isDoneToInWork";
import { isDoneToFulfilled } from "./isDoneToFulfilled";
import { atSameColumn } from "./atSameColumn";

export type ColumnShift = {
  dispatch: ThunkDispatch<any, any, any>;
  sourceColumnId: number | string;
  destinationColumnId: number | string;
  sourceRowId: number | string;
  destinationRowId: number | string;
  allTasks: KanbanType.Task[];
  rowTasks: KanbanType.RowTasks;
  groupBy: KanbanType.GroupByValue;
  result: DropResult;
  source: DraggableLocation;
  destination: DraggableLocation;
  backToWork?: MutationTrigger<any>;
};

export enum Errors {
  NO_SUCH_DIRECTION = "Движение задачи в этом направлении невозможно",
  ACTION_NOT_ALLOWED = "Вам запрещено данное действие",
}

export const useKanbanDnD = () => {
  const dispatch = useAppDispatch();

  const { allTasks, rowTasks } = useAppSelector(KanbanModel.getTasks);

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const [backToWork] = useTaskBackToWorkMutation();

  const handleOnDragEnd = (result: DropResult) => {
    // никуда не закинул
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // получаем начальные и конечные координаты таска
    const { columnId: sourceColumnId, rowId: sourceRowId } = getTaskPosition(
      source.droppableId
    );

    const { columnId: destinationColumnId, rowId: destinationRowId } =
      getTaskPosition(destination.droppableId);

    // для дебага
    // console.log({ sourceColumnId, destinationColumnId, statusDirections });

    // закинул в ту же колонку
    if (source.droppableId === destination.droppableId) {
      atSameColumn({
        dispatch,
        sourceColumnId,
        destinationColumnId,
        sourceRowId,
        destinationRowId,
        allTasks,
        rowTasks,
        groupBy,
        result,
        source,
        destination,
      });

      return;
    }

    // проверка на перемещение между колонками
    const canDropByDestination = statusDirections.some(
      (direction) =>
        direction.from === sourceColumnId &&
        direction.to === destinationColumnId
    );

    // для дебага
    // console.log({ statusDirections, sourceColumnId, destinationColumnId });

    // нет такого маршрута для перемещения между колонками
    if (!canDropByDestination) {
      dispatch(pushErrorMessage(Errors.NO_SUCH_DIRECTION));
      return;
    }

    // из "Все задачи" в "В работе"
    allTasksToInWork({
      dispatch,
      sourceColumnId,
      destinationColumnId,
      sourceRowId,
      destinationRowId,
      allTasks,
      rowTasks,
      groupBy,
      result,
      source,
      destination,
    });

    // из "В работе" в "Все задачи"
    inWorkToAllTasks({
      dispatch,
      sourceColumnId,
      destinationColumnId,
      sourceRowId,
      destinationRowId,
      allTasks,
      rowTasks,
      groupBy,
      result,
      source,
      destination,
    });

    // из "В работе" в "Проверка"
    inWorkToIsDone({
      dispatch,
      sourceColumnId,
      destinationColumnId,
      sourceRowId,
      destinationRowId,
      allTasks,
      rowTasks,
      groupBy,
      result,
      source,
      destination,
    });

    // из "Проверка" в "В работе"
    isDoneToInWork({
      dispatch,
      sourceColumnId,
      destinationColumnId,
      sourceRowId,
      destinationRowId,
      allTasks,
      rowTasks,
      groupBy,
      result,
      source,
      destination,
      backToWork,
    });

    // из "Проверка" в "Выполнено"
    isDoneToFulfilled({
      dispatch,
      sourceColumnId,
      destinationColumnId,
      sourceRowId,
      destinationRowId,
      allTasks,
      rowTasks,
      groupBy,
      result,
      source,
      destination,
    });
  };

  return { handleOnDragEnd } as const;
};
