import { forwardRef, ReactNode } from "react";
import {
  Select,
  SelectProps,
  TSelectItem,
} from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "src/types/generics";
import { useSelectExecutor } from "../lib/hooks/useSelectExecutor";

type Props = {
  publishedObjectId: number;
  userType?: Nullable<TSelectItem>;
  error?: boolean;
  helperText?: ReactNode;
  userId?: number;
  setValue?: any;
  type: "Project" | "ProjectProcess" | "ProcessWork";
} & Omit<SelectProps, "options">;

export const SelectExecutor = forwardRef(
  (
    {
      publishedObjectId,
      userType,
      value,
      className = "",
      disabled = false,
      error = false,
      helperText = "",
      userId,
      setValue,
      type,
      label = "Исполнитель",
      ...props
    }: Props,
    ref
  ) => {
    const { usersToAssign, isPendingUsersAssign } = useSelectExecutor({
      id: publishedObjectId,
      userType,
      userId,
      setValue,
      type,
    });

    return (
      <Select
        {...props}
        ref={ref}
        label={label}
        value={value}
        options={usersToAssign}
        isLoading={isPendingUsersAssign}
        className={className}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    );
  }
);
