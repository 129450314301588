export const getTotalSum = (groupId: number, applications: any, form: any) => {
  const formValues = form.getValues();

  let totalSum = 0;

  Object.keys(formValues).forEach((processTypeId) => {
    Object.keys(formValues[processTypeId]).forEach((processId) => {
      Object.keys(formValues[processTypeId][processId]).forEach(
        (userTypeId) => {
          if (formValues[processTypeId][processId][userTypeId]) {
            totalSum +=
              applications[groupId].userTypes[userTypeId].appProcesses[
                processId
              ].price;
          }
        }
      );
    });
  });

  return totalSum;
};
