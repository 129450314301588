import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import {
  useTaskCompleteMutation,
  useTaskCreateStopMutation,
  useTaskGetStoppedQuery,
} from "src/FSD/entities/task/api";
import { validationSchema } from "../../model/schemas/schema";

interface TFieldValues {
  content: string;
}

const defaultValues = {
  content: "",
};

interface Props {
  projectProcessId: number;
}

export const useStopProcess = ({ projectProcessId }: Props) => {
  const { data: projectProcessData, isLoading: isProcessLoading } =
    useProjectProcessViewQuery({
      id: projectProcessId,
    });

  const { data: stoppedReasons, isLoading: isStoppedReasonsLoading } =
    useTaskGetStoppedQuery({
      processId: projectProcessId,
    });

  const [createStop] = useTaskCreateStopMutation();

  const [complete] = useTaskCompleteMutation();

  const isProjectProcessLoading =
    !projectProcessData ||
    isProcessLoading ||
    !stoppedReasons ||
    isStoppedReasonsLoading;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<TFieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async (dataValues: TFieldValues) => {
    const data = {
      content: dataValues.content,
    };

    const response = await createStop({
      processId: projectProcessId,
      data,
    });

    // @ts-ignore
    if (!response.error) {
      setValue("content", "");
    }
  };

  return {
    handleSubmit,
    register,
    errors,
    isSubmitting,
    onSubmit,
    isProjectProcessLoading,
    stoppedReasons,
    isStoppedReasonsLoading,
    complete,
  } as const;
};
