import { Eye, EyeSlash } from "react-bootstrap-icons";
import { Accordion, Button, Modal, Paragraph } from "src/FSD/shared/uiKit/v2";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import stylesAccordion from "src/FSD/shared/uiKit/v2/Accordion/ui/Accordion.module.scss";
import { TabChildrenHeader } from "../../../../TabsPanel";
import { useMaterials } from "../lib/hooks/useMaterials";
import { ProviderFirm } from "./ProviderFirm/ProviderFirm";
import styles from "./Materials.module.scss";
import { Material } from "./Material/Material";
import ProjectProcessModals from "../../../modals";
import { ImageTitle } from "./ProviderFirm/ImageTitle";
import TaskModals from "../../../../task/modals";
import { NewSuppliersSVG } from "../../../../../../../components/newUI/SVG";

type TProps = {
  projectProcessId: number;
};

export const Materials = ({ projectProcessId }: TProps) => {
  const { materials, providerFirms, isFetching, dataProjectProcess } =
    useMaterials({
      processId: projectProcessId,
    });

  const { name, handleCloseModal, handleOpenModal, restData } = useModal();

  const media = dataProjectProcess?.can?.materialProcess ? (
    <Button
      onClick={() =>
        handleOpenModal({
          name: ModalNames.ProjectProcess.UPDATE_MATERIAL_PROCESS,
        })
      }
    >
      Запросить
    </Button>
  ) : (
    ""
  );

  const headerCardProviderFirm = (
    <Paragraph fontSize={20} color="light" fontWeight="medium">
      Проверенные платформой поставщики
    </Paragraph>
  );

  const {
    UPDATE_MATERIAL_PROCESS,
    REQUEST_EQUIPMENT_COMPLETE,
    MATERIAL_PROCESS_SELECT_REQUEST,
    MATERIAL_PROCESS_QUESTIONNAIRE,
  } = ModalNames.ProjectProcess;

  return (
    <>
      {isFetching ? (
        <List maxHeight="900px" className={styles.skeletonList}>
          <Skeleton.List count={1} height={200} />
          <Skeleton.List count={3} height={300} />
        </List>
      ) : (
        <>
          <TabChildrenHeader
            title="Оборудование"
            media={media}
            className={styles.tabHeader}
          />
          <List maxHeight="875px">
            <Accordion
              title={headerCardProviderFirm}
              defaultOpen
              iconOpen={
                <EyeSlash color="white" className={stylesAccordion.iconOpen} />
              }
              iconClose={
                <Eye color="white" className={stylesAccordion.iconClose} />
              }
              showIconValue={false}
              classNameAccordionRoot={styles.accordionRoot}
              classNameSummaryRoot={styles.summaryRoot}
              classNameDetailsRoot={styles.detailsRoot}
            >
              <List className={styles.providerFirmsList}>
                <ListMapper>
                  {providerFirms!.map(
                    (
                      providerFirm: ApiMaterialProcessResponses.GetProviderFirms
                    ) => (
                      <ProviderFirm
                        key={providerFirm.id}
                        providerFirm={providerFirm}
                      />
                    )
                  )}
                  {providerFirms!.length < 5 &&
                    Array.from(new Array(5 - providerFirms!.length)).map(
                      (_, i) => (
                        <div key={i} className={styles.blockProvidesFirm}>
                          <ImageTitle
                            icon={<NewSuppliersSVG />}
                            title="Ожидаем новых поставщиков"
                          />
                        </div>
                      )
                    )}
                </ListMapper>
              </List>
            </Accordion>
            <ListMapper>
              {materials!.map((material: ApiMaterialProcessResponses.Index) => (
                <Material key={material.id} material={material} />
              ))}
            </ListMapper>
          </List>
        </>
      )}

      <Modal
        isActive={name === MATERIAL_PROCESS_SELECT_REQUEST}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.MaterialProcessSelectRequestForm
          taskId={restData.taskId}
          materialId={restData.materialId}
          header="Критерии выбора коммерческого предложения"
        />
      </Modal>
      <Modal
        isActive={name === UPDATE_MATERIAL_PROCESS}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.UpdateMaterialProcessForm
          processId={projectProcessId}
          partNum={dataProjectProcess?.projectPart.id}
          header="Запросить предложение оборудования"
        />
      </Modal>
      <Modal
        isActive={name === REQUEST_EQUIPMENT_COMPLETE}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.RequestEquipmentForm
          taskId={restData.taskId}
          header="Сформировать коммерческое предложение"
        />
      </Modal>
      <Modal
        isActive={name === MATERIAL_PROCESS_QUESTIONNAIRE}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.MaterialProcessQuestionnaire
          materialId={restData.materialId}
          header="Опросный лист"
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.Task.FEEDBACK}
        handleClose={handleCloseModal}
      >
        <TaskModals.FeedbackForm
          taskId={restData.taskId}
          header="Оценить поставщика"
          isExecutor
        />
      </Modal>
    </>
  );
};
