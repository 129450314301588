import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Application.module.scss";

type TProps = {
  children: ReactNode;
  className?: string;
};

export const ApplicationFooter = ({ children, className }: TProps) => {
  return <div className={cn(styles.card__footer, className)}>{children}</div>;
};
