import React from "react";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { getFiles } from "src/FSD/shared/lib/helpers/getFiles";
import style from "./style.module.scss";
import { config } from "../../../../app.cofig";
import { useIsMobile } from "../../../hooks/useIsMobile";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";

export interface FileEditingProps {
  projectId: string | number;
  canFinish?: boolean;
}

export const FileEditing: React.FC<FileEditingProps> = ({
  projectId,
  canFinish = false,
}) => {
  const isMobile = useIsMobile(800);
  const handleFinish = () => {};
  return (
    <div className={style.wrapper}>
      <h1>Добавить файлы</h1>
      <h2 className={style.clue}>
        Здесь можно загрузить файлы с исходными данными, такие как ТЗ, ГПЗУ, ТУ
      </h2>
      <Uploader
        canEdit
        getDataCallback={() => getFiles("project", projectId)}
        autoUpload
        postUrl={`${config.localDomain}/v1/project/add-file?id=${projectId}`}
      />
      {canFinish && isMobile && (
        <CustomButton onClick={handleFinish} background={palette.green}>
          Завершить
        </CustomButton>
      )}
    </div>
  );
};
