import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectMilestone } from "../services/fetchProjectMilestone";
import { ProjectMilestoneModifiedType } from "../types/milestoneProjectType";
import {
  collectNums,
  setAllToggleNode,
  setToggleNode,
} from "../../../../../utils/helpersOpenNode";

function getTotalPercent(mileStone: any, id: number) {
  const mile = mileStone.find((m: any) => m.id === id);
  if (!mile) {
    return 0;
  }
  let totalPercent = mile.percent || 0;
  if (mile.parent_id) {
    totalPercent += getTotalPercent(mileStone, mile.parent_id);
  }
  return totalPercent;
}

interface InitialState {
  isLoadMileStone: boolean;
  isLoadBody: boolean;
  mileStone: ProjectMilestoneModifiedType;
  openNode: any;
  isAllToggle: boolean;
}

const initialState: InitialState = {
  isLoadMileStone: false,
  isLoadBody: false,
  mileStone: {},
  openNode: {},
  isAllToggle: false,
};

function addStatusToStages(data: any) {
  for (const key in data) {
    if (data[key].hasOwnProperty("data")) {
      addStatusToStages(data[key].data);
    } else {
      data[key].stages = data[key].stages?.map((stage: any, index: number) => {
        let status: "greenCheck" | "grayCheck" | "gray" | "" = "";
        if (stage.complete) {
          status = "greenCheck";
        } else if (index > 0 && stage[index - 1]?.complete) {
          status = "grayCheck";
        } else {
          status = "gray";
        }
        const totalPercent = getTotalPercent(data[key].stages, stage.id);

        return {
          ...stage,
          status,
          totalPercent,
        };
      });
    }
  }
  return data;
}

const projectMilestoneSlice = createSlice({
  name: "projectProcessMileStone",
  initialState,
  reducers: {
    setOpenNode: (state, action) => {
      setToggleNode(state.openNode, action.payload);
    },
    setOpenAllNode: (state) => {
      setAllToggleNode(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectMilestone.pending, (state, action) => {
      const { load } = action.meta.arg;
      if (!load) {
        state.isLoadMileStone = true;
      }
    });
    builder.addCase(fetchProjectMilestone.fulfilled, (state, action) => {
      state.isLoadMileStone = false;
      state.isLoadBody = false;
      const { load } = action.meta.arg;
      const newMilestone = addStatusToStages(action.payload);
      state.mileStone = newMilestone;
      if (!load) {
        state.openNode = collectNums(action.payload);
      }
    });
  },
});

export const { setOpenNode, setOpenAllNode } = projectMilestoneSlice.actions;
export default projectMilestoneSlice.reducer;
