import { Controller } from "react-hook-form";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import styles from "./UpdateMaterialProcess.module.scss";
import { config } from "../../../../../../../app.cofig";

type TProps = {
  providerFirm: ApiMaterialProcessResponses.GetProviderFirms;
  setValue: any;
  control: any;
};

export const CheckProviderFirm = ({
  providerFirm,
  setValue,
  control,
}: TProps) => {
  const photoUrl = `${config.localDomain}${providerFirm.photo}`;
  const label = providerFirm.photo ? (
    <img
      src={photoUrl}
      alt="Баннеры поставщиков материалов"
      className={styles.providerFirms__logo}
    />
  ) : (
    providerFirm.title
  );

  return (
    <div className={styles.providerFirms__item}>
      <Controller
        control={control}
        name="firmId"
        render={({ field: { value } }) => (
          <Checkbox
            label={label}
            onChange={() => {
              setValue("firmId", providerFirm.id);
            }}
            value={value}
            checked={value === providerFirm.id}
            editing
          />
        )}
      />
    </div>
  );
};
