import { Card, List, Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Warning.module.scss";

type TProps = {
  warning: string[];
};

export const Warning = ({ warning }: TProps) => {
  return (
    <Card
      className={styles.warningCard}
      header={<Card.Header>Внимание!</Card.Header>}
    >
      <List disableOverflow margin={0}>
        {warning.map((warningMessage, index) => (
          <Paragraph color="light" fontSize={18} key={index}>
            {warningMessage}
          </Paragraph>
        ))}
      </List>
    </Card>
  );
};
