import { ReactNode } from "react";
import cn from "classnames";
import styles from "./List.module.scss";

type TProps = {
  children: ReactNode;
  gap?: number;
  margin?: number;
  maxHeight?: string;
  className?: string;
  disableOverflow?: boolean;
};

export const List = ({
  children,
  gap = 15,
  margin,
  maxHeight,
  className,
  disableOverflow,
}: TProps) => {
  return (
    <div
      className={cn(
        styles.list,
        disableOverflow && styles.list_noOverflow,
        className
      )}
      style={{ gap: `${gap}px`, margin: `${margin ?? gap}px 0`, maxHeight }}
    >
      {children}
    </div>
  );
};
