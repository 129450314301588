import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import moment from "moment/moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormWrapper } from "../../../../newUI/FormWrapper/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectListByProcessId } from "../../../../../app/feature/getList/getListProjectByProcessId/services/fetchProjectListByProcessId";
import {
  getProjectList,
  getProjectListIsLoad,
} from "../../../../../app/feature/getList/getListProjectByProcessId/selectors/getListProjectByProcess";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import {
  getProjectProcessToProcess,
  getProjectProcessToProcessIsLoad,
} from "../../../../../app/feature/getList/getListProjectProcessToProcesses/selectors/getListProjectProcessToProcess";
import { SelectField } from "../../../../newUI/SelectMui/SelectMui";
import { Select as NewSelect } from "../../../../UI/components/FormComponentns/Select";
import { fetchProjectProcessToProcesses } from "../../../../../app/feature/getList/getListProjectProcessToProcesses/services/fetchProjectProcessToProcesses";
import cls from "./ModalRequestTask.module.scss";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { fetchRequestTask } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchRequestTask";
import { parseUnprocessableFields } from "../../../../../app/services/api/requestHandler";
import { getProjectProcessRequestError422 } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";
import { setNullRequest } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/slice/projectProcessHeaderBtn";
import { getProjectProcessRequestTaskSuccess } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequestTask";
import { clearProcessList } from "../../../../../app/feature/getList/getListProjectProcessToProcesses/slice/projectProcessToProcess";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";

interface Inputs {
  dateLimit: any | string;
  type: number;
  content: string;
  processesIds: string[];
  projectId: { title: string; id: number };
}
interface ModalRequestTaskProps {
  closeModal: () => void;
}

const schema = Yup.object().shape({
  projectId: Yup.string().required("Необходимо выбрать объект"),
  processesIds: Yup.array().required("Необходимо выбрать раздел"),
  dateLimit: Yup.date().required("Контрольный срок обязателен для заполнения"),
});

export const ModalRequestTask = memo(
  ({ closeModal }: ModalRequestTaskProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const isLoadProjectList = useAppSelector(getProjectListIsLoad);
    const projectList = useAppSelector(getProjectList);
    const processList = useAppSelector(getProjectProcessToProcess);
    const IsLoadProcessList = useAppSelector(getProjectProcessToProcessIsLoad);
    const error422 = useAppSelector(getProjectProcessRequestError422);
    const requestTaskSuccess = useAppSelector(
      getProjectProcessRequestTaskSuccess
    );

    const [valueMulti, setValueMulti] = useState<string[] | number[]>([]);

    useEffect(() => {
      if (processId) {
        dispatch(fetchProjectListByProcessId(processId));
      }
    }, [processId]);

    const {
      register,
      handleSubmit,
      watch,
      control,
      setValue,
      trigger,
      setError,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
    });

    const projectValue = watch("projectId");

    // на первый рендер очищаем мульти
    useEffect(() => {
      dispatch(clearProcessList());
      setValueMulti([]);
      setValue("processesIds", []);
    }, []);

    useEffect(() => {
      if (processId && projectValue) {
        dispatch(
          fetchProjectProcessToProcesses({
            projectId: projectValue.id,
            processId,
          })
        );
      }
    }, [projectValue]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      if (requestTaskSuccess) {
        dispatch(setNullRequest());
        closeModal();
      }
    }, [requestTaskSuccess]);

    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        dateLimit: moment(dataValues.dateLimit, "DD.MM.YYYY HH:mm:ss Z").format(
          "DD.MM.YYYY"
        ),
        content: dataValues.content,
        processesIds: dataValues.processesIds,
        projectId: projectValue.id,
      };
      if (processId) {
        await dispatch(fetchRequestTask({ processId, data }));
      }
    };

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all") && processList) {
        const all = Object.keys(processList);
        setValueMulti(all);
        setValue("processesIds", all);
        trigger("processesIds");
      } else {
        setValueMulti(event.target.value);
        setValue("processesIds", event.target.value);
        trigger("processesIds");
      }
    };

    const handleClearClick = () => {
      setValueMulti([]);
      setValue("processesIds", []);
    };

    if (isLoadProjectList) {
      <ModalPreloader />;
    }

    return (
      <FormWrapper
        submitHandler={handleSubmit(formSubmitHandler)}
        title={"Запрос задания"}
      >
        <NewSelect
          {...register("projectId")}
          variant={cls.fullWidth}
          value={projectValue}
          changeHandler={(_, value) => {
            setValue("projectId", value);
          }}
          label={"Объект"}
          options={projectList || []}
          disableClear
          disabled={isLoadProjectList}
          error={!!errors.projectId}
          helperText={errors.projectId?.message}
        />
        <SelectField
          {...register("processesIds")}
          name="processesIds"
          label="Разделы"
          disabled={IsLoadProcessList}
          multiple
          multiValue={valueMulti}
          isAllClick
          onChange={onChangeMulti}
          options={(processList && Object.entries(processList)) || []}
          handleClearClick={handleClearClick}
          error={errors.processesIds ? errors.processesIds.message : null}
        />
        <div className={cls.dateWrapper}>
          <Controller
            control={control}
            name="dateLimit"
            render={() => (
              <DateField
                {...register("dateLimit")}
                variant={cls.fullWidth}
                label="Контрольный срок"
                changeDateHandler={(date) => {
                  setValue("dateLimit", date);
                  trigger("dateLimit");
                }}
                error={!!errors.dateLimit}
                // @ts-ignore
                helperText={errors.dateLimit?.message}
              />
            )}
          />
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("content")}
          placeholder="Введите текст"
          error={!!errors.content}
          helperText={errors.content ? errors.content.message : null}
        />
        <CustomButton
          className={cls.submit}
          background={palette.green}
          width={160}
          type="submit"
        >
          Отправить
        </CustomButton>
      </FormWrapper>
    );
  }
);
