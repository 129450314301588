import cn from "classnames";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { PublishedObjectView } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Popper } from "src/FSD/shared/uiKit/v2/Popper";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import styles from "./Deadlines.module.scss";
import { useDeadlines } from "../../lib/hooks/useDeadlines";

interface Props {
  publishedObject: PublishedObjectView;
}

export const Deadlines = ({ publishedObject }: Props) => {
  const {
    canEditDates,
    handleChangeDates,
    factDateCreate,
    factDateEnd,
    isDatesUpdating,
  } = useDeadlines(publishedObject);

  return (
    <Card
      header={<Card.Header>Сроки выполнения</Card.Header>}
      className={cn(
        // @ts-ignore
        publishedObject?.paused && styles.deadlines_paused
      )}
    >
      <div className={styles.deadlines}>
        <Paragraph className={styles.deadlines__label}>Плановый:</Paragraph>
        <Paragraph className={styles.deadlines__date}>
          {publishedObject.date_start}&nbsp;-&nbsp;
        </Paragraph>
        <Popper.DateField
          date={publishedObject.date_limit}
          canEdit={canEditDates}
          title="Конечный срок"
          onChange={handleChangeDates}
          isLoading={isDatesUpdating}
          className={styles.deadlines__date}
        />
      </div>
      {factDateCreate && (
        <div className={styles.deadlines}>
          <Paragraph className={styles.deadlines__label}>
            Фактический:
          </Paragraph>
          <Paragraph className={styles.deadlines__date}>
            {factDateCreate}&nbsp;-&nbsp;
          </Paragraph>
          <Paragraph className={styles.deadlines__date}>
            {factDateEnd}
          </Paragraph>
        </div>
      )}
    </Card>
  );
};
