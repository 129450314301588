import React, { memo } from "react";
import { ApiExpertiseRemarkResponses } from "src/FSD/entities/expertise/remark/model/responseTypes";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import cls from "../RemarkCard.module.scss";
import { GridCardBody } from "./GridCardBody/GridCardBody";

interface ViewGridCardProps {
  header: string;
  rows: Record<string, ApiExpertiseRemarkResponses.RemarkRowsValue>;
  processId: number;
  can?: ApiProjectProcessResponses.Can;
}

export const ViewGridCard = memo(
  ({ header, rows, processId, can }: ViewGridCardProps) => {
    const rowsArray = Object.values(rows);

    return (
      <>
        <div className={cls.grid_header}>{header}</div>
        {rowsArray.map((el) => (
          <GridCardBody
            key={el.row.id}
            data={el}
            processId={processId}
            can={can}
          />
        ))}
      </>
    );
  }
);
