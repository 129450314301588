import React from "react";
import {
  Button as ButtonMui,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import classNames from "classnames";
import style from "./style.module.scss";

export interface ButtonUiProps {
  posVariant?: string;
  theme?: "old" | "dark" | "light";
  color?: "primary" | "red" | "green" | "blue" | "grey" | "auto";
  children?: string;
  clickHandler?: () => void;
}

export const Button: React.FC<
  ButtonUiProps & Omit<MuiButtonProps, keyof ButtonUiProps>
> = (props) => {
  const {
    posVariant = "",
    children,
    color = "primary",
    clickHandler = () => {},
    theme = "dark",
    ...other
  } = props;
  return (
    <div
      className={classNames(
        posVariant,
        style[color],
        style[theme],
        style.button
      )}
    >
      <ButtonMui onClick={clickHandler} {...other}>
        {children}
      </ButtonMui>
    </div>
  );
};
