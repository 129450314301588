import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage, request } from "src/app/services/api/requestHandler";
import {
  ApiGetUsersToAssign,
  ApiGetUsersTypeToAssign,
  ApiPublishInfo,
  ApiPublishInfoWork,
  ApiPublishProcess,
  ApiSectionsTree,
  ApiUnPublishProcess,
  ApiUpdatePublishProcess,
  ApiEditSourceComment,
  ApiCreateSourceComment,
  ApiFetchSourceComments,
  ApiDeleteSourceComment,
} from "src/app/services/api/project/sections";
import { pushError, pushSuccessMessage } from "../../errorTrace";
import { ApiPrimaryProjectProcess } from "src/types/api/primaryTypes/apiPrimaryProjectProcess";

export type TValuesData = ApiPrimaryProjectProcess & {
  data?: TValuesData[];
  name?: string;
  num: string;
};

type TSectionsTreeType = Record<string, TValuesData>;

export const fetchProjectSectionsTree = createAsyncThunk<
  TSectionsTreeType,
  number
>("projectSectionsTree/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiSectionsTree(id);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

// Дубликат, чтобы не было загрузки при изменении данных
export const fetchProjectSectionsTreeDuplicate = createAsyncThunk<
  TSectionsTreeType,
  number
>("projectSectionsTreeDuplicate/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiSectionsTree(id);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchPublishProcess = createAsyncThunk<
  boolean,
  any,
  { rejectValue: { status: string; message: string } }
>(
  "fetchPublishProcess/fetch",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await ApiPublishProcess(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);

export const fetchUnPublishProcess = createAsyncThunk<boolean, number>(
  "fetchUnPublishProcess/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiUnPublishProcess(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchUpdatePublishProcess = createAsyncThunk<
  boolean,
  any,
  { rejectValue: { status: string; message: string } }
>(
  "fetchUpdatePublishProcess/fetch",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ApiUpdatePublishProcess(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      return rejectWithValue({ status: "none", message: error });
    }
  }
);

export type TUsersToAssign = Record<string, string>;
export const fetchGetUsersToAssign = createAsyncThunk<TUsersToAssign, number>(
  "fetchGetUsersToAssign/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiGetUsersToAssign(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export type TUsersTypeToAssignProps = {
  id: number;
  executorValue: number;
};
export type TUsersTypeToAssign = Record<string, string>;
export const fetchGetUsersTypeToAssign = createAsyncThunk<
  TUsersTypeToAssign,
  TUsersTypeToAssignProps
>("fetchGetUsersTypeToAssign/fetch", async (props, { rejectWithValue }) => {
  const { id, executorValue } = props;
  try {
    const response = await ApiGetUsersTypeToAssign(id, executorValue);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export interface IPublishInfoReturn {
  canNoSecure: boolean;
  pay1: boolean;
  pay2: boolean;
  pay3: boolean;
  secureMissingAttributes: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
}
export const fetchPublishInfo = createAsyncThunk<
  IPublishInfoReturn,
  { id: number; isWork?: boolean }
>("fetchPublishInfo/fetch", async ({ id, isWork }, { rejectWithValue }) => {
  try {
    const response = isWork
      ? await ApiPublishInfoWork(id)
      : await ApiPublishInfo(id);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const editSourceComment = createAsyncThunk(
  "project/editSourceComment",
  async ({ id, data }: { id: number; data: any }, { dispatch }) => {
    await request(
      ApiEditSourceComment(id, data),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const createSourceComment = createAsyncThunk(
  "project/createSourceComment",
  async ({ id, data }: { id: number; data: any }, { dispatch }) => {
    const response: any = [];
    await request(
      ApiCreateSourceComment(id, data),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const fetchSourceComments = createAsyncThunk(
  "project/fetchSourceComments",
  async (id: number, { dispatch, rejectWithValue }) => {
    let response: any = [];
    let error = null;

    await request(
      ApiFetchSourceComments(id),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }

    return response;
  }
);

export const deleteSourceComment = createAsyncThunk(
  "project/deleteSourceComment",
  async (id: number, { dispatch, rejectWithValue }) => {
    let error = null;

    await request(
      ApiDeleteSourceComment(id),
      () => {
        dispatch(pushSuccessMessage());
      },
      () => (err) => {
        dispatch(pushError(err));
        error = err;
      }
    )();

    if (error) {
      return rejectWithValue(error);
    }
  }
);
