import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { throttle } from "lodash";
import style from "./style.module.scss";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { Message, MessageProps } from "../Message/Message";
import { scrollBarWithTheme } from "../../../../../styles/styleWithTheme";

import { TimeLine } from "../TimeLine/TimeLine";
import { withClassCurry } from "../../../../utils";

export type ContentProps = ComponentPropsType<{
  styleVariant?: Record<string, string>;
  scrollYPositionHandler?: (pos: string) => void;
  messages: MessageProps[];
  initialPositionScrollY?: string;
}>;

export const Content: React.FC<ContentProps> = ({
  theme = "dark",
  styleVariant,
  variant = "",
  messages,
  scrollYPositionHandler = () => {},
  initialPositionScrollY,
}) => {
  const contentRef = useRef<null | HTMLDivElement>(null);

  const withTheme = withClassCurry(style[theme]);

  useEffect(() => {
    // установка позиции внутри окна чата
    if (!contentRef.current) {
      return;
    }
    if (messages.length) {
      const maxHeight = contentRef.current!.scrollHeight;
      if (initialPositionScrollY === "bottom") {
        contentRef.current.scrollTop = maxHeight;
        return;
      }
      contentRef.current.scrollTop = Number(initialPositionScrollY);
    }
  }, [messages]);

  const onScroll = () =>
    scrollYPositionHandler(String(contentRef.current!.scrollTop));
  return (
    <div
      style={styleVariant}
      onScroll={throttle(onScroll, 100)}
      ref={contentRef}
      className={withTheme(
        classNames(variant, variant, style.content, scrollBarWithTheme(theme))
      )}
    >
      {messages.length > 0 && (
        <div>
          <TimeLine time={messages[0] && messages[0].date!} theme={theme} />
        </div>
      )}

      {messages.map((item, i, arr) => {
        const { date } = item;
        const nextDate = (arr[i + 1] && arr[i + 1].date) || "";

        if (i === 0 && arr[i + 1]) {
          // first message
          return (
            <div key={i}>
              <Message theme={theme} {...item} />
              <TimeLine time={nextDate!} theme={theme} />
            </div>
          );
        }
        if (i === arr.length - 1) {
          // last message
          return (
            <div key={i}>
              {" "}
              <Message theme={theme} {...item} />
            </div>
          );
        }
        if (date !== nextDate) {
          // Переход на следующий день
          return (
            <div key={i}>
              <Message theme={theme} {...item} />
              <TimeLine time={nextDate} theme={theme} />
            </div>
          );
        }
        return (
          <div key={i}>
            <Message theme={theme} {...item} />
          </div>
        );
      })}
    </div>
  );
};
