import React from "react";
import style from "./style.module.scss";
import { StarSVG } from "../../../../newUI/SVG";
import { NO_INFO } from "../Info/Info";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";

interface ReviewsProps {
  data: any;
}

export const Reviews: React.FC<ReviewsProps> = ({ data }) => {
  return (
    <div className={style.wrapper}>
      {data.dataFeedbacks?.length > 0 ? (
        data.dataFeedbacks.map((item: any) => (
          <div className={style.reviews}>
            <div className={style.header}>
              <TextLink variant={style.author} url={item.author?.src}>
                {item.author?.name}
              </TextLink>
              <p>{item.date}</p>
            </div>
            <div className={style.flex}>
              <div className={style.about}>
                <p>Оценка</p>
                <div className={style.qualities}>
                  <div className={style.quality}>Качество</div>
                  <div className={style.icon}>
                    <StarSVG />
                  </div>
                  <div className={style.grade}>{item.quality}</div>
                </div>

                <div className={style.qualities}>
                  <div className={style.quality}>Надежность</div>
                  <div className={style.icon}>
                    <StarSVG />
                  </div>
                  <div className={style.grade}>{item.reliability}</div>
                </div>

                <div className={style.qualities}>
                  <div className={style.quality}>Лояльность</div>
                  <div className={style.icon}>
                    <StarSVG />
                  </div>
                  <div className={style.grade}>{item.loyalty}</div>
                </div>
              </div>
              <div className={style.data}>
                <p>Комментарий</p>
                <div className={style.comment}>{item.comment || NO_INFO}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};
