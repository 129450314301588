import { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import style from "../../../pages/Project/View/InitialData/style.module.scss";
import { config } from "src/app.cofig";
import CustomButton from "../../CustomButton/CustomButton";
import {
  fetchProjectSourceDataView,
  fetchProjectViewInitialData,
} from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { httpRequestGet } from "src/app/services/api/api";
import { fetchProjectProcessSourceDataDuplicate } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceData";
import { useAppSelector } from "src/app/store";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessInitialData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import NewUploader from "../../../features/NewUploader/NewUploader";
import { request } from "src/app/services/api/requestHandler";
import { palette } from "src/styles/restyle";
import { TFile } from "../../../features/NewUploader/types";
import { FilesState, getFilesDifference } from "./getFilesDifference";

const ApiGetTaskFile = (id: number): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/task/view?id=${id}&expand=files.svgIcon`
  );
};

const getTaskFiles = async (taskId: number) => {
  let response: any;

  await request(ApiGetTaskFile(taskId), (data) => {
    response = data;
  })();

  return response.files;
};

interface FileUploaderProps {
  taskId: number;
  setModalFiles?: (data: boolean) => void;
  handleCloseModal?: () => void;
  handleUpdate?: () => void;
}

export const FileUploader = ({
  taskId,
  setModalFiles,
  handleCloseModal,
  handleUpdate,
}: FileUploaderProps) => {
  const files = useRef<FilesState>({
    initialIsFilled: false,
    initial: [],
    final: [],
  });

  useEffect(
    () => () => {
      const shouldUpdate = getFilesDifference(files.current);
    },
    []
  );

  const handleSetFiles = (uploadedFiles: TFile[]) => {
    files.current = files.current.initialIsFilled
      ? { ...files.current, final: [...uploadedFiles] }
      : {
          ...files.current,
          initial: [...uploadedFiles],
          initialIsFilled: true,
        };
  };

  return (
    <form>
      <NewUploader
        autoUpload
        canEdit
        getDataCallback={() => getTaskFiles(taskId)}
        postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
        uploadAsActive
        handleUpdate={handleUpdate}
        setFiles={handleSetFiles}
      />
      <div className={style.uploaderFile}>
        <CustomButton
          width={120}
          onClick={() => {
            if (setModalFiles) {
              setModalFiles(false);
            }

            if (handleCloseModal) {
              handleCloseModal();
            }
          }}
          background={palette.blue}
          color={palette.white}
          borderRadius={4}
          height={32}
        >
          Закрыть
        </CustomButton>
      </div>
    </form>
  );
};

interface ModalAddFilesProps {
  taskId: number;
  parentId: number;
  index?: number;
  setModalFiles: (data: boolean) => void;
  projectId: number;
  type: "process" | "project";
}

export const ModalAddFiles = ({
  taskId,
  setModalFiles,
  parentId,
  index,
  projectId,
  type: modalType,
}: ModalAddFilesProps) => {
  const dispatch = useDispatch();
  const infoId = useAppSelector(getProjectProcessInfoId);
  const params = useParams();

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const handleUpdate = async () => {
    if (projectId && (index || index === 0) && modalType === "project") {
      await dispatch(
        fetchProjectSourceDataView({
          idx: { parentId, id: projectId, index, type },
        })
      );
    }
    if (projectId && (index || index === 0) && modalType === "process") {
      await dispatch(
        fetchProjectProcessSourceDataDuplicate({
          projectId,
          parentId,
          index,
          type,
        })
      );
    }
  };

  useEffect(
    () => () => {
      handleUpdate();
      if (infoId) {
        dispatch(fetchProjectProcessInitialData(infoId as number));
        return;
      }

      if (params.id) {
        dispatch(
          fetchProjectViewInitialData({
            id: +params.id,
            type,
          })
        );
      }
    },
    [infoId]
  );

  return (
    <FileUploader
      taskId={taskId}
      setModalFiles={setModalFiles}
      handleUpdate={handleUpdate}
    />
  );
};
