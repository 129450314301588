import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProcessMaterial,
  fetchProcessMaterialDuplicate,
  fetchProcessMaterialFirms,
} from "../services/fetchProcessMaterial";
import { ProjectProcessMaterialsTypes } from "../types/projectProcessMaterialsTypes";
import { ProjectProcessMaterialsFirmTypes } from "../types/projectProcessMaterialsFirmTypes";
import { fetchProcessMaterialViewForm } from "../services/fetchProcessMaterialViewForm";
import { ViewFormType } from "../types/projectProcessViewFormTypes";
import { fetchProcessMaterialTemplateList } from "../services/fetchProcessMaterialTemplateList";
import { fetchProcessMaterialCreate } from "../services/fetchProcessMaterialCreate";
import { fetchProjectProcessMaterialDelete } from "../services/fetchProjectProcessMaterialDelete";
import { fetchProcessMaterialOfferBack } from "../services/fetchProcessMaterialOfferBack";
import { fetchProcessMaterialSelect } from "../services/fetchProcessMaterialSelect";

interface InitialState {
  isLoad: boolean;
  isLoadFirms: boolean;
  isLoadViewForm: boolean;
  isLoadTemplateList: boolean;
  isLoadCreateMaterial: boolean;
  isLoadDeleteMaterial: boolean;
  isLoadOfferBack: boolean;
  isLoadOfferSelect: boolean;
  templateList: { id: number; name: string }[];
  viewForms: ViewFormType[] | undefined;
  error: any;
  materials: ProjectProcessMaterialsTypes[] | null;
  firms: ProjectProcessMaterialsFirmTypes[] | undefined;
  firmsForHeader: ProjectProcessMaterialsFirmTypes[] | undefined;
}

const initialState: InitialState = {
  isLoad: false,
  isLoadFirms: false,
  isLoadTemplateList: false,
  isLoadViewForm: false,
  isLoadCreateMaterial: false,
  isLoadDeleteMaterial: false,
  isLoadOfferBack: false,
  isLoadOfferSelect: false,
  error: null,
  materials: null,
  firms: undefined,
  firmsForHeader: undefined,
  viewForms: undefined,
  templateList: [],
};

const projectProcessMaterial = createSlice({
  name: "projectProcessMaterial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProcessMaterial.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProcessMaterial.fulfilled, (state, action) => {
      state.isLoad = false;
      state.materials = action.payload;
    });
    builder.addCase(
      fetchProcessMaterialDuplicate.fulfilled,
      (state, action) => {
        state.materials = action.payload;
      }
    );
    builder.addCase(fetchProcessMaterialFirms.pending, (state) => {
      state.error = null;
      state.isLoadFirms = true;
    });
    builder.addCase(fetchProcessMaterialFirms.fulfilled, (state, action) => {
      state.isLoadFirms = false;
      const allCheckObj = {
        id: -1,
        title: "Выбрать все",
        site: "",
        photo: "",
        email: "",
        phone: "",
        questionnaires: [],
      };
      state.firms = [...action.payload, allCheckObj];
      state.firmsForHeader = action.payload;
    });
    builder.addCase(fetchProcessMaterialFirms.rejected, (state, action) => {
      state.isLoadFirms = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProcessMaterialViewForm.pending, (state) => {
      state.isLoadViewForm = true;
    });
    builder.addCase(fetchProcessMaterialViewForm.fulfilled, (state, action) => {
      state.isLoadViewForm = false;
      state.viewForms = action.payload;
    });
    builder.addCase(fetchProcessMaterialTemplateList.pending, (state) => {
      state.isLoadTemplateList = true;
    });
    builder.addCase(
      fetchProcessMaterialTemplateList.fulfilled,
      (state, action) => {
        state.isLoadTemplateList = false;
        state.templateList = action.payload;
      }
    );
    builder.addCase(fetchProcessMaterialCreate.pending, (state) => {
      state.isLoadCreateMaterial = true;
    });
    builder.addCase(fetchProcessMaterialCreate.fulfilled, (state) => {
      state.isLoadCreateMaterial = false;
    });
    builder.addCase(fetchProjectProcessMaterialDelete.pending, (state) => {
      state.isLoadDeleteMaterial = true;
    });
    builder.addCase(fetchProjectProcessMaterialDelete.fulfilled, (state) => {
      state.isLoadDeleteMaterial = false;
    });
    builder.addCase(fetchProcessMaterialOfferBack.pending, (state) => {
      state.isLoadOfferBack = true;
    });
    builder.addCase(fetchProcessMaterialOfferBack.fulfilled, (state) => {
      state.isLoadOfferBack = false;
    });
    builder.addCase(fetchProcessMaterialSelect.pending, (state) => {
      state.isLoadOfferSelect = true;
    });
    builder.addCase(fetchProcessMaterialSelect.fulfilled, (state) => {
      state.isLoadOfferSelect = false;
    });
  },
});
export default projectProcessMaterial;
