import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { ApiProjectAccessResponses } from "src/FSD/entities/projectAccess/model/responseTypes";

export const createGroupRequestDefaultValues = (
  groupRequestProcess: ApiProjectAccessResponses.GetGroupRequestStartForms,
  typeId: number,
  processId: number
) => {
  const { processes } = groupRequestProcess[typeId];

  return {
    common: {
      areAllSelected: false,
      dateStart: null,
      dateLimit: null,
      price: null,
    },
    processes: processes.map((process) => {
      return {
        processId: process.object.id,
        name: `${process.object.projectPart.code} ${process.object.projectPart.name}`,
        isActive: process.object.id === processId,
        dateStart: timestampToDate(process.date_start),
        dateLimit: timestampToDate(process.date_limit),
        price: process.price,
      };
    }),
  };
};
