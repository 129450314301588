import React, { memo } from "react";
import { ApiExpertiseRemarkResponses } from "src/FSD/entities/expertise/remark/model/responseTypes";
import { TableCardBody } from "./TableCardBody/TableCardBody";
import styles from "./TableCard.module.scss";

export const TableCard = memo(
  ({ header, remarkRows }: ApiExpertiseRemarkResponses.RemarkDataTypeValue) => {
    const rowsArray = Object.values(remarkRows);
    return (
      <>
        <tr>
          <td colSpan={6} className={styles.header}>
            {header}
          </td>
        </tr>
        {rowsArray?.map((el) => <TableCardBody key={el.row.id} data={el} />)}
      </>
    );
  }
);
