import { ReactNode } from "react";
import cn from "classnames";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IconButton } from "@mui/material";
import {
  RootNodeArrowClose,
  RootNodeArrowOpen,
} from "src/components/newUI/ToggleAccordion/ToggleAccordionSvg/ToggleAccordionSvg";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TDot } from "src/FSD/shared/lib/types/dot";
import { FolderIcon } from "./FolderIcon";
import styles from "./Accordion.module.scss";

interface TProps {
  title: ReactNode;
  classNameTitle?: string;
  titleAfter?: ReactNode;
  subTitle?: ReactNode;
  icon?: ReactNode;
  children: ReactNode;
  inWorkCount?: number;
  outDateCount?: number;
  doneCount?: number;
  expanded?: boolean;
  dot?: TDot;
  onExpand?: () => void;
  onShrink?: () => void;
  defaultOpen?: boolean;
  showIconValue?: boolean;
  iconOpen?: ReactNode;
  iconClose?: ReactNode;
  classNameAccordionRoot?: string;
  classNameSummaryRoot?: string;
  classNameDetailsRoot?: string;
}

export const Accordion = ({
  title,
  classNameTitle,
  titleAfter = "",
  subTitle,
  icon,
  children,
  dot,
  expanded,
  onExpand = () => {},
  onShrink = () => {},
  defaultOpen = false,
  showIconValue = true,
  iconOpen = <RootNodeArrowOpen className={styles.iconOpen} />,
  iconClose = <RootNodeArrowClose className={styles.iconClose} />,
  classNameAccordionRoot,
  classNameSummaryRoot,
  classNameDetailsRoot,
}: TProps) => {
  const subTitleStyle = typeof subTitle === "string";
  const iconValue = icon || <FolderIcon dot={dot} />;

  return (
    <AccordionMui
      onChange={(_, expanded) => {
        if (expanded) {
          onExpand?.();
        } else {
          onShrink?.();
        }
      }}
      expanded={expanded}
      classes={{
        root: cn(styles.accordion, classNameAccordionRoot),
        expanded: styles.accordion__expanded,
      }}
      defaultExpanded={defaultOpen}
    >
      <AccordionSummary
        expandIcon={
          <IconButton className={styles.icon}>
            {iconOpen}
            {iconClose}
          </IconButton>
        }
        classes={{
          root: cn(styles.summaryRoot, classNameSummaryRoot),
          content: styles.summaryContent,
          expanded: styles.summaryExpanded,
          expandIconWrapper: styles.summaryExpandIconWrapper,
        }}
      >
        {showIconValue && iconValue}
        <div>
          <div className={styles.headline}>
            {typeof title === "string" ? (
              <span className={cn(styles.title, classNameTitle)}>{title}</span>
            ) : (
              title
            )}
            {titleAfter}
          </div>
          {subTitle && (
            <div className={cn(subTitleStyle && styles.subTitle)}>
              {subTitle}
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: cn(styles.detailsRoot, classNameDetailsRoot),
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMui>
  );
};
