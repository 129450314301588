import { Controller } from "react-hook-form";
import { Button, Modal, Paragraph, TextField } from "src/FSD/shared/uiKit/v2";
import { SetState } from "src/FSD/shared/lib/types/setState";
import { SelectBankAccountByUserType } from "src/FSD/features/v2/SelectBankAccountByUserType/ui/SelectBankAccountByUserType";
import { SelectExecutorType } from "src/FSD/features/v2/SelectExecutorType";
import { useRequestForm } from "../../lib/hooks/useRequestForm";
import { TFormCache } from "../../model/types/fieldValues";

export type Props = {
  processId: number;
  setCurrentPage: SetState<number>;
  formCache: TFormCache;
  setFormCache: SetState<TFormCache>;
};

export const RequestForm = ({
  processId,
  setCurrentPage,
  formCache,
  setFormCache,
}: Props) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    watch,
    register,
    setValue,
    isSubmitting,
  } = useRequestForm({
    processId,
    setCurrentPage,
    formCache,
    setFormCache,
  });

  return (
    <Modal.Form>
      <Paragraph color="light">Данные исполнителя</Paragraph>
      <Controller
        name="executorType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectExecutorType
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
              setValue("bankAccount", null);
            }}
          />
        )}
      />
      <Controller
        name="bankAccount"
        control={control}
        render={({ field: { value }, fieldState: { error } }) => {
          const executorType = watch("executorType");

          return (
            <SelectBankAccountByUserType
              userType={executorType}
              value={value}
              setValue={setValue}
              disableClear
              error={Boolean(error)}
              helperText={error?.message}
              isBankAccount={!!value}
            />
          );
        }}
      />
      <TextField
        {...register("content")}
        label="Содержание заявки"
        multiline
        minRows={2}
      />
      <Modal.Controls>
        <Button
          onClick={() => {
            handleSubmit((dataValues) => onSubmit(dataValues, "back"))();
          }}
          color="secondary"
          type="button"
        >
          Назад
        </Button>
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            handleSubmit((dataValues) => onSubmit(dataValues, "request"))();
          }}
        >
          Опубликовать
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
