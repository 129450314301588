import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Card.module.scss";

type TProps = {
  children: ReactNode;
  location?: "top" | "center" | "right";
  width?: number;
  className?: string;
};

export const CardControls = ({
  children,
  location,
  width = 250,
  className,
}: TProps) => {
  if (!children) {
    return null;
  }

  return (
    <div
      style={width ? { flexBasis: `${width}px` } : undefined}
      className={cn(styles.controls, location && styles[location], className)}
    >
      {children}
    </div>
  );
};
