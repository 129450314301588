import React, { memo } from "react";
import { FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import cls from "./ModalGipRemove.module.scss";
import { palette, textFieldSX } from "../../../../../../../styles/restyle";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import {
  fetchGipObjectRemove,
  fetchProjectComplexObject,
} from "../../../../../../../app/feature/ProjectView/ComplexReducer/thunks";

const schema = Yup.object().shape({
  content: Yup.string()
    .required("Необходимо заполнить комментарий")
    .min(15, "Комментарий должен содержать не меньше 15 символов")
    .max(200, "Комментарий не может содержать больше 200 символов"),
});

interface Inputs {
  content: string;
}

interface ModalGipRemoveProps {
  id: number;
}
export const ModalGipRemove: React.FC<ModalGipRemoveProps> = memo(({ id }) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const formSubmitHandler = async (dataValues: Inputs) => {
    const data = {
      StartForm: {
        content: dataValues.content,
      },
    };
    await dispatch(fetchGipObjectRemove({ objectId: id, data }));
    if (params.id) {
      await dispatch(fetchProjectComplexObject({ id: +params.id }));
    }
  };
  return (
    <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.removeGip}>
      <h1>Заявка на снятие ГИП объекта</h1>
      <TextField
        {...textFieldSX}
        multiline
        minRows={2}
        placeholder="Введите комментарий"
        label="Комментарий к заявке"
        {...register("content")}
      />
      {errors.content ? (
        <p className={cls.error}>{errors.content.message}</p>
      ) : null}
      <FormControl className={cls.removeGip_btn}>
        <CustomButton background={palette.blue} width={160} type="submit">
          Отправить
        </CustomButton>
      </FormControl>
    </form>
  );
});
