import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { Nullable } from "../../../../../../../../types/generics";

type TProcess = {
  processId: number;
  isActive: boolean;
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  price: number | string;
};

export const createCurrentGroupProcesses = (processes: TProcess[]) => {
  return processes.reduce((processes, process) => {
    const { processId, dateStart, dateLimit, price, isActive } = process;

    if (!isActive) {
      return processes;
    }

    return {
      ...processes,
      [processId]: {
        date_start: toServerDate(dateStart),
        date_limit: toServerDate(dateLimit),
        price: Number(price),
      },
    };
  }, {});
};
