import React, { memo, useEffect } from "react";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { fetchProcessMaterialViewForm } from "../../../../../../app/feature/ProjectProcessView/Material/services/fetchProcessMaterialViewForm";
import {
  getProjectProcessMaterialViewForms,
  getProjectProcessMaterialViewFormsIsLoad,
} from "../../../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialViewForms";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import { AttrType } from "../../../../../../app/feature/ProjectProcessView/Material/types/projectProcessViewFormTypes";
import cls from "./ModalViewForm.module.scss";
import { FormWrapper } from "../../../../../newUI/FormWrapper/FormWrapper";
import { textFieldSX } from "../../../../../../styles/restyle";

interface ModalViewFormProps {
  materialId: number;
}
export const ModalViewForm = memo(({ materialId }: ModalViewFormProps) => {
  const dispatch = useAppDispatch();
  const viewForm = useAppSelector(getProjectProcessMaterialViewForms);
  const isLoadViewForm = useAppSelector(
    getProjectProcessMaterialViewFormsIsLoad
  );
  useEffect(() => {
    dispatch(fetchProcessMaterialViewForm(materialId));
  }, [materialId]);

  if (isLoadViewForm) {
    return <ModalPreloader />;
  }

  const renderGroup = (group: string, attr: AttrType) => {
    return (
      <tbody key={group}>
        <tr>
          <td>{attr.label}</td>
          <td>
            {attr.data.map((item) =>
              Array.isArray(item.value) ? (
                <TextField select {...textFieldSX} value={item.value} disabled>
                  {item.value.map((value: any) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </TextField>
              ) : (
                <TextField {...textFieldSX} value={item.value} disabled />
              )
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <FormWrapper title={"Опросный лист"}>
      {viewForm?.map((form) => (
        <table key={form.group} className={cls.table}>
          <thead>
            <tr>
              <th colSpan={2}>{form.group}</th>
            </tr>
          </thead>
          {form.attrs.map((attr) => renderGroup(form.group, attr))}
        </table>
      ))}
    </FormWrapper>
  );
});
