import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "src/app/services/api/requestHandler";
import {
  ApiCompareGip,
  ApiTaskComplete,
} from "src/app/services/api/project/project";
import { ApiUserTypeType } from "src/types/api/primaryTypes/apiUserTypeType";

export interface ProjectCompareGipReturn {
  id: number;
  user: {
    id: number;
    name: string;
    surname: string;
    patronymic: string;
    photo: string;
    job_duration: number;
    rating: {
      gip: number;
    };
  };
  task: {
    content: string;
    id: number;
  };
  task_id: number;
  room_id: number;
  userType: ApiUserTypeType;
  price: number;
}
export const fetchProjectCompareGip = createAsyncThunk<
  ProjectCompareGipReturn[],
  number
>("projectCompareGip/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiCompareGip(id);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchTaskComplete = createAsyncThunk<any, any>(
  "taskCompleteFetch/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiTaskComplete(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
