import React from "react";
import "./style.scss";
import "ckeditor5/ckeditor5.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";
import { editorConfig } from "./editorConfig";

/**
 * Редактор текста.
 *
 * На время написания CKEditor не поддерживает TS, поэтому создается в jsx.
 *
 * В качестве редактора был выбран CKEditor 5 {@link https://ckeditor.com/ckeditor-5/}.
 *
 * Общая документация {@link https://ckeditor.com/docs/ckeditor5/latest/index.html}
 *
 * Обзор для внедрения во фреймворк {@link https://ckeditor.com/docs/ckeditor5/latest/framework/guides/overview.html}
 *
 * Билды для фреймворков в меню слева папка Frameworks {@link https://ckeditor.com/docs/ckeditor5/latest/builds/index.html}
 *
 * Документация по CKEditor довольно объемная, и сходу разобраться не просто,
 * но CKEditor предоставляет онлайн сборку которую можно собрать по требования бизнеса
 *
 * Онлайн сборщик {@link https://ckeditor.com/ckeditor-5/online-builder/}
 *
 * После того как вы собрали и скачали сборку
 *  инструкция где расположить сборку
 *  {@link https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html#integrating-a-build-from-the-online-builder}
 *
 * Обертка CKEditorWrapper использует билд в качестве основы.
 * Билд содержит все необходимые плагины для редактирования.
 * Если нужно перебрать плагины, необходимо зайти на страницу {@link https://ckeditor.com/ckeditor-5/online-builder/} и создать билд.
 **/

export class CKEditorWrapper extends React.Component {
  render() {
    const { onBlur, changeHandler, data = "", ref } = this.props;
    return (
      <div>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          ref={ref}
          data={data}
          onBlur={onBlur}
          onChange={(event, editor) => {
            const data = editor.getData();
            changeHandler(data);
          }}
        />
      </div>
    );
  }
}

export const CKEditorRefWrapper = ({ reference }) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfig}
        data={reference.current}
        onChange={(event, editor) => {
          reference.current = editor.getData();
        }}
      />
    </div>
  );
};
