import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getProjectViewData,
  setError,
} from "src/app/feature/ProjectView/projectView";
import { Applications } from "src/FSD/widgets/v2/project/Applications";
import { getProjectInitialData } from "src/app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { getProjectUsersList } from "src/app/feature/ProjectView/AdminReducer/projectAdmin";
import { getProjectCompareGip } from "src/app/feature/ProjectView/CompareReducer/projectCompareGip";
import { clearAllApplications } from "src/app/feature/ProjectView/GroupApplications";
import { TTabsList } from "src/app/feature/ProjectView/thunks";
import { fetchProjectCompareGip } from "src/app/feature/ProjectView/CompareReducer/thunks";
import { Preloader } from "src/components/features";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import { Admin } from "src/components/pages/Project/NewView/Admin/Admin";
import { CheckList } from "src/components/pages/Project/NewView/CheckList/CheckList";
import { CheckTasks } from "src/components/pages/Project/NewView/CheckTasks/CheckTasks";
import { CompareGip } from "src/components/pages/Project/NewView/CompareGip/CompareGip";
import { Documents } from "src/FSD/widgets/v2/project/Documents";
import Graph from "src/FSD/widgets/v2/project/Graph";
import { History } from "src/components/pages/Project/NewView/History/History";
import { Info } from "src/FSD/widgets/v2/project/Info";
import Composition from "src/FSD/widgets/v2/project/Composition";
import { SourceData } from "src/FSD/widgets/v2/project/SourceData";
import { Milestone } from "src/components/pages/Project/NewView/Milestone/Milestone";
import Sections from "src/components/pages/Project/NewView/Sections/Sections";
import { Tasks } from "src/components/pages/Project/NewView/Tasks/Tasks";
import { ProjectHeader } from "src/FSD/pages/v2/project/ui/ProjectHeader";
import { useProjectViewPage } from "src/FSD/pages/v2/project/lib/hooks/useProjectViewPage";
import { Objects } from "src/FSD/widgets/v2/project";
import { fetchTabsData } from "./helpers/fetchTabsData";
import styles from "./ProjectView.module.scss";
import { ApiProjectResponses } from "../../../../FSD/entities/project/model/responseTypes";

export type DeviceType = "desktop" | "mobile" | "tablet";

export const ProjectView = () => {
  const params = useParams<{ id: string }>();

  const projectId = Number(params.id);

  const { device, breadcrumbs } = useProjectViewPage({
    id: projectId,
  });

  const { project, error, isLoadDelete } = useSelector(getProjectViewData);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateInitialData } = useSelector(getProjectInitialData);
  const { updateUsers } = useSelector(getProjectUsersList);
  const { compareGip } = useSelector(getProjectCompareGip);

  const [tabs, setTabs] = useState<TTabsList>();
  const [isContentLoad, setContentLoad] = useState(true);
  const [currentTab, setCurrentTab] = useState("info");

  const setDefaultTab: () => void = useCallback(() => {
    setCurrentTab("info");
  }, []);

  useEffect(() => {
    if (project?.tabs) {
      setTabs(project.tabs);
    }
  }, [project, compareGip]);

  useEffect(() => {
    if (updateInitialData) {
      setCurrentTab("sourceData");
    }
    if (updateUsers) {
      setCurrentTab("admin");
    }
    setCurrentTab("info");
  }, [params.id]);

  useEffect(() => {
    if (projectId) {
      fetchTabsData(currentTab, projectId, setContentLoad, dispatch);
    }
    if (updateInitialData) {
      enqueueSnackbar("Данные успешно обновлены", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (currentTab !== "applications") {
      dispatch(clearAllApplications());
    }
  }, [currentTab, updateInitialData, location.pathname]);

  useEffect(() => {
    if (projectId && project?.gip === null) {
      dispatch(fetchProjectCompareGip(projectId));
      setContentLoad(false);
    }
  }, [project]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
      dispatch(setError());
    }
  }, [error]);

  const componentsList: ApiProjectResponses.ComponentsType = {
    admin: <Admin />,
    applications: <Applications projectId={projectId} />,
    checklist: <CheckList setDefaultTab={setDefaultTab} />,
    checks: <CheckTasks setDefaultTab={setDefaultTab} />,
    compareGip: <CompareGip setDefaultTab={setDefaultTab} />,
    composition: <Composition />,
    documents: <Documents />,
    graph: <Graph />,
    history: <History setDefaultTab={setDefaultTab} />,
    info: <Info />,
    objects: <Objects projectId={projectId} isEdit={project?.can?.edit} />,
    processes: <Sections cost={project?.processPublishedCost} />,
    sourceData: <SourceData />,
    stages: <Milestone />,
    tasks: <Tasks setDefaultTab={setDefaultTab} />,
  };

  return (
    <div className={styles.wrapper}>
      <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />
        {isLoadDelete && <Preloader variant={styles.preloaderSections} />}
        <TabsPanel
          title={project?.name ? `${project?.code} ${project.name}` : ""}
          subTitle={project?.status ?? ""}
          // Todo отключаем валидацию project до полного перехода на rtq
          tabsList={tabs}
          componentsList={componentsList}
          device={device}
          // Todo отключаем валидацию project до полного перехода на rtq
          // @ts-ignore
          actions={<ProjectHeader project={project} />}
        />
      </PageWrapper>
    </div>
  );
};
