import { memo, ReactNode } from "react";
import { ListItemText } from "@mui/material";
import cn from "classnames";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Checkbox } from "../Checkbox";
import styles from "./styles.module.scss";
import { getNewLightValue } from "./utils";

type IsOptionEqualToValue = (
  option: TSelectItem,
  value: TSelectItem
) => boolean;

type Props = {
  value: TSelectItem[];
  label: string;
  placeholder?: string;
  changeHandler: (newValue: TSelectItem[]) => void;
  options: TSelectItem[];
  canSelectAll?: boolean;
  isLoading?: boolean;
  isOptionEqualToValue?: IsOptionEqualToValue;
  helperText?: ReactNode;
  error?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  optionsClassName?: string;
  id?: string;
  onBlur?: () => void;
};

export const SelectMultipleLight = (props: Props) => {
  const {
    value = [],
    label,
    placeholder,
    changeHandler,
    options,
    canSelectAll = false,
    helperText,
    error = false,
    disablePortal = true,
    isLoading = false,
    disabled = false,
    optionsClassName = "",
    onBlur = () => {},
    ...other
  } = props;

  const handleChange = (_: unknown, newValue: TSelectItem[]) => {
    const newOptions = getNewLightValue(options, newValue);
    changeHandler(newOptions);
  };

  return (
    <Autocomplete
      {...other}
      multiple
      disableCloseOnSelect
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => option.title}
      value={value}
      onBlur={onBlur}
      loading={isLoading}
      disabled={disabled}
      disablePortal={disablePortal}
      componentsProps={{
        paper: {
          sx: {
            width: 300,
          },
        },
      }}
      renderOption={(props, item) => {
        const isChecked = value.findIndex((value) => value.id === item.id) > -1;

        return (
          <li
            {...props}
            className={cn(styles.option, isChecked && styles.option_checked)}
          >
            <Checkbox onChange={() => {}} checked={isChecked} />
            <ListItemText>{item.title}</ListItemText>
          </li>
        );
      }}
      renderInput={(props) => {
        delete props.InputProps.startAdornment;

        return (
          <TextField
            {...props}
            label={label}
            sx={{
              input: {
                "&::placeholder": {
                  opacity: value.length ? 1 : 0.4,
                },
              },
            }}
            InputLabelProps={{ shrink: true }}
            placeholder={value.length ? `Выбрано ${value.length}` : placeholder}
            error={error}
            helperText={helperText}
          />
        );
      }}
    />
  );
};

export default memo(SelectMultipleLight);
