import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { mainPageInitialState } from "./mainPageInitialState";
import C from "./constants";
import { StateType } from "../../initialState";
import { ApiMainPage } from "../../../types/api/responseTypes/mainPage";
import {
  createExecutorsList,
  createNewsList,
  createProjectsList,
} from "./createData/createData";
import { ApiProjectDataType } from "../../../types/api/responseTypes/apiProjectsType";
import { ApiPrimaryUserObjectType } from "../../../types/api/primaryTypes/apiPrimaryUserType";
import { ApiNews } from "../../../types/api/responseTypes/apiNews";

export const mainPageReducer = createReducer(
  mainPageInitialState,
  (builder) => {
    /*
            Установка новостей на главную страницу
         */

    builder.addCase(
      C.SET_MAIN_PAGE_NEWS,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          news: createNewsList(action.payload.news),
        };
      }
    );

    /*
            Установка счетчиков на главную страницу
         */

    builder.addCase(
      C.SET_MAIN_PAGE_COUNTS,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          counts: action.payload.counts,
        };
      }
    );

    /*
            Установка счетчиков на главную страницу
         */

    builder.addCase(
      C.SET_MAIN_PAGE_PROJECTS_COUNTS,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          projectsCounts: action.payload.projectsCounts,
        };
      }
    );

    /*
          Установка проектов на главную страницу
        */

    builder.addCase(
      C.SET_MAIN_PAGE_PROJECTS,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          projects: createProjectsList(action.payload.projects),
        };
      }
    );

    /*
        Установка значений проектировщиков по ретингу
     */

    builder.addCase(
      C.SET_MAIN_PAGE_EXECUTORS_BY_RATING,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          executors: {
            ...state.executors,
            rating: createExecutorsList(action.payload.rating),
          },
        };
      }
    );

    /*
          Установка значений проектировщиков по опыту
       */

    builder.addCase(
      C.SET_MAIN_PAGE_EXECUTORS_BY_EXPERIENCE,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          executors: {
            ...state.executors,
            experience: createExecutorsList(action.payload.experience),
          },
        };
      }
    );

    /*
          Установка значений проектировщиков по регистрации
       */

    builder.addCase(
      C.SET_MAIN_PAGE_EXECUTORS_BY_NEW,
      (state, action: PayloadAction<ApiMainPage>) => {
        return {
          ...state,
          executors: {
            ...state.executors,
            new: createExecutorsList(action.payload.new),
          },
        };
      }
    );
  }
);

export const selectorMainPageCounts = (state: StateType) =>
  state.mainPage.counts;

export const selectorMainPageProjectsCounts = (state: StateType) =>
  state.mainPage.projectsCounts;

export const selectorMainPageProjects = (state: StateType) =>
  state.mainPage.projects;

export const selectorMainPageExecutors = (state: StateType) =>
  state.mainPage.executors;

export const selectorMainPaNews = (state: StateType) => state.mainPage.news;

export const news = (news: ApiNews[]) => ({
  type: C.SET_MAIN_PAGE_NEWS,
  payload: {
    news,
  },
});

export const pageCounts = (counts: Record<string, number>) => ({
  type: C.SET_MAIN_PAGE_COUNTS,
  payload: { counts },
});

export const pageProjectsCounts = (projectsCounts: number) => ({
  type: C.SET_MAIN_PAGE_PROJECTS_COUNTS,
  payload: { projectsCounts },
});

export const projects = (projects: ApiProjectDataType[]) => ({
  type: C.SET_MAIN_PAGE_PROJECTS,
  payload: { projects },
});

export const executorsByRating = (rating: ApiPrimaryUserObjectType[]) => ({
  type: C.SET_MAIN_PAGE_EXECUTORS_BY_RATING,
  payload: {
    rating,
  },
});

export const executorsByExperience = (
  experience: ApiPrimaryUserObjectType[]
) => ({
  type: C.SET_MAIN_PAGE_EXECUTORS_BY_EXPERIENCE,
  payload: {
    experience,
  },
});

export const executorsByNovelty = (newExecutors: ApiPrimaryUserObjectType) => ({
  type: C.SET_MAIN_PAGE_EXECUTORS_BY_NEW,
  payload: {
    new: newExecutors,
  },
});
