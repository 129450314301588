import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import style from "../style.module.scss";
import { request } from "../../../../app/services/api/requestHandler";
import { Select } from "../../../UI/components/FormComponentns/Select";
import {
  ApiGetMyFirm,
  ApiRequestJoinFirm,
} from "../../../../app/services/api/user/user";
import { selectorUserPageHeaderData } from "../../../../app/feature/userPage/userPage";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import { TSelectItem } from "../SetGipForm/types";

type TJoinUserFirmForm = {
  textArea: string;
  firm: TSelectItem | null;
};

const defaultValues: TJoinUserFirmForm = {
  textArea: "Приглашаю стать сотрудником организации!",
  firm: null,
};

export interface SearchGipProjectFormProps {
  submitHandler?: ({
    price,
    responsibilityType,
  }: {
    price: number;
    responsibilityType: number[];
  }) => void;
  closeHandler: VoidFunction;
}

const schema = Yup.object().shape({
  firm: Yup.mixed().test({
    test: (value) => {
      return !!value;
    },
    message: "Поле обязательно для заполнения",
  }),
  textArea: Yup.string()
    .required("Поле обязательно для заполнения")
    .min(10, "Минимум 10 символов")
    .max(2000, "Максимум 2000 символов"),
});

// Форма приглашения пользователя в проект

export const JoinUserFirmForm: React.FC<SearchGipProjectFormProps> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [disabled, setDisabled] = useState(false);
  const [myFirms, setMyFirms] = useState([]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<TJoinUserFirmForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const watchFirm = watch("firm");

  const headerData = useSelector(selectorUserPageHeaderData);
  const { Popup } = useUserWorkflow();

  const onSubmit = async (data: TJoinUserFirmForm) => {
    setDisabled(true);

    await request(
      ApiRequestJoinFirm(headerData!.id!, {
        StartForm: {
          content: data.textArea,
          // @ts-ignore
          firmId: Number(data.firm!.id!),
        },
      }),
      () => {
        enqueueSnackbar("Успешно", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          Popup.joinUserFirm.close();
        }, 300);
      },
      () => () => {
        enqueueSnackbar("Ошибка приглашения, сообщите администратору в чате", {
          variant: "error",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          Popup.joinUserFirm.close();
        }, 300);
      }
    )();

    setDisabled(false);
  };

  useEffect(() => {
    request(ApiGetMyFirm(), (data) => {
      setValue("firm", data[0]);
      setMyFirms(data);
    })();
  }, []);

  return (
    <div className={style.formWrapper} style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          label={"Организация"}
          options={myFirms}
          defaultValue={myFirms[0]}
          value={watchFirm}
          error={Boolean(errors.firm)}
          helperText={errors.firm?.message}
          changeHandler={(_, value) => {
            setValue("firm", value);
            trigger("firm");
          }}
        />
        <br />
        <TextField
          {...register("textArea")}
          name={"textArea"}
          label={"Комментарий"}
          multiline
          maxRows={5}
          rows={5}
          error={Boolean(errors.textArea)}
          helperText={errors.textArea?.message}
        />
        <br />
        <div className={style.buttonWrapper}>
          <CustomButton
            disabled={disabled}
            type="submit"
            background={palette.blue}
            width={160}
          >
            Отправить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
