import * as Yup from "yup";
import { messages, numberRequired } from "src/utils/SimplifiedYup";
import { formatLongNumber } from "../../../../../../../../utils/formatLongNumber";

export const freezeFundsSchema = Yup.object().shape({
  price: numberRequired("Сумма без налога (НДС)")
    .min(0, messages.minPrice("Сумма без налога (НДС)", formatLongNumber(0)))
    .test({
      name: "maxPrice",
      test(value) {
        if (!this.parent.maxDeposit) {
          return true;
        }

        const { maxDeposit } = this.parent;

        return value <= maxDeposit
          ? true
          : this.createError({
              message: messages.maxPrice("Сумма без налога (НДС)", maxDeposit),
            });
      },
    })
    .test("shouldBeIntNumber", messages.shouldBeIntNumber, (value: any) => {
      return !Number.isNaN(value) && Number.isInteger(value);
    }),
});
