import { Rating } from "src/FSD/shared/uiKit/v2/Rating";
import styles from "./Application.module.scss";

type TProps = {
  rating: number;
  jobDuration?: number;
  fontSize?: number;
};

export const ApplicationRating = ({
  rating,
  jobDuration,
  fontSize = 20,
}: TProps) => {
  return (
    <div className={styles.card__rating}>
      <Rating.StarExperience
        rating={rating}
        jobDuration={jobDuration}
        fontSize={fontSize}
      />
    </div>
  );
};
