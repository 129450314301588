import { Accordion, List } from "src/FSD/shared/uiKit/v2";
import { ExecutorCard } from "./ExecutorCard";
import { useContext } from "react";
import { ApplicationsContext } from "../Applications";

export const Executors = () => {
  const {
    applications,
    accordionState: { handleExpand, handleShrink, expandedIds },
  } = useContext(ApplicationsContext);

  return (
    <List margin={15} gap={0}>
      {Object.keys(applications).map((groupId) => {
        const { group, userTypes } = applications[groupId];

        const groupName = `${group.name} (${Object.keys(userTypes).length})`;

        const isExpanded = expandedIds.includes(groupId);

        return (
          <Accordion
            expanded={isExpanded}
            onExpand={() => handleExpand(groupId)}
            onShrink={() => handleShrink(groupId)}
            title={groupName}
          >
            <List margin={0}>
              {Object.keys(userTypes).map((id) => (
                <ExecutorCard
                  key={id}
                  executor={userTypes[id]}
                  groupId={Number(groupId)}
                />
              ))}
            </List>
          </Accordion>
        );
      })}
    </List>
  );
};

// maxHeight="865px"
