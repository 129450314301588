import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoName,
  getProjectProcessInfoProjectId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { Uploader } from "../../../../../features/Uploader/Uploader";
import { config } from "src/app.cofig";
import { palette, textFieldSX } from "src/styles/restyle";
import cls from "../../../HeaderBtn/ModalPreliminaryRequest/ModalPreliminaryRequest.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { fetchProjectProcessDonePreliminary } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessDonePreliminary";
import { fetchProjectProcessToProcesses } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessToProcesses";
import {
  getProjectProcessToProcessType,
  getProjectProcessToProcessTypeIsLoad,
} from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessToProcessType";
import { fetchProjectProcessDocumentations } from "src/app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { SelectField } from "../../../../../newUI/SelectMui/SelectMui";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";

type TInputs = {
  comment: string;
  status: number;
  upload_files: any;
  reviewProcessIds: string[];
  name: string;
};

interface ModalPreliminaryDoneProps {
  closeModal: () => void;
  header?: string;
}

const schema = Yup.object().shape({
  upload_files: Yup.array()
    .min(1, "Необходимо загрузить хотя бы один файл")
    .required("Необходимо загрузить хотя бы один файл"),
});

export const ModalPreliminaryDone = memo(
  ({ closeModal, header = "" }: ModalPreliminaryDoneProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const projectId = useAppSelector(getProjectProcessInfoProjectId);
    const processType = useAppSelector(getProjectProcessToProcessType);
    const name = useAppSelector(getProjectProcessInfoName);
    const isLoadProcessType = useAppSelector(
      getProjectProcessToProcessTypeIsLoad
    );

    const [valueMulti, setValueMulti] = useState<any>([]);

    const actualHeader =
      header || `Выдача предварительного решения по разделу ${name}`;

    const nameDefaultValue = "";

    useEffect(() => {
      if (projectId && processId) {
        dispatch(
          fetchProjectProcessToProcesses({ processId, projectId, all: 1 })
        );
      }
    }, []);

    const {
      handleSubmit,
      setValue,
      register,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const formData = new FormData();
      formData.append("name", dataValues.name);
      formData.append("status", "6");
      formData.append("comment", dataValues.comment);
      dataValues.reviewProcessIds?.forEach((review) => {
        formData.append("reviewProcessIds[]", review);
      });
      dataValues.upload_files.forEach((file: BinaryType) => {
        formData.append("upload_files[]", file);
      });
      if (processId) {
        await dispatch(
          fetchProjectProcessDonePreliminary({ id: processId, data: formData })
        );
        await dispatch(fetchProjectProcessDocumentations(processId));
        await dispatch(fetchProjectProcessViewDuplicate(processId));
      }
      closeModal();
    };

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all") && processType) {
        const all = Object.keys(processType);
        setValueMulti(all);
        setValue("reviewProcessIds", all);
      } else {
        setValueMulti(event.target.value);
        setValue("reviewProcessIds", event.target.value);
      }
    };

    const handleClearClick = () => {
      setValueMulti([]);
      setValue("reviewProcessIds", []);
    };
    //
    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{actualHeader}</h1>
        <TextField
          {...textFieldSX}
          label="Наименование"
          {...register("name")}
          defaultValue={nameDefaultValue}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
        <div>
          <Uploader
            canBeEdited
            autoUpload={false}
            identifier={null}
            postUrlString={(id) =>
              `${config.localDomain}/v1/task/add-file?id=${id}`
            }
            fileHandler={(data: any) => {
              setValue("upload_files", data);
            }}
          />
          {errors.upload_files && (
            <div className={cls.error}>
              <p>{errors.upload_files.message}</p>
            </div>
          )}
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={4}
          placeholder="Введите комментарий"
          label="Комментарий"
          {...register("comment")}
        />
        {errors.comment ? (
          <p className={cls.error}>{errors.comment.message}</p>
        ) : null}
        <SelectField
          {...register("reviewProcessIds")}
          name="reviewProcessIds"
          label="Проверяющие"
          multiple
          multiValue={valueMulti}
          isAllClick
          onChange={onChangeMulti}
          options={Object.entries(processType)}
          handleClearClick={handleClearClick}
          disabled={isLoadProcessType}
          error={
            errors.reviewProcessIds ? errors.reviewProcessIds.message : null
          }
        />
        <CustomButton
          type="submit"
          width={160}
          background={palette.blue}
          className={cls.form_submit}
        >
          Отправить
        </CustomButton>
      </form>
    );
  }
);
