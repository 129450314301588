import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField,
} from "@mui/material";
import moment from "moment";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";
import cls from "./style.module.scss";
import { palette, textFieldSX } from "../../../../../../styles/restyle";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import CustomCheckbox from "../../../../../newUI/CustomCheckbox/CustomCheckbox";
import {
  fetchGetUsersToAssign,
  fetchGetUsersTypeToAssign,
  fetchPublishInfo,
  fetchPublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { getProjectSections } from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { useDocsValue, useSecureValue } from "./useSecureAndDocsValue";
import { ModalSchema } from "../ModalSchema";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import { ModalPreloader } from "../../../../../newUI/Modal/ModalPreloader";
import globalStyle from "../ProcessPublication/style.module.scss";
import {
  Select as NewSelect,
  Select,
} from "../../../../../UI/components/FormComponentns/Select";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { MultipleSelect } from "../../../../../UI/components/FormComponentns/MultipleSelect";
import { getExecutorFullTypes } from "../../../../../../app/feature/project/projectProcessPublication/thunks";
import { useAppSelector } from "../../../../../../app/store";
import { getGroupPublishProcessData } from "../../../../../../app/feature/project/projectProcessPublication";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

interface PublishModalContentProps {
  node: TValuesData;
  closeModal: () => void;
}

const secureValueList = ["Нет", "Да"];

export const sx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

interface Inputs {
  price: number | null;
  secure: string;
  with_docs: string;
  type: TSelectItem[];
  date_start: any;
  date_limit: any;
  vor: boolean;
  vor_pnr: boolean;
  kac: boolean;
  executor: TSelectItem | null;
  executorType: TSelectItem | null;
  pay1: number;
  pay2: number;
  pay3: number;
  saveStatus: number;
  paymentSum: number;
  project_id: string;
}

export const PublishModalContent: FC<PublishModalContentProps> = memo(
  ({ node, closeModal }) => {
    const { project } = useSelector(getProjectViewData);
    const dispatch = useDispatch();

    const {
      executors,
      isLoadExecutor,
      TypeExecutors,
      error422,
      publish,
      publishInfo,
      isLoadPublishInfo,
      pending,
    } = useSelector(getProjectSections);

    const {
      data: { fullTypes },
    } = useAppSelector(getGroupPublishProcessData);

    const {
      id,
      haveExpertise,
      projectPart,
      price,
      secure,
      vor,
      vor_pnr,
      with_docs,
      date_limit,
      date_start,
      kac,
    } = node;
    const canAllCheck = vor && vor_pnr && kac;
    const additional =
      publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
    const defaultAgreementPrice = price === "Договорная";

    const [executorDiv, setExecutorDiv] = useState(false);
    const [payDiv, setPayDiv] = useState(false);
    const [docsValueList, setDocsValueList] = useState(["Нет", "Да"]);
    const [docsChangeValue, setDocsChangeValue] = useState(
      with_docs ? "Да" : "Нет"
    );

    const [checkPrice, setCheckPrice] = useState(defaultAgreementPrice);
    const [checkVor, setCheckVor] = useState(vor);
    const [checkVorPnr, setCheckVorPnr] = useState(vor_pnr);
    const [checkKac, setCheckKac] = useState(kac);
    const [checkAll, setCheckAll] = useState(canAllCheck);
    const [firstRender, setFirstRender] = useState(true);

    const schema = ModalSchema(haveExpertise, checkPrice);

    const isAddExecutorBtn = Object.keys(executors).length !== 0;

    const start = date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null;
    const end = date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null;

    useEffect(() => {
      setFirstRender(false);
    }, []);

    const {
      register,
      handleSubmit,
      control,
      setValue,
      setError,
      watch,
      trigger,
      clearErrors,
      formState: { errors },
    } = useForm<Inputs>({
      resolver: yupResolver(schema),
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        pay1: project?.advance_pay,
        pay2:
          !haveExpertise && project
            ? project.expertisePay + project.lastPay
            : project?.lastPay,
        pay3: project?.expertisePay,
        price: defaultAgreementPrice ? null : +price,
        secure: secure ? "Да" : "Нет",
        with_docs: with_docs ? "Да" : "Нет",
        date_start: start,
        date_limit: end,
        vor: !!vor,
        vor_pnr: !!vor_pnr,
        kac: !!kac,
        type: [],
      },
    });

    useEffect(() => {
      if (!payDiv && project) {
        setValue("pay1", project.advance_pay);
        setValue(
          "pay2",
          !haveExpertise
            ? project.expertisePay + project.lastPay
            : project.lastPay
        );
        setValue("pay3", project.expertisePay);
      }
    }, [payDiv]);

    useEffect(() => {
      // при изменении checkAll меняем состояние всех чекбоксов
      if (!firstRender) {
        setCheckVor(checkAll);
        setCheckVorPnr(checkAll);
        setCheckKac(checkAll);
      }
    }, [checkAll]);

    useEffect(() => {
      if (!checkVor && !checkVorPnr && !checkKac) {
        setCheckAll(false);
      }
      if (checkVor && checkVorPnr && checkKac) {
        setCheckAll(true);
      }
    }, [checkVor, checkVorPnr, checkKac]);

    const handleCheckAll = () => {
      setCheckAll(!checkAll);
    };
    const watchType = watch("type");
    const formSubmitHandler = async (dataValues: Inputs) => {
      const data = {
        agreementPrice: checkPrice,
        vor: !publishInfo.vor ? false : checkVor,
        vor_pnr: !publishInfo.vor_pnr ? false : checkVorPnr,
        kac: !publishInfo.kac ? false : checkKac,
        price: (!checkPrice && dataValues.price) || null,
        date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        with_docs: !publishInfo.canNoSecure ? true : docsChangeValue !== "Нет",
        secure: !publishInfo.canNoSecure ? true : dataValues.secure !== "Нет",
        type: watchType.map((item: TSelectItem) => item.id),
        pay1: payDiv ? +dataValues.pay1 : null,
        pay2: payDiv ? +dataValues.pay2 : null,
        pay3: payDiv && haveExpertise ? +dataValues.pay3 : null,
        executorByAdminId:
          executorDiv && dataValues.executor?.id
            ? dataValues.executor.id
            : null,
        executorTypeByAdminId:
          executorDiv && dataValues.executorType?.id
            ? dataValues.executorType.id
            : null,
        save: dataValues.saveStatus,
      };
      await dispatch(fetchPublishProcess({ id, data }));
      if (error422) {
        await parseUnprocessableFields(error422, setError);
      }
    };

    const addExecutorClick = async () => {
      setExecutorDiv(!executorDiv);
    };

    const executorValue = watch("executor");
    const executorTypeValue = watch("executorType");

    const secureValue = watch("secure");

    useEffect(() => {
      if (secureValue === "Да") {
        setDocsChangeValue("Да");
      }
    }, [secureValue]);

    useEffect(() => {
      dispatch(fetchGetUsersToAssign(id));
      dispatch(fetchPublishInfo({ id }));

      // получение списка форм собственности
      dispatch(getExecutorFullTypes());
    }, []);

    // useEffect(() => {
    //   if (executorValue) {
    //     dispatch(
    //       fetchGetUsersTypeToAssign({ id, executorValue: executorValue.id })
    //     );
    //   }
    // }, [executorValue, dispatch, id]);

    useEffect(() => {
      if (publish) {
        clearErrors();
        closeModal();
      }
    }, [publish]);

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    // получение возможных значений для селекта docs and secure
    useSecureValue(secureValue, setDocsValueList);
    useDocsValue(docsChangeValue, publishInfo.canNoSecure, setPayDiv);

    const changeHandler = (data: (TSelectItem | undefined)[]) => {
      if (data.includes(undefined)) {
        setValue(
          "type",
          fullTypes.length === watchType.length ? [] : fullTypes
        );

        trigger("type");

        return;
      }

      setValue("type", data as TSelectItem[]);
      trigger("type");
    };

    // управление полями "назначенный исполнитель" и "Форма собственности"

    useEffect(() => {
      if (executorValue) {
        setValue("executorType", null);
        dispatch(
          fetchGetUsersTypeToAssign({
            id,
            executorValue: executorValue.id as number,
          })
        );
      }
    }, [executorValue, dispatch, id]);

    useEffect(() => {
      if (executors.length && !pending.executors) {
        setValue("executor", executors[0]);
      }
    }, [pending.executors]);

    useEffect(() => {
      if (TypeExecutors.length && !pending.TypeExecutors) {
        setValue("executorType", TypeExecutors[0]);
      }
    }, [pending.TypeExecutors]);

    // **************

    if (isLoadExecutor || isLoadPublishInfo) {
      return <ModalPreloader />;
    }

    const priceRender = (
      <div className={cls.form_flex}>
        <label className={cls.form_label}>
          <CustomCheckbox
            editing
            onChange={() => setCheckPrice(!checkPrice)}
            checked={checkPrice}
          />
          Договорная стоимость
        </label>
        {!checkPrice && (
          <div className={cls.form_price}>
            <TextField
              {...register("price")}
              {...textFieldSX}
              label="Стоимость"
              defaultValue={price}
              error={!!errors.price}
            />
            {errors.price && (
              <p className={cls.error}>{errors.price.message}</p>
            )}
          </div>
        )}
      </div>
    );

    const secureAndDocsRender = publishInfo.canNoSecure && (
      <div className={cls.form_flex}>
        <FormControl {...textFieldSX}>
          <InputLabel id="secure">Безопасная сделка</InputLabel>
          <MuiSelect
            {...register("secure")}
            labelId="secure"
            label="Безопасная сделка"
            defaultValue={secure ? "Да" : "Нет"}
            error={!!errors.secure}
          >
            {secureValueList.map((value) => (
              <MenuItem key={value} sx={sx} value={value}>
                {value}
              </MenuItem>
            ))}
          </MuiSelect>
          {errors.secure && (
            <p className={cls.error}>{errors.secure.message}</p>
          )}
        </FormControl>
        <FormControl {...textFieldSX}>
          <InputLabel id="docs">Договора и Акты</InputLabel>
          <MuiSelect
            {...register("with_docs")}
            labelId="docs"
            label="Договора и Акты"
            value={docsChangeValue}
            onChange={(e) => setDocsChangeValue(e.target.value)}
          >
            {docsValueList.map((value) => (
              <MenuItem key={value} sx={sx} value={value}>
                {value}
              </MenuItem>
            ))}
          </MuiSelect>
          {errors.with_docs && (
            <p className={cls.error}>{errors.with_docs.message}</p>
          )}
        </FormControl>
      </div>
    );

    const payRender = (
      <>
        <div className={cls.form_pay}>
          <FormControl className={cls.form_pay_first}>
            <TextField
              {...register("pay1")}
              {...textFieldSX}
              label="Аванс (%)"
              defaultValue={project?.advance_pay}
              error={!!errors.pay1}
            />
            {errors.pay1 && <p className={cls.error}>{errors.pay1.message}</p>}
          </FormControl>
          <FormControl className={cls.form_pay_last}>
            <TextField
              {...register("pay2")}
              {...textFieldSX}
              label="Оплата при завершении (%)"
              defaultValue={
                !haveExpertise
                  ? project!.expertisePay + project!.lastPay
                  : project?.lastPay
              }
              error={!!errors.pay2}
            />
            {errors.pay2 && <p className={cls.error}>{errors.pay2.message}</p>}
          </FormControl>
          {haveExpertise && projectPart.published && (
            <FormControl className={cls.form_pay_second}>
              <TextField
                {...register("pay3")}
                {...textFieldSX}
                label="Оплата после экспертизы (%)"
                defaultValue={project?.expertisePay}
                error={!!errors.pay3}
              />
              {errors.pay3 && (
                <p className={cls.error}>{errors.pay3.message}</p>
              )}
            </FormControl>
          )}
          <input type="hidden" {...register("paymentSum")} />
        </div>
        {errors.paymentSum && (
          <p className={cls.form_pay_paymentSum}>{errors.paymentSum.message}</p>
        )}
      </>
    );

    const typeRender = (
      <MultipleSelect
        value={watchType}
        name="type"
        label="Форма собственности"
        changeHandler={changeHandler}
        options={fullTypes}
        canSelectAll
        error={Boolean(errors.type)}
        helperText={errors.type?.message}
      />
    );

    const dateRender = (
      <div className={cls.form_date}>
        <div className={cls.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={({ field: { value } }) => (
              <DateField
                variant={cls.formElement}
                label="Начальный срок"
                changeDateHandler={(date) => {
                  setValue("date_start", date);
                }}
                startDateProp={value}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={cls.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={cls.form_date_end}>
          <Controller
            control={control}
            name="date_limit"
            render={({ field: { value } }) => (
              <DateField
                variant={cls.formElement}
                label="Конечный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                startDateProp={value}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
      </div>
    );

    const vorRender = (
      <div className={cls.form_flexColumn}>
        {(publishInfo.vor || publishInfo.vor_pnr) && (
          <div className={cls.form_flex}>
            {publishInfo.vor && (
              <div className={cls.form_checkBox}>
                <CustomCheckbox
                  {...register("vor")}
                  editing
                  id="vor"
                  checked={checkVor}
                  onChange={() => setCheckVor(!checkVor)}
                  classname={cls.form_checkBox_input}
                />
                <label htmlFor="vor">Ведомость объемов работ</label>
              </div>
            )}
            {errors.vor && <p className={cls.error}>{errors.vor.message}</p>}
            {publishInfo.vor_pnr && (
              <div className={cls.form_checkBox}>
                <CustomCheckbox
                  {...register("vor_pnr")}
                  editing
                  id="vorPnr"
                  checked={checkVorPnr}
                  onChange={() => setCheckVorPnr(!checkVorPnr)}
                  classname={cls.form_checkBox_input}
                />
                <label htmlFor="vorPnr">
                  Ведомость объемов работ на пусконаладочные работы
                </label>
              </div>
            )}
            {errors.vor_pnr && (
              <p className={cls.error}>{errors.vor_pnr.message}</p>
            )}
          </div>
        )}
        <div className={cls.form_flex}>
          {publishInfo.kac && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                {...register("kac")}
                editing
                id="kac"
                checked={checkKac}
                onChange={() => setCheckKac(!checkKac)}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="kac">Конъюнктурный анализ цен</label>
            </div>
          )}
          {canAllCheck && (
            <div className={cls.form_checkBox}>
              <CustomCheckbox
                id="selectAll"
                checked={checkAll}
                onChange={handleCheckAll}
                classname={cls.form_checkBox_input}
              />
              <label htmlFor="selectAll">Выбрать все</label>
            </div>
          )}
        </div>
      </div>
    );

    const executorRender = (
      <div className={globalStyle.executors}>
        <Select
          value={executorValue}
          changeHandler={(_, value) => {
            setValue("executor", value);
          }}
          label={"Назначенный исполнитель"}
          options={executors}
          disableClear
          disabled={pending.executors}
        />
        <NewSelect
          changeHandler={(_, value) => {
            setValue("executorType", value);
          }}
          value={executorTypeValue}
          label={"Форма собственности"}
          options={TypeExecutors}
          disableClear
          disabled={pending.TypeExecutors}
        />
      </div>
    );

    const missingAttributes = !!publishInfo?.secureMissingAttributes.length && (
      <div className={cls.flexAttributes}>
        <p>
          Работа с электронными договорами невозможна, так как не заполнены
          необходимые реквизиты:
        </p>
        {publishInfo?.secureMissingAttributes.map((item: any) => (
          <div className={cls.missingAttributes}>
            <p>{`- ${item.title}:`}</p>
            {item.attributes.map((attribute: any) => (
              <span>{attribute}</span>
            ))}
          </div>
        ))}
      </div>
    );
    return (
      <div className={cls.modal}>
        <h1>{`Публикация раздела «${projectPart.name}» по проекту «${project?.name}»`}</h1>
        <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
          <input type="hidden" {...register("project_id")} />
          {errors.project_id && (
            <p className={cls.form_pay_paymentSum}>
              {errors.project_id.message}
            </p>
          )}
          <h3>Стоимость, сроки и условия оплаты</h3>
          {priceRender}
          {secureAndDocsRender}
          {payDiv && payRender}
          {missingAttributes}
          {dateRender}
          <h3>Требования к исполнителю</h3>
          {typeRender}
          {additional && (
            <h3>Дополнительные требования к разработке раздела</h3>
          )}
          {vorRender}
          {isAddExecutorBtn && (
            <FormControl className={cls.form_toggleBtn}>
              <CustomButton
                background={palette.red}
                width={200}
                onClick={addExecutorClick}
              >
                Назначение исполнителя
              </CustomButton>
            </FormControl>
          )}
          {executorDiv && executorRender}
          <div className={cls.form_submit}>
            <FormControl className={cls.form_submit_saveBtn}>
              <CustomButton
                background={palette.grey}
                width={200}
                type="submit"
                onClick={() => setValue("saveStatus", 1)}
              >
                Сохранить в черновике
              </CustomButton>
            </FormControl>
            <FormControl>
              <CustomButton
                background={palette.green}
                width={160}
                type="submit"
                onClick={() => setValue("saveStatus", 0)}
              >
                Опубликовать
              </CustomButton>
            </FormControl>
          </div>
        </form>
      </div>
    );
  }
);
