import React from "react";
import style from "./style.module.scss";
import { NO_INFO } from "../Info/Info";

interface CardSectionProps {
  title?: string;
  text?: string;
}

interface CardProps {
  title: string;
  leftCorner: CardSectionProps[];
  rightCorner: CardSectionProps;
}

export const Card: React.FC<CardProps> = ({
  title,
  leftCorner,
  rightCorner,
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.experience}>
        <p>{title}</p>
        <div className={style.flex}>
          <div className={style.about}>
            {leftCorner.map((item: any) => (
              <>
                <p>{item.title}</p>
                <div>{item.text || NO_INFO}</div>
              </>
            ))}
          </div>
          <div className={style.data}>
            <p>{rightCorner?.title}</p>
            <div>{rightCorner?.text || NO_INFO}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ExperienceProps {
  data: any;
}

export const Experience: React.FC<ExperienceProps> = ({ data }) => {
  return (
    <div className={style.cardWrapper}>
      {data?.dataJobPlaces.length > 0 ? (
        data?.dataJobPlaces.map((item: any) => {
          const leftCorner = [
            {
              title: "Организация",
              text: item.firm,
            },
            {
              title: "Должность",
              text: item.prof,
            },
          ];

          const rightCorner = {
            title: "Функции",
            text: item.description,
          };

          return (
            <Card
              title={item.date}
              leftCorner={leftCorner}
              rightCorner={rightCorner}
            />
          );
        })
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};

interface EducationProps {
  data: any;
}

export const Education: React.FC<EducationProps> = ({ data }) => {
  return (
    <div className={style.wrapper}>
      {data?.dataEducations.length > 0 ? (
        data?.dataEducations.map((item: any) => {
          const leftCorner = [
            {
              title: "Специализация",
              text: item.specialty,
            },
          ];

          const rightCorner = {
            title: "Учебное заведение",
            text: item.organization,
          };

          const getDate = `${item.date_start} - ${
            item.date_end ?? "По настоящее время"
          }`;

          return (
            <Card
              title={getDate}
              leftCorner={leftCorner}
              rightCorner={rightCorner}
            />
          );
        })
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};
