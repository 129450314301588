import parse from "html-react-parser";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import cn from "classnames";
import styles from "./FilesList.module.scss";

type TFileLink = {
  id: number;
  url: string;
  name: string;
  svgIcon?: string;
};

type TProps = {
  label?: string;
  fileLinks: TFileLink[] | undefined;
  className?: string;
  classNameFilesList?: string;
  classNameTextLink?: string;
  classNameFilesListIcon?: string;
};

export const FilesList = ({
  fileLinks,
  label,
  className,
  classNameFilesList,
  classNameTextLink,
  classNameFilesListIcon,
}: TProps) => {
  return (
    <div className={cn(styles.files, className)}>
      {label && <label className={styles.files__label}>{label}</label>}
      <div className={cn(styles.files__list, classNameFilesList)}>
        {fileLinks?.length ? (
          fileLinks.map(({ id, url, name, svgIcon }) => (
            <div
              key={id}
              className={cn(styles.files__iconFile, classNameFilesListIcon)}
            >
              {svgIcon && parse(svgIcon)}
              <TextLink
                download
                redirect
                target="_blank"
                key={id}
                url={url}
                className={classNameTextLink}
              >
                {name}
              </TextLink>
            </div>
          ))
        ) : (
          <p className={classNameTextLink}>Файлы отсутствуют</p>
        )}
      </div>
    </div>
  );
};
