import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  removeExecutor,
  removeProcessExecutor,
  requestUpdate,
  removeRequestExecution,
  request,
  requestWork,
  update,
  dataRemoveExecutor,
  groupSelectExecutor,
  getGroupRequestStartForms,
  groupRequest,
} from "./queries";

export const projectAccessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectAccessRemoveProcessExecutor: removeProcessExecutor(builder),
    projectAccessRequestUpdate: requestUpdate(builder),
    projectAccessRemoveRequestExecution: removeRequestExecution(builder),
    projectAccessRequest: request(builder),
    projectAccessRequestWork: requestWork(builder),
    projectAccessRemoveExecutor: removeExecutor(builder),
    projectAccessUpdate: update(builder),
    projectAccessDataRemoveExecutor: dataRemoveExecutor(builder),
    projectAccessGroupSelectExecutor: groupSelectExecutor(builder),
    projectAccessGetGroupRequestStartForms: getGroupRequestStartForms(builder),
    projectAccessGroupRequest: groupRequest(builder),
  }),
  overrideExisting: false,
});

export const {
  useProjectAccessRemoveProcessExecutorMutation,
  useProjectAccessRequestUpdateMutation,
  useProjectAccessRemoveRequestExecutionMutation,
  useProjectAccessRequestMutation,
  useProjectAccessDataRemoveExecutorQuery,
  useProjectAccessRequestWorkMutation,
  useProjectAccessRemoveExecutorMutation,
  useProjectAccessUpdateMutation,
  useProjectAccessGroupSelectExecutorMutation,
  useProjectAccessGetGroupRequestStartFormsQuery,
  useProjectAccessGroupRequestMutation,
} = projectAccessApi;
