import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { palette } from "../../../styles/restyle";

interface CustomButtonProps {
  width?: number;
  height?: number;
  onClick?: (e?: any) => void;
  className?: string;
  children?: any;
  redirect?: boolean;
  disabled?: boolean;
  type?: any;
  style?: any;
  small?: boolean;
  color?: string;
  background?: string;
  borderRadius?: number;
  border?: string;
  fontSize?: number;
  fontWeight?: number;
  forceDisabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  width = 160,
  height,
  onClick = () => {},
  className,
  children,
  redirect,
  disabled = false,
  type,
  style,
  small,
  background,
  borderRadius = 20,
  border,
  color,
  forceDisabled = false,
  fontSize = 14,
  fontWeight = 500,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      redirect={redirect}
      className={className}
      width={width + Number(!forceDisabled && !!disabled) * 20}
      type={type}
      style={{
        textTransform: "none",
        fontSize,
        fontWeight,
        width,
        height: height || (small ? 24 : 36),
        border,
        borderRadius,
        background: background ?? palette.grey,
        color: color ?? "white",
        // @ts-ignore
        "&:hover": {
          backgroundColor: palette.common,
          opacity: "0.8",
        },
        fontFamily: "Manrope",
      }}
      sx={{
        "&.MuiButtonBase-root:disabled": {
          opacity: 0.75,
        },
        "&:hover": {
          background: "1px solid white",
          opacity: "0.8",
        },
        ...style,
      }}
    >
      {disabled && !forceDisabled ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            transform: "translateX(-5%)",
          }}
        >
          <CircularProgress
            sx={{ color: "white", "& > *": { transform: "scale(50%)" } }}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
