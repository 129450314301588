import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "../../../services/api/requestHandler";
import {
  ApiProjectGetLogTypes,
  ApiProjectHistory,
} from "../../../services/api/project/history";

export interface ProjectHistoryType {
  result: {
    date_create: string;
    id: number;
    type: string;
    user: {
      id: number;
      name: string;
      surname: string;
      patronymic: string;
    };
    data: {
      attribute: string;
      newValue: string;
    }[];
  }[];
  head: any;
}
export const fetchProjectHistory = createAsyncThunk<ProjectHistoryType, string>(
  "fetchProjectHistory/fetch",
  async (url, { rejectWithValue }) => {
    try {
      const response = await ApiProjectHistory(url);
      const headersData = response.headers;
      const result = await response.json();
      if (!response.ok) {
        throw await result;
      }
      const head: any = {};
      headersData.forEach((value, key) => {
        head[key] = value;
      });
      return { result, head };
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
export type GetlogTypesType = Record<string, string>;
export const fetchGetLogTypes = createAsyncThunk<GetlogTypesType>(
  "fetchGetLogTypes/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiProjectGetLogTypes();
      const result = await response.json();
      if (!response.ok) {
        throw await result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);
