import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { parse } from "date-fns";
import cn from "classnames";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { SubmitDataProps } from "../Education";
import style from "../../Experience/ExpirienceItem/style.module.scss";
import { IEducation } from "../../../../../../app/feature/profileReducer/types";
import { UnsavedType } from "../../../../Project/types";
import { SetState } from "../../../../../features/ProjectEditing/SectionEditing/SectionEditing";
import DateRange from "./DateRange";
import {
  datePassedRequired,
  datePassed,
  stringMaxLength,
  stringMaxLengthRequired,
} from "../../../../../../utils/SimplifiedYup";
import { palette, textFieldSX } from "../../../../../../styles/restyle";

interface ComponentProps {
  onCreate?: (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onUpdate?: (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onDelete?: (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  educationPlace?: IEducation;
  setUnsaved: SetState<UnsavedType>;
}

const schema = Yup.object().shape({
  organization: stringMaxLengthRequired("Наименование учебного заведения", 500),
  date_start: datePassedRequired("Дата начала"),
  date_end: datePassed(),
  specialty: stringMaxLength(500),
});

const EducationItem: React.FC<ComponentProps> = ({
  setUnsaved,
  onCreate,
  onUpdate,
  onDelete,
  educationPlace,
}) => {
  const {
    handleSubmit,
    trigger,
    register,
    getValues,
    watch,
    setValue,
    formState: { dirtyFields, errors },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      date_start: educationPlace
        ? parse(educationPlace.date_start, "dd.MM.yyyy", new Date())
        : null,
      date_end: educationPlace?.date_end
        ? parse(educationPlace.date_end, "dd.MM.yyyy", new Date())
        : null,
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const dateStart = watch("date_start");
  const dateEnd = watch("date_end");

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, education: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  return (
    <div className={style.container}>
      <form
        onSubmit={handleSubmit(
          educationPlace
            ? (data = getValues()) =>
                onUpdate!(data, educationPlace.id, setIsFetching)
            : (data = getValues()) => onCreate!(data, setIsFetching)
        )}
      >
        <div className={style.formContent}>
          <div className={style.formChunk}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Наименование учебного заведения"
              defaultValue={educationPlace?.organization || ""}
              autoComplete="off"
              {...register("organization")}
              {...textFieldSX}
              error={!!errors.organization}
              helperText={
                errors.organization ? errors.organization.message : null
              }
            />
            <DateRange
              dateStart={dateStart}
              dateEnd={dateEnd}
              setValue={setValue}
              trigger={trigger}
              dateStartError={errors.date_start?.message}
              dateEndError={errors.date_end?.message}
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Специализация"
              defaultValue={educationPlace?.specialty || ""}
              autoComplete="off"
              {...register("specialty")}
              {...textFieldSX}
              error={!!errors.specialty}
              helperText={errors.specialty?.message}
            />
          </div>
        </div>
        <div
          className={cn(
            style.bottomContainer,
            !educationPlace && style.justifyRight,
            {
              [style.twoButtons]: educationPlace,
            }
          )}
        >
          {educationPlace ? (
            <CustomButton
              width={160}
              type="button"
              style={{ marginRight: "16px" }}
              background={palette.red}
              onClick={() => onDelete!(educationPlace.id, setIsFetching)}
              disabled={isFetching}
            >
              Удалить
            </CustomButton>
          ) : null}
          <CustomButton
            width={160}
            type="submit"
            background={palette.blue}
            disabled={isFetching}
          >
            Сохранить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default EducationItem;
