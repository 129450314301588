import React from "react";
import { Autocomplete, Paper, TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { textFieldSX } from "../../../../../styles/restyle";

export type MyFindSelectProps = ComponentPropsType<{
  id?: string;
  label: string;
  options: Record<string, any>[];
  isLoading?: boolean;
  defaultValue?: Record<string, any> | null;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  value?: any | undefined;
  disableClear?: boolean;
  fullWidth?: boolean;
  showLabel?: boolean;
  noOptionText?: string;
  paperOptions?: Record<string, any>;
  paperOptionHeight?: number;
  searchHandler?: (val: string) => void;
  changeHandler?: (event: any, value: any, reason: any) => void;
  blurHandler?: (event: any) => void;
  changeHandlerField?: (event: any) => void;
  placeholder?: string;
  disabled?: boolean;
  prompt?: string;
}>;

// более гибко настраиваемый селект,
// с возможностью передать объект paperOptions стилей для выпадающего paper
export const SelectWithPopper: React.FC<MyFindSelectProps> = ({
  id = "",
  label = "",
  options,
  isLoading = false,
  defaultValue = undefined,
  error = false,
  helperText = "",
  name = "",
  value = undefined,
  disableClear = false,
  noOptionText = "не найдено",
  paperOptions = {
    marginTop: 4,
    color: "#26313a",
    maxWidth: "320px",
    zIndex: "99999999999",
  },
  paperOptionHeight = 28,
  fullWidth = false,
  variant = "",
  changeHandler = () => {},
  blurHandler,
  changeHandlerField,
  placeholder = "",
  disabled,
  prompt,
}) => {
  // useStyles добавлено на тот случай если первый элемент будет
  // с пустым значением { id: "", title: ""}, чтобы задать ему и следующим пунктам
  // одинаковую высоту
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option": {
          minHeight: `${paperOptionHeight}px`,
          lineHeight: "1.5",
        },
      },
    },
  }));
  const classes = useStyles();
  return (
    <Autocomplete
      id={id}
      size="small"
      className={variant}
      fullWidth={fullWidth}
      noOptionsText={noOptionText}
      closeText={"Удалить"}
      clearText={"Очистить"}
      loadingText={"Загрузка"}
      openText={"Открыть"}
      defaultValue={defaultValue}
      loading={isLoading}
      options={options}
      value={value}
      disabled={disabled}
      disableClearable={disableClear}
      onChange={changeHandler}
      getOptionLabel={(option: any) => option.title}
      PaperComponent={({ children }) => (
        <Paper style={paperOptions} classes={classes}>
          {children}
        </Paper>
      )}
      renderInput={(params: any) => {
        const field = (
          <TextField
            helperText={helperText}
            name={name}
            className={variant}
            label={label}
            disabled={disabled}
            variant="outlined"
            value={(value && value.title) || undefined}
            onBlur={blurHandler} // formik.handleBlur
            onChange={changeHandlerField} // formik.handleChange
            error={error}
            placeholder={placeholder}
            {...params}
            {...textFieldSX}
            InputLabelProps={{
              shrink: 1,
            }}
          />
        );

        return disabled && prompt ? (
          <Tooltip title={prompt ?? ""}>{field}</Tooltip>
        ) : (
          field
        );
      }}
    />
  );
};
