import cn from "classnames";
import { IconButton } from "@mui/material";
import {
  PlusSquare,
  XSquare,
  CheckSquare,
  Star,
  ChatLeftText,
} from "react-bootstrap-icons";
import { TextLink } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./Tasks.module.scss";

type TProps = {
  materialId: number;
  task: ApiTaskResponses.View;
  handleBackToWorkTask: () => Promise<void>;
};

export const Actions = ({ materialId, task, handleBackToWorkTask }: TProps) => {
  const { handleOpenModal } = useModal();

  return (
    <div className={cn(styles.tasksBlock__content, styles.tasksBlock__gap)}>
      {task.can.submit && (
        <IconButton
          className={styles.tasksBlock__iconBtn}
          onClick={() =>
            handleOpenModal({
              name: ModalNames.ProjectProcess.REQUEST_EQUIPMENT_COMPLETE,
              taskId: task.id,
            })
          }
        >
          <PlusSquare color="white" />
        </IconButton>
      )}
      {task.can.backToWork && (
        <IconButton
          className={styles.tasksBlock__iconBtn}
          onClick={handleBackToWorkTask}
        >
          <XSquare color="white" />
        </IconButton>
      )}
      {task.can.select && (
        <IconButton
          className={styles.tasksBlock__iconBtn}
          onClick={() =>
            handleOpenModal({
              name: ModalNames.ProjectProcess.MATERIAL_PROCESS_SELECT_REQUEST,
              taskId: task.id,
              materialId,
            })
          }
        >
          <CheckSquare color="white" />
        </IconButton>
      )}
      {task.can.feedback && (
        <IconButton
          className={styles.tasksBlock__iconBtn}
          onClick={() =>
            handleOpenModal({
              name: ModalNames.Task.FEEDBACK,
              taskId: task.id,
            })
          }
        >
          <Star color="white" />
        </IconButton>
      )}
      {task!.chatRoomId?.id && (
        <TextLink
          url={`/site/front/chat/${task.chatRoomId.id}`}
          redirect
          target={"_blank"}
        >
          <IconButton className={styles.tasksBlock__iconBtn}>
            <ChatLeftText color="white" />
          </IconButton>
        </TextLink>
      )}
    </div>
  );
};
