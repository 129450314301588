import { useContext } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Checkbox, Heading, Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";
import { ApplicationsContext } from "../Applications";
import { useProcessCheckbox } from "../../lib/hooks/useProcessCheckbox";
import { TFieldValues } from "../../model/fieldValues";
import { Exclamation } from "./Exclamation";
import { getExclamationType } from "../../lib/helpers/getExclamationType";

type TProps = {
  taskId: number;
  price: number;
  dateStart: string;
  dateLimit: string;
  ndsText: string;
  field: Record<string, number>;
  form: UseFormReturn<TFieldValues>;
  processPrice: number;
};

export const ProcessCheckbox = ({
  taskId,
  dateStart,
  dateLimit,
  price,
  field,
  ndsText,
  processPrice,
}: TProps) => {
  const {
    form: { control },
  } = useContext(ApplicationsContext);

  const {
    processTypeId,
    processId,
    userTypeId,
    handleToggleProcess,
    bankErrorTaskIds,
    formattedPrice,
    formattedDateStart,
    formattedDateLimit,
  } = useProcessCheckbox({
    taskId,
    dateStart,
    dateLimit,
    price,
    field,
  });

  const processPriceDifference = price - processPrice;

  const showBankAccountError = Boolean(
    bankErrorTaskIds.length && bankErrorTaskIds.at(-1) === taskId
  );

  const exclamationType = getExclamationType(
    showBankAccountError,
    processPriceDifference
  );

  return (
    <div className={styles.processCheckbox}>
      <Controller
        name={`${processTypeId}.${processId}.${userTypeId}`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={Boolean(value)}
            onChange={() => handleToggleProcess(!value, onChange)}
            editing
          />
        )}
      />
      <div>
        <Heading className={styles[`processCheckbox_${exclamationType}`]}>
          {formattedPrice} ₽<span>&nbsp;&nbsp;{ndsText}</span>
          {Boolean(exclamationType) && (
            <Exclamation
              type={exclamationType!}
              price={processPriceDifference}
            />
          )}
        </Heading>
        <Paragraph>
          {formattedDateStart}-{formattedDateLimit}
        </Paragraph>
      </div>
    </div>
  );
};
