import React, { forwardRef, Ref, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment/moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import cls from "./EditDateModal.module.scss";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import {
  fetchProjectGanttData,
  fetchSchedulePlan,
  fetchUpdateDates,
} from "src/app/feature/ProjectView/GraphReducer/thunks";
import {
  clear422,
  getProjectGraphData,
} from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { ModalPreloader } from "src/components/newUI/Modal/ModalPreloader";
import { EditDateSchema } from "./EditDateSchema";
import { parseUnprocessableFields } from "src/app/services/api/requestHandler";
import { DateField } from "src/components/features/SpecialForm/DateField/DateField";

type TInputs = {
  pd_date_start: any;
  pd_date_end: any;
  rd_date_start: any;
  rd_date_end: any;
  ii_date_start: any;
  ii_date_end: any;
  obs_date_start: any;
  obs_date_end: any;
  exp_date_start: any;
  exp_date_end: any;
};

function parseDateFormat(date: Date): string {
  return moment(date, "DD.MM.YYYY").format("DD.MM.YYYY");
}
interface IEditDateModalProps {
  id: number;
  closeModal: () => void;
}
export interface ModalRefType {
  handleClose: () => void;
}

export const EditDateModal: React.FC<IEditDateModalProps> = forwardRef(
  ({ id, closeModal }, ref: Ref<ModalRefType>) => {
    const dispatch = useDispatch();
    const {
      pd_date_start,
      pd_date_end,
      rd_date_start,
      rd_date_end,
      ii_date_start,
      ii_date_end,
      obs_date_start,
      obs_date_end,
      exp_date_start,
      exp_date_end,
      isLoadSchedule,
      error422,
      graphUpdate,
    } = useSelector(getProjectGraphData);
    const canPd = !!pd_date_start || !!pd_date_end;
    const canRd = !!rd_date_end || !!rd_date_end;
    const canIi = !!ii_date_start || !!ii_date_end;
    const canObs = !!obs_date_start || !!obs_date_end;
    const canExp = !!exp_date_start || !!exp_date_end;

    const schema = EditDateSchema(canPd, canRd);
    useEffect(() => {
      dispatch(fetchSchedulePlan(id));
    }, []);

    const {
      handleSubmit,
      control,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        pd_date_start,
        pd_date_end,
        rd_date_start,
        rd_date_end,
        obs_date_start,
        obs_date_end,
        ii_date_start,
        ii_date_end,
        exp_date_start,
        exp_date_end,
      },
    });

    const handleClose = () => {
      clearErrors();
      dispatch(clear422());
      closeModal();
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    useEffect(() => {
      setValue("pd_date_start", pd_date_start);
      setValue("pd_date_end", pd_date_end);
      setValue("rd_date_start", rd_date_start);
      setValue("rd_date_end", rd_date_end);
      setValue("obs_date_start", obs_date_start);
      setValue("obs_date_end", obs_date_end);
      setValue("ii_date_start", ii_date_start);
      setValue("ii_date_end", ii_date_end);
      setValue("exp_date_start", exp_date_start);
      setValue("exp_date_end", exp_date_end);
    }, [pd_date_start, pd_date_end, rd_date_start, rd_date_end]);

    const formSubmitHandler = async (dataValues: TInputs) => {
      const {
        pd_date_start,
        pd_date_end,
        rd_date_start,
        rd_date_end,
        exp_date_start,
        exp_date_end,
        obs_date_start,
        obs_date_end,
        ii_date_start,
        ii_date_end,
      } = dataValues;
      const data = {
        pd_date_start: pd_date_start ? parseDateFormat(pd_date_start) : null,
        pd_date_end: pd_date_end ? parseDateFormat(pd_date_end) : null,
        rd_date_start: rd_date_start ? parseDateFormat(rd_date_start) : null,
        rd_date_end: rd_date_end ? parseDateFormat(rd_date_end) : null,
        exp_date_start: exp_date_start ? parseDateFormat(exp_date_start) : null,
        exp_date_end: exp_date_end ? parseDateFormat(exp_date_end) : null,
        obs_date_start: obs_date_start ? parseDateFormat(obs_date_start) : null,
        obs_date_end: obs_date_end ? parseDateFormat(obs_date_end) : null,
        ii_date_start: ii_date_start ? parseDateFormat(ii_date_start) : null,
        ii_date_end: ii_date_end ? parseDateFormat(ii_date_end) : null,
      };
      await dispatch(fetchUpdateDates({ id, data }));
    };

    useEffect(() => {
      if (error422) {
        parseUnprocessableFields(error422, setError);
      }
    }, [error422]);

    useEffect(() => {
      if (graphUpdate) {
        dispatch(fetchProjectGanttData(id));
        clearErrors();
        handleClose();
      }
    }, [graphUpdate]);

    if (isLoadSchedule) {
      return <ModalPreloader />;
    }

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>Редактирование план-графика</h1>
        <div className={cls.form_date}>
          {pd_date_start && (
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="pd_date_start"
                render={() => (
                  <DateField
                    variant={cls.formElement}
                    label="Дата начала ПД"
                    changeDateHandler={(date) => {
                      setValue("pd_date_start", date);
                    }}
                    startDateProp={pd_date_start}
                    error={!!errors.pd_date_start}
                  />
                )}
              />
              {errors.pd_date_start && (
                <p className={cls.error}>{errors.pd_date_start.message}</p>
              )}
            </div>
          )}
          {pd_date_end && (
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="pd_date_end"
                render={() => (
                  <DateField
                    label="Дата окончания ПД"
                    changeDateHandler={(date) => {
                      setValue("pd_date_end", date);
                    }}
                    startDateProp={pd_date_end}
                    error={!!errors.pd_date_end}
                  />
                )}
              />
              {errors.pd_date_end && (
                <p className={cls.error}>{errors.pd_date_end.message}</p>
              )}
            </div>
          )}
        </div>
        {canRd && (
          <div className={cls.form_date}>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="rd_date_start"
                render={() => (
                  <DateField
                    label="Дата начала РД"
                    changeDateHandler={(date) => {
                      setValue("rd_date_start", date);
                    }}
                    startDateProp={rd_date_start}
                    error={!!errors.rd_date_start}
                  />
                )}
              />
              {errors.rd_date_start && (
                <p className={cls.error}>{errors.rd_date_start.message}</p>
              )}
            </div>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="rd_date_end"
                render={() => (
                  <DateField
                    label="Дата окончания РД"
                    changeDateHandler={(date) => {
                      setValue("rd_date_end", date);
                    }}
                    startDateProp={rd_date_end}
                    error={!!errors.rd_date_end}
                  />
                )}
              />
              {errors.rd_date_end && (
                <p className={cls.error}>{errors.rd_date_end.message}</p>
              )}
            </div>
          </div>
        )}
        {canExp && (
          <div className={cls.form_date}>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="exp_date_start"
                render={() => (
                  <DateField
                    label="Дата начала экспертизы"
                    changeDateHandler={(date) => {
                      setValue("exp_date_start", date);
                    }}
                    startDateProp={exp_date_start}
                    error={!!errors.exp_date_start}
                  />
                )}
              />
              {errors.exp_date_start && (
                <p className={cls.error}>{errors.exp_date_start.message}</p>
              )}
            </div>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="exp_date_end"
                render={() => (
                  <DateField
                    label="Дата окончания экспертизы"
                    changeDateHandler={(date) => {
                      setValue("exp_date_end", date);
                    }}
                    startDateProp={exp_date_end}
                    error={!!errors.exp_date_end}
                  />
                )}
              />
              {errors.exp_date_end && (
                <p className={cls.error}>{errors.exp_date_end.message}</p>
              )}
            </div>
          </div>
        )}
        {canObs && (
          <div className={cls.form_date}>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="obs_date_start"
                render={() => (
                  <DateField
                    label="Дата начала ОБС"
                    changeDateHandler={(date) => {
                      setValue("obs_date_start", date);
                    }}
                    startDateProp={obs_date_start}
                    error={!!errors.obs_date_start}
                  />
                )}
              />
              {errors.obs_date_start && (
                <p className={cls.error}>{errors.obs_date_start.message}</p>
              )}
            </div>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="obs_date_end"
                render={() => (
                  <DateField
                    label="Дата окончания ОБС"
                    changeDateHandler={(date) => {
                      setValue("obs_date_end", date);
                    }}
                    startDateProp={obs_date_end}
                    error={!!errors.obs_date_end}
                  />
                )}
              />
              {errors.obs_date_end && (
                <p className={cls.error}>{errors.obs_date_end.message}</p>
              )}
            </div>
          </div>
        )}
        {canIi && (
          <div className={cls.form_date}>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="ii_date_start"
                render={() => (
                  <DateField
                    label="Дата начала ИИ"
                    changeDateHandler={(date) => {
                      setValue("ii_date_start", date);
                    }}
                    startDateProp={ii_date_start}
                    error={!!errors.ii_date_start}
                  />
                )}
              />
              {errors.ii_date_start && (
                <p className={cls.error}>{errors.ii_date_start.message}</p>
              )}
            </div>
            <div className={cls.fullWidth}>
              <Controller
                control={control}
                name="ii_date_end"
                render={() => (
                  <DateField
                    label="Дата окончания ИИ"
                    changeDateHandler={(date) => {
                      setValue("ii_date_end", date);
                    }}
                    startDateProp={ii_date_end}
                    error={!!errors.ii_date_end}
                  />
                )}
              />
              {errors.ii_date_end && (
                <p className={cls.error}>{errors.ii_date_end.message}</p>
              )}
            </div>
          </div>
        )}
        <div className={cls.form_btn}>
          <CustomButton
            background={palette.grey}
            width={160}
            onClick={handleClose}
          >
            Отменить
          </CustomButton>
          <CustomButton background={palette.blue} width={160} type="submit">
            Сохранить
          </CustomButton>
        </div>
      </form>
    );
  }
);
