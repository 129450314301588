import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { ColumnsGap } from "react-bootstrap-icons";
import MenuItem from "@mui/material/MenuItem";
import {
  fetchProjectDelete,
  fetchProjectPause,
  fetchProjectUnPause,
  fetchProjectView,
  fetchRecalculationsRelations,
} from "../../../../app/feature/ProjectView/thunks";
import TabsPanel from "../../../newUI/TabsPanel/TabsPanel";
import {
  CardList,
  InfoSquareSVG,
  PenIcon,
  GearFillSVG,
  ColumnsGapSVG,
  HistoryTabIcon,
  FolderIcon,
  CheckListIcon,
  TaskSTabIcon,
  ChecksGridSVG,
  VerticalThreeDots,
  ObjectTabIcon,
  GraphTabIcon,
  MileStoneTabIcon,
  ContentsSVG,
} from "../../../newUI/SVG";
import { Wrapper } from "../../../UI/templates";
import style from "./style.module.scss";
import TitlePanel from "../../../newUI/TitlePanel/TitlePanel";
import Info from "./Info/Info";
import {
  getProjectViewData,
  setError,
} from "../../../../app/feature/ProjectView/projectView";
import { InitialData } from "./InitialData/InitialData";
import { config } from "../../../../app.cofig";
import Sections from "./Sections/Sections";
import { fetchProjectSectionsTree } from "../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { Documents } from "./Documents/Documents";
import { fetchProjectDocument } from "../../../../app/feature/ProjectView/DocumentReducer/thunks";
import { Admin } from "./Admin/Admin";
import { CompareGip } from "./CompareGip/CompareGip";
import { fetchProjectCompareGip } from "../../../../app/feature/ProjectView/CompareReducer/thunks";
import { getProjectCompareGip } from "../../../../app/feature/ProjectView/CompareReducer/projectCompareGip";
import { History } from "./History/History";
import { CheckList } from "./CheckList/CheckList";
import { fetchProjectCheckListTree } from "../../../../app/feature/ProjectView/CheckListReducer/thunks";
import { getProjectUsersList } from "../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import { Tasks } from "./Tasks/Tasks";
import { fetchProjectCheckTaskTree } from "../../../../app/feature/ProjectView/CheckTaskReducer/thunks";
import { CheckTasks } from "./CheckTasks/CheckTasks";
import useTabs from "../../../hooks/useTabs";
import { StyledMenu } from "../../../newUI/Menu/Menu";
import { Preloader } from "../../../features";
import { getProjectInitialData } from "../../../../app/feature/ProjectView/InitialDataReducer/initialDataReducer";
import { Objects } from "./Objects/Objects";
import { Graph } from "./Graph/Graph";
import { useIsMobile } from "../../../hooks/useIsMobile";
import GroupApplications from "./GroupApplications/GroupApplications";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { useAppSelector } from "../../../../app/store";
import {
  clearAllApplications,
  getGroupApplications,
  toggleGroupComparisonMode,
} from "../../../../app/feature/ProjectView/GroupApplications";
import { palette } from "../../../../styles/restyle";
import { Milestone } from "./Milestone/Milestone";
import { fetchProjectMilestone } from "../../../../app/feature/ProjectView/milestone/services/fetchProjectMilestone";
import Contents from "./Contents/Contents";

const sx = { color: "white" };

export const ProjectView = () => {
  const { project, error, isLoadDelete } = useSelector(getProjectViewData);
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile(500);
  const { updateInitialData } = useSelector(getProjectInitialData);
  const { updateUsers } = useSelector(getProjectUsersList);
  const { compareGip } = useSelector(getProjectCompareGip);
  const {
    data: { selectedExecutors, comparisonMode },
  } = useAppSelector(getGroupApplications);
  const [isContentLoad, setContentLoad] = useState(true);
  const [currentTab, setCurrentTab] = useState("");

  const setCurrentTabMemo = () => {
    setCurrentTab("info");
  };

  const handleToggleComparisonMode = () => {
    dispatch(toggleGroupComparisonMode());
  };

  const [tabs, setTabs] = useState<any>([
    {
      title: "Информация",
      url: "info",
      icon: <InfoSquareSVG />,
      incomplete: false,
    },
    !isMobile && {
      title: "Графики",
      url: "graph",
      icon: <GraphTabIcon />,
      incomplete: false,
    },
    {
      title: "Исх. данные",
      url: "project-source-data",
      icon: <FolderIcon />,
      incomplete: project?.tabs.sourceData.dot,
    },
    {
      title: "Разделы",
      url: "project-process",
      icon: <CardList />,
      incomplete: false,
    },
  ]);

  useEffect(() => {
    const tabsData = [
      {
        title: "Информация",
        url: "info",
        icon: <InfoSquareSVG />,
        incomplete: false,
      },
      !isMobile && {
        title: "Графики",
        url: "graph",
        icon: <GraphTabIcon />,
        incomplete: false,
      },
      {
        title: "Исх. данные",
        url: "project-source-data",
        icon: <FolderIcon />,
        incomplete: project?.tabs.sourceData.dot,
      },
      project?.tabs.composition.show && {
        title: "Состав проекта",
        url: "contents",
        icon: <ContentsSVG />,
        incomplete: false,
      },
      project &&
        !project.hasObject && {
          title: "Объекты",
          url: "objects",
          icon: <ObjectTabIcon />,
          incomplete: false,
        },
      {
        title: "Разделы",
        url: "project-process",
        icon: <CardList />,
        incomplete: false,
      },
      project?.can.viewDocs &&
        project?.tabs.documents.show && {
          title: "Договоры",
          url: "documents",
          icon: <PenIcon />,
          incomplete: project?.tabs.documents.dot,
        },
      (project?.can.edit || project?.can.editByGipHelper) && {
        title: "Общий чек-лист",
        url: "checklist",
        icon: <CheckListIcon />,
        incomplete: project?.tabs.checklist.dot,
      },
      project?.tabs.applications.show && {
        title: "Заявки",
        url: "applications",
        icon: <ColumnsGap />,
        incomplete: project?.tabs.checklist.dot,
      },
      (project?.can.edit || project?.can.editByGipHelper) &&
        (project.tabs.tasksIn?.show || project.tabs.tasksOut?.show) && {
          title: "Задания",
          url: "tasks",
          icon: <TaskSTabIcon />,
          incomplete: project?.tabs.tasksOut?.dot || project?.tabs.tasksIn?.dot,
        },
      project?.tabs.stages.show && {
        title: "Вехи",
        url: "milestone",
        icon: <MileStoneTabIcon />,
        incomplete: project?.tabs.stages.dot,
      },
      (project?.can.edit || project?.can.editByGipHelper) &&
        project.tabs.checks.show && {
          title: "Проверки",
          url: "check-tasks",
          icon: <ChecksGridSVG />,
          incomplete: project?.tabs.checks.dot,
        },
      !!compareGip.length &&
        project?.can.edit && {
          title: "Заявки ГИПа",
          url: "compare",
          icon: <ColumnsGapSVG />,
          incomplete: project?.tabs.applications.dot,
        },
      (project?.tabs.history.show || project?.can.editByGipHelper) && {
        title: "История",
        url: "history",
        icon: <HistoryTabIcon />,
        incomplete: false,
      },
      project?.tabs.admin.show && {
        title: "Администр.",
        url: "admin",
        icon: <GearFillSVG />,
        incomplete: false,
      },
    ].filter(Boolean);
    setTabs(tabsData);
  }, [project, compareGip]);

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const canBtnHeader =
    project?.accountId ||
    project?.can?.edit ||
    project?.can?.isAdmin ||
    project?.can?.delete;

  const comparisonCount = selectedExecutors.length;
  const canCompare = Boolean(comparisonCount);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (updateInitialData) {
      setCurrentTab("project-source-data");
    }
    if (updateUsers) {
      setCurrentTab("admin");
    }
    setCurrentTab("info");
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      if (currentTab === "info") {
        dispatch(fetchProjectView(params.id));
        setContentLoad(false);
      }
      if (currentTab === "project-process") {
        dispatch(fetchProjectSectionsTree(+params.id));
        setContentLoad(false);
      }
      if (currentTab === "documents") {
        dispatch(fetchProjectDocument(params.id));
        setContentLoad(false);
      }
      if (currentTab === "checklist") {
        dispatch(fetchProjectCheckListTree(params.id));
        setContentLoad(false);
      }
      if (currentTab === "milestone") {
        dispatch(fetchProjectMilestone({ projectId: +params.id }));
        setContentLoad(false);
      }
      if (currentTab === "check-tasks") {
        dispatch(fetchProjectCheckTaskTree({ id: +params.id, status: "" }));
        setContentLoad(false);
      }
    }
    const tabNames = ["history", "admin", "project-source-data", "tasks"];
    if (tabNames.includes(currentTab)) {
      setContentLoad(false);
    }
    if (updateInitialData) {
      enqueueSnackbar("Данные успешно обновлены", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (currentTab !== "applications") {
      dispatch(clearAllApplications());
    }
  }, [currentTab, updateInitialData, location.pathname]);

  useEffect(() => {
    if (params.id && project?.gip === null) {
      dispatch(fetchProjectCompareGip(Number(params.id)));
      setContentLoad(false);
    }
  }, [project]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
      dispatch(setError());
    }
  }, [error]);

  const components = (
    <>
      {currentTab === "info" && <Info setCurrentTab={setCurrentTab} />}
      {currentTab === "graph" && <Graph />}
      {currentTab === "project-source-data" && <InitialData />}
      {currentTab === "project-process" && (
        <Sections cost={project?.processPublishedCost} />
      )}
      {currentTab === "documents" && (
        <Documents setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "admin" && (
        <Admin setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "compare" && (
        <CompareGip setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "history" && (
        <History setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "checklist" && (
        <CheckList setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "tasks" && (
        <Tasks setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "check-tasks" && (
        <CheckTasks setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "objects" && <Objects />}
      {currentTab === "applications" && (
        <GroupApplications setCurrentTabMemo={setCurrentTabMemo} />
      )}
      {currentTab === "milestone" && <Milestone />}
      {currentTab === "contents" && (
        <Contents
          id={Number(params.id)}
          can={project?.can ?? {}}
          setCurrentTabMemo={setCurrentTabMemo}
        />
      )}
    </>
  );

  const handleNavigate = () => {
    window.open(
      `${config.localDomain}/account/view?id=${project?.accountId}`,
      "_blank"
    );
  };

  const deleteProject = async () => {
    const result = window.confirm("Вы уверены, что хотите удалить проект?");
    if (params.id && result) {
      await dispatch(fetchProjectDelete(+params.id));
    }
  };

  const pauseProject = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить проект?"
    );
    if (params.id && result) {
      await dispatch(fetchProjectPause(+params.id));
      await dispatch(fetchProjectView(params.id));
    }
  };

  const unPauseProject = async () => {
    const result = window.confirm("Вы уверены, что хотите продолжить проект?");
    if (params.id && result) {
      await dispatch(fetchProjectUnPause(+params.id));
      await dispatch(fetchProjectView(params.id));
    }
  };

  const reCalculationRelations = async () => {
    const result = window.confirm("Вы уверены, что хотите обновить связи?");
    if (params.id && result) {
      await dispatch(fetchRecalculationsRelations(+params.id));
      await dispatch(fetchProjectView(params.id));
    }
  };

  useTabs(currentTab, setCurrentTab, "info");

  const renderDesktop = (
    <>
      <div>
        {canCompare && (
          <CustomButton
            onClick={handleToggleComparisonMode}
            width={130}
            background={palette.blue}
          >
            {comparisonMode ? <>Назад</> : <>Сравнить ({comparisonCount})</>}
          </CustomButton>
        )}
        {canBtnHeader && (
          <IconButton
            sx={sx}
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <VerticalThreeDots />
          </IconButton>
        )}
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {project?.accountId && (
            <MenuItem
              onClick={() => {
                handleNavigate();
                handleClose();
              }}
              disableRipple
            >
              Кошелек
            </MenuItem>
          )}
          {project?.can?.edit && (
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              disableRipple
            >
              <Link
                className={style.wrapper_link}
                to={`/project/update?id=${params.id}&tab=project&userTypeId=${project?.authorType?.id}`}
              >
                Редактировать
              </Link>
            </MenuItem>
          )}
          {project?.can?.isAdmin && (
            <MenuItem
              onClick={() => {
                reCalculationRelations();
                handleClose();
              }}
              disableRipple
            >
              Обновить связи
            </MenuItem>
          )}
          {project?.can?.edit && project?.statusKey !== 15 && (
            <MenuItem
              onClick={() => {
                pauseProject();
                handleClose();
              }}
              disableRipple
            >
              Приостановить
            </MenuItem>
          )}
          {project?.can?.edit && project?.statusKey === 15 && (
            <MenuItem
              onClick={() => {
                unPauseProject();
                handleClose();
              }}
              disableRipple
            >
              Продолжить
            </MenuItem>
          )}
          {project?.can?.delete && (
            <MenuItem
              onClick={() => {
                deleteProject();
                handleClose();
              }}
              disableRipple
            >
              Удалить
            </MenuItem>
          )}
        </StyledMenu>
      </div>
    </>
  );

  return (
    <div className={style.wrapper}>
      <Wrapper>
        {isLoadDelete && <Preloader variant={style.preloaderSections} />}
        <TitlePanel
          isContentLoad={isContentLoad}
          primaryText={project?.name ? `${project?.code} ${project?.name}` : ""}
          title={project?.name || ""}
          secondaryText={project?.status}
          small
          projectView
          parentName={project?.parentId ? project?.parentName : ""}
          parentId={project?.parentId}
        >
          {renderDesktop}
        </TitlePanel>
        <div className={style.contents}>
          <TabsPanel
            isContentLoad={isContentLoad}
            tabsInfo={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            components={components}
            setDefaultTab={setCurrentTabMemo}
          />
        </div>
      </Wrapper>
    </div>
  );
};
