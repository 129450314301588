import { createContext, useContext } from "react";
import { getErrorMessage } from "src/app/services/api/requestHandler";

type TModal = {
  isActive: boolean;
  handleClose: () => void;
};

const ModalContext = createContext<TModal | null>(null);

export function useModalContext() {
  const context = useContext(ModalContext);
  if (!context) {
    const errorMessage = getErrorMessage("Modal");
    throw new Error(errorMessage);
  }
  return context;
}

export default ModalContext;
