import queryString from "query-string";

type CreateUrlStringType = (
  params: Record<string, string | number> | null,
  setFn: (string: string) => void
) => void;

export const createUrlSearchString: CreateUrlStringType = (params, setFn) => {
  const newString = {
    ...params,
  };

  setFn(`?${queryString.stringify(newString)}`);
};

export const createUrlSearchParamsFromObject = (
  params: Record<string, string | number>
): string => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};
