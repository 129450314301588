import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  fetchGipHelpersList,
  TTabsList,
} from "src/app/feature/ProjectView/thunks";
import { Card } from "src/FSD/shared/uiKit/v2";
import { getUserId } from "src/app/services/auth/auth";
import { request } from "src/app/services/api/requestHandler";
import { ApiGetUserTypes } from "src/app/services/api/user/user";
import {
  getProjectViewData,
  setGipTypes,
} from "src/app/feature/ProjectView/projectView";
import { ApiGipType } from "src/types/api/primaryTypes/apiPrimaryUserType";
import { GipHave } from "./GipHave/GipHave";
import { GipNotAssigned } from "./GipNotAssigned/GipNotAssigned";
import styles from "./style.module.scss";

type InfoGipProps = {
  setCurrentTab: (arg: keyof TTabsList) => void;
};

export const InfoGip = ({ setCurrentTab }: InfoGipProps) => {
  const { project } = useSelector(getProjectViewData);

  const dispatch = useDispatch();
  const gipId = getUserId();

  useEffect(() => {
    request(ApiGetUserTypes(gipId), (data: ApiGipType) => {
      dispatch(setGipTypes(data));
    })();
    if (project?.gip?.firmId) {
      dispatch(fetchGipHelpersList(project.gip.firmId));
    }
  }, []);

  if (project) {
    return (
      <Card
        className={classNames(
          styles.gip,
          !project.gip && styles.gipHavePadding
        )}
        header={<Card.Header>ГИП</Card.Header>}
        cardContentClassName={styles.content}
      >
        {project.gip ? (
          <GipHave />
        ) : (
          <GipNotAssigned setCurrentTab={setCurrentTab} />
        )}
        <div className={styles.gip_vector} />
      </Card>
    );
  }
  return null;
};
