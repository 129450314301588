import { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";

type TProps = {
  children: ReactNode;
};

const modalRoot = document.getElementById("modalRoot");

const ModalWrapper = ({ children }: TProps) =>
  ReactDOM.createPortal(children, modalRoot!) as ReactElement;

export default ModalWrapper;
