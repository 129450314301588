import { UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "../../model/constants/errorMesages";
import { TGroupPublicationFieldValues } from "../../model/types/fieldValues";

export const checkPaymentTerms = (
  form: UseFormReturn<TGroupPublicationFieldValues>
) => {
  const { clearErrors, getValues, setError } = form;

  clearErrors(["pay1", "pay2", "pay3", "paySum"]);

  const pay1 = +getValues("pay1");
  const pay2 = +getValues("pay2");
  const pay3 = +getValues("pay3");
  const maybeExpertise = getValues("maybeExpertise");

  if (!pay1) {
    setError(`pay1`, {
      message: ErrorMessage.FIELD_REQUIRED,
    });
  }

  if (Number.isNaN(pay1)) {
    setError(`pay1`, {
      message: ErrorMessage.MUST_BE_INT,
    });
  }

  if (pay1 < 20) {
    setError(`pay1`, {
      message: ErrorMessage.MIN_20_PERCENT,
    });
  }

  if (pay1 > 100) {
    setError(`pay1`, {
      message: ErrorMessage.MAX_100_PERCENT,
    });
  }

  if (!pay2) {
    setError(`pay2`, {
      message: ErrorMessage.FIELD_REQUIRED,
    });
  }

  if (Number.isNaN(pay2)) {
    setError(`pay2`, {
      message: ErrorMessage.MUST_BE_INT,
    });
  }

  if (pay2 > 100) {
    setError(`pay2`, {
      message: ErrorMessage.MAX_100_PERCENT,
    });
  }

  if (maybeExpertise) {
    if (!pay3) {
      setError(`pay3`, {
        message: ErrorMessage.FIELD_REQUIRED,
      });
    }

    if (Number.isNaN(pay3)) {
      setError(`pay3`, {
        message: ErrorMessage.MUST_BE_INT,
      });
    }

    if (pay3 > 100) {
      setError(`pay3`, {
        message: ErrorMessage.MAX_100_PERCENT,
      });
    }

    if (pay1 + pay2 + pay3 !== 100) {
      setError(`paySum`, {
        message: ErrorMessage.SUM_100_PERCENT,
      });
    }
  } else if (pay1 + pay2 !== 100) {
    setError(`paySum`, {
      message: ErrorMessage.SUM_100_PERCENT,
    });
  }
};
