import React, { memo } from "react";
import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.scss";
import { Rashka } from "../../../../../../../newUI/SVG";
import { TextLink } from "../../../../../TextLink/TextLink";

interface RegionProps {
  showMap?: boolean;
  projecting?: boolean;
  processProject?: boolean;
  data: {
    experience?: string;
    region?: string;
    expertise?: string;
    logo?: string;
    url?: string;
  };
  vor?: boolean;
  vorPnr?: boolean;
}

const Region: React.FC<RegionProps> = ({
  data,
  showMap,
  projecting,
  processProject,
  vor,
  vorPnr,
}) => {
  if (processProject) {
    return (
      <div className={style.wrapper}>
        <h1 className={style.header}>Проектирование</h1>
        <h1 className={style.header}>Экспертиза</h1>
        <p className={style.expertise}>{data.expertise}</p>
        <h2>{data.logo}</h2>
        <h1 className={style.header}>Дополнительные требования</h1>
        <p>
          <div className={style.icons}>
            {vor ? (
              <CheckIcon color="inherit" fontSize="inherit" />
            ) : (
              <div className={style.icons_disabled}>
                {" "}
                <CloseIcon color="inherit" fontSize="inherit" />
              </div>
            )}
            <p className={style.icons__text}>Ведомость объемов работ</p>
          </div>
        </p>
        <p>
          <div className={style.icons}>
            {vorPnr ? (
              <CheckIcon color="inherit" fontSize="inherit" />
            ) : (
              <div className={style.icons_disabled}>
                {" "}
                <CloseIcon color="inherit" fontSize="inherit" />
              </div>
            )}
            <p className={style.icons__text}>Ведомость объемов работ для ПНР</p>
          </div>
        </p>
      </div>
    );
  }
  if (projecting) {
    return (
      <div className={style.wrapper}>
        <h1 className={style.header}>Проектирование</h1>
        <h1 className={style.header}>Регион объекта</h1>
        <p>{data.region}</p>
        <h1 className={style.header}>Экспертиза</h1>
        <p>{data.expertise}</p>

        <h2>{data.logo}</h2>
      </div>
    );
  }
  if (showMap) {
    return (
      <div
        style={{ minHeight: "150px" }}
        className={classNames(style.wrapper, style.map)}
      >
        <div>
          <Rashka />
        </div>
        <h1>Регион</h1>
        <p className={style.withMap}>{data.region}</p>
      </div>
    );
  }

  return (
    <div className={classNames(style.wrapper, style.noMap)}>
      <h1>Стаж в отрасли</h1>
      <h1>Регион</h1>
      <p>{data.region}</p>

      <TextLink url={`${data.url}`} variant={style.link}>
        {`${data.experience}`}
      </TextLink>
    </div>
  );
};

export default memo(Region);
