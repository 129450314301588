import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { remarkReasons } from "src/FSD/features/v2/SelectRemarkReason/model";
import { useAppDispatch } from "src/app/store";
import { expertiseRemarkRowCompleteSchema } from "../schemas";
import { pushError } from "src/app/feature/errorTrace";
import { Nullable } from "src/types/generics";

type TFieldValues = {
  responseToRemark: string;
  documentationsWithRemarksRecieved: string;
  remarkStatus: Nullable<string>;
  status: Nullable<Statuses>;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useExpertiseRemarkRowComplete = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(expertiseRemarkRowCompleteSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["remarkRow.remark.files"],
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("responseToRemark", taskData!.comment);
    setValue(
      "documentationsWithRemarksRecieved",
      taskData!.workflow_data!.changes
    );
    setValue(
      "remarkStatus",
      taskData!.workflow_data!.reason || remarkReasons[0].title
    );
  }, [taskData]);

  const status = watch("status");

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      reason: data.remarkStatus,
      comment: data.responseToRemark,
      changes: data.documentationsWithRemarksRecieved,
      status: Statuses.IS_DONE,
    };

    await dispatch(
      TaskApi.complete({
        data: { id, formedData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    status,
    isTaskViewPending: isFetching || !taskData?.workflow_data,
    taskData,
  } as const;
};
