import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
}>;

export const getGroupPublishProcess = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetGroupPublishProcess, TParams>({
    query: ({ id }) => ({
      url: `v1/project-process/get-group-publish-process`,
      params: {
        id,
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_GROUP_PUBLISH_PROCESS],
  });
