import { memo } from "react";
import { KanbanModel } from "src/FSD/entities/kanban";
import { useAppDispatch } from "src/app/store";
import AddButton from "../../../../../../components/UI/components/Buttons/AddButton/AddButton";

const ColumnAddTask = memo(() => {
  const dispatch = useAppDispatch();

  const handleOpenAddTaskModal = () => {
    dispatch(
      KanbanModel.setCreateFreeTask({
        isOpen: true,
      })
    );
  };

  return (
    <AddButton onClick={handleOpenAddTaskModal}>Добавить задание</AddButton>
  );
});

export default ColumnAddTask;
