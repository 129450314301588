import { Controller } from "react-hook-form";
import { Modal, Button, TextField } from "src/FSD/shared/uiKit/v2";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { SelectAgreement } from "src/FSD/features/v2/SelectAgreement";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useRequestRemoveExecutorComplete } from "../lib/hooks/useRequestRemoveExecutorComplete";

export type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const RequestRemoveExecutorCompleteForm = ({ id, type }: Props) => {
  const { form, onSubmit, viewData, isViewDataLoading } =
    useRequestRemoveExecutorComplete({
      id,
      type,
    });

  const { register, control, setValue, handleSubmit } = form;

  return (
    <Modal.Layout>
      <Modal.Header>
        Рассмотрение заявки на Запрос на снятие исполнителя
      </Modal.Header>
      {isViewDataLoading ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Комментарий от руководителя проекта"
            disabled
            multiline
            minRows={2}
            value={viewData!.activeAccess.taskRemove!.content}
          />
          {viewData!.activeAccess.taskRemove!.data?.visibleWithoutFeedback && (
            <Controller
              name="withoutFeedback"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectAgreement
                  value={value}
                  label="Запретить оценку"
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                />
              )}
            />
          )}
          <TextField
            {...register("comment")}
            label="Комментарий"
            placeholder="Введите комментарий"
            disabled
            multiline
            minRows={2}
          />
          <Modal.Controls>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setValue("status", Statuses.REJECT);
              }}
            >
              Отказать
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setValue("status", Statuses.ACCEPT);
              }}
            >
              Согласиться
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
