import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { TFieldValues } from "../../model/fieldValues";

export const createFormData = (
  dataValues: TFieldValues,
  applications: ApiProjectResponses.GetApplications,
  groupId: number
) => {
  const taskIds: number[] = [];

  Object.keys(dataValues).forEach((processTypeId) => {
    Object.keys(dataValues[processTypeId]).forEach((processId) => {
      Object.keys(dataValues[processTypeId][processId]).forEach(
        (userTypeId) => {
          if (!dataValues[processTypeId][processId][userTypeId]) {
            return;
          }

          const taskId =
            applications[groupId!].userTypes[Number(userTypeId)].appProcesses[
              Number(processId)
            ].task.id;

          taskIds.push(taskId);
        }
      );
    });
  });

  return taskIds;
};
