import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  useMaterialProcessCreateMutation,
  useMaterialProcessGetProviderFirmsQuery,
  useLazyMaterialProcessGetTemplateListQuery,
} from "src/FSD/entities/materialProcess/api";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { pushSuccessMessage } from "../../../../../../../../app/feature/errorTrace";
import { useAppDispatch } from "../../../../../../../../app/store";
import { Nullable } from "../../../../../../../../types/generics";
import { updateMaterialProcessSchema } from "../schema/updateMaterialProcess";

export type TFieldValues = {
  files: TFile[];
  comment: string;
  dateLimit: Nullable<Date>;
  template: Nullable<TSelectItem>;
  firmId: number;
  nameRequest: string;
  templateListLength: number;
};

type TProps = {
  processId: number;
  partNum?: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useUpdateMaterialProcess = ({
  processId,
  partNum,
  defaultValues,
  handleClose,
}: TProps) => {
  const dispatch = useAppDispatch();

  const [getTemplateList, { data: templateList }] =
    useLazyMaterialProcessGetTemplateListQuery();

  const { data: providerFirms, isFetching: isFetchingProviderFirms } =
    useMaterialProcessGetProviderFirmsQuery({
      processId,
    });

  const [createMaterialProcess] = useMaterialProcessCreateMutation();

  const fetchGetTemplateList = async () => {
    if (!partNum) {
      return;
    }

    await getTemplateList({ partNum });
  };

  useEffect(() => {
    fetchGetTemplateList();
  }, [partNum]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(updateMaterialProcessSchema),
  });

  const formValues = {
    showUploader: !watch("template"),
    showTemplate: !watch("files").length,
  };

  useEffect(() => {
    if (!templateList) {
      return;
    }

    setValue("templateListLength", templateList.length);
  });

  const onSubmit = async (data: TFieldValues) => {
    const dateLimit = toServerDate(data.dateLimit);

    const formData = new FormData();

    formData.append("comment", data.comment);
    formData.append("name", data.nameRequest);
    formData.append("comment", data.comment);
    formData.append("date_limit", dateLimit!);

    if (partNum) {
      formData.append("project_part_id", String(partNum));
    }

    if (data.template) {
      formData.append("template_id", String(data.template.id));
    }

    if (data.firmId === -1) {
      providerFirms!.forEach((providerFirm: any) =>
        formData.append("firmId", providerFirm.id)
      );
    } else {
      formData.append("firmId", String(data.firmId));
    }

    data.files.forEach(({ file }: TFile) => {
      formData.append("uploadFiles[]", file!);
    });

    const result = await createMaterialProcess({
      processId,
      data: formData,
    });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    onSubmit,
    setValue,
    errors,
    register,
    handleSubmit,
    isSubmitting,
    providerFirms,
    control,
    isFetching: isFetchingProviderFirms || !providerFirms || !templateList,
    templateList,
    formValues,
  } as const;
};
