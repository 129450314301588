import { TFile } from "../../../features/NewUploader/types";

export interface FilesState {
  initialIsFilled: boolean;
  initial: TFile[];
  final: TFile[];
}

export const getFilesDifference = (files: FilesState) => {
  const initialIds = files.initial.map(({ id }) => id);
  const finalIds = files.final.map(({ id }) => id);

  return Boolean(finalIds.filter((id) => !initialIds.includes(id)).length);
};
