import { useContext, useState } from "react";
import moment from "moment";
import { Application as ApplicationCard } from "src/FSD/entities/application";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { palette } from "../../../../../../../styles/restyle";
import styles from "./Executors.module.scss";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import { GroupApplicationsTable } from "./GroupApplicationsTable";
import { ToggleAccordion } from "../../../../../../../components/newUI/ToggleAccordion/ToggleAccordion";
import { trimPrice } from "../../../../../../../utils/trimNumber";
import { ApplicationsContext } from "../Applications";
import toggleElementInArray from "../../../../../../../utils/toggleElementInArray";
import {
  ABSCENT_STATUS_TEXT,
  MAX_3_EXECUTORS,
  MULTIPLE_GROUP_SELECTION_NOT_ALLOWED,
} from "../../model/constants";

type TProps = {
  executor: ApiProjectResponses.GetApplications[0]["userTypes"][0];
  groupId: number;
};

export const ExecutorCard = ({
  executor,
  groupId: executorGroupId,
}: TProps) => {
  const [showApplications, setShowApplications] = useState<boolean>(false);

  const {
    selectedUserTypeIds,
    setSelectedUserTypeIds,
    groupId,
    setGroupId,
    setBankErrorTaskIds,
    setTotalPrice,
    form: { reset },
  } = useContext(ApplicationsContext);

  const {
    fio,
    photo_id,
    job_duration,
    userTypeText,
    userType: { id: userTypeId, user_id: userId },
    rating,
    countsByType: { dateStart, dateLimit, ndsText, price },
    appProcesses,
  } = executor;

  const formattedDateStart = moment(dateStart.date).format("DD.MM.YYYY");

  const formattedDateLimit = moment(dateLimit.date).format("DD.MM.YYYY");

  const formattedPrice = trimPrice(price);

  const appProcessesCount = Object.keys(appProcesses).length;

  const appProcessesAppliedCount = Object.values(appProcesses).reduce(
    (count: number, appProcess: any) => {
      return count + Number(appProcess.statusText !== ABSCENT_STATUS_TEXT);
    },
    0
  );

  const isExecutorSelected =
    selectedUserTypeIds.includes(userTypeId) && executorGroupId === groupId;

  const formattedJobDuration = Math.floor(+job_duration / 365);

  const handleSelectExecutor = () => {
    if (groupId && groupId !== executorGroupId) {
      window.alert(MULTIPLE_GROUP_SELECTION_NOT_ALLOWED);

      setGroupId(executorGroupId);
      setSelectedUserTypeIds([userTypeId]);

      reset();
      setBankErrorTaskIds([]);
      setTotalPrice(0);

      return;
    }

    const newSelectedExecutorIds = toggleElementInArray(
      selectedUserTypeIds,
      userTypeId
    );

    if (newSelectedExecutorIds.length > 3) {
      window.alert(MAX_3_EXECUTORS);
      return;
    }

    if (newSelectedExecutorIds.length) {
      setGroupId(executorGroupId);
    } else {
      setGroupId(null);
    }

    setSelectedUserTypeIds(newSelectedExecutorIds);
  };

  return (
    <ApplicationCard
      className={styles.applicationCard}
      avatar={
        <ApplicationCard.Avatar
          photoUrl={`${config.localDomain}/file/get?id=${photo_id}`}
          borderColor={palette.persianGreen}
          lowerText={userTypeText}
          fio={fio}
          userId={userId}
        />
      }
      rating={
        <ApplicationCard.Rating
          rating={+rating}
          jobDuration={formattedJobDuration}
        />
      }
      duration={
        <ApplicationCard.Segment prompt="Общий срок">
          {formattedDateStart} - {formattedDateLimit}
        </ApplicationCard.Segment>
      }
      price={
        <ApplicationCard.Segment
          prompt={ndsText}
          className={styles.applicationCard__price}
        >
          {formattedPrice} ₽
        </ApplicationCard.Segment>
      }
      actions={
        <div onClick={handleSelectExecutor} className={styles.bookMark}>
          {isExecutorSelected ? <BookmarkFill color="orange" /> : <Bookmark />}
        </div>
      }
      footer={
        <ApplicationCard.Footer className={styles.processes}>
          <div
            onClick={() => setShowApplications((prev) => !prev)}
            className={styles.processes__applications}
          >
            <Paragraph>
              Заявки, поданные на разделы группы ({appProcessesAppliedCount}/
              {appProcessesCount})
            </Paragraph>
            <ToggleAccordion toggle={showApplications} secondRoot />
          </div>
          {showApplications && (
            <GroupApplicationsTable appProcesses={appProcesses} />
          )}
        </ApplicationCard.Footer>
      }
    />
  );
};
