import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  useRemarkRowViewQuery,
  useRemarkRowUpdateMutation,
} from "src/FSD/entities/expertise/remarkRow/api";
import {
  projectProcessApi,
  useProjectProcessToProjectsQuery,
} from "src/FSD/entities/projectProcess/api";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "src/types/generics";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { DDMMYYYYToDate } from "src/FSD/shared/lib/helpers";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import {
  objectToSelect,
  toSelect,
} from "src/app/feature/project/projectProcessPublication";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getExpertiseRemarksIsLoad } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { editRemarkRowSchema } from "../schemas/editRemarkRow";
import { TProps as UseModalProps } from "../../ui/EditRemarkRowForm";

const defaultValues = {
  text: "",
  comment: "",
  project: null,
  dateLimit: null,
  projectPartIds: [],
};

type TFieldValues = {
  text: string;
  comment: string;
  dateLimit: Nullable<Date>;
  project: Nullable<TSelectItem>;
  projectPartIds: TSelectItem[];
};

export const useEditRemarkRow = ({ processId, remarkRowId }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const dispatch = useAppDispatch();

  // убрать в будущем в FSD
  const isLoadExpertiseRemarks = useAppSelector(getExpertiseRemarksIsLoad);

  const { data: projectsListRaw, isFetching: isProjectsFetching } =
    useProjectProcessToProjectsQuery({
      processId,
    });

  const { data: remarkRow, isFetching: isRemarkRowFetching } =
    useRemarkRowViewQuery({
      id: remarkRowId,
    });

  const [
    fetchToProcesses,
    { data: processesListRaw, isLoading: isProcessesFetching },
  ] = projectProcessApi.useLazyProjectProcessToProcessesQuery();

  // /v1/expertise/remark-row/update
  const [updateRemarkRow, { isLoading: isRemarkRowUpdating }] =
    useRemarkRowUpdateMutation();

  const projectsList = useDataMapper(projectsListRaw, objectToSelect);

  const processesList = useDataMapper(processesListRaw, toSelect);

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(editRemarkRowSchema),
  });

  useEffect(() => {
    if (!remarkRow || !projectsList) {
      return;
    }

    setValue("text", remarkRow.text);
    setValue("comment", remarkRow.comment);
    setValue("dateLimit", DDMMYYYYToDate(remarkRow.date_limit));

    const project = projectsList.find(
      (project: TSelectItem) => project.id === remarkRow.project_id
    );
    setValue("project", project);
  }, [remarkRow, projectsList]);

  const onSubmit = async (dataValues: TFieldValues) => {
    const data = {
      text: dataValues.text,
      comment: dataValues.comment,
      date_limit: toServerDate(dataValues.dateLimit),
      project_id: dataValues.project!.id as number,
      project_part_ids: dataValues.projectPartIds.map(({ id }) => id as number),
    };

    await updateRemarkRow({ id: remarkRowId, remarkRow: data });
    await dispatch(fetchExpertiseRemarks(processId));

    handleClose();
  };

  const projectValue = watch("project");

  useEffect(() => {
    if (!projectValue) {
      return;
    }

    fetchToProcesses({
      projectId: projectValue.id as number,
      processId,
    });
  }, [projectValue, processId]);

  useEffect(() => {
    if (!processesList || !remarkRow) {
      return;
    }

    const processIds = new Set(remarkRow.project_part_ids);
    const projectPartIds = processesList.filter((process: TSelectItem) =>
      processIds.has(process.id as number)
    );

    setValue("projectPartIds", projectPartIds);
  }, [processesList, remarkRow]);

  const isFetching = isRemarkRowFetching && !remarkRow && !processesList;

  const disabled =
    isRemarkRowFetching ||
    isProjectsFetching ||
    isProcessesFetching ||
    isRemarkRowUpdating;

  return {
    isFetching,
    handleSubmit,
    onSubmit,
    control,
    errors,
    isProcessesFetching,
    projectsList,
    isSubmitting: isRemarkRowUpdating || isLoadExpertiseRemarks,
    processesList,
    disabled,
    isProjectsFetching,
  } as const;
};
