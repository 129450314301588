import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import styles from "./AddRights.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  createProjectAccess,
  getAccessCodesByProject,
  getAvailableUsersOnProject,
  getFirmsInProjects,
} from "../../../../../../app/feature/ProjectView/AdminReducer/thunks";
import {
  TRolesModal,
  clearAddRightsData,
  getProjectUsersList,
} from "../../../../../../app/feature/ProjectView/AdminReducer/projectAdmin";
import { notNullRequired } from "../../../../../../utils/SimplifiedYup";

interface AddRightsProps {
  projectId: number;
  handleCloseModal: () => void;
}

const validationSchema = Yup.object().shape({
  firm: notNullRequired("Организация"),
  user: notNullRequired("Пользователь"),
  role: notNullRequired("Роль"),
});

const AddRights = ({ projectId, handleCloseModal }: AddRightsProps) => {
  const dispatch = useAppDispatch();

  const {
    firmsInProject,
    availableUsersOnProject,
    accessCodes,
    pending,
    defaultValues,
  } = useAppSelector(getProjectUsersList);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<TRolesModal>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const firmValue = watch("firm");

  const onSubmit = async (data: TRolesModal) => {
    const { user, role, firm } = data;

    await dispatch(
      createProjectAccess({
        projectId,
        data: {
          userId: user!.id,
          accessCode: role!.id,
          firmId: firm!.id,
          needComplete: true,
        },
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(getFirmsInProjects(projectId));
    dispatch(getAccessCodesByProject({ projectId, isInviteGip: true }));

    return () => {
      dispatch(clearAddRightsData());
    };
  }, []);

  useEffect(() => {
    if (!firmValue) {
      return;
    }

    dispatch(
      getAvailableUsersOnProject({ projectId, firmId: firmValue.id as number })
    );
  }, [firmValue?.id]);

  const isDisabled =
    Object.values(pending).some(Boolean) || pending.createProjectAccess;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <Controller
        name="firm"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            variant={styles.selectField}
            isLoading={pending.firmsInProject}
            label=""
            newLabel={"Организация"}
            options={firmsInProject}
            error={Boolean(errors.firm)}
            helperText={errors.firm?.message}
            newDesign
          />
        )}
      />
      <Controller
        name="user"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            variant={styles.selectField}
            isLoading={pending.availableUsersOnProject}
            label=""
            newLabel={"Пользователь"}
            options={availableUsersOnProject}
            error={Boolean(errors.user)}
            helperText={errors.user?.message}
            newDesign
            disabled={!availableUsersOnProject.length}
          />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            changeHandler={(_, newValue) => {
              onChange(newValue);
            }}
            disableClear
            isLoading={pending.accessCodes}
            variant={styles.selectField}
            label=""
            newLabel={"Роль"}
            options={accessCodes}
            error={Boolean(errors.role)}
            helperText={errors.role?.message}
            newDesign
          />
        )}
      />
      <Button
        className={styles.submitButton}
        disabled={isDisabled}
        isLoading={isSubmitting}
        type="submit"
      >
        Добавить
      </Button>
    </form>
  );
};

export default AddRights;
