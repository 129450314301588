import { Dispatch } from "redux";
import { isBadStatusRequest } from "./api";
import { ApiRegionListPage } from "./Region/region";
import C from "../../../constants";

export interface FormErrorType {
  field: string;
  message: string;
}

// Обертка стандартного поведения обработки запроса
export type RequestHandlerType = (
  fetch: Promise<Response>,
  actionHandler?: (
    data: any,
    headersValue?: any,
    headers?: any,
    status?: number
  ) => void,
  errorHandler?: (dispatch: Dispatch) => (err: Record<string, any>) => void
) => (dispatch?: Dispatch) => Promise<void>;

export const request: RequestHandlerType =
  (fetch, actionHandler, errorHandler) => async (dispatch) => {
    try {
      const response = await fetch;

      const { headers, status } = response;

      const headersObj: Record<string, string> = {};

      headers.forEach((value, key) => {
        headersObj[key] = value;
      });

      const result = await response.json();

      if (isBadStatusRequest(status)) {
        throw result;
      }

      if (actionHandler) {
        actionHandler(result, headers, headersObj, status);
      }
    } catch (err: any) {
      if (err instanceof DOMException) {
        // если запрос прерван, то не нужно обрабатывать сообщение об ошибке
        return;
      }

      if (errorHandler) {
        errorHandler(dispatch!)(err as Record<string, any>);
      }
    }
  };

export const requestAllData: RequestHandlerType =
  (fetch, actionHandler, errorHandler) => async (dispatch) => {
    try {
      const headersData = await (await fetch).headers;
      const head: any = {};
      headersData.forEach((value, key) => {
        head[key] = value;
      });

      const maxPages = head["x-pagination-page-count"];
      let result: any;
      await Promise.all(
        Array(Number(maxPages))
          .fill("")
          .map((_, i) => ApiRegionListPage(i + 1).then((res) => res.json()))
      ).then((data) => {
        result = data;
      });

      if (isBadStatusRequest(result.status)) {
        throw result;
      }
      if (actionHandler) actionHandler(result, head, headersData);
    } catch (err: any) {
      console.log(err);
      if (errorHandler) errorHandler(dispatch!)(err as Record<string, any>);
    }
  };

export const parseUnprocessableFields = (message: string, setError: any) => {
  const errorsList = JSON.parse(message);

  console.log(errorsList);

  Object.keys(errorsList).forEach((field) => {
    errorsList[field].forEach((error: string) => {
      setError(field, { message: error });
    });
  });
};

export const getErrorsList = (err: any) => {
  const errorsList = JSON.parse(err.message).message;
  const errors: FormErrorType[] = [];
  parseUnprocessableFields(
    errorsList,
    (field: string, e: { message: string }) => {
      errors.push({ field, message: e.message });
    }
  );

  return errors;
};

export const getFirstErrorMessage = (
  errorsList: Record<string, any> | string | string[]
): Record<string, any> | string | string[] => {
  if (typeof errorsList === "string") {
    return errorsList;
  }

  if (Array.isArray(errorsList) && errorsList.length) {
    return errorsList[0];
  }

  if (
    typeof errorsList === "object" &&
    !Array.isArray(errorsList) &&
    errorsList !== null
  ) {
    const keys = Object.keys(errorsList);
    return getFirstErrorMessage(errorsList[keys[0]]);
  }

  return C.ERRORS_OCCURED;
};

export const getErrorMessage = (err: any) => {
  if (typeof err === "string") {
    return err;
  }

  let message = "";
  try {
    if (Array.isArray(err) && err.length) {
      return err[0];
    }

    const errorsList = JSON.parse(err.message);
    message = getFirstErrorMessage(errorsList) as string;
  } catch (e) {
    message = err.message;
  }

  return message.replace(/<[^>]+>/g, "");
};
