import React, { FC, memo, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import classNames from "classnames";
import style from "./style.module.scss";
import {
  setInvisible,
  getModalVisibility,
} from "../../../../../app/feature/modalController/modalVisibility";
import PersonalDataForm from "./PersonalDataForm";
import useProfile from "../../../../../app/feature/profileReducer/useProfile";
import { getUserId } from "../../../../../app/services/auth/auth";

interface ModalWrapperProps {
  show?: boolean;
  head?: ReactNode;
  children: ReactNode;
  width?: number;
  handleCloseModal: () => void;
  classname?: string;
  classnameWindow?: string;
  pending?: boolean;
}

export const ModalWrapper: FC<ModalWrapperProps> = memo(
  ({
    show,
    head = null,
    children,
    handleCloseModal,
    width,
    classname,
    classnameWindow,
    pending,
  }) => {
    useEffect(() => {
      const listener = (e: any) => {
        let isEscape = false;
        if ("key" in e) {
          isEscape = e.key === "Escape" || e.key === "Esc";
        }

        if (isEscape) {
          handleCloseModal();
        }
      };

      window.addEventListener("keyup", listener);

      return () => {
        window.removeEventListener("keyup", listener);
      };
    });

    const styles = width ? { width } : {};

    return (
      <>
        {show && (
          <div
            className={classNames(style.window, classnameWindow)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={classNames(style.modal, classname)} style={styles}>
              <div className={style.head}>
                <IconButton
                  onClick={handleCloseModal}
                  className={style.clearButton}
                >
                  <Clear />
                </IconButton>
                {head}
              </div>
              {pending ? (
                <div className={style.pending}>
                  <CircularProgress />
                </div>
              ) : (
                children
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

const PersonalDataChange: FC = memo(() => {
  const dispatch = useDispatch();
  const { visible } = useSelector(getModalVisibility);

  const id = getUserId();
  const { profileRequestData, profileRequestLoading, loadProfileRequest } =
    useProfile();

  useEffect(() => {
    const validId = String(id);

    if (!validId) {
      return;
    }

    loadProfileRequest(validId);
  }, [id]);

  const handleCloseModal = () => {
    dispatch(setInvisible());
  };

  if (!visible) {
    return null;
  }

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      show={!profileRequestLoading}
    >
      {!profileRequestLoading && (
        <PersonalDataForm
          handleCloseModal={handleCloseModal}
          profileRequestData={profileRequestData}
        />
      )}
    </ModalWrapper>
  );
});

export default PersonalDataChange;
