import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { getRemarks, create, deleteRemark, start } from "./queries";

export const remarkApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    remarkGetRemarks: getRemarks(builder),
    remarkCreate: create(builder),
    remarkDelete: deleteRemark(builder),
    remarkStart: start(builder),
  }),
  overrideExisting: false,
});

export const {
  useRemarkGetRemarksQuery,
  useRemarkCreateMutation,
  useRemarkDeleteMutation,
  useRemarkStartMutation,
} = remarkApi;
