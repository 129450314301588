import React from "react";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import { useSourceData } from "src/FSD/widgets/v2/projectProcess/std/SourceData/lib/hooks/useSourceData";
import styles from "./SourceData.module.scss";
import { SourceDataContent } from "./SourceDataContent";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { ApiProjectProcessResponses } from "../../../../../../entities/projectProcess/model/responseTypes";

type TProps = {
  canProjectProcess: ApiProjectProcessResponses.View["can"];
  canProject: ApiProjectResponses.View["can"];
  projectProcessId: number;
  projectParentId: number;
};

export const SourceData = ({
  canProjectProcess,
  canProject,
  projectProcessId,
  projectParentId,
}: TProps) => {
  const { data, activeTab, isLoading, searchValue, setSearch } = useSourceData({
    id: projectProcessId,
  });

  return (
    <div className={styles.wrapper}>
      <TabChildrenHeader
        title="Исходные данные по проекту"
        media={<SearchBar onChange={setSearch} />}
        className={styles.tab__header}
      />
      {isLoading ? (
        <Skeleton.List height={65} className={styles.skeleton} />
      ) : (
        <ScrollWrap height={"876px"}>
          <SourceDataContent
            activeTab={activeTab}
            data={data!}
            canProjectProcess={canProjectProcess}
            canProject={canProject}
            projectParentId={projectParentId}
            searchValue={searchValue}
          />
        </ScrollWrap>
      )}
    </div>
  );
};
