import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";
import { Avatar } from "../Avatar/Avatar";
import { TextLink } from "../TextLink/TextLink";
import { Rating } from "../Rating/Rating";
import { Verify } from "../Verify/Verify";
import { BiM } from "../Decoration/BiM/BiM";
import { cutOff } from "../../../../app/services/other";

export interface ExecutorType {
  id: number;
  name: { name: string; surname: string };
  tested: boolean;
  photo: string;
  workWithBim: boolean;
  skills: string;
  rating: number;
  region: { title?: string | undefined; country?: string | undefined } | null;
}

export type ExecutorsListProps = ComponentPropsType<{
  items: ExecutorType[];
}>;

export const ExecutorsList: React.FC<ExecutorsListProps> = ({
  variant = "",
  theme = "dark",
  items,
}) => {
  return (
    <ul className={classNames(variant, style.list)}>
      {items.map((item: ExecutorType, i: number) => {
        return (
          <li className={style.item} key={i}>
            <div>
              <Avatar size={"large"} src={item.photo} />
            </div>
            <div className={style.infoUser}>
              <div className={style.linkContainer}>
                <NavLink
                  className={classNames(style.executor, style[theme])}
                  to={`/user/${item.id}?tab=info`}
                >
                  <p>{cutOff(item.name.name)}</p>
                  <p>{cutOff(item.name.surname, 16)}</p>
                </NavLink>
                <div className={style.verifyWrapper}>
                  <Verify
                    confirm={item.tested}
                    theme={theme}
                    variant={style.verify}
                  />
                  {item.workWithBim && (
                    <BiM theme={theme} variant={style.withBim} />
                  )}
                </div>
              </div>
              {item.skills && (
                <span className={style.skills}>{cutOff(item.skills, 34)}</span>
              )}
              <div className={style.ratingWrapper}>
                <Rating value={item.rating} name="half-rating-read" readOnly />
                <TextLink
                  variant={style.feedback}
                  url={`/user/${item.id}?tab=feedback`}
                >
                  Отзывы
                </TextLink>
              </div>
              {item.region ? (
                <span className={style.region}>
                  {item.region.country}, {item.region.title}
                </span>
              ) : (
                <></>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
