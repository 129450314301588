import { FC, useState } from "react";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import { Avatar } from "@mui/material";
import cn from "classnames";
import style from "./style.module.scss";
import { StarSVG } from "../../../../newUI/SVG";
import { Price } from "../../../User/Dashboard/Secondary/Sections/Section";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import {
  TExecutor,
  getGroupApplications,
  selectExecutor,
  clearSelectedExecutors,
} from "../../../../../app/feature/ProjectView/GroupApplications";
import { getYearsCount } from "../../../../utils";
import { config } from "../../../../../app.cofig";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

enum List {
  Comments = "Comments",
  Applications = "Applications",
  Closed = "Closed",
}

interface ExecutorCardProps {
  executor: TExecutor;
  groupId: number;
}

const ExecutorCard: FC<ExecutorCardProps> = ({ executor, groupId }) => {
  const {
    id,
    photoId,
    fio,
    experience,
    rating,
    userTypeText,
    ndsText,
    price,
    deadlines,
    comments,
    applications,
  } = executor;

  const dispatch = useAppDispatch();

  const {
    data: { selectedExecutors },
  } = useAppSelector(getGroupApplications);

  const [openTab, setOpenTab] = useState<string>(List.Closed);

  const years = getYearsCount(experience);

  const isSelected = Boolean(
    selectedExecutors.find(
      (executor: TExecutor & { groupId: number }) =>
        executor.id === id && executor.groupId === groupId
    )
  );

  const handleToggleTab = (tab: string) => {
    if (tab === openTab) {
      setOpenTab(List.Closed);
      return;
    }

    setOpenTab(tab);
  };

  const handleSelectExecutor = () => {
    const ids = selectedExecutors.map(
      (executor: TExecutor & { groupId: number }) => executor.groupId
    );

    const isDisabled = Array.from(new Set([...ids, groupId])).length > 1;

    if (isDisabled) {
      alert("Выбранные для сравнения заявки из других групп будут сброшены!");
      dispatch(clearSelectedExecutors());
      dispatch(selectExecutor({ ...executor, groupId }));
      return;
    }

    if (!isSelected && selectedExecutors.length === 3) {
      alert("Для сравнения можно выбрать только 3 исполнителей");
      return;
    }

    dispatch(selectExecutor({ ...executor, groupId }));
  };

  const BookMark = () => (
    <div onClick={handleSelectExecutor} className={style.bookMark}>
      {isSelected ? <BookmarkFill color="orange" /> : <Bookmark />}
    </div>
  );

  const Deadline = () => (
    <>
      {openTab === List.Closed && (
        <div className={cn(style.line, style.bold)}>
          <p>Сроки</p>
          <p>{deadlines}</p>
        </div>
      )}
    </>
  );

  const Comments = () => (
    <>
      {openTab === List.Comments && (
        <>
          <div className={cn(style.line, style.bold)}>
            <p>Комментарий</p>
            <p />
          </div>
          {Object.entries(comments).map(([process, comment]: any) => (
            <div className={cn(style.line, style.forComment)}>
              <p>{process}</p>
              <p>{comment}</p>
            </div>
          ))}
        </>
      )}
    </>
  );

  const Applications = () => (
    <>
      {openTab === List.Applications && (
        <>
          <div className={cn(style.line, style.bold)}>
            <p>Все заявки группы</p>
            <p />
          </div>
          {Object.entries(applications).map(([process, status]: any) => (
            <div className={style.line}>
              <p>{process}</p>
              <p>{status}</p>
            </div>
          ))}
        </>
      )}
    </>
  );

  return (
    <div className={style.wrapper}>
      <BookMark />
      <div className={style.executorCard}>
        <div className={style.data}>
          <Avatar
            className={style.avatar}
            src={`${config.localDomain}/file/get?id=${photoId}`}
          />
          <div className={style.name}>
            <p>
              Опыт {experience} {years}
            </p>
            <h2>{fio}</h2>
          </div>
        </div>
        <div className={style.additionalData}>
          <div className={style.rating}>
            <StarSVG />
            <p>{rating}</p>
          </div>
          <p>{userTypeText}</p>
        </div>
        <div className={style.insertions}>
          <div className={style.tabs}>
            <p onClick={() => handleToggleTab(List.Comments)}>
              Комментарий&nbsp;&nbsp;
              <ToggleAccordion toggle={openTab === List.Comments} />
            </p>
            <p onClick={() => handleToggleTab(List.Applications)}>
              Все заявки группы&nbsp;&nbsp;
              <ToggleAccordion toggle={openTab === List.Applications} />
            </p>
          </div>
          <Price secure={false} price={price} message={ndsText} />
        </div>
      </div>
      <div className={style.bottomSection}>
        <Deadline />
        <Comments />
        <Applications />
      </div>
    </div>
  );
};

export default ExecutorCard;
