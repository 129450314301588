import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import classNames from "classnames";
import { ComponentPropsType } from "src/types/other/components/componentPropsType";
import { ApiSendMoney } from "src/app/services/api/organisation/organisation";
import { request } from "src/app/services/api/requestHandler";
import { selectorSendMoneyData } from "src/app/feature/eventEmitter/organisation/eventSendMoney";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import {
  selectorModalClosed,
  setModalClosed,
} from "src/app/feature/modalClose/eventModalClose";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { palette, textFieldSX } from "src/styles/restyle";

type TFormValues = {
  value: number;
};

const schema = Yup.object().shape({
  value: Yup.string()
    .required("Необходимо заполнить «Сумма»")
    .test("typeError", "Значение «Сумма» должно быть числом.", (val) => {
      if (!val) {
        return false;
      }
      return val === String(+val);
    }),
});

export const margin = "0 0 0 calc(100% - 160px)";

export type SelectItemProps = ComponentPropsType<unknown>;

export type SetUserRoleFormProps = ComponentPropsType<{
  submitHandler?: () => void;
  submitClose?: () => void;
  closeHandler?: VoidFunction;
}>;

export const SendMoneyForm: React.FC<SetUserRoleFormProps> = ({
  variant = "",
  closeHandler = () => {},
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { Popup } = useUserWorkflow();
  const { userRoleId } = useSelector(selectorSendMoneyData);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      value: 0,
    },
    resolver: yupResolver(schema),
  });

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const getOpacity = () => (disabled ? ".5" : "1");

  const onSubmit = async (data: any) => {
    setDisabled(true);

    await request(
      ApiSendMoney(userRoleId, data),
      () => {
        enqueueSnackbar("Сумма успешно переведена пользователю", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          setDisabled(false);
          Popup.sendMoney.set({});
          closeHandler();
        }, 500);
      },
      () => (err) => {
        if (err.status === 403) {
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar("Ошибка переведения суммы пользователю", {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
        setTimeout(() => {
          setDisabled(false);
          Popup.sendMoney.set({});
          closeHandler();
        }, 500);
      }
    )();

    dispatch(setModalClosed(!modalClosed));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, variant)}
    >
      <TextField
        {...register("value")}
        name={"value"}
        variant="outlined"
        label="Сумма"
        size={"small"}
        autoComplete={"off"}
        error={Boolean(errors.value)}
        helperText={errors.value?.message}
        {...textFieldSX}
      />
      <br />
      <CustomButton
        disabled={disabled}
        type="submit"
        background={palette.blue}
        width={160}
        style={{
          margin,
          opacity: getOpacity(),
        }}
      >
        Перевести
      </CustomButton>
    </form>
  );
};
