import { UseFormSetValue } from "react-hook-form";
import { TFieldValues } from "../../model/fieldValues";

export const removeUserTypeId = (
  formValues: TFieldValues,
  userTypeId: number,
  setValue: UseFormSetValue<TFieldValues>
) => {
  const taskIds: number[] = [];

  Object.keys(formValues).forEach((processTypeId) => {
    Object.keys(formValues[processTypeId]).forEach((processId) => {
      Object.keys(formValues[processTypeId][processId]).forEach((id) => {
        if (Number(id) === userTypeId) {
          setValue(`${processTypeId}.${processId}.${userTypeId}`, false);
        }
      });
    });
  });

  return taskIds;
};
