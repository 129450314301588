import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  dateFutureRequired,
  dateRequired,
  messages,
  priceMinMaxRequired,
  stringMinMaxLengthRequired,
} from "src/utils/SimplifiedYup";

export const requestEquipmentSchema = Yup.object().shape({
  dateDeadline: dateFutureRequired("Действительно до"),
  price: Yup.mixed().when("status", {
    is: (status) => status === Statuses.IS_DONE,
    then: priceMinMaxRequired("Стоимость", 100, 10000000).integer(
      messages.shouldBeIntNumber
    ),
  }),
  userType: dateRequired("Форма собственности"),
  comment: stringMinMaxLengthRequired("Комментарий", 15, 200),
});
