import React, { useState } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { createSearchObjectDataFromFormParams } from "../../../../app/services/searchParams/searchParamsCreateData";
import style from "./style.module.scss";
import globalStyles from "../style.module.scss";
import { buttonStyle } from "../Executors/ExecutorFormHead";
import { Specialization } from "../../../features/SpecialForm/Specialization";
import { DesignType } from "../../../features/SpecialForm/DesignType";
import { DatePicker } from "../../../UI/components/FormComponentns/DatePicker/DatePicker";

import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { palette, textFieldSX } from "../../../../styles/restyle";

type ProjectProcessFormType = ComponentPropsType<{
  submitHandler?: (params: Record<string, any>, type: string) => void;
  resetHandler?: () => void;
  initialValues?: Record<string, any>;
}>;

export const ProjectProcessForm: React.FC<ProjectProcessFormType> = ({
  theme = "dark",
  submitHandler = () => {},
  resetHandler = () => {},
  initialValues = () => {},
}) => {
  const sx = {
    bgcolor: palette.white,
    color: palette.common,
  };
  const start = initialValues["ProjectProcessSearch[datePublish1]"]
    ? moment(
        initialValues["ProjectProcessSearch[datePublish1]"],
        "DD.MM.YYYY"
      ).toDate()
    : null;
  const end = initialValues["ProjectProcessSearch[datePublish2]"]
    ? moment(
        initialValues["ProjectProcessSearch[datePublish2]"],
        "DD.MM.YYYY"
      ).toDate()
    : null;
  const [dateValue, setDateValue] = useState<(Date | null)[]>([null, null]);
  const { handleSubmit, control, setValue, reset, watch, register } = useForm({
    defaultValues: {
      sections: "",
      group: initialValues["PublishedObjectSearch[partGroupId]"],
      dates: [start, end],
      designType: initialValues["PublishedObjectSearch[design_type]"],
    },
  });
  const onSubmit = async (data: any) => {
    const searchData = createSearchObjectDataFromFormParams(
      data,
      "projectsProcessExercise"
    );
    submitHandler(searchData, "Задание");
  };
  const values = watch("sections");
  const specializationValue = watch("group");
  const designValue = watch("designType");
  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div className={style.formContainer}>
        <div className={style.formGroup}>
          <FormControl className={style.formGroup_select} {...textFieldSX}>
            <InputLabel id="demo-simple-select-label">Раздел</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              {...register("sections")}
              value={values}
              onChange={(event) => {
                setValue("sections", event.target.value as string);
              }}
              label={"Раздел"}
            >
              <MenuItem sx={sx} value={"Раздел"}>
                Раздел
              </MenuItem>
              <MenuItem sx={sx} value={"Задание"}>
                Задание
              </MenuItem>
            </Select>
          </FormControl>
          <Specialization
            {...register("group")}
            label={"Специализация"}
            value={specializationValue}
            variant={style.formElement}
            changeHandler={(data) =>
              setValue("group", data ? data.id : undefined)
            }
          />
          <DesignType
            {...register("designType")}
            value={designValue}
            variant={style.formElementSmall}
            changeHandler={(data) =>
              setValue("designType", data ? data.id : undefined)
            }
          />
          <Controller
            render={({ field }) => (
              <DatePicker
                {...field}
                multiple
                startDateProp={dateValue[0]}
                endDateProp={dateValue[1]}
                variant={style.formElement}
                label={"Сроки выполнения"}
                changeDateHandler={(dates: any) => {
                  setDateValue(dates);
                  setValue("dates", dates || undefined);
                }}
              />
            )}
            name="dates"
            control={control}
          />
        </div>
        <div className={globalStyles.buttonFormWrapper}>
          <Button
            theme={theme}
            variant="contained"
            color="primary"
            style={buttonStyle}
            type={"submit"}
          >
            Поиск
          </Button>
          <Button
            theme={theme}
            variant="contained"
            color="grey"
            style={buttonStyle}
            clickHandler={() => {
              reset();
              setDateValue([null, null]);
              resetHandler();
            }}
          >
            Сбросить
          </Button>
        </div>
      </div>
    </form>
  );
};
