import { forwardRef } from "react";
import {
  Select,
  SelectProps,
  TSelectItem,
} from "src/FSD/shared/uiKit/v2/Select";
import { useSelectUserTypeToAssign } from "../lib/hooks/useSelectUserTypeToAssign";
import { Nullable } from "src/types/generics";

type Props = {
  publishedObjectId: number;
  type: "Project" | "ProjectProcess" | "ProcessWork";
  executor?: Nullable<TSelectItem>;
  userTypeId?: number;
  setValue?: any;
} & Omit<SelectProps, "label" | "options">;

export const SelectUserTypeToAssign = forwardRef(
  (
    {
      publishedObjectId,
      value,
      className = "",
      disabled = false,
      executor,
      userTypeId,
      setValue,
      type,
      ...props
    }: Props,
    ref
  ) => {
    const { userTypeToAssign, isPendingTypeAssign } = useSelectUserTypeToAssign(
      {
        id: publishedObjectId,
        type,
        executor,
        userTypeId,
        setValue,
      }
    );

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={userTypeToAssign ?? []}
        isLoading={isPendingTypeAssign}
        label="Форма собственности"
        className={className}
        disabled={disabled}
      />
    );
  }
);
