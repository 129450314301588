import { UseFormReturn } from "react-hook-form";
import { checkRow } from "./checkRow";
import { TGroupFieldValues } from "../../model/types/fieldValues";

export const setCommonPrice = (
  form: UseFormReturn<TGroupFieldValues>,
  fields: Record<"id", string>[]
) => {
  const { getValues, setValue } = form;

  const price = getValues("common.price");

  if (!price) {
    return;
  }

  const activeCount = fields.filter((_, index) =>
    getValues(`processes.${index}.isActive`)
  ).length;

  const dividedPrice = (Number(price) / activeCount).toFixed(2);

  if (Number.isNaN(dividedPrice)) {
    return;
  }

  fields.forEach((_, index) => {
    const isActive = getValues(`processes.${index}.isActive`);
    if (isActive) {
      setValue(`processes.${index}.price`, dividedPrice);
      checkRow(index, form);
    }
  });
};
