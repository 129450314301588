import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { request } from "src/app/services/api/requestHandler";
import { ApiPostResetPasswordForm } from "src/app/services/api/forgotPassword/forgotPassword";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { selectorAppTheme } from "src/app/feature/app/app";
import { useQuery } from "src/app/services/url/getQueryParameters";
import style from "./style.module.scss";
import { resetPasswordFormSchema } from "./yupSchemas";
import { textFieldSX } from "src/styles/restyle";

type TFormValues = {
  password: string;
  password_confirm: string;
};

export const ResetPasswordForm = () => {
  const theme = useSelector(selectorAppTheme);
  const [showMessage, setShowMessage] = useState(false);
  const [messageError, setMessageError] = useState("");
  const token = useQuery("token");

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      password_confirm: "",
    },
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const onSubmit = (data: TFormValues) => {
    request(
      ApiPostResetPasswordForm(token!, {
        ResetPwdForm: {
          password: data.password,
          password_confirm: data.password_confirm,
        },
      }),
      (result, headersData, headers, status) => {
        if (status === 200) {
          setShowMessage(true);
        }
      },
      () => (data) => {
        if (data.status === 404) {
          setMessageError(data.message);
        }

        if (data.status === 422) {
          const message = JSON.parse(data.message);
          Object.keys(message).forEach((key, i) => {
            setError(key as keyof TFormValues, message[key][i]);
          });
        }
      }
    )();
  };

  const error = (error: string | undefined, touch: boolean | undefined) => {
    return error && touch ? (
      <ErrorMessage message={error} variant={style.passwordError} />
    ) : (
      <></>
    );
  };

  if (showMessage) {
    return (
      <div>
        <p className={style.message}>Пароль успешно изменен</p>
        <a className={style.enterLink} href={"/site/login"}>
          Войти
        </a>
      </div>
    );
  }

  return (
    <>
      <div className={style.formTitle}>
        Пожалуйста, заполните форму для смены пароля:
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.fieldWrapper}>
          <TextField
            {...register("password")}
            size={"small"}
            fullWidth
            id="password"
            name="password"
            label="Пароль"
            type="password"
            autoComplete="new-password"
            error={Boolean(errors.password)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            {...textFieldSX}
          />
          {error(errors.password?.message, Boolean(errors.password))}
        </div>
        <div className={style.fieldWrapper}>
          <TextField
            {...register("password_confirm")}
            size={"small"}
            fullWidth
            id="password_confirm"
            name="password_confirm"
            label="Повторить пароль"
            type="password"
            autoComplete="new-password"
            error={Boolean(errors.password_confirm)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            {...textFieldSX}
          />
          {error(
            errors.password_confirm?.message,
            Boolean(errors.password_confirm)
          )}
        </div>
        {messageError && <div className={style.tokenError}>{messageError}</div>}
        <Button
          theme={theme}
          type="submit"
          color={"blue"}
          className={style.button}
        >
          Сохранить
        </Button>
      </form>
    </>
  );
};
