import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  task: any;
  isMultipart?: boolean;
}>;

export const complete = (builder: QueryBuilder) =>
  builder.mutation<ApiTaskResponses.View, TParams>({
    query: ({ id, task, isMultipart = false }) => ({
      url: `v1/task/complete?id=${id}`,
      method: HTTPMethods.POST,
      data: task,
      isMultipart,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.VIEW,
      ApiTags.ProcessWork.GET_DOCUMENTATIONS,
      ApiTags.ProjectProcess.GET_DOCUMENTATIONS,
      ApiTags.Task.GET_STOPPED,
      ApiTags.ProjectProcess.GET_IN_TASKS,
      ApiTags.ProjectProcess.GET_OUT_TASKS,
      ApiTags.Expertise.GET_REMARKS,
      ApiTags.MaterialProcess.INDEX,
    ],
  });
