import React from "react";
import style from "./style.module.scss";
import EmployeeCard from "../../../newUI/EmployeeCard/EmployeeCard";

interface StaffProps {
  data: any;
  isMobile: boolean;
}

const Appliances: React.FC<StaffProps> = ({ data, isMobile }) => {
  return (
    <div className={style.staffWrapper}>
      {data.length > 0 ? (
        <div className={style.grid}>
          {data.map((item: any) => (
            <EmployeeCard
              multipleSupervisors={false}
              access={{}}
              roles={[]}
              key={item.id}
              data={item}
              isAppliance
              isMobile={isMobile}
            />
          ))}
        </div>
      ) : (
        <div className={style.notFound}>Ничего не найдено</div>
      )}
    </div>
  );
};

export default Appliances;
