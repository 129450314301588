import { Controller, UseFormReturn } from "react-hook-form";
import { Paragraph, TextField } from "src/FSD/shared/uiKit/v2";
import styles from "./styles.module.scss";
import { checkPaymentTerms } from "../lib/helpers/checkPaymentTerms";
import { TGroupPublicationFieldValues } from "../model/types/fieldValues";

type Props = {
  showPay3: boolean;
  form: UseFormReturn<TGroupPublicationFieldValues>;
};

export const PaymentTerms = ({ showPay3, form }: Props) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <div className={styles.paymentTerms__wrapper}>
      <Paragraph color="light">Условия оплаты</Paragraph>
      <Controller
        control={control}
        name={"pay1"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextField
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
                checkPaymentTerms(form);
              }}
              label="Аванс %"
              error={Boolean(error)}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={"pay2"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextField
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
                checkPaymentTerms(form);
              }}
              label="Оплата после приемки %"
              error={Boolean(error)}
              helperText={error?.message}
            />
          );
        }}
      />
      {showPay3 && (
        <Controller
          control={control}
          name={"pay3"}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                value={value}
                onChange={(event) => {
                  onChange(event.target.value);
                  checkPaymentTerms(form);
                }}
                label="Оплата после экспертизы %"
                error={Boolean(error)}
                helperText={error?.message}
              />
            );
          }}
        />
      )}
      <p className={styles.error}>{errors?.paySum?.message}</p>
    </div>
  );
};
