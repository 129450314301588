import React, { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { FirstForm } from "./FirstForm/FirstForm";
import {
  fetchProjectProcessRemoveExecutor,
  fetchProjectProcessRemoveExecutorData,
} from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessRemoveExecutor";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getExecutorProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { getProjectProcessRemoveExecutorDataIsLoad } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessRemoveExecutor";
import { ModalPreloader } from "../../../../newUI/Modal/ModalPreloader";
import { RefundForm } from "./RefundForm/RefundForm";
import { LastForm } from "./LastForm/LastForm";

interface IModalRemoveExecutorProps {
  projectPart: {
    code: string;
    name: string;
    bim: boolean;
  };
  projectName: string;
  closeModal: () => void;
}

export const ModalRemoveExecutor: React.FC<IModalRemoveExecutorProps> = memo(
  ({ projectPart, projectName, closeModal }) => {
    const [currentForm, setCurrentForm] = useState(1);
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const executor = useAppSelector(getExecutorProcessInfo);
    const isLoadRemoveExecutorData = useAppSelector(
      getProjectProcessRemoveExecutorDataIsLoad
    );
    const title = `Запросить снятие исполнителя «${projectPart.code} ${projectPart.name}» по проекту «${projectName}»`;

    useEffect(() => {
      if (processId && executor) {
        dispatch(
          fetchProjectProcessRemoveExecutorData({
            processId,
            executorId: executor.id,
          })
        );
      }
    }, []);

    const handleFormSubmit = async (dataValue: any) => {
      if (dataValue?.status === "end" && processId) {
        await dispatch(
          fetchProjectProcessRemoveExecutor({
            processId,
            data: dataValue.data,
          })
        );
        closeModal();
      }
      if (dataValue.status === "notRefund") {
        setCurrentForm((prevForm) => prevForm + 2);
      } else {
        setCurrentForm((prevForm) => prevForm + 1);
      }
    };

    const handleBackClick = (isRefund: string) => {
      if (isRefund === "refund") {
        setCurrentForm((prevForm) => prevForm - 1);
      } else {
        setCurrentForm((prevForm) => prevForm - 2);
      }
    };

    const renderCurrentForm = () => {
      switch (currentForm) {
        case 1:
          return <FirstForm onSubmit={handleFormSubmit} title={title} />;
        case 2:
          return (
            <RefundForm
              onSubmit={handleFormSubmit}
              title={title}
              handleBackClick={handleBackClick}
            />
          );
        case 3:
          return (
            <LastForm
              title={title}
              handleBackClick={handleBackClick}
              onSubmit={handleFormSubmit}
            />
          );
        default:
          return null;
      }
    };

    if (isLoadRemoveExecutorData) {
      return <ModalPreloader />;
    }

    return <div>{renderCurrentForm()}</div>;
  }
);
