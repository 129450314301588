import { Button, Modal } from "src/FSD/shared/uiKit/v2";
import { ApiProjectAccessResponses } from "src/FSD/entities/projectAccess/model/responseTypes";
import { SetState } from "src/FSD/shared/lib/types/setState";
import cn from "classnames";
import { CommonRow } from "./CommonRow";
import { ProcessesList } from "./ProcessesList";
import styles from "./styles.module.scss";
import { useGroupForm } from "../lib/hooks/useGroupForm";
import { TFormCache } from "../model/types/fieldValues";

export type Props = {
  groupPublishProcess: ApiProjectAccessResponses.GetGroupRequestStartForms;
  currentFormId: string;
  processId: number;
  currentPage: number;
  setCurrentPage: SetState<number>;
  setFormCache: SetState<TFormCache>;
  formCache: TFormCache;
};

export const GroupForm = ({
  groupPublishProcess,
  currentFormId,
  processId,
  currentPage,
  setCurrentPage,
  setFormCache,
  formCache,
}: Props) => {
  const { form, showBackwardButton, onSubmit, handleSubmit, isValid } =
    useGroupForm({
      groupPublishProcess,
      currentFormId,
      processId,
      currentPage,
      setCurrentPage,
      setFormCache,
      formCache,
      type: "request",
    });

  return (
    <div className={cn(styles.wrapper, styles.wrapper_noPayment)}>
      <div className={styles.datesList}>
        <CommonRow form={form} />
        <ProcessesList form={form} processId={processId} />
      </div>
      <Modal.Controls className={styles.requestControls}>
        {showBackwardButton && (
          <Button
            onClick={() => {
              if (isValid) {
                handleSubmit((dataValues) => onSubmit(dataValues, -1))();
              }
            }}
            disabled={!isValid}
            color="secondary"
            type="button"
          >
            Назад
          </Button>
        )}
        <Button
          onClick={() => {
            if (isValid) {
              handleSubmit((dataValues) => onSubmit(dataValues, 1))();
            }
          }}
          disabled={!isValid}
          color="primary"
          type="button"
        >
          Далее
        </Button>
      </Modal.Controls>
    </div>
  );
};
