import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useTaskCompleteMutation,
  useTaskViewQuery,
} from "src/FSD/entities/task/api";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { useModalContext } from "src/FSD/shared/uiKit/v2";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { Nullable } from "src/types/generics";
import { useAppDispatch } from "src/app/store";
import { pushSuccessMessage } from "src/app/feature/errorTrace";
import { requestEquipmentSchema } from "../schemas/RequestEquipment";

export type TFiledValues = {
  price: number;
  dateDeadline: Nullable<Date>;
  comment: string;
  files: TFile[];
  userType: any;
  status: Nullable<Statuses>;
};

type TUseModal = {
  taskId: number;
  defaultValues: TFiledValues;
};

export const useRequestEquipment = ({ taskId, defaultValues }: TUseModal) => {
  const { handleClose } = useModalContext();
  const dispatch = useAppDispatch();

  const { data: taskData, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: [
      "myMaterialProcess",
      "files.svgIcon",
      "outFiles",
      "outFiles.svgIcon",
      "filesSize",
    ],
  });

  const [complete] = useTaskCompleteMutation();

  const form = useForm({
    defaultValues,
    resolver: yupResolver(requestEquipmentSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    if (taskData.workflow_data.price) {
      form.setValue("price", taskData.workflow_data.price);
    }

    if (taskData.comment) {
      form.setValue("comment", taskData.comment);
    }

    if (taskData.date_deadline) {
      form.setValue("dateDeadline", timestampToDate(taskData.date_deadline));
    }
  }, [taskData]);

  const status = form.watch("status");

  const { isSubmitting } = form.formState;

  const isCancelling = isSubmitting && status === Statuses.CANCELLED;
  const isAccepting = isSubmitting && status === Statuses.ACCEPT;

  const onSubmit = async (data: TFiledValues) => {
    const formedData = {
      CompleteForm: {
        status: data.status,
        comment: data.comment,
        dateDeadline: toServerDate(data.dateDeadline),
        userTypeId: data.userType.id,
        price: data.price,
      },
    };

    const result = await complete({ id: taskId, task: formedData });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    isFetching: isFetching || !taskData,
    taskData,
    form,
    onSubmit,
    isCancelling,
    isAccepting,
    isSubmitting,
  } as const;
};
