import { Heading, List, Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./CommentsForm.module.scss";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";

interface TProps {
  comments: ApiTaskResponses.Comment[];
}

export const CommentsList = ({ comments }: TProps) => {
  return (
    <List>
      {comments.length ? (
        comments.map((comment: ApiTaskResponses.Comment) => (
          <div key={comment.id} className={styles.comment}>
            <Heading>{comment.author}</Heading>
            <Heading>{comment.status.value}</Heading>
            <Paragraph>{comment.text}</Paragraph>
            <Paragraph>{comment.date_create}</Paragraph>
          </div>
        ))
      ) : (
        <p className={styles.notFound}>Комментарии не найдены</p>
      )}
    </List>
  );
};
