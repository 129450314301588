import { Controller } from "react-hook-form";
import cn from "classnames";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SelectExecutorTypeMultiple } from "src/FSD/features/v2/SelectExecutorType";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { SelectAgreement } from "src/FSD/features/v2/SelectAgreement";
import { WarningMessagePublication } from "src/FSD/shared/lib/constants/warningComponents/WarningComponents";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { AppointmentExecutor } from "./AppointmentExecutor";
import { AdditionalRequirements } from "./AdditionalRequirements";
import styles from "./PublishObjectProcess.module.scss";
import { MissingAttributesError } from "../../../Request/ui/MissingAttributesError";
import { ErrorTextFiles } from "./ErrorTextFiles";
import { SelectMoneyFromProcess } from "../../../../../../../features/v2/SelectMoneyFromProcess";
import { TPublicationValues } from "../../lib/hooks/usePublishContent";

type Props = {
  control: any;
  register: any;
  publicationValues: TPublicationValues;
  id: number;
  setValue: any;
  errors: any;
  processData: any;
  showErrorFiles: boolean;
  secureMissingAttributes: ApiCommonResponses.SecureMissingAttributes[];
  isProcessWork: boolean;
  textProcess: string;
  type: PublishedObjectControllingEntities;
  isEdit: boolean;
};

export const PublishObjectProcess = ({
  control,
  register,
  publicationValues,
  id,
  setValue,
  errors,
  processData,
  showErrorFiles,
  secureMissingAttributes,
  isProcessWork,
  textProcess,
  type,
  isEdit,
}: Props) => {
  return (
    <div
      className={cn(
        styles.processPublication,
        isEdit && styles.processPublication__height
      )}
    >
      {showErrorFiles && <ErrorTextFiles />}
      <div className={styles.processPublication__block}>
        <div className={styles.processPublication__wrapper}>
          <p className={styles.processPublication__text}>
            Укажите срок и стоимость {textProcess}
          </p>
          <div className={styles.processPublication__container}>
            <Controller
              control={control}
              name="dateStart"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Начальный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateStart}
                  helperText={errors?.dateStart?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="dateLimit"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Конечный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateLimit}
                  helperText={errors?.dateLimit?.message}
                />
              )}
            />
            <TextField
              {...register("price")}
              label="Стоимость"
              disabled={publicationValues.disabled}
              error={!!errors.price}
              helperText={errors?.price?.message}
            />

            {!publicationValues.userTypeToAssign && (
              <Controller
                control={control}
                name="checkNegotiatedPrice"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label="Договорная стоимость"
                    onChange={onChange}
                    value={value}
                    checked={!!value}
                    className={styles.processPublication__treatyPrice}
                    editing
                  />
                )}
              />
            )}
          </div>
        </div>
        {isProcessWork && !isEdit && (
          <div className={styles.processPublication__wrapper}>
            <p className={styles.processPublication__text}>
              Определить источник финансирования
            </p>
            <Controller
              name="workAccount"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectMoneyFromProcess
                  workId={id}
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  error={!!errors.workAccount}
                  helperText={errors?.workAccount?.message}
                  setValue={setValue}
                  processData={processData}
                />
              )}
            />
          </div>
        )}
        {!isEdit && (
          <div className={styles.processPublication__wrapper}>
            <p className={styles.processPublication__text}>Договор</p>
            <div
              className={cn(
                styles.processPublication__container,
                styles.processPublication__selects
              )}
            >
              <Controller
                name="agreementsActs"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectAgreement
                    value={value}
                    label="Договоры и акты"
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    disabled={
                      !publicationValues.canNoSecure ||
                      !!secureMissingAttributes.length
                    }
                  />
                )}
              />
              <Controller
                name="safeTransaction"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectAgreement
                    value={value}
                    label="Безопасная сделка"
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    disabled={
                      !publicationValues.canNoSecure ||
                      !!secureMissingAttributes.length
                    }
                  />
                )}
              />
            </div>
            {!!secureMissingAttributes.length && (
              <MissingAttributesError
                missingAttributes={secureMissingAttributes}
              >
                {WarningMessages.WORKING_ELECTRONIC_CONTACTS_IMPOSSIBLE}
              </MissingAttributesError>
            )}
            {!publicationValues.canNoSecure && <WarningMessagePublication />}
          </div>
        )}
        {publicationValues.showAdditionalRequirements && (
          <AdditionalRequirements
            control={control}
            valuesAdditionalRequirements={publicationValues}
            title={`Дополнительные требования к разработке ${textProcess}`}
            className={styles.processPublication__wrapper}
          />
        )}
        <div className={styles.processPublication__wrapper}>
          <p className={styles.processPublication__text}>
            Требование к исполнителю
          </p>
          <Controller
            name="executorTypes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectExecutorTypeMultiple
                value={value}
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                error={!!errors.executorTypes}
                helperText={errors?.executorTypes?.message}
                setValue={setValue}
                processWork={processData}
              />
            )}
          />
        </div>
      </div>

      {!isEdit && (
        <Button
          type="button"
          color="primary"
          onClick={() =>
            setValue(
              "isAppointmentExecutor",
              !publicationValues.isAppointmentExecutor
            )
          }
        >
          Назначение исполнителя
        </Button>
      )}

      {publicationValues.isAppointmentExecutor && (
        <AppointmentExecutor
          control={control}
          id={id}
          userType={publicationValues.userTypeToAssign}
          executor={publicationValues.userToAssign}
          errors={errors}
          isProcessWork={isProcessWork}
          type={type}
        />
      )}
    </div>
  );
};
