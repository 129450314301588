import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

interface TUsePageProps {
  projectProcessId: number;
}

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProjectProcessViewPage = ({
  projectProcessId,
}: TUsePageProps) => {
  const { data: projectProcessRaw, isLoading } = useProjectProcessViewQuery(
    { id: projectProcessId },
    { skip: !projectProcessId }
  );

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadcrumbs = getBreadcrumbs(projectProcessRaw);

  const isProjectProcessLoading = !projectProcessRaw || isLoading;

  return {
    isProjectProcessLoading,
    breadcrumbs,
    isLoading,
    device,
    projectProcess: projectProcessRaw,
  } as const;
};
