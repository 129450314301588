import React from "react";
import IconsSVG from "../../../../media/icons/icons.svg";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import style from "./style.module.scss";

export type IconsProps = ComponentPropsType<{
  width?: string;
  height?: string;
  size?: string | "your PX size";
  name: string;
  styleVariant?: Record<string, any>;
  active?: boolean;
}>;

export const CustomIcon: React.FC<IconsProps> = ({
  name,
  size,
  width,
  height,
  variant = "",
  styleVariant = {},
  theme = "dark",
  active = false,
}) => {
  return (
    <svg
      className={`icon icon-${name} ${variant} ${style[theme]} ${
        active && style.active
      }`}
      width={width || size}
      height={height || size}
      style={styleVariant}
    >
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`${IconsSVG}#${name}`}
      />
    </svg>
  );
};
