import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiProjectAccessRequests } from "../../model/requestsTypes";

type TParams = RequestParams<{
  processId: number;
  data: ApiProjectAccessRequests.GroupRequest;
}>;

export const groupRequest = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ processId, data }) => ({
      url: `v1/project-access/group-request`,
      params: {
        processId,
      },
      data,
      method: HTTPMethods.POST,
    }),
  });
