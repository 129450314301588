import React, { useState, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, debounce } from "@mui/material";
import cn from "classnames";
import { UseFormSetValue, Path } from "react-hook-form";
import { getOrganizationsOptions } from "../../../../../../../app/feature/profileReducer/thunks";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import style from "./style.module.scss";
import { SubmitDataProps } from "../../Experience";
import { textFieldSX } from "../../../../../../../styles/restyle";

interface Props<TFormValues> {
  name: Path<TFormValues>;
  register: any;
  defaultValue: string | null;
  setValue: UseFormSetValue<SubmitDataProps>;
  hasError: boolean;
  helperText?: string | null;
  disabled?: boolean;
  inputProps?: any;
}

const OrganizationField = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  defaultValue,
  setValue,
  hasError,
  helperText,
  disabled,
  inputProps,
}: Props<TFormValues>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const outsideRef = useRef(null);
  useClickOutside(outsideRef, () => {
    setShow(false);
  });

  const searchHandler = debounce(async (val) => {
    setShow(false);
    setIsLoading(true);
    const response = await getOrganizationsOptions(val);
    setOptions(response);
    setIsLoading(false);
    setShow(true);
  }, 500);

  return (
    <div className={style.fieldContainer}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label="Наименование организации"
        defaultValue={defaultValue}
        autoComplete="off"
        disabled={disabled}
        error={hasError}
        helperText={helperText}
        {...textFieldSX}
        {...register(name)}
        onChange={async (e) => {
          const { value } = e.currentTarget;
          await searchHandler(value);

          if (value.length === 1) {
            setValue("firm_name", value);
          }
        }}
        onFocus={() => {
          setShow(true);
        }}
        InputProps={{
          endAdornment: (
            <div className={style.loaderContainer}>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </div>
          ),
          ...inputProps,
        }}
      />
      {show && !isLoading && (
        <div
          className={cn(style.optionsContainer, {
            [style.noBorder]: options.length === 0,
          })}
          ref={outsideRef}
        >
          {options.map((option: any) => {
            return (
              <button
                className={style.option}
                key={option.inn}
                type="button"
                onClick={() => {
                  setShow(false);
                  setValue("firm_name", option.value);
                  setValue("firm_ogrn", option.ogrn);
                }}
              >
                {`${option.value}, ${option.address}`}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OrganizationField;
