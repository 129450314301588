import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Card, List, Paragraph } from "src/FSD/shared/uiKit/v2";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { DesignTypeValue } from "src/app/feature/ProjectView/thunks";
import { BIMIcon, Icon2D, Icon3D } from "./DesignSVG";
import styles from "./InfoDesign.module.scss";

type DesignTypeIcon = {
  [key in DesignTypeValue]: React.ReactNode;
};

export const InfoDesign = () => {
  const { project } = useSelector(getProjectViewData);

  if (project) {
    const onPause = project.statusKey === 15;
    const designTypeIcon: DesignTypeIcon = {
      "2D": <Icon2D />,
      "3D": <Icon3D />,
      BIM: <BIMIcon />,
    };

    const datesRange = `${
      project.dateStart
    } - ${project.dateEnd || "Не указано"}`;

    return (
      <Card
        className={classNames(
          styles.project,
          styles.basic,
          onPause && styles.pause
        )}
        header={
          <Card.Header className={classNames(onPause && styles.pause_header)}>
            Проектирование
          </Card.Header>
        }
      >
        <List margin={0} gap={4} disableOverflow>
          <Paragraph>Сроки:</Paragraph>
          {project.dateStart && (
            <Paragraph color="light">{datesRange}</Paragraph>
          )}
        </List>
        <List margin={20} gap={4} disableOverflow>
          <Paragraph>Экспертизы и согласования:</Paragraph>
          {project.expertiseType.map((expertise) => (
            <Paragraph key={expertise.num} color="light">
              {expertise.title}
            </Paragraph>
          ))}
        </List>
        <div className={classNames(styles.icon, onPause && styles.iconPause)}>
          {designTypeIcon?.[project?.designType]}
        </div>
        <div
          className={classNames(
            styles.design_type,
            onPause && styles.pauseDesign
          )}
        >
          {project.designType}
        </div>
      </Card>
    );
  }
  return <div></div>;
};
