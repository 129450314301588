import { useMemo, useState } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { FormSteps } from "src/FSD/shared/uiKit/v2/FormSteps";
import { useGroupProcessPublication } from "../../lib/hooks/useGroupProcessPublication";
import { GroupFormWithPayment } from "../GroupFormWithPayment";
import { createFormSteps } from "../../lib/helpers/createFormSteps";
import { PublicationForm } from "./PublicationForm";
import { TFormCache } from "../../model/types/fieldValues";
import { PROCESSES_PUBLICATION } from "../../model/constants/formNames";

type Props = {
  processId: number;
  groupName: string;
};

export const GroupPublicationForm = ({ processId, groupName }: Props) => {
  const [formCache, setFormCache] = useState<TFormCache>({});
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { groupPublishProcess, isProcessesLoading } =
    useGroupProcessPublication({
      processId,
    });

  const formSteps = useMemo(() => {
    if (!groupPublishProcess) {
      return;
    }

    const groupFormSteps = createFormSteps(groupPublishProcess);
    return { ...groupFormSteps, publication: PROCESSES_PUBLICATION };
  }, [groupPublishProcess]);

  const currentFormId = formSteps ? Object.keys(formSteps)[currentPage] : null;

  return (
    <Modal.Layout modalSize="xl">
      {isProcessesLoading ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Header>
            Групповая публикация разделов «{groupName}»
          </Modal.Header>
          <FormSteps steps={formSteps!} currentStep={currentFormId!} />
          {currentFormId === "publication" ? (
            <PublicationForm
              processId={processId}
              setCurrentPage={setCurrentPage}
              formCache={formCache}
              setFormCache={setFormCache}
            />
          ) : (
            <GroupFormWithPayment
              key={currentFormId!}
              groupPublishProcess={groupPublishProcess!}
              currentFormId={currentFormId!}
              processId={processId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              formCache={formCache}
              setFormCache={setFormCache}
            />
          )}
        </>
      )}
    </Modal.Layout>
  );
};
