import React from "react";
import { config } from "src/app.cofig";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";

interface TProps {
  canBimitUser?: boolean;
  cloudLink?: string;
}

export const CloudLink = ({ canBimitUser, cloudLink }: TProps) => {
  if (!cloudLink) {
    return null;
  }

  const link = () => {
    window.open(`${config.localDomain}${cloudLink}`, "_blank");
  };

  const handleSwitchingToBimit = () => {
    if (canBimitUser) {
      link();
      return;
    }

    if (!window.confirm("Необходимо привязать учётную запись BIMIT")) {
      return;
    }
    link();
  };

  return (
    <IconButton
      sx={{ paddingTop: 0, paddingBottom: 0, ...iconBtnSx }}
      onClick={handleSwitchingToBimit}
    >
      <i
        className={`bi ${canBimitUser ? "bi-cloud-fill" : "bi-cloud-slash-fill"}`}
      />
    </IconButton>
  );
};
