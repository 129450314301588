import moment from "moment";
import { useContext } from "react";
import { ApplicationsContext } from "../../ui/Applications";
import { getTotalSum } from "../helpers/getTotalSum";
import { trimPrice } from "../../../../../../../utils/trimNumber";

type TUseProcessCheckbox = {
  taskId: number;
  dateStart: string;
  dateLimit: string;
  price: number;
  field: Record<string, number>;
};

export const useProcessCheckbox = ({
  taskId,
  dateStart,
  dateLimit,
  price,
  field,
}: TUseProcessCheckbox) => {
  const {
    setTotalPrice,
    groupId,
    applications,
    form,
    bankErrorTaskIds,
    setBankErrorTaskIds,
    account,
  } = useContext(ApplicationsContext);

  const { getValues, setValue } = form;

  const { processTypeId, userTypeId, processId } = field;

  const formattedDateStart = moment(dateStart).format("DD.MM.YYYY");

  const formattedDateLimit = moment(dateLimit).format("DD.MM.YYYY");

  const formattedPrice = trimPrice(price);

  const handleToggleProcess = (
    value: boolean,
    onChange: (newValue: boolean) => void
  ) => {
    let bankErrorTaskIdsDerrived = [...bankErrorTaskIds];

    if (value) {
      const userTypesIdsByProcess = getValues(`${processTypeId}.${processId}`);

      const taskIds: number[] = [];

      // сбрасываем значения на всем ряду
      Object.keys(userTypesIdsByProcess).forEach((userTypeId) => {
        const taskId =
          applications[groupId!].userTypes[userTypeId].appProcesses[processId]
            .task.id;

        setValue(`${processTypeId}.${processId}.${userTypeId}`, false);

        taskIds.push(taskId);
      });

      // очищаем банковскую ошибку в ряду
      bankErrorTaskIdsDerrived = bankErrorTaskIdsDerrived.filter(
        (id) => !taskIds.includes(id)
      );
    }

    onChange(value);

    const newTotalPrice = getTotalSum(groupId!, applications, form);
    setTotalPrice(newTotalPrice);

    if (newTotalPrice > account.value) {
      // проставляем банковскую ошибку только в тот раздел, где необходимо
      if (value) {
        setBankErrorTaskIds([...bankErrorTaskIdsDerrived, taskId]);
      } else {
        setBankErrorTaskIds(
          bankErrorTaskIdsDerrived.filter((id) => id !== taskId)
        );
      }
    } else {
      setBankErrorTaskIds([]);
    }
  };

  console.log({ bankErrorTaskIds });

  return {
    processTypeId,
    processId,
    userTypeId,
    handleToggleProcess,
    bankErrorTaskIds,
    formattedPrice,
    formattedDateStart,
    formattedDateLimit,
  } as const;
};
