import { FC } from "react";
import cn from "classnames";
import CustomButton from "../../../../../../newUI/CustomButton/CustomButton";
import style from "./style.module.scss";
import { palette } from "../../../../../../../styles/restyle";
import { TextLink } from "../../../../../../UI/components/TextLink/TextLink";
import { config } from "../../../../../../../app.cofig";

interface SeismicityInfoProps {
  seismicity: { a: string; b: string; c: string } | null;
  location: string;
  region: string;
  handleCloseModal: () => void;
}

const SeismicityInfo: FC<SeismicityInfoProps> = ({
  seismicity,
  region,
  location,
  handleCloseModal,
}) => {
  const containsSeismicity = Boolean(
    seismicity?.a || seismicity?.b || seismicity?.c
  );

  const containsAstrisk =
    seismicity?.a?.includes("*") ||
    seismicity?.b?.includes("*") ||
    seismicity?.c?.includes("*");

  const SeismicityData = () => (
    <>
      <span>карта&nbsp;</span>
      <span className={style.big}>А -&nbsp;</span>
      <span className={cn(style.white, style.big)}>{seismicity?.a}</span>
      <span>&nbsp;бал., карта&nbsp;</span>
      <span className={style.big}>В -&nbsp;</span>
      <span className={cn(style.white, style.big)}>{seismicity?.b}</span>
      <span>&nbsp;бал., карта&nbsp;</span>
      <span className={style.big}>С -&nbsp;</span>
      <span className={cn(style.white, style.big)}>{seismicity?.c}</span>
      <span>&nbsp;бал.</span>
    </>
  );

  return (
    <div className={style.wrapper}>
      <h2>Сейсмичность по данным ОСР-2016</h2>
      <div>
        <span>Регион: {region} -&nbsp;</span>
        <span className={style.white}>сейсмоактивный</span>
      </div>
      <div className={style.marginTop}>
        <span>Населенный пункт:&nbsp;</span>
        <span className={style.white}>{location}</span>
      </div>
      <div>
        <span>Сейсмическая интенсивность:&nbsp;</span>
        {containsSeismicity ? (
          <SeismicityData />
        ) : (
          <span>
            <TextLink
              target="_blank"
              redirect
              url={`${config.localDomain}/files/Карты_OCP_2016_А_В_С.pdf`}
              variant={style.link}
            >
              Уточнить по картам
            </TextLink>
          </span>
        )}
      </div>
      {containsAstrisk && (
        <p className={style.marginTop}>
          * Фактическая балльность очередного по времени землетрясения превышает
          некоторый заранее заданный уровень интенсивности для данной территории
        </p>
      )}
      <p className={style.marginTop}>
        Данные указаны согласно списку населенных пунктов Российской Федерации,
        расположенных в сейсмоактивных зонах, характеризуемых сейсмической
        интенсивностью 6 и более баллов по шкале MSK-64 для средних грунтовых
        условий и трёх уровней сейсмической опасности (вероятностей превышения
        расчётной интенсивности в течение 50 лет, равных 10, 5, 1% для карт
        ОСР-2016-А, ОСР-2016-В, ОСР-2016-С)
      </p>
      <CustomButton
        onClick={handleCloseModal}
        className={style.okButton}
        background={palette.blue}
        width={80}
      >
        Ок
      </CustomButton>
    </div>
  );
};

export default SeismicityInfo;
