import { useEffect } from "react";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useAppDispatch } from "src/app/store";

const CHECK_OUTPUT_TASK_CONFIRM = "Вы уверены, что хотите принять задание?";

type CheckOutputTaskProps = {
  taskId: number;
  handleCloseModal: () => void;
};

export const CheckOutputTask = ({
  taskId,
  handleCloseModal,
}: CheckOutputTaskProps) => {
  const dispatch = useAppDispatch();

  const acceptHandler = () => {
    const result = window.confirm(CHECK_OUTPUT_TASK_CONFIRM);

    if (result) {
      dispatch(
        TaskApi.complete({
          data: {
            id: taskId,
            formedData: {
              status: Statuses.ACCEPT,
            },
          },
          events: {},
        })
      );
    }

    handleCloseModal();
  };

  useEffect(() => {
    acceptHandler();
  }, []);

  return null;
};
