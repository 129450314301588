import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";

export const getProcessesByProcessTypeId = (
  applicationsGroup: ApiProjectResponses.GetApplications[0],
  selectedUserTypeIds: number[],
  processTypeId: number
) => {
  return Object.entries(
    applicationsGroup.userTypes[selectedUserTypeIds[0]].appProcesses
  )
    .filter(
      ([_, appProcess]) =>
        appProcess.process.projectPart.type.key === processTypeId
    )
    .map(([id, appProcess]) => ({
      processId: Number(id),
      title: appProcess.process.title,
    }));
};
