import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "src/app/services/api/requestHandler";
import {
  ApiGetUserResponsibilityTypes,
  ApiGetCounters,
  ApiGetAccounts,
  ApiGetPirsSub,
} from "src/app/services/api/user/user";
import { ApiTaskComplete } from "src/app/services/api/task/task";
import { getUserId } from "src/app/services/auth/auth";
import { pushError } from "../../errorTrace";

export const getUserTypes = createAsyncThunk(
  "dashboard/getTypes",
  async (_, { dispatch }) => {
    const userId = getUserId();

    let response: any = [];
    await request(
      ApiGetUserResponsibilityTypes(userId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const getCounters = createAsyncThunk(
  "dashboard/getCounters",
  async (_, { dispatch }) => {
    const userId = getUserId();
    let response: any = {};
    await request(
      ApiGetCounters(userId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const getAccounts = createAsyncThunk(
  "dashboard/getAccounts",
  async (userType: number, { dispatch }) => {
    let response: any = [];
    await request(
      ApiGetAccounts(userType),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const getSubscription = createAsyncThunk(
  "dashboard/getSubscription",
  async (_, { dispatch }) => {
    const userId = getUserId();
    let response: any = {};
    await request(
      ApiGetPirsSub(userId),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();

    return response;
  }
);

export const completeTask = createAsyncThunk(
  "project/admin/completeTask",
  async (
    { id, data }: { id: number; data: Record<string, any> },
    { dispatch, rejectWithValue }
  ) => {
    let error = null;
    let response: unknown;

    await request(
      ApiTaskComplete(id, data),
      () => {
        response = data;
      },
      () => (err) => {
        error = err;
        dispatch(pushError(err));
      }
    )();

    if (error && !response) {
      return rejectWithValue(error);
    }

    return response;
  }
);
