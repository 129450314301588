import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Executors.module.scss";
import { trimPrice } from "../../../../../../../utils/trimNumber";

type TProps = {
  price: number;
  ndsText: string;
  dateStart: string;
  dateLimit: string;
};

export const PaymentAndDeadlines = ({
  price,
  ndsText,
  dateStart,
  dateLimit,
}: TProps) => {
  const formattedPrice = trimPrice(price);

  return (
    <div>
      <Paragraph color="light" className={styles.groupApplicationsTable__price}>
        {formattedPrice} ₽<span>&nbsp;&nbsp;{ndsText}</span>
      </Paragraph>
      <Paragraph>
        {dateStart}-{dateLimit}
      </Paragraph>
    </div>
  );
};
