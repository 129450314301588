import React, { FC, useState } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import cn from "classnames";
import style from "./style.module.scss";
import DashboardSkeleton from "../Skeleton";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getDashboardDestinations,
  setStaffId,
  setTab,
} from "../../../../../app/feature/dashboard/destinations";
import { getDashboardStaff } from "../../../../../app/feature/dashboard/staff/index";
import { useStaff } from "../../../Organisation/tabs/Staff";
import FolderPanel from "../Secondary/Sections/FolderPanel";
import { TeamSVG } from "../../../../newUI/SVG";
import SearchField from "../SearchField";
import useFilter from "../Filter/useFilter";
import FilterStaff from "../Filter/Staff";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface StaffFolderProps {
  name: string;
  staff: any;
  ticked: number[];
  isMobile: boolean;
}

interface StaffDetailsProps {
  staff: any;
}

const comparator = (staffA: any, staffB: any) => {
  if (!staffA.boss && staffB.boss) {
    return 1;
  }

  if (staffA.boss && !staffB.boss) {
    return -1;
  }

  return 0;
};

const StaffDetails: FC<StaffDetailsProps> = ({ staff }) => {
  if (staff.role_id === 7) {
    return (
      <div className={style.specialties}>
        {staff.skills.length > 0 &&
          staff.skills.map(({ short_name }: any) => {
            return <h4>{short_name}</h4>;
          })}
      </div>
    );
  }

  if (staff.role_id === 12) {
    return (
      <div className={style.teams}>
        {staff.teams.length > 0 &&
          staff.teams.map((team: any) => {
            const groupName =
              team.part_group_id?.[Object.keys(team.part_group_id)[0]];

            const count = team.members.length;

            return (
              <div className={style.team}>
                <h4>{groupName}</h4>
                <TeamSVG />
                <p>{count}</p>
              </div>
            );
          })}
      </div>
    );
  }

  return null;
};

const StaffFolder: FC<StaffFolderProps> = ({
  name,
  staff,
  ticked,
  isMobile,
}) => {
  const dispatch = useAppDispatch();
  const { staffId } = useAppSelector(getDashboardDestinations);
  const [isOpen, setOpen] = useState<boolean>(true);

  const staffPeople = staff[name]
    .sort(comparator)
    .filter((staff: any) => ticked.includes(staff.id))
    .map((staff: any) => {
      const handleChangeStaffId = () => {
        dispatch(setStaffId(staff.id));

        if (isMobile) {
          dispatch(setTab("groups"));
        }
      };

      const isSelected = staff.id === staffId;

      return (
        <div
          onClick={handleChangeStaffId}
          className={cn(style.staffItem, isSelected && style.selected)}
        >
          <p>{staff.name}</p>
          {staff.role_id === 3 && (
            <p className={style.staffBoss}>{staff.boss}</p>
          )}
          <StaffDetails staff={staff} />
          {staff.firmAttorney && staff.role_id === 3 && (
            <div className={style.firmAttorney}>
              <p>Доверенность</p>
              <h4>
                <p>{staff.firmAttorney.date_start}</p>
                {!isMobile && <>&nbsp;&nbsp;•&nbsp;&nbsp;</>}
                <p>{staff.firmAttorney.date_end}</p>
              </h4>
            </div>
          )}
        </div>
      );
    });

  if (!staffPeople.length) {
    return null;
  }

  return (
    <>
      <FolderPanel
        name={`${name} (${staff[name].length})`}
        canOpen={Boolean(staff[name].length)}
        short
        isOpen={isOpen}
        handleShowSubtree={() => setOpen(!isOpen)}
      />
      {isOpen && staffPeople}
    </>
  );
};

const StaffList: FC = () => {
  const isMobile = useIsMobile(1100);

  const { data, pending } = useAppSelector(getDashboardStaff);
  const { fullOrderedList, staff } = useStaff(data.staff);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [query, setQuery] = useState<string>("");

  const { ticked, handleChange } = useFilter(
    data.staff,
    query,
    true,
    FilterStaff,
    setQuery
  );

  const staffElements = fullOrderedList.map((key: string) => {
    if (!staff[key]) {
      return null;
    }

    return (
      <StaffFolder
        isMobile={isMobile}
        name={key}
        staff={staff}
        ticked={ticked}
      />
    );
  });

  return (
    <div className={cn(style.wrapper, style.projectsPane)}>
      <div className={cn(style.header, style.staffMobileHeader)}>
        <h3>Сотрудники</h3>
        {isMobile ? (
          <IconButton
            className={style.mobileSearchBar}
            onClick={() => setShowSearchBar(!showSearchBar)}
          >
            <Search style={{ color: "#9DAFBD" }} />
          </IconButton>
        ) : (
          <SearchField
            value={query}
            onChange={handleChange}
            className={style.searchBar}
          />
        )}
      </div>
      {showSearchBar && isMobile && (
        <SearchField
          value={query}
          onChange={handleChange}
          className={style.searchBar}
          mobile
        />
      )}
      {pending.staff ? (
        <DashboardSkeleton count={8} />
      ) : (
        <div className={style.list}>{staffElements}</div>
      )}
    </div>
  );
};
export default StaffList;
