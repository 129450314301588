import cn from "classnames";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./Tasks.module.scss";
import { taskStatuses } from "../../lib/hooks/useTask";

type TProps = {
  task: ApiTaskResponses.View;
};

export const Status = ({ task }: TProps) => {
  const color = taskStatuses[task.status.key];

  return (
    <div
      className={cn(styles.tasksBlock__content, styles.tasksBlock__basisStatus)}
    >
      <Paragraph>Статус:</Paragraph>
      <Paragraph fontSize={18} fontWeight="medium" color={color!}>
        {task.status.value}
      </Paragraph>
    </div>
  );
};
