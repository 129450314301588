import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";

export const selectAllItem = { id: "add-all", title: "Выбрать все" };

export const getCompleteOptions = (
  value: TSelectItem[],
  options: TSelectItem[],
  showSelectAll: boolean
) => {
  const ids = new Set(value.map((item) => item.id));

  const customOptions = [...options].filter(
    (option: TSelectItem) => !ids.has(option.id)
  );

  if (!showSelectAll) {
    return customOptions;
  }

  return value.length === options.length
    ? []
    : [selectAllItem, ...customOptions];
};

export const getNewValue = (
  options: TSelectItem[],
  newValue: TSelectItem[]
) => {
  const containsAll = newValue.find((item) => item.id === selectAllItem.id);

  if (containsAll) {
    return options;
  }

  return newValue;
};

export const listItemSX = { color: "black", fontWeight: "bold" };

export const getNewLightValue = (
  options: TSelectItem[],
  newValue: TSelectItem[]
) => {
  const containsAll = newValue.find((item) => item.id === selectAllItem.id);

  if (containsAll) {
    return options;
  }

  const valueIds = newValue.map((item) => item.id);

  return newValue.filter((item) => {
    return valueIds.filter((id) => id === item.id).length <= 1;
  });
};
