import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";

import {
  fetchParentCheckList,
  fetchProjectSourceDataView,
  fetchProjectViewInitialData,
  fetchProjectViewInitialDataUpdate,
  fetchTaskDataUpdate,
  CheckListResult,
  InitialDataInterfaceType,
  ProjectSourceData,
} from "./thunks";

interface InitialState {
  isLoad: boolean;
  isLoadBody: boolean;
  initialDataInterface: InitialDataInterfaceType[] | null;
  initialDataCounters: {
    allSourceData: number;
    overdueSourceData: number;
    receivedSourceData: number;
  } | null;
  error: any;
  projectSourceData: ProjectSourceData[][];
  isLoadSourceData: boolean;
  checkListParent: CheckListResult[];
  isLoadCheckList: boolean;
  updateInitialData: boolean;
  isLoadDateUpdate: boolean;
  searchValue: string;
  isSearched: boolean;
}

const initialState: InitialState = {
  isLoad: false,
  isLoadBody: false,
  error: null,
  initialDataInterface: null,
  initialDataCounters: null,
  projectSourceData: [],
  isLoadSourceData: false,
  checkListParent: [],
  isLoadCheckList: false,
  updateInitialData: false,
  isLoadDateUpdate: false,
  searchValue: "",
  isSearched: false,
};

const projectInitialDataSlice = createSlice({
  name: "projectGraphData",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setIsSearched: (state, action) => {
      state.isSearched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectViewInitialData.pending, (state, action) => {
      const { load } = action.meta.arg;
      if (load) {
        state.isLoadBody = true;
      } else {
        state.isLoad = true;
      }
    });
    builder.addCase(fetchProjectViewInitialData.fulfilled, (state, action) => {
      // Исходные данные
      state.initialDataCounters = {
        allSourceData: action.payload.allSourceData,
        overdueSourceData: action.payload.overdueSourceData,
        receivedSourceData: action.payload.receivedSourceData,
      };
      state.initialDataInterface = action.payload.data;
      state.projectSourceData = new Array(action.payload.data.length);
      state.checkListParent = new Array(action.payload.data.length);
      state.updateInitialData = false;
      state.isLoad = false;
      state.isLoadBody = false;
    });
    builder.addCase(fetchProjectViewInitialData.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
      state.isLoadBody = false;
    });
    builder.addCase(fetchProjectSourceDataView.pending, (state) => {
      state.isLoadSourceData = true;
    });
    builder.addCase(fetchProjectSourceDataView.fulfilled, (state, action) => {
      const newArr = [...state.projectSourceData];
      newArr.splice(action.payload.index, 1, action.payload.item);
      state.projectSourceData = newArr;
      state.isLoadSourceData = false;
    });
    builder.addCase(fetchProjectSourceDataView.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoadSourceData = false;
    });
    builder.addCase(fetchParentCheckList.pending, (state) => {
      state.isLoadCheckList = true;
    });
    builder.addCase(fetchParentCheckList.fulfilled, (state, action) => {
      const newArr = [...state.checkListParent];
      newArr.splice(action.payload.index, 1, action.payload.item);
      state.checkListParent = newArr;
      state.isLoadCheckList = false;
    });
    builder.addCase(fetchParentCheckList.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoad = false;
      state.isLoadCheckList = false;
    });
    builder.addCase(fetchProjectViewInitialDataUpdate.pending, (state) => {
      state.isLoad = true;
      state.updateInitialData = false;
    });
    builder.addCase(fetchProjectViewInitialDataUpdate.fulfilled, (state) => {
      state.updateInitialData = true;
      state.isLoad = false;
    });
    builder.addCase(
      fetchProjectViewInitialDataUpdate.rejected,
      (state, action) => {
        state.error = action.payload;
        state.isLoad = false;
      }
    );
    builder.addCase(fetchTaskDataUpdate.pending, (state) => {
      state.isLoadDateUpdate = true;
    });
    builder.addCase(fetchTaskDataUpdate.fulfilled, (state) => {
      state.isLoadDateUpdate = false;
    });
    builder.addCase(fetchTaskDataUpdate.rejected, (state, action) => {
      state.isLoadDateUpdate = false;
      state.error = action.payload;
    });
  },
});
export const getProjectInitialData = (state: RootState) =>
  state.projectInitialData;
export const { setSearchValue, setIsSearched } =
  projectInitialDataSlice.actions;
export default projectInitialDataSlice.reducer;
