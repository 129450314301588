import { memo } from "react";
import { PeopleFill, CheckLg, X } from "react-bootstrap-icons";
import cn from "classnames";
import { KanbanType } from "src/FSD/entities/kanban";
import styles from "./Column.module.scss";
import { Dot } from "src/components/newUI/Dot/Dot";
import { palette } from "src/styles/restyle";
import { useColumnTask } from "../lib/hooks/useColumnTask";

export type ColumnTaskProps = KanbanType.Task & {
  position: string;
};

const ColumnTask = memo((kanbanTaskExtended: ColumnTaskProps) => {
  const {
    backgroundColor,
    isFreeTask,
    isDoneColumn,
    showCheckIcon,
    showGreenCheck,
    showRedCross,
    code,
  } = useColumnTask(kanbanTaskExtended);

  const { id, isImportant, executor, partGroup, isStop, process, date, name } =
    kanbanTaskExtended;

  return (
    <div id={`task-anchor-${id}`} className={styles.taskWrapper}>
      {isImportant && (
        <div style={{ backgroundColor }} className={styles.taskMark}>
          Важно!
        </div>
      )}
      <div
        className={cn(styles.task, isImportant && styles.task_hasMark)}
        style={{ borderColor: partGroup.color }}
      >
        <p
          className={styles.task__groupName}
          style={{ backgroundColor: partGroup.color }}
        >
          {partGroup.code}
        </p>
        <div className={styles.task__data}>
          {showCheckIcon && (
            <div
              className={cn(
                styles.checkIcon,
                showGreenCheck && styles.checkIcon_checked,
                showRedCross && styles.checkIcon_unchecked
              )}
            >
              {showRedCross && <X />}
              {showGreenCheck && <CheckLg />}
            </div>
          )}
          {Boolean(process.name) && (
            <div className={styles.task__data__process}>
              <p>{process.name}</p>
              {isStop && <Dot status={"error"} size={12} />}
            </div>
          )}
          {isFreeTask && (
            <div className={styles.task__data__process}>
              <p>Оперативная задача</p>
            </div>
          )}
          <div className={styles.task__data__executor}>
            <p>{executor.fullName}</p>
            {executor.isDelegate && <PeopleFill color={palette.green} />}
          </div>
          <p className={styles.task__data__taskName}>{name}</p>
          <div className={styles.task__data__dateAndCode}>
            <p className={styles.task__data__code}>{code}</p>
            <p
              className={cn(
                styles.task__data__date,
                isDoneColumn
                  ? null
                  : (date.isExpiring && styles.task__data__date_isExpiring,
                    date.isExpired && styles.task__data__date_expired)
              )}
            >
              {date.value}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ColumnTask;
