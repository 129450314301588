export enum ErrorMessage {
  PRICE_1000 = "Стоимость не может быть меньше 1000 рублей",
  PRICE_IS_INT = "Стоимость должна быть целым числом",
  DATE_RANGE = "Начальный срок должен быть раньше конечного",
  DATE_START_REQUIRED = "Укажите начальный срок",
  DATE_LIMIT_REQUIRED = "Укажите конечный срок",
  MIN_DATE_RANGE_8_DAYS = "Продолжительность работ должна быть более 8 дней",
  DATE_IS_NEXT = "Дата должна быть будущей",
  FIELD_REQUIRED = "Поле обязательно",
  MUST_BE_INT = "Значение должно быть числом",
  MIN_20_PERCENT = "Минимальное значение - 20%",
  MAX_100_PERCENT = "Максимальное значение - 100%",
  SUM_100_PERCENT = "Сумма должна быть равна 100%",
}
