import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessExpertise } from "src/app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import {
  getProjectProcessExpertise,
  getProjectProcessExpertiseIsLoad,
} from "src/app/feature/ProjectProcessView/Expertise/selectors/getProjectProcessExpertise";
import { ExpertiseProjectProcess } from "src/components/UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import cls from "./ExpertiseInTasks.module.scss";
import { ExpertiseCard } from "./ExpertiseCard/ExpertiseCard";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";

interface IProps {
  projectName: string;
}

export const ExpertiseInTasks = memo(({ projectName }: IProps) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const infoId = useAppSelector(getProjectProcessInfoId);
  const isLoadExpertise = useAppSelector(getProjectProcessExpertiseIsLoad);
  const expertise = useAppSelector(getProjectProcessExpertise);
  const { name, handleCloseModal, restData } = useModal();

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessExpertise(infoId));
    }
  }, [infoId]);

  if (isLoadExpertise) {
    return <ExpertiseProjectProcess />;
  }

  return (
    <div className={cls.wrapper}>
      {expertise?.map((item, index) => (
        <ExpertiseCard
          key={item.processName}
          name={item.processName}
          remarks={item.remarks}
          dot={item.dot}
          index={index + 1}
        />
      ))}

      <Modal
        isActive={name === ModalNames.ProjectProcess.TASK_COMPLETE}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.TaskComplete
          row={restData.row}
          task={restData.task}
          expertiseName={restData.expertiseName}
          mode={restData.mode}
          files={restData.files}
          projectName={projectName}
        />
      </Modal>
    </div>
  );
});
