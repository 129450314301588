import {
  useMaterialProcessGetProviderFirmsQuery,
  useMaterialProcessIndexQuery,
} from "src/FSD/entities/materialProcess/api";
import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";

type TUseModal = {
  processId: number;
};

export const useMaterials = ({ processId }: TUseModal) => {
  const { data: materials, isLoading: isLoadingMaterials } =
    useMaterialProcessIndexQuery({
      processId,
      expand: [
        "countTasks",
        "countKP",
        "tasks",
        "can",
        "files.svgIcon",
        "filesSize",
      ],
    });

  const { data: providerFirms, isLoading: isLoadingProviderFirms } =
    useMaterialProcessGetProviderFirmsQuery({
      processId,
      expand: ["questionnaires"],
    });

  const { data: dataProjectProcess, isLoading: isLoadingProjectProcess } =
    useProjectProcessViewQuery({
      id: processId,
    });

  return {
    materials,
    providerFirms,
    isFetching:
      isLoadingMaterials ||
      isLoadingProviderFirms ||
      isLoadingProjectProcess ||
      !providerFirms ||
      !materials ||
      !dataProjectProcess,
    dataProjectProcess,
  } as const;
};
