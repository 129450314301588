import { useTaskCompleteMutation } from "src/FSD/entities/task/api";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { ApiPublishedObjectResponse } from "src/FSD/entities/publishedObject/model/responseTypes";
import { getUserId } from "../../../../../../../app/services/auth/auth";
import { useTabsPanelContext } from "../../../../TabsPanel";
import { trimNumber } from "../../../../../../../utils/trimNumber";

export const useApplication = (
  application: ApiPublishedObjectResponse.ApplicationType
) => {
  const { setCurrentTab } = useTabsPanelContext();

  const isMobile = useResolution(1200);

  const userId = getUserId();

  const fullName = formFullUserName(application.task.author);

  const jobDuration = (
    (application.task.author.job_duration ?? 0) / 365
  ).toFixed(0);

  const ndsText = getNdsText(Boolean(application.executor_nds));

  const comment = `Комментарий: ${application.task.content || "-"}`;

  const { name, code } = application.projectPart;

  const nameProjectProcess = `«${name}${code ? ` (${code})` : ""}»`;

  const headerUpdate = `Редактирование заявки на исполнение задачи «${application.work.title}» по разделу ${nameProjectProcess}`;
  const headerRemove = `Удаление заявки на исполнение задачи «${application.work.title}» по разделу ${nameProjectProcess}`;

  const [taskComplete, { isLoading: isLoadingTaskComplete }] =
    useTaskCompleteMutation();

  const color =
    !application.buttons["select-executor"] || isLoadingTaskComplete
      ? "secondary"
      : "primary";

  const disabled =
    !application.buttons["select-executor"] || isLoadingTaskComplete;

  const price = trimNumber(application.task.workflow_data.price);

  const fetchTaskComplete = async (id: number) => {
    if (!window.confirm("Вы уверены, что хотите нанять исполнителя?")) {
      return;
    }

    const formedData = {
      CompleteForm: {
        status: Statuses.ACCEPT,
      },
    };

    await taskComplete({ id, task: formedData })
      .unwrap()
      .then(() => {
        setCurrentTab("info");
      });
  };

  return {
    isMobile,
    userId,
    fullName,
    jobDuration,
    ndsText,
    comment,
    headerUpdate,
    headerRemove,
    fetchTaskComplete,
    isLoadingTaskComplete,
    color,
    disabled,
    price,
  } as const;
};
