import { ApiProjectAccessRequests } from "src/FSD/entities/projectAccess/model/requestsTypes";
import {
  TFormCache,
  TGroupRequestFieldValues,
  TRequestFieldValues,
} from "../../model/types/fieldValues";
import { createCurrentGroupProcesses } from "./createCurrentGroupGroupProcesses";

type TRequestGroupType = {
  objectId: number[];
};

export const createRequestFormData = (
  publicationFormValues: TRequestFieldValues,
  formCache: TFormCache
) => {
  const groupFormValues = structuredClone(formCache);
  delete groupFormValues.publication;

  const types: Record<string, TRequestGroupType> = {};

  const { executorType, bankAccount, content } = publicationFormValues;

  const processes = Object.keys(groupFormValues).reduce(
    (groupProcesses, groupId) => {
      const { processes } = groupFormValues[
        groupId
      ] as TGroupRequestFieldValues;

      types[`type${groupId}`] = {
        objectId: processes
          .filter(({ isActive }) => isActive)
          .map(({ processId }) => processId),
      };

      const currentGroupProcesses = createCurrentGroupProcesses(processes);

      return { ...groupProcesses, ...currentGroupProcesses };
    },
    {}
  );

  const formedData: ApiProjectAccessRequests.GroupRequest = {
    StartForm: {
      ...types,
      ...processes,
      executor_type: Number(executorType!.id),
      bank_account_id: Number(bankAccount!.id),
      content,
    },
  };

  return formedData;
};

export type TGroupRequestSubmitData = ReturnType<typeof createRequestFormData>;
