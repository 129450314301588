import { ApiTags } from "src/FSD/shared/api/tags";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";

type TParams = {
  processId: number;
};

export const getGroupRequestStartForms = (builder: QueryBuilder) =>
  builder.query<void, TParams>({
    query: ({ processId }) => ({
      url: `v1/project-access/get-group-request-start-forms`,
      params: {
        processId,
      },
    }),
    providesTags: [ApiTags.ProjectAccess.GET_GROUP_REQUEST_START_FORMS],
  });
