import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import { useState } from "react";
import { useProjectCheckListListByProjectWithCountQuery } from "src/FSD/entities/projectChecklist/api";

const tabMapping: Record<string, number> = {
  common: 4,
  otr: 1,
  project: 2,
  work: 3,
};

type TProps = {
  id: number;
};

export const useSourceData = ({ id }: TProps) => {
  const { currentChildTab } = useTabsPanelContext();
  const [searchValue, setSearchValue] = useState("");
  const type = tabMapping[currentChildTab];

  const { data, isFetching } = useProjectCheckListListByProjectWithCountQuery({
    id,
    type,
    searchValue,
  });

  const isLoading = isFetching || !data;

  return {
    isLoading,
    searchValue,
    setSearchValue,
    data,
    type,
  };
};
