import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { getUserId, userIsAuth } from "src/app/services/auth/auth";
import style from "../../Organisation/style.module.scss";
import { Wrapper } from "../../../UI/templates";
import TitlePanel from "../../../newUI/TitlePanel/TitlePanel";
import TabsPanel from "../../../newUI/TabsPanel/TabsPanel";
import { RedirectToExternal } from "../../../features/RedirectToExternal/RedirectToExternal";
import {
  AwardSVG,
  EducationSVG,
  ExperienceSVG,
  StarSVG,
  InfoSquareSVG,
  OrganisationSVG,
  PencilEditSVG,
  PortfolioSVG,
  WalletSVG,
  InviteSVG,
  TeamSVG,
} from "../../../newUI/SVG";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { Info } from "./Info/Info";
import { Education, Experience } from "./Experience/Experience";
import { Portfolio } from "./Portfolio/Portfolio";
import { Awards } from "./Awards/Awards";
import { Reviews } from "./Reviews/Reviews";
import { Organisation } from "./Organisation/Organisation";
import { userIsAdmin } from "src/app/services/user/userRoles";
import {
  selectorUserPageContentData,
  selectorUserPageHeaderData,
  setUserPageContentData,
  setUserPageHeaderData,
  setUserPageTeamsData,
} from "src/app/feature/userPage/userPage";
import { useTitle } from "../../../hooks/useTitle";
import { request } from "src/app/services/api/requestHandler";
import {
  ApiGetUserPageContentData,
  ApiGetUserPageHeaderData,
  ApiGetUserTeams,
} from "src/app/services/api/user/user";
import { appErrorHandler } from "src/app/services/api/errorHandlers";
import { createContentData } from "./lib/createContentData";
import { createHeaderData } from "./lib/createHeaderData";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { Teams } from "./Teams/Teams";
import { palette } from "src/styles/restyle";
import useTabs from "../../../hooks/useTabs";
import { ApiGetUserProjects } from "src/app/services/api/organisation/organisation";
import { ModalWrapper } from "../Edit/PersonalDataChange/PersonalDataChange";
import InviteAsGipAssistant from "./modals/InviteAsGipAssistant";

const sx = { color: "white" };

const tabsData = [
  {
    title: "О себе",
    url: "info",
    icon: <InfoSquareSVG />,
  },
  {
    title: "Стаж",
    url: "jobPlace",
    icon: <ExperienceSVG />,
  },
  {
    title: "Образование",
    url: "education",
    icon: <EducationSVG />,
  },
  {
    title: "Портфолио",
    url: "portfolio",
    icon: <PortfolioSVG />,
  },
  {
    title: "Награды",
    url: "awards",
    icon: <AwardSVG />,
  },
  {
    title: "Отзывы",
    url: "feedback",
    icon: <StarSVG />,
  },
  {
    title: "Организации",
    url: "firm",
    icon: <OrganisationSVG />,
  },
  {
    title: "Команда",
    url: "teams",
    icon: <TeamSVG size={"16"} />,
  },
];

const purseTab = {
  title: "Кошелек",
  url: "wallet",
  redirect: true,
  icon: <WalletSVG />,
};

export const View: React.FC = () => {
  const isAuth = userIsAuth();
  const params = useParams<{ id: string; tab: string }>();
  const id = Number(params.id);

  const isAdmin = userIsAdmin();
  const isCurrentUser = getUserId() === id;
  const allowAccess = isAdmin || isCurrentUser;

  const [currentTab, setCurrentTab] = useState<string>(params?.tab || "");
  const [canInviteToProject, setCanInviteToProject] = useState<boolean>(false);

  const [showInvitationAsGipAssistant, setShowInvitationAsGipAssistant] =
    useState<boolean>(false);

  const [isInvitation, setIsInvitation] = useState<boolean>();

  const [tabs, setTabs] = useState(tabsData);
  const [isContentLoad, setContentLoad] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = {
    header: useSelector(selectorUserPageHeaderData),
    content: useSelector(selectorUserPageContentData),
  };

  useTabs(currentTab, setCurrentTab, "info");

  useTitle(
    `${data.header?.name || "Пользователь"} ${data.header?.surname || ""}`
  );

  useEffect(() => {
    if (Number.isNaN(id)) {
      navigate("/");
      return;
    }

    request(
      ApiGetUserPageHeaderData(id),
      (data) => {
        dispatch(setUserPageHeaderData(data));
        if (allowAccess) {
          setTabs([
            ...tabsData,
            { ...purseTab, url: `/account/view?id=${data?.account?.id}` },
          ]);
        }
      },
      () => () => {
        navigate("/");
      }
    )(dispatch);

    const currentUserId = getUserId();
    request(ApiGetUserProjects(currentUserId), (data) => {
      setCanInviteToProject(Boolean(data.length));
    })();
  }, [id]);

  useEffect(() => {
    setContentLoad(true);

    if (Number.isNaN(id)) {
      return;
    }

    (async () => {
      await request(
        ApiGetUserPageContentData(id),
        (data) => {
          dispatch(setUserPageContentData(data));
        },
        appErrorHandler
      )(dispatch);

      await request(
        ApiGetUserTeams(id),
        (data) => {
          dispatch(setUserPageTeamsData(data));
          setContentLoad(false);
        },
        appErrorHandler
      )(dispatch);
    })();
  }, [id]);

  const preparedData = {
    content: (data.content && createContentData(data.content)) || null,
  };

  const headerData = (data.header && createHeaderData(data.header!)) || null;
  const contentData = (data.content && createContentData(data.content)) || null;

  const isContentReady = !isContentLoad && preparedData.content;
  const [editing, setEditing] = useState(false);

  const components = (
    <>
      {currentTab === "info" && isContentReady && contentData && (
        <Info headers={headerData} data={preparedData.content} />
      )}
      {currentTab === "jobPlace" && <Experience data={preparedData.content} />}
      {currentTab === "education" && <Education data={preparedData.content} />}
      {currentTab === "portfolio" && <Portfolio data={preparedData.content} />}
      {currentTab === "awards" && <Awards data={preparedData.content} />}
      {currentTab === "feedback" && <Reviews data={preparedData.content} />}
      {currentTab === "firm" && <Organisation />}
      {currentTab === "teams" && <Teams />}
    </>
  );

  const { Popup } = useUserWorkflow();

  const handleModalOrganisationInvitation = () => {
    Popup.joinUserFirm.open();
  };

  const handleInvitation = () => setIsInvitation(true);

  const handleCloseModal = () => {
    setShowInvitationAsGipAssistant(false);
  };

  const isMobile = useIsMobile();

  return (
    <>
      {isAuth ? (
        <div className={style.wrapper}>
          <Wrapper>
            <TitlePanel
              isContentLoad={isContentLoad}
              setEditing={setEditing}
              primaryText={headerData?.name}
              secondaryText={""}
              photo={headerData?.avatarUrl}
              small
            >
              {isCurrentUser ? (
                !editing && (
                  <Link
                    to={`/user/profile-edit/${params.id}?tab=info`}
                    className={style.tableControls}
                  >
                    {isMobile ? (
                      <IconButton sx={sx}>
                        <PencilEditSVG />
                      </IconButton>
                    ) : (
                      <CustomButton
                        width={160}
                        disabled={editing}
                        background={palette.blue}
                        className={classNames(style.button, style.invite)}
                      >
                        Редактировать
                      </CustomButton>
                    )}
                  </Link>
                )
              ) : isMobile ? (
                <IconButton sx={sx} onClick={handleInvitation}>
                  <InviteSVG />
                </IconButton>
              ) : (
                <div onMouseOut={() => setIsInvitation(false)}>
                  <CustomButton
                    width={160}
                    disabled={editing}
                    onClick={handleInvitation}
                    background={palette.blue}
                    className={classNames(style.button, style.invite)}
                  >
                    Пригласить
                  </CustomButton>
                </div>
              )}
              {isInvitation && (
                <div
                  onMouseEnter={() => setIsInvitation(true)}
                  onMouseLeave={() => setIsInvitation(false)}
                  className={style.list}
                >
                  <button onClick={() => setShowInvitationAsGipAssistant(true)}>
                    На проект
                  </button>
                  <button onClick={handleModalOrganisationInvitation}>
                    В организацию
                  </button>
                </div>
              )}
            </TitlePanel>
            <div className={style.contents}>
              <TabsPanel
                isContentLoad={isContentLoad}
                setEditing={setEditing}
                tabsInfo={tabs}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                components={components}
              />
            </div>
            <ModalWrapper
              show={showInvitationAsGipAssistant}
              head={
                <h2 className={style.addRightsHeader}>Пригласить на проект</h2>
              }
              handleCloseModal={handleCloseModal}
            >
              <InviteAsGipAssistant
                userId={Number(params.id)}
                handleCloseModal={handleCloseModal}
              />
            </ModalWrapper>
          </Wrapper>
        </div>
      ) : (
        <RedirectToExternal to={"/site/login"} />
      )}
    </>
  );
};
