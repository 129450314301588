import React from "react";
import classNames from "classnames";
import cls from "./TransitionShowContent.module.scss";

type TransitionShowContentProps = {
  children: React.ReactNode;
  classname?: string;
  open: boolean;
};
export const TransitionShowContent = ({
  children,
  open,
  classname,
}: TransitionShowContentProps) => {
  return (
    <div className={classNames(cls.notVisible, open && cls.visible, classname)}>
      {children}
    </div>
  );
};

export default TransitionShowContent;
