export const EXECUTORS_SELECTION_DONE_SUCCESSFULLY =
  "Операция по выбору исполнителей завершена успешно";

export const MULTIPLE_GROUP_SELECTION_NOT_ALLOWED =
  "Выбранные для сравнения заявки из других групп будут сброшены!";

export const MAX_3_EXECUTORS =
  "Для сравнения можно выбрать только 3 исполнителей";

export const ABSCENT_STATUS_TEXT = "Отсутствует";
