import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { editPostInitialState } from "./editPostInitialState";
import C from "./constants";
import { ApiPostEditType } from "../../../types/api/responseTypes/apiPostType";
import { StateType } from "../../initialState";

export const postEditReducer = createReducer(
  editPostInitialState,
  (builder) => {
    builder.addCase(
      C.SET_POST_FOR_EDIT,
      (state, action: PayloadAction<ApiPostEditType>) => {
        const { id, content, date_publication, photoUrl, tags, title, url } =
          action.payload;

        return {
          ...state,
          id,
          content,
          photoUrl,
          date_publication,
          tags: tags.length
            ? tags.map((item) => {
                return {
                  id: `${item.id}`,
                  title: item.label,
                };
              })
            : [],
          title,
          url,
        };
      }
    );
  }
);

export const selectorPostEdit = (state: StateType) => state.postEdit;

export const setPostForEdit = (post: ApiPostEditType) => ({
  type: C.SET_POST_FOR_EDIT,
  payload: {
    ...post,
  },
});
