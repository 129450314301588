import React from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import moment from "moment";
import { ComponentPropsType } from "../../../../types/other/components/componentPropsType";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { createSearchObjectDataFromFormParams } from "../../../../app/services/searchParams/searchParamsCreateData";
import style from "./style.module.scss";
import globalStyles from "../style.module.scss";
import { Specialization } from "../../../features/SpecialForm/Specialization";
import { DesignType } from "../../../features/SpecialForm/DesignType";
import { DatePicker } from "../../../UI/components/FormComponentns/DatePicker/DatePicker";
import { buttonStyle } from "../Executors/ExecutorFormHead";
import { textFieldSX } from "../../../../styles/restyle";

type TFormValues = Record<string, any>;

export type ProjectsFormHeadType = ComponentPropsType<{
  submitHandler?: (params: Record<string, any>) => void;
  resetHandler?: () => void;
  initialValues?: Record<string, any>;
}>;

export const ProjectsFormHeader: React.FC<ProjectsFormHeadType> = ({
  theme = "dark",
  submitHandler = () => {},
  resetHandler = () => {},
  initialValues = {},
}) => {
  const start = initialValues["ProjectSearch[dateEnd1]"]
    ? moment(initialValues["ProjectSearch[dateEnd1]"], "DD.MM.YYYY").toDate()
    : null;
  const end = initialValues["ProjectSearch[dateEnd2]"]
    ? moment(initialValues["ProjectSearch[dateEnd2]"], "DD.MM.YYYY").toDate()
    : null;

  const { register, handleSubmit, setValue, trigger, watch, reset } =
    useForm<TFormValues>({
      defaultValues: {
        name: initialValues["ProjectSearch[name]"],
        group: initialValues["ProjectSearch[partGroupId]"],
        dates: [start, end],
        designType: initialValues["ProjectSearch[design_type]"],
      },
    });

  const watchGroup = watch("group");
  const watchDates = watch("dates");
  const watchDesignType = watch("designType");

  const onSubmit = (data: TFormValues) => {
    const searchData = createSearchObjectDataFromFormParams(data, "projects");
    submitHandler(searchData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div className={style.formContainer}>
        <div className={style.formGroup}>
          <TextField
            {...register("name")}
            {...textFieldSX}
            className={style.formElementBig}
            label="Наименование"
          />
          <Specialization
            variant={style.formElement}
            value={watchGroup}
            changeHandler={(data) =>
              setValue("group", data ? data.id : undefined)
            }
            label={"Специализация"}
          />
          <DatePicker
            multiple
            variant={style.formElement}
            label={"Период выполнения"}
            changeDateHandler={(dates) => {
              setValue("dates", dates);
              trigger("dates");
            }}
            startDateProp={watchDates[0]}
            endDateProp={watchDates[1]}
          />
          <DesignType
            changeHandler={(data) =>
              setValue("designType", data ? data.id : undefined)
            }
            value={watchDesignType}
            variant={style.formElementSmall}
          />
        </div>
        <div className={globalStyles.buttonFormWrapper}>
          <Button
            theme={theme}
            variant="contained"
            color="primary"
            style={buttonStyle}
            type={"submit"}
          >
            Поиск
          </Button>
          <Button
            theme={theme}
            variant="contained"
            color="grey"
            style={buttonStyle}
            clickHandler={() => {
              resetHandler();
              reset();
              setValue("name", "");
              setValue("group", "");
              setValue("designType", undefined);
              setValue("dates", [null, null]);
            }}
          >
            Сбросить
          </Button>
        </div>
      </div>
    </form>
  );
};
