import { useEffect, useState } from "react";
import { useLazyBankAccountAccountsByUserTypeQuery } from "src/FSD/entities/bankAccount/api";
import { ApiBankAccountResponses } from "src/FSD/entities/bankAccount/model/responseTypes";
import { Nullable } from "src/types/generics";
import { TSelectItem } from "src/FSD/shared/uiKit/v2";

type UseSelectProps = {
  userType?: Nullable<TSelectItem>;
  setValue?: any;
  bankAccountId?: number;
  isBankAccount: boolean;
};

export const useSelectBankAccountByUserType = ({
  userType,
  bankAccountId,
  setValue,
  isBankAccount,
}: UseSelectProps) => {
  const [getBankAccountUser, { data, isLoading }] =
    useLazyBankAccountAccountsByUserTypeQuery();
  const [bankAccount, setBankAccount] = useState<TSelectItem[]>([]);

  const fetchBankAccountUser = async () => {
    await getBankAccountUser({
      userTypeId: Number(userType!.id),
    });
  };

  useEffect(() => {
    if (userType) {
      fetchBankAccountUser();

      if (!isBankAccount && bankAccount.length) {
        setValue("bankAccount", bankAccount[0]);
      }
    }
  }, [userType, isBankAccount, bankAccount]);

  useEffect(() => {
    if (data) {
      const bankAccountToSelect = data.map(
        (item: ApiBankAccountResponses.AccountsByUserType) => ({
          id: item.id,
          title: item.name,
        })
      );

      setBankAccount(bankAccountToSelect);
    }
  }, [data]);

  useEffect(() => {
    if (!bankAccount) {
      return;
    }

    if (!bankAccountId && bankAccount.length) {
      setValue("bankAccount", bankAccount[0]);
      return;
    }

    setValue(
      "bankAccount",
      bankAccount.find((item) => item.id === bankAccountId)
    );
  }, [bankAccountId, bankAccount]);

  return {
    bankAccount,
    isLoadingBankAccount: (isLoading || !bankAccount) && !!userType,
  } as const;
};
