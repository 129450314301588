import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProjectProcessCreateOutTaskMutation } from "src/FSD/entities/projectProcess/api";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { createOutTaskSchema } from "../schemas/createOutTaskSchema";

interface IInputs {
  content: string;
  toProcessesIds: TSelectItem[];
  toProjectId: TSelectItem;
  uploadFiles: TFile[];
}

type TProps = {
  id: number;
};

export const useCreateOutTask = ({ id }: TProps) => {
  const { handleClose } = useModalContext();

  const form = useForm<IInputs>({
    resolver: yupResolver(createOutTaskSchema),
  });

  const projectValue = form.watch("toProjectId");

  const [createOutTask, { isLoading }] =
    useProjectProcessCreateOutTaskMutation();

  const formSubmitHandler = async (dataValues: IInputs) => {
    const formData = new FormData();

    formData.append("content", dataValues.content || "");
    formData.append("status", String(Statuses.IS_DONE));
    formData.append("toProjectId", String(projectValue.id));
    dataValues.toProcessesIds.forEach((item) => {
      formData.append("toProcessesIds[]", String(item.id));
    });
    dataValues.uploadFiles.forEach(({ file }) => {
      formData.append("upload_files[]", file!);
    });

    if (id) {
      await createOutTask({ id, data: formData });
      handleClose();
    }
  };

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(formSubmitHandler);

  return {
    onSubmit,
    form,
    isLoading,
  } as const;
};
