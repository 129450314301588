import { memo } from "react";
import cn from "classnames";
import styles from "./ProgressBar.module.scss";
import { Paragraph } from "../Paragraph";

type TValuesColorScheme = {
  red: string;
  green: string;
};

type TProps = {
  title?: string;
  titleAddition?: string;
  upperLeftLabel?: string;
  upperRightLabel?: string;
  lowerLabel?: string;
  valueProgress: number;
  colorScheme: string;
  className?: string;
};

const gradients: TValuesColorScheme = {
  red: "linear-gradient(270.00deg, rgb(190, 0, 0) 1.5%,rgba(190, 0, 0, 0) 77.94%)",
  green:
    "linear-gradient(90.00deg, rgba(155, 155, 155, 0),rgb(10, 166, 153) 69.164%)",
};

const textColors: TValuesColorScheme = {
  red: "rgb(190, 0, 0)",
  green: "rgb(10, 166, 153)",
};

export const ProgressBar = memo(
  ({
    title = "",
    titleAddition = "",
    upperLeftLabel = "",
    upperRightLabel = "",
    lowerLabel = "",
    valueProgress = 50,
    colorScheme = "green",
    className = "",
  }: TProps) => {
    const backgroundGradient =
      gradients[colorScheme as keyof TValuesColorScheme];
    const textColor = textColors[colorScheme as keyof TValuesColorScheme];

    return (
      <div className={cn(styles.deadlines, className)}>
        <div className={styles.deadlines__title}>
          <Paragraph color="light" fontWeight="medium">
            {title}
          </Paragraph>
          {titleAddition && (
            <Paragraph fontSize={14} fontWeight="medium">
              {titleAddition}
            </Paragraph>
          )}
        </div>
        <div className={styles.date}>
          <Paragraph fontSize={14} fontWeight="medium">
            {upperLeftLabel}
          </Paragraph>
          <Paragraph fontSize={14} fontWeight="medium">
            {upperRightLabel}
          </Paragraph>
        </div>
        <div className={styles.terms}>
          <div
            className={styles.terms__slider}
            style={{
              width: `${valueProgress}%`,
              background: backgroundGradient,
            }}
          >
            <Paragraph
              className={cn(
                styles.terms__date__default,
                valueProgress < 20 && styles.left
              )}
              style={{ color: textColor }}
              fontSize={14}
              color="default"
              fontWeight="medium"
            >
              {lowerLabel}
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }
);
