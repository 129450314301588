import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiFeedbackRequest } from "../../model/requestsTypes";

type TParams = RequestParams<{
  taskId: number;
  userId: number;
  data: ApiFeedbackRequest.Task;
}>;

export const task = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ taskId, userId, data }) => ({
      url: `v1/feedback/task`,
      params: {
        taskId,
        userId,
      },
      data,
      method: HTTPMethods.POST,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.REMOVED,
      ApiTags.ProjectProcess.REMOVED,
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.VIEW,
      ApiTags.MaterialProcess.INDEX,
    ],
  });
