import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Skeleton } from "@mui/material";
import cn from "classnames";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import style from "../ProcessPublication/style.module.scss";
import DatesForm from "./DatesForm";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  fetchGetUsersToAssign,
  getExecutorFullTypes,
} from "src/app/feature/project/projectProcessPublication/thunks";
import {
  clearGroupPublishProcess,
  setTouched,
} from "src/app/feature/project/projectProcessPublication";
import {
  ERROR_MESSAGES,
  TFormValues,
} from "../ProcessPublication/ProcessPublication";
import { getGroupRequestProcessData } from "src/app/feature/project/projectProcessRequest";
import {
  groupRequest,
  getGroupRequestProcesses,
} from "src/app/feature/project/projectProcessRequest/thunks";
import Info from "./Info";
import { getProjectProcessUserTypes } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getUserTypesProjectProcess";
import { fetchUserTypes } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchUserTypes";
import { getUserId } from "src/app/services/auth/auth";
import { getProjectProcessExecutorBank } from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessExecutorBank";
import { getProjectProcessExecutorTypeForm } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessBank } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessBank";
import { setExecutorTypeForm } from "src/app/feature/ProjectProcessView/Info/slice/projectProcessInfo";
import {
  getProjectProcessRequestBanksLoading,
  getProjectProcessRequestUserTypesLoading,
} from "src/app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";

const ProcessRequest = () => {
  const [currentPage, setPage] = useState<number>(0);

  const dispatch = useAppDispatch();
  const userId = getUserId();
  const userTypes = useAppSelector(getProjectProcessUserTypes);
  const executorBanks = useAppSelector(getProjectProcessExecutorBank);
  const executorTypeValue = useAppSelector(getProjectProcessExecutorTypeForm);

  const isUserTypesLoading = useAppSelector(
    getProjectProcessRequestUserTypesLoading
  );

  const isUserBanksLoading = useAppSelector(
    getProjectProcessRequestBanksLoading
  );

  const {
    data: { processId, processesData },
    pending,
  } = useAppSelector(getGroupRequestProcessData);

  const { id: projectId } = useParams<{ id: string }>();
  const id = Number(projectId);

  const showLastPage =
    Object.keys(processesData).length &&
    currentPage === Object.keys(processesData).length;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      // основные страницы заполняются динамически
      // последняя страница
      userType: null,
      bankAccount: null,
      content: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        ...Object.entries(processesData).reduce(
          (finalSchema: any, [typeId, { list }]: any) => {
            return {
              ...finalSchema,
              bankAccount: Yup.mixed().test(
                "hasBankAccount",
                "Поле обязательно",
                (): boolean => {
                  const bankAccount = watch("bankAccount");
                  return Boolean(bankAccount);
                }
              ),
              ...Object.keys(list).reduce((result: any, id: string) => {
                const dateStartKey = `processes.${typeId}.list.${id}.dateStart`;
                const dateLimitKey = `processes.${typeId}.list.${id}.dateLimit`;
                const priceKey = `processes.${typeId}.list.${id}.price`;

                return {
                  ...result,
                  [dateStartKey]: Yup.string()
                    .nullable()
                    .test(
                      `datesRange.${id}`,
                      ERROR_MESSAGES.DATE_RANGE,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !dateLimit || !isActive) {
                          return true;
                        }

                        return moment(dateStart) <= moment(dateLimit);
                      }
                    )
                    .test(
                      `dateStartPick.${id}`,
                      ERROR_MESSAGES.DATE_IS_NEXT,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !isActive) {
                          return true;
                        }

                        return moment(dateStart) > moment();
                      }
                    )
                    .test(
                      `dateRequired.${id}`,
                      ERROR_MESSAGES.DATE_START_REQUIRED,
                      (): boolean => {
                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );

                        return isActive ? Boolean(dateStart) : true;
                      }
                    ),
                  [dateLimitKey]: Yup.string()
                    .nullable()
                    .test(
                      `dateRequired.${id}`,
                      ERROR_MESSAGES.DATE_LIMIT_REQUIRED,
                      (): boolean => {
                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        return isActive ? Boolean(dateLimit) : true;
                      }
                    )
                    .test(
                      `dateLimitPick.${id}`,
                      ERROR_MESSAGES.DATE_IS_NEXT,
                      (): boolean => {
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateLimit || !isActive) {
                          return true;
                        }

                        return moment(dateLimit) > moment();
                      }
                    )
                    .test(
                      `dateRangeMin.${id}`,
                      ERROR_MESSAGES.MIN_DATE_RANGE_8_DAYS,
                      (): boolean => {
                        const dateStart: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateStart`
                        );
                        const dateLimit: string | undefined = watch(
                          `processes.${typeId}.list.${id}.dateLimit`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!dateStart || !dateLimit || !isActive) {
                          return true;
                        }

                        const dateStartParsed = moment(dateStart);
                        const dateLimitParsed = moment(dateLimit);

                        const daysDifference = dateLimitParsed.diff(
                          dateStartParsed,
                          "days"
                        );

                        return daysDifference >= 8;
                      }
                    ),
                  [priceKey]: Yup.number()
                    .test(
                      `price.${id}`,
                      ERROR_MESSAGES.PRICE_1000,
                      (): boolean => {
                        const price: string | undefined = watch(
                          `processes.${typeId}.list.${id}.price`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!isActive) {
                          return true;
                        }

                        return price ? Number(price) >= 1000 : false;
                      }
                    )
                    .test(
                      `price.${id}.isInt`,
                      ERROR_MESSAGES.PRICE_IS_INT,
                      (): boolean => {
                        const price: string | undefined = watch(
                          `processes.${typeId}.list.${id}.price`
                        );

                        const isActive: boolean | undefined = watch(
                          `processes.${typeId}.list.${id}.active`
                        );

                        if (!isActive) {
                          return true;
                        }

                        return Number.isInteger(Number(price));
                      }
                    ),
                };
              }, {}),
            };
          },
          {}
        ),
      })
    ),
    mode: "all",
  });

  const onSubmit = (body: TFormValues) => {
    dispatch(groupRequest({ processId, body }));
  };

  const handleSetTouched = () => {
    dispatch(setTouched(true));
  };

  useEffect(() => {
    dispatch(getExecutorFullTypes());
    dispatch(getGroupRequestProcesses(processId));

    return () => {
      dispatch(clearGroupPublishProcess());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGetUsersToAssign(processId));
  }, [processId, dispatch]);

  useEffect(() => {
    Object.keys(processesData).forEach((typeId: string) => {
      if (processesData[typeId].list[processId]) {
        setValue(`processes.${typeId}.list.${processId}.active`, true);
      }

      const watchTotalPrice = watch(`${typeId}.totalPrice`);
      if (watchTotalPrice === undefined) {
        setValue(`${typeId}.totalPrice`, 0);
      }

      const watchDateStart = watch(`${typeId}.dateStart`);
      if (watchDateStart === undefined) {
        setValue(`${typeId}.dateStart`, null);
      }

      const watchDateLimit = watch(`${typeId}.dateLimit`);
      if (watchDateLimit) {
        setValue(`${typeId}.dateLimit`, null);
      }
    });
  }, [processesData, currentPage]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserTypes(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (executorTypeValue) {
      dispatch(fetchProjectProcessBank(Number(executorTypeValue)));
    }

    setValue("bankAccount", null);
    trigger("bankAccount");
  }, [executorTypeValue]);

  useEffect(() => {
    if (executorBanks?.length) {
      const { id, name: title } = executorBanks[0];
      setValue("bankAccount", { id, title });
      trigger("bankAccount");
    }
  }, [executorBanks, setValue]);

  useEffect(() => {
    if (userTypes?.length) {
      const { id, listName: title } = userTypes[0];

      setValue("userType", { id, title });
      dispatch(setExecutorTypeForm(id));
    }
  }, [userTypes, setValue]);

  if (pending.getProcesses) {
    return (
      <div className={cn(style.wrapper, style.loading)}>
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
        <Skeleton className={style.skeleton} />
      </div>
    );
  }

  return (
    <form onClick={handleSetTouched} className={style.wrapper}>
      {Object.entries(processesData).map(
        ([typeId, value]: any, index: number) => {
          if (index !== currentPage) {
            return;
          }

          return (
            <>
              <DatesForm
                list={value.list}
                title={value.title}
                maybeExpertise={value.maybeExpertise}
                typeId={typeId}
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                pending={pending}
                processId={processId}
              />
              <div className={style.buttons}>
                {Boolean(currentPage && !showLastPage) && (
                  <CustomButton
                    onClick={() => {
                      setPage(currentPage - 1);
                      dispatch(setTouched(false));
                    }}
                    background={palette.red}
                    width={120}
                    disabled={pending.publishing}
                  >
                    Назад
                  </CustomButton>
                )}
                <CustomButton
                  onClick={async () => {
                    let containesError = false;
                    const values = Object.keys(processesData[typeId].list);

                    const validateProcess = async (id: number) => {
                      const isValidDateStart = await trigger(
                        `processes.${typeId}.list.${id}.dateStart`
                      );

                      const isValidDateLimit = await trigger(
                        `processes.${typeId}.list.${id}.dateLimit`
                      );

                      const isValidPrice = await trigger(
                        `processes.${typeId}.list.${id}.price`
                      );

                      containesError =
                        containesError ||
                        !isValidDateStart ||
                        !isValidDateLimit ||
                        !isValidPrice;
                    };

                    const promises = values.map((id: any) =>
                      validateProcess(id)
                    );
                    await Promise.all(promises);

                    if (containesError) {
                      return;
                    }

                    setPage(currentPage + 1);
                    dispatch(setTouched(false));
                  }}
                  disabled={Boolean(Object.keys(errors).length)}
                  forceDisabled
                  background={palette.blue}
                  width={150}
                >
                  Далее
                </CustomButton>
              </div>
            </>
          );
        }
      )}
      {showLastPage && (
        <>
          <Info
            watch={watch}
            id={id}
            processId={processId}
            setValue={setValue}
            userTypes={userTypes}
            executorBanks={executorBanks}
            pending={pending}
            control={control}
            errors={errors}
            trigger={trigger}
            register={register}
            isUserTypesLoading={isUserTypesLoading}
            isUserBanksLoading={isUserBanksLoading}
          />
          <div className={style.buttons}>
            <CustomButton
              onClick={() => setPage(currentPage - 1)}
              background={palette.red}
              width={120}
              disabled={pending.publishing}
              forceDisabled
            >
              Назад
            </CustomButton>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data))}
              background={palette.blue}
              width={140}
              disabled={
                isUserTypesLoading || isUserBanksLoading || pending.publishing
              }
              forceDisabled={Boolean(Object.keys(errors).length)}
            >
              Отправить
            </CustomButton>
          </div>
        </>
      )}
    </form>
  );
};

export default ProcessRequest;
