import { ReactNode } from "react";
import cn from "classnames";
import styles from "./FormSteps.module.scss";

type Props = {
  children: ReactNode;
  isSelected?: boolean;
  index: number;
};

export const Step = ({ children, isSelected, index }: Props) => {
  return (
    <div className={cn(styles.step, isSelected && styles.step_selected)}>
      <hr />
      <div>{index + 1}</div>
      <p>{children}</p>
    </div>
  );
};
