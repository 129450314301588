import React, { memo } from "react";
import { Button, Select } from "src/FSD/shared/uiKit/v2";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import {
  usePartGroupGetProjectPartByGroupQuery,
  usePartGroupIndexQuery,
} from "src/FSD/entities/partGroup/api";
import styles from "./HeaderRemarksFilter.module.scss";

const status = [
  {
    id: 2,
    title: "В работе",
  },
  {
    id: 3,
    title: "Выполнено",
  },
];

export type TFilter = {
  partGroupId?: number | string;
  projectPartIds?: number | string;
  status?: number | string;
};

type TProps = {
  projectId: number;
  processId: number;
  setFilter: (value: TFilter) => void;
};

export const HeaderRemarksFilter = memo(
  ({ projectId, processId, setFilter }: TProps) => {
    const [partGroupId, setPartGroupId] = React.useState<TSelectItem>();
    const [projectPartId, setProjectPartId] = React.useState<TSelectItem>();
    const [statusId, setStatusId] = React.useState<TSelectItem>();
    const { data: partGroup = [] } = usePartGroupIndexQuery();
    const { data: projectPart = [] } = usePartGroupGetProjectPartByGroupQuery(
      {
        projectId,
        groupId: partGroupId?.id as number,
      },
      { skip: !partGroupId?.id }
    );

    const mapProjectPart = projectPart
      ? projectPart.map((part) => ({ id: part.code, title: part.name }))
      : [];

    function handleSearchClick() {
      if (processId) {
        setFilter({
          partGroupId: partGroupId?.id,
          projectPartIds: projectPartId?.id,
          status: statusId?.id,
        });
      }
    }

    function handleClearClick() {
      if (processId) {
        setFilter({
          partGroupId: "",
          projectPartIds: "",
          status: "",
        });
      }
    }

    return (
      <div className={styles.filter}>
        <Select
          label="Группа разделов"
          optionLabel="name"
          options={partGroup}
          value={partGroupId}
          changeHandler={(_, newValue) => {
            setPartGroupId(newValue);
          }}
        />
        <Select
          label="Разделы"
          disabled={!projectPart}
          value={projectPartId}
          options={mapProjectPart}
          disableClear
          changeHandler={(_, newValue) => {
            setProjectPartId(newValue);
          }}
        />
        <Select
          label="Статус"
          options={status}
          disableClear
          value={statusId}
          changeHandler={(_, newValue) => {
            setStatusId(newValue);
          }}
        />
        <div className={styles.filter_btn}>
          <Button onClick={handleSearchClick}>Поиск</Button>
          <Button onClick={handleClearClick}>Сбросить</Button>
        </div>
      </div>
    );
  }
);
