import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import {
  Button,
  DateField,
  Select,
  TextField,
  Uploader,
} from "src/FSD/shared/uiKit/v2";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { useUpdateMaterialProcess } from "../lib/hooks/useUpdateMaterialProcess";
import styles from "./UpdateMaterialProcess.module.scss";
import { CheckProviderFirm } from "./CheckProviderFirm";

type TProps = {
  processId: number;
  partNum?: number;
  header: string;
};

const defaultValues = {
  files: [],
  comment: "",
  dateLimit: null,
  template: null,
  firmId: -1,
  nameRequest: "",
  templateListLength: 1,
};

export const UpdateMaterialProcessForm = ({
  processId,
  partNum,
  header,
}: TProps) => {
  const { handleClose } = useModalContext();

  const {
    onSubmit,
    setValue,
    errors,
    register,
    handleSubmit,
    isSubmitting,
    control,
    isFetching,
    providerFirms,
    templateList,
    formValues,
  } = useUpdateMaterialProcess({
    processId,
    partNum,
    handleClose,
    defaultValues,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.providerFirms}>
            {providerFirms!.map(
              (providerFirm: ApiMaterialProcessResponses.GetProviderFirms) => (
                <CheckProviderFirm
                  key={providerFirm.id}
                  providerFirm={providerFirm}
                  setValue={setValue}
                  control={control}
                />
              )
            )}
            {providerFirms!.length > 1 && (
              <Controller
                control={control}
                name="firmId"
                render={({ field: { value } }) => (
                  <Checkbox
                    label="Выбрать все"
                    onChange={() => setValue("firmId", -1)}
                    value={value}
                    checked={value === -1}
                    editing
                  />
                )}
              />
            )}
          </div>
          <TextField
            {...register("nameRequest")}
            label="Наименование запроса"
            multiline
            error={Boolean(errors.nameRequest)}
            helperText={errors.nameRequest?.message}
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
            minRows={3}
          />
          <div className={styles.dateLimitBlock}>
            <Controller
              control={control}
              name="dateLimit"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Срок предоставления КП"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateLimit}
                  helperText={errors?.dateLimit?.message}
                />
              )}
            />
          </div>
          {!!templateList.length && formValues.showTemplate && (
            <Controller
              name="template"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Шаблон опросного листа"
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  options={templateList}
                  optionLabel="name"
                  error={Boolean(errors.template)}
                  helperText={errors.template?.message}
                />
              )}
            />
          )}
          {formValues.showUploader && (
            <Controller
              name="files"
              control={control}
              render={({ field: { onChange } }) => (
                <Uploader
                  canEdit
                  autoUpload
                  setFiles={(files) => {
                    onChange(files);
                  }}
                  error={Boolean(errors.files)}
                  helperText={errors.files?.message}
                  uploadAsActive
                />
              )}
            />
          )}
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Опубликовать
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
