import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button, TextField } from "src/FSD/shared/uiKit/v2";
import { useMaterialProcessSelectRequest } from "../lib/hooks/useMaterialProcessSelectRequest";

type TProps = {
  taskId: number;
  materialId: number;
  header: string;
};

const defaultValues = {
  comment: "",
};

export const MaterialProcessSelectRequestForm = ({
  taskId,
  materialId,
  header,
}: TProps) => {
  const { register, handleSubmit, isSubmitting, errors, onSubmit } =
    useMaterialProcessSelectRequest({
      taskId,
      materialId,
      defaultValues,
    });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("comment")}
          label="Комментарий"
          multiline
          error={!!errors.comment}
          helperText={errors?.comment?.message}
          minRows={3}
        />

        <Modal.Controls>
          <Button disabled={isSubmitting} type="submit">
            Отправить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
