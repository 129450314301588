import { config } from "../../../../app.cofig";
import { httpRequestGet } from "../api";

export interface ApiGetProvider {
  page: number;
}

export const ApiGetProvidersList = (
  paramsString: string
): Promise<Response> => {
  return httpRequestGet(
    `${config.localDomain}/v1/user/index-provider${paramsString}&expand=portfolios,jobPlaces,educations,rewards,feedbacks,additionalInfo,rating,providerPullCount,providerTotalCount`,
    {}
  );
};
