import { Controller, UseFormReturn } from "react-hook-form";
import { Fragment } from "react";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import { List } from "src/FSD/shared/uiKit/v2/List";
import styles from "./Forms.module.scss";
import { TFieldValues } from "../model/types";
import { useProcessesForm } from "../lib/hooks/useProcessesForm";

export type Props = {
  dataRemoveExecutor: any;
  form: UseFormReturn<TFieldValues, object>;
  onSubmit: (data: TFieldValues) => void;
  isRemovingExecutor: boolean;
};

export const ProcessesForm = ({
  dataRemoveExecutor,
  form,
  onSubmit,
  isRemovingExecutor,
}: Props) => {
  const {
    handleSubmit,
    onPreSubmit,
    control,
    register,
    paidByProcessesValue,
    errors,
    handleGoBack,
    clearErrors,
  } = useProcessesForm({
    dataRemoveExecutor,
    onSubmit,
    form,
  });

  return (
    <Modal.Form onSubmit={handleSubmit(onPreSubmit)}>
      <Paragraph color="light">
        Выберите разделы, с которых вы хотите снять исполнителя, и укажите сумму
        фактической оплаты за выполненные работы
      </Paragraph>
      <div className={styles.processes__grid}>
        {Object.values(dataRemoveExecutor.objects).map((project: any) => {
          return (
            <Fragment key={project.projectName}>
              <Paragraph
                className={styles.projectName}
                color="light"
                fontWeight="bold"
              >
                {project.projectName}
              </Paragraph>
              {Object.values(project.objects).map((process: any) => {
                const ndsText = getNdsText(process.object.executor_nds);
                const { id: workId } = process.object;
                const processTitle = process.object.title;

                return (
                  <Fragment key={workId}>
                    <Paragraph>Задача</Paragraph>
                    <Paragraph>Оплачено</Paragraph>
                    <Controller
                      name={`processes.checked.${workId}`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={value}
                          editing
                          onChange={() => {
                            onChange(!value);

                            if (value) {
                              clearErrors(`processes.price.${workId}`);
                            } else {
                              clearErrors("atLeastOneProcessRequired");
                            }
                          }}
                          label={processTitle}
                          className={styles.processes__grid__checkbox}
                        />
                      )}
                    />
                    <div className={styles.processes__grid__price}>
                      <TextField
                        {...register(`processes.price.${workId}`)}
                        className={styles.processes__grid__price__field}
                        error={Boolean(errors?.processes?.price?.[workId])}
                        disabled={paidByProcessesValue}
                        helperText={errors?.processes?.price?.[workId]?.message}
                      />
                      <span>{ndsText}</span>
                    </div>
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </div>
      <Controller
        name="advancesPaidByProcesses"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={value}
            editing
            onChange={() => {
              onChange(!value);
            }}
            label={"Оплата исполнителю в сумме выплаченных авансов по разделам"}
          />
        )}
      />
      {Boolean(errors.atLeastOneProcessRequired) && (
        <Paragraph color="error">
          {errors.atLeastOneProcessRequired?.message}
        </Paragraph>
      )}
      <List className={styles.processes__warnings}>
        <Paragraph color="warning">Внимание!</Paragraph>
        <Paragraph color="light">
          Не забудьте подписать дополнительное соглашение или соглашение о
          расторжении после нажатия кнопки "Снять исполнителя".
        </Paragraph>
      </List>
      <Modal.Controls>
        <Button color="primary" variant="outlined" onClick={handleGoBack}>
          Назад
        </Button>
        <Button isLoading={isRemovingExecutor} color="primary" type="submit">
          Снять
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
