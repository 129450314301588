import { useContext } from "react";
import { Button, Card } from "src/FSD/shared/uiKit/v2";
import { ApplicationsContext } from "../Applications";

export const Header = () => {
  const { isComparison, selectedUserTypeIds, setComparison } =
    useContext(ApplicationsContext);

  const ComparisonButton = selectedUserTypeIds.length >= 2 && (
    <Button onClick={() => setComparison(true)}>
      Сравнить ({selectedUserTypeIds.length})
    </Button>
  );

  const BackButton = (
    <Button onClick={() => setComparison(false)}>Назад</Button>
  );

  return (
    <Card
      header={<Card.Header isCentered>Заявки</Card.Header>}
      controls={
        <Card.Controls location="right">
          {isComparison ? BackButton : ComparisonButton}
        </Card.Controls>
      }
    />
  );
};
