import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import classes from "./style.module.scss";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import Avatar from "../../../../../UI/components/indexPageLists/UserList/User/subcomponents/Avatar/Avatar";
import { config } from "../../../../../../app.cofig";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { palette } from "../../../../../../styles/restyle";
import {
  ChatIcon,
  ConsiderIcon,
  DeleteButtonIcon,
} from "../../../../../newUI/SVG";
import Modal from "../../../../../newUI/Modal/Modal";
import { GipSearch } from "./GipSearch";
import { RequestGip } from "./RequestGip";
import { RemoveGip } from "./RemoveGip";
import {
  fetchGipCancelSearch,
  fetchGipHelpersList,
  fetchGipRemoveRequest,
  fetchProjectViewSearchGip,
} from "../../../../../../app/feature/ProjectView/thunks";
import { getUserId } from "../../../../../../app/services/auth/auth";
import { request } from "../../../../../../app/services/api/requestHandler";
import { ApiGetUserTypes } from "../../../../../../app/services/api/user/user";
import {
  getProjectViewData,
  setGipTypes,
} from "../../../../../../app/feature/ProjectView/projectView";
import { CompleteRemoveGip } from "./CompleteRemoveGip";
import { ApiGipType } from "../../../../../../types/api/primaryTypes/apiPrimaryUserType";
import { getProjectCompareGip } from "../../../../../../app/feature/ProjectView/CompareReducer/projectCompareGip";
import { trimNumber } from "../../../../../../utils/trimNumber";
import { GipCard } from "./GipCard/GipCard";

interface InfoGipProps {
  project: ProjectViewData;
  setCurrentTab: (arg: string) => void;
}

const sx = { color: "white" };

export const InfoGip: React.FC<InfoGipProps> = ({ project, setCurrentTab }) => {
  const {
    gipTypes,
    isLoadSearchGip,
    isLoadCompleteRemoveGip,
    isLoadRequestGip,
  } = useSelector(getProjectViewData);
  const { isLoadRemoveGip } = useSelector(getProjectViewData);
  const { compareGip } = useSelector(getProjectCompareGip);
  const isMobile = useIsMobile(500);
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const gipId = getUserId();
  const [modalSearch, setModalSearch] = useState(false);
  const [modalRequest, setModalRequest] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);

  async function handleClick(str: string) {
    if (str === "Отменить" && params.id) {
      const result = window.confirm(
        "Вы уверены, что хотите отменить поиск ГИП"
      );
      const { id } = params;
      if (result) {
        await dispatch(fetchGipCancelSearch(id));
        await dispatch(fetchProjectViewSearchGip(id));
      }
    }
    if (str === "Отозвать" && params.id) {
      const result = window.confirm(
        "Вы уверены, что хотите отозвать заявку ГИП"
      );
      const { id } = params;
      if (result) {
        await dispatch(fetchGipRemoveRequest(id));
        await dispatch(fetchProjectViewSearchGip(id));
      }
    }
  }

  function clickRequestCount() {
    if (project?.can.edit && compareGip.length > 0) {
      setCurrentTab("compare");
    }
  }

  useEffect(() => {
    request(ApiGetUserTypes(gipId), (data: ApiGipType) => {
      dispatch(setGipTypes(data));
    })();
    if (project?.gip?.firmId && project?.gip?.id === gipId) {
      dispatch(fetchGipHelpersList(project.gip.firmId));
    }
  }, []);

  const data = useMemo(() => {
    return [
      {
        avatar: `${config.localDomain}${project?.gip?.photo}`,
        link: `/user/${project?.gip?.id}`,
        name: project?.gip?.name,
        firm: project?.gip?.type,
        firmId: project?.gip?.firmId,
        id: project?.gip?.id,
        role: "ГИП",
        gipHelpers: project?.gipHelpers,
        gipHelpersApplication: project?.gipHelpersApplication,
        jobDuration: project?.gip?.jobDuration,
        rating: project?.gip?.rating,
        gipInvite: project?.can?.inviteGipHelpers,
        gipRemove: project?.can?.removeGipHelpers,
        editByGipHelper: project?.can?.editByGipHelper,
        chatId: project?.chatId,
      },
    ];
  }, [project]);

  if (project) {
    const canGip = project.gipProcess?.status?.key === 2 || undefined;

    return (
      <div className={classNames(classes.object_gip, classes.basic)}>
        {!project.gip && (
          <>
            <Modal
              active={modalSearch}
              setActive={setModalSearch}
              isLoad={isLoadSearchGip}
            >
              <GipSearch />
            </Modal>
            <Modal
              active={modalRequest}
              setActive={setModalRequest}
              isLoad={isLoadRequestGip}
            >
              {gipTypes && (
                <RequestGip
                  gipTypes={gipTypes.userTypes}
                  price={project?.gipProcess?.price}
                />
              )}
            </Modal>
            <div className={classes.object_gip_not}>
              <h1>ГИП</h1>
              {project.can?.startSearchGip && (
                <CustomButton
                  width={isMobile ? 100 : 160}
                  onClick={() => setModalSearch(true)}
                  background={palette.green}
                >
                  Искать
                </CustomButton>
              )}
              {project.can?.cancelSearchGip && (
                <CustomButton
                  width={isMobile ? 100 : 160}
                  onClick={() => handleClick("Отменить")}
                  background={palette.red}
                >
                  Отменить
                </CustomButton>
              )}
              {project.can?.requestGip && (
                <CustomButton
                  width={isMobile ? 100 : 160}
                  background={palette.green}
                  onClick={() => setModalRequest(true)}
                >
                  Заявиться
                </CustomButton>
              )}
              {project.can?.deleteRequestGip && (
                <CustomButton
                  width={isMobile ? 100 : 160}
                  onClick={() => handleClick("Отозвать")}
                  background={palette.red}
                >
                  Отозвать
                </CustomButton>
              )}
            </div>
            {project?.can.startSearchGip && <p>Не назначен</p>}
            {canGip && (
              <div className={classes.object_gip_process}>
                <div className={classes.object_gip_process_type}>
                  <p>Требуемая форма собственности</p>
                  <div>
                    {project?.gipProcess?.gipTypes?.map((type) => (
                      <span key={type.value}>{type.value}</span>
                    ))}
                  </div>
                </div>
                <div className={classes.object_gip_process_price}>
                  <div className={classes.object_gip_process_price_text}>
                    {trimNumber(project?.gipProcess?.price)} <span>₽</span>
                    <span> в т.ч. НДС 20%</span>
                  </div>
                  <div className={classes.object_gip_process_request}>
                    <p>Заявок</p>
                    <span onClick={clickRequestCount}>{compareGip.length}</span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {project.gip && (
          <div className={classes.object_gip_have}>
            <Avatar data={data} projectViewGip />
            <GipCard data={data} projectName={project.name} />
            <div className={classes.object_gip_have_btn}>
              <>
                <Modal
                  active={modalRemove}
                  setActive={setModalRemove}
                  isLoad={isLoadRemoveGip}
                >
                  <RemoveGip />
                </Modal>
                <Modal
                  active={modalComplete}
                  setActive={setModalComplete}
                  isLoad={isLoadCompleteRemoveGip}
                >
                  {project.requestRemoveGipTask && (
                    <CompleteRemoveGip
                      removeGip={project.requestRemoveGipTask}
                    />
                  )}
                </Modal>
                <div className={classes.object_gip_have_btn_div}>
                  {project.can?.view && (
                    <Link to={`/chat/${project.chatId}`}>
                      <IconButton title="Чат" sx={sx}>
                        <ChatIcon width="22" height="24" />
                      </IconButton>
                    </Link>
                  )}
                  {project.can?.requestRemoveGip && (
                    <IconButton
                      title="Снять"
                      sx={sx}
                      onClick={() => setModalRemove(true)}
                    >
                      <DeleteButtonIcon />
                    </IconButton>
                  )}
                  {project.can?.completeRemoveGip && (
                    <IconButton
                      title="Рассмотреть"
                      sx={sx}
                      onClick={() => setModalComplete(true)}
                    >
                      <ConsiderIcon />
                    </IconButton>
                  )}
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <div></div>;
};
