import { AddSourceFiles } from "./AddSourceFiles";
import { CheckCustomerAlignForm } from "./CheckCustomerAlign";
import { CheckExecuteProcess } from "./CheckExecuteProcess";
import { CheckExpertiseRemarkForm } from "./CheckExpertiseRemark";
import { CheckSubCheckForm } from "./CheckSubCheck";
import { CheckSubCheckProcessForm } from "./CheckSubCheckProcess";
import { CheckSubTaskForm } from "./CheckSubTask";
import { Checks } from "./Checks";
import { ExpertiseCommentCreateForm } from "./ExpertiseCommentCreate";
import { ConclusionCreateForm } from "./ConclusionCreate";
import { CreateOutTaskForm } from "./CreateOutTask";
import { CustomerAlignCompleteForm } from "./CustomerAlignComplete";
import { ExecuteProcessForm } from "./ExecuteProcess";
import { ExpertiseContractForm } from "./ExpertiseContract";
import { StopProcessForm } from "./StopProcess";
import { ExpertiseRemarkForm } from "./ExpertiseRemark";
import { ModalGear } from "./ModalGear";
import { OutputTaskForm } from "./OutputTask";
import { RemarkCreateForm } from "./RemarkCreate";
import { RequestCustomerAlignCompleteForm } from "./RequestCustomerAlignComplete";
import { RequestOutTaskForm } from "./RequestOutTask";
import { SubTaskForm } from "./SubTask";
import { GroupPublicationForm, GroupRequestForm } from "./GroupPublication";
import { UpdateRemarkRowForm } from "./UpdateRemarkRow";
import { TaskComplete } from "./TaskComplete";
import { UpdateMaterialProcessForm } from "./UpdateMaterialProcess";
import { RequestEquipmentForm } from "./RequestEquipment";
import { MaterialProcessSelectRequestForm } from "./MaterialProcessSelectRequest";
import { MaterialProcessQuestionnaire } from "./MaterialProcessQuestionnaire";

const ProjectProcessModals = {
  AddSourceFiles,
  CheckCustomerAlignForm,
  CheckExecuteProcess,
  CheckExpertiseRemarkForm,
  CheckSubCheckForm,
  CheckSubCheckProcessForm,
  CheckSubTaskForm,
  Checks,
  ExpertiseCommentCreateForm,
  ConclusionCreateForm,
  CreateOutTaskForm,
  CustomerAlignCompleteForm,
  ExecuteProcessForm,
  ExpertiseContractForm,
  StopProcessForm,
  ExpertiseRemarkForm,
  ModalGear,
  OutputTaskForm,
  RemarkCreateForm,
  RequestCustomerAlignCompleteForm,
  RequestOutTaskForm,
  SubTaskForm,
  GroupPublicationForm,
  GroupRequestForm,
  UpdateRemarkRowForm,
  TaskComplete,
  UpdateMaterialProcessForm,
  RequestEquipmentForm,
  MaterialProcessSelectRequestForm,
  MaterialProcessQuestionnaire,
};

export default ProjectProcessModals;
