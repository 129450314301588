import { createSlice } from "@reduxjs/toolkit";
import { fetchExpertiseDocuments } from "../services/fetchExpertiseDocuments";
import { ExpertiseDocumentsTypes } from "../types/expertiseDocumentsTypes";

interface InitialState {
  isLoadExpertiseDocuments: boolean;
  expertiseDocuments: ExpertiseDocumentsTypes[] | undefined;
}

const initialState: InitialState = {
  isLoadExpertiseDocuments: false,
  expertiseDocuments: undefined,
};

const expertiseDocuments = createSlice({
  name: "expertiseDocuments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExpertiseDocuments.pending, (state) => {
      state.isLoadExpertiseDocuments = true;
    });
    builder.addCase(fetchExpertiseDocuments.fulfilled, (state, action) => {
      state.isLoadExpertiseDocuments = false;
      state.expertiseDocuments = action.payload;
    });
  },
});
export default expertiseDocuments;
