import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { Select } from "../../UI/components/FormComponentns/Select";
import { request } from "../../../app/services/api/requestHandler";
import {
  ApiGetSpecializations,
  ApiCreateTeam,
} from "../../../app/services/api/organisation/organisation";
import {
  selectorModalClosed,
  setModalClosed,
} from "../../../app/feature/modalClose/eventModalClose";
import { useUserWorkflow } from "../../hooks/useUserWorkflow";
import { margin } from "./SendMoneyForm";
import { palette, textFieldSX } from "../../../styles/restyle";
import { getStaff } from "../../../app/feature/dashboard/staff/thunks";
import {
  getDashboardHeader,
  TUserType,
} from "../../../app/feature/dashboard/header";
import { getDashboardDestinations } from "../../../app/feature/dashboard/destinations";
import { useAppSelector } from "../../../app/store";
import { TSelectItem } from "../../features/Forms/SetGipForm/types";

const schema = Yup.object().shape({
  teamName: Yup.string()
    .nullable()
    .required("Необходимо заполнить «Название команды»."),
});

interface CreateTeamFormProps {
  data: {
    userId: number | undefined | null;
    id: number;
    isDashboard?: boolean;
  };
}

interface OptionType {
  id: string | number;
  title: string;
}

type TCreateTeamForm = {
  teamName: string;
  specialization: TSelectItem | null;
};

export const CreateTeamForm: React.FC<CreateTeamFormProps> = ({ data }) => {
  const { id, isDashboard } = data;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [specializationList, setSpecializationList] = useState<OptionType[]>(
    []
  );

  const dispatch = useDispatch();
  const { Popup } = useUserWorkflow();
  const { enqueueSnackbar } = useSnackbar();

  const { data: headerData } = useAppSelector(getDashboardHeader);
  const { userType } = useAppSelector(getDashboardDestinations);

  const modalClosed: boolean = useSelector(selectorModalClosed);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TCreateTeamForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      teamName: "",
      specialization: null,
    },
  });

  const watchSpecialization = watch("specialization");

  const onSubmit = async (data: any) => {
    if (!data.specialization) {
      return;
    }

    setDisabled(true);
    await request(
      ApiCreateTeam(+id, +data.specialization.id, data.teamName),
      () => {
        enqueueSnackbar("Успешно", {
          variant: "success",
          autoHideDuration: 5000,
        });
        Popup.createTeam.set({});
      },
      () => () => {
        enqueueSnackbar("Возникла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();

    if (isDashboard) {
      const currentFirmId = headerData.find(
        (type: TUserType) => type.id === userType
      )?.firmId;

      dispatch(getStaff(currentFirmId));
    }

    setDisabled(false);
    dispatch(setModalClosed(!modalClosed));
  };

  useEffect(() => {
    request(
      ApiGetSpecializations(),
      (data) => {
        const preparedData = Object.keys(data).map((id: any) => ({
          id,
          title: data[id],
        }));
        setSpecializationList(preparedData);
      },
      () => () => {
        enqueueSnackbar("Возникла ошибка", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  }, []);

  useEffect(() => {
    if (specializationList.length) {
      setValue("specialization", specializationList[0]);
    }
  }, [specializationList]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(style.modalWindow, style.noPaddings)}
    >
      <TextField
        {...register("teamName")}
        name={"teamName"}
        variant="outlined"
        label="Название команды"
        size={"small"}
        autoComplete={"off"}
        error={Boolean(errors.teamName)}
        helperText={errors.teamName?.message}
        {...textFieldSX}
      />
      <br />
      <Select
        value={watchSpecialization}
        name={"specialization"}
        changeHandler={(_, value) => {
          setValue("specialization", value);
        }}
        label={"Специализация"}
        options={specializationList}
        isLoading={false}
        disableClear
      />
      <br />
      <CustomButton
        width={160}
        disabled={disabled}
        type="submit"
        background={palette.green}
        style={{ margin }}
      >
        Сохранить
      </CustomButton>
    </form>
  );
};
