import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertiseProjectList,
  getExpertiseProjectListIsLoad,
  getExpertiseProjectPartList,
  getExpertiseProjectPartListIsLoad,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseProjectList";
import { fetchExpertiseProjectList } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectList";
import { fetchExpertiseProjectPartList } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProcesses";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { ModalPreloader } from "src/components/newUI/Modal/ModalPreloader";
import cls from "../../ViewGridCard/GridCardBody/ModalUpdateRemarkRow/ModalUpdateRemarkRow.module.scss";
import { menuItemSx, palette, textFieldSX } from "src/styles/restyle";
import { SelectField } from "src/components/newUI/SelectMui/SelectMui";
import CustomButton from "../../../../../../../newUI/CustomButton/CustomButton";
import { fetchExpertiseRemarkRowCreate } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowCreate";
import { DateField } from "src/components/features/SpecialForm/DateField/DateField";

type TInputs = {
  text: string;
  comment: string;
  date_limit: any;
  project_id: number;
  project_part_ids: number[];
};

type ModalCreateRemarkRowProps = {
  closeModal: () => void;
  remarkId: number;
};

const schema = Yup.object().shape({
  text: Yup.string().required("Необходимо заполнить «Текст замечания»"),
  project_id: Yup.number()
    .typeError("Необходимо выбрать объект")
    .required("Необходимо выбрать объект"),
  date_limit: Yup.date().required("Необходимо заполнить «Контрольный срок»."),
});

export const ModalCreateRemarkRow = memo(
  ({ closeModal, remarkId }: ModalCreateRemarkRowProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    const projectList = useAppSelector(getExpertiseProjectList);
    const projectPartList = useAppSelector(getExpertiseProjectPartList);
    const isLoadProjectList = useAppSelector(getExpertiseProjectListIsLoad);
    const isLoadProjectPartList = useAppSelector(
      getExpertiseProjectPartListIsLoad
    );

    const [valueMulti, setValueMulti] = useState<string[] | number[]>([]);

    useEffect(() => {
      if (processId) {
        dispatch(fetchExpertiseProjectList(processId));
      }
    }, [processId]);

    const {
      register,
      handleSubmit,
      setValue,
      control,
      watch,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(schema),
    });

    const projectIdValue = watch("project_id");
    useEffect(() => {
      if (processId && projectIdValue) {
        dispatch(
          fetchExpertiseProjectPartList({
            projectId: projectIdValue,
            processId,
          })
        );
      }
    }, [projectIdValue]);

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        text: dataValues.text,
        comment: dataValues.comment,
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        project_id: dataValues.project_id,
        project_part_ids: dataValues.project_part_ids,
      };
      await dispatch(fetchExpertiseRemarkRowCreate({ remarkId, data }));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
      closeModal();
    };

    const onChangeMulti = (event: any) => {
      if (event.target.value.includes("all") && projectPartList) {
        const all = Object.keys(projectPartList).map(Number);
        setValueMulti(all);
        setValue("project_part_ids", all);
      } else {
        setValueMulti(event.target.value);
        setValue("project_part_ids", event.target.value);
      }
    };

    const handleClearClick = () => {
      setValueMulti([]);
      setValue("project_part_ids", []);
    };

    const mapExpertiseProjectList = projectList
      ? projectList?.map((project) => (
          <MenuItem key={project.id} sx={menuItemSx} value={project.id}>
            {project.name}
          </MenuItem>
        ))
      : null;

    if (isLoadProjectList) {
      return <ModalPreloader />;
    }

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>Добавление строки замечания</h1>
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Текст замечания"
          {...register("text")}
          placeholder="Введите текст"
          error={!!errors.text}
          helperText={errors.text ? errors.text.message : null}
        />
        <TextField
          {...textFieldSX}
          multiline
          minRows={2}
          label="Комментарий"
          {...register("comment")}
          placeholder="Введите текст"
          error={!!errors.comment}
          helperText={errors.comment ? errors.comment.message : null}
        />
        <div className={cls.dateWrapper}>
          <Controller
            control={control}
            name="date_limit"
            render={() => (
              <DateField
                variant={cls.form_date}
                label="Контрольный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
        <FormControl {...textFieldSX} className={cls.form_tasks}>
          <InputLabel id="project_id">Объект</InputLabel>
          <Select
            {...register("project_id")}
            labelId="project_id"
            label="Объект"
            error={!!errors.project_id}
          >
            {mapExpertiseProjectList}
          </Select>
          {errors.project_id && (
            <p className={cls.error}>{errors.project_id?.message}</p>
          )}
        </FormControl>
        <SelectField
          {...register("project_part_ids")}
          name="project_part_ids"
          label="Разделы"
          disabled={isLoadProjectPartList}
          multiple
          multiValue={valueMulti}
          isAllClick
          onChange={onChangeMulti}
          options={(projectPartList && Object.entries(projectPartList)) || []}
          handleClearClick={handleClearClick}
          error={
            errors.project_part_ids ? errors.project_part_ids.message : null
          }
        />
        <CustomButton
          className={cls.form_submit}
          background={palette.green}
          width={160}
          type="submit"
        >
          Сохранить
        </CustomButton>
      </form>
    );
  }
);
