import { FC, SetStateAction, Dispatch } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "../../../UI/components/FormComponentns/Select";
import style from "./style.module.scss";
import { Uploader } from "../../../features/Uploader/Uploader";
import { config } from "../../../../app.cofig";
import CustomButton from "../../CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";
import {
  dateFutureRequired,
  stringRequired,
} from "../../../../utils/SimplifiedYup";
import { DateField } from "../../../features/SpecialForm/DateField/DateField";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  createSourceComment,
  editSourceComment,
  fetchSourceComments,
} from "../../../../app/feature/ProjectView/SectionsReducer/thunks";
import {
  ApiGetCommentById,
  TCommentValues,
  formDefaultValues,
  statusOptions,
} from "./utils";
import {
  TSourceComment,
  getProjectSections,
} from "../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import useNonInitialEffect from "../../../hooks/useNonInitialEffect";

interface AddCommentModalProps {
  currentComment: TSourceComment | null;
  sourceDataId: number;
  setCommentId: Dispatch<SetStateAction<number | null>>;
  name: string;
  isEditing: boolean;
}

const validationSchema = Yup.object().shape({
  comment: stringRequired("Комментарий"),
  date_limit: dateFutureRequired("Контрольный срок"),
});

const AddCommentModal: FC<AddCommentModalProps> = ({
  sourceDataId,
  name,
  isEditing,
  currentComment,
  setCommentId,
}) => {
  const dispatch = useAppDispatch();

  const {
    error: stateError,
    pending: { editingSourceComment, creatingSourceComment },
  } = useAppSelector(getProjectSections);

  const {
    watch,
    setValue,
    handleSubmit,
    register,
    trigger,
    formState: { errors },
  } = useForm<TCommentValues>({
    defaultValues: formDefaultValues(isEditing, currentComment),
    resolver: yupResolver(validationSchema),
  });

  // @ts-ignore
  const watchStatus = watch("status");
  // @ts-ignore
  const watchDateLimit = watch("date_limit");

  const onSubmit = async (rawData: TCommentValues) => {
    const data = new FormData();

    data.append("comment", rawData.comment);
    data.append("status", `${rawData.status.id}`);
    data.append("date_limit", moment(rawData.date_limit).format("DD.MM.YYYY"));

    rawData.uploadFiles.forEach((file) => {
      data.append("uploadFiles[]", file);
    });

    await dispatch(
      isEditing
        ? editSourceComment({ id: currentComment!.id!, data })
        : createSourceComment({ id: sourceDataId, data })
    );
    await dispatch(fetchSourceComments(sourceDataId));
  };

  /*
  useEffect(() => {
    dispatch(setCheckInput(isEditing));

    return () => {
      dispatch(setCheckInput(false));
    };
  }, []);
  */

  useNonInitialEffect(() => {
    if (editingSourceComment || creatingSourceComment || stateError) {
      return;
    }

    setCommentId(null);
  }, [editingSourceComment, creatingSourceComment]);

  if (!currentComment && isEditing) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.wrapper}>
      <h2 className={style.title}>Комментарий к «{name}»</h2>
      <TextField
        {...register("comment")}
        className={style.comment}
        label="Комментарий"
        multiline
        minRows={3}
        error={Boolean(errors.comment)}
        helperText={errors.comment?.message}
      />
      <DateField
        startDateProp={watchDateLimit as Date}
        changeDateHandler={(value) => {
          setValue("date_limit", value);
          trigger("date_limit");
        }}
        label="Контрольный срок"
        variant={style.picker}
        error={Boolean(errors.date_limit)}
        helperText={errors.date_limit?.message}
      />
      <Select
        value={watchStatus}
        variant={style.status}
        options={statusOptions}
        changeHandler={(_, value) => {
          setValue("status", value);
        }}
        disableClear
        label="Статус"
      />
      <div className={style.uploader}>
        <Uploader
          autoUpload={false}
          identifier={currentComment?.id ? `${currentComment.id}` : null}
          getDataCallback={ApiGetCommentById}
          field="files"
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          fileHandler={(data: any) => {
            setValue("uploadFiles", data);
          }}
          canBeEdited
          uploadAsActive
        />
      </div>
      <CustomButton
        width={120}
        type="submit"
        disabled={editingSourceComment || creatingSourceComment}
        background={palette.blue}
        className={style.saveButton}
      >
        Сохранить
      </CustomButton>
    </form>
  );
};

export default AddCommentModal;
