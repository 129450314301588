import { ReactNode } from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ApiActuallyProject,
  ApiCompleteSubTask,
  ApiDeleteProject,
  ApiGipHelperApplicateInvite,
  ApiGipHelperDelegate,
  ApiGipHelperInvite,
  ApiGipHelperList,
  ApiGipHelperRemove,
  ApiProjectCancelSearchGip,
  ApiProjectPause,
  ApiProjectUnPause,
  ApiRecalculationsRelations,
  ApiRemoveGipRequest,
  ApiRequestGipProject,
  ApiRequestRemoveGip,
  ApiRequestRemoveGipStart,
  ApiSearchGip,
  ApiSubTaskBackToWork,
} from "src/app/services/api/project/project";
import { ApiExecutorTypesFull } from "src/types/api/responseTypes/apiProjectType";
import { getErrorMessage, request } from "src/app/services/api/requestHandler";
import { pushError, pushMessage } from "src/app/feature/errorTrace";

export interface IProjectParams {
  id: number;
  value: number;
  parameter_code: number;
  type: { name: string };
}

export type TTabsItem = {
  title: string;
  name: string;
  icon: string;
  show: boolean;
  dot: boolean;
  stop?: boolean;
  items: Record<string, TTabsItem> | TTabsItem[];
};

export type TTabsList = {
  admin: TTabsItem;
  applications: TTabsItem;
  checklist: TTabsItem;
  checks: TTabsItem;
  compareGip: TTabsItem;
  composition: TTabsItem;
  documents: TTabsItem;
  graph: TTabsItem;
  history: TTabsItem;
  info: TTabsItem;
  objects: TTabsItem;
  processes: TTabsItem;
  sourceData: TTabsItem;
  stages: TTabsItem;
  tasks: TTabsItem;
  sourceFiles?: TTabsItem;
  exchangeTask?: TTabsItem;
  executionTask?: TTabsItem;
  expertiseInTasks?: TTabsItem;
  checkTasks?: TTabsItem;
  materials?: TTabsItem;
  works?: TTabsItem;
  removed?: TTabsItem;
  // Todo удалить как будут удалены пункты входящие и исходящие
  tasksIn?: TTabsItem;
  tasksOut?: TTabsItem;
};

export type TTabsListComponent = {
  [K in keyof TTabsList]?: ReactNode;
};

export type DesignTypeValue = "2D" | "3D" | "BIM";

export type TProjectView = {
  author: {
    name: string;
    job_duration: number;
    rating: {
      id: number;
      customer: number;
    };
    surname: string;
    patronymic: string;
    id: number;
    photo: string;
  };
  authorPremium: any;
  authorAccess: {
    access_code: number;
    id: number;
    user_id: string;
    user_type_id: string;
  };
  advance_pay: number;
  pay1: number;
  pay2: number;
  pay_limit: number;
  chatId: number;
  location: string;
  resultFilesSize: string[];
  processPublishedCost: string;
  gip: {
    id: number;
    name: string;
    surname: string;
    patronymic: string;
    photo: string;
    job_duration: number;
    rating: {
      id: number;
      gip: number;
    };
  };
  gipAccess: {
    access_code: number;
    id: number;
    userType: {
      listName: string;
      type: number;
      firmId: number;
    };
    user_id: string;
    user_type_id: string;
  }[];
  gipProcess: {
    price: string;
    gipTypes: ApiExecutorTypesFull;
    status: { key: number };
  };
  gipHelpers: {
    name: string;
    surname: string;
    patronymic: string;
    id: string;
    delegate: boolean;
    accessId: number;
  }[];
  gipHelpersApplication: {
    can: {
      delete: boolean;
      accept: boolean;
    };
    fio: string;
    userId: number;
    taskId: number;
  }[];
  date_start: string;
  date_end: string;
  accountId: number;
  code: string;
  parent_id: number;
  parentName: string;
  bank_account_id: number;
  name_for_doc: string;
  id: string;
  status: { value: string; key: number };
  can: {
    delete: boolean;
    startSearchGip: boolean;
    cancelSearchGip: boolean;
    deleteRequestGip: boolean;
    downloadDocs: boolean;
    downloadSourceDocs: boolean;
    inviteGipHelpers: boolean;
    removeGipHelpers: boolean;
    edit: boolean;
    isAdmin: boolean;
    requestGip: boolean;
    requestRemoveGip: boolean;
    update: boolean;
    view: boolean;
    viewDocs: boolean;
    completeRemoveGip: boolean;
    editByGipHelper: boolean;
    updateGanttData: boolean;
    updateSourceData: boolean;
  };
  requestRemoveGipTask: {
    id: number;
    content: string;
  };
  expertise: { title: string; partId: number; num: string }[];
  designType: {
    value: DesignTypeValue;
  };
  authorType: {
    listName: string;
    type: number;
    title: string;
    typeName: string;
    id: number;
  };
  name: string;
  constructionType: { name: string };
  buildingFunction: { name: string; type: number; id: number };
  region: {
    title: string;
    code: number;
    country: { code: number };
    isSeismic: boolean;
  };
  projectParameters: IProjectParams[];
  description: string;
  files: { name: string; url: string }[];
  seismicity: { a: string; b: string; c: string } | null;
  tabs: TTabsList;
};

type TCompleteSubTask = {
  taskId: number;
  comment: string;
  content: string;
  status: number;
  isProceeded: boolean;
};

interface GipSearchParams {
  gip: {
    id: string;
    adminDiv: boolean;
    data: {
      price: number;
      gip_type: number[] | string[];
    };
  };
}

interface GipRemoveCompleteParams {
  gip: {
    id: number;
    data: {
      comment: string;
      status: number;
    };
  };
}

interface GipRemoveParams {
  gip: {
    id: string | number;
    data: {
      StartForm: {
        content: string;
      };
    };
  };
}

export interface GipHelperListData {
  name: string;
  id: number;
}

interface GipHelperInviteParams {
  invite: {
    id: string;
    data: any;
  };
}

interface GipHelperDeleteParams {
  deleteGip: {
    projectId: string;
    userId: string | number;
    active: boolean;
  };
}

export const fetchProjectView = createAsyncThunk<TProjectView, string | number>(
  "projectView/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiActuallyProject(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectDelete = createAsyncThunk<TProjectView, number>(
  "fetchProjectDelete/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiDeleteProject(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectPause = createAsyncThunk<boolean, number>(
  "fetchProjectPause/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiProjectPause(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectUnPause = createAsyncThunk<boolean, number>(
  "fetchProjectUnPause/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiProjectUnPause(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchRecalculationsRelations = createAsyncThunk<boolean, number>(
  "fetchRecalculationsRelations/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiRecalculationsRelations(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectViewSearchGip = createAsyncThunk<TProjectView, string>(
  "fetchProjectViewSearchGip/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiActuallyProject(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipHelpersList = createAsyncThunk<
  GipHelperListData[],
  number
>("gipHelpersListFetch/Fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiGipHelperList(id);
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    return result;
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchGipHelperInvite = createAsyncThunk<
  boolean,
  GipHelperInviteParams
>("gipHelperInviteFetch/Fetch", async ({ invite }, { rejectWithValue }) => {
  const { id, data } = invite;
  try {
    const response = await ApiGipHelperInvite(id, data);
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    return result;
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchGipHelperApplicateInvite = createAsyncThunk<
  boolean,
  { id: number; status: number }
>(
  "fetchGipHelperApplicateInvite/Fetch",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await ApiGipHelperApplicateInvite(id, status);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipHelperDelete = createAsyncThunk<
  boolean,
  GipHelperDeleteParams
>("fetchGipHelperDelete/Fetch", async ({ deleteGip }, { rejectWithValue }) => {
  const { projectId, userId, active } = deleteGip;
  try {
    const response = await ApiGipHelperRemove(projectId, userId, active);
    const result = await response.json();
    if (!response.ok) {
      throw result;
    }
    return result;
  } catch (err: any) {
    const error = getErrorMessage(err);
    return rejectWithValue(error);
  }
});

export const fetchGipHelperApplicationDelete = createAsyncThunk<
  boolean,
  GipHelperDeleteParams
>(
  "fetchGipHelperApplicationDelete/Fetch",
  async ({ deleteGip }, { rejectWithValue }) => {
    const { projectId, userId, active } = deleteGip;
    try {
      const response = await ApiGipHelperRemove(projectId, userId, active);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

interface IGipHelperDelegateProps {
  accessId: number;
  delegate: boolean;
}
export const fetchGipHelperDelegate = createAsyncThunk<
  boolean,
  IGipHelperDelegateProps
>(
  "fetchGipHelperDelegate/Fetch",
  async ({ accessId, delegate }, { rejectWithValue }) => {
    try {
      const response = await ApiGipHelperDelegate(accessId, delegate);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipSearch = createAsyncThunk<boolean, GipSearchParams>(
  // Поиск Гипа
  "projectGipSearch/fetch",
  async ({ gip }, { rejectWithValue }) => {
    try {
      const { id, data, adminDiv } = gip;
      const response = await ApiSearchGip(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return adminDiv;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipCancelSearch = createAsyncThunk<boolean, string>(
  // Отменить поиск Гипа
  "projectGipCancel/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiProjectCancelSearchGip(id);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipRequest = createAsyncThunk<boolean, any>(
  // Заявиться
  "projectGipRequest/fetch",
  async ({ gip }, { rejectWithValue }) => {
    const { id, data } = gip;
    try {
      const response = await ApiRequestGipProject(id, data);
      if (!response.ok) {
        throw await response.json();
      }
      return await response.json();
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipRemoveRequest = createAsyncThunk<boolean, string>(
  // Отозвать
  "projectGipRemoveRequest/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiRemoveGipRequest(id);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipRemove = createAsyncThunk<boolean, GipRemoveParams>(
  // Снять
  "projectGipRemove/fetch",
  async ({ gip }, { rejectWithValue }) => {
    try {
      const { id, data } = gip;
      const response = await ApiRequestRemoveGipStart(data, id);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const fetchGipRemoveComplete = createAsyncThunk<
  boolean,
  GipRemoveCompleteParams
>(
  // Рассмотреть
  "projectGipRemoveComplete/fetch",
  async ({ gip }, { rejectWithValue }) => {
    try {
      const { id, data } = gip;
      const response = await ApiRequestRemoveGip(data, id);
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      return result;
    } catch (err: any) {
      const error = getErrorMessage(err);
      return rejectWithValue(error);
    }
  }
);

export const completeSubTask = createAsyncThunk(
  "projectSubTaskComplete/fetch",
  async (
    { status, taskId, comment, content, isProceeded }: TCompleteSubTask,
    { dispatch }
  ) => {
    const CompleteForm: Record<string, string | number> = {
      status,
    };

    if (isProceeded) {
      CompleteForm.content = content;
    } else {
      CompleteForm.comment = comment;
    }

    await request(
      ApiCompleteSubTask(taskId, { CompleteForm }),
      () => {
        dispatch(pushMessage("Успешно!"));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);

export const taskBackToWork = createAsyncThunk(
  "projectSubTaskBackToWork/fetch",
  async (id: number, { dispatch }) => {
    await request(
      ApiSubTaskBackToWork(id),
      () => {
        dispatch(pushMessage("Успешно!"));
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
  }
);
