import { useForm } from "react-hook-form";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskCompleteMutation } from "src/FSD/entities/task/api";
import { useProcessWorkViewQuery } from "src/FSD/entities/processWork/api";
import { responseAgreement } from "src/FSD/features/v2/SelectAgreement";
import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Props as UseModalsProps } from "../../ui/RequestRemoveExecutorCompleteForm";
import { Nullable } from "src/types/generics";

type TFieldValues = {
  status: Nullable<Statuses>;
  withoutFeedback: TSelectItem;
  comment: string;
};

const defaultValues = {
  status: null,
  withoutFeedback: responseAgreement[0],
  comment: "",
};

export const useRequestRemoveExecutorComplete = ({
  id,
  type,
}: UseModalsProps) => {
  const { handleClose } = useModalContext();

  const form = useForm<TFieldValues>({
    defaultValues,
  });

  const accessCodeQueries = {
    ProjectProcess: useProjectProcessViewQuery,
    ProcessWork: useProcessWorkViewQuery,
  };

  const { data: viewData, isFetching } = accessCodeQueries[type]({
    id,
  });

  const [completeTask, { isLoading }] = useTaskCompleteMutation();

  const onSubmit = async (dataValues: TFieldValues) => {
    const task = {
      CompleteForm: {
        status: dataValues.status,
        withoutFeedback: Boolean(dataValues.withoutFeedback.id),
        comment: dataValues.comment,
      },
    };

    const taskId = viewData!.activeAccess.task_remove_id!;

    const result = await completeTask({ id: taskId, task });
    // @ts-ignore
    if (!result?.error) {
      handleClose();
    }
  };

  const isViewDataLoading = isFetching || !viewData;

  return { form, isLoading, onSubmit, viewData, isViewDataLoading } as const;
};
