import cn from "classnames";
import { Save } from "react-bootstrap-icons";
import { Button } from "src/FSD/shared/uiKit/v2";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { PopoverPopupState } from "src/FSD/shared/uiKit/v2/Popover";
import { Fio } from "./Fio";
import { Price } from "./Price";
import { Status } from "./Status";
import styles from "./Tasks.module.scss";
import { Actions } from "./Actions";
import { useTask } from "../../lib/hooks/useTask";

type TProps = {
  materialId: number;
  task: ApiTaskResponses.View;
};

export const Task = ({ materialId, task }: TProps) => {
  const {
    handleBackToWorkTask,
    price,
    dateDeadline,
    filesLink,
    outFilesLength,
    popoverRef,
  } = useTask({
    task,
  });

  return (
    <li className={styles.tasksBlock__item}>
      <Fio task={task} />
      <Price price={price} dateDeadline={dateDeadline} />
      <Status task={task} />
      <div className={cn(styles.tasksBlock__content, styles.tasksBlock__gap)}>
        <Button
          color="secondary"
          variant="outlined"
          borderRadius={20}
          disabled={!outFilesLength}
          endIcon={<Save />}
          target="_blank"
          href={filesLink}
          className={styles.tasksBlock__filesBtn}
        >
          Вложение ({outFilesLength})
        </Button>
        <Button
          color="success"
          variant="outlined"
          borderRadius={20}
          disabled={!task.comment}
          onClick={(e) => popoverRef.current?.openPopover(e)}
          className={styles.tasksBlock__commentBtn}
        >
          Комментарий
        </Button>

        <PopoverPopupState
          ref={popoverRef}
          title="Комментарий"
          content={task.comment}
        />
      </div>
      <Actions
        task={task}
        handleBackToWorkTask={handleBackToWorkTask}
        materialId={materialId}
      />
    </li>
  );
};
