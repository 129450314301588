import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "src/types/generics";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useRemarkCreateMutation } from "src/FSD/entities/expertise/remark/api";
import { useEffect } from "react";
import { useProjectProcessToProjectsQuery } from "src/FSD/entities/projectProcess/api";
import { remarkCreateSchema } from "../schemas/remarkCreate";

type TProps = {
  processId: number;
  projectId: number;
};

type TInputs = {
  project_id?: TSelectItem;
  date_create: Nullable<Date>;
  parseSpreadsheet: boolean;
  files: TFile[];
};

export const useRemarkCreate = ({ processId, projectId }: TProps) => {
  const { data: projectList, isFetching } = useProjectProcessToProjectsQuery({
    processId,
  });
  const isLoadingToProjects = !projectList || isFetching;
  const [remarkCreate, { isLoading: isLoadingRemarkCreate }] =
    useRemarkCreateMutation();

  const targetObject = projectList?.find(
    (item: TSelectItem) => item.id === projectId
  );

  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(remarkCreateSchema),
  });

  const checkImport = watch("parseSpreadsheet");
  useEffect(() => {
    if (processId && checkImport) {
      setValue("project_id", targetObject);
    }
  }, [processId, checkImport]);

  const formSubmitHandler = async (dataValues: TInputs) => {
    const formData = new FormData();
    formData.append("projectId", String(dataValues.project_id?.id));
    formData.append(
      "date_create",
      String(toServerDate(dataValues.date_create))
    );
    formData.append(
      "parseSpreadsheet",
      dataValues.parseSpreadsheet ? "1" : "0"
    );
    dataValues.files.forEach(({ file }) => {
      formData.append("upload_files[]", file!);
    });

    if (processId) {
      await remarkCreate({ processId, data: formData })
        .unwrap()
        .then(() => {
          handleClose();
        });
    }
  };

  const onSubmit = handleSubmit(formSubmitHandler);

  return {
    control,
    errors,
    projectList,
    onSubmit,
    checkImport,
    isLoadingRemarkCreate,
    isLoadingToProjects,
  };
};
