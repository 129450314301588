import { Controller } from "react-hook-form";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import styles from "./AdditionalRequirements.module.scss";
import { Paragraph } from "src/FSD/shared/uiKit/v2";

export type TValuesAdditionalRequirements = {
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  bim: boolean;
};

type Props = {
  control: any;
  valuesAdditionalRequirements: TValuesAdditionalRequirements;
  title?: string;
  className?: string;
};

export const AdditionalRequirements = ({
  control,
  valuesAdditionalRequirements,
  title = "",
  className = "",
}: Props) => {
  const { vor, vorPnr, kac, bim } = valuesAdditionalRequirements;

  return (
    <div className={className}>
      {title && (
        <Paragraph color="light" className={styles.title}>
          {title}
        </Paragraph>
      )}
      <div className={styles.additionalRequirements}>
        {vor && (
          <Controller
            control={control}
            name="vor"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Ведомость объемов работ"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
        {kac && (
          <Controller
            control={control}
            name="kac"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Конъюнктурный анализ цен"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
        {vorPnr && (
          <Controller
            control={control}
            name="vorPnr"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="ВОР на пусконаладочные работы"
                onChange={onChange}
                value={value}
                checked={!!value}
                editing
              />
            )}
          />
        )}
        {bim && (
          <Controller
            control={control}
            name="bim"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="BIM"
                onChange={onChange}
                value={value}
                checked={value}
                editing
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
