import { Paragraph } from "src/FSD/shared/uiKit/v2";

type TProps = {
  statusText: string;
  badReasons: string[];
};

export const Correspondence = ({ statusText, badReasons }: TProps) => (
  <div>
    <Paragraph color="light">{statusText}</Paragraph>
    <div>
      {badReasons.map((reason) => (
        <Paragraph fontSize={14}>{reason}</Paragraph>
      ))}
    </div>
  </div>
);
