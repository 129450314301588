import { useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import {
  getProjectSections,
  setErrorNull,
  setOpenAllNode,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { trimPrice } from "src/utils/trimNumber";
import { fetchProjectView } from "src/app/feature/ProjectView/thunks";
import {
  fetchProjectSectionsTree,
  fetchProjectSectionsTreeDuplicate,
} from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { useAppSelector } from "src/app/store";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import PublishedObjectModals from "src/FSD/widgets/v2/publishedObject/modals";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import SectionsTree from "./SectionsTree/Tree";
import styles from "./Sections.module.scss";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import ProcessRequest from "./ProcessRequest/ProcessRequest";
import gstyles from "./ProcessPublication/style.module.scss";
import {
  getGroupPublishProcessData,
  setClosed as setPublishingClosed,
} from "../../../../../app/feature/project/projectProcessPublication";
import useNonInitialEffect from "../../../../hooks/useNonInitialEffect";
import {
  getGroupRequestProcessData,
  setClosed as setRequestClosed,
} from "../../../../../app/feature/project/projectProcessRequest";
import { ScrollWrap } from "../../../../newUI/ScrollWrap/ScrollWrap";
import { SectionsSkeletonFullPage } from "./SkeletonSections";
import { useIsMobile } from "../../../../hooks/useIsMobile";

export const expand = [
  "can",
  "countAccesses",
  "activeAccess.user",
  "activeAccess.userType",
  "activeAccess.job_duration",
  "activeAccess.price",
  "haveExpertise",
  "executorNds",
  "stopped",
  "countProcessWorks",
  "partGroup",
];

interface SectionsProps {
  cost?: string;
}

const Sections = ({ cost = "0" }: SectionsProps) => {
  const {
    sections,
    isLoad,
    error,
    isAllToggle,
    pending: { duplicateTree },
  } = useSelector(getProjectSections);
  const { publish } = useSelector(getProjectSections);
  const { project } = useAppSelector(getProjectViewData);
  const isTablet = useIsMobile(1600);

  const [getProjectProcessTree, { data: projectProcessTreeRaw }] =
    projectProcessApi.useLazyProjectProcessGetProjectProcessTreeQuery();

  const fetchGetProjectProcessTree = async () => {
    await getProjectProcessTree({
      id: Number(project!.id),
      expand,
    });
  };

  useEffect(() => {
    if (project) {
      fetchGetProjectProcessTree();
    }
  }, [project]);

  const projectProcessTree = useDataMapper(
    projectProcessTreeRaw!,
    Object.values
  );

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<{ id: string }>();

  const {
    data: {
      isOpen: isPublicationOpen,
      groupName: groupPublicationName,
      processId: publicationProcessId,
    },
    pending: { publishing: publishingGroupProcess }, // для групповой публикации разделов
  } = useAppSelector(getGroupPublishProcessData);

  const {
    data: {
      isOpen: isRequestOpen,
      groupName: groupRequestName,
      processId: requestProcessId,
    },
    pending: { publishing: publishingGroupRequest },
  } = useAppSelector(getGroupRequestProcessData);

  useEffect(() => {
    if (params.id && !projectProcessTree && !sections) {
      dispatch(fetchProjectView(params.id));
      dispatch(fetchProjectSectionsTree(+params.id));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (publish && params.id) {
      dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
      fetchGetProjectProcessTree();
    }
  }, [publish]);

  useNonInitialEffect(() => {
    if (publishingGroupProcess || publishingGroupRequest || !params.id) {
      return;
    }

    // dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
    fetchGetProjectProcessTree();
  }, [publishingGroupProcess, publishingGroupRequest]);

  const handleClick = () => {
    dispatch(setOpenAllNode());
  };

  const handleClosePublishingModal = () => {
    dispatch(setPublishingClosed());
  };

  const handleCloseApplicationModal = () => {
    dispatch(setRequestClosed());
  };

  const isPending = !projectProcessTree || duplicateTree || isLoad;

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setErrorNull());
  }

  const { name, handleCloseModal, restData } = useModal();

  return (
    <>
      <div className={styles.wrapper}>
        {isPending ? (
          <SectionsSkeletonFullPage />
        ) : (
          <>
            {cost === "Договорная" ? (
              <div />
            ) : (
              <div className={styles.header}>
                <h1>Разделы</h1>
                <div className={styles.header_price}>
                  <p className={styles.header_num}>{trimPrice(cost)}</p>
                  <p className={styles.header_subtext}>
                    Опубликовано без налога (НДС)
                  </p>
                </div>
              </div>
            )}
            <ScrollWrap height={isTablet ? "auto" : "884px"}>
              <SectionsTree
                data={projectProcessTree!}
                fetchGetProjectProcessTree={fetchGetProjectProcessTree}
              />
              <AllToggleWidget
                isAllToggle={isAllToggle}
                setIsAllToggle={handleClick}
              />
            </ScrollWrap>
          </>
        )}
      </div>
      <Modal
        isActive={isPublicationOpen}
        handleClose={handleClosePublishingModal}
      >
        <ProjectProcessModals.GroupPublicationForm
          processId={publicationProcessId}
          groupName={groupPublicationName}
        />
      </Modal>
      <Modal isActive={isRequestOpen} handleClose={handleCloseApplicationModal}>
        <ProjectProcessModals.GroupRequestForm
          processId={requestProcessId}
          groupName={groupRequestName}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.PublishObjectForm
          id={restData.id}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.UpdatePublishedObjectForm
          id={restData.id}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>

      {/*
      <ModalWrapper
        handleCloseModal={handleClosePublishingModal}
        show={isPublicationOpen}
        head={
          <div className={cn(gstyles.wrapper, gstyles.topWrapper)}>
            <h2>Групповая публикация разделов «{groupPublishName}»</h2>
            <p>{project?.name}</p>
          </div>
        }
      >
        {isPublicationOpen && <ProcessPublication />}
      </ModalWrapper>
      <ModalWrapper
        handleCloseModal={handleCloseRequestModal}
        show={isRequestOpen}
        head={
          <div className={cn(gstyles.wrapper, gstyles.topWrapper)}>
            <h2>Заявка на разделы «{groupApplicationName}»</h2>
            <p>{project?.name}</p>
          </div>
        }
      >
        {isRequestOpen && <ProcessRequest />}
      </ModalWrapper>
      */}
    </>
  );
};

export default Sections;
