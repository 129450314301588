import { memo, useEffect, useState } from "react";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { Select, TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import {
  useLazyProjectProcessToProcessesQuery,
  useProjectProcessToProjectsQuery,
} from "src/FSD/entities/projectProcess/api";
import {
  arrayToSelect,
  toSelect,
} from "src/app/feature/project/projectProcessPublication";
import { Nullable } from "src/types/generics";

type TProps = {
  processId: number;
  onProjectChange?: (newValue: TSelectItem) => void;
  onProcessesChange?: (newValue: TSelectItem[]) => void;
  errors?: any;
  projectFieldName?: string;
  processesFieldName?: string;
  projectFieldLabel?: string;
  processesFieldLabel?: string;
};

export const SelectReviewProcesses = memo(
  ({
    processId,
    onProjectChange,
    onProcessesChange,
    errors,
    projectFieldName = "projectId",
    processesFieldName = "processesIds",
    projectFieldLabel = "Проект",
    processesFieldLabel = "Проверяющие разделы",
  }: TProps) => {
    const [project, setProject] = useState<Nullable<TSelectItem>>(null);

    const [processes, setProcesses] = useState<TSelectItem[]>([]);

    const { data: projectsRaw, isFetching: isProjectsFetching } =
      useProjectProcessToProjectsQuery({ processId });

    const [
      getProjectProcessToProcesses,
      { data: projectProcessesRaw, isFetching: isProcessesFetching },
    ] = useLazyProjectProcessToProcessesQuery();

    useEffect(() => {
      if (!project) {
        return;
      }
      getProjectProcessToProcesses({
        processId,
        projectId: project.id as number,
        all: true,
      });
    }, [project?.id]);

    const projectProcessesList = useDataMapper(projectProcessesRaw, toSelect);

    const projectsList = useDataMapper(projectsRaw, arrayToSelect);

    const isProjectsLoading = isProjectsFetching || !projectsList;

    const isProcessesLoading = isProcessesFetching || !projectProcessesList;

    return (
      <>
        <Select
          value={project}
          changeHandler={(_, newValue: TSelectItem) => {
            setProject(newValue);
            onProjectChange?.(newValue);
            if (project?.id !== newValue.id) {
              setProcesses([]);
              onProcessesChange?.([]);
            }
          }}
          label={projectFieldLabel}
          options={projectsList}
          disableClear
          isLoading={isProjectsLoading}
          error={Boolean(errors?.[projectFieldName])}
          helperText={errors?.[projectFieldName]?.message}
        />
        <SelectMultiple
          value={processes}
          changeHandler={(newValue) => {
            setProcesses(newValue);
            onProcessesChange?.(newValue);
          }}
          label={processesFieldLabel}
          options={projectProcessesList}
          disabled={isProjectsLoading}
          isLoading={isProcessesLoading || isProjectsLoading}
          showSelectAll
          error={Boolean(errors?.[processesFieldName])}
          helperText={errors?.[processesFieldName]?.message}
        />
      </>
    );
  }
);
