import React from "react";
import classNames from "classnames";
import { Logo } from "../Logo/Logo";
import { Text } from "../Text/Text";
import { TextHeading } from "../TextHeading/TextHeading";
import style from "./style.module.scss";
import FSILogo from "../../../../media/images/FSI_logo.png";

export interface FooterSectionInfoProps {
  variant?: string;
}

export const FooterSectionInfo: React.FC<FooterSectionInfoProps> = React.memo(
  ({ variant = "" }) => {
    return (
      <section className={classNames(style.section, variant)}>
        <Logo link="/" variant={style.sectionInfoLogo} />

        <div className={style.info}>
          <TextHeading
            variant={style.heading}
          >{`Проекты </br> и проектировщики`}</TextHeading>
          <Text variant={style.text}>
            Привлекайте для решения конкретной проектной задачи свободных
            исполнителей с различным опытом работы, из всех уголков нашей
            необъятной Родины.
          </Text>
          <div className={style.imgWrap}>
            <p>{"Работа проводится при \n финансовой поддержке"}</p>
            <img
              src={FSILogo}
              alt="Фонд содействия инновациям"
              title="Фонд содействия инновациям"
            />
          </div>
        </div>
      </section>
    );
  }
);
