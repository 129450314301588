import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiMaterialProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  processId: number;
}>;

export const getProviderFirms = (builder: QueryBuilder) =>
  builder.query<ApiMaterialProcessResponses.GetProviderFirms[], TParams>({
    query: (params) => ({
      url: `v1/material-process/get-provider-firms`,
      params: {
        processId: params.processId,
        expand: concatenateParams(params.expand),
      },
    }),
    providesTags: [ApiTags.MaterialProcess.GET_PROVIDER_FIRMS],
  });
