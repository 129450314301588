import cn from "classnames";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./Tasks.module.scss";

type TProps = {
  task: ApiTaskResponses.View;
};

export const Fio = ({ task }: TProps) => {
  const executorFio = `${task.executor.surname} ${task.executor.name} ${task.executor.patronymic}`;

  return (
    <div className={cn(styles.tasksBlock__content, styles.tasksBlock__basis)}>
      <Paragraph>Предложение от:</Paragraph>
      <Paragraph
        fontSize={20}
        color="light"
        fontWeight="bold"
        className={styles.tasksBlock__userType}
      >
        {task.userType ?? "-"}
      </Paragraph>
      <Paragraph fontSize={18} color="light">
        {executorFio}
      </Paragraph>
    </div>
  );
};
