import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { ApiTags } from "src/FSD/shared/api/tags";
import { checkSubCheckProcessSchema } from "../schemas/checkSubCheckProcess";
import { useAppDispatch } from "src/app/store";
import { pushError } from "src/app/feature/errorTrace";
import { Nullable } from "src/types/generics";

interface TFieldValues {
  comment: string;
  status: Nullable<Statuses>;
}

interface UseModalProps {
  taskId: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
}

export const useCheckSubCheckProcess = ({
  taskId,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(checkSubCheckProcessSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["parent.files"],
  });

  const status = watch("status");

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      comment: data.comment,
      status: data.status!,
    };

    await dispatch(
      TaskApi.complete({
        data: { id: taskId, formedData },
        events: {
          onFulfilled: () => {
            handleClose();

            dispatch(
              rootApi.util.invalidateTags([
                ApiTags.ProjectProcess.GET_SUB_CHECK_TASKS,
              ])
            );
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  const isRejecting = isSubmitting && status === Statuses.REJECT;
  const isAccepting = isSubmitting && status === Statuses.ACCEPT;

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isRejecting,
    isAccepting,
    isTaskViewPending: isFetching || !taskData,
    taskData,
  } as const;
};
