import React, { memo } from "react";
import { IconButton } from "@mui/material";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { handleRequest } from "src/app/feature/dashboard/staff/thunks";
import { getConstants } from "src/app/feature/constantValues/slice";
import { DarkCheckSVG, DarkClearSVG } from "src/components/newUI/SVG";
import globalStyle from "src/components/pages/User/Dashboard/Primary/style.module.scss";
import style from "../style.module.scss";

const Group = ({ isRequest, member, short }: any) => {
  const { roles } = useAppSelector(getConstants);
  const dispatch = useAppDispatch();

  if (!member) {
    return null;
  }

  const nameElement = (
    <div className={cn(style.code, isRequest && style.longRequest)}>
      {isRequest && <div className={cn(globalStyle.red, globalStyle.orange)} />}
      <h1>{member.name}</h1>
    </div>
  );

  const handleAcceptRequest = () => {
    dispatch(handleRequest({ accepted: true, id: member.id }));
  };

  const handleDismissRequest = () => {
    dispatch(handleRequest({ accepted: false, id: member.id }));
  };

  if (isRequest) {
    return (
      <div
        className={cn(
          style.section,
          style.group,
          style.extraPadding,
          style.inviteRequest
        )}
      >
        {nameElement}
        <div className={style.staffFate}>
          <IconButton onClick={handleAcceptRequest}>
            <DarkCheckSVG />
          </IconButton>
          <IconButton onClick={handleDismissRequest}>
            <DarkClearSVG />
          </IconButton>
        </div>
      </div>
    );
  }

  const role = <h2 className={style.role}>{roles[member.role_id]}</h2>;

  const specElement = (
    <div className={style.spec}>
      {member?.skills?.map((skill: any) => <p>{skill.name}</p>)}
    </div>
  );

  return (
    <div className={cn(style.section, style.group)}>
      {nameElement}
      {specElement}
      {role}
    </div>
  );
};

export default memo(Group);
