import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCreateDocument } from "src/app/services/api/projectProcess/ExpertiseApi/expertiseHeaderBtn";
import { pushError } from "../../../../errorTrace";
import { fetchExpertiseDocuments } from "../../ExpertiseDocuments/services/fetchExpertiseDocuments";

export const fetchExpertiseCreateDocument = createAsyncThunk<
  boolean,
  any,
  { rejectValue: { status: string; message: string } }
>(
  "expertise/fetchExpertiseCreateDocument",
  async ({ processId, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiCreateDocument(processId, formData);
      if (!response.ok) {
        throw await response.json();
      }
      dispatch(fetchExpertiseDocuments(processId));
      return await response.json();
    } catch (err: any) {
      if (err.status === 422) {
        return rejectWithValue({ status: "422", message: err.message });
      }
      dispatch(pushError(err));
    }
  }
);
