import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { TextField } from "@mui/material";
import { palette, textFieldSX } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  deleteAnchor,
  getAnchorParams,
  getClassList,
  getMethodList,
  saveAnchor,
} from "src/app/feature/milestone/thunks";
import { getMilestone, clearParams } from "src/app/feature/milestone";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { agreementOptions } from "../../../../Project/View/Sections/ProcessPublication/Requirements";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { Select } from "../../../../../UI/components/FormComponentns/Select";
import style from "./style.module.scss";

type TFormValues = Record<string, any>;

const schema = Yup.object().shape({
  name: Yup.string().required("Необходимо заполнить наименование"),
});

interface AnchorProps {
  stage: any;
}

const Anchor = ({ stage }: AnchorProps) => {
  const dispatch = useAppDispatch();
  const [lastParamsByClass, setLastParamsByClass] = useState<
    Record<string, any>
  >({});

  const [hints, setHints] = useState<Record<string, string>>({
    className: "",
    methodName: "",
  });

  const {
    data: { classList, methodList, params: paramsByClass },
    pending,
  } = useAppSelector(getMilestone);

  const isNew = !stage || !stage?.stageAnchor;

  const initialParams =
    !stage?.stageAnchor || Array.isArray(stage?.stageAnchor.params)
      ? {}
      : stage.stageAnchor.params;

  const defaultValues = useMemo(() => {
    if (isNew) {
      return {
        name: stage.name,
        class: null,
        method: null,
        description: "",
      };
    }

    const { stageAnchor } = stage;

    // для дополнительных булевых значений
    const extraParams = Object.entries(initialParams).reduce(
      (result: Record<string, TSelectItem>, [field, data]: any) => {
        const isYes = Number(data.value);
        return { ...result, [field]: agreementOptions[isYes] };
      },
      {}
    );

    return {
      ...extraParams,
      name: stage.name,
      description: stageAnchor.description ?? "",
      class: {
        id: stageAnchor.class.value,
        title: stageAnchor.class.name,
      },
      method: {
        id: stageAnchor.method.value,
        title: stageAnchor.method.name,
      },
    };
  }, [stage]);

  const {
    setValue,
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TFormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues,
  });

  const watchFields = watch();

  const findAndSetHints = () => {
    const classHint =
      classList.find((item: any) => item.id === watchFields.class?.id)?.hint ??
      "";

    const methodHint =
      methodList.find((item: any) => item.id === watchFields.method?.id)
        ?.hint ?? "";

    setHints({
      className: classHint,
      methodName: methodHint,
    });
  };

  const handleDeleteAnchor = () => {
    if (
      !window.confirm("Вы действительно хотите перевести на ручное управление?")
    ) {
      return;
    }

    dispatch(deleteAnchor(stage.stageAnchor?.id));
  };

  const onSubmit = (data: TFormValues) => {
    dispatch(
      saveAnchor({
        id: stage.id,
        data,
        isNew,
        anchorId: stage?.stageAnchor?.id,
      })
    );
  };

  useEffect(() => {
    setLastParamsByClass(initialParams);

    dispatch(getClassList());
    dispatch(getMethodList());

    return () => {
      dispatch(clearParams());
    };
  }, []);

  useEffect(() => {
    if (pending.getClassList || pending.getMethodList) {
      return;
    }

    if (isNew) {
      if (classList.length) {
        setValue("class", classList[0]);
      }

      if (methodList.length) {
        setValue("method", methodList[0]);
      }

      return;
    }

    findAndSetHints();
  }, [pending.getClassList, pending.getMethodList]);

  useEffect(() => {
    if (!watchFields.class) {
      return;
    }

    dispatch(getAnchorParams(watchFields.class.id as string));
  }, [watchFields.class]);

  useEffect(() => {
    findAndSetHints();
  }, [watchFields.class, watchFields.method]);

  useEffect(() => {
    // удаляем старые поля
    Object.keys(lastParamsByClass).forEach((field: string) => {
      unregister(field);
    });

    // добавляем новые полученные поля
    Object.entries(paramsByClass).forEach(([field, data]: any) => {
      const hasValue = watch(field);

      if (hasValue) {
        return;
      }

      const isYes = Number(data.value);
      register(field, { value: agreementOptions[isYes] });
    });

    setLastParamsByClass(paramsByClass); // обновляем запись со старыми полями
  }, [paramsByClass]);

  return (
    <form
      className={cn(style.modalWrapper, style.anchorForm)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        {...register("name")}
        {...textFieldSX}
        label="Наименование вехи"
        autoComplete={"off"}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      <br />
      <Select
        label="Вид документа или события"
        disableClear
        size="small"
        value={watchFields.class}
        changeHandler={(_, newValue) => {
          setValue("class", newValue);
        }}
        options={classList}
      />
      <p className={cn(style.hint, hints.className && style.hintExists)}>
        {hints.className}
      </p>
      <Select
        label="Этап приемки вехи"
        disableClear
        size="small"
        value={watchFields.method}
        changeHandler={(_, newValue) => {
          setValue("method", newValue);
        }}
        options={methodList}
      />
      <p className={cn(style.hint, hints.methodName && style.hintExists)}>
        {hints.methodName}
      </p>
      {Object.entries(lastParamsByClass).map(
        ([field, { label }], index: any) => {
          const hint = paramsByClass[field]?.hint ?? "";

          return (
            <div key={`${field}-${index}`}>
              <Select
                label={label}
                disableClear
                size="small"
                value={watchFields[field]}
                changeHandler={(_, newValue) => {
                  setValue(field, newValue);
                }}
                options={agreementOptions}
              />
              <p
                className={cn(style.hint, hint && style.hintExists)}
                dangerouslySetInnerHTML={{ __html: hint }}
              />
            </div>
          );
        }
      )}
      <TextField
        {...register("description")}
        {...textFieldSX}
        label="Описание"
        autoComplete={"off"}
        minRows={3}
        multiline
      />
      <div className={style.anchorControls}>
        {!isNew && (
          <CustomButton
            onClick={handleDeleteAnchor}
            background={palette.red}
            disabled={pending.anchorDelete || pending.getParams}
            width={130}
          >
            Очистить
          </CustomButton>
        )}
        <CustomButton
          type="submit"
          background={palette.blue}
          width={130}
          disabled={pending.anchorModify || pending.getParams}
        >
          Сохранить
        </CustomButton>
      </div>
    </form>
  );
};

export default Anchor;
