import { useForm } from "react-hook-form";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import { Nullable } from "src/types/generics";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDocumentCreateMutation } from "src/FSD/entities/expertise/document/api";
import { conclusionCreateSchema } from "../schemas/expertiseContract";

type TInputs = {
  date: Nullable<Date>;
  dateEnd: Nullable<Date>;
  dateStart: Nullable<Date>;
  fileUpload: TFile[];
  name: string;
  num: string;
};

type TProps = {
  processId: number;
};

export const useExpertiseContract = ({ processId }: TProps) => {
  const { handleClose } = useModalContext();

  const [createDocument, { isLoading }] = useDocumentCreateMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TInputs>({
    mode: "onSubmit",
    resolver: yupResolver(conclusionCreateSchema),
  });

  const formSubmitHandler = async (dataValues: TInputs) => {
    const { date, dateEnd, dateStart, fileUpload, name, num } = dataValues;
    const formData = new FormData();

    formData.append("date", String(toServerDate(date)));
    formData.append("date_end", String(toServerDate(dateEnd)));
    formData.append("date_start", String(toServerDate(dateStart)));
    fileUpload.forEach(({ file }) => {
      formData.append("file_upload", file!);
    });
    formData.append("num", num);
    formData.append("name", name);

    if (processId) {
      await createDocument({ id: processId, data: formData });
    }
    handleClose();
  };

  const onSubmit = handleSubmit(formSubmitHandler);

  return {
    onSubmit,
    control,
    errors,
    isLoading,
  } as const;
};
