import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { ChatLeftText } from "react-bootstrap-icons";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import {
  PublishedObjectControllingEntities,
  PublishedObjectView,
} from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { newDesignButtonSx, palette } from "src/styles/restyle";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import { config } from "src/app.cofig";
import styles from "./Executor.module.scss";
import { useExecutorHave } from "../../lib/hooks/useExecutorHave";
import ProjectAccessModals from "../../../../projectAccess/modals";
import PublishedObjectModals from "../../../modals";

interface Props {
  publishedObject: PublishedObjectView;
  type: PublishedObjectControllingEntities;
  accessType: ProjectAccessControllingEntities;
}

export const ExecutorApplied = ({
  publishedObject,
  type,
  accessType,
}: Props) => {
  const { executor, fullName, chatId, showOptions, jobDuration } =
    useExecutorHave(publishedObject);

  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  const { name, handleOpenModal, handleCloseModal } = useModal();

  const entityName =
    accessType === ProjectAccessControllingEntities.PROJECT_PROCESS
      ? "проекту"
      : "задаче";

  return (
    <>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.REQUEST_REMOVE_EXECUTOR_START
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.RequestRemoveExecutorStartForms
          id={publishedObject.id}
          type={type}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectAccess.UPDATE_ACCESS}
        handleClose={handleCloseModal}
      >
        <ProjectAccessModals.UpdateAccessForm
          id={publishedObject.id}
          header={`Изменить доступ к ${entityName}`}
          type={accessType}
        />
      </Modal>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.REQUEST_REMOVE_EXECUTOR_COMPLETE
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.RequestRemoveExecutorCompleteForm
          id={publishedObject.id}
          type={type}
        />
      </Modal>
      <Card
        header={<Card.Header>Исполнитель</Card.Header>}
        className={styles.executorCard}
        controls={
          <Card.Controls location="top" width={30}>
            {executor && (
              <div className={styles.controls}>
                {publishedObject.can!.chat && (
                  <Link to={`/chat/${chatId}`} target="_blank">
                    <IconButton title="Чат" sx={newDesignButtonSx}>
                      <ChatLeftText color={palette.persianGreen} />
                    </IconButton>
                  </Link>
                )}
                {showOptions && (
                  <ThreeDotButton ref={threeDotButtonRef} color="success">
                    {publishedObject.can!.requestRemoveExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.PublishedObject
                              .REQUEST_REMOVE_EXECUTOR_START,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Снять исполнителя
                      </MenuItem>
                    )}
                    {publishedObject.can!.updateExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.ProjectAccess.UPDATE_ACCESS,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Сменить исполнителя
                      </MenuItem>
                    )}
                    {publishedObject.activeAccess.taskRemove?.can?.complete && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.PublishedObject
                              .REQUEST_REMOVE_EXECUTOR_COMPLETE,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Рассмотреть конфликт
                      </MenuItem>
                    )}
                    {publishedObject.task?.can?.feedbackToExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.Task.FEEDBACK,
                            taskId: publishedObject.task.id,
                            header: "Оценить исполнителя",
                            isExecutor: true,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Оценить исполнителя
                      </MenuItem>
                    )}
                  </ThreeDotButton>
                )}
              </div>
            )}
          </Card.Controls>
        }
      >
        <>
          <div className={styles.userInfo}>
            <TextLink
              className={styles.userInfo__name}
              url={`${config.localDomain}/user/${executor.id}`}
              target="_blank"
              redirect
            >
              {fullName}
            </TextLink>
            <Paragraph>
              {publishedObject.activeAccess.userType?.listName}
            </Paragraph>
          </div>
          <NewAvatar
            photoUrl={`${config.localDomain}${executor.photo}`}
            rating={executor.rating.executor}
            jobDuration={jobDuration}
            borderColor={palette.signalOrange}
            classname={styles.avatar}
          />
          <hr className={styles.yellowLine} />
        </>
      </Card>
    </>
  );
};
