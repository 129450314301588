import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { responseAgreement } from "src/FSD/features/v2/SelectAgreement/model/options";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "src/types/generics";
import { pushSuccessMessage } from "src/app/feature/errorTrace";
import { useAppDispatch } from "src/app/store";
import { publishContentSchema } from "../schemas/publishContent";
import { useQueries } from "./useQueries";
import { setDateValues } from "../helpers/setDateValues";
import { editSimpleContentSchema } from "../schemas/editSimpleContent";

const defaultValues = {
  price: 0,
  pay1: 0,
  pay2: 0,
  pay3: 0,
  dateStart: null,
  dateLimit: null,
  checkNegotiatedPrice: false,
  checkDelegation: false,
  agreementsActs: responseAgreement[0],
  safeTransaction: responseAgreement[0],
  userTypeToAssign: null,
  userToAssign: null,
  executorTypes: [],
  save: 0,
  isAppointmentExecutor: false,
  vor: false,
  vorPnr: false,
  kac: false,
  showVor: false,
  showVorPnr: false,
  showKac: false,
  showBim: false,
  bim: false,
  payError: "",
  showPay3: false,
  canNoSecure: false,
  isProcessWork: false,
  workAccount: null,
  isEdit: false,
  daysInterval: 8,
};

export type TPublicationValues = {
  disabled: boolean;
  showTermsPayment: boolean;
  userTypeToAssign: Nullable<TSelectItem>;
  userToAssign: Nullable<TSelectItem>;
  isAppointmentExecutor: boolean;
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  bim: boolean;
  pay3: boolean;
  canNoSecure: boolean;
  disabledSaveBtn: boolean;
  showAdditionalRequirements: boolean;
};

export type TFieldValues = {
  price: number;
  pay1: number;
  pay2: number;
  pay3: number;
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  checkNegotiatedPrice: boolean;
  checkDelegation: boolean;
  agreementsActs: TSelectItem;
  safeTransaction: TSelectItem;
  userTypeToAssign: Nullable<TSelectItem>;
  userToAssign: Nullable<TSelectItem>;
  executorTypes: TSelectItem[];
  save: number;
  isAppointmentExecutor: boolean;
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  showVor: boolean;
  showVorPnr: boolean;
  showKac: boolean;
  showBim: boolean;
  bim: boolean;
  payError: string;
  showPay3: boolean;
  canNoSecure: boolean;
  isProcessWork: boolean;
  workAccount: Nullable<TSelectItem>;
  isEdit: boolean;
  daysInterval: number;
};

type UseModalProps = {
  id: number;
  type: PublishedObjectControllingEntities;
  submitQuery: any;
  isEdit?: boolean;
};

export const usePublishContent = ({
  id,
  type,
  submitQuery,
  isEdit = false,
}: UseModalProps) => {
  const { handleClose } = useModalContext();

  const dispatch = useAppDispatch();
  const { publishInfo, processData, isFetching, isFetchingPublishInfo } =
    useQueries({ id, type });

  const contentSchema = publishInfo?.simpleModal
    ? editSimpleContentSchema
    : publishContentSchema;

  const form = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(contentSchema),
    mode: "onChange",
  });

  const { setValue, watch, setError, formState } = form;

  const isProcessWork =
    type === PublishedObjectControllingEntities.PROCESS_WORK;

  useEffect(() => {
    if (isProcessWork) {
      setValue("isProcessWork", isProcessWork);
      setValue("daysInterval", 1);
    }
  }, [isProcessWork]);

  useEffect(() => {
    if (isEdit) {
      setValue("isEdit", isEdit);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!processData) {
      return;
    }
    if (!publishInfo) {
      return;
    }

    setDateValues(processData, publishInfo, setValue, setError, isEdit);
  }, [processData, publishInfo]);

  const publicationValues = {
    disabled: watch("checkNegotiatedPrice") && !watch("userTypeToAssign"),
    showTermsPayment: !!watch("agreementsActs").id,
    userTypeToAssign: watch("userTypeToAssign"),
    userToAssign: watch("userToAssign"),
    isAppointmentExecutor: watch("isAppointmentExecutor"),
    vor: watch("showVor"),
    vorPnr: watch("showVorPnr"),
    kac: watch("showKac"),
    bim: watch("showBim"),
    pay3: watch("showPay3"),
    canNoSecure: watch("canNoSecure"),
    disabledSaveBtn: !!(watch("userTypeToAssign") || watch("userToAssign")),
    showAdditionalRequirements:
      watch("showVor") ||
      watch("showVorPnr") ||
      watch("showKac") ||
      watch("showBim"),
  };

  useEffect(() => {
    if (publicationValues.userTypeToAssign) {
      setValue("checkNegotiatedPrice", false);
    }
  }, [publicationValues.userTypeToAssign]);

  useEffect(() => {
    if (!publicationValues.userToAssign && isProcessWork) {
      setValue("checkDelegation", false);
    }
  }, [publicationValues.userToAssign]);

  useEffect(() => {
    if (
      isProcessWork &&
      !publicationValues.userTypeToAssign &&
      publicationValues.userToAssign
    ) {
      setValue("userToAssign", null);
    }
  }, [
    publicationValues.userTypeToAssign,
    publicationValues.userToAssign,
    isProcessWork,
  ]);

  useEffect(() => {
    if (
      !isProcessWork &&
      publicationValues.userTypeToAssign &&
      !publicationValues.userToAssign
    ) {
      setValue("userTypeToAssign", null);
    }
  }, [
    publicationValues.userTypeToAssign,
    publicationValues.userToAssign,
    isProcessWork,
  ]);

  const safeTransaction = watch("safeTransaction").title === "Да";
  const agreementsActs = watch("agreementsActs").title === "Нет";

  useEffect(() => {
    if (safeTransaction && agreementsActs) {
      setValue("agreementsActs", responseAgreement[1]);
    }
  }, [safeTransaction, agreementsActs]);

  const missingAttributesLength = publishInfo?.secureMissingAttributes?.length;

  useEffect(() => {
    if (missingAttributesLength) {
      setValue("agreementsActs", responseAgreement[1]);
      setValue("safeTransaction", responseAgreement[1]);
    }
  }, [missingAttributesLength]);

  const save = watch("save");

  const showErrorFiles =
    !isProcessWork &&
    !(processData?.files?.length || processData?.project?.files?.length);

  const textProcess = isProcessWork ? "задачи" : "раздела";

  const onSubmit = async (data: TFieldValues) => {
    if ((showErrorFiles || missingAttributesLength) && !data.save) {
      return;
    }

    const secure = Number(data.safeTransaction.id);
    const with_docs = Number(data.agreementsActs.id);

    const formedData: any = {
      agreementPrice: data.checkNegotiatedPrice,
      date_start: toServerDate(data.dateStart),
      date_limit: toServerDate(data.dateLimit),
      executor_type: data.executorTypes.map((item: TSelectItem) =>
        Number(item.id)
      ),
    };

    if (!isEdit) {
      formedData.with_docs = with_docs;
      formedData.secure = secure;
      formedData.save = data.save;
      formedData.bim = data.bim;
    }

    if (isProcessWork && !isEdit) {
      formedData.money_from_process = data.workAccount!.id === "true";
    }

    if (!data.checkNegotiatedPrice) {
      formedData.price = Number(data.price);
    } else {
      formedData.price = 0;
    }

    if (data.userTypeToAssign) {
      formedData.firmIdByAdmin = Number(data.userTypeToAssign.id);
      formedData.executorByAdminId = Number(data.userToAssign!.id);

      if (isProcessWork) {
        formedData.delegate = data.checkDelegation;
      }
    }

    if (with_docs) {
      formedData.pay1 = data.pay1;
      formedData.pay2 = data.pay2;
      if (publishInfo!.pay3) {
        formedData.pay3 = data.pay3;
      } else {
        formedData.pay3 = 0;
      }
    }

    if (processData!.projectPart.vor) {
      formedData.vor = data.vor;
    }

    if (processData!.projectPart.vor_pnr) {
      formedData.vor_pnr = data.vorPnr;
    }

    if (processData!.projectPart.kac) {
      formedData.kac = data.kac;
    }

    const result = await submitQuery({ id, data: formedData });

    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    onSubmit,
    form,
    publicationValues,
    isSubmittingSave: !!(formState.isSubmitting && save),
    isSubmittingPublish: formState.isSubmitting && !save,
    isProjectProcessViewPending:
      isFetching || !processData || isFetchingPublishInfo || !publishInfo,
    processData,
    showErrorFiles,
    publishInfo,
    textProcess,
    isProcessWork,
  } as const;
};
