import { ReactNode } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";

type TProps = {
  children: ReactNode;
};

export const WarningCell = ({ children }: TProps) => (
  <Paragraph color="warning" className={styles.warningCell}>
    {children}
  </Paragraph>
);
