import TextFieldMui, { TextFieldProps } from "@mui/material/TextField";
import { styled, alpha } from "@mui/material/styles";
import { forwardRef } from "react";

import { palette } from "src/styles/restyle";

export const TextField = styled(
  forwardRef(({ ...otherProps }, ref) => (
    <TextFieldMui
      {...otherProps}
      inputRef={ref}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
    />
  ))
)<TextFieldProps>(() => ({
  fullWidth: false,
  size: "small",
  variant: "outlined",
  width: "100%",
  "& .Mui-error": {
    color: `${palette.darkTelegraph}`,
  },
  "& .MuiFormHelperText-root": {
    color: `${palette.darkTelegraph}`,
    "&.Mui-disabled": {
      color: `${palette.darkTelegraph}`,
    },
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    position: "static",
    transform: "none",
    fontSize: "16px",
    lineHeight: 1.4,
    marginBottom: "2px",
    "&.Mui-error": {
      color: palette.darkTelegraph,
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: `${alpha(palette.darkTelegraph, 0.8)}`,
    cursor: "not-allowed",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: palette.grey,
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${alpha(palette.grey, 0.6)}`,
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    cursor: "not-allowed",
  },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
    padding: "0px 8px",
  },
  "& label": {
    color: palette.darkTelegraph,
    "&.Mui-focused": {
      color: palette.blue,
    },
    "&.Mui-disabled": {
      color: palette.darkTelegraph,
    },
  },
  "& .MuiOutlinedInput-root": {
    minHeight: "37px",
    "& fieldset": {
      border: `1px solid ${palette.grey}`,
      transition: "all 0.2s ease",
      top: 0,
      "> legend": {
        display: "none",
      },
    },
    "&:hover fieldset": {
      border: `1px solid ${palette.blue}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${palette.blue}`,
    },
    "& input": {
      color: palette.white,
      padding: "7px 8px",
    },
    "& textarea": {
      color: palette.white,
      fontSize: "16px",
    },
    "& .MuiSelect-select": {
      color: palette.white,
    },
    "& .MuiSvgIcon-root": {
      color: palette.grey,
    },
  },
  "& .MuiInputBase-multiline": {
    height: "auto",
  },
  "& p.MuiFormHelperText-root": {
    fontSize: "0.875rem",
    lineHeight: "1rem",
    marginLeft: 0,
    marginRight: 0,
    "&.Mui-error": {
      color: palette.bordeaux,
    },
  },
}));
