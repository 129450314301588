import { memo } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useComments } from "../lib/hooks/useComments";
import { CommentsList } from "src/FSD/widgets/v2/task/modals/Comments/ui/CommentsList";

interface Props {
  taskId: number;
}

export const CommentsForm = memo(({ taskId }: Props) => {
  const { isCommentsLoading, comments } = useComments(taskId);

  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      {isCommentsLoading ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form>
          <CommentsList comments={comments} />
        </Modal.Form>
      )}
    </Modal.Layout>
  );
});
