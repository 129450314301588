import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = {
  remarkId: number;
  data: {
    text: string;
    comment: string;
    date_limit: string;
    project_id: number;
    project_part_ids: number[];
  };
};

export const create = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ remarkId, data }) => ({
      url: `v1/expertise/remark-row/create`,
      params: {
        remarkId,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [ApiTags.Expertise.GET_REMARKS],
  });
