import { useState } from "react";
import { TTreaty } from "src/app/feature/dashboard/treaties";
import FolderPanel from "../Sections/FolderPanel";
import { TreatiesPoint } from "./Point";

const formTreaties = (treaties: any, areTreaties: boolean) => {
  const treaty = treaties.filter((treaty: any) => treaty.title === "Договор");

  if (areTreaties || !treaty.length) {
    return treaties;
  }

  const attachment = treaties.filter((treaty: any) =>
    treaty.title.includes("Приложение")
  );

  const extra = treaties.filter((treaty: any) => treaty.title.includes("Доп"));

  const agreement = treaties.filter((treaty: any) =>
    treaty.title.includes("Соглашение")
  );

  return [...treaty, ...attachment, ...extra, ...agreement];
};

export type TreatiesGroupProps = {
  name: string;
  treaties: TTreaty[];
  ticked: number[];
  offset?: number;
  areTreaties: boolean;
  isMobile?: boolean;
};

export const TreatiesGroup = ({
  name,
  treaties,
  ticked,
  areTreaties,
  offset = 0,
  isMobile = false,
}: TreatiesGroupProps) => {
  const foundTreaties = formTreaties(treaties, areTreaties).filter(
    (treaty: TTreaty) => !areTreaties || ticked.includes(treaty.id)
  );

  const hasOrange = (docsList: any) =>
    docsList.some((treaty: any) => treaty.status.includes("orange"));

  const documentsUnsigned = foundTreaties.some((docs: any) => {
    const areOrange = (docs?.documents ?? [])
      .reduce((result: any[], doc: any) => [...result, ...doc[1]], [])
      .some((treaty: any) => treaty.status.includes("orange"));

    return areOrange;
  }, false);

  const containsUnsigned = hasOrange(foundTreaties) || documentsUnsigned;

  // Дичь такое условие писать, папку в которой есть слово "договор" не показываем
  const showFolder = areTreaties || (!areTreaties && !name.includes("Договор"));

  const [isOpen, setOpen] = useState<boolean>(Boolean(isMobile) || !showFolder);

  return (
    <>
      {foundTreaties.length > 0 && showFolder && (
        <FolderPanel
          isOpen={isOpen}
          name={`${name} (${foundTreaties.length})`}
          canOpen={Boolean(foundTreaties.length)}
          handleShowSubtree={() => setOpen(!isOpen)}
          short={isMobile}
          offset={offset}
          color={containsUnsigned ? "orange" : null}
        />
      )}
      {isOpen &&
        foundTreaties.map((treaty: TTreaty) => (
          <TreatiesPoint
            treaty={treaty}
            name={name}
            treaties={treaties}
            ticked={ticked}
            isMobile={isMobile}
            areTreaties={areTreaties}
            offset={offset}
          />
        ))}
    </>
  );
};
