import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import style from "../style.module.scss";
import { Button } from "../../../UI/components/Buttons/Button/Button";
import { selectorAppTheme } from "src/app/feature/app/app";
import { request } from "src/app/services/api/requestHandler";
import { ApiProjectInvite } from "src/app/services/api/project/project";
import { Select } from "../../../UI/components/FormComponentns/Select";
import { ApiGetMyProjects } from "src/app/services/api/user/user";
import { selectorUserPageHeaderData } from "src/app/feature/userPage/userPage";
import { useUserWorkflow } from "../../../hooks/useUserWorkflow";
import { TSelectItem } from "../SetGipForm/types";
import { textFieldSX } from "src/styles/restyle";

type TJoinUserProjectForm = {
  textArea: string;
  projects: TSelectItem | null;
};

export interface SearchGipProjectFormProps {
  submitHandler?: ({
    price,
    responsibilityType,
  }: {
    price: number;
    responsibilityType: number[];
  }) => void;
  closeHandler: VoidFunction;
}

const schema = Yup.object().shape({
  projects: Yup.mixed().test({
    test: (value) => {
      return value !== null;
    },
    message: "Поле обязательно для заполнения",
  }),
  textArea: Yup.string()
    .min(10, "Минимум 10 символов")
    .max(2000, "Максимум 2000 символов"),
});

// Форма приглашения пользователя в проект

export const JoinUserProjectForm: React.FC<SearchGipProjectFormProps> = () => {
  const theme = useSelector(selectorAppTheme);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [myProjects, setMyProjects] = useState([]);
  const headerData = useSelector(selectorUserPageHeaderData);
  const { Popup } = useUserWorkflow();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TJoinUserProjectForm>({
    defaultValues: {
      textArea: "Приглашаю стать участником проекта!",
      projects: null,
    },
    resolver: yupResolver(schema),
  });

  const watchProjects = watch("projects");

  const onSubmit = (data: TJoinUserProjectForm) => {
    request(
      ApiProjectInvite(headerData!.id!, {
        ProjectInviteForm: {
          comment: data.textArea,
          // @ts-ignore
          project_id: Number(data.projects!.id!),
        },
      }),
      () => {
        enqueueSnackbar("Успешно", {
          variant: "success",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          Popup.joinUserProject.close();
        }, 300);
      },
      () => () => {
        enqueueSnackbar("Ошибка приглашения, сообщите администратору в чате", {
          variant: "error",
          autoHideDuration: 5000,
        });
        setTimeout(() => {
          Popup.joinUserProject.close();
        }, 300);
      }
    )();
  };

  useEffect(() => {
    request(ApiGetMyProjects(), (data) => {
      const newData = data.map((item: any) => ({
        id: item.id,
        title: item.name,
      }));

      setValue("projects", newData[0]);
      setMyProjects(newData);
    })(dispatch);
  }, []);

  return (
    <div className={style.formWrapper} style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          label={"Проект"}
          options={myProjects}
          value={watchProjects}
          error={Boolean(errors.projects)}
          helperText={errors.projects?.message}
          changeHandler={(_, value) => {
            setValue("projects", value);
          }}
        />
        <br />
        <TextField
          {...register("textArea")}
          {...textFieldSX}
          label={"Комментарий"}
          multiline
          maxRows={5}
          rows={5}
          error={Boolean(errors.textArea)}
          helperText={errors.textArea?.message}
        />
        <br />
        <div className={style.buttonWrapper}>
          <Button theme={theme} type="submit" color={"blue"}>
            Отправить
          </Button>
        </div>
      </form>
    </div>
  );
};
