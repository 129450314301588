import { useProjectProcessGetGroupPublishProcessQuery } from "src/FSD/entities/projectProcess/api";

type Props = {
  processId: number;
};

export const useGroupProcessPublication = ({ processId }: Props) => {
  const { data: groupPublishProcess, isFetching } =
    useProjectProcessGetGroupPublishProcessQuery({
      id: processId,
    });

  const isProcessesLoading = !groupPublishProcess || isFetching;

  return { groupPublishProcess, isProcessesLoading } as const;
};
