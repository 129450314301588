import { Avatar } from "src/FSD/shared/uiKit/v2/Avatar/Avatar";
import styles from "./Application.module.scss";

type TProps = {
  photoUrl: string;
  borderColor: string;
  fio: string;
  upperText?: string;
  lowerText?: string;
  userId: number;
  className?: string;
};

export const ApplicationAvatar = ({
  photoUrl,
  borderColor,
  fio,
  upperText,
  lowerText,
  userId,
  className,
}: TProps) => {
  return (
    <div className={styles.card__avatar}>
      <Avatar
        photoUrl={photoUrl}
        borderColor={borderColor || "orange"}
        fio={fio}
        upperText={upperText}
        lowerText={lowerText}
        userId={userId}
        className={className}
      />
    </div>
  );
};
