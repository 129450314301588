import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { SetState } from "../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { palette } from "../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getParentProjectId,
  setParentProjectId,
} from "../../../../app/feature/ProjectView/ComplexReducer/projectComplex";

interface ProjectCreationProps {
  decree: boolean;
  setDecree: SetState<boolean>;
  setSimple: SetState<boolean | undefined>;
  sqObject: boolean;
  setSqObject: SetState<boolean>;
  setCurrentTab: SetState<string>;
  authorType: any;
}

const ERROR_MSG = "Заполните форму ответственности заказчика";

export const Creation: FC<ProjectCreationProps> = ({
  setSimple,
  sqObject,
  setSqObject,
  setCurrentTab,
  authorType,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const parentId = useAppSelector(getParentProjectId);

  const setForm = (isSimple: boolean) => {
    if (!authorType.id) {
      alert(ERROR_MSG);
      return;
    }

    navigate(`/project/init?userTypeId=${authorType.id}`);

    setSimple(isSimple);
    setCurrentTab("info");
  };

  const setSquareObject = () => setSqObject(true);
  const setLinearObject = () => setSqObject(false);

  useEffect(
    () => () => {
      if (!window.location.href.includes("init")) {
        dispatch(setParentProjectId(null));
      }
    },
    []
  );

  return (
    <div className={style.wrapper}>
      <div className={style.pane}>
        <div className={style.typeSelection}>
          <h1>Площадной объект</h1>
          <CustomButton
            onClick={setSquareObject}
            background={sqObject ? palette.green : palette.grey}
            width={120}
            className={style.button}
          >
            Выбрать
          </CustomButton>
        </div>
        <p>
          Все объекты капитального строительства не относящиеся к линейным
          объектам.
        </p>
      </div>
      <div className={style.pane}>
        <div className={style.typeSelection}>
          <h1>Линейный объект</h1>
          <CustomButton
            onClick={setLinearObject}
            background={sqObject ? palette.grey : palette.green}
            width={120}
            className={style.button}
          >
            Выбрать
          </CustomButton>
        </div>
        <p>
          Длина объектов намного превышает ширину. Совокупно к ним относятся
          дороги, инженерные сети, линии электрических передач, железнодорожные
          пути. К таким объектам можно причислить метро, фуникулеры, мосты,
          туннели и т. д.
        </p>
      </div>
      {!parentId && (
        <div className={style.pane}>
          <div className={style.typeSelection}>
            <h1>Простая форма</h1>
            <CustomButton
              onClick={() => setForm(true)}
              background={palette.green}
              width={120}
              className={style.button}
            >
              Создать
            </CustomButton>
          </div>
          <p>
            Без использования функционала платформы по заключению договоров с
            исполнителями и без ведения документооборота
          </p>
          <p>Функционал управления проектом отключен</p>
          <p>
            Возможность выбора использования функционала платформы по заключению
            договора с выбранными исполнителями и ведение документооборота
          </p>
          <p>Архив документации по проекту</p>
        </div>
      )}
      <div className={style.pane}>
        <div className={style.typeSelection}>
          <h1>Стандартная форма</h1>
          <CustomButton
            onClick={() => setForm(false)}
            background={palette.green}
            width={120}
            className={style.button}
          >
            Создать
          </CustomButton>
        </div>
        <p>
          Включает заполнение полей: название проекта, название проекта для
          заключаемых договоров, вид проектирования (2D,3D, BIM), сроки
          проектирования объекта, страна и регион объекта, тип строительства,
          назначение объекта, физические параметры объекта, условия оплаты,
          банковский счет, описание работ, выбор разделов в соответствии 87-му
          Постановлению, загрузку исходных данных.
        </p>
        <p>
          Активирован функционал управления проектом: выдача заданий смежникам,
          проверки документации смежниками, работа с поставщиками материалов и
          оборудования. При необходимости может быть отключен Заказчиком.
        </p>
        <p>
          Возможность выбора использования функционала платформы по заключению
          договора с выбранными исполнителями и ведение документооборота
        </p>
        <p>Архив документации по проекту</p>
      </div>
    </div>
  );
};
