import { FC } from "react";
import cn from "classnames";
import { Trash } from "react-bootstrap-icons";
import { CircularProgress, IconButton } from "@mui/material";
import style from "./style.module.scss";
import { AddSVG, ClearSquareSVG } from "../../../../newUI/SVG";
import StagesPercents from "./StagesPercents";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  getMilestone,
  setToggledForms,
} from "../../../../../app/feature/milestone";
import {
  createGroup,
  deleteGroup,
  deleteLink,
} from "src/app/feature/milestone/thunks";

export type TPart = {
  id: number;
  code: string;
  name: string;
  linkId: number;
};

export interface ProcessesListProps {
  firmId: number;
  bfType: number;
  parts: TPart[];
  groups: any;
  partType: number;
  groupId: number;
  handleAddProcess: (
    id: number,
    parts: TPart[],
    groupParts: TPart[],
    partType: number,
    groupId: number
  ) => void;
}

const ProcessesList: FC<ProcessesListProps> = ({
  parts: groupParts,
  groups,
  handleAddProcess,
  partType,
  firmId,
  bfType,
  groupId,
}) => {
  const dispatch = useAppDispatch();
  const groupsFormed = Object.values(groups);

  const { data, pending } = useAppSelector(getMilestone);

  const handleToggle = (id: number) => {
    const { toggledForms } = data;

    dispatch(
      setToggledForms(
        toggledForms.includes(id)
          ? toggledForms.filter((item: number) => item !== id)
          : [...toggledForms, id]
      )
    );
  };

  const handleCreateGroup = () => {
    dispatch(createGroup({ firmId, bfType, groupId, partType }));
  };

  const handleDeleteGroup = (id: number) => {
    if (!window.confirm("Вы уверены что хотите удалить данную группу?")) {
      return;
    }

    dispatch(deleteGroup({ groupId, partType, id }));
  };

  const handleDeleteLink = (id: number, link: TPart & { linkId: number }) => {
    dispatch(deleteLink({ id, groupId, partType, link }));
  };

  return (
    <div className={style.completeList}>
      <div className={style.processList}>
        {groupParts.map(({ code }) => (
          <p>{code}</p>
        ))}
        <div className={style.addProcessButton}>
          <p>Создать группу</p>
          {pending.createGroup === partType ? (
            <CircularProgress className={style.pending} />
          ) : (
            <IconButton
              onClick={handleCreateGroup}
              className={style.addProcesses}
            >
              <AddSVG />
            </IconButton>
          )}
        </div>
      </div>
      {Boolean(groupsFormed.length) && (
        <div className={style.groupsList}>
          {groupsFormed.map((item: any, index: number) => {
            const { parts, stageGroupPercents } = item;
            const id = item?.id ?? 0;

            const isToggled = data.toggledForms.includes(id);
            const exactGroupId = item?.id;
            return (
              <>
                <div className={style.groupTitle}>
                  <h3>Группа {index + 1}</h3>
                  {pending.deleteGroup === id ? (
                    <CircularProgress className={style.pending} />
                  ) : (
                    <IconButton onClick={() => handleDeleteGroup(id)}>
                      <Trash />
                    </IconButton>
                  )}
                  <div
                    onClick={() => handleToggle(id)}
                    className={style.groupsControl}
                  >
                    <p>
                      {Number.isNaN(data.groupSum[id]) ? (
                        <>Некорректное значение</>
                      ) : (
                        <>{data.groupSum[id]}%</>
                      )}
                    </p>
                    <ToggleAccordion toggle={isToggled} />
                  </div>
                </div>
                <div className={cn(style.processList, style.parts)}>
                  {parts.map(({ id, name, linkId, code }: any) => (
                    <p>
                      {code}
                      <button
                        disabled={pending.deleteLink === id}
                        onClick={() => {
                          const link = { id, name, linkId, code };
                          handleDeleteLink(exactGroupId, link);
                        }}
                        className={style.clear}
                      >
                        <ClearSquareSVG />
                      </button>
                    </p>
                  ))}
                  <div className={style.addProcessButton}>
                    <p>Добавить раздел</p>
                    <IconButton
                      onClick={() =>
                        handleAddProcess(
                          id,
                          parts,
                          groupParts,
                          partType,
                          groupId
                        )
                      }
                      className={style.addProcesses}
                    >
                      <AddSVG />
                    </IconButton>
                  </div>
                </div>
                <StagesPercents
                  id={id}
                  visible={isToggled}
                  stageGroupPercents={stageGroupPercents}
                  firmId={firmId}
                />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProcessesList;
