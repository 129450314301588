import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import style from "./checklist.module.scss";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { ChecklistProjectData } from "src/app/feature/ProjectView/CheckListReducer/thunks";
import { trimPrice } from "src/utils/trimNumber";
import { getProjectCheckList } from "src/app/feature/ProjectView/CheckListReducer/projectCheckList";
import { TextLink } from "../../../../UI/components/TextLink/TextLink";
import { ToggleAccordion } from "../../../../newUI/ToggleAccordion/ToggleAccordion";
import { Dot } from "../../../../newUI/Dot/Dot";

const Dash = () => {
  return <div className={style.dash}></div>;
};

type TreeCheckListProps = {
  data: ChecklistProjectData[];
};

export const TreeCheckList = ({ data }: TreeCheckListProps) => {
  return (
    <ul className={style.sections_container}>
      {data.map((tree, index) => (
        <TreeNode key={index} node={tree} />
      ))}
    </ul>
  );
};

type TreeNodeProps = {
  node: ChecklistProjectData;
};

const TreeNode = ({ node }: TreeNodeProps) => {
  const isMobile = useIsMobile(551);
  const [childVisible, setChildVisibility] = useState(false);
  const { isAllToggle } = useSelector(getProjectCheckList);
  const haveStopTask = node?.stopTasks?.length > 0;

  useEffect(() => {
    setChildVisibility(isAllToggle);
  }, [isAllToggle]);
  const hasChild = !!node.data;

  const iconType = <ToggleAccordion toggle={childVisible} />;

  const renderIsMobile = isMobile ? (
    <>
      <div>Сроки</div>
      <div className={style.terms}>
        <div>{node.dateStart}</div>
        <div className={style.terms_slider}>
          <div style={{ width: `${node.datePercent}%` }}></div>
          <div className={style.terms_slider_progress}>{node.datePercent}%</div>
          <div style={{ width: `${100 - node.datePercent}%` }}></div>
        </div>
        <div>{node.dateEnd}</div>
      </div>
      <div>Бюджет</div>
      <div className={style.terms}>
        <div>
          {trimPrice(node.priceStart)}
          <span>₽</span>
        </div>
        <div className={style.terms_slider}>
          <div style={{ width: `${node.pricePercent}%` }}></div>
          <div className={style.terms_slider_progress}>
            {node.pricePercent}%
          </div>
          <div style={{ width: `${100 - node.pricePercent}%` }}></div>
        </div>
        <div>
          {trimPrice(node.priceEnd)}
          <span>₽</span>
        </div>
      </div>
    </>
  ) : (
    <>
      <div>Сроки</div>
      <div>Бюджет</div>
      <div className={style.terms}>
        <div>{node.dateStart}</div>
        <div className={style.terms_slider}>
          <div style={{ width: `${node.datePercent}%` }}></div>
          <div className={style.terms_slider_progress}>{node.datePercent}%</div>
          <div style={{ width: `${100 - node.datePercent}%` }}></div>
        </div>
        <div>{node.dateEnd}</div>
      </div>
      <div className={style.terms}>
        <div>
          {trimPrice(node.priceStart)}
          <span>₽</span>
        </div>
        <div className={style.terms_slider}>
          <div style={{ width: `${node.pricePercent}%` }}></div>
          <div className={style.terms_slider_progress}>
            {node.pricePercent}%
          </div>
          <div style={{ width: `${100 - node.pricePercent}%` }}></div>
        </div>
        <div>
          {trimPrice(node.priceEnd)}
          <span>₽</span>
        </div>
      </div>
    </>
  );

  return (
    <li className={classNames(!hasChild && style.sections)}>
      <div
        className={classNames(
          hasChild && style.sections_node,
          !hasChild && style.sections_child,
          [style.basic]
        )}
        onClick={() => setChildVisibility((v) => !v)}
      >
        <div className={style.sections_name}>
          {node?.alert && <Dot status={"error"} size={8} />}
          {node.name}
        </div>
        {hasChild && <div>{iconType}</div>}
        {!hasChild && (
          <>
            <div
              className={classNames(style.grid, style.basic, style.checklist)}
            >
              <div className={style.checklist_code}>
                <TextLink redirect url={`/project-process/${node.id}`}>
                  <div
                    title={node.part.name}
                    className={style.checklist_code_title}
                  >
                    {haveStopTask && <Dot status={"error"} />}
                    {node.part.code}
                  </div>
                </TextLink>
              </div>
              <div
                className={classNames(
                  node.prepareTask.status && style.checklist_status
                )}
              >
                {(node.prepareTask.all &&
                  `${node.prepareTask.done}/${node.prepareTask.all}`) || (
                  <Dash />
                )}
              </div>
              <div
                className={classNames(
                  node.acceptTask.status && style.checklist_status
                )}
              >
                {(node.acceptTask.all &&
                  `${node.acceptTask.done}/${node.acceptTask.all}`) || <Dash />}
              </div>
              <div
                className={classNames(
                  node.customerAlign.status && style.checklist_status
                )}
              >
                {(node.customerAlign.all &&
                  `${node.customerAlign.done}/${node.customerAlign.all}`) || (
                  <Dash />
                )}
              </div>
              <div
                className={classNames(
                  node.prepareDoc.status && style.checklist_status
                )}
              >
                {(node.prepareDoc.all &&
                  `${node.prepareDoc.done}/${node.prepareDoc.all}`) || <Dash />}
              </div>
              <div
                className={classNames(
                  node.checkDoc.status && style.checklist_status
                )}
              >
                {(node.checkDoc.all &&
                  `${node.checkDoc.done}/${node.checkDoc.all}`) || <Dash />}
              </div>
              <div
                className={classNames(
                  node.regAct.status && style.checklist_status
                )}
              >
                {(node.regAct.all &&
                  `${node.regAct.done}/${node.regAct.all}`) || <Dash />}
              </div>
            </div>
            <div className={style.gridTerms}>{renderIsMobile}</div>
            {haveStopTask && (
              <div className={style.stopTask}>
                <p>Причины остановки работ</p>
                {node.stopTasks.map((task) => (
                  <div key={task.id}>
                    <span>
                      {moment(task.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
                        "DD.MM.YYYY"
                      )}
                    </span>
                    <span>{task.content}</span>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {hasChild && childVisible && node.data && (
        <div>
          <TreeCheckList data={Object.values(node?.data)} />
        </div>
      )}
    </li>
  );
};
