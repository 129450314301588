import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { Uploader } from "../../../features/Uploader/Uploader";
import style from "../StatusCardWrapper.module.scss";
import cls from "../../Fieldset/Fieldset.module.scss";
import { config } from "src/app.cofig";
import CustomButton from "../../CustomButton/CustomButton";
import { palette, textFieldSX } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { completeSubTask } from "src/app/feature/ProjectView/thunks";
import {
  getProjectViewData,
  setCheckInput,
} from "src/app/feature/ProjectView/projectView";
import { TextLink } from "../../../UI/components/TextLink/TextLink";
import { ITaskCan } from "src/types/api/primaryTypes/apiPrimaryTaskType";

type TFormValues = {
  comment: string;
  content: string;
};

type CheckProps = {
  taskId: number;
  files: any[];
  name: string;
  isProceeded: boolean;
  taskStatus: number;
  comment: string;
  content: string;
  can: ITaskCan;
  handleCloseModal: () => void;
};

const Check = ({
  taskStatus,
  taskId,
  files,
  name,
  isProceeded,
  comment,
  content,
  can,
  handleCloseModal,
}: CheckProps) => {
  const dispatch = useAppDispatch();
  const [noFilesError, setNoFilesError] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [listLength, setListLength] = useState<number>(0);

  const {
    pending: { subTaskCompletion },
  } = useAppSelector(getProjectViewData);

  const { register, handleSubmit } = useForm<TFormValues>({
    defaultValues: {
      comment,
      content,
    },
  });

  const onSubmit = async (data: TFormValues, status: number) => {
    if (!isProceeded && status === 6 && noFilesError) {
      setShowError(true);
      return;
    }

    setShowError(false);
    await dispatch(completeSubTask({ taskId, status, isProceeded, ...data }));

    handleCloseModal();
  };

  useEffect(() => {
    dispatch(setCheckInput(Boolean(can?.complete)));

    return () => {
      dispatch(setCheckInput(false));
    };
  }, []);

  useEffect(() => {
    if (listLength) {
      setShowError(false);
    }
  }, [listLength]);

  return (
    <form className={style.modalComment}>
      <h2>{name}</h2>
      <TextField
        {...register("comment")}
        {...textFieldSX}
        className={style.textField}
        label="Комментарий исполнителя"
        multiline
        minRows={3}
        disabled={isProceeded}
      />
      {isProceeded ? (
        <fieldset className={cls.fieldset}>
          <legend>Файлы</legend>
          <div className={cls.fieldset_files}>
            {files.map((file) => (
              <TextLink url={file.url} target="_blank" redirect>
                {file.name}
              </TextLink>
            ))}
          </div>
        </fieldset>
      ) : (
        <Uploader
          identifier={`${taskId}`}
          postUrlString={(id) =>
            `${config.localDomain}/v1/task/add-file?id=${id}`
          }
          uploadAsActive
          canBeEdited={true}
          defaultValue={files}
          setNoFilesError={setNoFilesError}
          listLength={listLength}
          setListLength={setListLength}
        />
      )}
      {noFilesError && showError && (
        <p className={style.error}>Необходимо приложить хотя бы один файл</p>
      )}
      {isProceeded && (
        <TextField
          {...register("content")}
          {...textFieldSX}
          className={style.textField}
          label="Замечания"
          multiline
          minRows={3}
        />
      )}
      <div className={style.sendControls}>
        {isProceeded ? (
          <>
            <CustomButton
              width={190 + Number(subTaskCompletion === 2) * 30}
              onClick={handleSubmit((data) => onSubmit(data, 5))}
              background={palette.red}
              disabled={subTaskCompletion === 2}
            >
              Вернуть на доработку
            </CustomButton>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data, 4))}
              width={130}
              type="submit"
              background={palette.green}
              disabled={subTaskCompletion === 8 || subTaskCompletion === 3}
            >
              Принять
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data, 7))}
              width={130 + Number(subTaskCompletion === 7) * 30}
              type="submit"
              background={palette.red}
              disabled={subTaskCompletion === 7}
            >
              Не требуется
            </CustomButton>
            <CustomButton
              onClick={handleSubmit((data) => onSubmit(data, 6))}
              width={130}
              type="submit"
              background={palette.blue}
              disabled={subTaskCompletion === 6}
            >
              Отправить
            </CustomButton>
          </>
        )}
      </div>
    </form>
  );
};

export default Check;
