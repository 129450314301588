import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "src/app/services/api/requestHandler";
import { ApiProjectCheckList } from "src/app/services/api/project/checklist";

interface DocType {
  all: number;
  done: number;
  status: boolean;
}
interface StopTaskType {
  id: number;
  content: string;
  date_create: Date;
}
export interface ChecklistProjectData {
  data?: ChecklistProjectData[];
  num: string;
  lvl: number;
  name?: string;
  alert: boolean;
  acceptTask: DocType;
  checkDoc: DocType;
  customerAlign: DocType;
  prepareDoc: DocType;
  prepareTask: DocType;
  regAct: DocType;
  stopTasks: StopTaskType[];
  dateStart: string;
  dateEnd: string;
  dateEndFact?: {
    date: string;
  } | null;
  datePercent: number;
  part: {
    id: number;
    code: string;
    name: string;
  };
  id: number;
  priceStart: string;
  priceEnd: string;
  pricePercent: number;
}

type ChecklistTreetype = Record<string, ChecklistProjectData>;

export const fetchProjectCheckListTree = createAsyncThunk<
  ChecklistTreetype,
  string
>("fetchProjectCheckListTree/fetch", async (id, { rejectWithValue }) => {
  try {
    const response = await ApiProjectCheckList(id);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (err: any) {
    const error = getErrorMessage(err);
    console.log(error);
    return rejectWithValue(error);
  }
});
