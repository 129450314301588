import { FC } from "react";
import { useForm } from "react-hook-form";
import { MultipleSelect } from "../../../../../UI/components/FormComponentns/MultipleSelect";
import style from "./style.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { TSelectItem } from "../../../../../features/Forms/SetGipForm/types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { editLinks } from "../../../../../../app/feature/milestone/thunks";
import { getMilestone } from "../../../../../../app/feature/milestone";

type TProcessValues = Record<string, TSelectItem[]>;

interface ChooseProcessProps {
  id: number | null;
  groupId: number | null;
  partType: number | null;
  processes: TProcessValues;
}

const ChooseProcess: FC<ChooseProcessProps> = ({
  id,
  groupId,
  processes: { options, value },
  partType,
}) => {
  const dispatch = useAppDispatch();

  const { pending } = useAppSelector(getMilestone);

  const { handleSubmit, watch, setValue } = useForm<TProcessValues>({
    defaultValues: {
      processes: value,
    },
  });

  const processesValue = watch("processes");

  const changeHandler = (data: (TSelectItem | undefined)[]) => {
    if (data.includes(undefined)) {
      setValue(
        "processes",
        options.length === processesValue.length ? [] : options
      );

      return;
    }

    setValue("processes", data as TSelectItem[]);
  };

  const onSubmit = (data: TProcessValues) => {
    const ids = data.processes.map((item: TSelectItem) => item.id);
    const body = { projectPartIds: ids };
    dispatch(
      editLinks({
        id: id as number,
        partType: partType as number,
        groupId: groupId as number,
        data: data.processes,
        options,
        body,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.createProcess}>
      <h2>Редактирование группы разделов</h2>
      <MultipleSelect
        changeHandler={changeHandler}
        value={processesValue}
        name={"processes"}
        label="Разделы"
        options={options}
        canSelectAll
      />
      <CustomButton
        className={style.saveButton}
        background={palette.blue}
        width={130}
        disabled={pending.editLinks}
        type="submit"
      >
        Сохранить
      </CustomButton>
    </form>
  );
};

export default ChooseProcess;
