import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Button, Card, Modal } from "src/FSD/shared/uiKit/v2";
import styles from "./Header.module.scss";
import ProjectProcessModals from "../../../../../modals";

interface Props {
  projectProcess: ApiProjectProcessResponses.View;
}

export const Header = ({ projectProcess }: Props) => {
  const { name, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CREATE_STOP}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.StopProcessForm
          projectProcessId={projectProcess.id}
        />
      </Modal>
      <Card
        className={styles.header}
        controls={
          <Card.Controls location="top">
            {projectProcess.can!.stop && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.ProjectProcess.CREATE_STOP,
                  });
                }}
              >
                Приостановить работу
              </Button>
            )}
          </Card.Controls>
        }
      >
        <h1>{projectProcess.projectPart.name}</h1>
        <p>{projectProcess.projectPart.type.value}</p>
      </Card>
    </>
  );
};
