import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProjectAccessGroupRequestMutation } from "src/FSD/entities/projectAccess/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2";
import { Props } from "../../ui/GroupPublicationForm/PublicationForm";
import { TRequestFieldValues } from "../../model/types/fieldValues";
import { requestValidationSchema } from "../../model/schemas/requestSchema";
import { createRequestFormData } from "../helpers/createRequestFormData";
import { useAppDispatch } from "../../../../../../../../app/store";
import { fetchProjectSectionsTreeDuplicate } from "../../../../../../../../app/feature/ProjectView/SectionsReducer/thunks";

export type TSubmitAction = "back" | "request";

const requestDefaultValues = {
  executorType: null,
  bankAccount: null,
  content: "",
};

export const useRequestForm = ({
  processId,
  setCurrentPage,
  formCache,
  setFormCache,
}: Props) => {
  // временно, пока "проект" не переписан на RTK Query
  const params = useParams();

  const dispatch = useAppDispatch();

  const { handleClose } = useModalContext();

  const [groupRequest] = useProjectAccessGroupRequestMutation();

  const defaultValues = useMemo(() => {
    if (formCache.request) {
      return formCache.request as TRequestFieldValues;
    }

    return requestDefaultValues;
  }, []);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    register,
    formState: { isSubmitting },
  } = useForm<TRequestFieldValues>({
    defaultValues,
    resolver: yupResolver(requestValidationSchema),
  });

  const onSubmit = async (
    dataValues: TRequestFieldValues,
    action: TSubmitAction
  ) => {
    if (action === "back") {
      setFormCache({
        ...formCache,
        request: dataValues,
      });

      setCurrentPage((prev) => prev - 1);
      return;
    }

    const formedData = createRequestFormData(dataValues, formCache);

    const response = await groupRequest({
      processId,
      data: formedData,
    });

    // @ts-ignore
    if (!response?.error && params.id) {
      handleClose();
      // временно, пока "проект" не переписан на RTK Query
      await dispatch(fetchProjectSectionsTreeDuplicate(+params.id));
    }
  };

  return {
    control,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    register,
    isSubmitting,
  } as const;
};
