import { ReactNode } from "react";
import cn from "classnames";
import styles from "./Card.module.scss";

const weights = {
  regular: 400,
};

type TProps = {
  children: ReactNode;
  fontSize?: number;
  fontWeight?: keyof typeof weights;
  className?: string;
  isCentered?: boolean;
};

export const CardHeader = ({
  children,
  fontSize = 24,
  fontWeight = "regular",
  className,
  isCentered,
}: TProps) => {
  return (
    <h2
      style={{ fontSize, fontWeight: weights[fontWeight] }}
      className={cn(
        styles.card__header,
        isCentered && styles.card__header_centered,
        className
      )}
    >
      {children}
    </h2>
  );
};
