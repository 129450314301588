import { Card } from "src/FSD/shared/uiKit/v2";
import styles from "../style.module.scss";

interface HeaderProps {
  count: number;
}
export const Header = ({ count }: HeaderProps) => {
  return (
    <Card
      header={
        <Card.Header className={styles.header} isCentered>
          Заявки ГИП <span>(всего {count})</span>
        </Card.Header>
      }
    />
  );
};
