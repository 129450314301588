import { Controller } from "react-hook-form";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./ExpertiseRemarkForm.module.scss";
import { useExpertiseRemark } from "../lib/hooks/useExpertiseRemark";

const defaultValues = {
  files: [],
  status: null,
};

interface Props {
  taskId: number;
  header: string;
}

export const ExpertiseRemarkForm = ({ taskId, header }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    setValue,
    errors,
    isDone,
    isSubmitting,
    control,
    isTaskViewPending,
    addText,
  } = useExpertiseRemark({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <>
          {addText && <p className={styles.addText}>{addText}</p>}
          <Modal.Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="files"
              control={control}
              render={({ field: { onChange } }) => (
                <Uploader
                  canEdit
                  getDataCallback={() => getTaskFiles(taskId)}
                  setFiles={(files) => {
                    onChange(files);
                  }}
                  autoUpload
                  postUrl={`${config.localDomain}/v1/task/add-file?id=${taskId}`}
                  error={Boolean(errors.files)}
                  helperText={errors.files?.message}
                />
              )}
            />
            {!addText && (
              <Modal.Controls>
                <Button
                  disabled={isSubmitting}
                  isLoading={isDone}
                  type="submit"
                  width={250}
                  onClick={() => {
                    setValue("status", Statuses.IS_DONE);
                  }}
                >
                  Отправить на проверку
                </Button>
              </Modal.Controls>
            )}
          </Modal.Form>
        </>
      )}
    </Modal.Layout>
  );
};
