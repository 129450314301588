import { memo } from "react";
import { IconButton } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import { useTaskRemoveMutation } from "src/FSD/entities/task/api";
import { CONFIRM_DELETE } from "../model/constants";
import { palette } from "src/styles/restyle";

type TProps = {
  taskId: number;
  onRemoval?: () => void;
};

export const DeleteTaskButton = memo(
  ({ taskId, onRemoval = () => {} }: TProps) => {
    const [removeTask] = useTaskRemoveMutation();

    const handleReturnDelete = async () => {
      if (!window.confirm(CONFIRM_DELETE)) {
        return;
      }

      await removeTask(taskId);
      onRemoval();
    };

    return (
      <IconButton onClick={handleReturnDelete}>
        <Trash color={palette.lightGrey} />
      </IconButton>
    );
  }
);
