import * as Yup from "yup";
import moment from "moment";

const parseDate = (value: Date, originalValue: string) =>
  moment(originalValue).isValid()
    ? value
    : moment(originalValue, "DD.MM.YYYY").toDate();

export function EditDateSchema(
  canPd: boolean,
  canRd: boolean,
  canIi: boolean,
  canObs: boolean,
  canExp: boolean
) {
  return Yup.object().shape({
    pd_date_start: canPd
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date().nullable(),
    pd_date_end: canPd
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("pd_date_start"),
            "Конечный срок не может быть раньше начального"
          )
      : Yup.date().nullable(),
    rd_date_start: canRd
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date().nullable(),
    rd_date_end: canRd
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("rd_date_start"),
            "Конечный срок не может быть раньше начального"
          )
      : Yup.date().nullable(),
    obs_date_start: canObs
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date().nullable(),
    obs_date_end: canObs
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("obs_date_start"),
            "Конечный срок не может быть раньше начального"
          )
      : Yup.date().nullable(),
    ii_date_start: canIi
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date().nullable(),
    ii_date_end: canIi
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("ii_date_start"),
            "Конечный срок не может быть раньше начального"
          )
      : Yup.date().nullable(),
    exp_date_start: canExp
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Начальный срок»")
      : Yup.date().nullable(),
    exp_date_end: canExp
      ? Yup.date()
          .typeError("Введите корректную дату")
          .required("Необходимо заполнить «Конечный срок»")
          .transform(parseDate)
          .min(
            Yup.ref("exp_date_start"),
            "Конечный срок не может быть раньше начального"
          )
      : Yup.date().nullable(),
  });
}
