import { useContext } from "react";
import cn from "classnames";
import { Card } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";
import { ProcessTypeDetails } from "./ProcessTypeDetails";
import { Header } from "./Header";
import { ProcessesTable } from "./ProcessesTable";
import { ProcessesSummary } from "./ProcessesSummary";
import { ApplicationsContext } from "../Applications";
import { Controls } from "./Controls";

export const Comparison = () => {
  const { applications, selectedUserTypeIds, groupId } =
    useContext(ApplicationsContext);

  return (
    <Card className={styles.comparisonTable}>
      <div
        className={cn(
          styles.grid,
          styles[`grid_${selectedUserTypeIds.length}`]
        )}
      >
        <Header />
        {Object.entries(applications[groupId!].processTypes).map(
          ([processTypeId, processType]: any) => (
            <>
              <ProcessTypeDetails
                key={processTypeId}
                processType={processType}
              />
              <ProcessesTable
                applicationsGroup={applications[groupId!]}
                processTypeId={Number(processTypeId)}
              />
              <ProcessesSummary
                applicationsGroup={applications[groupId!]}
                processTypeId={Number(processTypeId)}
                processTypeName={processType.typeName}
              />
            </>
          )
        )}
      </div>
      <Controls />
    </Card>
  );
};
