import React from "react";

export const ShieldCheck = () => (
  <svg width="16" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.63 1.984a1.048 1.048 0 0 0-.397-.176 1.108 1.108 0 0 0-.443-.004 8.869 8.869 0 0 1-3.241.007A8.483 8.483 0 0 1 8.57.661 1.079 1.079 0 0 0 8 .5c-.204 0-.403.056-.57.16a8.483 8.483 0 0 1-2.979 1.151 8.869 8.869 0 0 1-3.241-.007 1.108 1.108 0 0 0-.443.004 1.048 1.048 0 0 0-.397.176.922.922 0 0 0-.274.313.826.826 0 0 0-.096.39v6.706c0 1.291.34 2.563.997 3.711a8.417 8.417 0 0 0 2.773 2.888l3.65 2.341c.17.109.372.167.58.167.208 0 .41-.058.58-.167l3.65-2.34a8.417 8.417 0 0 0 2.774-2.889A7.457 7.457 0 0 0 16 9.394V2.685a.827.827 0 0 0-.097-.389.922.922 0 0 0-.273-.313ZM14 9.394a5.799 5.799 0 0 1-.774 2.885 6.545 6.545 0 0 1-2.156 2.246L8 16.497l-3.07-1.972a6.545 6.545 0 0 1-2.156-2.246A5.8 5.8 0 0 1 2 9.393V3.722c2.096.161 4.196-.277 6-1.252 1.804.975 3.904 1.413 6 1.252v5.671ZM9.54 7.331l-2.69 2.43-.89-.81a1.063 1.063 0 0 0-.71-.265c-.266 0-.522.096-.71.265a.86.86 0 0 0-.294.64c0 .24.106.469.294.639l1.6 1.44c.093.084.204.151.325.197a1.097 1.097 0 0 0 .77 0c.121-.046.232-.113.325-.197L11 8.601a.86.86 0 0 0 .294-.64.86.86 0 0 0-.294-.638 1.063 1.063 0 0 0-.71-.265c-.266 0-.522.095-.71.265l-.04.009Z"
      fill="#D5D6DE"
    />
  </svg>
);
