import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectProcessCheckTask } from "../services/fetchProjectProcessCheckTask";
import { TCheckTask } from "../types/projectProcessCheckTaskTypes";
import { fetchProjectProcessCheckDocumentations } from "../services/fetchProjectProcessCheckDocumentations";

interface InitialState {
  isLoad: boolean;
  isLoadCheckDocumentations: boolean;
  error: any;
  inCheckTask: TCheckTask[] | null;
  outCheckTask: TCheckTask[] | null;
  openTask: any;
}

const initialState: InitialState = {
  isLoad: false,
  isLoadCheckDocumentations: false,
  error: null,
  inCheckTask: null,
  outCheckTask: null,
  openTask: {},
};

const projectProcessCheckTask = createSlice({
  name: "projectProcessCheckTask",
  initialState,
  reducers: {
    setOpenTask: (state, action) => {
      const index = action.payload;
      if (state.openTask[index] !== undefined) {
        state.openTask[index] = undefined;
      } else {
        state.openTask[index] = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProcessCheckTask.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProjectProcessCheckTask.fulfilled, (state, action) => {
      state.isLoad = false;
      state.inCheckTask = Object.values(action.payload.in);
      state.outCheckTask = Object.values(action.payload.out);
    });
    builder.addCase(fetchProjectProcessCheckTask.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProjectProcessCheckDocumentations.pending, (state) => {
      state.isLoadCheckDocumentations = true;
    });
    builder.addCase(
      fetchProjectProcessCheckDocumentations.fulfilled,
      (state) => {
        state.isLoadCheckDocumentations = false;
      }
    );
  },
});

export const { setOpenTask } = projectProcessCheckTask.actions;
export default projectProcessCheckTask;
