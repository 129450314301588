import {
  getProjectDocument,
  setToggleProcessDocs,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import { useAppDispatch, useAppSelector } from "src/app/store";

export const useDocumentsProcessDocs = () => {
  const dispatch = useAppDispatch();
  const { processDocs } = useAppSelector(getProjectDocument);
  function toggleProcessDos(index: number) {
    dispatch(setToggleProcessDocs(index));
  }

  return {
    processDocs,
    toggleProcessDos,
  };
};
