import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Application as ApplicationCard } from "src/FSD/entities/application";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { useFeedbackTask } from "../lib/hooks/useFeedbackTask";
import { palette } from "../../../../../../../styles/restyle";
import styles from "./FeedbackForm.module.scss";
import { RatingStarsFeedback } from "./RatingStarsFeedback";

const defaultValues = {
  comment: "",
  quality: 0,
  reliability: 0,
  loyalty: 0,
};

type TProps = {
  taskId: number;
  header: string;
  isExecutor?: boolean;
};

export const FeedbackForm = ({
  taskId,
  header,
  isExecutor = false,
}: TProps) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    isSubmitting,
    isTaskViewPending,
    control,
    taskDetails,
  } = useFeedbackTask({
    taskId,
    defaultValues,
    handleClose,
    isExecutor,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <ApplicationCard
            className={styles.feedbackTask}
            avatar={
              <ApplicationCard.Avatar
                photoUrl={taskDetails.photoUrl!}
                borderColor={palette.persianGreen}
                lowerText={taskDetails.lowerText}
                fio={taskDetails.fio!}
                userId={taskDetails.userId!}
                className={styles.avatar}
              />
            }
            rating={
              <ApplicationCard.Rating
                rating={taskDetails.rating!}
                jobDuration={Number(taskDetails.jobDuration)}
                fontSize={24}
              />
            }
          />
          <RatingStarsFeedback control={control} />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Оценить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
