import React from "react";
import { Paper, TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ComponentPropsType } from "../../../../../types/other/components/componentPropsType";
import { textFieldSX } from "../../../../../styles/restyle";

export type SelectGroupedProps = ComponentPropsType<{
  id?: string;
  label: string;
  options: Record<string, any>[];
  isLoading?: boolean;
  defaultValue?: Record<string, any> | null;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  value?: any | undefined;
  disableClear?: boolean;
  fullWidth?: boolean;
  showLabel?: boolean;
  noOptionText?: string;
  paperOptions?: Record<string, any>;
  paperOptionHeight?: number;
  searchHandler?: (val: string) => void;
  changeHandler?: (event: any, value: any, reason: any) => void;
  groupLabelColor?: string;
  disabled?: boolean;
}>;

// более гибко настраиваемый селект,
// с возможностью передать объект paperOptions стилей для выпадающего paper
export const SelectGrouped: React.FC<SelectGroupedProps> = ({
  id = "",
  label = "",
  options,
  isLoading = false,
  defaultValue = undefined,
  error = false,
  helperText = "",
  name = "",
  value = undefined,
  disableClear = false,
  noOptionText = "не найдено",
  paperOptions = {
    marginTop: 4,
    color: "#26313a",
    maxWidth: "320px",
    zIndex: "9999999",
  },
  paperOptionHeight = 28,
  fullWidth = false,
  variant = "",
  changeHandler = () => {},
  searchHandler = () => {},
  groupLabelColor = "#26313a",
  disabled,
}) => {
  // useStyles добавлено на тот случай если первый элемент будет
  // с пустым значением { id: "", title: ""}, чтобы задать ему и следующим пунктам
  // одинаковую высоту
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option": {
          minHeight: `${paperOptionHeight}px`,
          lineHeight: "1.3",
        },
      },
      "& .MuiAutocomplete-groupLabel": {
        color: groupLabelColor,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Autocomplete
      id={id}
      size="small"
      className={variant}
      fullWidth={fullWidth}
      noOptionsText={noOptionText}
      closeText={"Удалить"}
      clearText={"Очистить"}
      loadingText={"Загрузка"}
      openText={"Открыть"}
      defaultValue={defaultValue}
      loading={isLoading}
      options={options}
      value={value}
      disabled={disabled}
      groupBy={(option) => option.typeName}
      disableClearable={disableClear}
      onChange={changeHandler}
      getOptionLabel={(option: any) => option.title}
      ListboxProps={{
        // @ts-ignore
        shrink: "true",
      }}
      PaperComponent={({ children }) => (
        <Paper style={paperOptions} classes={classes}>
          {children}
        </Paper>
      )}
      renderInput={(params: any) => (
        <TextField
          error={error}
          helperText={helperText}
          name={name}
          className={variant}
          label={label}
          variant="outlined"
          value={(value && value.title) || undefined}
          onChange={(e) => {
            searchHandler(e.currentTarget.value);
          }}
          {...params}
          {...textFieldSX}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
