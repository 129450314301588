import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2";
import { useRequestEquipment } from "../lib/hooks/useRequestEquipment";
import { RequestEquipment } from "./RequestEquipment";
import styles from "./RequestEquipmentForm.module.scss";
import { Task } from "./Task";
import { Statuses } from "../../../../../../shared/lib/constants/statuses";

type TProps = {
  taskId: number;
  header: string;
};

const defaultValues = {
  price: 0,
  dateDeadline: null,
  comment: "",
  files: [],
  userType: null,
  status: null,
};

export const RequestEquipmentForm = ({ taskId, header }: TProps) => {
  const {
    isFetching,
    taskData,
    form,
    onSubmit,
    isCancelling,
    isAccepting,
    isSubmitting,
  } = useRequestEquipment({
    taskId,
    defaultValues,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          className={styles.form}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Task taskData={taskData!} />
          <RequestEquipment taskData={taskData!} form={form} />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isCancelling}
              type="submit"
              color="error"
              variant="outlined"
              onClick={() => {
                form.setValue("status", Statuses.CANCELLED);
              }}
            >
              Отказать
            </Button>
            <Button
              disabled={isSubmitting}
              isLoading={isAccepting}
              type="submit"
              onClick={() => {
                form.setValue("status", Statuses.IS_DONE);
              }}
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
