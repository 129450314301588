import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProcessWork,
  fetchProcessWorkDuplicate,
} from "../services/fetchProcessWork";
import { TGroups } from "../types/projectProcessWorkTypes";
import { fetchProjectProcessWorkPublish } from "../services/fetchProjectProcessWorkPublish";
import { fetchProjectProcessWorkPublishUpdate } from "../services/fetchProjectProcessWorkPublishUpdate";
import { fetchProjectProcessDelegate } from "../services/fetchProjectProcessDelegate";

interface InitialState {
  isLoad: boolean;
  isLoadCard: boolean;
  isLoadDelegate: boolean;
  error: any;
  groups: [string, TGroups][] | null;
  fullCost: number | null;
  dot: boolean;
  publish: boolean;
  isLoadPublishWork: boolean;
  isLoadUpdatePublishWork: boolean;
  update: boolean;
  error422: any;
}

const initialState: InitialState = {
  dot: false,
  isLoad: false,
  isLoadCard: false,
  isLoadDelegate: false,
  error: null,
  groups: null,
  fullCost: null,
  isLoadPublishWork: false,
  publish: false,
  update: false,
  isLoadUpdatePublishWork: false,
  error422: null,
};

const projectProcessWork = createSlice({
  name: "projectProcessWork",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProcessWork.pending, (state) => {
      state.error = null;
      state.isLoad = true;
    });
    builder.addCase(fetchProcessWork.fulfilled, (state, action) => {
      state.fullCost = action.payload.fullCost;
      state.dot = action.payload.dot;
      state.groups = Object.entries(action.payload.groups);
      state.isLoad = false;
      state.update = false;
      state.publish = false;
    });
    builder.addCase(fetchProjectProcessDelegate.pending, (state) => {
      state.isLoadDelegate = true;
    });
    builder.addCase(fetchProjectProcessDelegate.fulfilled, (state) => {
      state.isLoadDelegate = false;
    });
    builder.addCase(fetchProcessWork.rejected, (state, action) => {
      state.isLoad = false;
      state.error = action.payload;
    });
    builder.addCase(fetchProcessWorkDuplicate.pending, (state) => {
      state.error = null;
      state.isLoadCard = true;
    });
    builder.addCase(fetchProcessWorkDuplicate.fulfilled, (state, action) => {
      state.fullCost = action.payload.fullCost;
      state.dot = action.payload.dot;
      state.groups = Object.entries(action.payload.groups);
      state.update = false;
      state.publish = false;
      state.isLoadCard = false;
    });
    builder.addCase(fetchProjectProcessWorkPublish.pending, (state) => {
      state.isLoadPublishWork = true;
      state.error = null;
      state.error422 = null;
      state.publish = false;
    });
    builder.addCase(fetchProjectProcessWorkPublish.fulfilled, (state) => {
      state.publish = true;
      state.error422 = null;
      state.error = null;
      state.isLoadPublishWork = false;
    });
    builder.addCase(
      fetchProjectProcessWorkPublish.rejected,
      (state, action) => {
        state.isLoadPublishWork = false;
        if (action?.payload?.status === "none") {
          state.error = action.payload.message;
        }
        if (action?.payload?.status === "422") {
          state.error422 = action.payload.message;
        }
      }
    );
    builder.addCase(fetchProjectProcessWorkPublishUpdate.pending, (state) => {
      state.isLoadUpdatePublishWork = true;
      state.error = null;
      state.update = false;
      state.error422 = null;
    });
    builder.addCase(fetchProjectProcessWorkPublishUpdate.fulfilled, (state) => {
      state.update = true;
      state.isLoadUpdatePublishWork = false;
    });
    builder.addCase(
      fetchProjectProcessWorkPublishUpdate.rejected,
      (state, action) => {
        state.isLoadUpdatePublishWork = false;
        if (action?.payload?.status === "none") {
          state.error = action.payload.message;
        }
        if (action?.payload?.status === "422") {
          state.error422 = action.payload.message;
        }
      }
    );
  },
});
export default projectProcessWork;
