import {
  getProjectDocument,
  setToggleDocs,
} from "src/app/feature/ProjectView/DocumentReducer/projectDocuments";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { iconType } from "../helpers";

export const useCardNode = () => {
  const dispatch = useAppDispatch();
  const { docs } = useAppSelector(getProjectDocument);

  const isMobile = useIsMobile(651);

  function handleToggleDocs(index: number) {
    dispatch(setToggleDocs(index));
  }

  return {
    docs,
    isMobile,
    handleToggleDocs,
    iconType,
  };
};
