import React, { memo } from "react";
import moment from "moment/moment";
import { IconButton } from "@mui/material";
import { ColorType, selectColor } from "src/utils/selectColor";
import { CommentIcon } from "src/components/newUI/SVG";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { iconBtnSx } from "src/styles/restyle";
import { FileArrowDownFill, PencilSquare } from "react-bootstrap-icons";
import { ApiExpertiseRemarkResponses } from "src/FSD/entities/expertise/remark/model/responseTypes";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "../TableCard.module.scss";
import { BackToWorkBtn } from "./BackToWorkBtn/BackToWorkBtn";
import { CompleteBtn } from "./CompleteBtn/CompleteBtn";

type TableCardBodyProps = {
  data: ApiExpertiseRemarkResponses.RemarkRowsValue;
};
export const TableCardBody = memo(({ data }: TableCardBodyProps) => {
  const { row, count, groups, commentCount } = data;
  const groupsArray = Object.values(groups);
  const { handleOpenModal } = useModal();

  const handleCommentRemarkRow = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.COMMENT_REMARK_ROW,
      rowId: row.id,
    });
  };

  const handleTaskComplete = (task: ApiTaskResponses.View) => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.TASK_COMPLETE,
      row,
      task,
    });
  };

  return (
    <>
      <tr>
        <td rowSpan={count}>{row.num}</td>
        <td rowSpan={count}>{row.text}</td>
        <td rowSpan={count}>{row.comment}</td>
      </tr>
      {groupsArray.map((group) => (
        <React.Fragment key={group.partGroup.id}>
          <tr>
            <td colSpan={3}>
              <div className={styles.flex}>
                <p>{group?.partGroup?.name}</p>
                <p
                  style={{
                    color: selectColor(group.status.color as ColorType),
                  }}
                >
                  {group.status.value}
                </p>
                <p>
                  {moment(row.date_limit, "DD.MM.YYYY HH:mm:ss Z").format(
                    "DD.MM.YYYY"
                  )}
                </p>
              </div>
            </td>
          </tr>
          {group.tasks?.map((task) => (
            <tr key={task.id}>
              <td>
                <div className={styles.flex}>
                  <div className={styles.flexCol}>
                    <p>{`${task.taskToProcess?.projectPart?.code} (${task.executor?.surname})`}</p>
                    <span
                      style={{
                        color: selectColor(task.status.color as ColorType),
                      }}
                    >
                      {task.status.value}
                    </span>
                    <p
                      style={{ color: selectColor(task.workflow_data?.color) }}
                    >
                      {task.workflow_data.reason}
                    </p>
                    <p>{task.comment}</p>
                  </div>
                  <div>
                    <IconButton onClick={handleCommentRemarkRow}>
                      {commentCount === 0 ? (
                        <CommentIcon />
                      ) : (
                        <CommentIcon
                          fonsSize={"10"}
                          color={"#fa990e"}
                          count={String(commentCount)}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.changes}>
                  {task.workflow_data.changes}
                  {task.files.length === 0 && (
                    <p>(Документация не приложена)</p>
                  )}
                </div>
              </td>
              <td>
                <div className={styles.buttons}>
                  {task.can.edit && (
                    <IconButton
                      title={"Редактировать"}
                      sx={iconBtnSx}
                      onClick={() => handleTaskComplete(task)}
                    >
                      <PencilSquare size={24} />
                    </IconButton>
                  )}
                  {task.can.backToWork && (
                    <BackToWorkBtn taskId={task.children[0].id} />
                  )}
                  {task.can.check && (
                    <CompleteBtn taskId={task.children[0].id} />
                  )}
                  {task.can.view && task.files?.length > 0 && (
                    <IconButton
                      sx={iconBtnSx}
                      component={"a"}
                      href={`/task/download-files?id=${task.parent_id}`}
                      target={"_blank"}
                      title={"Скачать"}
                    >
                      <FileArrowDownFill />
                    </IconButton>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
});
