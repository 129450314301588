import { ReactNode, memo } from "react";
import styles from "./Column.module.scss";

type TProps = {
  children: ReactNode;
};

const ColumnRange = memo(({ children }: TProps) => {
  return <p className={styles.header__range}>{children}</p>;
});

export default ColumnRange;
