import { helpersYears } from "src/utils/helpersYears";
import { StarFill } from "react-bootstrap-icons";
import styles from "./Rating.module.scss";
import { Paragraph } from "../Paragraph";

type Props = {
  rating: number;
  jobDuration?: number;
  fontSize?: number;
};

export const RatingStarExperience = ({
  rating,
  jobDuration,
  fontSize = 20,
}: Props) => {
  const formattedRating = rating ? Number(rating).toFixed(1) : "0";

  const formattedJobDuration = helpersYears(jobDuration);

  return (
    <div className={styles.skills}>
      <div className={styles.star}>
        <StarFill />
        <Paragraph color="light" fontSize={fontSize} fontWeight="medium">
          {formattedRating}
        </Paragraph>
      </div>
      {jobDuration !== undefined && (
        <Paragraph>Опыт {formattedJobDuration}</Paragraph>
      )}
    </div>
  );
};
