import cn from "classnames";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import styles from "./Application.module.scss";
import { Nullable } from "src/types/generics";

type TProps = {
  remarks: string[];
  reasons: string[];
  accountId: Nullable<number>;
};

export const ApplicationRemarks = ({ remarks, reasons, accountId }: TProps) => {
  if (!remarks.length && !reasons.length) {
    return <div className={styles.noRemarks} />;
  }

  return (
    <div className={styles.remarks}>
      <div className={cn(styles.remarks__list, styles.remarks__list_major)}>
        {remarks.map((remark, index) => {
          return accountId ? (
            <TextLink
              key={index}
              url={`${config.localDomain}/account/view?id=${accountId}`}
              target="_blank"
              redirect
              className={styles.link}
            >
              {remark}
            </TextLink>
          ) : (
            <p key={index} className={styles.link}>
              {remark}
            </p>
          );
        })}
      </div>
      <div className={styles.remarks__list}>
        {reasons.map((reason, index) => (
          <p key={index}>{reason}</p>
        ))}
      </div>
    </div>
  );
};
