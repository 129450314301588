import { useTaskGetCommentsQuery } from "src/FSD/entities/task/api";

export const useComments = (taskId: number) => {
  const { data: comments = [], isFetching } = useTaskGetCommentsQuery({
    id: taskId,
  });

  const isCommentsLoading = isFetching || !comments;

  return { comments, isCommentsLoading } as const;
};
