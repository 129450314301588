import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import {
  PencilSquare,
  BoxArrowInLeft,
  Trash,
  BoxArrowInRight,
} from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { iconBtnSx } from "src/styles/restyle";
import { ApiExpertiseRemarkResponses } from "src/FSD/entities/expertise/remark/model/responseTypes";
import ExpertiseModals from "src/FSD/widgets/v2/expertise/modals";
import {
  useRemarkRowDeleteMutation,
  useRemarkRowStartMutation,
  useRemarkRowStopMutation,
} from "src/FSD/entities/expertise/remarkRow/api";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import styles from "../../RemarkCard.module.scss";

interface GridCardBodyProps {
  data: ApiExpertiseRemarkResponses.RemarkRowsValue;
  processId: number;
  can?: ApiProjectProcessResponses.Can;
}

export const GridCardBody = memo(
  ({ data, can, processId }: GridCardBodyProps) => {
    const { row, partCodes } = data;
    const [modalUpdateRemarkRow, setUpdateRemarkRow] = useState(false);

    const handleCloseModal = useCallback(() => {
      setUpdateRemarkRow(false);
    }, []);

    const [remarkRowStop] = useRemarkRowStopMutation();
    async function remarkRowStopHandler() {
      const result = window.confirm(
        "Вы уверены, что хотите вернуть замечание в черновик?"
      );
      if (result) {
        await remarkRowStop({ id: row.id });
      }
    }

    const [remarkRowStart] = useRemarkRowStartMutation();
    async function remarkRowStartHandler() {
      const result = window.confirm(
        "Вы уверены, что хотите направить замечание в работу?"
      );
      if (result) {
        await remarkRowStart({ id: row.id });
      }
    }

    const [remarkRowDelete] = useRemarkRowDeleteMutation();
    async function remarkRowDeleteHandler() {
      const result = window.confirm(
        "Вы уверены, что хотите удалить замечание ?"
      );
      if (result) {
        await remarkRowDelete({ id: row.id });
      }
    }

    return (
      <>
        <div className={styles.grid_item}>{row.num}</div>
        <div className={styles.grid_item}>{row.text}</div>
        <div className={styles.grid_item}>{row.comment}</div>
        <div className={classNames(styles.grid_item, styles.grid_codes)}>
          {partCodes.map((code) => (
            <div key={code}>{code}</div>
          ))}
        </div>
        <div className={styles.grid_item}>{row.date_limit}</div>
        <div className={styles.grid_item}>{row.status.value}</div>
        <div className={styles.grid_item}>
          {can?.edit && (
            <div>
              <Modal
                isActive={modalUpdateRemarkRow}
                handleClose={handleCloseModal}
              >
                <ExpertiseModals.EditRemarkRowForm
                  processId={processId!}
                  remarkRowId={row.id}
                />
              </Modal>
              <IconButton
                sx={iconBtnSx}
                title={"Изменить"}
                onClick={() => setUpdateRemarkRow(true)}
              >
                <PencilSquare />
              </IconButton>
              {row.status.key !== 1 && (
                <IconButton
                  sx={iconBtnSx}
                  title={"Вернуть в черновик"}
                  onClick={remarkRowStopHandler}
                >
                  <BoxArrowInLeft />
                </IconButton>
              )}
              {row.status.key === 1 && (
                <IconButton
                  sx={iconBtnSx}
                  title={"Отправить в работу"}
                  onClick={remarkRowStartHandler}
                >
                  <BoxArrowInRight />
                </IconButton>
              )}
              {row.status.key === 1 && (
                <IconButton
                  sx={iconBtnSx}
                  title={"Удалить"}
                  onClick={remarkRowDeleteHandler}
                >
                  <Trash />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);
