import {
  Globe2,
  Telephone,
  Envelope,
  FileEarmarkArrowDown,
} from "react-bootstrap-icons";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { TextLink } from "src/FSD/shared/uiKit/v2";
import { ApiMaterialProcessResponses } from "src/FSD/entities/materialProcess/model/responseTypes";
import { copyTextToClipboard } from "src/FSD/shared/lib/helpers/copyTextToClipboard";
import styles from "./ProviderFirm.module.scss";
import { ImageTitle } from "./ImageTitle";
import { IconWithTooltip } from "./IconWithTooltip";
import { useAppDispatch } from "../../../../../../../../app/store";
import { palette } from "../../../../../../../../styles/restyle";
import { NoLogoSVG } from "../../../../../../../../components/newUI/SVG";

type TProps = {
  providerFirm: ApiMaterialProcessResponses.GetProviderFirms;
};

export const ProviderFirm = ({ providerFirm }: TProps) => {
  const { phone, email, site, questionnaires } = providerFirm;

  const displayPhone = phone ? phone : "-";
  const displayEmail = email ? email : "-";
  const displaySite = site ? "" : ": -";
  const displayQuestionnaires = questionnaires!.length ? "" : ": -";

  const dispatch = useAppDispatch();

  const photoUrl = providerFirm.photo
    ? `${config.localDomain}${providerFirm.photo}`
    : "";

  return (
    <div className={styles.blockProvidesFirm}>
      <ImageTitle
        photoUrl={photoUrl}
        title={providerFirm.title}
        icon={<NoLogoSVG />}
      />
      <div className={styles.blockProvidesFirm__info}>
        <IconWithTooltip
          title={`Сайт${displaySite}`}
          icon={(color: string) =>
            providerFirm.site ? (
              <TextLink
                url={providerFirm.site}
                className={styles.blockProvidesFirm__link}
                target="_blank"
              >
                <Globe2 color={color} />
              </TextLink>
            ) : (
              <Globe2 color={palette.darkTelegraph} />
            )
          }
        />
        <IconWithTooltip
          title={`Телефон: ${displayPhone}`}
          icon={(color: string) =>
            providerFirm.phone ? (
              <Telephone color={color} />
            ) : (
              <Telephone color={palette.darkTelegraph} />
            )
          }
          onClick={() =>
            providerFirm.phone &&
            copyTextToClipboard(providerFirm.phone, dispatch)
          }
          color={palette.oceanicSmooth}
        />
        <IconWithTooltip
          title={`Почта: ${displayEmail}`}
          icon={(color: string) =>
            providerFirm.email ? (
              <Envelope color={color} />
            ) : (
              <Envelope color={palette.darkTelegraph} />
            )
          }
          onClick={() =>
            providerFirm.email &&
            copyTextToClipboard(providerFirm.email, dispatch)
          }
          color={palette.oceanicSmooth}
        />
        <IconWithTooltip
          title={`Опросные листы${displayQuestionnaires}`}
          icon={(color: string) =>
            providerFirm.questionnaires!.length ? (
              <TextLink
                url={`${config.localDomain}/firm/download-files?id=${providerFirm.id}`}
                className={styles.blockProvidesFirm__link}
                target="_blank"
              >
                <FileEarmarkArrowDown color={color} />
              </TextLink>
            ) : (
              <FileEarmarkArrowDown color={palette.darkTelegraph} />
            )
          }
        />
      </div>
    </div>
  );
};
