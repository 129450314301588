import { Button, Paragraph, TextLink } from "src/FSD/shared/uiKit/v2";
import styles from "./Comparison.module.scss";
import { config } from "../../../../../../shared/lib/configs/app.config";
import { useControls } from "../../lib/hooks/useControls";

const warning: string[] = [];

export const Controls = () => {
  const {
    totalPrice,
    submitHandler,
    isSubmitting,
    showBankAccountError,
    totalPriceDifference,
    account,
    customer,
  } = useControls();

  const SubmitButton = totalPrice > 0 && (
    <Button
      className={styles.controls__submitButton}
      onClick={submitHandler}
      isLoading={isSubmitting}
      disabled={isSubmitting}
    >
      Выбрать
    </Button>
  );

  const BankAccountErrorMessage = showBankAccountError && (
    <Paragraph color="error" className={styles.controls__error}>
      Для обеспечения безопасной сделки необходимо, чтобы заказчик{" "}
      <TextLink
        redirect
        target="_blank"
        url={`${config.localDomain}/account/view?id=${account.id}`}
      >
        {customer}
      </TextLink>{" "}
      пополнил свой кошелек на {totalPriceDifference} р! Свяжитесь с ним для
      этого!
    </Paragraph>
  );

  const showSubmitButton = Boolean(!showBankAccountError && !warning.length);

  return (
    <div className={styles.controls}>
      <div className={styles.controls__warning}>
        {warning.map((warningMessage, index) => (
          <Paragraph color="warning" key={index}>
            {warningMessage}
          </Paragraph>
        ))}
        {BankAccountErrorMessage}
      </div>
      {showSubmitButton && SubmitButton}
    </div>
  );
};
