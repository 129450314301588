import { Controller, UseFormReturn } from "react-hook-form";
import {
  DateField,
  Paragraph,
  TextField,
  Uploader,
} from "src/FSD/shared/uiKit/v2";
import { SelectUserTypes } from "src/FSD/features/v2/SelectUserTypes";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./RequestEquipmentForm.module.scss";
import { TFiledValues } from "../lib/hooks/useRequestEquipment";

type TProps = {
  taskData: ApiTaskResponses.View;
  form: UseFormReturn<TFiledValues>;
};

export const RequestEquipment = ({ taskData, form }: TProps) => {
  const { control, register, formState, setValue } = form;

  return (
    <div className={styles.wrapper}>
      <Paragraph fontSize={18} color="light">
        Предложение
      </Paragraph>
      <div className={styles.container}>
        <div className={styles.container__item}>
          <TextField
            {...register("price")}
            label="Стоимость"
            error={!!formState.errors.price}
            helperText={formState.errors?.price?.message}
          />
          <Controller
            control={control}
            name="dateDeadline"
            render={({ field: { value, onChange } }) => (
              <DateField
                label="Действительно до"
                startDateProp={value}
                changeDateHandler={(date) => {
                  onChange(date);
                }}
                error={!!formState.errors.dateDeadline}
                helperText={formState.errors?.dateDeadline?.message}
              />
            )}
          />
        </div>
        <TextField
          {...register("comment")}
          label="Комментарий"
          multiline
          error={!!formState.errors.comment}
          helperText={formState.errors?.comment?.message}
          minRows={3}
        />
        <Controller
          name="userType"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectUserTypes
              value={value}
              changeHandler={(_, newValue) => {
                onChange(newValue);
              }}
              setValue={setValue}
              error={!!formState.errors.userType}
              helperText={formState.errors?.userType?.message}
              userTypeId={taskData.workflow_data.userTypeId}
            />
          )}
        />
        <Controller
          name="files"
          control={control}
          render={({ field: { onChange } }) => (
            <Uploader
              canEdit
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(formState.errors.files)}
              helperText={formState.errors.files?.message}
              uploadAsActive
              autoUpload
              postUrl={`${config.localDomain}/v1/task/add-file?id=${taskData.id}`}
              initialFiles={taskData.outFiles}
            />
          )}
        />
      </div>
    </div>
  );
};
