import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "src/app/services/api/requestHandler";
import { pushError } from "../../../errorTrace";
import { ApiProjectProcessSourceDataUpdate } from "src/app/services/api/projectProcess/initialData";
import { ProjectProcessSourceDataChild } from "../types/projectProcessInitialDataTypes";

type TSourceDataUpdate = {
  projectId: number;
  type: number;
  data: {
    critical: boolean;
    executor: boolean;
    hidden: boolean;
    id: number;
    select: boolean;
  }[];
  parentId: number;
};
export const fetchProjectProcessSourceDataUpdate = createAsyncThunk<
  ProjectProcessSourceDataChild[],
  TSourceDataUpdate
>(
  "projectProcess/fetchProjectProcessSourceDataUpdate",
  async ({ projectId, parentId, data, type }, { dispatch }) => {
    let response: any = {};
    await request(
      ApiProjectProcessSourceDataUpdate(projectId, parentId, data, type),
      (data) => {
        response = data;
      },
      () => (err) => {
        dispatch(pushError(err));
      }
    )();
    return response;
  }
);
