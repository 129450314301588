import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { XSquareFill } from "react-bootstrap-icons";
import { useTaskCompleteMutation } from "src/FSD/entities/task/api";

type TProps = {
  taskId: number;
};

export const BackToWorkBtn = memo(({ taskId }: TProps) => {
  const [taskComplete] = useTaskCompleteMutation();

  async function backToWorkHandle() {
    if (!window.confirm("Вы уверены, что хотите вернуть на доработку?")) {
      return;
    }
    const data = {
      CompleteForm: {
        status: 5,
      },
    };
    await taskComplete({ id: taskId, task: data });
  }

  return (
    <IconButton
      sx={iconBtnSx}
      title={"Вернуть на доработку"}
      onClick={backToWorkHandle}
    >
      <XSquareFill size={24} />
    </IconButton>
  );
});
