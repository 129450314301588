import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProjectProcessRequestOutTaskMutation } from "src/FSD/entities/projectProcess/api";
import { requestOutTaskSchema } from "../schemas/requestOutTask";
import { Nullable } from "src/types/generics";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";

interface IInputs {
  dateLimit: Nullable<Date>;
  content: string;
  processesIds: TSelectItem[];
  projectId: TSelectItem;
}

type TProps = {
  id: number;
};

export const useRequestOutTask = ({ id }: TProps) => {
  const { handleClose } = useModalContext();

  const methods = useForm<IInputs>({
    resolver: yupResolver(requestOutTaskSchema),
  });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const projectValue = watch("projectId");

  const [requestOutTask, { isLoading }] =
    useProjectProcessRequestOutTaskMutation();

  const formSubmitHandler = async (dataValues: IInputs) => {
    const processesIds = dataValues.processesIds.map((item) => item.id);
    const data = {
      dateLimit: toServerDate(dataValues.dateLimit),
      content: dataValues.content,
      processesIds,
      projectId: Number(projectValue.id),
    };

    if (id) {
      await requestOutTask({ id, data });
      handleClose();
    }
  };

  const onSubmit = handleSubmit(formSubmitHandler);

  const currentDate = new Date();

  return {
    onSubmit,
    control,
    setValue,
    errors,
    isLoading,
    currentDate,
  } as const;
};
